import React, { Suspense, lazy, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Grid, Typography } from '@mui/material'
import { t } from 'i18next'
import {

  commonUrlFilterData, initialRowsPerPageAndPages,
} from '../../../CommonAppRedux/commonAppSlice'
import { commonAppSelector } from '../../../CommonAppRedux/selector'
import {
  useAppDispatch,
  useAppSelector,
} from '../../../ReusableComponent/CustomHooks/appHooks'
import { searchOrderSubmitValuesProps } from '../Types'
import { getCompleteQuotationListDetailRequest } from '../Redux/completeQuotationListSlices'
import { getCommonStorePreviousURLPath } from '../../../CommonLocalStorageRedux/localReduxSlice'

//below import with lazy loading, which is typically used when you want to load modules or components asynchronously, especially when they are not immediately needed.
const CompleteQuotationListTable = lazy(() => import('./CompleteQuotationListTables'))
const CompleteQuotationList = () => {
  const dispatch = useAppDispatch()

  const { rowsPerPage, page, tableCheckBoxData, collapseTableCheckBoxData } =
    useAppSelector(commonAppSelector)

  //filter for test purpose make component soon---
  const params = useParams<{ values?: string }>()
  const decodedValues = useMemo(() => {
    if (params.values) {
      return decodeURIComponent(params.values)
    }
    return ''
  }, [params.values])

  // Define the type for parsedValues
  let parsedValues: searchOrderSubmitValuesProps | null = null

  try {
    if (decodedValues) {
      parsedValues = JSON.parse(decodedValues)
    }
  } catch (error) {
    console.error('Error parsing JSON:', error)
  }

  useEffect(() => {
    dispatch(commonUrlFilterData(parsedValues))
    dispatch(
      getCompleteQuotationListDetailRequest({
        page: page,
        rowsPerPage: rowsPerPage,
        filterData: parsedValues,
      })
    )

  }, [rowsPerPage, page])
  const location=useLocation();
  useEffect(() => {
    dispatch(getCommonStorePreviousURLPath([{pathName:location?.pathname,search: location?.search }]))
  }, [])

  return (
    <React.Fragment>
      <Typography variant="h6" fontWeight="bold" sx={{ padding: '16px' }}>
      {t('menuName.completeQuotationList')}
      </Typography>
      {/* <Suspense fallback={<></>}>
        <CompleteQuotationListSearch />
      </Suspense> */}
      <Suspense fallback={<></>}>
        <CompleteQuotationListTable />
      </Suspense>
    </React.Fragment>
  )
}

export default CompleteQuotationList
