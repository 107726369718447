import { createSlice } from '@reduxjs/toolkit'

import { billedListProps } from './types'

const initialState: billedListProps = {
  billedLists: [],
  edit: false,
  billedList: null,
  loadingBilledList: false,
  loadingCreateBilledList: false,
  count: 0,
  next: '',
  previous: '',
}
export const BilledList = createSlice({
  name: 'billedListReducer',
  initialState,
  reducers: {
    loadingBilledList: (state) => {
      state.loadingBilledList = true
    },
    getBilledListRequest: (state, action) => {
      state.loadingBilledList = true
    },
    getBilledListSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingBilledList = false
      state.billedLists = results
      state.count = count
      state.next = next
      state.previous = previous
    },
    getBilledListFail: (state) => {
      state.loadingBilledList = false
    },
    getBilledListNextRequest: (state, action) => {
      state.loadingBilledList = true
    },
    getBilledListPreviousRequest: (state, action) => {
      state.loadingBilledList = true
    },
  },
})
export const {
  loadingBilledList,
  getBilledListSuccess,
  getBilledListFail,
  getBilledListRequest,
  getBilledListPreviousRequest,
  getBilledListNextRequest,
} = BilledList.actions
export default BilledList.reducer
