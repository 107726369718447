import produce from 'immer'

import { getSearchURL } from '../Utils/AppFunction'
import { defaultRowsPerPage } from '../Utils/globalConstant'
import { InputChangeEvent } from '../Utils/globalTypes'
import {
  commonCostSharingDepartmentCodeModUrl,
  commonDocumentUrl,
  commonExpenseTypeUrl,
  commonExportRestrictionUrl,
  commonIndustryTypeUrl,
  commonJobUrl,
  commonSaleURL,
  commonSalesDepartmentModUrl,
  commonSalesTypeModUrl,
  commonTranslationSubjectUrl,
  commonUserModUrl,
  commonDepartmentModUrl,
  commonAffiliatedDepartmentModUrl,
  commonStatusModUrl,
  commonTaskTypeModUrl,
} from './api'
import {
  getCommonTaskFail,
  getCommonTaskRequest,
  getCommonTaskSuccess,
  getCommonTradosFail,
  getCommonTradosRequest,
  getCommonTradosSuccess,
  getCommonTranslatorFail,
  getCommonTranslatorRequest,
  getCommonTranslatorSuccess,
  loadingCommonTask,
  loadingCommonTrados,
  loadingCommonTranslator,
  getCommonCompanyFail,
  getCommonCompanyRequest,
  getCommonCompanySuccess,
  getCommonDeliveryStyleFail,
  getCommonDeliveryStyleRequest,
  getCommonLayoutFail,
  getCommonLayoutRequest,
  getCommonLayoutSuccess,
  getCommonPaymentTermsFail,
  getCommonPaymentTermsRequest,
  getCommonPaymentTermsSuccess,
  getCommonRequesterRequest,
  getCommonTranslatorLanguageFail,
  getCommonTranslatorLanguageRequest,
  getCommonTranslatorLanguageSuccess,
  loadingCommonCompany,
  loadingCommonDeliveryStyle,
  loadingCommonLayoutRequest,
  loadingCommonPaymentTerms,
  loadingCommonTranslatorLanguage,
  getCommonFilteredTranslatorOptionSuccess, // getCommonFilteredTranslatorSuccess,
  getCommonFilteredTranslatorOptionRequest,
  loadingCommonFilteredTranslatorOption,
  getCommonTranslatorFilterOptionFail,
  loadingCommonSaleTax,
  getCommonSaleTaxSuccess,
  getCommonSaleTaxFail,
  getCommonSaleTaxRequest,
  getCommonDocumentRequest,
  getCommonDocumentSuccess,
  loadingCommonDocument,
  getCommonDocumentFail,
  loadingCommonTranslationSubject,
  getCommonTranslationSubjectFail,
  getCommonTranslationSubjectSuccess,
  getCommonTranslationSubjectRequest,
  loadingCommonExpenseType,
  getCommonExpenseTypeSuccess,
  getCommonExpenseTypeFail,
  getCommonExpenseTypeRequest,
  loadingCommonIndustryType,
  getCommonIndustryTypeSuccess,
  getCommonIndustryTypeFail,
  getCommonIndustryTypeRequest,
  getCommonExportRestrictionRequest,
  loadingCommonExportRestriction,
  getCommonExportRestrictionSuccess,
  getCommonExportRestrictionFail,
  getCommonJobRequest,
  loadingCommonJob,
  getCommonJobSuccess,
  getCommonJobFail,
  loadingCommonUser,
  getCommonUserSuccess,
  getCommonUserFail,
  getCommonUserRequest,
  getCommonCostSharingDepartmentCodeRequest,
  getCommonCostSharingDepartmentCodeSuccess,
  getCommonCostSharingDepartmentCodeFail,
  loadingCommonCostSharingDepartmentCode,
  loadingCommonSalesDepartment,
  getCommonSalesDepartmentSuccess,
  getCommonSalesDepartmentFail,
  getCommonSalesDepartmentRequest,
  loadingCommonSalesType,
  getCommonSalesTypeSuccess,
  getCommonSalesTypeFail,
  getCommonSalesTypeRequest,
  loadingCommonDepartment,
  getCommonDepartmentSuccess,
  getCommonDepartmentFail,
  getCommonDepartmentRequest,
  loadingCommonAffiliatedDepartment,
  getCommonAffiliatedDepartmentSuccess,
  getCommonAffiliatedDepartmentFail,
  getCommonAffiliatedDepartmentRequest,
  getCommonTaskTypeSuccess,
  getCommonTaskTypeFail,
  getCommonTaskTypeRequest,
  getCommonStatusFail,
  getCommonStatusSuccess,
  getCommonStatusRequest,
  loadingCommonStatus,
  loadingCommonTaskType,
} from './commonAppSlice'

const commonURL = '/common'

export const deleteAppData = (state: Object[], id: number) => {
  return produce(state, (draft) => {
    draft.find((data: any) => data.id !== id)
  })
}

export const onChangeInputTranslatorLanguageCommon = (
  target: any,
  setQuery: any
) => {
  const onChangeInputDeptReqBelongTo = (value: string) => {
    setQuery((prev: any) => {
      return {
        ...prev,
        search: value,
        url: `${commonURL}/translation-language?${getSearchURL(value)}`,
        loadingAction: loadingCommonTranslatorLanguage,
        successSlice: getCommonTranslatorLanguageSuccess,
        errorSlice: getCommonTranslatorLanguageFail,
      }
    })
  }

  if (target?.value !== undefined) {
    const value = target.value
    onChangeInputDeptReqBelongTo(value)
  }
}

export const handleFocusTranslatorLanguageCommon = (dispatch: any) => {
  const handleFocusTranslatorLanguage = () => {
    dispatch(getCommonTranslatorLanguageRequest(defaultRowsPerPage))
  }
  handleFocusTranslatorLanguage()
}

export const onChangeInputCompanyCommon = (target: any, setQuery: any) => {
  const onChangeInputCompany = (value: string) => {
    const apiUrl = '/common/company'
    setQuery((prev: any) => {
      return {
        ...prev,
        search: value,
        url: `${apiUrl}?${getSearchURL(value)}`,
        loadingAction: loadingCommonCompany,
        successSlice: getCommonCompanySuccess,
        errorSlice: getCommonCompanyFail,
      }
    })
  }

  if (target?.value !== undefined) {
    const value = target.value
    onChangeInputCompany(value)
  }
}

export const handleFocusCompanyCommon = (dispatch: any) => {
  const handleFocusCompany = () => {
    dispatch(getCommonCompanyRequest(defaultRowsPerPage))
  }
  handleFocusCompany()
}

export const onChangeInputPaymentTermsCommon = (target: any, setQuery: any) => {
  const onChangeInputInputPaymentTerms = (value: string) => {
    const apiUrl = 'common/payment-terms'
    setQuery((prev: any) => {
      return {
        ...prev,
        search: value,
        url: `${apiUrl}?${getSearchURL(value)}`,
        loadingAction: loadingCommonPaymentTerms,
        successSlice: getCommonPaymentTermsSuccess,
        errorSlice: getCommonPaymentTermsFail,
      }
    })
  }

  if (target?.value !== undefined) {
    const value = target.value
    onChangeInputInputPaymentTerms(value)
  }
}

export const handleFocusPaymentTermsCommon = (dispatch: any) => {
  const handleFocusPaymentTerms = () => {
    dispatch(getCommonPaymentTermsRequest(defaultRowsPerPage))
  }
  handleFocusPaymentTerms()
}

export const onChangeInputDeliveryStyleCommon = (
  target: any,
  setQuery: any
) => {
  const onChangeInputDeptReqBelongTo = (value: string) => {
    const apiUrl = ''

    setQuery((prev: any) => {
      return {
        ...prev,
        search: value,
        url: `${apiUrl}?${getSearchURL(value)}`,
        loadingAction: loadingCommonTranslator,
        successSlice: getCommonTranslatorSuccess,
        errorSlice: getCommonTranslatorFail,
      }
    })
  }

  if (target?.value !== undefined) {
    const value = target.value
    onChangeInputDeptReqBelongTo(value)
  }
}

export const handleFocusDeliveryStyleCommon = (dispatch: any) => {
  const handleFocusDeliveryStyle = () => {
    dispatch(getCommonDeliveryStyleRequest(defaultRowsPerPage))
  }
  handleFocusDeliveryStyle()
}
// common translator
export const onChangeInputTranslatorCommon = (target: any, setQuery: any) => {
  const onChangeInputTranslatorReq = ({ value }: any) => {
    setQuery((prev: any) => {
      return {
        ...prev,
        search: value,
        url: `${commonURL}/translator?${getSearchURL(value)}`,
        loadingAction: loadingCommonFilteredTranslatorOption,
        successSlice: getCommonFilteredTranslatorOptionSuccess,
        errorSlice: getCommonTranslatorFilterOptionFail,
      }
    })
  }
  onChangeInputTranslatorReq(target)
}

export const handleFocusTranslatorCommon = (dispatch: any) => {
  const handleFocusTranslator = () => {
    dispatch(getCommonTranslatorRequest(defaultRowsPerPage))
  }
  handleFocusTranslator()
}

// common trados
export const onChangeInputTradosCommon = (target: any, setQuery: any) => {
  const onChangeInputTradosReq = ({ value }: any) => {
    setQuery((prev: any) => {
      return {
        ...prev,
        search: value,
        url: `${commonURL}/trados?${getSearchURL(value)}`,
        loadingAction: loadingCommonTrados,
        successSlice: getCommonTradosSuccess,
        errorSlice: getCommonTradosFail,
      }
    })
  }
  onChangeInputTradosReq(target)
}

export const handleFocusTradosCommon = (dispatch: any) => {
  const handleFocusTrados = () => {
    dispatch(getCommonTradosRequest(defaultRowsPerPage))
  }
  handleFocusTrados()
}

// common task
export const onChangeInputTaskCommon = (target: any, setQuery: any) => {
  const onChangeInputTaskReq = ({ value }: any) => {
    setQuery((prev: any) => {
      return {
        ...prev,
        search: value,
        url: `${commonURL}/task?${getSearchURL(value)}`,
        loadingAction: loadingCommonTask,
        successSlice: getCommonTaskSuccess,
        errorSlice: getCommonTaskFail,
      }
    })
  }
  onChangeInputTaskReq(target)
}

export const handleFocusTaskCommon = (dispatch: any) => {
  const handleFocusTask = () => {
    dispatch(getCommonTaskRequest(defaultRowsPerPage))
  }
  handleFocusTask()
}

export const onChangeInputLayoutCommon = (target: any, setQuery: any) => {
  const onChangeInputDeptReqBelongTo = (value: string) => {
    const apiUrl = ''
    setQuery((prev: any) => {
      return {
        ...prev,
        search: value,
        url: `${apiUrl}?${getSearchURL(value)}`,
        loadingAction: loadingCommonLayoutRequest,
        successSlice: getCommonLayoutSuccess,
        errorSlice: getCommonLayoutFail,
      }
    })
  }
  if (target?.value !== undefined) {
    const value = target.value
    onChangeInputDeptReqBelongTo(value)
  }
}

export const handleFocusLayoutCommon = (dispatch: any) => {
  const handleFocusLayout = () => {
    dispatch(getCommonLayoutRequest(defaultRowsPerPage))
  }
  handleFocusLayout()
}

// common sales
export const onChangeInputSaleTaxCommon = (target: any, setQuery: any) => {
  const onChangeInputSaleTaxReq = ({ value }: any) => {
    setQuery((prev: any) => {
      return {
        ...prev,
        search: value,
        url: `${commonSaleURL}?${getSearchURL(value)}`,
        loadingAction: loadingCommonSaleTax,
        successSlice: getCommonSaleTaxSuccess,
        errorSlice: getCommonSaleTaxFail,
      }
    })
  }
  onChangeInputSaleTaxReq(target)
}

export const handleFocusSaleTaxCommon = (dispatch: any) => {
  const handleFocusSaleTax = () => {
    dispatch(getCommonSaleTaxRequest(defaultRowsPerPage))
  }
  handleFocusSaleTax()
}

//
export const onChangeInputExpenseTypeCommon = (target: any, setQuery: any) => {
  const onChangeInputExpenseTypeReq = ({ value }: any) => {
    setQuery((prev: any) => {
      return {
        ...prev,
        search: value,
        url: `${commonExpenseTypeUrl}?${getSearchURL(value)}`,
        loadingAction: loadingCommonExpenseType,
        successSlice: getCommonExpenseTypeSuccess,
        errorSlice: getCommonExpenseTypeFail,
      }
    })
  }
  onChangeInputExpenseTypeReq(target)
}
export const handleFocusExpenseTypeCommon = (dispatch: any) => {
  const handleFocusExpenseType = () => {
    dispatch(getCommonExpenseTypeRequest(defaultRowsPerPage))
  }
  handleFocusExpenseType()
}
//
export const onChangeInputIndustryTypeCommon = (target: any, setQuery: any) => {
  const onChangeInputIndustryTypeReq = ({ value }: any) => {
    setQuery((prev: any) => {
      return {
        ...prev,
        search: value,
        url: `${commonIndustryTypeUrl}?${getSearchURL(value)}`,
        loadingAction: loadingCommonIndustryType,
        successSlice: getCommonIndustryTypeSuccess,
        errorSlice: getCommonIndustryTypeFail,
      }
    })
  }
  onChangeInputIndustryTypeReq(target)
}

export const handleFocusIndustryTypeCommon = (dispatch: any) => {
  const handleFocusIndustryType = () => {
    dispatch(getCommonIndustryTypeRequest(defaultRowsPerPage))
  }
  handleFocusIndustryType()
}

//
export const onChangeInputDocumentCommon = (target: any, setQuery: any) => {
  const onChangeInputDocumentReq = ({ value }: any) => {
    setQuery((prev: any) => {
      return {
        ...prev,
        search: value,
        url: `${commonDocumentUrl}?${getSearchURL(value)}`,
        loadingAction: loadingCommonDocument,
        successSlice: getCommonDocumentSuccess,
        errorSlice: getCommonDocumentFail,
      }
    })
  }
  onChangeInputDocumentReq(target)
}
export const handleFocusDocumentCommon = (dispatch: any) => {
  const handleFocusDocument = () => {
    dispatch(getCommonDocumentRequest(defaultRowsPerPage))
  }
  handleFocusDocument()
}
//
export const onChangeInputTranslationSubjectCommon = (
  target: any,
  setQuery: any
) => {
  const onChangeInputTranslationSubjectReq = ({ value }: any) => {
    setQuery((prev: any) => {
      return {
        ...prev,
        search: value,
        url: `${commonTranslationSubjectUrl}?${getSearchURL(value)}`,
        loadingAction: loadingCommonTranslationSubject,
        successSlice: getCommonTranslationSubjectSuccess,
        errorSlice: getCommonTranslationSubjectFail,
      }
    })
  }
  onChangeInputTranslationSubjectReq(target)
}

export const handleFocusTranslationSubjectCommon = (dispatch: any) => {
  const handleFocusTranslationSubject = () => {
    dispatch(getCommonTranslationSubjectRequest(defaultRowsPerPage))
  }
  handleFocusTranslationSubject()
}

//
export const onChangeInputExportRestrictionCommon = (
  target: any,
  setQuery: any
) => {
  const onChangeInputExportRestrictionReq = ({ value }: any) => {
    setQuery((prev: any) => {
      return {
        ...prev,
        search: value,
        url: `${commonExportRestrictionUrl}?${getSearchURL(value)}`,
        loadingAction: loadingCommonExportRestriction,
        successSlice: getCommonExportRestrictionSuccess,
        errorSlice: getCommonExportRestrictionFail,
      }
    })
  }
  onChangeInputExportRestrictionReq(target)
}

export const handleFocusExportRestrictionCommon = (dispatch: any) => {
  const handleFocusExportRestriction = () => {
    dispatch(getCommonExportRestrictionRequest(defaultRowsPerPage))
  }
  handleFocusExportRestriction()
}
//
export const onChangeInputJobCommon = (target: any, setQuery: any) => {
  const onChangeInputJobReq = ({ value }: any) => {
    setQuery((prev: any) => {
      return {
        ...prev,
        search: value,
        url: `${commonJobUrl}?${getSearchURL(value)}`,
        loadingAction: loadingCommonJob,
        successSlice: getCommonJobSuccess,
        errorSlice: getCommonJobFail,
      }
    })
  }
  onChangeInputJobReq(target)
}
export const handleFocusJobCommon = (dispatch: any) => {
  const handleFocusJob = () => {
    dispatch(getCommonJobRequest(defaultRowsPerPage))
  }
  handleFocusJob()
}

//
//
export const onChangeInputUserCommon = (target: any, setQuery: any) => {
  const onChangeInputUserReq = ({ value }: any) => {
    setQuery((prev: any) => {
      return {
        ...prev,
        search: value,
        url: `${commonUserModUrl}?${getSearchURL(value)}`,
        loadingAction: loadingCommonUser,
        successSlice: getCommonUserSuccess,
        errorSlice: getCommonUserFail,
      }
    })
  }

  onChangeInputUserReq(target)
}
export const handleFocusUserCommon = (dispatch: any) => {
  const handleFocusUser = () => {
    dispatch(getCommonUserRequest(defaultRowsPerPage))
  }
  handleFocusUser()
}
//
export const onChangeInputCostSharingDepartmentCodeCommon = (
  target: any,
  setQuery: any
) => {
  const onChangeInputCostSharingDepartmentCodeReq = ({ value }: any) => {
    setQuery((prev: any) => {
      return {
        ...prev,
        search: value,
        url: `${commonCostSharingDepartmentCodeModUrl}?${getSearchURL(value)}`,
        loadingAction: loadingCommonCostSharingDepartmentCode,
        successSlice: getCommonCostSharingDepartmentCodeSuccess,
        errorSlice: getCommonCostSharingDepartmentCodeFail,
      }
    })
  }
  onChangeInputCostSharingDepartmentCodeReq(target)
}
export const handleFocusCostSharingDepartmentCodeCommon = (dispatch: any) => {
  const handleFocusCostSharingDepartmentCode = () => {
    dispatch(getCommonCostSharingDepartmentCodeRequest(defaultRowsPerPage))
  }
  handleFocusCostSharingDepartmentCode()
}

//
export const onChangeInputSalesDepartmentCommon = (
  target: any,
  setQuery: any
) => {
  const onChangeInputSalesDepartmentReq = ({ value }: any) => {
    setQuery((prev: any) => {
      return {
        ...prev,
        search: value,
        url: `${commonSalesDepartmentModUrl}?${getSearchURL(value)}`,
        loadingAction: loadingCommonSalesDepartment,
        successSlice: getCommonSalesDepartmentSuccess,
        errorSlice: getCommonSalesDepartmentFail,
      }
    })
  }
  onChangeInputSalesDepartmentReq(target)
}
export const handleFocusSalesDepartmentCommon = (dispatch: any) => {
  const handleFocusSalesDepartment = () => {
    dispatch(getCommonSalesDepartmentRequest(defaultRowsPerPage))
  }
  handleFocusSalesDepartment()
}

export const onChangeInputSalesTypeCommon = (target: any, setQuery: any) => {
  const onChangeInputSalesTypeReq = ({ value }: any) => {
    setQuery((prev: any) => {
      return {
        ...prev,
        search: value,
        url: `${commonSalesTypeModUrl}?${getSearchURL(value)}`,
        loadingAction: loadingCommonSalesType,
        successSlice: getCommonSalesTypeSuccess,
        errorSlice: getCommonSalesTypeFail,
      }
    })
  }
  onChangeInputSalesTypeReq(target)
}
export const handleFocusSalesTypeCommon = (dispatch: any) => {
  const handleFocusSalesType = () => {
    dispatch(getCommonSalesTypeRequest(defaultRowsPerPage))
  }
  handleFocusSalesType()
}

//common department
export const onChangeInputDepartmentCommon = (target: any, setQuery: any) => {
  const onChangeInputDepartmentReq = ({ value }: any) => {
    setQuery((prev: any) => {
      return {
        ...prev,
        search: value,
        url: `${commonDepartmentModUrl}?${getSearchURL(value)}`,
        loadingAction: loadingCommonDepartment,
        successSlice: getCommonDepartmentSuccess,
        errorSlice: getCommonDepartmentFail,
      }
    })
  }
  onChangeInputDepartmentReq(target)
}
export const handleFocusDepartmentCommon = (dispatch: any) => {
  const handleFocusDepartment = () => {
    dispatch(getCommonDepartmentRequest(defaultRowsPerPage))
  }
  handleFocusDepartment()
}
//common affiliated department
export const onChangeInputAffiliatedDepartmentCommon = (
  target: any,
  setQuery: any
) => {
  const onChangeInputAffiliatedDepartmentReq = ({ value }: any) => {
    setQuery((prev: any) => {
      return {
        ...prev,
        search: value,
        url: `${commonAffiliatedDepartmentModUrl}?${getSearchURL(value)}`,
        loadingAction: loadingCommonAffiliatedDepartment,
        successSlice: getCommonAffiliatedDepartmentSuccess,
        errorSlice: getCommonAffiliatedDepartmentFail,
      }
    })
  }
  onChangeInputAffiliatedDepartmentReq(target)
}
export const handleFocusAffiliatedDepartmentCommon = (dispatch: any) => {
  const handleFocusAffiliatedDepartment = () => {
    dispatch(getCommonAffiliatedDepartmentRequest(defaultRowsPerPage))
  }
  handleFocusAffiliatedDepartment()
}

//common task types
export const onChangeInputTaskTypeCommon = (target: any, setQuery: any) => {
  const onChangeInputTaskTypeReq = ({ value }: any) => {
    setQuery((prev: any) => {
      return {
        ...prev,
        search: value,
        url: `${commonTaskTypeModUrl}?${getSearchURL(value)}`,
        loadingAction: loadingCommonTaskType,
        successSlice: getCommonTaskTypeSuccess,
        errorSlice: getCommonTaskTypeFail,
      }
    })
  }
  onChangeInputTaskTypeReq(target)
}
export const handleFocusTaskTypeCommon = (dispatch: any) => {
  const handleFocusTaskType = () => {
    dispatch(getCommonTaskTypeRequest(defaultRowsPerPage))
  }
  handleFocusTaskType()
}

//common status
export const onChangeInputStatusCommon = (target: any, setQuery: any) => {
  const onChangeInputStatusReq = ({ value }: any) => {
    setQuery((prev: any) => {
      return {
        ...prev,
        search: value,
        url: `${commonStatusModUrl}?${getSearchURL(value)}`,
        loadingAction: loadingCommonStatus,
        successSlice: getCommonStatusSuccess,
        errorSlice: getCommonStatusFail,
      }
    })
  }
  onChangeInputStatusReq(target)
}
export const handleFocusStatusCommon = (dispatch: any) => {
  const handleFocusStatus = () => {
    dispatch(getCommonStatusRequest(defaultRowsPerPage))
  }
  handleFocusStatus()
}
