import * as React from 'react'

import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'

import CloseIcon from '@mui/icons-material/Close'

import './ModalStyles.css'

const ModalTitle = (props: any) => {
  const { children, onClose, ...other } = props

  const closeButtonStyle: React.CSSProperties = {
    position: 'absolute',
    top: '12px',
    right: '12px',
    color: '#fff',
  }

  return (
    <DialogTitle
      className="modal-title"
      {...other}
      style={{ borderRadius: '2px' }}
    >
      <div>{children}</div>
      {onClose && (
        <div style={closeButtonStyle}>
          <IconButton aria-label="close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
      )}
    </DialogTitle>
  )
}

export default React.memo(ModalTitle)
