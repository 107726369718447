export const WORKFLOW_STATUS = {
  PENDING: 1,
  APPROVING: 2,
  APPROVED: 3,
  REJECTED: 4,
  CANCELLED: 5,
}

export const WORKFLOW_STAGE = {
  INT: 1,
  CLIENT: 2,
}

export const WORKFLOW_ACTION = {
  APPLICATE: 'applicate',
  UNAPPLICATE: 'unapplicate',
  APPROVE: 'approve',
  REJECT: 'reject',
  CANCEL: 'cancel',
}

export const WORKFLOW_DETAIL_STATUS = {
  TERMINATED: 0,
  PENDING: 1,
  APPLICATED: 2,
  APPROVED: 3,
  REJECTED: 4,
  CANCELLED: 5,
}
