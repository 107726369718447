import enTranslations from './Translations/en'
import jaTranslations from './Translations/ja'

const translations = {
  en: enTranslations,
  ja: jaTranslations,
  // Add more languages if needed
}

export default translations
