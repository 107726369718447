import React from 'react'

function formatCurrencyWithSymbol(amount: number) {
  if (typeof amount !== 'number') {
    throw new Error('Invalid input. Please provide a valid number.')
  }

  return amount.toLocaleString(undefined, {
    style: 'currency',
    currency: 'JPY', // Change this to the desired currency code (e.g., 'EUR', 'GBP', 'JPY', etc.)
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })
}
function formatCurrencyNumber(amount: any) {
  const updateInNumber = Number(amount)
  if (typeof updateInNumber !== 'number' || isNaN(updateInNumber)) {
    // throw new Error('Invalid input. Please provide a valid number.');
  }
  const hasDecimalPlaces = updateInNumber % 1 !== 0
  const formatOptions = {
    minimumFractionDigits: hasDecimalPlaces ? 0 : 0,
    maximumFractionDigits: hasDecimalPlaces ? 0 : 0,
  }
  return updateInNumber.toLocaleString(undefined, formatOptions)
}

const formateNumberAgain = (amount: any) => {
  const numericAmount =
    typeof amount === 'string' ? amount.replace(/[^0-9.]/g, '') : amount
  return numericAmount
}
interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void
  name: string
}

const dateFormatter = (date: string | Date | null): string | null => {
  console.log(date,"datedatedatedate")
  //@ts-ignore
  if (date && !isNaN(Date.parse(date))) {
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }
    const deliveryDate = new Intl.DateTimeFormat('ja', options).format(
      typeof date === 'string' ? new Date(date) : date
    )
    return deliveryDate.replace(/\//g, '-')
  } else {
    return null
  }
}

function formatDateTimestamp(isoString: string) {
  const date = new Date(isoString)

  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')
  const hours = String(date.getHours()).padStart(2, '0')
  const minutes = String(date.getMinutes()).padStart(2, '0')
  const seconds = String(date.getSeconds()).padStart(2, '0')

  return `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`
}

function stringToBinary(urlString: string): string {
  let binaryString = ''
  for (let i = 0; i < urlString.length; i++) {
    const charCode = urlString.charCodeAt(i)
    const binary = charCode.toString(2).padStart(8, '0')
    binaryString += binary
  }
  return binaryString
}

export {
  stringToBinary,
  formatDateTimestamp,
  formatCurrencyWithSymbol,
  formatCurrencyNumber,
  formateNumberAgain,
  dateFormatter,
}
