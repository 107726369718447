import { memo } from 'react'

import { Card, CardHeader, Typography } from '@mui/material'

interface CardTitleProps {
  name: string
}
const CardTitle = ({ name }: CardTitleProps) => {
  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'white' }}>
            {name}
          </Typography>
        }
        sx={{
          backgroundColor: '#062f96',
          height: '48px',
        }}
      />
    </Card>
  )
}
export default memo(CardTitle)
