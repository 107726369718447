import React, { useState, Suspense, useEffect, useRef, lazy } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import { Card, CardHeader, Grid, Typography } from '@mui/material'

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AnyAction } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import {
  handleFocusCompanyCommon,
  handleFocusCostSharingDepartmentCodeCommon,
  handleFocusExpenseTypeCommon,
  handleFocusExportRestrictionCommon,
  handleFocusSaleTaxCommon,
  onChangeInputCompanyCommon,
  onChangeInputCostSharingDepartmentCodeCommon,
  onChangeInputExpenseTypeCommon,
  onChangeInputExportRestrictionCommon,
} from '../../../CommonAppRedux/commonAppFunctions'
import {
  alertErrorAction,
  getCommonPaymentTermsRequest,
} from '../../../CommonAppRedux/commonAppSlice'
import { commonAppSelector } from '../../../CommonAppRedux/selector'
import { commonOptionType } from '../../../CustomAlert/types'
import { authSelector } from '../../../Login/Redux/selector'
import AppAutoComplete from '../../../ReusableComponent/AppAutoComplete/AppAutoComplete'
import AppDatePicker from '../../../ReusableComponent/AppDatePicker/AppDatePicker'
import AppTextField from '../../../ReusableComponent/AppTextField/AppTextField'
import {
  useAppDispatch,
  useAppSelector,
} from '../../../ReusableComponent/CustomHooks/appHooks'
import { useInputSearch } from '../../../ReusableComponent/CustomHooks/useInputSearch'
import FocusElementOnInvalidValidation from '../../../ReusableComponent/FocusElementIfInvalid/FocusElementOnInvalidValidation'
import History from '../../../ReusableComponent/History'
import IssueBarComponent from '../../../ReusableComponent/IssueBarComponent/IssueBarComponent'
import ReasonForChange from '../../../ReusableComponent/ReasonForChange/ReasonForChange'
import SaveFooterComponent from '../../../ReusableComponent/SaveFooterButton/SaveFooterButton'
import {
  dateFormatter,
  formateNumberAgain,
} from '../../../Utils/AppFormatFunction'
import { todayDate } from '../../../Utils/AppFunction'
import messages, { maxCharValidation } from '../../../Utils/ValidationMessage'
import {
  defaultRowsPerPage,
  selectTypePaymentTerms,
} from '../../../Utils/globalConstant'
import { query } from '../../../Utils/globalTypes'
import { company, saleTax } from '../../JobList/Types'
import {
  clearDataProjectDetails,
  clearHardResetProjectList,
  clearOrderReceipt,
  clearQuotation,
  clearQuotationDetailDraft,
  createOrderReceiptRequest,
  getOrderReceiptByIdRequest,
  getProjectDetailByIdRequest,
  getQuotationByIdRequest,
  getQuotationDetailDraftByIdRequest,
  updateOrderReceiptRequest,
} from '../Redux/projectListSlice'
import { projectListSelector } from '../Redux/selector'
import OrderRecept, {
  orderReceptUpload,
} from '../UploadReceptLayout/OrderReceptUpload'
import { taxSubTotalProps } from './IssueQuotation'

const CommonReportTable = lazy(
  () => import('../../../ReusableComponent/CommonReportTable/CommonReportTable')
)

export interface order_receipt_details {
  uniqueId?: string
  item_name: string
  quantity: string | number
  unit_cost: string | number
  sales_tax: saleTax | null
  amount: string | number
  tax_amount: number | string
}

export interface OrderReceipt {
  id?: number
  version?: number
  quotation?: number
  order_receipt_no: string
  history?: any[]
  quotation_date: string | Date | null
  company: company | null
  title: string
  reason: string
  comment: string
  issue_flag?: boolean
  case?: any
  case_no: number | null
  cost_sharing_department_code: any
  production_order_no: string
  export_restriction: any
  expense_type: AnyAction
  due_date: string | Date | null
  payment_terms: any
  remarks: string
  order_receipt_details: order_receipt_details[]
  order_receipt_acceptance?: any
  workflow: number
  issue_date: string | Date | null
  tax_subtotal: taxSubTotalProps[]
  total_amount: any
  shimadzu_report_flag?: boolean
  unwithdrawed_usr_md_receipt?: number | string
  usr_md_receipt?: orderReceptUpload | null
}

const IssueOrderReceipt = () => {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const caseId = searchParams.get('case-id')
  const orderReceiptId = searchParams.get('order-receipt-id')
  const edit = searchParams.get('edit')
  const { state } = location
  const previousUrl = state?.path
  const {
    orderReceipt,
    projectDetail,
    issueQuotation,
    loadingCreateOrderReceipt,
    issueQuotationDetailDraft,
  } = useAppSelector(projectListSelector)
  const { t } = useTranslation()
  const {
    commonSalesTax,
    defaultSalesTax,
    commonCompanies,
    loadingCommonCompany,
    commonCostSharingDepartmentCodes,
    commonExportRestrictions,
    commonExpenseTypes,
  } = useAppSelector(commonAppSelector)
  const dispatch = useAppDispatch()
  useEffect(() => {
    if (caseId) {
      dispatch(getProjectDetailByIdRequest(caseId))
    } else {
      dispatch(clearDataProjectDetails())
      dispatch(clearHardResetProjectList())
    }
    dispatch(getCommonPaymentTermsRequest(defaultRowsPerPage))
    handleFocusSaleTaxCommon(dispatch)
    return () => {
      dispatch(clearHardResetProjectList())
    }
  }, [caseId, location, dispatch, edit])
  useEffect(() => {
    if (edit) {
      dispatch(getOrderReceiptByIdRequest(orderReceiptId))
    } else if (projectDetail?.case_report_relation?.quotation) {
      dispatch(
        getQuotationByIdRequest(projectDetail?.case_report_relation?.quotation)
      )
    } else {
      dispatch(clearOrderReceipt())
      dispatch(clearQuotation())
    }
    if (projectDetail?.without_quotation_flag && !edit) {
      dispatch(getQuotationDetailDraftByIdRequest(caseId))
    } else {
      dispatch(clearQuotationDetailDraft())
    }
  }, [projectDetail, edit, orderReceiptId, dispatch, caseId])

  const updatedOrderReceiptDetailsData =
    orderReceipt?.order_receipt_details?.map((details: any) => {
      const matchingSubtotal = orderReceipt?.tax_subtotal?.find(
        (subtotal: any) => {
          return subtotal.sales_tax.id === details.sales_tax.id
        }
      )
      return {
        ...details,
        sub_total_id: matchingSubtotal?.id ? matchingSubtotal?.id : undefined,
      }
    })

  const updateIssueQuotationDetailDraft = issueQuotationDetailDraft?.map(
    (row: any) => {
      return {
        item_name: row?.item_name,
        quantity: row?.quantity,
        unit_cost: row?.unit_cost,
        sales_tax: row?.sales_tax,
        amount: row?.amount,
        tax_amount: row?.tax_amount,
      }
    }
  )

  const initialState: OrderReceipt = {
    version: orderReceipt?.version,
    order_receipt_no: orderReceipt ? orderReceipt?.order_receipt_no : '',
    quotation_date: orderReceipt?.quotation_date
      ? orderReceipt?.quotation_date
      : todayDate,
    issue_date: orderReceipt ? orderReceipt?.issue_date : todayDate,
    due_date: orderReceipt?.due_date
      ? orderReceipt?.due_date
      : issueQuotation?.due_date ??
        dayjs(projectDetail?.desired_delivery_date).format('YYYY年MM月DD日') ??
        '',
    company: orderReceipt
      ? orderReceipt?.company
      : issueQuotation?.company ?? projectDetail?.request_company ?? null,
    title: orderReceipt
      ? orderReceipt?.title
      : issueQuotation?.title ?? projectDetail?.case_title ?? '',
    cost_sharing_department_code: orderReceipt
      ? orderReceipt?.cost_sharing_department_code
      : issueQuotation?.cost_sharing_department_code ??
        projectDetail?.cost_sharing_department_code1 ??
        null,
    production_order_no: orderReceipt
      ? orderReceipt?.production_order_no
      : issueQuotation?.production_order_no ??
        projectDetail?.production_order_no1 ??
        '',
    export_restriction: orderReceipt
      ? orderReceipt?.export_restriction
      : issueQuotation?.export_restriction ??
        projectDetail?.export_restriction ??
        null,
    payment_terms: orderReceipt
      ? orderReceipt?.payment_terms
      : issueQuotation?.payment_terms
      ? issueQuotation?.payment_terms
      : projectDetail?.request_company?.shimadzu_report_flag
      ? selectTypePaymentTerms[0]?.name
      : selectTypePaymentTerms[1]?.name ?? 'null',
    expense_type: orderReceipt
      ? orderReceipt?.expense_type
      : issueQuotation?.expense_type ?? projectDetail?.expense_type ?? null,
    remarks: orderReceipt
      ? orderReceipt?.remarks
      : issueQuotation?.remarks ?? '',
    case_no: orderReceipt
      ? orderReceipt?.case_no
      : issueQuotation?.case_no ?? projectDetail?.case_no ?? null,
    comment: '',
    reason: '',
    workflow: orderReceipt ? orderReceipt.workflow : 0,
    total_amount: orderReceipt?.total_amount ?? 0,
    tax_subtotal: orderReceipt
      ? orderReceipt?.tax_subtotal
      : issueQuotation?.tax_subtotal ?? [
          {
            sales_tax: null,
            subtotal_amount: 0,
            amount: 0,
          },
        ],
    order_receipt_details: updatedOrderReceiptDetailsData
      ? updatedOrderReceiptDetailsData
      : issueQuotation?.quotation_details
      ? issueQuotation?.quotation_details
      : projectDetail?.without_quotation_flag &&
        updateIssueQuotationDetailDraft?.length > 0
      ? updateIssueQuotationDetailDraft
      : [
          {
            item_name: '',
            quantity: 0,
            unit_cost: 0,
            sales_tax: defaultSalesTax,
            amount: 0,
            tax_amount: 0,
          },
        ],
  }

  const validationSchema = Yup.object().shape({
    quotation_date: Yup.mixed().required(t(messages.required)),
    production_order_no: Yup.string().max(20, maxCharValidation(20)),
    issue_date: Yup.mixed().required(t(messages.required)),
    title: Yup.string().max(100, maxCharValidation(100)),
    payment_terms: Yup.string().required(t(messages.required)),
    due_date: Yup.string()
      .required(t(messages.required))
      .max(100, maxCharValidation(100)),
    export_restriction: Yup.object().required(t(messages.required)),
    expense_type: Yup.object().required(t(messages.required)),
    order_receipt_details: Yup.array().of(
      Yup.object().shape({
        item_name: Yup.string()
          .required(t(messages.required))
          .max(100, maxCharValidation(100)),
        quantity: Yup.mixed().required(t(messages.required)),
        unit_cost: Yup.mixed().required(t(messages.required)),
        sales_tax: Yup.object().required(t(messages.required)),
        amount: Yup.mixed().required(t(messages.required)),
      })
    ),
  })

  const columns = [
    {
      id: 'item_name',
      label: `${t('orderReceiptIssuance.tableItems.itemName')}`,
      minWidth: 100,
    },
    {
      id: 'quantity',
      label: `${t('orderReceiptIssuance.tableItems.quantity')}`,
      minWidth: 50,
      qty: true,
      currency: true,
    },
    {
      id: 'unit_cost',
      label: `${t('orderReceiptIssuance.tableItems.unitCost')}`,
      minWidth: 50,
      currency: true,
      cost: true,
    },
    {
      id: 'sales_tax',
      label: `${t('orderReceiptIssuance.tableItems.saleTax')}`,
      minWidth: 350,
      dropDown: true,
      options: commonSalesTax,
    },
    {
      id: 'amount',
      label: `${t('orderReceiptIssuance.tableItems.amount')}`,
      currency: true,
      minWidth: 100,
      amount: true,
    },
    {
      id: 'actions',
      label: `${t('common.actions')}`,
      minWidth: 100,
      actions: true,
    },
  ]

  const [query, setQuery] = useState<query>({
    search: '',
    url: '',
  })
  useInputSearch(query)

  const navigate = useNavigate()

  const onSubmit = async (values: OrderReceipt, actions: any) => {
    const {
      company,
      export_restriction,
      cost_sharing_department_code,
      due_date,
      order_receipt_details,
      tax_subtotal,
      expense_type,
    } = values

    const updatedTaxSubTotal = tax_subtotal?.map((row: any) => ({
      ...row,
      sales_tax: row?.sales_tax?.id,
    }))
    const updatedOrderReceipt = order_receipt_details?.map((details: any) => ({
      ...details,
      sales_tax: details?.sales_tax?.id,
      amount: formateNumberAgain(details?.amount),
      unit_cost: formateNumberAgain(details?.unit_cost),
      tax_amount: formateNumberAgain(details.tax_amount),
    }))

    if (edit) {
      const id = orderReceipt?.id
      const updatedValues = {
        ...values,
        case: caseId,
        quotation: projectDetail?.case_report_relation?.quotation ?? null,
        export_restriction: export_restriction?.id ?? '',
        cost_sharing_department_code: cost_sharing_department_code?.id ?? '',
        company: company ? company?.id : null,
        due_date: due_date !== '' ? due_date : null,
        payment_terms_id: company?.payment_terms,
        expense_type: expense_type?.id ?? '',
        order_receipt_details: updatedOrderReceipt,
        tax_subtotal: updatedTaxSubTotal,
      }
      dispatch(
        updateOrderReceiptRequest({
          id,
          values: updatedValues,
          nav: navigate,
          actions,
          previousUrl,
        })
      )
    } else {
      const updatedValues = {
        ...values,
        case: caseId,
        quotation: projectDetail?.case_report_relation?.quotation ?? null,
        export_restriction: export_restriction?.id ?? '',
        cost_sharing_department_code: cost_sharing_department_code?.id ?? '',
        company: company ? company?.id : '',
        payment_terms_id: company ? company?.payment_terms : '',
        order_receipt_details: updatedOrderReceipt,
        tax_subtotal: updatedTaxSubTotal,
        expense_type: expense_type?.id ?? '',
      }
      dispatch(
        createOrderReceiptRequest({
          values: updatedValues,
          nav: navigate,
          actions,
          previousUrl,
        })
      )
    }
  }

  const focus_save_btn = useRef(null)
  const [readOnlyMode, setReadOnlyMode] = useState(false)
  const { is_staff } = useAppSelector(authSelector)
  useEffect(() => {
    if (!is_staff) {
      setReadOnlyMode(true)
    } else if (
      ['030', '050'].includes(projectDetail?.status?.code) === false &&
      !(
        projectDetail?.status?.code === '010' &&
        projectDetail?.without_quotation_flag
      )
    ) {
      setReadOnlyMode(true)
    }
    return () => {
      setReadOnlyMode(false)
    }
  }, [is_staff, projectDetail])

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialState}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          values,
          handleChange,
          setFieldValue,
          handleBlur,
          errors,
          submitForm,
          touched,
        }) => {
          const handlePreview = () => {
            const url = 'order-receipt-report'
            const queryParams = `?id=${orderReceiptId}&preview-report-url=${url}&case-id=${
              projectDetail?.id
            }&file-name=${orderReceipt?.order_receipt_no}&active-send=${true}`
            navigate(`/preview${queryParams}`)
          }
          const handleSave = () => {
            submitForm()
          }

          const handleOrderReceiptUpload = () => {
            if (projectDetail) {
              const { case_report_relation, usermade_order_receipt } =
                projectDetail
              const purchaseOrderReceiptId = case_report_relation?.order_receipt
              if (usermade_order_receipt) {
                const queryParams = `?purchase-order-receipt-id=${purchaseOrderReceiptId}&edit=${true}`
                navigate(`/order-receipt-upload${queryParams}`, {
                  state: { path: location?.pathname + location?.search },
                })
              } else if (purchaseOrderReceiptId) {
                const queryParams = `?purchase-order-receipt-id=${purchaseOrderReceiptId}`
                navigate(`/order-receipt-upload${queryParams}`, {
                  state: { path: location?.pathname + location?.search },
                })
              } else {
                dispatch(
                  alertErrorAction(
                    t('customAlertMessage.createOrderReceiptFirst')
                  )
                )
              }
            }
          }
          const handleBackToCase = () => {
            const queryParams = `?id=${projectDetail?.id}&edit=${true}`
            navigate(`/create-agency-project${queryParams}`, {
              state: { path: location?.pathname + location?.search },
            })
          }
          const buttonConfigs = [
            ...(is_staff &&
            (['030', '050'].includes(projectDetail?.status?.code) ||
              (projectDetail?.status?.code === '010' &&
                projectDetail?.without_quotation_flag))
              ? [
                  {
                    label: `${edit ? 'common.update' : 'common.save'}`,
                    handler: handleSave,
                    isShowAlert: true,
                    focusBtn: focus_save_btn,
                    loading: loadingCreateOrderReceipt,
                    message: edit
                      ? t('confirmMessage.update')
                      : t('confirmMessage.save'),
                  },
                ]
              : []),

            ...((
              edit
                ? ['030', '050', '060', '070', '080', '090'].includes(
                    projectDetail?.status?.code
                  ) ||
                  (projectDetail?.status?.code === '010' &&
                    projectDetail?.without_quotation_flag)
                : [''].includes(projectDetail?.status?.code)
            )
              ? [
                  {
                    label: 'common.preview',
                    handler: handlePreview,
                  },
                ]
              : []),
            ...(projectDetail?.case_report_relation?.order_receipt &&
            is_staff &&
            ['030', '050', '060', '070', '080', '090'].includes(
              projectDetail?.status?.code
            )
              ? [
                  {
                    label: 'uploadRecept.orderReceipt.name',
                    handler: handleOrderReceiptUpload,
                  },
                ]
              : []),
            {
              label: 'common.backToCase',
              handler: handleBackToCase,
            },
          ]

          const handleButtonAction = (action: string) => {
            const buttonConfig = buttonConfigs.find(
              (config) => config.label === action
            )
            if (buttonConfig) {
              const { handler } = buttonConfig
              handler()
            }
          }

          return (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid item sx={{ mb: 4 }}>
                <SaveFooterComponent
                  handleClick={handleButtonAction}
                  buttonConfigs={buttonConfigs}
                />
              </Grid>
              {orderReceipt && orderReceipt?.unwithdrawed_usr_md_receipt ? (
                <OrderRecept
                  showInReportScreen={orderReceipt.unwithdrawed_usr_md_receipt}
                />
              ) : (
                <div className={readOnlyMode ? 'disabled-form' : ''}>
                  {edit && is_staff && (
                    <ReasonForChange
                      name="reason"
                      setFieldValue={setFieldValue}
                      onChange={handleChange}
                    />
                  )}
                  <IssueBarComponent isIssued={orderReceipt?.issue_flag} />
                  <Form autoComplete="off" noValidate>
                    <Card sx={{ mb: 2 }}>
                      <CardHeader
                        title={
                          <Typography
                            variant="h6"
                            sx={{ fontWeight: 'bold', color: 'white' }}
                          >
                            {t('orderReceiptIssuance.name')}
                          </Typography>
                        }
                        sx={{
                          backgroundColor: '#062f96',
                          height: '48px',
                        }}
                      />
                      <h1 style={{ textAlign: 'center' }}>
                        {t('orderReceiptIssuance.name')}
                      </h1>
                      <Grid container padding={2} alignItems="center">
                        <Grid item xs={6}>
                          <Grid container alignItems="center" mb={0}>
                            <Grid item xs={3.5}>
                              {t('orderReceiptIssuance.orderReceiptNumber')}:
                            </Grid>
                            <AppTextField
                              xs={5}
                              disabled
                              name="order_receipt_no"
                              value={values?.order_receipt_no}
                              label={t(
                                'orderReceiptIssuance.orderReceiptNumber'
                              )}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Grid>
                          <Grid container alignItems="center" mb={0} mt={2.5}>
                            <Grid item xs={3.5}>
                              {t('orderReceiptIssuance.caseNo')}:
                            </Grid>
                            <AppTextField
                              xs={5}
                              disabled
                              name="case_no"
                              value={values?.case_no ?? ''}
                              label={t('orderReceiptIssuance.caseNo')}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Grid>
                          <Grid container alignItems="center">
                            <Grid item xs={3.5}>
                              {t('orderReceiptIssuance.issueDate')}:
                            </Grid>
                            <AppDatePicker
                              id={t('orderReceiptIssuance.issueDate')}
                              xs={5}
                              disabled={readOnlyMode}
                              name="issue_date"
                              value={values?.issue_date}
                              onChange={(value: any) => {
                                if (value !== null) {
                                  setFieldValue(
                                    `issue_date`,
                                    dateFormatter(value)
                                  )
                                } else {
                                  setFieldValue(`issue_date`, '')
                                }
                              }}
                              variant="outlined"
                              mt
                              sx={{
                                width: '100%',
                                size: 'small',
                                '& .MuiInputBase-root': {
                                  height: '39px',
                                  display: 'flex',
                                  alignItems: 'center',
                                },
                                '& .MuiInputLabel-root': {
                                  textAlign: 'center',
                                },
                              }}
                            />
                          </Grid>
                        </Grid>
                        <Grid item xs={6}>
                          <Grid container alignItems="center" mt={2.5}>
                            <Grid item xs={3}>
                              {t('quotation.dueDate')}:
                            </Grid>
                            <AppTextField
                              id={t('quotation.dueDate')}
                              xs={4}
                              name="due_date"
                              // required
                              disabled={readOnlyMode}
                              label={t('quotation.dueDate')}
                              value={values?.due_date}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container alignItems="center" mt={2.5}>
                            <Grid item xs={1.8}>
                              {t('orderReceiptIssuance.company')}:
                            </Grid>
                            <AppAutoComplete
                              xs={2.5}
                              disabled={readOnlyMode}
                              name="company"
                              value={values?.company}
                              options={commonCompanies}
                              loading={loadingCommonCompany}
                              getOptionLabel={(option: commonOptionType) =>
                                option.name
                              }
                              label={t('orderReceiptIssuance.company')}
                              onChange={(event: any, value: any) => {
                                if (value !== null) {
                                  setFieldValue('company', value)
                                  setFieldValue(
                                    'payment_terms',
                                    value?.shimadzu_report_flag
                                      ? selectTypePaymentTerms[0]?.name
                                      : selectTypePaymentTerms[1]?.name
                                  )
                                } else {
                                  setFieldValue('company', null)
                                }
                              }}
                              onFocus={() => handleFocusCompanyCommon(dispatch)}
                              onBlur={handleBlur}
                              inputOnchange={(e: any) =>
                                onChangeInputCompanyCommon(e.target, setQuery)
                              }
                            />
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container alignItems="center" mt={2.5}>
                            <Grid item xs={1.8}>
                              {t('orderReceiptIssuance.title')}:
                            </Grid>
                            <AppTextField
                              xs={2.5}
                              name="title"
                              disabled={readOnlyMode}
                              label={t('orderReceiptIssuance.title')}
                              value={values?.title}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container alignItems="center" mt={2.5}>
                            <Grid item xs={1.8}>
                              {t('orderReceiptIssuance.costSharingDepartment')}:
                            </Grid>

                            <AppAutoComplete
                              xs={2.5}
                              disabled={readOnlyMode}
                              options={commonCostSharingDepartmentCodes}
                              value={
                                values?.cost_sharing_department_code ?? null
                              }
                              getOptionLabel={(option: any) => option.name}
                              label={t(
                                'orderReceiptIssuance.costSharingDepartment'
                              )}
                              name="cost_sharing_department_code"
                              onChange={(_: object, value: any) => {
                                if (value !== null) {
                                  setFieldValue(
                                    `cost_sharing_department_code`,
                                    value
                                  )
                                } else {
                                  setFieldValue(
                                    `cost_sharing_department_code`,
                                    null
                                  )
                                }
                              }}
                              onFocus={() =>
                                handleFocusCostSharingDepartmentCodeCommon(
                                  dispatch
                                )
                              }
                              onBlur={handleBlur}
                              inputOnchange={(e: any) =>
                                onChangeInputCostSharingDepartmentCodeCommon(
                                  e.target,
                                  setQuery
                                )
                              }
                            />
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container alignItems="center" mt={2.5}>
                            <Grid item xs={1.8}>
                              {t('orderReceiptIssuance.productionOrderNo')}:
                            </Grid>
                            <AppTextField
                              xs={2.5}
                              name="production_order_no"
                              label={t(
                                'orderReceiptIssuance.productionOrderNo'
                              )}
                              value={values?.production_order_no}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              disabled={readOnlyMode}
                            />
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container alignItems="center" mt={2.5}>
                            <Grid item xs={1.8}>
                              {t('quotation.exportRestriction')}:
                            </Grid>
                            <AppAutoComplete
                              xs={2.5}
                              disabled={readOnlyMode}
                              options={commonExportRestrictions}
                              value={values?.export_restriction ?? null}
                              getOptionLabel={(option: any) => option.name}
                              label={t('quotation.exportRestriction')}
                              name="export_restriction"
                              onChange={(_: object, value: any) => {
                                if (value !== null) {
                                  setFieldValue(`export_restriction`, value)
                                } else {
                                  setFieldValue(`export_restriction`, null)
                                }
                              }}
                              onFocus={() =>
                                handleFocusExportRestrictionCommon(dispatch)
                              }
                              onBlur={handleBlur}
                              inputOnchange={(e: any) =>
                                onChangeInputExportRestrictionCommon(
                                  e.target,
                                  setQuery
                                )
                              }
                            />
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container alignItems="center" mt={2.5}>
                            <Grid item xs={1.8}>
                              {t('orderReceiptIssuance.paymentTerms')}:
                            </Grid>
                            <AppTextField
                              id={t('orderReceiptIssuance.paymentTerms')}
                              xs={2.5}
                              name="payment_terms"
                              value={values?.payment_terms}
                              label={t('orderReceiptIssuance.paymentTerms')}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container alignItems="center" mt={2.5}>
                            <Grid item xs={1.8}>
                              {t('projectList.columns.expenseType')}:
                            </Grid>
                            <AppAutoComplete
                              xs={2.5}
                              disabled={readOnlyMode}
                              options={commonExpenseTypes}
                              value={values?.expense_type ?? null}
                              getOptionLabel={(option: any) => option.name}
                              label={t('projectList.columns.expenseType')}
                              name="expense_type"
                              onChange={(_: object, value: any) => {
                                if (value !== null) {
                                  setFieldValue(`expense_type`, value)
                                } else {
                                  setFieldValue(`expense_type`, null)
                                }
                              }}
                              onFocus={() =>
                                handleFocusExpenseTypeCommon(dispatch)
                              }
                              onBlur={handleBlur}
                              inputOnchange={(e: any) =>
                                onChangeInputExpenseTypeCommon(
                                  e.target,
                                  setQuery
                                )
                              }
                            />
                          </Grid>
                        </Grid>

                        <Grid item xs={12}>
                          <Grid container alignItems="center" mt={2.5}>
                            <Grid item xs={1.8}>
                              {t('orderReceiptIssuance.remarks')}:
                            </Grid>
                            <AppTextField
                              xs={8.7}
                              disabled={readOnlyMode}
                              name="remarks"
                              label={t('orderReceiptIssuance.remarks')}
                              value={values?.remarks}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              multiline // Set the multiline prop to true
                              rows={4}
                              InputProps={{
                                style: { padding: '10px' }, // Add padding to the input element
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid
                        container
                        padding={4}
                        rowSpacing={2}
                        alignItems="center"
                      ></Grid>
                      <Suspense fallback={<></>}>
                        <CommonReportTable
                          columns={columns}
                          taxType={`${t('commonFinanceTerm.excludingTax')})`}
                          keyName="order_receipt_details"
                          values={values?.order_receipt_details}
                          retrieve_tax_subtotal={values?.tax_subtotal}
                          handleChange={handleChange}
                          setFieldValue={setFieldValue}
                          handleBlur={handleBlur}
                          initialValues={initialState?.order_receipt_details}
                          setQuery={setQuery}
                          touched={touched}
                          disabled={readOnlyMode}
                          edit={edit}
                        />
                      </Suspense>
                    </Card>
                    <FocusElementOnInvalidValidation />
                  </Form>
                </div>
              )}
              {edit && is_staff && (
                <History
                  history={
                    orderReceipt?.history && orderReceipt?.history?.length > 0
                      ? orderReceipt?.history
                      : []
                  }
                />
              )}
            </LocalizationProvider>
          )
        }}
      </Formik>
    </>
  )
}

export default IssueOrderReceipt
