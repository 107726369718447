const jaTranslations = {
  translation: {
    common: {
      info: '島津インターナショナル 翻訳G',
      address: '075-823-2883 (ex910-2883)',
      mailAddress: 'language@int.shimadzu.co.jp',
      complete: '登録完了',
      message: 'ユーザ登録が完了しました。ログインしてください。',
      referenceFile: '参考資料ファイル',
      requestFile: '依頼ファイル',
      issueFlag: '発行済',
      networkError: 'ネットワークエラー',
      noInternet: 'ネットワークに接続されていません。',
      uploadFile: 'ファイルアップロード',
      createDeliveryItemUploadBtn: '成果物アップロード',
      requestForApproval: 'プレビュー',
      delivery: 'プレビュー',
      openURLLink: 'URLリンクを開く',
      createCertificateUpload: '検収書アップロード',
      updateCertificateUpload: '検収書',
      issued: '発行済',
      notIssued: '未発行',
      backToCase: '案件に戻る',
      deliveryNote: '納品書',
      translator: '仕入先',
      company: '依頼企業',
      selectReportForProjectListType: '案件の帳票を選択',
      selectReportType: '帳票を選択',
      client: '依頼者',
      orderPlacement: '引受',
      orderPlacementBtn: '引受',
      stockingExport: '仕入データ出力',
      salesExport: '売上データ出力',
      dataExport: 'データ出力',
      exportInvoiceData: '請求データ出力',
      withoutQuotation: '発注(見積なし)',
      temporarySaving: '一時保存',
      registration: '登録',
      issueInvoice: '請求書作成',
      download: 'ダウンロード',
      autoCalculate: '自動計算',
      edit: '編集',
      save: '保存',
      print: 'ダウンロード',
      percussion: '打診する',
      email: 'メールアドレス',
      submit: '登録',
      confirm: '確認',
      ok: 'OK',
      cancel: 'キャンセル',
      telePhoneNo: '電話番号',
      general: '一般',
      remarks: '備考',
      search: '検索',
      clear: '条件クリア',
      invoice: '請求書',
      invoiceBtn: '請求書作成',
      add: '追加',
      no: 'いいえ',
      yes: 'はい',
      number: 'No',
      upload: 'アップロード',
      character: '文字',
      send: '送信',
      url: 'URLリンク',
      file: 'ファイル',
      fileName: 'ファイル名',
      uploadUrlLinkHere: 'ここにURLリンクを入力してください',
      downloadAttachment: '添付ファイルをダウンロード',
      addAttachment: '添付ファイルを追加',
      deleteAttachment: '添付ファイルを削除',
      actions: '操作',
      delete: '削除',
      update: '更新',
      reasonForChange: '変更理由',
      comments: '納品書に関わるコメント(あれば)',
      goBack: '戻る',
      cost: '原価',
      expense: '費用',
      csvExport: 'CSV出力',
      title: '件名',
      Expanded: '閉じる',
      Collapse: '開く',
      preview: 'プレビュー',
      quotation: '見積書',
      searchCondition: '検索条件はこちらから入力してください。',
      clearConditions: '条件をクリア',
      purchaseOrder: '発注書',
      purchaseOrderBtn: '発注書アップロード',
      orderReceipt: '注文請書',
      orderReceiptBtn: '注文請書作成',
      deliveryItemUpload: '成果物',
      deliveryItemUploadBtn: '成果物アップロード',
      deliveryNoteApprove: '納品書',
      deliveryNoteApproveBtn: '納品書作成',
      acceptance: '受理',
      requestQuotation: '見積依頼',
      updateRequestQuotation: '自動見積',
      addNewDetail: '明細を追加',
      deleteDetail: '明細を削除',
      addNewProjectDetail: '案件情報を追加',
      deleteProjectDetail: '案件情報を削除',
      noDataDisplay: 'データが存在しません',
      logout: 'ログアウト',
      uploadAndDeliveryNoteIssue: 'アップロード+納品書自動発行',
      uploadAndIssueOrderReceipt: 'アップロード+注文請書自動発行',
      invoiceUpload: '請求書アップロード',
      close: '閉じる',
      tax: '%消費税',
      total: '合計',
      withdrawal: '取下げ',
      previewReportNotFound: '帳票のプレビューが見つかりませんでした。',
      urlOrFile: 'ファイルまたはURLリンク',
    },
    confirmMessage: {
      deleteJobDetail: 'JOBの明細を削除します。よろしいですか?',
      cancelMatching: '打診をキャンセルします。よろしいですか？',
      requestQuotationRequestFiles:
        '依頼ファイルがアップロードされていません。見積を依頼しますか？',
      confirmationTitle: '確認',
      confirmation: '実行します。 よろしいですか？',
      save: '保存します。よろしいですか？',
      update: '更新します。よろしいですか？',
      upload: 'アップロードします。よろしいですか？',
      delete: '削除します。よろしいですか？',
      cancel: 'キャンセルします。よろしいですか？',
      send: '送信します。よろしいですか？',
      temporarySaving: '一時保存します。よろしいですか？',
      registration: '登録します。よろしいですか？',
      requestQuotation: '見積を依頼します。よろしいですか？',
      autoCalculate: '自動計算を行います。よろしいですか？',
      orderWithoutQuotation: '見積なしで発注を行います。よろしいですか？',
      completeInspection: '検収を完了します。よろしいですか？',
      completeStocking: '仕入を完了します。よろしいですか？',
      sendCertificate: '検収書を自動発行します。よろしいですか？',
      UploadAndSendDeliveryNote:
        '成果物をアップロードし、納品書を自動発行します。よろしいですか？',
      UploadAndSendOrderReceipt:
        '発注書をアップロードし、注文請書を自動発行します。よろしいですか？',
      percussion: '仕入先へ発注打診メールを送信します。よろしいですか？',
      withdrawal: '取下げします。よろしいですか？',
      orderPlacement: '引受を行います。よろしいですか？',
      offCheck: '承認フローの使用を解除します。よろしいですか？',
      approveWorkflowFlag:
        '承認者情報に入力されている為、チェックをオフに出来ません。',
    },
    validationMessage: {
      passwordMatch: '確認用パスワードが一致しません。',
      invalidFileTypeOnlyAllowedDoc: '自動計算時、Wordの形式のみ有効です',
      minSheetCount: '1枚以上入力してください。',
      field: '項目',
      filledAllRequiredField: '必須項目は全て入力済みです。',
      required: '必須項目です',
      email: '正しいメールアドレスを入力してください。',
      selectOneTranslator: '1人以上の翻訳者を選択してください。',
      minimumChar: '文字以上で入力してください。',
      maxChar: '文字以内で入力して下さい。',
      invalidUrl: '有効なURLを入力してください。',
      atLeast2CharAfterDecimal: 'ドメインの「.]の後ろは2文字以上必須です。',
      atMost3CharAfterDecimal: '少数桁は3桁以下にして下さい。',
      passwordNotMatch: 'パスワードが正しくありません。',
      costSharingRequired1:
        '選択中の費用負担部門コード1では製造オーダー番号1が必須です。',
      costSharingRequired2:
        '選択中の費用負担部門コード2では製造オーダー番号2が必須です。',
      costSharingRequired:
        '選択中の費用負担部門コードでは製造オーダー番号が必須です。',
      numericInput: '数値入力してください。',
    },
    customAlertMessage: {
      cancelSuccess: '仕入先への打診をキャンセルしました。',
      usermadeNotCreated: '未作成です。',
      pleaseSelectOnlyOneLanguage: '言語を 1 つだけ選択してください',
      invalidFileType: 'このファイル形式は無効です。',
      previewDataFailed: 'プレビューの表示に失敗しました。',
      pleaseSelectJobDetail: 'JOBを選択してください。',
      processSuccess: '処理が正常に終了しました。',
      createSuccess: '正常に作成されました。',
      createFail: '作成に失敗しました。',
      updateSuccess: '正常に更新しました',
      updateFail: '更新に失敗しました。',
      deleteSuccess: '正常に削除しました。',
      deleteFail: '削除に失敗しました。',
      gettingFail: 'データの取得に失敗しました。',
      createQuotationMsg: '見積書を作成してください。',
      selectOne: '行を選択してください。',
      doesntExist: 'ダウンロードするファイルがありません。',
      uploadValidation: 'アップロードするファイルを選択してください。',
      pleaseSelectOnlyOneData: '1行選択してください。',
      sendMailSuccess: 'メールが正常に送信されました。',
      sendMailFail: 'メールの送信に失敗しました。',
      sendAcceptanceSuccess: '正常に受理されました。',
      sendAcceptanceFail: '受理できませんでした。',
      createOrderReceiptFirst: '注文請書を作成してください。',
      caseIdNotFound: '案件が見つかりませんでした。',
      addQuotationFirst:
        '見積なしで発注した案件の注文請書発行は「注文請書作成」から行って下さい。',
      addJobTaskFirst: 'JOBを作成してください。',
      purchaseOrderAmountNotFound: '発注書が見つかりませんでした。',
      addTaskFirst: '作業を作成してください。',
      getJobIdFirst: '案件にJOBを登録してください。',
      selectCheckboxFirst: '行を選択してください。',
      noInvoiceExistsForThisJobDetail:
        'このJOB明細に紐づく請求書は見つかりませんでした。',
      firstOrderReceiptSend: '注文請書を送信してください。',
      completeCaseEdit: '案件が完了しているため更新できません。',
      failedToExport: 'ファイルの出力に失敗しました。',
      downloadSuccess: 'ダウンロードが完了しました。',
      getJobTaskId: '作業が見つかりません。',
    },
    authMessage: {
      loginFail: 'ログインに失敗しました。',
      resetPasswordSuccess: 'リセットメールを送信しました。',
      resetPasswordFail: 'ユーザーが見つかりませんでした。',
      confirmPasswordSuccess: 'パスワードを再設定しました。',
      confirmPasswordFail: 'パスワードの再設定に失敗しました。',
      changePasswordFail: 'パスワードの変更に失敗しました。',
      changePasswordSuccess: 'パスワードを変更しました。',
      authFail: '認証に失敗しました。ログインしてください。',
    },
    moduleName: {
      task: {
        create: '作業新規作成',
        edit: '作業編集',
        delete: '作業削除',
      },
      job: {
        create: 'JOB新規作成',
        edit: 'JOB編集',
        delete: 'JOB削除',
      },
      project: {
        create: '案件入力',
        edit: '案件編集',
        delete: '案件削除',
      },
      projectForClient: {
        create: '案件入力',
        edit: '案件編集',
        delete: '案件削除',
      },
    },
    taskList: {
      certificateIssue: '検収書',
      searchCondition: '検索条件はこちらから入力してください。',
      clearCondition: '条件をクリア',
      search: '検索',
      taskInformation: '作業情報',
      job: 'JOB番号',
      orderDate: '発注日',
      subContractor: '発注先',
      orderAmount: '発注金額',
      wordCount: '単語数',
      rate: 'Rate',
      deliveryDate: '指定納期',
      stockingDate: '仕入日',
      dateRecorded: '請求書受月',
      operationManager: '作業管理',
      trados: 'Trados',
      readability: 'Readability',
      jobTask: 'JOB明細作業',
      ownWorkFlow: '自社ワークフロー',
      clientWorkFlow: 'クライアントワークフロー',
      issuePurchase: {
        deliveryDate: '納品希望日',
        translator: '宛先',
        purchaseOrderDate: '発注年月日',
        purchaseIssue: '発注書',
        your: '宛先',
        purchase_order_no: '発注書番号',
        purchase_order_no_seq: '発注書番号枝番',
        quotation_date: '発注年月日',
        due_date: '予定納期',
        jobNo: 'JOB番号',
        subject: '件名',
        estimateDate: '発行年月日',
        amountIncTax: '税込金額',
        appDeliveryDate: '納期',
        paymentTerms: '支払条件',
        effictiveDate: '有効期限',
        language: '翻訳言語',
        company: '宛先',
        title: '件名',
        remarks: '備考',
        tableItems: {
          jobNo: 'Job番号',
          desiredDeliveryDate: '納品希望日',
          taxExcludedPrice: '発注金額(税抜)',
          taxIncludedPrice: '税込金額',
          tableTitle: '発注書作成',
          name: '区分',
          content: '項目',
          qty: '数量',
          unit: '単位',
          salesTax: '税率',
          unitCost: '単価',
          amount: '金額',
          operate: '作業',
          subTotal: '小計',
          yen: '円(税込)',
          taxAmount: '(参考)消費税',
        },
      },
      issueCertificate: {
        caseId: '案件ID',
        jobId: 'JOB ID',
        issueDate: '発行日',
        inspectionDate: '検収日',
        issueInspectionCertificate: '検収書作成',
        certificate_no: '検収書番号',
        certificate_no_seq: '検収書枝番',
        company: '宛先',
        title: '件名',
        jobNo: 'JOB番号',
        language: '翻訳言語',
        paymentTerms: '支払条件',
        remarks: '備考',
        withdrawal_flag: '取下げ',
        invalid_flag: '無効',
        addIssueCertificate: {
          tableTitle: '検収書作成',
          totalAmount: '金額',
          unitCost: '単価',
          unit: '単位',
          salesTax: '税額',
          operate: '作業',
          quantity: '数量',
          content: '内容',
          projectName: '項目',
        },
      },
    },
    appDropZoneCommon: {
      title: 'アップロードするファイルをドラッグしてください',
      size: 'ファイルサイズの上限は50MBです',
      fileSizeShouldBeUpTo: 'ファイルサイズは最大にしてください',
      fileTypeShouldBe: 'ファイルの種類は次のとおりにしてください',
      invalidFileTypeOrSize: 'ファイルの種類またはサイズが無効です',
    },
    menuName: {
      projectList: '案件一覧',
      quotationList: '①見積依頼一覧',
      completeQuotationList: '②見積完了一覧',
      orderList: '③受注完了一覧',
      taskStateList: '作業中',
      inspectionTaskStateList: '仕入検収済一覧',
      waitingBillList: '仕入請求一覧',
      completeDeliveryList: '④納品完了(未検収)一覧',
      jobList: 'JOB検索',
      billableList: '⑤検収済一覧',
      exportInvoiceDataList: '出力済請求データ',
      billedList: '⑥請求完了一覧',
      projectCreate: '案件入力',
      clientProjectCreate: '新規依頼はこちら',
      projectListClient: '依頼一覧',
      workflowTest: 'ワークフローテスト',
    },
    uploadRecept: {
      preview: 'プレビュー',
      orderReceipt: {
        name: '自作注文請書アップロード',
        company: '宛先',
        issueDate: '発行日',
        amount: '金額',
      },
      invoiceUpload: {
        name: '請求書アップロード',
        issuedTo: '宛先',
        publisher: '発行者',
        issueDate: '発行日',
        amount: '金額',
      },
      purchaseOrderUpload: {
        name: '発注書アップロード',
        company: '発行者',
        purchaseOrderNo: '発注書No',
        dateRecorded: '計上日',
        issueDate: '発行日',
        publisher: '発行者',
        amount: '金額',
      },
      usermadeInvoiceUpload: {
        name: '自作請求書アップロード',
        company: '宛先',
        issueDate: '発行日',
        amount: '金額',
      },
      translatorInvoiceUpload: {
        certificateNo: '検収書番号',
        name: '請求書アップロード',
        issueDate: '請求書発行日',
        historySeq: '履歴連番',
        translator: '発行者',
        amount: '金額',
        taxAmount: '税額',
        taxExcludedAmount: '税抜金額',
        recordedDate: '計上日',
      },
      deliveryItemUpload: {
        name: '成果物アップロード',
        clientName: '納品ファイル',
        message: '納品コメント(お客さんに届く)',
        deliveryComment: '納品コメント',
      },
      certificateUpload: {
        name: '検収書アップロード',
        issueDate: '発行日',
        company: '発行者',
        amount: '金額',
        recordedDate: '計上日',
        productionOrderNo1: '製造オーダー番号1',
        costSharingDepartmentCode1: '費用負担部門コード1',
        productionOrderNo2: '製造オーダー番号2',
        costSharingDepartmentCode2: '費用負担部門コード2',
        certificateNo: '検収書No',
        discardFlag: '取り消し',
      },
      fields: {
        seq: '連番',
      },
      invoiceHq: {
        name: '島津本社請求書アップロード',
        company: '発行者',
        issueDate: '発行日',
        amount: '金額',
        taxAmount: '税額',
        taxExcludedAmount: '税抜金額',
        invoiceNo: '請求書番号',
      },
    },
    exportInvoiceDataList: {
      columns: {
        dlDate: 'データ出力日時',
        dlUser: 'データ出力ユーザ',
        invoiceHq: '島津本社請求書アップロード',
        delete: 'データ削除',
      },
    },
    projectList: {
      invoiceHq: '請求書アップロード済',
      salesExport: '売上データ出力',
      confirmSalesExportMsg: '売上データを出力します。よろしいですか？',
      generalAffairsFlag: '総務G案件',
      approveWorkflowFlag: '承認フローを使用する',
      useCCFlag: '依頼者のCCを表示する',
      saleStatus: '売上状態',
      stockingStatus: '仕入状態',
      departmentToWhichRequester: '部署名',
      contactPerson: '依頼者',
      searchCondition: '検索条件はこちらから入力してください。',
      departmentResponsibleFor: '部署名',
      nameOfShimadzuAdcom: '担当者',
      includeDeletedMatters: '削除済を含める',
      desiredDeliveryDate: '希望納期',
      desiredDate: '納入日',
      estimatedAmountFrom: '見積金額FROM',
      estimatedAmountTo: '見積金額TO',
      orderNumber: '受注番号',
      freeDescription: 'キーワード',
      agencyProjectCreate: '新規案件作成',
      export: '出力',
      approveDepartment: '承認者情報',
      csvExportFileName: '案件詳細',
      columns: {
        companyAbbreviation: '会社略称',
        generalAffairsFlag: '総務G',
        salesDepartment: '売上部署',
        orderReceiptTaxSubTotalSubTotalAmount: '受注金額',
        deliveryNoteDeliveryDate: '納品日',
        certificateCompletionDate: '検収完了日',
        salesType: '売上種別',
        duplicateBtn: '案件の複製',
        approverDepartmentName: '承認者部署名',
        approverDepartmentNamePlaceholder: '承認フローに必要です。',
        approverInnerLine: '連絡可能な番号',
        approverInnerLinePlaceholder: '承認フローに必要です。半角',
        approverMail: 'e-mail',
        approverMailPlaceholder: '承認フローに必要です。半角',
        approverContactPersonName: '承認者名',
        approverContactPersonNamePlaceholder:
          '承認フローに必要です。入力例：島津花子',
        costSharingDepartmentCode1: '費用負担部門コード1',
        costSharingDepartmentCode1Placeholder: '見積に必要です。',
        productionOrderNo1: '製造オーダー番号1',
        productionOrderNo1Placeholder: 'ある場合は入力(8桁)',
        costSharingDepartmentCode2: '費用負担部門コード2',
        costSharingDepartmentCode2Placeholder: 'ある場合のみ(4桁)',
        productionOrderNo2: '製造オーダー番号2',
        productionOrderNo2Placeholder: 'ある場合は入力(8桁)',
        detailCode: '細目コード',
        detailCodePlaceholder: '見積に必要です。',
        ownContactPersonUser: 'インター担当者',
        invoiceNumber: '請求書番号',
        salesNumber: '売上番号',
        deliveryDate: '納入日',
        generalAffairsCertificateDate: '総務G案件検収日',
        invoiceNumberPlaceholder: '総務G案件に必要です。',
        salesNumberPlaceholder: '総務G案件に必要です。',
        expenseType: '細目コード',
        version: 'バージョン',
        draftFlag: '下書き',
        withoutQuotationFlag: '発注(見積なし)',
        inspectionFlag: '検収済',
        statusBtnColumn: '状態',
        caseNo: '依頼番号',
        jobNo: 'JOB番号',
        requestedDate: '依頼日',
        requestNo: '依頼番号',
        caseTitle: '件名',
        ownCaseTitle: '件名(インター命名)',
        ownCaseTitlePlaceholder: 'インター側で指定する件名を入力してください。',
        caseTitlePlaceholder:
          '一時保存に必要です。メールの件名となりますので特殊文字は使わないでください。',
        industryType: '業種',
        desireDeliveryDate: '希望納期',
        desireDeliveryDatePlaceholder: '見積に必要です。',
        orderReceiveDate: '受注日',
        requestDepartmentCode: '部署コード',
        requestDepartmentName1: '部署名',
        requestDepartmentName1Placeholder:
          '一時保存に必要です。入力例：分析計測SCOE',
        requestDepartmentContactPersonName1: '担当者名',
        requestDepartmentContactPersonName1Placeholder:
          '一時保存に必要です。入力例：島津太郎',
        requestDepartmentInnerLine1: '連絡可能な番号',
        requestDepartmentInnerLine1Placeholder: '一時保存に必要です。',
        requestDepartmentMail1: 'e-mail',
        requestDepartmentMail1Placeholder: '一時保存に必要です。',
        requestDepartmentName2: '部署名1',
        requestDepartmentName2Placeholder: '入力例：分析計測SCOE',
        requestDepartmentContactPersonName2: '担当者名1',
        requestDepartmentContactPersonName2Placeholder: '入力例：島津花子',
        requestDepartmentInnerLine2: '連絡可能な番号1',
        requestDepartmentInnerLine2Placeholder: '半角',
        requestDepartmentMail2: 'e-mail1',
        requestDepartmentMail2Placeholder: '半角',
        requestDepartmentContactPersonName3: '担当者名2',
        requestDepartmentContactPersonName3Placeholder: '入力例：島津太郎',
        requestDepartmentInnerLine3: '連絡可能な番号2',
        requestDepartmentInnerLine3Placeholder: '半角',
        requestDepartmentMail3: 'e-mail2',
        requestDepartmentMail3Placeholder: '半角',
        requestDepartmentName3: '部署名2',
        requestDepartmentName3Placeholder: '入力例：分析計測SCOE',
        PurchaseOrderFile: '発注ファイル',
        PurchaseOrderNo: '発注No',
        status: '見積',
        company: '会社名',
        companyPlaceholder: '一時保存に必要です。',
        requestDepartmentAffiliated1: '依頼所属部門',
        requestDepartmentAffiliated1Placeholder: '一時保存に必要です。',
      },
    },
    projectDetails: {
      certificate: '検収書',
      invoice: '請求書',
      autoCalculateTable: '自動計算',
      showClientItem: '費用処理を表示する',
      hiddenClientItem: '費用処理を非表示にする',
      completeCertificate: '検収完了',
      hiddenRequestFile: '依頼ファイルを非表示にする',
      showRequestFile: '依頼ファイルを表示する',
      hiddenReferenceFile: '参考資料ファイルを非表示にする',
      showReferenceFile: '参考資料ファイルを表示する',
      layout: 'レイアウト',
      layoutPlaceholder: '自動計算に必要です。',
      updateQuotation: '見積書',
      createQuotation: '見積書作成',
      orderConfirmation: '注文請書',
      showArtifact: '成果物',
      artifactUpload: '成果物アップロード',
      certificateUpload: '検収書アップロード',
      showDeliveryNote: '納品書',
      deliveryReport: '納品書',
      showOrderReceipt: '注文請書',
      orderReceipt: '注文請書',
      orderPlacement: '引受',
      showPurchaseOrder: '発注書',
      productName: '製品名',
      document: 'ドキュメント',
      documentPlaceholder: '自動計算に必要です。',
      work: '作業',
      workPlaceholder: '自動計算に必要です。',
      orderPlacementModalTitle: '注文請書を自動発行します。よろしいですか？',
      exportRestriction: '輸出規制',
      exportRestrictionPlaceholder: '見積に必要です。',
      translationLanguage: '翻訳言語',
      translationLanguagePlaceholder: '自動計算に必要です。',
      multipleSelectAllowed: '複数選択可。',
      translationSubject: '翻訳対象',
      translationSubjectPlaceholder: '自動計算に必要です。',
      customerInformation: '顧客情報',
      quotationDate: '発行日',
      requesterName: '依頼者名',
      departmentToWhichThe: '部署名',
      requestInformation: '依頼情報',
      requestTitle: '依頼ファイル',
      referenceTitle: '参考資料ファイル',
      remarks: '依頼メッセージ',
      remarksPlaceholder: '500文字',
      formTitleName: {
        contactDetail1: '依頼者情報',
        contactDetail2: '依頼者CC',
        contactDetail3: '依頼者CC(2)',
        caseInfo: '案件情報',
        clientItem: '費用処理',
      },
      showCC: '依頼者のCCを追加する',
      hiddenCC: '依頼者のCCを非表示にする',
      addNewProjectDetails: '案件の詳細を追加',
      deleteProjectDetails: '案件の詳細を削除',
      copyCase: '条件コピー(新規依頼)',
      workFlowHistory: '承認履歴',
      quotation: '見積書作成',
    },
    projectListClient: {
      issueDate: '発行日',
      requester: '依頼者',
      deptReqBelongTo: '依頼所属部門',
      departmentResponsibleFor: '部署名',
      contactPerson: '担当者',
      includeDeletedMatters: '削除済を含む',
      estimatedAmountFrom: '見積日FROM',
      estimatedAmountTo: '見積日TO',
      orderNumber: '依頼番号',
      freeDescription: 'キーワード',
      desiredDate: '希望納期',
    },
    quotationRequestList: {
      columns: {
        status: '状態',
        purchaseWithoutQuotation: '発注(見積なし)',
        quotationRequestedDateTime: '見積依頼日',
        quotationRequestNumber: '(見積)依頼番号',
        caseTitle: '件名',
        requestCompany: '依頼企業',
        requestDepartmentName: '依頼部署',
        requestContactPersonName: '担当者',
        desiredDeliveryDate: '希望納期',
        quotationState: '見積書作成中',
      },
    },

    completeQuotationList: {
      columns: {
        status: '状態',
        requestDepartmentName1: '依頼部署',
        requestDepartmentContactPersonName1: '担当者',
        purchaseWithoutQuotation: '発注(見積なし)',
        quotationRequestedDateTime: '見積発信日',
        quotationRequestNumber: '見積依頼番号',
        caseTitle: '件名',
        requestCompany: '依頼企業',
        requestDepartmentName: '依頼部署',
        requestContactPersonName: '担当者',
        desiredDeliveryDate: '希望納期',
        quotation: '見積書',
        orderReceipt: '注文請書',
        openQuotation: '見積書を開く',
        issueOrderReceipt: '注文請書を作成',
      },
    },

    quotationStateList: {
      columns: {
        status: '状態',
        statusBtn: '見積',
        orderReceivedDate: '受注日',
        requestNumber: '依頼番号',
        approverMail: 'email',
        caseTitle: '件名',
        requestDepartmentName1: '依頼部署',
        requestDepartmentMail1: 'メールアドレス',
        requestContactPersonName1: '依頼担当者',
        requestInnerLine: '連絡可能な番号',
        desiredDeliveryDate: '希望納期',
        forwardingInvoiceDateTime: '請求書送付日時',
        quotation: '見積書',
      },
    },

    inspectionTaskStateList: {
      caseNo: '依頼番号',
      jobNo: 'JOB番号',
      freeDescription: 'キーワード(JOB件名/JOB番号/JOB明細番号)',
      inspectionDateFrom: '検収日FROM',
      inspectionDateTo: '検収日TO',
      certificateCompletionDateFrom: '検収日FROM',
      certificateCompletionDateTo: '検収日TO',
      desiredDate: '仕入日',
      translator: '仕入先',
      columns: {
        issueCertificate: '検収書作成',
        issueCertificateBtn: '検収書作成',
        quotationRequestNumber: '見積依頼番号',
        title: '件名',
        jobNo: 'JOB番号',
        orderAmount: '発注金額',
        deliveryDate: '納品日',
        deliveryContactPersonName: '納品担当者',
        completeInspectionDate: '検収完了日',
        statusBtnColumn: '状態',
        certificateDetailDraftTotalTaxIncludedPrice: '修正後金額',
      },
    },

    waitingBillList: {
      columns: {
        certificateNo: '検収書No',
        certificateDate: '検収日',
        issueDate: '発行日',
        company: '宛先',
        title: '件名',
        invoice: '請求書アップロード',
        invoiceUploadConfirmationFlag: '請求書アップロード済',
      },
    },

    orderList: {
      columns: {
        purchaseWithoutQuotation: '発注(見積なし)',
        quotationRequestNumber: '見積依頼番号',
        jobNo: 'JOB番号',
        caseTitle: '件名',
        requestCompany: '依頼企業',
        requestDepartmentName: '依頼部署',
        requestContactPersonName: '担当者',
        desireDeliveryDate: '希望納期',
        quotation: '見積書',
        orderReceipt: '注文請書',
        orderReceiveDate: '受注日',
        statusBtnColumn: '状態',
      },
    },

    deliverableList: {
      columns: {
        status: '状態',
        purchaseWithoutQuotation: '発注(見積なし)',
        requestedDateTime: '見積依頼日',
        requestNumber: '見積依頼番号',
        caseTitle: '件名',
        requestDepartmentName: '依頼部署',
        requestContactPersonName: '担当者',
        requestInnerLine: '連絡可能な番号',
        quotation: '見積書',
        purchaseOrder: '発注書',
        orderReceipt: '注文請書',
        deliveryItemUpload: '成果物アップロード',
        deliveryNoteApprove: '納品書作成',
      },
    },

    billedList: {
      columns: {
        caseNo: '見積依頼番号',
        caseTitle: '件名',
        requestCompanyName: '依頼企業',
        requestDepartmentName1: '依頼部署',
        requestContactPersonName: '担当者',
        jobNo: 'JOB番号',
        deliveryContactPersonName: '納品担当者',
        deliveryDate: '納品日',
        completeInspectionDate: '検収完了日',
        invoiceDate: '請求日',
        excludingAmount: '金額(税抜)',
        statusBtnColumn: '状態',
        invoice: '請求書',
        invoiceHq: '請求書アップロード済',
      },
    },

    deliveryCompleteList: {
      completeCertificate: '検収完了',
      columns: {
        requestNumber: '見積依頼番号',
        jobNo: 'JOB番号',
        caseTitle: '件名',
        requestCompany: '依頼企業',
        requestDepartmentName1: '依頼部署',
        requestContactPersonName1: '担当者',
        deliveryDate: '納品日',
        deliveryContactPersonName: '納品担当者',
        inspectionFlag: '検収済',
        statusBtnColumn: '状態',
      },
    },

    billableList: {
      fileName: '請求可能一覧',
      columns: {
        certificateUpload: '検収書アップロード',
        requestedDate: '見積依頼日',
        requestCompanyName: '依頼企業',
        desiredDeliveryDate: '希望納期',
        requestDepartmentContactPersonName1: '担当者',
        subject: '件名',
        caseNo: '依頼番号',
        caseTitle: '件名',
        jobNo: 'JOB番号',
        deliveryDate: '納品日',
        statusBtnColumn: '状態',
      },
    },

    projectListForClient: {
      columns: {
        requestDepartmentName1: '依頼部署',
        requestCompany: '依頼企業',
        requestDepartmentContactPersonName1: '担当者',
        quotationRequestedDateTime: '見積依頼日',
        quotationRequestNo: '見積依頼番号',
        quotationPassiveDateTime: '見積受日時',
        caseTitle: '件名',
        orderDateTime: '発注日',
        jobNo: 'JOB番号',
        desireDeliveryDate: '希望納品日',
        deliveryNoteAcceptanceDate: '納品日',
        completeInspectionDate: '検収完了日',
        statusBtnColumn: '状態',
        ownContactPersonUser: 'インター担当者',
      },
    },
    jobDetails: {
      unitPrice: '単価',
      taskTime: '時間',
      taskType: '作業種別',
      sellingPrice: '売価',
      totalCost: '社内+原価',
      jobInformation: 'JOB情報',
      jobNo: 'JOB番号',
      recyclingNo: '台帳番号',
      caseId: '案件ID',
      invoiceId: '請求書ID',
      detailNo: 'JOB枝番',
      subject: '件名',
      orderAmount: '受注金額',
      pageCount: '頁数',
      wordCount: '単語数',
      rate: 'Rate',
      sheetCount: '枚数',
      sheetCountPlaceholder: 'レイアウト有の場合、自動計算に必要です。',
      layout: 'レイアウト',
      desiredDeliveryDate: '希望納期',
      deliveryDate: '納入日',
      invoicingFlag: '請求書発行済',
      delete_flag: '削除済',
      accountsReceivableMonth: '売掛月',
      jobTask: {
        accPayableMonth: '買掛月',
        orderDate: '発注日',
        translator: '発注先',
        task: '作業',
        translateLanguage: '翻訳言語',
        orderAmt: '発注金額',
        orderAmtCost: '発注金額(原価)',
        orderAmt10Oer: '発注金額(10%)',
        wordCount: '単語数',
        rate: 'Rate',
        deliveryDate: '指定納期',
        stockingDate: '仕入日',
        dateRecorded: '請求書受月',
        operationManager: '作業管理',
        trados: 'Trados',
        readibility: 'Readibility',
        remarks: '備考',
        cancelFlag: 'キャンセル',
      },
    },
    jobList: {
      name: 'JOB一覧',
      searchCondition: '検索条件はこちらから入力してください。',
      stockingExportMessage: '仕入データを出力します。よろしいですか？',
      requester: '依頼者',
      deptReqBelongTo: '依頼所属部門',
      search: '検索',
      clearConditions: '条件をクリア',
      create: 'JOB新規作成',
      export: '出力',
      csvExportFileName: 'JOB明細',
      paymentSchedule: '支払予定書作成',
      bulkInspectionCertificate: '検収書を送信',
      bulkInspectionCertificateModalTitle:
        '選択中の翻訳者に検収書を送信します。よろしいですか？',
      sendPaymentScheduleModalTitle:
        '選択中の翻訳者に支払予定書を送信します。よろしいですか？',
      issueInvoiceData: {
        btnName: '請求データ作成',
        exportBtn: 'CSV出力',
        cardTitle: '請求データ作成',
        tableItems: {
          deptCode: '依頼部署',
          deptName: '依頼部署名',
          invoiceAmt: '請求金額',
          selectReqNo: '選択行',
          title: '件名',
          total: '合計',
          totalAmount: '41,000(税抜)',
        },
      },
      columns: {
        statusBtnColumn: '状態',
        requestedDate: '見積依頼日',
        requestNo: '見積依頼番号',
        desireDeliveryDate: '希望納期',
        orderReceiveDate: '受注日',
        jobNo: 'JOB番号',
        reqDepartmentName: '部署名',
        requestDepartmentContactPersonName: '担当者名',
        title: '件名',
        orderReceiveAmount: '受注金額',
        interDesiredDeliveryDate: '指定納期',
        paymentScheduleBtnColumn: '支払予定書',
        deliveryDate: '納入日',
        inspectionDate: '検収日',
        orderDate: '発注日',
        subContractor: '発注先',
        orderAmt: '発注金額',
        stockingDate: '仕入日',
        oprMngr: '作業管理',
        duplicateTaskBtnLabel: 'JOBの複製',
        caseTitle: '件名',
        invoiceId: '請求ID',
        orderNo: '受注番号',
        requestDepartment: '依頼所属部門',
        invoiceBtnColumn: '請求書',
        status: '発注',
        translatorMatchingBtnColumn: '打診',
        reqDeptName1: '依頼所属部門',
      },
      taskColumns: {
        duplicateBtn: '複製',
        duplicateTaskLabel: '作業の複製',
        subject: '件名',
        orderDate: '発注日',
        transratorId: '発注先',
        transrateLanguageId: '翻訳言語',
        taskId: '作業',
        orderAmount: '発注金額',
        remarks: '備考',
        insCertFlag: '検収書フラグ',
        cancelBtn: 'キャンセル',
        translator: '宛先',
        PurchaseOrdBtn: '発注書',
        createPurchaseOrdBtn: '発注書',
        createPurchaseAfterAmountChangeBtn: '金額修正',
        insCert: '検収書',
        invoiceBtn: '請求書',
        paymentScheduleBtn: '支払予定書',
      },
      collapsableColumn: {
        name: '作業',
        check: '選択',
        orderDate: '受注日',
        transratorId: '翻訳者',
        transratorLangId: '翻訳言語',
        taskId: '作業ID',
        orderAmt: '受注金額',
        remarks: '備考',
        insCertFlag: '検収済',
        cancel: 'キャンセル',
        purchaseOrder: '発注書',
        insCertificate: '検収書',
        invoice: '請求書',
      },
    },
    quotation: {
      name: '見積書',
      proposalNumber: '案件番号',
      caseNo: '依頼番号',
      quotationNo: '見積番号',
      discardFlag: '取り消し',
      quotationNoSeq: '見積番号枝番',
      quotationDate: '発行日',
      title: '件名',
      dueDate: '納期(作業期間)',
      productionOrderNo: '製造オーダー番号',
      remarks: '備考',
      issueFlag: '発行済',
      withdrawalFlag: '取下げ',
      invalidFlag: '無効',
      job: 'JOB',
      case: '案件',
      orderReceipt: '注文請書',
      company: '宛先',
      costSharingDeptCode: '費用負担部門コード',
      exportRestriction: '輸出規制',
      detailCode: '細目コード',
      amountIncTax: '税込金額',
      yen: '円(税込)',
      subtotal: '小計',
      quotationValidityPeriod: '見積有効期限',
      deliveryStyle: '納品形態',
      desiredDeliveryData: '希望納期',
      mediaName: 'Media Name',
      plateTypeSize: 'Plate type/size',
      manuScriptFormat: 'Manuscript format',
      adName: ' Advertisement name',
      dummyPlateType: 'Dummy plate type',
      originalColor: 'Original color',
      select: 'を選択',
      tableItems: {
        tableTitle: '見積書作成',
        content: '項目',
        quantity: '数量',
        unitCost: '単価',
        salesTax: '税率',
        taxAmount: '消費税',
        amount: '金額',
      },
    },
    history: {
      title: '変更履歴',
      updateDate: '更新日',
      updateUser: '更新者',
      operation: '作業内容',
      reasonForChange: '変更理由',
      PDFBeforeChange: 'PDFファイル',
    },
    workFlow: {
      approvalWorkFlow: '承認ワークフロー',
      approvalHistory: '承認履歴',
      applicator: '作成者',
      approver: '承認者',
      comment: 'コメント',
      applicate: '申請',
      approve: '承認',
      reject: '却下',
      unApplicate: '申請取消',
      cancel: '取下げ',
      tableItems: {
        userName: '作成者/承認者',
        status: '状態',
        comment: 'コメント',
        processingData: '処理日',
      },
      status: {
        APPLICATED: '申請済',
        APPROVED: '承認済',
        REJECTED: '却下済',
        CANCELLED: '取下げ済',
        APPROVINGREQUEST: '承認依頼',
        ORDER: '承認(発注)',
      },
      useEmail: 'メール入力',
    },
    purchaseAfterAmountChange: {
      title: '金額修正',
      legendTitle: '仕入日を追加',
      jobNo: 'JOB番号',
      translatorId: '翻訳者',
      remarks: '備考',
      deliveryDate: '指定納期',
      stockingDate: '仕入日',
      purchaseOrderDate: '発注日',
      addAttachments: '入力欄を追加する',
      tableItems: {
        section: '区分',
        itemName: '項目',
        quantity: '数量',
        unit: '単位',
        unitCost: '単価',
        salesTax: '税率',
        totalAmount: '金額',
      },
    },
    issueInvoice: {
      certificateDate: '検収日',
      invoiceNoSeq: '請求書番号枝番',
      paymentTermsId: '支払条件',
      translationExpiration: '翻訳有効期限',
      company: '宛先',
      your: '宛先',
      name: '請求書',
      looks: '宛先',
      invoiceNumber: '請求書番号',
      invoiceDate: '請求年月日',
      totalAmount: '税込金額',
      subject: '件名',
      language: '翻訳言語',
      deliveryDate: '納品日',
      jobNo: 'JOB番号',
      quotationNo: '見積書番号',
      preview: 'プレビュー',
      withdrawalFlag: '取下げ',
      issueFlag: '発行済',
      addIssueInvoice: {
        section: '区分',
        itemName: '項目',
        unit: '単位',
        unitCost: '単価',
        salesTax: '消費税',
        amount: '金額',
        tableTitle: '請求書作成',
        totalAmount: '金額',
        operate: '作業',
        quantity: '数量',
        content: '内容',
        projectName: '項目',
      },
      tableItems: {
        productName: '製品名',
        quantity: '数量',
        unit: '単位',
        unitCost: '単価',
        amount: '金額',
      },
      subtotal: '小計',
      totalOrderAmount: '税抜合計',
      consumptionTax: '消費税',
      total: '税込合計',
      bankNameLabel: '銀行名',
      bankName: '三菱UFJ銀行',
      branchNameLabel: '支店名',
      branchName: '京都中央支店',
      depositTypeLabel: '預金の種類',
      depositType: '当座預金',
      accountLabel: '口座番号',
      account: '238432',
      accountHolderNameLabel: '口座名義人',
      accountHolderName: 'カ) シマヅインターナショナル',
      paymentDueDateLabel: 'お支払期日',
      paymentDueDate: 'xxxx/xx/xx',
      noteLabel: '※',
      note: '振込手数料は貴社にてご負担ください。',
    },
    orderReceiptIssuance: {
      certificateDate: '検収日',
      caseNo: '依頼番号',
      jobNo: 'JOB番号',
      paymentDate: 'お支払期日',
      acceptanceDate: '検収日',
      purchaseOrderNo: '発注書番号',
      name: '注文請書',
      orderReceiptNumber: '注文請書番号',
      orderReceiptDate: '発行年月日',
      quotationDate: '見積年月日',
      issueDate: '発行日',
      quotationNo: '見積番号',
      quotationNoSeq: '見積番号枝番',
      company: '宛先',
      title: '件名',
      translationLanugage: '翻訳言語',
      deliveryStyle: '納品形態',
      dueDate: '納期(作業期間)',
      translationExpiration: '見積有効期限',
      paymentTerms: 'お支払条件',
      remarks: '備考',
      withdrawalFlag: '取下げ',
      costSharingDepartment: '費用負担部門コード',
      productionOrderNo: '製造オーダー番号',
      addOrderReceipt: {
        tableTitle: '注文請書作成',
        totalAmount: '金額',
        unitPrice: '単価',
        operate: '作業',
        quantity: '数量',
        content: '内容',
        projectName: '項目',
      },
      tableItems: {
        section: '区分',
        itemName: '項目',
        quantity: '数量',
        unit: '単位',
        unitCost: '単価',
        saleTax: '税率',
        amount: '金額',
      },
      subtotal: '小計',
      yen: '円',
    },
    translatorMatchingScreen: {
      pageCount: '頁数',
      unitCost: '単価',
      translationLanguage: '翻訳言語',
      readability: 'Readbility',
      task: '作業',
      trados: 'Trados',
      orderAmount10per: '発注金額(10%)',
      deliveryDate: '指定納期',
      operationManager: '作業管理',
      orderAmountCost: '発注金額(原価)',
      wordCount: '単語数',
      rate: 'Rate',
      orderAmount: '発注金額',
      translatorLanguage: '翻訳言語',
      orderDate: '発注日',
      name: '発注打診',
      jobInfo: '仕入先',
      desiredDeliveryDate: '希望納期',
      desiredDeliveryDateValue: '2023-09-08',
      jobNo: 'Job番号',
      jobNoValue: 'Job no. 1',
      subject: '件名',
      subjectValue: 'Subject 1',
      translatorListLabel: '仕入先',
      bodyEmail: 'メール本文',
      addMsgEmail: '追加メッセージ',
      richText: 'リッチテキスト',
      translatorFilter: '翻訳者',
      tableItems: {
        status: '状態',
        accountsPayableMonth: '買掛月',
        cancelFlag: 'キャンセル状況',
        deliveryDate: '予定納期',
        operationManager: '作業管理',
        orderAmount: '発注金額',
        orderAmount10per: '発注金額(10%)',
        orderAmountCost: '発注金額(原価)',
        orderDate: '受注日',
        rate: 'Rate',
        stockingDate: '仕入日',
        trados: 'Trados',
        translationLanguage: '翻訳言語',
        translator: '翻訳者',
      },
      subtotal: '小計',
      yen: '円',
    },
    auth: {
      login: 'ログイン',
      emailAddress: 'メールアドレス',
      password: 'パスワード',
      changePassword: 'パスワード変更',
      confirmPassword: 'パスワードを確認',
      oldPassword: '現在のパスワード',
      newPassword: '新しいパスワード',
      confirmNewPassword: '新しいパスワードを確認',
      rememberMe: 'ログイン情報を保存',
      pid: 'メールアドレス',
      submit: '登録',
      createNow: '作成',
      dontHaveAcc: 'アカウントを持っていない',
      send: '送信',
      return: 'キャンセル',
      forgotPassword: {
        msg: 'パスワードを忘れた方はこちら',
        steps: 'パスワードを忘れた方',
        step1: '以下にメールアドレスを入力して送信してください。',
        step2: '仮パスワードがメールで送信されます',
        step3: 'メールの内容にしたがってパスワードを再設定してください。',
      },
      resetPassword: 'パスワードリセット',
      register: {
        firstName: '名',
        lastName: '姓',
        register: '登録',
        email: 'メールアドレス',
        tel: '電話番号',
      },
    },
    deliveryReportNoteApprove: {
      name: '納品書',
      deliveryNoteNo: '納品書番号',
      deliveryNoteNoSeq: '納品書番号枝番',
      companyId: '宛先',
      title: '件名',
      detailCode: '細目コード',
      preview: 'プレビュー',
      send: '送信',
      costSharingDepartmentCode: '費用負担部門コード',
      exportRestriction: '輸出規制',
      paymentTermId: '支払条件',
      ownWorkFlowId: '自社ワークフロー',
      clientWorkFlowId: 'クライアント',
      completeInspection: '検収完了',
      caseNo: '依頼番号',
      productionOrderNo: '製造オーダー番号',
      deliveryNoteDetail: {
        section: '区分',
        itemName: '項目',
        quantity: '数量',
        unit: '単位',
        unitCost: '単価',
        amount: '金額',
        salesTax: '税率',
      },
      certificateComplete: {
        certificateComplete: '検収完了',
        title: '検収完了確認',
        costSharingDepartmentCode1: '費用負担部門コード1',
        costSharingDepartmentCode2: '費用負担部門コード2',
        productionOrderNo1: '製造オーダー番号1',
        productionOrderNo2: '製造オーダー番号2',
        expenseType: '細目コード',
        message: 'メッセージ',
      },
    },
    commonDate: {
      issueDate: '発行年月日',
      deliveryDate: '納品日',
      issueDateFrom: '検収日FROM',
      issueDateTo: '検収日TO',
    },
    commonFinanceTerm: {
      subTotal: '小計',
      includingTax: '(税込)',
      excludingTax: '(税抜)',
      currency: {
        yen: '円',
      },
    },
    dummyData: {
      jobNo: '00021',
      translatorName: '田中 太郎',
      purchaseOrderDate: '2023/07/20',
      deliveryDate: '2023/08/31',
    },
    taskStateList: {
      jobDetail: 'JOB明細',
      deleteJobDetail: '行を削除',
      deliveryItemUpload: '成果物アップロード',
      create: '新規作業作成',
      deptReqBelongTo: '依頼所属部門',
      requester: '依頼担当者',
      unitPrice: '単価',
      deliveryDate: '納品日',
      jobNo: 'JOB番号',
      taskTime: '時間',
      taskType: '作業種別',
      sellingPrice: '売価',
      totalCost: '社内＋原価',
      wordCount: '単語数',
      rate: 'Rate',
      sheetCount: '枚数',
      layout: 'Layout',
      invoicingFlag: '請求書発行済',
      task: '作業',
      accountsReceivableMonth: '売掛月',
      bulkInspectionCertificateModalTitle: '検収書一括送信',
      columns: {
        caseOrderAmount: '受注金額（税抜）',
        matchingCancel: '打診キャンセル',
        caseNo: '案件依頼番号',
        caseTitle: '案件タイトル',
        orderReceiveDate: '受注日',
        industryTypeName: '業種',
        requestDepartmentName1: '依頼部署',
        requestDepartmentAffiliatedName1: '依頼所属部門',
        requestDepartmentContactPersonName1: '依頼担当者',
        desiredDeliveryDate: '希望納期',
        invoiceNo: '請求書No',
        company: '依頼企業',
        purchaseOrder: '発注書',
        stocking: '仕入',
        totalCostRate: '原価率(プラス社内)',
        costRate: '原価率',
        unitCost: '単価',
        translatorLanguageName: '翻訳言語',
        task: '作業',
        stockingDate: '仕入日',
        inspectionDate: '検収日',
        duplicateJobDetail: 'JOBの複製',
        purchaseAfterAmountChange: '金額修正',
        cancel: 'キャンセル',
        completeInspection: '検収完了',
        inspectionCertificate: '検収書',
        translatorMatching: '打診',
        orderPurchase: '発注書作成',
        addTask: '作業を作成',
        version: 'バージョン',
        inspectionFlag: '検収済',
        cost: '原価',
        expense: '費用',
        unitPrice: '単価',
        layout: 'Layout',
        invoice: '請求書',
        updateUser: '更新ユーザ',
        updateDate: '更新日',
        detailNo: '明細No',
        subject: '件名',
        orderAmount: '発注金額',
        pageCount: '頁数',
        wordCount: '単語数',
        rate: 'Rate',
        sheetCount: '枚数',
        deliveryDate: '納入日',
        totalCost: '社内＋原価',
        sellingPrice: '売価',
        taskType: '作業種別',
        taskTime: '時間',
        jobNo: 'JOB番号',
        job: 'JOB',
        caseDetail: '案件詳細',
        duplicateBtn: '複製',
        statusBtnColumn: '状態',
        accountsReceivableMonth: '売掛月',
        translatorMatchingBtnColumn: '打診',
        invoiceBtnColumn: '請求書',
        duplicateTaskBtnLabel: '作業の複製',
        status: '状態',
        industryType: '業種',
        accountsPayableMonth: '買掛月',
        orderDate: '発注日',
        translator: '発注先',
        task_inspection_flag: '仕入検収',
        dateRecorded: '請求書受月',
        operationManager: '作業管理',
        trados: 'Trados',
        readability: 'Readbility',
        jobDetailWordCount: '単語数',
        jobDetailDeliveryDate: '指定納期',
        invoiceNoUpload: '請求書アップロード',
      },
      subColumns: {
        duplicateBtn: '複製',
        orderDate: '発注日',
        translateLanguageId: '翻訳言語',
        taskId: '作業ID',
        orderAmount: '発注金額',
        remarks: '備考',
        cancelBtn: 'キャンセル',
        PurchaseOrdBtn: '発注書',
        title: '件名',
        insCert: '検収書',
        invoiceBtn: '請求書',
      },
    },
    globalConstant: {
      quotationReport: '見積書',
      deliveryNoteReport: '納品書',
      orderReceiptReport: '注文請書',
      invoiceReport: '請求書',
      purchaseOrderReport: '発注書',
      certificateReport: '検収書',
    },
    searchConditions: {
      date_from: '発行日FROM',
      date_to: '発行日TO',
      amount_from: '金額FROM',
      amount_to: '金額TO',
      workInProgress: '仕掛品',
      status: '状態',
      caseTitle: '件名',
      ownCaseTitle: '件名(インター命名)',
      requestDepartmentName1: '依頼部署',
      ownContactPersonUser: 'インター担当者',
      caseNo: '依頼番号',
      jobNo: 'JOB番号',
      requestDeptName1: '依頼部署',
      requestDeptContactPersonName1: '依頼担当者',
      desiredDeliveryDateFrom: '希望納期FROM',
      desiredDeliveryDateTo: '希望納期TO',
      deliveryDateFrom: '納品日FROM',
      deliveryDateTo: '納品日TO',
      certificateCompletionDateFrom: '検収日FROM',
      certificateCompletionDateTo: '検収日TO',
      orderDateFrom: '発注日FROM',
      orderDateTo: '発注日TO',
      taskDeliveryDateFrom: '指定納期FROM',
      taskDeliveryDateTo: '指定納期TO',
      stockingDateFrom: '仕入日FROM',
      stockingDateTo: '仕入日TO',
      inspectionDateFrom: '検収日From',
      inspectionDateTo: '検収日To',
      generalDeliveryDateFrom: '納入日FROM',
      generalDeliveryDateTo: '納入日TO',
    },
    autoCalculateTable: {
      quantity: '数量',
      unitCost: '単価',
      itemName: '項目',
      amount: '金額',
      taxAmount: '消費税',
    },
    workFlowHistory: {
      title: '承認履歴',
      columns: {
        caseNo: '依頼番号',
        userFullname: 'ユーザ',
        jobNo: 'JOB番号',
        processingDate: '処理日',
        reportNo: '帳票No',
        reportType: '帳票名',
        status: '状態',
      },
      status: {
        Applicated: '申請済',
        Approved: '承認済',
        Rejected: '却下済',
        Cancelled: '取下げ済',
        Sent: '送信済',
        ApproveRequested: '承認依頼',
        Ordered: '承認(発注)',
        Cancel: 'キャンセル',
      },
      reportName: {
        1: '見積書',
        2: '注文請書',
        3: '納品書',
        4: '翻訳者発注書',
        5: '翻訳者検収書',
        6: '請求書',
        7: '島津本社請求書',
      },
    },
    inputTrackRecord: {
      name: '費用実績入力',
      totalExpense: '社内費用',
      cost: '原価',
      totalCost: '社内+原価',
      sellingPrice: '売価',
      internalUnitPrice: '社内単価',
      costRate: '原価率',
      totalCostRate: '原価率(+社内)',
      tableItems: {
        contactPersonUser: '担当者名',
        taskType: '作業種別',
        time: '時間',
        internalExpense: '社内費用',
      },
    },
  },
}

export const taxAmount = (tax_amount: number) => {
  return `${'消費税('}${tax_amount}${'%)'}`
}

export default jaTranslations
