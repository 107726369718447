import { createSlice } from '@reduxjs/toolkit'

import { projectListClientProps } from './types'

const initialState: projectListClientProps = {
  projectListClients: [],
  edit: false,
  projectListClient: null,
  loadingProjectListClient: false,
  loadingCreateProjectListClient: false,
  count: 0,
  next: '',
  previous: '',
}
export const ProjectListClient = createSlice({
  name: 'projectListClientReducer',
  initialState,
  reducers: {
    loadingProjectListClient: (state) => {
      state.loadingProjectListClient = true
    },
    getProjectListClientRequest: (state, action) => {
      state.loadingProjectListClient = true
    },
    getProjectListClientSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingProjectListClient = false
      state.projectListClients = results
      state.count = count
      state.next = next
      state.previous = previous
    },
    getProjectListClientFail: (state) => {
      state.loadingProjectListClient = false
    },
    getProjectListClientNextRequest: (state, action) => {
      state.loadingProjectListClient = true
    },
    getProjectListClientPreviousRequest: (state, action) => {
      state.loadingProjectListClient = true
    },
  },
})
export const {
  loadingProjectListClient,
  getProjectListClientSuccess,
  getProjectListClientFail,
  getProjectListClientRequest,
  getProjectListClientPreviousRequest,
  getProjectListClientNextRequest,
} = ProjectListClient.actions
export default ProjectListClient.reducer
