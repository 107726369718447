import React, { useState, Suspense, useLayoutEffect, useRef, lazy } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import { Card, CardHeader, FormGroup, Grid, Typography } from '@mui/material'

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import {
  handleFocusSaleTaxCommon,
  handleFocusTranslatorCommon,
  onChangeInputTranslatorCommon,
} from '../../../../CommonAppRedux/commonAppFunctions'
import { commonAppSelector } from '../../../../CommonAppRedux/selector'
import { authSelector } from '../../../../Login/Redux/selector'
import AppAutoComplete from '../../../../ReusableComponent/AppAutoComplete/AppAutoComplete'
import AppDatePicker from '../../../../ReusableComponent/AppDatePicker/AppDatePicker'
import AppTextField from '../../../../ReusableComponent/AppTextField/AppTextField'
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../ReusableComponent/CustomHooks/appHooks'
import { useInputSearch } from '../../../../ReusableComponent/CustomHooks/useInputSearch'
import FocusElementOnInvalidValidation from '../../../../ReusableComponent/FocusElementIfInvalid/FocusElementOnInvalidValidation'
import History from '../../../../ReusableComponent/History'
import IssueBarComponent from '../../../../ReusableComponent/IssueBarComponent/IssueBarComponent'
import ReasonForChange from '../../../../ReusableComponent/ReasonForChange/ReasonForChange'
import SaveFooterComponent from '../../../../ReusableComponent/SaveFooterButton/SaveFooterButton'
import {
  dateFormatter,
  formateNumberAgain,
} from '../../../../Utils/AppFormatFunction'
import { todayDate } from '../../../../Utils/AppFunction'
import messages from '../../../../Utils/ValidationMessage'
import { query } from '../../../../Utils/globalTypes'
import { taxSubTotalProps } from '../../../ProjectList/IssueReportPages/IssueQuotation'
import {
  clearHardResetJobList,
  clearJobDetailRow,
  clearJobTask,
  clearPurchaseChangeAmountById,
  clearPurchaseOrder,
  clearTranslatorCertificate,
  createTranslatorCertificateRequest,
  getJobDetailRowByIdRequest,
  getJobTaskByIdRequest,
  getPurchaseChangeAmountByIdRequest,
  getPurchaseOrderByIdRequest,
  getTranslatorCertificateByIdRequest,
  updateInspectionWithdrawalRequest,
  updateTranslatorCertificateRequest,
} from '../../Redux/jobListSlice'
import { jobListSelector } from '../../Redux/selector'
import { saleTax } from '../../Types'

const CommonReportTable = lazy(
  () =>
    import('../../../../ReusableComponent/CommonReportTable/CommonReportTable')
)
export interface certificate_details {
  uniqueId?: string
  job_no: string | number
  delivery_date: any
  tax_excluded_price: string | number
  tax_included_price: string | number
  inspection_date: any
  tax_amount: number
  sales_tax: saleTax | null
}

export interface translatorCertificate {
  id?: number
  job_task?: number
  certificate_no: string
  translator: any
  remarks: string
  reason: string
  comment: string
  issue_flag?: boolean
  certificate_details: certificate_details[]
  history?: any
  issue_date: Date | string | null
  tax_subtotal: taxSubTotalProps[]
  withdrawal_flag?: boolean
  version: number
}

const IssueCertificate = () => {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const translatorCertificateId = searchParams.get('certificate-id')
  const jobTaskId = searchParams.get('job-task-id')
  const edit = searchParams.get('edit')
  const issueCertificateId = searchParams.get('issue-certificate-id')

  const { state } = location
  const previousUrl = state?.path
  const { t } = useTranslation()
  const {
    translatorCertificate,
    jobTask,
    loadingCreateTranslatorCertificate,
    jobDetailRow,
    purchaseOrder,
    purchaseChangeAmountDetails,
  } = useAppSelector(jobListSelector)

  const taskJobNoDetailNo =
    (jobDetailRow?.job_no ?? '') + (jobDetailRow?.detail_no ?? '')
  const JobInspectionDate = jobTask?.inspection_date
  const {
    commonSalesTax,
    defaultSalesTax,
    loadingCommonTranslator,
    commonTranslator,
  } = useAppSelector(commonAppSelector)
  const dispatch = useAppDispatch()
  const updatedPurchaseOrderDetails = purchaseChangeAmountDetails?.map(
    (details: any) => {
      const matchingSubtotal = purchaseChangeAmountDetails?.tax_subtotal?.find(
        (subtotal: any) => {
          return subtotal.sales_tax.id === details.sales_tax.id
        }
      )
      return {
        ...details,
        job_no: taskJobNoDetailNo,
        delivery_date: jobTask?.stocking_date,
        inspection_date: details?.desired_delivery_date ?? JobInspectionDate,
        sub_total_id: matchingSubtotal?.id ? matchingSubtotal?.id : undefined,
      }
    }
  )
  useLayoutEffect(() => {
    handleFocusSaleTaxCommon(dispatch)
    if (jobTaskId) {
      dispatch(getJobTaskByIdRequest(jobTaskId))
    } else {
      dispatch(clearJobTask())
      dispatch(clearHardResetJobList())
    }
    return () => {
      dispatch(clearHardResetJobList())
    }
  }, [jobTaskId, location, dispatch, edit])

  useLayoutEffect(() => {
    if (jobTask?.job_detail) {
      dispatch(getJobDetailRowByIdRequest(jobTask?.job_detail))
    } else {
      dispatch(clearJobDetailRow())
    }
    if ((translatorCertificateId || issueCertificateId) && edit) {
      dispatch(
        getTranslatorCertificateByIdRequest(
          translatorCertificateId || issueCertificateId
        )
      )
    } else {
      dispatch(clearTranslatorCertificate())
    }
    if (jobTask?.translator_purchase_order?.translator_purchase_order) {
      dispatch(
        getPurchaseOrderByIdRequest(
          jobTask?.translator_purchase_order?.translator_purchase_order
        )
      )
    } else {
      dispatch(clearPurchaseOrder())
    }
    if (jobTask?.translator_purchase_order?.translator_purchase_order) {
      dispatch(
        getPurchaseChangeAmountByIdRequest(
          jobTask?.translator_purchase_order?.translator_purchase_order
        )
      )
    } else {
      dispatch(clearPurchaseChangeAmountById())
    }
  }, [
    jobTask,
    translatorCertificateId,
    issueCertificateId,
    location,
    dispatch,
    edit,
  ])

  const updatedTranslatorCertificateDetailsData =
    translatorCertificate?.certificate_details?.map((details: any) => {
      const matchingSubtotal = translatorCertificate?.tax_subtotal?.find(
        (subtotal: any) => {
          return subtotal?.sales_tax?.id === details?.sales_tax?.id
        }
      )
      return {
        ...details,
        inspection_date: details?.inspection_date ?? JobInspectionDate,
        sub_total_id: matchingSubtotal?.id ? matchingSubtotal?.id : undefined,
      }
    })

  const initialState: translatorCertificate = {
    certificate_no: !issueCertificateId
      ? translatorCertificate?.certificate_no
        ? translatorCertificate?.certificate_no
        : ''
      : '',
    translator: translatorCertificate
      ? translatorCertificate?.translator
      : purchaseOrder?.translator ?? null,
    remarks: translatorCertificate
      ? translatorCertificate?.remarks
      : purchaseOrder?.remarks ?? '',
    reason: '',
    comment: '',
    issue_date: translatorCertificate
      ? translatorCertificate?.issue_date
      : dateFormatter(new Date()),
    tax_subtotal: translatorCertificate?.tax_subtotal ??
      purchaseChangeAmountDetails?.tax_subtotal ?? [
        {
          sales_tax: defaultSalesTax,
          subtotal_amount: 0,
          amount: 0,
        },
      ],
    certificate_details: updatedTranslatorCertificateDetailsData
      ? updatedTranslatorCertificateDetailsData
      : updatedPurchaseOrderDetails ?? [
          {
            job_no: taskJobNoDetailNo,
            delivery_date: todayDate,
            inspection_date: JobInspectionDate,
            tax_excluded_price: 0,
            tax_included_price: 0,
            tax_amount: 0,
            sales_tax: purchaseOrder?.translator?.sales_tax,
          },
        ],
    version: translatorCertificate?.version ?? 0,
  }

  const validationSchema = Yup.object().shape({
    translator: Yup.object().nullable().required(t(messages.required)),
    remarks: Yup.string().nullable(),
    issue_date: Yup.mixed().required(t(messages.required)),
    certificate_details: Yup.array().of(
      Yup.object().shape({
        sales_tax: Yup.object().required(t(messages.required)),
        inspection_date: Yup.string().required(t(messages.required)),
      })
    ),
  })

  const columns = [
    {
      id: 'job_no',
      label: `${t('taskList.issuePurchase.tableItems.jobNo')}`,
      minWidth: 150,
      disabled: true,
    },
    {
      id: 'delivery_date',
      label: `${t('inspectionTaskStateList.columns.deliveryDate')}`,
      minWidth: 200,
      required: true,
      dateField: true,
    },
    {
      id: 'tax_excluded_price',
      label: `${t('taskList.issuePurchase.tableItems.taxExcludedPrice')}`,
      currency: true,
      minWidth: 150,
      taxExcludedPrice: true,
    },
    {
      id: 'tax_amount',
      label: `${t('quotation.tableItems.taxAmount')}`,
      minWidth: 150,
      currency: true,
      taxAmount: true,
    },
    {
      id: 'inspection_date',
      label: `${t('taskList.issueCertificate.inspectionDate')}`,
      minWidth: 200,
      dateField: true,
    },
    {
      id: 'tax_included_price',
      label: `${t('taskList.issuePurchase.tableItems.taxIncludedPrice')}`,
      currency: true,
      minWidth: 150,
      taxIncludedPrice: true,
    },
    {
      id: 'sales_tax',
      label: `${t('taskList.issuePurchase.tableItems.salesTax')}`,
      minWidth: 200,
      dropDown: true,
      options: commonSalesTax,
    },
    {
      id: 'actions',
      label: `${t('common.actions')}`,
      minWidth: 100,
      actions: true,
    },
  ]

  const [query, setQuery] = useState<query>({
    search: '',
    url: '',
  })

  useInputSearch(query)

  const nav = useNavigate()
  const [detailsData, setDetailsData] = useState([])
  const onSubmit = async (values: translatorCertificate, actions: any) => {
    const { translator, tax_subtotal } = values
    const updatedTaxSubTotal = tax_subtotal?.map((row: any) => ({
      ...row,
      sales_tax: row?.sales_tax?.id,
    }))
    if (jobTask?.translator_certificate?.translator_certificate && edit) {
      const updatedCertificateDetails = detailsData?.map((details: any) => ({
        ...details,
        sales_tax: details?.sales_tax?.id,
        tax_amount: formateNumberAgain(details.tax_amount),
      }))
      const updatedValues = {
        ...values,
        job_task: jobTaskId,
        translator: translator?.id ?? '',
        certificate_details: updatedCertificateDetails,
        tax_subtotal: updatedTaxSubTotal,
      }
      dispatch(
        updateTranslatorCertificateRequest({
          id: jobTask?.translator_certificate?.translator_certificate,
          values: updatedValues,
          nav: nav,
          actions,
          previousUrl,
        })
      )
    } else {
      const updatedCertiticateDetails = detailsData?.map((details: any) => ({
        ...details,
        sales_tax: details?.sales_tax?.id,
        tax_amount: formateNumberAgain(details.tax_amount),
      }))
      const updatedValues = {
        ...values,
        job_task: jobTaskId,
        translator: translator?.id ?? '',
        certificate_details: updatedCertiticateDetails,
        tax_subtotal: updatedTaxSubTotal,
      }
      dispatch(
        createTranslatorCertificateRequest({
          values: updatedValues,
          nav: nav,
          actions,
          previousUrl,
        })
      )
    }
  }

  const focus_save_btn = useRef(null)
  const handleInspectionWithdrawal = () => {
    dispatch(
      updateInspectionWithdrawalRequest({
        id: jobTask?.translator_certificate?.translator_certificate,
        version: translatorCertificate?.version,
        nav,
        previousUrl,
        jobTaskId: jobTaskId,
      })
    )
  }

  const handlePreview = () => {
    const url = 'translator-certificate-report'
    const queryParams = `?id=${translatorCertificateId}&preview-report-url=${url}&file-name=${
      translatorCertificate?.certificate_no
    }${!translatorCertificate?.withdrawal_flag ? '&active-send=true' : ''}`
    nav(`/preview${queryParams}`)
  }

  const [readOnlyMode, setReadOnlyMode] = useState(false)
  const { is_staff } = useAppSelector(authSelector)
  useLayoutEffect(() => {
    if (!is_staff) {
      setReadOnlyMode(true)
    }

    return () => {
      setReadOnlyMode(false)
    }
  }, [])
  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialState}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          values,
          handleChange,
          setFieldValue,
          handleBlur,
          errors,
          validateForm,
          submitForm,
          touched,
        }) => {
          const handleSave = async () => {
            try {
              await validateForm()
              await submitForm()
            } catch (validationError) {
              console.log('the validation error is', validationError)
            }
          }
          const buttonConfigs = [
            ...(!translatorCertificate?.withdrawal_flag
              ? [
                  {
                    label: translatorCertificate
                      ? 'common.update'
                      : 'common.save',
                    handler: handleSave,
                    isShowAlert: true,
                    focusBtn: focus_save_btn,
                    loading: loadingCreateTranslatorCertificate,
                    message: translatorCertificate
                      ? t('confirmMessage.update')
                      : t('confirmMessage.save'),
                  },
                ]
              : []),
            ...(translatorCertificate
              ? [
                  {
                    label: 'common.preview',
                    handler: handlePreview,
                  },
                ]
              : []),
            ...(translatorCertificate &&
            !translatorCertificate?.withdrawal_flag &&
            jobTask?.job_task_status_code === '054'
              ? [
                  {
                    label: 'common.withdrawal',
                    isShowAlert: true,
                    handler: handleInspectionWithdrawal,
                    message: t('confirmMessage.withdrawal'),
                  },
                ]
              : []),
          ]
          const handleButtonAction = (action: string) => {
            const buttonConfig = buttonConfigs.find(
              (config) => config.label === action
            )
            if (buttonConfig) {
              const { handler } = buttonConfig
              handler()
            }
          }
          return (
            <>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Grid item sx={{ mb: 4 }}>
                  <SaveFooterComponent
                    handleClick={handleButtonAction}
                    buttonConfigs={buttonConfigs}
                  />
                </Grid>
                {jobTask?.translator_certificate?.translator_certificate && (
                  <ReasonForChange
                    name="reason"
                    setFieldValue={setFieldValue}
                    onChange={handleChange}
                  />
                )}
                <IssueBarComponent
                  isIssued={translatorCertificate?.issue_flag}
                />
                <div className={readOnlyMode ? 'disabled-form' : ''}>
                  <Form autoComplete="off" noValidate>
                    <Card sx={{ mb: 2, bgcolor: '#d7e2ea' }}>
                      <CardHeader
                        title={
                          <Typography
                            variant="h6"
                            sx={{ fontWeight: 'bold', color: 'white' }}
                          >
                            {t('taskList.certificateIssue')}
                          </Typography>
                        }
                        sx={{
                          backgroundColor: '#062f96',
                          height: '48px',
                        }}
                      />
                      <h1 style={{ textAlign: 'center' }}>
                        {t('taskList.certificateIssue')}
                      </h1>
                      <Grid
                        container
                        spacing={2}
                        padding={4}
                        alignItems="center"
                      >
                        <Grid item xs={6}>
                          <Grid container alignItems="center" mb={0}>
                            <Grid item xs={3.7}>
                              {t('taskList.issueCertificate.certificate_no')}:
                            </Grid>
                            <AppTextField
                              xs={5}
                              disabled
                              name="certificate_no"
                              value={values?.certificate_no}
                              label={t(
                                'taskList.issueCertificate.certificate_no'
                              )}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Grid>
                        </Grid>

                        <Grid item xs={12}>
                          <Grid container alignItems="center">
                            <Grid item xs={1.8}>
                              {t('taskList.issuePurchase.translator')}:
                            </Grid>
                            <AppAutoComplete
                              xs={2.5}
                              // required
                              disabled={readOnlyMode}
                              name="translator"
                              value={values?.translator}
                              options={commonTranslator}
                              loading={loadingCommonTranslator}
                              getOptionLabel={(option: any) => option.name}
                              label={t('taskList.issuePurchase.translator')}
                              onChange={(event: any, value: any) => {
                                if (value !== null) {
                                  setFieldValue('translator', value)
                                  const updatedDetails =
                                    values.certificate_details.map((detail) => {
                                      const taxRate = parseFloat(
                                        value.sales_tax.tax_rate
                                      )
                                      const excludedPrice =
                                        typeof detail.tax_excluded_price ===
                                        'number'
                                          ? detail.tax_excluded_price
                                          : parseFloat(
                                              detail.tax_excluded_price
                                            )

                                      const taxAmount =
                                        (excludedPrice * taxRate) / 100
                                      const roundedTaxAmount =
                                        taxAmount.toFixed(0)

                                      const includedPrice =
                                        excludedPrice * (1 + taxRate / 100)
                                      const roundedIncludedPrice =
                                        includedPrice.toFixed(0)

                                      return {
                                        ...detail,
                                        sales_tax: value.sales_tax,
                                        tax_amount:
                                          parseFloat(roundedTaxAmount),
                                        tax_included_price:
                                          parseFloat(roundedIncludedPrice),
                                      }
                                    })

                                  const taxAmountSum = updatedDetails.reduce(
                                    (sum, detail) => sum + detail.tax_amount,
                                    0
                                  )
                                  const taxSubtotalAmountSum =
                                    updatedDetails.reduce(
                                      (sum, detail) =>
                                        sum +
                                        (typeof detail.tax_excluded_price ===
                                        'number'
                                          ? detail.tax_excluded_price
                                          : parseFloat(
                                              detail.tax_excluded_price
                                            )),
                                      0
                                    )

                                  const updatedTaxSubtotal = {
                                    sales_tax: value?.sales_tax,
                                    amount: taxAmountSum,
                                    subtotal_amount: taxSubtotalAmountSum,
                                  }
                                  setFieldValue(
                                    'certificate_details',
                                    updatedDetails
                                  )
                                  setFieldValue('tax_subtotal', [
                                    updatedTaxSubtotal,
                                  ])
                                } else {
                                  setFieldValue('translator', null)
                                }
                              }}
                              onFocus={() =>
                                handleFocusTranslatorCommon(dispatch)
                              }
                              onBlur={handleBlur}
                              inputOnchange={(e: any) =>
                                onChangeInputTranslatorCommon(
                                  e.target,
                                  setQuery
                                )
                              }
                            />
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container alignItems="center">
                            <Grid item xs={1.8}>
                              {t('taskList.issueCertificate.issueDate')}:
                            </Grid>
                            <AppDatePicker
                              disabled={readOnlyMode}
                              xs={2.5}
                              id={t('taskList.issueCertificate.issueDate')}
                              value={values?.issue_date ?? ''}
                              name="issue_date"
                              onChange={(value: any) => {
                                if (value !== null) {
                                  setFieldValue(
                                    'issue_date',
                                    dateFormatter(value)
                                  )
                                } else {
                                  setFieldValue('issue_date', '')
                                }
                              }}
                              variant="outlined"
                              sx={{
                                width: '100%',
                                size: 'medium',
                                '& .MuiInputBase-root': {
                                  height: '39px',
                                  display: 'flex',
                                  alignItems: 'center',
                                },
                                '& .MuiInputLabel-root': {
                                  textAlign: 'center',
                                },
                              }}
                            />
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container alignItems="center">
                            <Grid item xs={1.8}>
                              {t('taskList.issueCertificate.remarks')}:
                            </Grid>
                            <AppTextField
                              xs={2.5}
                              disabled={readOnlyMode}
                              name="remarks"
                              label={t('taskList.issueCertificate.remarks')}
                              value={values?.remarks}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              multiline // Set the multiline prop to true
                              rows={4}
                              InputProps={{
                                style: { padding: '10px' }, // Add padding to the input element
                              }}
                            />
                          </Grid>
                        </Grid>
                        <Grid item xs={2}>
                          <FormGroup
                            style={{ alignContent: 'space-around' }}
                          ></FormGroup>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        padding={4}
                        rowSpacing={2}
                        alignItems="center"
                      ></Grid>
                      <Suspense fallback={<></>}>
                        <CommonReportTable
                          setDetailsData={setDetailsData}
                          columns={columns}
                          taxType={`${t('commonFinanceTerm.excludingTax')})`}
                          keyName="certificate_details"
                          values={values?.certificate_details}
                          retrieve_tax_subtotal={values?.tax_subtotal}
                          handleChange={handleChange}
                          setFieldValue={setFieldValue}
                          handleBlur={handleBlur}
                          initialValues={initialState?.certificate_details}
                          setQuery={setQuery}
                          disabled={readOnlyMode}
                          touched={touched}
                          bgColor="#d7e2ea"
                          edit={edit}
                          translatorsSalesTax={values?.translator?.sales_tax}
                          translator_certificate={true}
                        />
                      </Suspense>
                    </Card>
                    <FocusElementOnInvalidValidation />
                  </Form>
                </div>
                {jobTask?.translator_certificate?.translator_certificate &&
                  is_staff && (
                    <History
                      history={
                        translatorCertificate?.history?.length > 0
                          ? translatorCertificate?.history
                          : []
                      }
                    />
                  )}
              </LocalizationProvider>
            </>
          )
        }}
      </Formik>
    </>
  )
}

export default IssueCertificate
