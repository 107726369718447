import React, { Suspense, lazy, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { Grid, Typography } from '@mui/material'

import { t } from 'i18next'

import {
  alertErrorAction,
  alertInfoAction,
  commonUrlFilterData,
} from '../../../CommonAppRedux/commonAppSlice'
import { commonAppSelector } from '../../../CommonAppRedux/selector'
import ConfirmModal from '../../../ReusableComponent/ConfirmModal/ConfirmModal'
import {
  useAppDispatch,
  useAppSelector,
} from '../../../ReusableComponent/CustomHooks/appHooks'
import Modal from '../../../ReusableComponent/Modal'
import SaveFooterComponent from '../../../ReusableComponent/SaveFooterButton/SaveFooterButton'
import {
  getJobListDetailRequest,
  getJobListRequest,
} from '../Redux/jobListSlice'
import { searchSubmitValuesProps } from '../Types'

//below import with lazy loading, which is typically used when you want to load modules or components asynchronously, especially when they are not immediately needed.
const CompleteInspectionListTable = lazy(
  () => import('./CompleteInspectionListTable')
)
const CompleteInspectionList = () => {
  const dispatch = useAppDispatch()

  const { rowsPerPage, page, tableCheckBoxData, collapseTableCheckBoxData } =
    useAppSelector(commonAppSelector)
  console.log(tableCheckBoxData, 'TableCheckBoxData')
  const uniqueCheck = tableCheckBoxData.find(
    (row: any) => row?.invoice?.invoice !== null
  )
  const uniqueCheckFilter = tableCheckBoxData.filter(
    (row: any) => row?.invoice?.invoice !== null
  )
  const idString = uniqueCheckFilter.map((row) => row.detail_no).join(',')
  const location = useLocation()
  const handleInvoice = () => {
    if (uniqueCheck) {
      dispatch(
        alertErrorAction(
          `Job Detail No.${idString} Already Have Invoice So Make Edit`
        )
      )
    } else {
      if (tableCheckBoxData?.length > 0) {
        const queryParams = ``
        navigate(`/issue-invoice${queryParams}`, {
          state: { path: location?.pathname + location?.search },
        })
      } else {
        dispatch(alertInfoAction(t('customAlertMessage.pleaseSelectJobDetail')))
      }
    }
  }
  const handlePaymentSchedule = () => {
    navigate('/issue-payment-schedule')
  }

  // const handleIssueInvoiceData = () => {
  //   navigate('/issue-invoice-data')
  // }
  const [showBulkInspectionCertificate, setShowBulkInspectionCertificate] =
    useState(false)
  const handleBulkInspectionCertificate = () => {
    setShowBulkInspectionCertificate(true)
  }
  const buttonConfigs = [

    {
      label: 'common.invoice',
      handler: handleInvoice,
    },
    // {
    //   label: 'jobList.paymentSchedule',
    //   handler: handlePaymentSchedule,
    // },
    // {
    //   label: 'jobList.bulkInspectionCertificate',
    //   handler: handleBulkInspectionCertificate,
    // },
    // {
    //   label: 'jobList.issueInvoiceData.btnName',
    //   handler: handleIssueInvoiceData,
    // },
  ]

  const handleButtonAction = (action: string) => {
    const buttonConfig = buttonConfigs.find((config) => config.label === action)
    if (buttonConfig) {
      const { handler } = buttonConfig
      handler()
    }
  }
  const navigate = useNavigate()

  let bulkInspectionCertificateModalProps: any = useMemo(() => {
    return {
      title: t('common.confirm'),
      maxWidth: 'sm',
      showModal: showBulkInspectionCertificate,
      setShowModal: setShowBulkInspectionCertificate,
    }
  }, [showBulkInspectionCertificate])

  //filter for test purpose make component soon---
  const params = useParams<{ values?: string }>()
  const decodedValues = useMemo(() => {
    if (params.values) {
      return decodeURIComponent(params.values)
    }
    return ''
  }, [params.values])

  // Define the type for parsedValues
  let parsedValues: searchSubmitValuesProps | null = null

  try {
    if (decodedValues) {
      parsedValues = JSON.parse(decodedValues)
    }
  } catch (error) {
    console.error('Error parsing JSON:', error)
  }

  useEffect(() => {
    dispatch(commonUrlFilterData(parsedValues))
    dispatch(getJobListRequest({ page: page, rowsPerPage: rowsPerPage }))
    dispatch(
      getJobListDetailRequest({
        page: page,
        rowsPerPage: rowsPerPage,
        filterData: parsedValues,
      })
    )
  }, [rowsPerPage, page])

  return (
    <React.Fragment>
      <Grid item sx={{ mb: 4 }}>
        <SaveFooterComponent
          handleClick={handleButtonAction}
          buttonConfigs={buttonConfigs}
        />
      </Grid>
      <Typography variant="h6" fontWeight="bold" sx={{ padding: '16px' }}>
        {t('menuName.completeInspectionList')}
      </Typography>
      <Suspense fallback={<></>}></Suspense>
      <Suspense fallback={<></>}>
        <CompleteInspectionListTable />
      </Suspense>
      {showBulkInspectionCertificate && (
        <Suspense fallback={<></>}>
          <Modal {...bulkInspectionCertificateModalProps}>
            <ConfirmModal
              setShowConfirmModal={setShowBulkInspectionCertificate}
              showConfirmModal={showBulkInspectionCertificate}
              title={t('jobList.bulkInspectionCertificateModalTitle')}
            />
          </Modal>
        </Suspense>
      )}
    </React.Fragment>
  )
}

export default CompleteInspectionList
