import * as React from 'react'

import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Typography from '@mui/material/Typography'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import { t } from 'i18next'

interface props {
  searchLabel: string
  children: React.ReactNode
  byDefault?: boolean
}

const CollapsableSearch = ({ searchLabel, children, byDefault }: props) => {
  const [expanded, setExpanded] = React.useState<string | false>(
    byDefault ? 'panel1' : false
  )

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false)
    }

  return (
    <>
      <Accordion
        expanded={expanded === 'panel1'}
        onChange={handleChange('panel1')}
        sx={{ mb: 2 }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: 'white', fontSize: 28 }} />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          sx={{
            backgroundColor: '#062f96',
            height: '38px',
          }}
        >
          <Typography
            variant="h6"
            sx={{
              width: '33%',
              flexShrink: 0,
              fontWeight: 'bold',
              color: 'white',
              transition: 'all 1s ease-out',
              whiteSpace: 'nowrap',
            }}
          >
            {t(`${searchLabel}`)}
          </Typography>
          <Typography
            sx={{
              marginTop: 0.5,
              marginRight: 1,
              marginLeft: 'auto',
              fontWeight: 'bold',
              color: 'white',
              transition: 'all 10s ease-out',
            }}
          >
            {expanded ? t('common.Expanded') : t('common.Collapse')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </Accordion>
    </>
  )
}

export default CollapsableSearch
