import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import {
  Button,
  IconButton,
  TextField,
  Typography,
  Toolbar,
} from '@mui/material'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import InputAdornment from '@mui/material/InputAdornment'
import Paper from '@mui/material/Paper'

import LockResetIcon from '@mui/icons-material/LockReset'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'

import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import {
  useAppDispatch,
  useAppSelector,
} from '../../ReusableComponent/CustomHooks/appHooks'
import { resetPasswordRequest } from '../Redux/authSlice'
import { authSelector } from '../Redux/selector'

type ResetPasswordInputValues = {
  new_password1: string
  new_password2: string
}

//initial form values
const initialValues: ResetPasswordInputValues = {
  new_password1: '',
  new_password2: '',
}

const ResetPassword: React.FC = () => {
  const [showPassword, setShowPassword] = React.useState(false)
  const { loading } = useAppSelector(authSelector)
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const nav = useNavigate()

  const { uid, token } = useParams()

  const handleClickShowPassword = () => setShowPassword((show) => !show)

  //validation for form fields
  const validationSchema = Yup.object().shape({
    new_password1: Yup.string().required(t('validationMessage.required')),
    new_password2: Yup.string()
      .required(t('validationMessage.required'))
      .oneOf(
        [Yup.ref('new_password1'), ''],
        t('validationMessage.passwordMatch')
      ),
  })

  //handle form submittion
  const handleSubmit = ({
    new_password1,
    new_password2,
  }: ResetPasswordInputValues) => {
    if (!uid || !token) return
    dispatch(
      resetPasswordRequest({
        uid,
        token,
        new_password1,
        new_password2,
      })
    )
  }

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100vh"
      >
        <Paper elevation={20}>
          <Formik
            // enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, handleChange, handleBlur, touched, errors }) => {
              return (
                <Form autoComplete="off">
                  <AppBar position="static">
                    <Toolbar>
                      <LockResetIcon />
                      <Typography variant="h5" sx={{ ml: 2 }}>
                        {t('auth.resetPassword')}
                      </Typography>
                    </Toolbar>
                  </AppBar>
                  <Grid container sx={{ mt: 0, ml: 0, mx: 'auto' }}>
                    <Grid container sx={{ p: 2 }} spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          id="new_password1"
                          name="new_password1"
                          type={showPassword ? 'text' : 'password'}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          fullWidth
                          label={t('auth.newPassword')}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  // onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          error={
                            touched.new_password1 &&
                            Boolean(errors.new_password1)
                          }
                          helperText={
                            touched.new_password1 && errors.new_password1
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="new_password2"
                          name="new_password2"
                          type={showPassword ? 'text' : 'password'}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          fullWidth
                          label={t('auth.confirmNewPassword')}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle confirm password visibility"
                                  onClick={handleClickShowPassword}
                                  // onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          error={
                            touched.new_password2 &&
                            Boolean(errors.new_password2)
                          }
                          helperText={
                            touched.new_password2 && errors.new_password2
                          }
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Button
                          size="large"
                          variant="contained"
                          onClick={() => nav('/login')}
                        >
                          {t('common.goBack')}
                        </Button>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sx={{ display: 'flex', flexDirection: 'row-reverse' }}
                      >
                        <Button
                          size="large"
                          variant="contained"
                          type="submit"
                          disabled={loading}
                        >
                          {t('auth.submit')}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Form>
              )
            }}
          </Formik>
        </Paper>
      </Box>
    </>
  )
}

export default ResetPassword
