import ListIcon from '@mui/icons-material/List'

import { t } from 'i18next'

const menuConfig = [
  {
    key: 'ProjectList',
    translationTitle: 'projectList',
    to: '/project-list',
    icon: ListIcon,
    isStaff: true,
  },
  {
    key: 'QuotationList',
    translationTitle: 'quotationList',
    to: '/quotation-request-list',
    icon: ListIcon,
    isStaff: true,
  },
  {
    key: 'completeQuotation',
    translationTitle: 'completeQuotationList',
    to: '/complete-quotation-list',
    icon: ListIcon,
    isStaff: true,
  },
  {
    key: 'orderList',
    translationTitle: 'orderList',
    to: '/order-list',
    icon: ListIcon,
    isStaff: true,
  },
  {
    key: 'taskStateList',
    translationTitle: 'taskStateList',
    to: '/task-state-list',
    icon: ListIcon,
    isStaff: true,
    spacing: '30px',
  },
  {
    key: 'InspectionTaskStateList',
    translationTitle: 'inspectionTaskStateList',
    to: '/inspection-task-state-list',
    icon: ListIcon,
    isStaff: true,
    spacing: '60px',
  },
  {
    key: 'billWaitingProjectList',
    translationTitle: 'waitingBillList',
    to: '/waiting-bill-list',
    icon: ListIcon,
    isStaff: true,
    spacing: '60px',
  },
  {
    key: 'deliveryCompleteList',
    translationTitle: 'completeDeliveryList',
    to: '/complete-delivery-list',
    icon: ListIcon,
    isStaff: true,
  },
  {
    key: 'JobList',
    translationTitle: 'jobList',
    to: '/job-list',
    icon: ListIcon,
    isStaff: true,
  },
  {
    key: 'billableList',
    translationTitle: 'billableList',
    to: '/bill-able-list',
    icon: ListIcon,
    isStaff: true,
  },
  {
    key: 'ExportInvoiceDataList',
    translationTitle: 'exportInvoiceDataList',
    to: '/export-invoice-data-list',
    icon: ListIcon,
    isStaff: true,
    spacing: '30px',
  },
  {
    key: 'billedList',
    translationTitle: 'billedList',
    to: '/billed-list',
    icon: ListIcon,
    isStaff: true,
  },
  {
    key: 'ProjectCreate',
    translationTitle: 'projectCreate',
    to: '/create-agency-project',
    icon: ListIcon,
    isStaff: true,
  },
  {
    key: 'ClientProjectCreate',
    translationTitle: 'clientProjectCreate',
    to: '/create-agency-project',
    icon: ListIcon,
    isStaff: false,
  },
  {
    key: 'ProjectListClient',
    translationTitle: 'projectListClient',
    to: '/project-list-client',
    icon: ListIcon,
    isStaff: false,
  },
]

if (process.env.NODE_ENV === 'development') {
  menuConfig.push({
    key: 'workflowTest',
    translationTitle: 'workflowTest',
    to: '/workflow-test',
    icon: ListIcon,
    isStaff: true,
  })
  menuConfig.push({
    key: 'workflowTest',
    translationTitle: 'workflowTest',
    to: '/workflow-test',
    icon: ListIcon,
    isStaff: false,
  })
}
export default menuConfig
