import { BrowserRouter, useRoutes } from 'react-router-dom'

import { commonAppSelector } from './components/CommonAppRedux/selector'
import CustomAlert from './components/CustomAlert/CustomAlert'
import { useAppSelector } from './components/ReusableComponent/CustomHooks/appHooks'
import routeConfig from './route/routeConfig'
import "./app.css"
const GetRoutes = () => {
  const routes = useRoutes(routeConfig)
  return routes
}

export default function App() {
  const { open, message } = useAppSelector(commonAppSelector)
  return (
    <>
      {open && <CustomAlert openAlert={open} data={message} />}
      <BrowserRouter>
        <GetRoutes />
      </BrowserRouter>
    </>
  )
}
