import { saveAs } from 'file-saver'

import { axiosInstance } from '../../../Utils/axios'
import { getApiProps } from '../../../Utils/globalTypes'
import { generateQueryString } from '../../../Utils/AppFunction'

const billableList = '/billable-list'
export const getBillableList = (data: getApiProps) => {
  const { rowsPerPage, page,filterData } = data
  return axiosInstance.get(
    `${billableList}?offset=${rowsPerPage * page}&limit=${rowsPerPage}&${filterData?`${generateQueryString(filterData)}`:''}`
  )
}

export const getBillableListCSV = async (id: number[]) => {
  try {
    const { data, headers } = await axiosInstance.get(`${billableList}`, {
      params: {
        id,
      },
      paramsSerializer: {
        indexes: null,
      },
      headers: {
        Accept: 'text/csv',
      },
      responseType: 'blob',
    })
    const mineType = headers['content-type']
    const dispostion = headers['content-disposition']
    const fileName = decodeURI(
      dispostion.split(';')[1].split('=')[1].replace('"', '').replace('"', '')
    )
    const blob = new Blob([data], { type: mineType })
    saveAs(blob, fileName)
  } catch (error) {
    console.log(error)
  }
}
