import React, {
  useState,
  useEffect,
  Suspense,
  lazy,
  useLayoutEffect,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import { Grid } from '@mui/material'

import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import {
  handleFocusCompanyCommon,
  handleFocusExportRestrictionCommon,
  handleFocusPaymentTermsCommon,
  handleFocusSaleTaxCommon,
  onChangeInputCompanyCommon,
  onChangeInputExportRestrictionCommon,
} from '../../../CommonAppRedux/commonAppFunctions'
import { getCommonPaymentTermsRequest } from '../../../CommonAppRedux/commonAppSlice'
import { commonAppSelector } from '../../../CommonAppRedux/selector'
import { commonOptionType } from '../../../CustomAlert/types'
import { authSelector } from '../../../Login/Redux/selector'
import AppAutoComplete from '../../../ReusableComponent/AppAutoComplete/AppAutoComplete'
import AppDatePicker from '../../../ReusableComponent/AppDatePicker/AppDatePicker'
import AppTextField from '../../../ReusableComponent/AppTextField/AppTextField'
import AppTitleCard from '../../../ReusableComponent/AppTitleCard/AppTitleCard'
import {
  useAppDispatch,
  useAppSelector,
} from '../../../ReusableComponent/CustomHooks/appHooks'
import { useInputSearch } from '../../../ReusableComponent/CustomHooks/useInputSearch'
import FocusElementOnInvalidValidation from '../../../ReusableComponent/FocusElementIfInvalid/FocusElementOnInvalidValidation'
import History from '../../../ReusableComponent/History'
import IssueBarComponent from '../../../ReusableComponent/IssueBarComponent/IssueBarComponent'
import ReasonForChange from '../../../ReusableComponent/ReasonForChange/ReasonForChange'
import SaveFooterComponent from '../../../ReusableComponent/SaveFooterButton/SaveFooterButton'
import WorkFlowPanel from '../../../ReusableComponent/Workflow'
import { workflowSelector } from '../../../ReusableComponent/Workflow/Redux/selector'
import WorkFlowHistoryTable from '../../../ReusableComponent/WorkflowHistory'
import { dateFormatter } from '../../../Utils/AppFormatFunction'
import { todayDate } from '../../../Utils/AppFunction'
import messages, {
  maxCharValidation,
  minCharValidation,
} from '../../../Utils/ValidationMessage'
import {
  defaultRowsPerPage,
  selectTypePaymentTerms,
} from '../../../Utils/globalConstant'
import { InputChangeEvent, query } from '../../../Utils/globalTypes'
import UsermadeInvoiceUpload from '../../JobList/IssueInvoicePages/UsermadeInvoiceUpload'
import PreviewIndex from '../../Preview'
import {
  clearDataProjectDetails,
  clearHardResetProjectList,
  clearProjectDetailInvoice,
  createProjectIssueInvoiceRequest,
  getProjectDeliveryNoteApproveByIdRequest,
  getCertificateByIdRequest,
  getProjectDetailByIdRequest,
  getProjectDetailInvoiceByIdRequest,
  getUserMadeInvoiceStatusRequest,
  updateProjectIssueInvoiceRequest,
} from '../Redux/projectListSlice'
import { projectListSelector } from '../Redux/selector'
import { initialProjectIssueInvoiceProps } from '../Types'

const CommonReportTable = lazy(
  () => import('../../../ReusableComponent/CommonReportTable/CommonReportTable')
)

const calculateFutureDate = (acceptanceDate: any, getPaymentTerm: any) => {
  const myDate = new Date(acceptanceDate)
  const dayOfMonth = myDate.getDate()
  let updateMonth = 0

  if (
    getPaymentTerm &&
    typeof getPaymentTerm.payment_deadline_month === 'number'
  ) {
    if (dayOfMonth > getPaymentTerm.cutoff_date) {
      updateMonth = getPaymentTerm.payment_deadline_month + 1
    } else {
      updateMonth = getPaymentTerm.payment_deadline_month
    }

    if (!isNaN(updateMonth)) {
      myDate.setMonth(myDate.getMonth() + updateMonth)
      if (getPaymentTerm.payment_deadline_day === 31) {
        myDate.setMonth(myDate.getMonth() + 1)
        myDate.setDate(0)
      } else {
        myDate.setDate(getPaymentTerm.payment_deadline_day)
      }
      return myDate.toISOString().split('T')[0]
    } else {
      console.error('Invalid value for updateMonth:', updateMonth)
    }
  } else {
    return null
  }
}

const ProjectIssueInvoice = () => {
  const dispatch = useAppDispatch()
  const {
    projectDetailInvoice,
    projectDetail,
    loadingCreateProjectIssueInvoice,
    ProjectDeliveryNoteApprove,
    certificate,
  } = useAppSelector(projectListSelector)
  const location = useLocation()

  const searchParams = new URLSearchParams(location.search)
  const { state } = location
  const previousUrl = state?.path
  const id = searchParams.get('case-id')
  const invoiceId = searchParams.get('invoice-id')
  const edit = searchParams.get('edit')

  useEffect(() => {
    dispatch(getCommonPaymentTermsRequest(defaultRowsPerPage))
  }, [])

  useLayoutEffect(() => {
    handleFocusPaymentTermsCommon(dispatch)
    handleFocusSaleTaxCommon(dispatch)
    if (id) {
      dispatch(getProjectDetailByIdRequest(id))
    } else {
      dispatch(clearDataProjectDetails())
      dispatch(clearHardResetProjectList())
    }
    return () => {
      dispatch(clearHardResetProjectList())
    }
  }, [id, location, dispatch, edit])

  useLayoutEffect(() => {
    if (edit) {
      dispatch(getProjectDetailInvoiceByIdRequest(invoiceId))
    } else if (projectDetail?.case_report_relation?.delivery_note) {
      dispatch(
        getProjectDeliveryNoteApproveByIdRequest(
          projectDetail?.case_report_relation?.delivery_note
        )
      )
      dispatch(
        getCertificateByIdRequest(
          projectDetail?.case_report_relation?.certificate
        )
      )
    } else {
      dispatch(clearProjectDetailInvoice())
    }
  }, [projectDetail, location, dispatch, edit])

  console.log(
    projectDetailInvoice,
    'projectDetailInvoiceprojectDetailInvoiceprojectDetailInvoice'
  )
  const navigate = useNavigate()
  const {
    defaultSalesTax,
    commonCompanies,
    commonPaymentTerms,
    commonSalesTax,
    commonExportRestrictions,
  } = useAppSelector(commonAppSelector)
  const handleUsermadeInvoice = () => {
    dispatch(
      getUserMadeInvoiceStatusRequest({
        id: projectDetailInvoice?.id,
        navigate,
        location,
      })
    )
  }

  const { t } = useTranslation()

  const columns = [
    {
      id: 'item_name',
      label: `${t('issueInvoice.addIssueInvoice.itemName')}`,
      minWidth: 100,
    },
    {
      id: 'quantity',
      label: `${t('issueInvoice.addIssueInvoice.quantity')}`,
      minWidth: 50,
      qty: true,
      currency: true,
    },
    {
      id: 'unit_cost',
      label: `${t('issueInvoice.addIssueInvoice.unitCost')}`,
      minWidth: 50,
      cost: true,
      currency: true,
    },
    {
      id: 'sales_tax',
      label: `${t('issueInvoice.addIssueInvoice.salesTax')}`,
      currency: true,
      minWidth: 250,
      tax: true,
      dropDown: true,
      options: commonSalesTax,
    },
    {
      id: 'amount',
      label: `${t('issueInvoice.addIssueInvoice.totalAmount')}`,
      minWidth: 100,
      currency: true,
      amount: true,
    },
    {
      id: 'actions',
      label: `${t('common.actions')}`,
      minWidth: 100,
      actions: true,
    },
  ]
  const updatedInvoiceDetailsData = projectDetailInvoice?.invoice_details?.map(
    (details: any) => {
      const matchingSubtotal = projectDetailInvoice?.tax_subtotal?.find(
        (subtotal: any) => {
          return subtotal?.sales_tax?.id === details?.sales_tax?.id
        }
      )
      return {
        ...details,
        sub_total_id: matchingSubtotal?.id ? matchingSubtotal?.id : undefined,
      }
    }
  )

  const updatedDeliveryNoteDetails =
    ProjectDeliveryNoteApprove?.delivery_note_details?.map((details: any) => {
      const { id, ...rest } = details
      return {
        ...rest,
      }
    })
  const updatedDeliveryNoteTaxSubTotalDetails =
    ProjectDeliveryNoteApprove?.delivery_note_tax_subtotal?.map(
      (details: any) => {
        const { id, ...rest } = details
        return {
          ...rest,
        }
      }
    )

  const acceptanceDate = projectDetail?.certificate_completion_date ?? todayDate
  const getPaymentTerm = commonPaymentTerms?.find((row) =>
    row?.id === ProjectDeliveryNoteApprove?.company?.payment_terms
      ? ProjectDeliveryNoteApprove?.company?.payment_terms
      : projectDetail?.general_affairs_flag && projectDetail?.request_company
      ? projectDetail?.request_company?.payment_terms
      : null
  )
  let getPaymentTermForGeneralAffairsFlag: any = null
  if (projectDetail?.general_affairs_flag && projectDetail?.request_company) {
    getPaymentTermForGeneralAffairsFlag = commonPaymentTerms?.find(
      (row: any) => row?.id === projectDetail.request_company.payment_terms
    )
  }
  const [futureDate, setFutureDate] = useState('')

  useEffect(() => {
    const calculatedFutureDate = calculateFutureDate(
      acceptanceDate,
      getPaymentTerm
    )
    if (calculatedFutureDate) {
      setFutureDate(calculatedFutureDate)
    }
  }, [getPaymentTerm?.cutoff_date])

  const initialState: initialProjectIssueInvoiceProps = {
    version: projectDetailInvoice?.version,
    invoice_no: projectDetailInvoice?.invoice_no
      ? projectDetailInvoice?.invoice_no
      : '',
    invoice_date: projectDetailInvoice
      ? projectDetailInvoice?.invoice_date
      : dateFormatter(new Date()),
    company: projectDetailInvoice
      ? projectDetailInvoice?.company
      : ProjectDeliveryNoteApprove?.company
      ? ProjectDeliveryNoteApprove?.company
      : projectDetail?.general_affairs_flag && projectDetail?.request_company
      ? projectDetail?.request_company
      : null,
    export_restriction: projectDetailInvoice
      ? projectDetailInvoice?.export_restriction
      : ProjectDeliveryNoteApprove?.export_restriction
      ? ProjectDeliveryNoteApprove?.export_restriction
      : projectDetail?.general_affairs_flag && projectDetail?.export_restriction
      ? projectDetail?.export_restriction
      : null,
    title: projectDetailInvoice
      ? projectDetailInvoice?.title
      : ProjectDeliveryNoteApprove?.title
      ? ProjectDeliveryNoteApprove?.title
      : projectDetail?.general_affairs_flag && projectDetail?.case_title
      ? projectDetail?.case_title
      : '',
    acceptance_date: projectDetailInvoice
      ? projectDetailInvoice?.acceptance_date
      : projectDetail?.general_affairs_flag && projectDetail?.delivery_date
      ? projectDetail?.delivery_date
      : certificate?.certificate_completion_date
      ? certificate?.certificate_completion_date
      : todayDate,
    payment_date: projectDetailInvoice
      ? projectDetailInvoice?.payment_date
      : futureDate,
    job_no: projectDetailInvoice
      ? projectDetailInvoice?.job_no
      : ProjectDeliveryNoteApprove?.job_no
      ? ProjectDeliveryNoteApprove?.job_no
      : '',
    case_no: projectDetail
      ? projectDetail?.case_no
      : ProjectDeliveryNoteApprove?.case_no
      ? ProjectDeliveryNoteApprove?.case_no
      : '',
    payment_terms: projectDetailInvoice
      ? projectDetailInvoice?.payment_terms
      : ProjectDeliveryNoteApprove?.payment_terms
      ? ProjectDeliveryNoteApprove?.payment_terms
      : getPaymentTermForGeneralAffairsFlag
      ? getPaymentTermForGeneralAffairsFlag.name
      : null,
    remarks: projectDetailInvoice
      ? projectDetailInvoice?.remarks
      : ProjectDeliveryNoteApprove?.remarks
      ? ProjectDeliveryNoteApprove?.remarks
      : '',
    reason: '',
    comment: '',
    total_amount: projectDetailInvoice
      ? projectDetailInvoice?.total_amount
      : ProjectDeliveryNoteApprove?.total_amount ?? 0,
    tax_subtotal: projectDetailInvoice?.tax_subtotal
      ? projectDetailInvoice?.tax_subtotal
      : updatedDeliveryNoteTaxSubTotalDetails ?? [
          {
            sales_tax: null,
            subtotal_amount: 0,
            amount: 0,
          },
        ],
    invoice_details: updatedInvoiceDetailsData
      ? updatedInvoiceDetailsData
      : updatedDeliveryNoteDetails ?? [
          {
            item_name:
              projectDetail?.general_affairs_flag &&
              projectDetail?.sales_type?.name
                ? projectDetail?.sales_type?.name
                : '',
            quantity: 0,
            unit_cost: 0,
            sales_tax: defaultSalesTax,
            amount: 0,
          },
        ],
  }

  const validationSchema = Yup.object().shape({
    invoice_no: Yup.string()
      .min(1, minCharValidation(1))
      .max(30, maxCharValidation(30)),
    title: Yup.string().max(100, maxCharValidation(100)),
    job_no: Yup.string().max(20, maxCharValidation(20)),
    payment_terms: Yup.mixed().required(t(messages.required)),
    remarks: Yup.string().max(100, maxCharValidation(100)),
    invoice_details: Yup.array().of(
      Yup.object().shape({
        item_name: Yup.string()
          .required(t(messages.required))
          .max(100, maxCharValidation(100)),
        unit_cost: Yup.number().required(messages.required),
        sales_tax: Yup.object().required(t(messages.required)),
      })
    ),
  })
  const nav = useNavigate()
  const onSubmit = async (values: initialProjectIssueInvoiceProps) => {
    const {
      company,
      export_restriction,
      invoice_details,
      tax_subtotal,
      acceptance_date,
      invoice_date,
    } = values
    const updatedTaxSubTotal = tax_subtotal?.map((row: any) => ({
      ...row,
      sales_tax: row?.sales_tax?.id,
    }))
    const updatedInvoiceDetails = invoice_details?.map((details: any) => {
      const { unit, ...rest } = details
      return {
        ...rest,
        sales_tax: details?.sales_tax?.id,
        amount: details?.amount,
        unit_cost: details?.unit_cost,
      }
    })
    if (edit) {
      const updatedValues = {
        ...values,
        export_restriction: export_restriction?.id ?? '',
        payment_terms_id: company?.payment_terms,
        invoice_details: updatedInvoiceDetails,
        case: projectDetail?.id,
        tax_subtotal: updatedTaxSubTotal,
        acceptance_date: acceptance_date ? acceptance_date : todayDate,
        invoice_date: invoice_date ? invoice_date : null,
        company: company ? company?.id : null,
      }
      dispatch(
        updateProjectIssueInvoiceRequest({
          id: projectDetailInvoice?.id,
          values: updatedValues,
          nav: nav,
          previousUrl,
        })
      )
    } else {
      const updatedValues = {
        ...values,
        payment_terms_id: company?.payment_terms,
        export_restriction: export_restriction?.id ?? '',
        invoice_details: updatedInvoiceDetails,
        case: projectDetail?.id,
        tax_subtotal: updatedTaxSubTotal,
        acceptance_date: acceptance_date ? acceptance_date : todayDate,
        invoice_date: invoice_date ? invoice_date : null,
        company: company ? company?.id : null,
      }
      dispatch(
        createProjectIssueInvoiceRequest({
          values: updatedValues,
          nav: nav,
          previousUrl,
        })
      )
    }
  }

  const [query, setQuery] = useState<query>({
    search: '',
    url: '',
  })
  useInputSearch(query)

  const handlePreview = () => {
    const url = 'invoice-report'
    const queryParams = `?id=${projectDetailInvoice?.id}&preview-report-url=${url}&file-name=${projectDetailInvoice?.invoice_no}&case-id=${id}`
    navigate(`/preview${queryParams}`)
  }
  const [readOnlyMode, setReadOnlyMode] = useState(false)
  const [userMadeInvoicePresent, setUserMadeInvoicePresent] = useState(false)
  const { is_staff } = useAppSelector(authSelector)
  const { workflow } = useAppSelector(workflowSelector)
  useEffect(() => {
    if (workflow) {
      if (workflow?.status !== 1) {
        setReadOnlyMode(true)
      } else {
        setReadOnlyMode(false)
      }
    } else {
      setReadOnlyMode(false)
    }
    if (projectDetailInvoice?.usermade_invoice) {
      setUserMadeInvoicePresent(true)
    }
    return () => {
      setReadOnlyMode(false)
    }
  }, [workflow, projectDetailInvoice])

  return (
    <div>
      <Formik
        enableReinitialize={true}
        initialValues={initialState}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          values,
          handleChange,
          setFieldValue,
          handleBlur,
          touched,
          errors,
          submitForm,
        }) => {
          const handleSave = () => {
            submitForm()
          }
          const handleBackToCase = () => {
            const queryParams = `?id=${id}&edit=${true}`
            navigate(`/create-agency-project${queryParams}`, {
              state: { path: location?.pathname + location?.search },
            })
          }
          const buttonConfigs = [
            ...(!readOnlyMode
              ? [
                  {
                    label: edit ? 'common.update' : 'common.save',
                    handler: handleSave,
                    isShowAlert: true,
                    loading: loadingCreateProjectIssueInvoice,
                    message: edit
                      ? t('confirmMessage.update')
                      : t('confirmMessage.save'),
                  },
                ]
              : []),
            ...(edit
              ? [
                  {
                    label: 'uploadRecept.usermadeInvoiceUpload.name',
                    handler: handleUsermadeInvoice,
                  },
                ]
              : []),
            ...(edit
              ? [
                  {
                    label: 'issueInvoice.preview',
                    handler: handlePreview,
                  },
                ]
              : []),

            {
              label: 'common.backToCase',
              handler: handleBackToCase,
            },
          ]
          const handleButtonAction = (action: string) => {
            const buttonConfig = buttonConfigs.find(
              (config) => config.label === action
            )
            if (buttonConfig) {
              const { handler } = buttonConfig
              handler()
            }
          }
          return (
            <>
              <Grid item sx={{ mb: 4 }}>
                <SaveFooterComponent
                  handleClick={handleButtonAction}
                  buttonConfigs={buttonConfigs}
                />
              </Grid>
              {edit && <WorkFlowPanel id={projectDetailInvoice?.workflow} />}
              {readOnlyMode ? (
                !userMadeInvoicePresent &&
                projectDetailInvoice?.id !== undefined &&
                projectDetailInvoice?.id !== null ? (
                  <PreviewIndex
                    hideButton={
                      projectDetailInvoice?.usermade_invoice ? false : true
                    }
                    directUrl="invoice-report"
                    directId={projectDetailInvoice?.id}
                  />
                ) : (
                  <UsermadeInvoiceUpload
                    showInReportScreen={projectDetailInvoice?.id}
                  />
                )
              ) : (
                <div className={readOnlyMode ? 'disabled-form' : ''}>
                  {edit && (
                    <ReasonForChange
                      setFieldValue={setFieldValue}
                      name="reason"
                      onChange={handleChange}
                    />
                  )}
                  <IssueBarComponent
                    isIssued={projectDetailInvoice?.issue_flag}
                  />

                  <Form autoComplete="off" noValidate>
                    <AppTitleCard title="issueInvoice.name">
                      <h1 style={{ textAlign: 'center' }}>
                        {t('issueInvoice.name')}
                      </h1>
                      <Grid
                        container
                        padding={2}
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Grid item xs={6}>
                          <Grid container alignItems="center">
                            <Grid item xs={3.6}>
                              {t('issueInvoice.invoiceNumber')}:
                            </Grid>
                            <AppTextField
                              xs={5}
                              disabled
                              name="invoice_no"
                              value={values?.invoice_no ?? ''}
                              label={t('issueInvoice.invoiceNumber')}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Grid>
                        </Grid>
                        <Grid item xs={6}></Grid>
                      </Grid>
                      <Grid
                        container
                        padding={2}
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Grid item xs={6}>
                          <Grid container alignItems="center">
                            <Grid item xs={3.6}>
                              {t('issueInvoice.invoiceDate')}:
                            </Grid>
                            <AppDatePicker
                              disabled={readOnlyMode}
                              xs={5}
                              value={values?.invoice_date}
                              name="invoice_date"
                              label="issueInvoice.invoiceDate"
                              onChange={(value: any) => {
                                if (value !== null) {
                                  setFieldValue(
                                    'invoice_date',
                                    dateFormatter(value)
                                  )
                                } else {
                                  setFieldValue('invoice_date', '')
                                }
                              }}
                              variant="outlined"
                              sx={{
                                width: '100%',
                                size: 'medium',
                                '& .MuiInputBase-root': {
                                  height: '39px',
                                  display: 'flex',
                                  alignItems: 'center',
                                },
                                '& .MuiInputLabel-root': {
                                  textAlign: 'center',
                                },
                              }}
                            />
                          </Grid>
                        </Grid>
                        <Grid item xs={6}>
                          <Grid container alignItems="center">
                            <Grid item xs={4}>
                              {t('issueInvoice.company')}:
                            </Grid>
                            <AppAutoComplete
                              disabled={readOnlyMode}
                              xs={5}
                              name="company"
                              value={values?.company ?? null}
                              options={commonCompanies}
                              getOptionLabel={(option: commonOptionType) =>
                                option.name
                              }
                              label={t('issueInvoice.company')}
                              onChange={(event: any, value: any) => {
                                if (value !== null) {
                                  setFieldValue('company', value)
                                  const getPaymentTermUpdate =
                                    commonPaymentTerms?.find(
                                      (row) => row?.id === value?.payment_terms
                                    )
                                  if (projectDetail?.general_affairs_flag) {
                                    setFieldValue(
                                      'payment_terms',
                                      getPaymentTermUpdate.name
                                    )
                                  } else {
                                    setFieldValue(
                                      'payment_terms',
                                      value?.shimadzu_report_flag
                                        ? selectTypePaymentTerms[0]?.name
                                        : selectTypePaymentTerms[1]?.name
                                    )
                                  }

                                  const calculatedFutureDate =
                                    calculateFutureDate(
                                      acceptanceDate,
                                      getPaymentTermUpdate
                                    )
                                  if (calculatedFutureDate) {
                                    setFieldValue(
                                      'payment_date',
                                      calculatedFutureDate
                                    )
                                  }
                                } else {
                                  setFieldValue('company', null)
                                }
                              }}
                              onFocus={() => handleFocusCompanyCommon(dispatch)}
                              onBlur={handleBlur}
                              inputOnchange={(e: any) =>
                                onChangeInputCompanyCommon(e.target, setQuery)
                              }
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        spacing={1}
                        padding={2}
                        alignItems="center"
                      >
                        <Grid container>
                          <Grid item xs={6} sx={{ pl: 1 }}>
                            <Grid container alignItems="center" mt={1}>
                              <Grid item xs={3.6}>
                                {t('common.title')}:
                              </Grid>
                              <AppTextField
                                disabled={readOnlyMode}
                                xs={5}
                                name="title"
                                value={values?.title ?? ''}
                                label={t('common.title')}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container alignItems="center" mt={1}>
                            <Grid item xs={1.8}>
                              {t('orderReceiptIssuance.certificateDate')}:
                            </Grid>
                            <AppDatePicker
                              disabled={readOnlyMode}
                              xs={2.5}
                              value={values?.acceptance_date ?? ''}
                              name="acceptance_date"
                              label="orderReceiptIssuance.certificateDate"
                              onChange={(value: any) => {
                                if (value !== null) {
                                  setFieldValue(
                                    'acceptance_date',
                                    dateFormatter(value)
                                  )
                                } else {
                                  setFieldValue('acceptance_date', '')
                                }
                              }}
                              variant="outlined"
                              sx={{
                                width: '100%',
                                size: 'medium',
                                '& .MuiInputBase-root': {
                                  height: '39px',
                                  display: 'flex',
                                  alignItems: 'center',
                                },
                                '& .MuiInputLabel-root': {
                                  textAlign: 'center',
                                },
                              }}
                            />
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container alignItems="center" mt={1}>
                            <Grid item xs={1.8}>
                              {t('orderReceiptIssuance.paymentTerms')}:
                            </Grid>
                            <AppTextField
                              id={t('orderReceiptIssuance.paymentTerms')}
                              xs={2.5}
                              name="payment_terms"
                              value={values?.payment_terms ?? ''}
                              label={t('orderReceiptIssuance.paymentTerms')}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container alignItems="center" mt={1}>
                            <Grid item xs={1.8}>
                              {t('quotation.exportRestriction')}:
                            </Grid>
                            <AppAutoComplete
                              disabled={readOnlyMode}
                              xs={2.5}
                              name="export_restriction"
                              value={values?.export_restriction ?? null}
                              options={commonExportRestrictions}
                              getOptionLabel={(option: commonOptionType) =>
                                option.name
                              }
                              label={t('quotation.exportRestriction')}
                              onChange={(
                                event: any,
                                value: InputChangeEvent
                              ) => {
                                if (value !== null) {
                                  setFieldValue('export_restriction', value)
                                } else {
                                  setFieldValue('export_restriction', null)
                                }
                              }}
                              onFocus={() =>
                                handleFocusExportRestrictionCommon(dispatch)
                              }
                              onBlur={handleBlur}
                              inputOnchange={(e: any) =>
                                onChangeInputExportRestrictionCommon(
                                  e.target,
                                  setQuery
                                )
                              }
                            />
                          </Grid>
                        </Grid>

                        <Grid item xs={12}>
                          <Grid container alignItems="center" mt={1}>
                            <Grid item xs={1.8}>
                              {t('orderReceiptIssuance.paymentDate')}:
                            </Grid>
                            <AppDatePicker
                              disabled={readOnlyMode}
                              xs={2.5}
                              value={values?.payment_date ?? ''}
                              name="payment_date"
                              label="orderReceiptIssuance.paymentDate"
                              onChange={(value: any) => {
                                if (value !== null) {
                                  setFieldValue(
                                    'payment_date',
                                    dateFormatter(value)
                                  )
                                } else {
                                  setFieldValue('payment_date', '')
                                }
                              }}
                              variant="outlined"
                              sx={{
                                width: '100%',
                                size: 'medium',
                                '& .MuiInputBase-root': {
                                  height: '39px',
                                  display: 'flex',
                                  alignItems: 'center',
                                },
                                '& .MuiInputLabel-root': {
                                  textAlign: 'center',
                                },
                              }}
                            />
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container alignItems="center" mt={2.5}>
                            <Grid item xs={1.8}>
                              {t('orderReceiptIssuance.jobNo')}:
                            </Grid>
                            <AppTextField
                              disabled={readOnlyMode}
                              xs={2.5}
                              name="job_no"
                              value={values?.job_no}
                              label={t('orderReceiptIssuance.jobNo')}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container alignItems="center" mt={2.5}>
                            <Grid item xs={1.8}>
                              {t('orderReceiptIssuance.caseNo')}:
                            </Grid>
                            <AppTextField
                              xs={2.5}
                              disabled
                              name="case_no"
                              value={values?.case_no ?? ''}
                              label={t('orderReceiptIssuance.caseNo')}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container alignItems="center" mt={2.5}>
                            <Grid item xs={1.8}>
                              {t('common.remarks')}:
                            </Grid>
                            <AppTextField
                              xs={8.8}
                              name="remarks"
                              value={values?.remarks ?? ''}
                              label={t('common.remarks')}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              multiline // Set the multiline prop to true
                              disabled={readOnlyMode}
                              rows={4}
                              InputProps={{
                                style: { padding: '10px' }, // Add padding to the input element
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Suspense fallback={<></>}>
                        <CommonReportTable
                          columns={columns}
                          taxType={`${t('commonFinanceTerm.excludingTax')})`}
                          keyName="invoice_details"
                          values={values?.invoice_details}
                          retrieve_tax_subtotal={values?.tax_subtotal}
                          handleChange={handleChange}
                          setFieldValue={setFieldValue}
                          handleBlur={handleBlur}
                          initialValues={initialState?.invoice_details}
                          setQuery={setQuery}
                          touched={touched}
                          disabled={readOnlyMode}
                          edit={edit}
                        />
                      </Suspense>
                    </AppTitleCard>
                  </Form>
                </div>
              )}
              <FocusElementOnInvalidValidation />
            </>
          )
        }}
      </Formik>
      {edit && (
        <>
          <WorkFlowHistoryTable />
          {is_staff && (
            <History
              history={
                projectDetailInvoice?.history?.length > 0
                  ? projectDetailInvoice?.history
                  : []
              }
            />
          )}
        </>
      )}
    </div>
  )
}

export default ProjectIssueInvoice
