import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit'

import {
  ResetPasswordPayload,
  LoginPayload,
  LoginResponse,
  RegisterPayload,
  RegisterResponse,
  ForgetPasswordPayload,
  UserResponse,
  ChangePasswordPayload,
} from './types'

export interface AuthState {
  loading: boolean
  token: string
  user: UserResponse | null
  is_staff: boolean
  isAuthenticated: boolean
  commonPreviousURLPath: any
}
const initialState: AuthState = {
  loading: false,
  token: localStorage.getItem('token') || '',
  user: null,
  isAuthenticated: false,
  is_staff: false,
  commonPreviousURLPath: [],
}

const Auth = createSlice({
  name: 'authSlice',
  initialState,
  reducers: {
    loginRequest: (state, action: PayloadAction<LoginPayload>) => {
      state.loading = true
    },
    loginSuccess: (state, action: PayloadAction<LoginResponse>) => {
      state.loading = false
      state.token = action.payload.token
      localStorage.setItem('token', action.payload.token)
      // MEMO: we should not get is_staff from login response
      // Instead, we should get it from getCurrentUser
      // Also, isAuthenticated should be set to true only after getCurrentUser
      state.isAuthenticated = false
    },
    logoutRequest: (state) => {
      state.loading = true
    },
    logout: (state) => {
      state.loading = false
      state.token = ''
      state.is_staff = false
      localStorage.removeItem('token')
      state.isAuthenticated = false
    },
    forgetPasswordRequest: (
      state,
      action: PayloadAction<ForgetPasswordPayload>
    ) => {
      state.loading = true
    },
    forgetPasswordSuccess: (state) => {
      state.loading = false
    },
    forgetPasswordFail: (state) => {
      state.loading = false
    },
    resetPasswordRequest: (
      state,
      action: PayloadAction<ResetPasswordPayload>
    ) => {
      state.loading = true
    },
    resetPasswordSuccess: (state) => {
      state.loading = false
    },
    resetPasswordFail: (state) => {
      state.loading = false
    },
    getCurrentUserRequest: (state) => {
      state.loading = true
    },
    getCurrentUserSuccess: (state, action: PayloadAction<UserResponse>) => {
      state.loading = false
      state.is_staff = action.payload.is_staff
      state.isAuthenticated = true
      state.user = action.payload
    },
    registerRequest: (state, action: PayloadAction<RegisterPayload>) => {
      state.loading = true
    },
    registerFail: (state) => {
      state.loading = false
    },
    changePasswordRequest: (
      state,
      action: PayloadAction<ChangePasswordPayload>
    ) => {
      state.loading = true
    },
    changePasswordSuccess: (state) => {
      state.loading = false
    },
    changePasswordFail: (state) => {
      state.loading = false
    },
    //
    // getCommonStorePreviousURLPath: (state, action: PayloadAction<any>) => {
    //   state.commonPreviousURLPath = action?.payload
    // },
    // clearCommonStorePreviousURLPath: (state) => {
    //   state.commonPreviousURLPath = []
    // },
  },
})
export const {
  loginRequest,
  loginSuccess,
  logoutRequest,
  logout,
  registerRequest,
  registerFail,
  forgetPasswordRequest,
  forgetPasswordSuccess,
  forgetPasswordFail,
  resetPasswordRequest,
  resetPasswordSuccess,
  resetPasswordFail,
  getCurrentUserRequest,
  getCurrentUserSuccess,
  changePasswordRequest,
  changePasswordSuccess,
  changePasswordFail,
  //
  // getCommonStorePreviousURLPath,
  // clearCommonStorePreviousURLPath,
} = Auth.actions
export default Auth.reducer
