import { Action } from '@reduxjs/toolkit'
import { t } from 'i18next'
import { combineEpics } from 'redux-observable'
import { Observable } from 'rxjs'
import { filter, map, switchMap } from 'rxjs/operators'

import { getNext, getPrevious } from '../../../../CommonAppRedux/api'
import {
  alertErrorAction,
  alertSuccessAction,
} from '../../../../CommonAppRedux/commonAppSlice'
import messages from '../../../../Utils/ValidationMessage'
import { defaultPage, defaultRowsPerPage } from '../../../../Utils/globalConstant'
import { dispatchAction, stateAction } from '../../../../Utils/globalTypes'
import {
  loadingInputTrackRecord,
  clearInputTrackRecord,
 createInputTrackRecordRequest,
 createInputTrackRecordSuccess,
 createInputTrackRecordFail,
 //
 updateInputTrackRecordRequest,
 updateInputTrackRecordSuccess,
 updateInputTrackRecordFail,
 //
 getInputTrackRecordRequest,
 getInputTrackRecordSuccess,
 getInputTrackRecordFail,
} from './inputTrackRecordSlice'
import {
  createInputTrackRecord,
 getInputTrackRecord,
 updateInputTrackRecord, // getInputTrackRecord,
} from './api'
import { extractErrorMessage } from '../../../../Utils/AppFunction'



const getInputTrackRecordEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(getInputTrackRecordRequest.match),
    switchMap(async (action) => {
      dispatch(loadingInputTrackRecord())
      try {
        const response = await getInputTrackRecord(action.payload)
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? getInputTrackRecordSuccess(action?.payload)
        : getInputTrackRecordFail()
    )
  )
}
const createInputTrackRecordEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(createInputTrackRecordRequest.match),
    switchMap(
      async ({
        payload: {
          id,
          values,
          nav,
          actions,
          jobDetailId,
          previousUrl,
          matchingTranslator,
        },
      }) => {
        try {
          const body = JSON.stringify(values)
          const response = await createInputTrackRecord(body)
          if (response) {
            if (actions) {
              actions.resetForm()
            }
            dispatch(alertSuccessAction(t(messages.createSuccess)))
            // nav(`${previousUrl}`)
            const queryParams = `?id=${response?.data?.id}&edit=${true}`
            if (nav) {
                const queryParams = `?case-id=${
                  values?.case
                }&edit=${true}`
                await nav(`/input-track-record${queryParams}`)
            }
          }
          return { payload: response.data }
        } catch (e) {
          dispatch(alertErrorAction(extractErrorMessage(e)))
          return { error: e }
        }
      }
    ),
    map((action) =>
      action?.payload
        ? createInputTrackRecordSuccess(action?.payload)
        : createInputTrackRecordFail()
    )
  )
}
const updateInputTrackRecordEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(updateInputTrackRecordRequest.match),
    switchMap(
      async ({
        payload: { id, values, nav, actions, previousUrl, matchingTranslator,taskStateList },
      }) => {
        try {
          const body = JSON.stringify(values)
          const response = await updateInputTrackRecord(id, body)
          if (response) {
            if (actions) {
              actions.resetForm()
            }
            dispatch(clearInputTrackRecord());
            dispatch(alertSuccessAction(t(messages.updateSuccess)))
              if (nav) {
                const queryParams = `?case-id=${values?.case}&edit=${true}`
                nav(`/input-track-record${queryParams}`)
              }
          }
          return { payload: response.data }
        } catch (e) {
          dispatch(alertErrorAction(extractErrorMessage(e)))
          return { error: e }
        }
      }
    ),
    map((action) =>
      action?.payload
        ? updateInputTrackRecordSuccess(action?.payload)
        : updateInputTrackRecordFail()
    )
  )
}

//

export const InputTrackRecordEpics = combineEpics(
  getInputTrackRecordEpic,
  createInputTrackRecordEpic,
  updateInputTrackRecordEpic

)
