import React, { Suspense, lazy, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { Grid, Typography } from '@mui/material'

// import * as XLSX from 'xlsx'
import {
  alertErrorAction,
  alertInfoAction,
  clearCommonData,
  commonUrlFilterData,
  initialRowsPerPageAndPages,
} from '../../../CommonAppRedux/commonAppSlice'
import { commonAppSelector } from '../../../CommonAppRedux/selector'
import { getCommonStorePreviousURLPath } from '../../../CommonLocalStorageRedux/localReduxSlice'
import {
  useAppDispatch,
  useAppSelector,
} from '../../../ReusableComponent/CustomHooks/appHooks'
import SaveFooterComponent from '../../../ReusableComponent/SaveFooterButton/SaveFooterButton'
import { defaultPage, defaultRowsPerPage } from '../../../Utils/globalConstant'
import {
  getBillableListCSVRequest,
  getBillableListRequest,
} from '../Redux/billableSlice'
import { billableListSelector } from '../Redux/selector'
import { billableSearchProps } from '../Redux/types'

const BillableListTable = lazy(() => import('./BillableListTable'))
const BillableListSearch = lazy(() => import('./BillableSearchList'))
const BillableList = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { rowsPerPage, page, tableCheckBoxData } =
    useAppSelector(commonAppSelector)
  const { loadingCSV } = useAppSelector(billableListSelector)

  const location = useLocation()
  useEffect(() => {
    dispatch(
      getCommonStorePreviousURLPath([
        { pathName: location?.pathname, search: location?.search },
      ])
    )
  }, [])

  const handleExportInvoiceData = () => {
    if (tableCheckBoxData?.length > 0) {
      handleDownloadCSV()
    } else {
      dispatch(alertInfoAction(t('customAlertMessage.selectCheckboxFirst')))
    }
  }

  const handleSalesExport = () => {
    alert('sales export')
  }
  const buttonConfigs = [
    // {
    //   label: 'common.invoice',
    //   handler: handleInvoice,
    // },
    {
      label: 'common.exportInvoiceData',
      handler: handleExportInvoiceData,
      disabled: loadingCSV,
    },
    // {
    //   label: 'common.salesExport',
    //   handler: handleSalesExport,
    // },
  ]
  const handleButtonAction = (action: string) => {
    const buttonConfig = buttonConfigs.find((config) => config.label === action)
    if (buttonConfig) {
      const { handler } = buttonConfig
      handler()
    }
  }

  const handleDownloadCSV = () => {
    dispatch(
      getBillableListCSVRequest(tableCheckBoxData.map((item: any) => item.id))
    )
  }
  const params = useParams<{ values?: string }>()
  const decodedValues = useMemo(() => {
    if (params.values) {
      return decodeURIComponent(params.values)
    }
    return ''
  }, [params.values])

  // Define the type for parsedValues
  let parsedValues: billableSearchProps | null = null

  try {
    if (decodedValues) {
      parsedValues = JSON.parse(decodedValues)
    }
  } catch (error) {
    console.error('Error parsing JSON:', error)
  }
  useEffect(() => {
    dispatch(commonUrlFilterData(parsedValues))
    dispatch(
      getBillableListRequest({
        page,
        rowsPerPage,
        filterData: parsedValues,
      })
    )
  }, [dispatch, page, rowsPerPage, parsedValues])
  return (
    <React.Fragment>
      <Grid item sx={{ mb: 4 }}>
        <SaveFooterComponent
          handleClick={handleButtonAction}
          buttonConfigs={buttonConfigs}
          hideBackBtn={true}
        />
      </Grid>
      <Typography variant="h6" fontWeight="bold" sx={{ padding: '16px' }}>
        {t('menuName.billableList')}
      </Typography>
      <Suspense fallback={<></>}>
        <BillableListSearch />
      </Suspense>
      <Suspense fallback={<></>}>
        <BillableListTable />
      </Suspense>
    </React.Fragment>
  )
}

export default BillableList
