import { Action } from '@reduxjs/toolkit'
import { t } from 'i18next'
import { combineEpics } from 'redux-observable'
import { Observable, identity } from 'rxjs'
import { filter, map, switchMap } from 'rxjs/operators'

import { getNext, getPrevious } from '../../../CommonAppRedux/api'
import {
  alertErrorAction,
  alertSuccessAction,
  clearCommonData,
} from '../../../CommonAppRedux/commonAppSlice'
import { extractErrorMessage } from '../../../Utils/AppFunction'
import messages from '../../../Utils/ValidationMessage'
import { defaultPage, defaultRowsPerPage } from '../../../Utils/globalConstant'
import { dispatchAction, stateAction } from '../../../Utils/globalTypes'
import { getTaskStateListRequest } from '../../TaskStateList/Redux/taskStateListSlice'
import {
  createIssueInvoice,
  createJobDetails,
  createJobTask,
  createPurchaseAmountChange,
  createPurchaseOrder,
  createTranslator,
  createTranslatorCertificate,
  createUserMadeInvoice,
  getCommonTranslatorTableList,
  getInvoiceById,
  getJobDetailById,
  getJobList,
  getJobListDetail,
  getJobListDetailById,
  getJobTaskById,
  getJobTaskStockingDateById,
  getPurchaseChangeAmountById,
  getPurchaseOrderById,
  getTranslatorCertificateById,
  getTranslatorFilter,
  getUserMadeInvoiceById,
  getTranslatorPurchaseOrderDetailsById,
  updateIssueInvoice,
  updateJobDetails,
  updateJobTask,
  updatePurchaseOrder,
  updateTranslatorCertificate,
  updateUserMadeInvoice,
  getTranslatorInvoiceUploadByCertificateId,
  createTranslatorInvoiceUpload,
  updateTranslatorInvoiceUpload,
  updateInspectionWithdrawal,
  updateJobDetailById,
  createJobDetailById,
  getJobListSalesExport,
  getMultipleUserMadeInvoiceById,
} from './api'
import {
  loadingJobList,
  getJobListSuccess,
  getJobListFail,
  getJobListRequest,
  createJobDetailsRequest,
  createJobDetailsSuccess,
  createJobDetailsFail,
  getJobListDetailSuccess,
  getJobListDetailFail,
  getJobListDetailRequest,
  getJobListDetailByIdRequest,
  getJobListDetailByIdSuccess,
  getJobListDetailByIdFail,
  createTranslatorRequest,
  createTranslatorSuccess,
  createTranslatorFail,
  getCommonTranslatorTableListRequest,
  getCommonTranslatorTableListSuccess,
  getCommonTranslatorTableListFail,
  updateJobDetailsSuccess,
  updateJobDetailsFail,
  updateJobDetailsRequest,
  getJobTaskByIdRequest,
  getJobTaskByIdSuccess,
  getJobTaskByIdFail,
  updateJobTaskRequest,
  updateJobTaskSuccess,
  updateJobTaskFail,
  getJobDetailListNextRequest,
  getJobDetailListPreviousRequest,
  createIssueInvoiceRequest,
  createIssueInvoiceSuccess,
  createIssueInvoiceFail,
  getTranslatorFilterRequest,
  getCommonTranslatorPreviousRequest,
  getCommonTranslatorNextRequest,
  getJobDetailRowByIdRequest,
  getJobDetailRowByIdSuccess,
  getJobDetailRowByIdFail,
  getJobDetailInvoiceByIdRequest,
  getJobDetailInvoiceByIdSuccess,
  getJobDetailInvoiceByIdFail,
  updateIssueInvoiceRequest,
  updateIssueInvoiceSuccess,
  updateIssueInvoiceFail,
  clearJobDetailInvoice, // getPurchaseOrderRequest,
  // getPurchaseOrderSuccess,
  // getPurchaseOrderFail,
  createPurchaseOrderRequest,
  createPurchaseOrderSuccess,
  createPurchaseOrderFail, // getTranslatorFilterOptionRequest,
  updatePurchaseOrderRequest,
  clearPurchaseOrder,
  updatePurchaseOrderSuccess,
  updatePurchaseOrderFail,
  getPurchaseOrderByIdRequest,
  getPurchaseOrderByIdFail,
  getPurchaseOrderByIdSuccess,
  getTranslatorCertificateByIdRequest,
  getTranslatorCertificateByIdSuccess,
  getTranslatorCertificateByIdFail,
  createTranslatorCertificateRequest,
  createTranslatorCertificateSuccess,
  createTranslatorCertificateFail,
  updateTranslatorCertificateRequest,
  clearTranslatorCertificate,
  updateTranslatorCertificateSuccess,
  updateTranslatorCertificateFail,
  createPurchaseAmountChangeFail,
  createPurchaseAmountChangeRequest,
  createPurchaseAmountChangeSuccess,
  getPurchaseChangeAmountByIdRequest,
  getPurchaseChangeAmountByIdSuccess,
  getPurchaseChangeAmountByIdFail,
  createJobTaskRequest,
  createJobTaskSuccess,
  createJobTaskFail,
  getJobTaskStockingDateByIdRequest,
  getJobTaskStockingDateByIdSuccess,
  getJobTaskStockingDateByIdFail,
  getUserMadeInvoiceByIdRequest,
  getUserMadeInvoiceByIdSuccess,
  getUserMadeInvoiceByIdFail,
  createUserMadeInvoiceRequest,
  createUserMadeInvoiceSuccess,
  createUserMadeInvoiceFail, // getTranslatorFilterOptionSuccess,
  updateUserMadeInvoiceRequest, // getTranslatorFilterOptionFail,
  clearUserMadeInvoice,
  updateUserMadeInvoiceSuccess,
  updateUserMadeInvoiceFail,
  getTranslatorPurchaseOrderDetailByIdRequest,
  getTranslatorPurchaseOrderDetailByIdSuccess,
  getTranslatorPurchaseOrderDetailByIdFail,
  getTranslatorInvoiceUploadByCertIdRequest,
  getTranslatorInvoiceUploadByIdSuccess,
  getTranslatorInvoiceUploadByIdFail,
  createTranslatorInvoiceUploadRequest,
  createTranslatorInvoiceUploadSuccess,
  createTranslatorInvoiceUploadFail,
  updateTranslatorInvoiceUploadRequest,
  clearTranslatorInvoiceUpload,
  updateTranslatorInvoiceUploadSuccess,
  updateTranslatorInvoiceUploadFail,
  updateInspectionWithdrawalRequest,
  updateInspectionWithdrawalSuccess, // getTranslatorFilterOptionSuccess,
  updateInspectionWithdrawalFail,
  updateJobDetailRowByIdRequest,
  updateJobDetailRowByIdSuccess,
  updateJobDetailRowByIdFail,
  createJobDetailRowByIdRequest,
  createJobDetailRowByIdSuccess,
  createJobDetailRowByIdFail,
  clearJobTask,
  clearDataJobListDetails,
  clearPurchaseChangeAmountById,
  downloadJobListSalesExportSuccess,
  downloadJobListSalesExportFail,
  downloadJobListSalesExportRequest,
  getMultipleUserMadeInvoiceByIdRequest, // getTranslatorFilterOptionFail,
} from './jobListSlice'

const getJobListEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(getJobListRequest.match),
    switchMap(async (action) => {
      dispatch(loadingJobList())
      try {
        const response = await getJobList(action.payload)
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload ? getJobListSuccess(action?.payload) : getJobListFail()
    )
  )
}

//get next
const getJobDetailListNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getJobDetailListNextRequest.match),
    switchMap(async (action) => {
      try {
        const response = await getNext(action.payload)
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? getJobListDetailSuccess(action?.payload)
        : getJobListDetailFail()
    )
  )
//get previous
const getJobDetailListPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getJobDetailListPreviousRequest.match),
    switchMap(async (action) => {
      try {
        const response = await getPrevious(action.payload)
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? getJobListDetailSuccess(action?.payload)
        : getJobListDetailFail()
    )
  )

const getJobListDetailEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(getJobListDetailRequest.match),
    switchMap(async (action) => {
      dispatch(loadingJobList())
      try {
        const response = await getJobListDetail(action.payload)
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? getJobListDetailSuccess(action?.payload)
        : getJobListDetailFail()
    )
  )
}

const getJobListDetailByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(getJobListDetailByIdRequest.match),
    switchMap(async (action) => {
      dispatch(loadingJobList())
      try {
        const response = await getJobListDetailById(action.payload)
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? getJobListDetailByIdSuccess(action?.payload)
        : getJobListDetailByIdFail()
    )
  )
}
const createJobDetailsEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(createJobDetailsRequest.match),
    switchMap(async ({ payload: { values, nav, actions, previousUrl } }) => {
      dispatch(loadingJobList())
      try {
        const body = JSON.stringify(values)
        const response = await createJobDetails(body)
        if (response) {
          dispatch(alertSuccessAction(t(messages.createSuccess)))
          const queryParams = `?id=${response?.data?.job}&edit=${true}`
          nav(`/job${queryParams}`)
        }
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? createJobDetailsSuccess(action?.payload)
        : createJobDetailsFail()
    )
  )
}
//update
const updateJobDetailsEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(updateJobDetailsRequest.match),
    switchMap(
      async ({ payload: { id, values, nav, actions, previousUrl } }) => {
        dispatch(loadingJobList())
        try {
          const body = JSON.stringify(values)
          const response = await updateJobDetails(id, body)
          if (response) {
            // actions.resetForm()
            dispatch(clearDataJobListDetails())
            dispatch(alertSuccessAction(t(messages.updateSuccess)))
            const queryParams = `?id=${id}&edit=${true}`
            nav(`/job${queryParams}`)
          }
          return { payload: response.data }
        } catch (e) {
          dispatch(alertErrorAction(extractErrorMessage(e)))
          return { error: e }
        }
      }
    ),
    map((action) =>
      action?.payload
        ? updateJobDetailsSuccess(action?.payload)
        : updateJobDetailsFail()
    )
  )
}
//get job detail row by id
const getJobDetailRowByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(getJobDetailRowByIdRequest.match),
    switchMap(async (action) => {
      dispatch(loadingJobList())
      try {
        const response = await getJobDetailById(action.payload)
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? getJobDetailRowByIdSuccess(action?.payload)
        : getJobDetailRowByIdFail()
    )
  )
}
const updateJobDetailRowByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(updateJobDetailRowByIdRequest.match),
    switchMap(
      async ({ payload: { id, values, nav, actions, previousUrl } }) => {
        dispatch(loadingJobList())
        try {
          const body = JSON.stringify(values)
          const response = await updateJobDetailById(id, body)
          dispatch(alertSuccessAction(t(messages.updateSuccess)))
          return { payload: response.data }
        } catch (e) {
          dispatch(alertErrorAction(extractErrorMessage(e)))
          return { error: e }
        }
      }
    ),
    map((action) =>
      action?.payload
        ? updateJobDetailRowByIdSuccess()
        : updateJobDetailRowByIdFail()
    )
  )
}

//
const createJobDetailRowByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(createJobDetailRowByIdRequest.match),
    switchMap(async ({ payload: { values, nav } }) => {
      dispatch(loadingJobList())
      try {
        const body = JSON.stringify(values)
        const response = await createJobDetailById(body)
        if (response) {
          const queryParams = `?job-detail=${response?.data?.id}&edit=${true}`
          nav(`/job${queryParams}`)
        }
        dispatch(alertSuccessAction(t(messages.createSuccess)))
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? createJobDetailRowByIdSuccess()
        : createJobDetailRowByIdFail()
    )
  )
}

//get job task by id epic
const getJobTaskByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(getJobTaskByIdRequest.match),
    switchMap(async (action) => {
      try {
        const response = await getJobTaskById(action.payload)
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? getJobTaskByIdSuccess(action?.payload)
        : getJobTaskByIdFail()
    )
  )
}

//create translator matching
const createTranslatorMatchingEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(createTranslatorRequest.match),
    switchMap(
      async ({ payload: { values, actions, previousUrl, navigate } }) => {
        try {
          const body = JSON.stringify(values)
          const response = await createTranslator(body)
          if (response) {
            // setSuccessResponse(true)
            //reset the form if response is success
            actions.resetForm()
            dispatch(alertSuccessAction(t(messages.createSuccess)))
            dispatch(
              getCommonTranslatorTableListRequest({
                page: defaultPage,
                rowsPerPage: defaultRowsPerPage,
              })
            )
            navigate(`${previousUrl}`)
          }
          return { payload: response.data }
        } catch (e) {
          dispatch(alertErrorAction(extractErrorMessage(e)))

          return { error: e }
        }
      }
    ),
    map((action) =>
      action?.payload
        ? createTranslatorSuccess(action?.payload)
        : createTranslatorFail()
    )
  )
}

// get common translator list for table
const getCommonTranslatorTableListEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(getCommonTranslatorTableListRequest.match),
    switchMap(async (action) => {
      try {
        const response = await getCommonTranslatorTableList(action.payload)
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? getCommonTranslatorTableListSuccess(action?.payload)
        : getCommonTranslatorTableListFail()
    )
  )
}

// get filter translator list for table
const getFilterTranslatorTableListEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(getTranslatorFilterRequest.match),
    switchMap(async (action) => {
      try {
        const response = await getTranslatorFilter(action?.payload)
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? getCommonTranslatorTableListSuccess(action?.payload)
        : getCommonTranslatorTableListFail()
    )
  )
}

//get next page of translator matching table
const getCommonTranslatorListNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getCommonTranslatorNextRequest.match),
    switchMap(async (action) => {
      try {
        const response = await getNext(action.payload)
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? getCommonTranslatorTableListSuccess(action?.payload)
        : getCommonTranslatorTableListFail()
    )
  )

//get previous page of translator matching table
const getCommonTranslatorListPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getCommonTranslatorPreviousRequest.match),
    switchMap(async (action) => {
      try {
        const response = await getPrevious(action.payload)
        return { payload: response.data }
      } catch (e) {
        dispatch(alertSuccessAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? getCommonTranslatorTableListSuccess(action?.payload)
        : getCommonTranslatorTableListFail()
    )
  )

//common translator filter option
// const getTranslatorFilterOptionRequestEpic = (
//   action$: Observable<Action>,
//   _: stateAction,
//   { dispatch }: dispatchAction
// ): Observable<Action> => {
//   return action$.pipe(
//     filter(getTranslatorFilterOptionRequest.match),
//     switchMap(async (action) => {
//       try {
//         const response = await getTranslatorFilter(action.payload)
//         return { payload: response.data }
//       } catch (e) {
//         return { error: e }
//       }
//     }),
//     map((action) =>
//       action?.payload
//         ? getTranslatorFilterOptionSuccess(action?.payload)
//         : getTranslatorFilterOptionFail()
//     )
//   )
// }

//update job task
const updateJobTaskEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(updateJobTaskRequest.match),
    switchMap(
      async ({
        payload: {
          id,
          values,
          nav,
          actions,
          previousUrl,
          matchingTranslator,
          taskStateList,
        },
      }) => {
        try {
          const body = JSON.stringify(values)

          const response = await updateJobTask(id, body)
          if (response) {
            if (actions) {
              actions.resetForm()
            }
            dispatch(clearJobTask())
            if (taskStateList) {
              dispatch(
                getTaskStateListRequest({
                  page: defaultPage,
                  rowsPerPage: defaultRowsPerPage,
                })
              )
            }

            dispatch(alertSuccessAction(t(messages.updateSuccess)))
            if (!matchingTranslator) {
              if (nav) {
                // nav(`${previousUrl}`)
                const queryParams = `?id=${id}&edit=${true}`
                nav(`/task${queryParams}`)
              }
            } else {
              dispatch(getJobTaskByIdRequest(id))
            }
          }
          return { payload: response.data }
        } catch (e) {
          dispatch(alertErrorAction(extractErrorMessage(e)))
          return { error: e }
        }
      }
    ),
    map((action) =>
      action?.payload
        ? updateJobTaskSuccess(action?.payload)
        : updateJobTaskFail()
    )
  )
}
//
const createJobTaskEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(createJobTaskRequest.match),
    switchMap(
      async ({
        payload: {
          id,
          values,
          nav,
          actions,
          jobDetailId,
          previousUrl,
          matchingTranslator,
        },
      }) => {
        try {
          const body = JSON.stringify(values)
          const response = await createJobTask(body)
          if (response) {
            if (actions) {
              actions.resetForm()
            }
            dispatch(alertSuccessAction(t(messages.createSuccess)))
            // nav(`${previousUrl}`)
            const queryParams = `?id=${response?.data?.id}&edit=${true}`
            if (nav) {
              if (matchingTranslator) {
                const queryParams = `?job-task-id=${
                  response?.data?.id
                }&edit=${true}`
                await nav(`/translator-matching${queryParams}`)
              } else {
                nav(`/task${queryParams}`)
              }
            }
          }
          return { payload: response.data }
        } catch (e) {
          dispatch(alertErrorAction(extractErrorMessage(e)))
          return { error: e }
        }
      }
    ),
    map((action) =>
      action?.payload
        ? createJobTaskSuccess(action?.payload)
        : createJobTaskFail()
    )
  )
}
//
const createPurchaseAmountChangeEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(createPurchaseAmountChangeRequest.match),
    switchMap(async ({ payload: { values, nav, previousUrl, jobTaskId } }) => {
      try {
        const { id } = values
        const body = JSON.stringify(values)
        const response = await createPurchaseAmountChange(body)
        if (response) {
          dispatch(clearPurchaseChangeAmountById())
          dispatch(alertSuccessAction(t(messages.createSuccess)))
          dispatch(getPurchaseChangeAmountByIdRequest(id))
          // nav(`${previousUrl}`)
          const queryParams = `?job-task-id=${jobTaskId}&translator-purchase-order=${id}&edit=true`
          nav(`/purchase-after-amount-change${queryParams}`)
        }
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? createPurchaseAmountChangeSuccess(action?.payload)
        : createPurchaseAmountChangeFail()
    )
  )
}
//
//
const createIssueInvoiceEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(createIssueInvoiceRequest.match),
    switchMap(async ({ payload: { values, nav, previousUrl } }) => {
      try {
        const body = JSON.stringify(values)
        const response = await createIssueInvoice(body)
        if (response) {
          dispatch(alertSuccessAction(t(messages.createSuccess)))
          // nav(`${previousUrl}`)
          const queryParams = `?id=${response?.data?.id}&edit=${true}`
          nav(`/issue-invoice${queryParams}`)
          dispatch(clearCommonData())
        }
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? createIssueInvoiceSuccess(action?.payload)
        : createIssueInvoiceFail()
    )
  )
}
//get invoice by job detail  id epic
const getInvoiceByJobDetailIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(getJobDetailInvoiceByIdRequest.match),
    switchMap(async (action) => {
      try {
        const response = await getInvoiceById(action.payload)
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? getJobDetailInvoiceByIdSuccess(action?.payload)
        : getJobDetailInvoiceByIdFail()
    )
  )
}
const updateIssueInvoiceEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(updateIssueInvoiceRequest.match),
    switchMap(async ({ payload: { id, values, nav, fromBilledList } }) => {
      try {
        const body = JSON.stringify(values)
        const response = await updateIssueInvoice(id, body)
        if (response) {
          dispatch(alertSuccessAction(t(messages.updateSuccess)))
          dispatch(getJobDetailInvoiceByIdRequest(id))
          dispatch(clearJobDetailInvoice())
          dispatch(clearCommonData())
          if (fromBilledList) {
            nav('/billed-list')
          } else {
            const queryParams = `?id=${id}&edit=${true}`
            nav(`/issue-invoice${queryParams}`)
            // nav('/job-list')
          }
        }
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? updateIssueInvoiceSuccess(action?.payload)
        : updateIssueInvoiceFail()
    )
  )
}

// get purchase order details
const getPurchaseOrderByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(getPurchaseOrderByIdRequest.match),
    switchMap(async (action) => {
      try {
        const response = await getPurchaseOrderById(action.payload)
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? getPurchaseOrderByIdSuccess(action?.payload)
        : getPurchaseOrderByIdFail()
    )
  )
}

// create job task purchase order
const createPurchaseOrderEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(createPurchaseOrderRequest.match),
    switchMap(
      async ({
        payload: {
          id,
          values,
          nav,
          actions,
          previousUrl,
          jobTaskId,
          jobTaskNo,
        },
      }) => {
        try {
          const body = JSON.stringify(values)
          const response = await createPurchaseOrder(body)
          if (response) {
            actions.resetForm()
            dispatch(alertSuccessAction(t(messages.createSuccess)))
            // nav(`${previousUrl}`)
            const purchaseOrderId = response.data?.id
            const queryParams = `?purchase-order-id=${purchaseOrderId}&job-task-id=${jobTaskId}&edit=true`
            nav(`/issue-purchase${queryParams}`)
          }
          return { payload: response.data }
        } catch (e) {
          dispatch(alertErrorAction(extractErrorMessage(e)))
          return { error: e }
        }
      }
    ),
    map((action) =>
      action?.payload
        ? createPurchaseOrderSuccess(action?.payload)
        : createPurchaseOrderFail()
    )
  )
}

//update job task purchase order
const updatePurchaseOrderEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(updatePurchaseOrderRequest.match),
    switchMap(
      async ({
        payload: {
          id,
          values,
          nav,
          actions,
          previousUrl,
          jobTaskId,
          jobTaskNo,
        },
      }) => {
        try {
          const body = JSON.stringify(values)
          const response = await updatePurchaseOrder(id, body)
          if (response) {
            if (actions) {
              actions.resetForm()
            }
            dispatch(clearPurchaseOrder())
            dispatch(alertSuccessAction(t(messages.updateSuccess)))
            // nav(`${previousUrl}`)
            // dispatch(getPurchaseOrderByIdRequest(id))
            const queryParams = `?purchase-order-id=${id}&job-task-id=${jobTaskId}&edit=true`
            if (nav) {
              nav(`/issue-purchase${queryParams}`)
            }
          }
          return { payload: response.data }
        } catch (e) {
          dispatch(alertErrorAction(extractErrorMessage(e)))
          return { error: e }
        }
      }
    ),
    map((action) =>
      action?.payload
        ? updatePurchaseOrderSuccess(action?.payload)
        : updatePurchaseOrderFail()
    )
  )
}

// get translator certifiacte details
const getTranslatorCertificateByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(getTranslatorCertificateByIdRequest.match),
    switchMap(async (action) => {
      try {
        const response = await getTranslatorCertificateById(action.payload)
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? getTranslatorCertificateByIdSuccess(action?.payload)
        : getTranslatorCertificateByIdFail()
    )
  )
}

const getPurchaseChangeAmountByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(getPurchaseChangeAmountByIdRequest.match),
    switchMap(async (action) => {
      try {
        const response = await getPurchaseChangeAmountById(action.payload)
        return { payload: response?.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? getPurchaseChangeAmountByIdSuccess(action?.payload)
        : getPurchaseChangeAmountByIdFail()
    )
  )
}

// create translator certificate
const createTranslatorCertificateEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(createTranslatorCertificateRequest.match),
    switchMap(async ({ payload: { values, nav, actions, previousUrl } }) => {
      try {
        const body = JSON.stringify(values)
        const response = await createTranslatorCertificate(body)
        if (response) {
          actions.resetForm()
          dispatch(alertSuccessAction(t(messages.createSuccess)))
          const certificateId = response.data?.id
          const queryParams = `?certificate-id=${certificateId}&job-task-id=${
            values?.job_task
          }&edit=${true}`
          if (nav) {
            nav(`/issue-certificate${queryParams}`, {
              state: { path: previousUrl },
            })
          }
        }
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? createTranslatorCertificateSuccess(action?.payload)
        : createTranslatorCertificateFail()
    )
  )
}

//update Translator Certificate
const updateTranslatorCertificateEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(updateTranslatorCertificateRequest.match),
    switchMap(
      async ({ payload: { id, values, nav, actions, previousUrl } }) => {
        try {
          const body = JSON.stringify(values)
          const response = await updateTranslatorCertificate(id, body)
          if (response) {
            actions.resetForm()
            dispatch(alertSuccessAction(t(messages.updateSuccess)))
            dispatch(getTranslatorCertificateByIdRequest(id))
            dispatch(clearTranslatorCertificate())
            const queryParams = `?certificate-id=${id}&job-task-id=${
              values?.job_task
            }&edit=${true}`
            nav(`/issue-certificate${queryParams}`, {
              state: { path: previousUrl },
            })
          }
          return { payload: response.data }
        } catch (e) {
          dispatch(alertErrorAction(extractErrorMessage(e)))
          return { error: e }
        }
      }
    ),
    map((action) =>
      action?.payload
        ? updateTranslatorCertificateSuccess(action?.payload)
        : updateTranslatorCertificateFail()
    )
  )
}
//
const getJobTaskStockingDateByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(getJobTaskStockingDateByIdRequest.match),
    switchMap(async (action) => {
      try {
        const response = await getJobTaskStockingDateById(action.payload)
        return { payload: response?.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? getJobTaskStockingDateByIdSuccess(action?.payload)
        : getJobTaskStockingDateByIdFail()
    )
  )
}
//
const getTranslatorPurchaseOrderDetailByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(getTranslatorPurchaseOrderDetailByIdRequest.match),
    switchMap(async (action) => {
      try {
        const response = await getTranslatorPurchaseOrderDetailsById(
          action.payload
        )
        return { payload: response?.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? getTranslatorPurchaseOrderDetailByIdSuccess(action?.payload)
        : getTranslatorPurchaseOrderDetailByIdFail()
    )
  )
}

// get UserMadeInvoice
const getUserMadeInvoiceByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(getUserMadeInvoiceByIdRequest.match),
    switchMap(async (action) => {
      try {
        const response = await getUserMadeInvoiceById(action.payload)
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? getUserMadeInvoiceByIdSuccess(action?.payload)
        : getUserMadeInvoiceByIdFail()
    )
  )
}
// get UserMadeInvoice
const getMultipleUserMadeInvoiceByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(getMultipleUserMadeInvoiceByIdRequest.match),
    switchMap(async (action) => {
      try {
        const response = await getMultipleUserMadeInvoiceById(action.payload)
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? getUserMadeInvoiceByIdSuccess(action?.payload)
        : getUserMadeInvoiceByIdFail()
    )
  )
}

// create UserMadeInvoice
const createUserMadeInvoiceEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(createUserMadeInvoiceRequest.match),
    switchMap(async ({ payload: { values, nav, actions, invoiceId } }) => {
      const { file, ...restValues } = values
      try {
        const body = new FormData()
        for (const [key, value] of Object.entries(restValues)) {
          //@ts-ignore
          body.append(key, value)
        }
        if (file) {
          body.append('file', file)
        } else {
          body.append('file', '')
        }
        const response = await createUserMadeInvoice(body)
        if (response) {
          actions.resetForm()
          dispatch(alertSuccessAction(t(messages.createSuccess)))
          dispatch(getUserMadeInvoiceByIdRequest(response?.data?.id))
          const queryParams = `?invoice-id=${invoiceId}&edit=${true}`
          nav(`/usermade-invoice-upload${queryParams}`)
        }
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? createUserMadeInvoiceSuccess(action?.payload)
        : createUserMadeInvoiceFail()
    )
  )
}

//update UserMadeInvoice
const updateUserMadeInvoiceEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(updateUserMadeInvoiceRequest.match),
    switchMap(async ({ payload: { id, values, nav, actions, invoiceId } }) => {
      const { file, ...restValues } = values
      try {
        const body = new FormData()
        for (const [key, value] of Object.entries(restValues)) {
          //@ts-ignore
          body.append(key, value)
        }
        if (file === null) {
          body.append('file', '')
        } else if (typeof file !== 'string') {
          body.append('file', file)
        }
        const response = await updateUserMadeInvoice(id, body)
        if (response) {
          dispatch(alertSuccessAction(t(messages.updateSuccess)))
          const queryParams = `?invoice-id=${invoiceId}&edit=${true}`
          nav(`/usermade-invoice-upload${queryParams}`)
          dispatch(getUserMadeInvoiceByIdRequest(id))
          if (actions) {
            actions.resetForm()
          }
          dispatch(clearUserMadeInvoice())
        }
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? updateUserMadeInvoiceSuccess(action?.payload)
        : updateUserMadeInvoiceFail()
    )
  )
}

// get TranslatorInvoiceUpload
const getTranslatorInvoiceUploadByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(getTranslatorInvoiceUploadByCertIdRequest.match),
    switchMap(async (action) => {
      try {
        const response = await getTranslatorInvoiceUploadByCertificateId(
          action.payload
        )
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? getTranslatorInvoiceUploadByIdSuccess(action?.payload)
        : getTranslatorInvoiceUploadByIdFail()
    )
  )
}

// create TranslatorInvoiceUpload
const createTranslatorInvoiceUploadEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(createTranslatorInvoiceUploadRequest.match),
    switchMap(async ({ payload: { values, nav, actions, invoiceId } }) => {
      const { file, ...restValues } = values
      try {
        const body = new FormData()
        for (const [key, value] of Object.entries(restValues)) {
          //@ts-ignore
          body.append(key, value)
        }
        if (file) {
          body.append('file', file)
        } else {
          body.append('file', '')
        }
        const response = await createTranslatorInvoiceUpload(body)
        if (response) {
          if (actions) {
            actions.resetForm()
          }
          dispatch(alertSuccessAction(t(messages.createSuccess)))
          if (response?.data?.id) {
            await dispatch(
              getTranslatorInvoiceUploadByCertIdRequest(response?.data?.id)
            )
          }
          let queryParams = `?job-task=${values?.job_task}&edit=${true}`
          if (nav) {
            nav(`/translator-invoice-upload${queryParams}`)
          }
        }
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? createTranslatorInvoiceUploadSuccess(action?.payload)
        : createTranslatorInvoiceUploadFail()
    )
  )
}

//update TranslatorInvoiceUpload
const updateTranslatorInvoiceUploadEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(updateTranslatorInvoiceUploadRequest.match),
    switchMap(async ({ payload: { id, values, nav, actions, invoiceId } }) => {
      const { file, ...restValues } = values
      try {
        const body = new FormData()
        for (const [key, value] of Object.entries(restValues)) {
          //@ts-ignore
          body.append(key, value)
        }
        if (file === null) {
          body.append('file', '')
        } else if (typeof file !== 'string') {
          body.append('file', file)
        }
        const response = await updateTranslatorInvoiceUpload(id, body)
        if (response) {
          if (actions) {
            actions.resetForm()
          }

          dispatch(alertSuccessAction(t(messages.updateSuccess)))
          dispatch(clearTranslatorInvoiceUpload())
          // const queryParams = `?`
          await dispatch(getTranslatorInvoiceUploadByCertIdRequest(id))
          let queryParams = `?job-task=${values?.job_task}&edit=${true}`

          if (nav) {
            nav(`/translator-invoice-upload${queryParams}`)
          }
        }
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? updateTranslatorInvoiceUploadSuccess(action?.payload)
        : updateTranslatorInvoiceUploadFail()
    )
  )
}

//update job task withdrawal flag
const updateInspectionWithdrawalEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(updateInspectionWithdrawalRequest.match),
    switchMap(
      async ({ payload: { id, version, nav, previousUrl, jobTaskId } }) => {
        try {
          const response = await updateInspectionWithdrawal(id, version)
          if (response) {
            dispatch(alertSuccessAction(t(messages.updateSuccess)))
            if (nav) {
              const queryParams = `?job-task-id=${jobTaskId}`
              await nav(`/issue-certificate${queryParams}`)
              // nav(`${previousUrl}`)
            }
          }
          return { payload: response.data }
        } catch (e) {
          dispatch(alertErrorAction(extractErrorMessage(e)))
          return { error: e }
        }
      }
    ),
    map((action) =>
      action?.payload
        ? updateInspectionWithdrawalSuccess()
        : updateInspectionWithdrawalFail()
    )
  )
}

const downloadJobListSalesExportEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(downloadJobListSalesExportRequest.match),
    switchMap(async (action) => {
      try {
        const response = await getJobListSalesExport(action.payload)
        dispatch(alertSuccessAction(t(messages.downloadSuccess)))
        return { payload: response }
      } catch (e) {
        dispatch(alertErrorAction(t(messages.failedToExport)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? downloadJobListSalesExportSuccess()
        : downloadJobListSalesExportFail()
    )
  )
}
export const jobListEpics = combineEpics(
  downloadJobListSalesExportEpic,
  getJobListEpic,
  createJobDetailsEpic,
  getJobListDetailEpic,
  getJobListDetailByIdEpic,
  //common translator
  createTranslatorMatchingEpic,
  getCommonTranslatorTableListEpic,
  getFilterTranslatorTableListEpic,
  getCommonTranslatorListPrevious,
  getCommonTranslatorListNext,
  //
  updateJobDetailsEpic,
  getJobTaskByIdEpic,
  updateJobTaskEpic,
  getJobDetailListNext,
  getJobDetailListPrevious,
  createIssueInvoiceEpic,
  updateIssueInvoiceEpic,
  getJobDetailRowByIdEpic,
  getInvoiceByJobDetailIdEpic,
  // getPurchaseOrderDetailEpic,
  createPurchaseOrderEpic,
  updatePurchaseOrderEpic,
  getPurchaseOrderByIdEpic,
  //translator certificate
  getTranslatorCertificateByIdEpic,
  createTranslatorCertificateEpic,
  updateTranslatorCertificateEpic,
  createPurchaseAmountChangeEpic,
  getPurchaseChangeAmountByIdEpic,
  createJobTaskEpic,
  getJobTaskStockingDateByIdEpic,
  //user made invoice
  getUserMadeInvoiceByIdEpic,
  getMultipleUserMadeInvoiceByIdEpic,
  createUserMadeInvoiceEpic,
  updateUserMadeInvoiceEpic,
  //translator invoice upload
  getTranslatorInvoiceUploadByIdEpic,
  createTranslatorInvoiceUploadEpic,
  updateTranslatorInvoiceUploadEpic,
  //
  getTranslatorPurchaseOrderDetailByIdEpic,
  //inspection withdrawal flag
  updateInspectionWithdrawalEpic,
  updateJobDetailRowByIdEpic,
  createJobDetailRowByIdEpic
)
