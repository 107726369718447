import { createTheme } from '@mui/material/styles'

import { LinkProps } from '@mui/material/Link'
import { red } from '@mui/material/colors'

import { bindCallback } from 'rxjs'

import LinkBehavior from './components/partials/LinkBehavior'

// A custom theme for this app (sample)
const CustomTheme = createTheme({
  components: {
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      } as LinkProps,
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: '#000000', // Change this to your desired color
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: 'red',
        },
      },
    },
  },
  palette: {
    text: {
      disabled: '#545454',
    },
    primary: {
      main: '#1976d2',
    },
    secondary: {
      main: '#9c27b0',
    },
    error: {
      main: red.A400,
    },
    grey: {
      '50': '#fafafa',
      '100': '#f5f5f5',
      '200': '#eeeeee',
      '300': '#e0e0e0',
      '400': '#bdbdbd',
      '500': '#9e9e9e', // Main shade of grey
      '600': '#757575',
      '700': '#616161',
      '800': '#424242',
      '900': '#212121', // Darkest shade of grey
    },
  },
})

export default CustomTheme
