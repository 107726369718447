import { Action } from '@reduxjs/toolkit'
import { t } from 'i18next'
import { combineEpics } from 'redux-observable'
import { Observable } from 'rxjs'
import { filter, map, switchMap } from 'rxjs/operators'

import { getNext, getPrevious } from '../../../CommonAppRedux/api'
import { alertErrorAction } from '../../../CommonAppRedux/commonAppSlice'
import messages from '../../../Utils/ValidationMessage'
import { dispatchAction, stateAction } from '../../../Utils/globalTypes'
import { getQuotationStateList } from './api'
import {
  loadingQuotationStateList,
  getQuotationStateListSuccess,
  getQuotationStateListFail,
  getQuotationStateListRequest,
  getQuotationStateListNextRequest,
  getQuotationStateListPreviousRequest,
} from './quotationStateSlice'
import { extractErrorMessage } from '../../../Utils/AppFunction'

const getQuotationStateListEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(getQuotationStateListRequest.match),
    switchMap(async (action) => {
      dispatch(loadingQuotationStateList())
      try {
        const response = await getQuotationStateList(action.payload)
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? getQuotationStateListSuccess(action?.payload)
        : getQuotationStateListFail()
    )
  )
}

//get next
const getQuotationStateListNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getQuotationStateListNextRequest.match),
    switchMap(async (action) => {
      try {
        const response = await getNext(action.payload)
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? getQuotationStateListSuccess(action?.payload)
        : getQuotationStateListFail()
    )
  )
//get previous
const getQuotationStateListPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getQuotationStateListPreviousRequest.match),
    switchMap(async (action) => {
      try {
        const response = await getPrevious(action.payload)
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? getQuotationStateListSuccess(action?.payload)
        : getQuotationStateListFail()
    )
  )

export const quotationStateListEpics = combineEpics(
  getQuotationStateListEpic,
  getQuotationStateListNext,
  getQuotationStateListPrevious
)
