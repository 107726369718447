import React, { Suspense, lazy, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { Button, Grid, Typography } from '@mui/material'

import { t } from 'i18next'
import Cookies from 'js-cookie'

import {
  alertErrorAction,
  alertInfoAction,
  commonUrlFilterData,
  initialRowsPerPageAndPages,
} from '../../../CommonAppRedux/commonAppSlice'
import { commonAppSelector } from '../../../CommonAppRedux/selector'
import { getCommonStorePreviousURLPath } from '../../../CommonLocalStorageRedux/localReduxSlice'
import ConfirmModal from '../../../ReusableComponent/ConfirmModal/ConfirmModal'
import {
  useAppDispatch,
  useAppSelector,
} from '../../../ReusableComponent/CustomHooks/appHooks'
import SaveFooterComponent from '../../../ReusableComponent/SaveFooterButton/SaveFooterButton'
import {
  getProjectListDetailRequest,
  getProjectListRequest,
  projectListSalesExportRequest,
} from '../Redux/projectListSlice'
import { projectListSelector } from '../Redux/selector'
import { searchProjectSubmitValuesProps } from '../Types'

// import {  getCommonStorePreviousURLPath } from '../../../Login/Redux/authSlice'

//below import with lazy loading, which is typically used when you want to load modules or components asynchronously, especially when they are not immediately needed.
const ProjectListTableList = lazy(() => import('./ProjectListTableList'))
const ProjectListSearch = lazy(() => import('./ProjectListSearch'))
const ProjectList = () => {
  const dispatch = useAppDispatch()
  const { rowsPerPage, page, tableCheckBoxData, collapseTableCheckBoxData } =
    useAppSelector(commonAppSelector)
  const { loadingProjectListSalesExport } = useAppSelector(projectListSelector)
  //filter for test purpose make component soon---
  const params = useParams<{ values?: string }>()
  const decodedValues = useMemo(() => params.values || '', [params.values])
  let parsedValues: searchProjectSubmitValuesProps | null = null

  try {
    if (decodedValues) {
      parsedValues = JSON.parse(decodedValues)
    }
  } catch (error) {
    console.error('Error parsing JSON:', error)
  }

  console.log(parsedValues, 'parsedValuesparsedValuesparsedValues')

  useEffect(() => {
    dispatch(commonUrlFilterData(parsedValues))
    if (parsedValues) {
      dispatch(
        getProjectListDetailRequest({
          page: page,
          rowsPerPage: rowsPerPage,
          filterData: parsedValues,
        })
      )
    } else {
      dispatch(
        getProjectListDetailRequest({ page: page, rowsPerPage: rowsPerPage })
      )
    }
    // dispatch())
  }, [rowsPerPage, page])
  const location = useLocation()
  useEffect(() => {
    dispatch(
      getCommonStorePreviousURLPath([
        { pathName: location?.pathname, search: location?.search },
      ])
    )
  }, [])

  const handleSalesExportClick = () => {
    // dispatch(commonUrlFilterData(parsedValues))
    dispatch(
      projectListSalesExportRequest({
        page: page,
        rowsPerPage: rowsPerPage,
        filterData: parsedValues,
      })
    )
  }

  const buttonConfigs = [
    {
      label: 'projectList.salesExport',
      handler: handleSalesExportClick,
      disabled: loadingProjectListSalesExport,
      isShowAlert: true,
      message: t('projectList.confirmSalesExportMsg'),
    },
  ]

  const handleButtonAction = (action: string) => {
    const buttonConfig = buttonConfigs.find((config) => config.label === action)
    if (buttonConfig) {
      const { handler } = buttonConfig
      handler()
    }
  }

  return (
    <React.Fragment>
      <Grid item sx={{ mb: 4 }}>
        <SaveFooterComponent
          handleClick={handleButtonAction}
          buttonConfigs={buttonConfigs}
          hideBackBtn={true}
        />
      </Grid>
      <Typography variant="h6" fontWeight="bold" sx={{ padding: '16px' }}>
        {t('menuName.projectList')}
      </Typography>
      <Suspense fallback={<></>}>
        <ProjectListSearch />
      </Suspense>
      <Suspense fallback={<></>}>
        <ProjectListTableList />
      </Suspense>
    </React.Fragment>
  )
}

export default ProjectList
