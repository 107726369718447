import { createSlice } from '@reduxjs/toolkit'

import { completeDeliveryProp } from './types'

const initialState: completeDeliveryProp = {
  completeDeliveries: [],
  edit: false,
  completeDelivery: null,
  loadingCompleteDelivery: false,
  loadingCreateCompleteDelivery: false,
  count: 0,
  next: '',
  previous: '',
}
export const CompleteDelivery = createSlice({
  name: 'completeDeliveryReducer',
  initialState,
  reducers: {
    loadingCompleteDelivery: (state) => {
      state.loadingCompleteDelivery = true
    },
    getCompleteDeliveryRequest: (state, action) => {
      state.loadingCompleteDelivery = true
    },
    getCompleteDeliverySuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingCompleteDelivery = false
      state.completeDeliveries = results
      state.count = count
      state.next = next
      state.previous = previous
    },
    getCompleteDeliveryFail: (state) => {
      state.loadingCompleteDelivery = false
    },
    getCompleteDeliveryNextRequest: (state, action) => {
      state.loadingCompleteDelivery = true
    },
    getCompleteDeliveryPreviousRequest: (state, action) => {
      state.loadingCompleteDelivery = true
    },
  },
})
export const {
  loadingCompleteDelivery,
  getCompleteDeliverySuccess,
  getCompleteDeliveryFail,
  getCompleteDeliveryRequest,
  getCompleteDeliveryPreviousRequest,
  getCompleteDeliveryNextRequest,
} = CompleteDelivery.actions
export default CompleteDelivery.reducer
