import React, { Suspense, useEffect } from 'react'

import { Dialog, DialogContent } from '@mui/material'

import ModalTitle from './ModalTitle'

const Modal = ({
  children,
  maxWidth,
  title,
  showModal,
  setShowModal,
  fullScreen,
  clearState,
}: any) => {
  const closeOtherModal = () => {
    setShowModal?.(false)
  }
  const handleClose = async () => {
    closeOtherModal()
    // clearState()
  }
  const modalStyle = {
    borderRadius: '25px',
  }
  return (
    <>
      <Dialog
        onClose={(event, reason) => {
          handleClose()
        }}
        aria-labelledby="customized-dialog-title"
        open={showModal}
        fullScreen={fullScreen}
        fullWidth
        maxWidth={maxWidth}
        style={modalStyle}
      >
        <ModalTitle id="customized-dialog-title" onClose={handleClose}>
          {title}
        </ModalTitle>
        <DialogContent dividers>{children}</DialogContent>
      </Dialog>
    </>
  )
}

export default React.memo(Modal)
