import React from 'react'

import { Box, Typography } from '@mui/material'
import { t } from 'i18next'

export interface IssueBarComponentProps {
  isIssued?: boolean
}
const IssueBarComponent = ({ isIssued }: IssueBarComponentProps) => {
  return (
    <Box sx={{ background: '#2e7d32', p: 1, mb: 2 }}>
      <Typography color="#FFF">{isIssued ? t('common.issued') : t("common.notIssued")}</Typography>
    </Box>
  )
}

export default IssueBarComponent
