import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { StateObservable } from 'redux-observable'

// Update the import to use CommonAppProps
import { defaultPage, defaultRowsPerPage } from '../Utils/globalConstant'
import { CommonAppProps } from './types'

const initialState: CommonAppProps = {
  open: false,
  error: false,
  info: false,
  success: false,
  message: '',
  rowsPerPage: defaultRowsPerPage,
  page: defaultPage,
  loadingCommonRequester: false,
  commonRequesters: [],
  loadingCommon: false,
  commonLayouts: [],
  loadingCommonLayouts: false,
  showAlert: false,
  showInfoAlert: false,
  urlFilterData: null,
  tableCheckBoxData: [],
  collapseTableCheckBoxData: [],
  loadingCommonTranslatorLanguage: false,
  commonTranslatorLanguages: [],
  loadingCommonCompany: false,
  commonCompanies: [],
  loadingCommonPaymentTerms: false,
  commonPaymentTerms: [],
  loadingCommonDeliveryStyle: false,
  commonDeliveryStyles: [],
  loadingCommonTranslator: false,
  commonTranslator: [],
  commonFilteredTranslator: [],
  loadingCommonFilteredTranslator: false,
  loadingCommonTask: false,
  commonTasks: [],
  loadingCommonTrados: false,
  commonTrados: [],
  commonSalesTax: [],
  defaultSalesTax: null,
  loadingCommonSaleTax: false,
  footerButtonActive: false,
  loadingCommonTranslationSubject: false,
  loadingCommonDocument: false,
  commonTranslationSubjects: [],
  commonDocuments: [],
  loadingCommonExpenseType: false,
  loadingCommonIndustryType: false,
  commonIndustryTypes: [],
  commonExpenseTypes: [],
  loadingCommonExportRestriction: false,
  commonExportRestrictions: [],
  activeHandler: null,
  activeMessage: '',
  currentInnerWidth: false,
  loadingCommonJob: false,
  commonJobs: [],
  loadingCommonUser: false,
  commonUsers: [],
  loadingCommonCostSharingDepartmentCode: false,
  commonCostSharingDepartmentCodes: [],
  activeOnlyMessage: false,
  showChangePassword: false,
  loadingCommonSalesDepartment: false,
  commonSalesDepartments: [],
  loadingCommonSalesType: false,
  commonSalesTypes: [],
  activeButtonIndex: 0,
  activeButtonLoading: null,
  loadingCommonDepartment: false,
  commonDepartments: [],
  loadingCommonAffiliatedDepartment: false,
  commonAffiliatedDepartments: [],
  loadingCommonTaskType: false,
  commonTaskTypes: [],
  loadingCommonStatus: false,
  commonStatuses: [],
  // commonPreviousURLPath:[],
}

const CommonAppSlice = createSlice({
  name: 'commonAppReducer',
  initialState,
  reducers: {
    alertSuccessAction: (state, action: PayloadAction<string>) => {
      state.open = true
      state.success = true
      state.error = false
      state.message = action.payload
    },
    alertErrorAction: (state, action: PayloadAction<string>) => {
      state.open = true
      state.error = true
      state.success = false
      state.message = action.payload
    },
    alertInfoAction: (state, action: PayloadAction<string>) => {
      state.open = true
      state.info = true
      state.message = action.payload
    },
    closeAlertAction: (state) => {
      state.open = false
      state.info = false
      state.success = false
      state.error = false
      state.message = ''
    },
    getCommonRequesterRequest: (state, action: PayloadAction<number>) => {
      state.loadingCommonRequester = true
    },
    getCommonRequesterSuccess: (
      state,
      action: PayloadAction<{ results: any[] }>
    ) => {
      state.loadingCommonRequester = false
      state.commonRequesters = action.payload.results
    },
    getCommonRequesterFail: (state) => {
      state.loadingCommonRequester = false
      state.commonRequesters = []
    },
    loadingCommon: (state) => {
      state.loadingCommon = true
    },
    getCommonLayoutRequest: (state, action: PayloadAction<number>) => {
      state.loadingCommonLayouts = true
      // Access the payload data and do something with it if needed
    },
    loadingCommonLayoutRequest: (state, action: PayloadAction<number>) => {
      state.loadingCommonLayouts = true
    },
    getCommonLayoutSuccess: (
      state,
      action: PayloadAction<{ results: any[] }>
    ) => {
      state.loadingCommonLayouts = false
      state.commonLayouts = action.payload.results
    },
    getCommonLayoutFail: (state) => {
      state.loadingCommonLayouts = false
      state.commonLayouts = []
    },
    showAlertDialog: (state) => {
      state.showAlert = true
    },
    showInfoAlertDialog: (state) => {
      state.showInfoAlert = true
    },
    clickFooterButtonActive: (state, { payload }) => {
      state.footerButtonActive = true
      state.activeHandler = payload?.activeHandler
      state.activeMessage = payload?.activeMessage
      state.activeOnlyMessage = payload?.activeOnlyMessage
      state.activeButtonIndex = payload?.activeButtonIndex
      state.activeButtonLoading = payload?.activeButtonLoading
    },
    disabledClickFooterButtonActive: (state) => {
      state.footerButtonActive = false
    },
    closeAlertDialog: (state) => {
      state.showAlert = false
      state.footerButtonActive = false
    },
    closeInfoAlertDialog: (state) => {
      state.showInfoAlert = false
      state.footerButtonActive = false
    },
    commonAppRowsPerPage: (state, { payload }) => {
      state.rowsPerPage = payload
    },
    commonAppPage: (state, { payload }) => {
      state.page = payload
    },
    commonUrlFilterData: (state, { payload }) => {
      state.urlFilterData = payload
    },
    clearCommonUrlFilterData: (state) => {
      state.urlFilterData = null
    },

    initialRowsPerPageAndPages: (state) => {
      state.rowsPerPage = defaultRowsPerPage
      state.page = defaultPage
    },
    setTableCheckBoxData: (state, { payload }) => {
      // state.tableCheckBoxData = payload?.map((n: any) => {
      //   return { id: n?.id,invoice:n?.erts }
      // })
      state.tableCheckBoxData = payload

      state.collapseTableCheckBoxData = []
    },
    setCollapseTableCheckBoxData: (state, { payload }) => {
      state.collapseTableCheckBoxData = payload?.map((n: any) => {
        return { id: n?.id }
      })
      state.tableCheckBoxData = []
    },
    clearCommonData: (state) => {
      state.tableCheckBoxData = []
      state.collapseTableCheckBoxData = []
    },
    //
    getCommonTranslatorLanguageRequest: (
      state,
      action: PayloadAction<number>
    ) => {
      state.loadingCommonTranslatorLanguage = true
    },
    getCommonTranslatorLanguageSuccess: (
      state,
      action: PayloadAction<{ results: any[] }>
    ) => {
      state.loadingCommonTranslatorLanguage = false
      state.commonTranslatorLanguages = action.payload.results
    },
    getCommonTranslatorLanguageFail: (state) => {
      state.loadingCommonTranslatorLanguage = false
      state.commonTranslatorLanguages = []
    },
    loadingCommonTranslatorLanguage: (state) => {
      state.loadingCommonTranslatorLanguage = true
    },
    //
    getCommonCompanyRequest: (state, action: PayloadAction<number>) => {
      state.loadingCommonCompany = true
    },
    getCommonCompanySuccess: (
      state,
      action: PayloadAction<{ results: any[] }>
    ) => {
      state.loadingCommonCompany = false
      state.commonCompanies = action.payload.results
    },
    getCommonCompanyFail: (state) => {
      state.loadingCommonCompany = false
      state.commonCompanies = []
    },
    loadingCommonCompany: (state) => {
      state.loadingCommonCompany = true
    },
    //
    getCommonPaymentTermsRequest: (state, action: PayloadAction<number>) => {
      state.loadingCommonPaymentTerms = true
    },
    getCommonPaymentTermsSuccess: (
      state,
      action: PayloadAction<{ results: any[] }>
    ) => {
      state.loadingCommonPaymentTerms = false
      state.commonPaymentTerms = action.payload.results
    },
    getCommonPaymentTermsFail: (state) => {
      state.loadingCommonPaymentTerms = false
      state.commonPaymentTerms = []
    },
    loadingCommonPaymentTerms: (state) => {
      state.loadingCommonPaymentTerms = true
    },
    //
    getCommonDeliveryStyleRequest: (state, action: PayloadAction<number>) => {
      state.loadingCommonDeliveryStyle = true
    },
    getCommonDeliveryStyleSuccess: (
      state,
      action: PayloadAction<{ results: any[] }>
    ) => {
      state.loadingCommonDeliveryStyle = false
      state.commonDeliveryStyles = action.payload.results
    },
    getCommonDeliveryStyleFail: (state) => {
      state.loadingCommonDeliveryStyle = false
      state.commonDeliveryStyles = []
    },
    loadingCommonDeliveryStyle: (state) => {
      state.loadingCommonDeliveryStyle = true
    },
    //get common translator list
    loadingCommonTranslator: (state) => {
      state.loadingCommonTranslator = true
    },
    getCommonTranslatorRequest: (state, payload) => {
      state.loadingCommonTranslator = true
    },
    getCommonTranslatorSuccess: (state, { payload: { results } }) => {
      state.commonTranslator = results
      state.loadingCommonTranslator = false
    },
    getCommonTranslatorFail: (state) => {
      state.loadingCommonTranslator = false
    },
    //store the filtred value of translator
    loadingCommonFilteredTranslatorOption: (state) => {
      state.loadingCommonFilteredTranslator = true
    },
    getCommonFilteredTranslatorOptionRequest: (state, payload) => {
      state.loadingCommonFilteredTranslator = true
    },
    getCommonFilteredTranslatorOptionSuccess: (
      state,
      { payload: { results } }
    ) => {
      console.log(results, 'resultsresultsresults')
      state.commonFilteredTranslator = results
      state.loadingCommonFilteredTranslator = false
    },
    getCommonTranslatorFilterOptionFail: (state) => {
      state.loadingCommonFilteredTranslator = false
    },
    //get common trados list
    loadingCommonTrados: (state) => {
      state.loadingCommonTrados = true
    },
    getCommonTradosRequest: (state, payload) => {
      state.loadingCommonTrados = true
    },
    getCommonTradosSuccess: (state, { payload: { results } }) => {
      state.commonTrados = results
      state.loadingCommonTrados = false
    },
    getCommonTradosFail: (state) => {
      state.loadingCommonTrados = false
    },
    //get common task
    loadingCommonTask: (state) => {
      state.loadingCommonTask = true
    },
    getCommonTaskRequest: (state, payload) => {
      state.loadingCommonTask = true
    },
    getCommonTaskSuccess: (state, { payload: { results } }) => {
      state.commonTasks = results
      state.loadingCommonTask = false
    },
    getCommonTaskFail: (state) => {
      state.loadingCommonTask = false
    },
    // common sales tax
    getCommonSaleTaxRequest: (state, action: PayloadAction<number>) => {
      state.loadingCommonSaleTax = true
    },
    getCommonSaleTaxSuccess: (
      state,
      action: PayloadAction<{ results: any[] }>
    ) => {
      state.loadingCommonSaleTax = false
      state.commonSalesTax = action.payload.results
      state.defaultSalesTax = action?.payload?.results?.find((tax: any) => {
        return tax?.is_default === true
      })
    },
    getCommonSaleTaxFail: (state) => {
      state.loadingCommonSaleTax = false
      state.commonSalesTax = []
      state.defaultSalesTax = null
    },
    loadingCommonSaleTax: (state) => {
      state.loadingCommonSaleTax = true
    },
    //
    getCommonDocumentRequest: (state, action: PayloadAction<number>) => {
      state.loadingCommonDocument = true
    },
    getCommonDocumentSuccess: (
      state,
      action: PayloadAction<{ results: any[] }>
    ) => {
      state.loadingCommonDocument = false
      state.commonDocuments = action.payload.results
    },
    getCommonDocumentFail: (state) => {
      state.loadingCommonDocument = false
      state.commonDocuments = []
    },
    loadingCommonDocument: (state) => {
      state.loadingCommonDocument = true
    },
    //
    getCommonSalesDepartmentRequest: (state, action: PayloadAction<number>) => {
      state.loadingCommonSalesDepartment = true
    },
    getCommonSalesDepartmentSuccess: (
      state,
      action: PayloadAction<{ results: any[] }>
    ) => {
      state.loadingCommonSalesDepartment = false
      state.commonSalesDepartments = action.payload.results
    },
    getCommonSalesDepartmentFail: (state) => {
      state.loadingCommonSalesDepartment = false
      state.commonSalesDepartments = []
    },
    loadingCommonSalesDepartment: (state) => {
      state.loadingCommonSalesDepartment = true
    },

    //
    getCommonSalesTypeRequest: (state, action: PayloadAction<number>) => {
      state.loadingCommonSalesType = true
    },
    getCommonSalesTypeSuccess: (
      state,
      action: PayloadAction<{ results: any[] }>
    ) => {
      state.loadingCommonSalesType = false
      state.commonSalesTypes = action.payload.results
    },
    getCommonSalesTypeFail: (state) => {
      state.loadingCommonSalesType = false
      state.commonSalesTypes = []
    },
    loadingCommonSalesType: (state) => {
      state.loadingCommonSalesType = true
    },

    //
    getCommonTranslationSubjectRequest: (
      state,
      action: PayloadAction<number>
    ) => {
      state.loadingCommonTranslationSubject = true
    },
    getCommonTranslationSubjectSuccess: (
      state,
      action: PayloadAction<{ results: any[] }>
    ) => {
      state.loadingCommonTranslationSubject = false
      state.commonTranslationSubjects = action.payload.results
    },
    getCommonTranslationSubjectFail: (state) => {
      state.loadingCommonTranslationSubject = false
      state.commonTranslationSubjects = []
    },
    loadingCommonTranslationSubject: (state) => {
      state.loadingCommonTranslationSubject = true
    },
    //
    getCommonIndustryTypeRequest: (state, action: PayloadAction<number>) => {
      state.loadingCommonIndustryType = true
    },
    getCommonIndustryTypeSuccess: (
      state,
      action: PayloadAction<{ results: any[] }>
    ) => {
      state.loadingCommonIndustryType = false
      state.commonIndustryTypes = action.payload.results
    },
    getCommonIndustryTypeFail: (state) => {
      state.loadingCommonIndustryType = false
      state.commonIndustryTypes = []
    },
    loadingCommonIndustryType: (state) => {
      state.loadingCommonIndustryType = true
    },
    //
    getCommonExpenseTypeRequest: (state, action: PayloadAction<number>) => {
      state.loadingCommonExpenseType = true
    },
    getCommonExpenseTypeSuccess: (
      state,
      action: PayloadAction<{ results: any[] }>
    ) => {
      state.loadingCommonExpenseType = false
      state.commonExpenseTypes = action.payload.results
    },
    getCommonExpenseTypeFail: (state) => {
      state.loadingCommonExpenseType = false
      state.commonExpenseTypes = []
    },
    loadingCommonExpenseType: (state) => {
      state.loadingCommonExpenseType = true
    },
    //
    getCommonExportRestrictionRequest: (
      state,
      action: PayloadAction<number>
    ) => {
      state.loadingCommonExportRestriction = true
    },
    getCommonExportRestrictionSuccess: (
      state,
      action: PayloadAction<{ results: any[] }>
    ) => {
      state.loadingCommonExportRestriction = false
      state.commonExportRestrictions = action.payload.results
    },
    getCommonExportRestrictionFail: (state) => {
      state.loadingCommonExportRestriction = false
      state.commonExportRestrictions = []
    },
    loadingCommonExportRestriction: (state) => {
      state.loadingCommonExpenseType = true
    },

    getCurrentInnerWidth: (state, { payload }) => {
      state.currentInnerWidth = payload
    },
    //
    getCommonJobRequest: (state, action: PayloadAction<number>) => {
      state.loadingCommonJob = true
    },
    getCommonJobSuccess: (state, action: PayloadAction<{ results: any[] }>) => {
      state.loadingCommonJob = false
      state.commonJobs = action.payload.results
    },
    getCommonJobFail: (state) => {
      state.loadingCommonJob = false
      state.commonJobs = []
    },
    loadingCommonJob: (state) => {
      state.loadingCommonJob = true
    },
    //
    getCommonCostSharingDepartmentCodeRequest: (
      state,
      action: PayloadAction<number>
    ) => {
      state.loadingCommonCostSharingDepartmentCode = true
    },
    getCommonCostSharingDepartmentCodeSuccess: (
      state,
      action: PayloadAction<{ results: any[] }>
    ) => {
      state.loadingCommonCostSharingDepartmentCode = false
      state.commonCostSharingDepartmentCodes = action.payload.results
    },
    getCommonCostSharingDepartmentCodeFail: (state) => {
      state.loadingCommonCostSharingDepartmentCode = false
      state.commonCostSharingDepartmentCodes = []
    },
    loadingCommonCostSharingDepartmentCode: (state) => {
      state.loadingCommonCostSharingDepartmentCode = true
    },
    //
    //
    getCommonUserRequest: (state, action: PayloadAction<number>) => {
      state.loadingCommonUser = true
    },
    getCommonUserSuccess: (
      state,
      action: PayloadAction<{ results: any[] }>
    ) => {
      state.loadingCommonUser = false
      state.commonUsers = action.payload.results
    },
    getCommonUserFail: (state) => {
      state.loadingCommonUser = false
      state.commonUsers = []
    },
    loadingCommonUser: (state) => {
      state.loadingCommonJob = true
    },
    openChangePassword: (state) => {
      state.showChangePassword = true
    },
    closeChangePassword: (state) => {
      state.showChangePassword = false
    },
    // common department
    getCommonDepartmentRequest: (state, action: PayloadAction<number>) => {
      state.loadingCommonDepartment = true
    },
    getCommonDepartmentSuccess: (
      state,
      action: PayloadAction<{ results: any[] }>
    ) => {
      state.loadingCommonDepartment = false
      state.commonDepartments = action.payload.results
    },
    getCommonDepartmentFail: (state) => {
      state.loadingCommonDepartment = false
      state.commonDepartments = []
    },
    loadingCommonDepartment: (state) => {
      state.loadingCommonDepartment = true
    },
    // common affiliated department
    getCommonAffiliatedDepartmentRequest: (
      state,
      action: PayloadAction<number>
    ) => {
      state.loadingCommonAffiliatedDepartment = true
    },
    getCommonAffiliatedDepartmentSuccess: (
      state,
      action: PayloadAction<{ results: any[] }>
    ) => {
      state.loadingCommonAffiliatedDepartment = false
      state.commonAffiliatedDepartments = action.payload.results
    },
    getCommonAffiliatedDepartmentFail: (state) => {
      state.loadingCommonAffiliatedDepartment = false
      state.commonAffiliatedDepartments = []
    },
    loadingCommonAffiliatedDepartment: (state) => {
      state.loadingCommonAffiliatedDepartment = true
    },
    // common task type
    getCommonTaskTypeRequest: (state, action: PayloadAction<number>) => {
      state.loadingCommonTaskType = true
    },
    getCommonTaskTypeSuccess: (
      state,
      action: PayloadAction<{ results: any[] }>
    ) => {
      state.loadingCommonTaskType = false
      state.commonTaskTypes = action.payload.results
    },
    getCommonTaskTypeFail: (state) => {
      state.loadingCommonTaskType = false
      state.commonTaskTypes = []
    },
    loadingCommonTaskType: (state) => {
      state.loadingCommonTaskType = true
    },
    // common statuses
    getCommonStatusRequest: (state, action: PayloadAction<number>) => {
      state.loadingCommonStatus = true
    },
    getCommonStatusSuccess: (
      state,
      action: PayloadAction<{ results: any[] }>
    ) => {
      state.loadingCommonStatus = false
      state.commonStatuses = action.payload.results
    },
    getCommonStatusFail: (state) => {
      state.loadingCommonStatus = false
      state.commonStatuses = []
    },
    loadingCommonStatus: (state) => {
      state.loadingCommonStatus = true
    },

    //
    // getCommonStorePreviousURLPath: (state,action:PayloadAction<any>) => {
    //   state.commonPreviousURLPath = [...state?.commonPreviousURLPath,action?.payload]
    // },
    // clearCommonStorePreviousURLPath: (state) => {
    //   state.commonPreviousURLPath = []
    // },
  },
})

export const {
  alertSuccessAction,
  alertErrorAction,
  alertInfoAction,
  closeAlertAction,
  getCommonRequesterRequest,
  getCommonRequesterSuccess,
  getCommonRequesterFail,
  loadingCommon,
  loadingCommonLayoutRequest,
  getCommonLayoutRequest,
  getCommonLayoutSuccess,
  getCommonLayoutFail,
  //
  showAlertDialog,
  showInfoAlertDialog,
  closeAlertDialog,
  closeInfoAlertDialog,
  commonAppRowsPerPage,
  commonAppPage,
  commonUrlFilterData,
  initialRowsPerPageAndPages,
  setCollapseTableCheckBoxData,
  setTableCheckBoxData,
  clearCommonData,
  //
  getCommonTranslatorLanguageRequest,
  getCommonTranslatorLanguageSuccess,
  getCommonTranslatorLanguageFail,
  loadingCommonTranslatorLanguage,
  //
  getCommonCompanyRequest,
  getCommonCompanySuccess,
  getCommonCompanyFail,
  loadingCommonCompany,
  //
  getCommonPaymentTermsRequest,
  getCommonPaymentTermsSuccess,
  getCommonPaymentTermsFail,
  loadingCommonPaymentTerms,
  //
  getCommonDeliveryStyleRequest,
  getCommonDeliveryStyleSuccess,
  getCommonDeliveryStyleFail,
  loadingCommonDeliveryStyle,
  //get common translator
  loadingCommonTranslator,
  getCommonTranslatorRequest,
  getCommonTranslatorSuccess,
  getCommonTranslatorFail,
  //
  loadingCommonFilteredTranslatorOption,
  getCommonFilteredTranslatorOptionRequest,
  getCommonFilteredTranslatorOptionSuccess,
  getCommonTranslatorFilterOptionFail,
  //common trados
  loadingCommonTrados,
  getCommonTradosRequest,
  getCommonTradosSuccess,
  getCommonTradosFail,
  //common tasks
  loadingCommonTask,
  getCommonTaskRequest,
  getCommonTaskSuccess,
  getCommonTaskFail,
  //common sale tax
  loadingCommonSaleTax,
  getCommonSaleTaxRequest,
  getCommonSaleTaxSuccess,
  getCommonSaleTaxFail,
  clickFooterButtonActive,
  //
  loadingCommonDocument,
  getCommonDocumentRequest,
  getCommonDocumentSuccess,
  getCommonDocumentFail,
  //
  loadingCommonTranslationSubject,
  getCommonTranslationSubjectRequest,
  getCommonTranslationSubjectSuccess,
  getCommonTranslationSubjectFail,
  //
  loadingCommonIndustryType,
  getCommonIndustryTypeRequest,
  getCommonIndustryTypeSuccess,
  getCommonIndustryTypeFail,
  //
  loadingCommonExpenseType,
  getCommonExpenseTypeRequest,
  getCommonExpenseTypeSuccess,
  getCommonExpenseTypeFail,
  //
  loadingCommonExportRestriction,
  getCommonExportRestrictionRequest,
  getCommonExportRestrictionSuccess,
  getCommonExportRestrictionFail,
  getCurrentInnerWidth,
  //
  loadingCommonJob,
  getCommonJobRequest,
  getCommonJobSuccess,
  getCommonJobFail,
  //
  loadingCommonUser,
  getCommonUserRequest,
  getCommonUserSuccess,
  getCommonUserFail,
  //
  getCommonSalesDepartmentRequest,
  getCommonSalesDepartmentSuccess,
  getCommonSalesDepartmentFail,
  loadingCommonSalesDepartment,
  //
  getCommonSalesTypeRequest,
  getCommonSalesTypeSuccess,
  getCommonSalesTypeFail,
  loadingCommonSalesType,
  //
  loadingCommonCostSharingDepartmentCode,
  getCommonCostSharingDepartmentCodeRequest,
  getCommonCostSharingDepartmentCodeSuccess,
  getCommonCostSharingDepartmentCodeFail,
  clearCommonUrlFilterData,
  //
  openChangePassword,
  closeChangePassword,
  //common department
  getCommonDepartmentRequest,
  getCommonDepartmentSuccess,
  getCommonDepartmentFail,
  loadingCommonDepartment,
  //common Affiliated department
  getCommonAffiliatedDepartmentRequest,
  getCommonAffiliatedDepartmentSuccess,
  getCommonAffiliatedDepartmentFail,
  loadingCommonAffiliatedDepartment,
  //task type
  getCommonTaskTypeRequest,
  getCommonTaskTypeSuccess,
  getCommonTaskTypeFail,
  loadingCommonTaskType,
  disabledClickFooterButtonActive,
  //
  getCommonStatusRequest,
  getCommonStatusSuccess,
  getCommonStatusFail,
  loadingCommonStatus,
  //
  // getCommonStorePreviousURLPath,
  // clearCommonStorePreviousURLPath,
} = CommonAppSlice.actions

export default CommonAppSlice.reducer
