import React, { Suspense, lazy, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { Typography } from '@mui/material'

import { commonAppSelector } from '../../../CommonAppRedux/selector'
import {
  useAppDispatch,
  useAppSelector,
} from '../../../ReusableComponent/CustomHooks/appHooks'
import { defaultPage, defaultRowsPerPage } from '../../../Utils/globalConstant'
import { getDeliverableListRequest } from '../Redux/deliverableSlice'

const DeliverableListTable = lazy(() => import('./DeliverableListTable'))

const DeliverableList = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { page, rowsPerPage } = useAppSelector(commonAppSelector)
  useEffect(() => {
    dispatch(
      getDeliverableListRequest({
        page,
        rowsPerPage,
      })
    )
  }, [dispatch, page, rowsPerPage])

  return (
    <React.Fragment>
      <Typography variant="h6" fontWeight="bold" sx={{ padding: '16px' }}>
        {t('menuName.deliverableList')}
      </Typography>
      <Suspense fallback={<></>}>
        <DeliverableListTable />
      </Suspense>
    </React.Fragment>
  )
}

export default DeliverableList
