import { Suspense, lazy, useLayoutEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import { Card, CardHeader, Grid, Paper, Typography } from '@mui/material'

import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import { getCommonTranslatorRequest } from '../../../CommonAppRedux/commonAppSlice'
import { authSelector } from '../../../Login/Redux/selector'
import AppDatePicker from '../../../ReusableComponent/AppDatePicker/AppDatePicker'
import AppTextField from '../../../ReusableComponent/AppTextField/AppTextField'
import { NumericFormatCustom } from '../../../ReusableComponent/CommonReportTable/CommonReportTable'
import {
  useAppDispatch,
  useAppSelector,
} from '../../../ReusableComponent/CustomHooks/appHooks'
import { useInputSearch } from '../../../ReusableComponent/CustomHooks/useInputSearch'
import FocusElementOnInvalidValidation from '../../../ReusableComponent/FocusElementIfInvalid/FocusElementOnInvalidValidation'
import SaveFooterComponent from '../../../ReusableComponent/SaveFooterButton/SaveFooterButton'
import { dateFormatter } from '../../../Utils/AppFormatFunction'
import { todayDate } from '../../../Utils/AppFunction'
import messages from '../../../Utils/ValidationMessage'
import { defaultPage, defaultRowsPerPage } from '../../../Utils/globalConstant'
import { query } from '../../../Utils/globalTypes'
import {
  clearHardResetJobList,
  clearJobDetailRow,
  clearJobTask,
  createJobTaskRequest,
  getJobDetailRowByIdRequest,
  getJobTaskByIdRequest,
  getTranslatorFilterRequest,
  updateJobTaskRequest,
} from '../Redux/jobListSlice'
import { jobListSelector } from '../Redux/selector'

interface translatorInitialState {
  unit_cost: any
  page_count: any
  job_detail: number | null | undefined
  email: string
  message: string
  order_date: any

  word_count: number
  order_amount: any
  rate: number

  delivery_date: any

  translator: any
  version: number
}

//below import with lazy loading, which is typically used when you want to load modules or components asynchronously, especially when they are not immediately needed.
const TranslatorMatchingTable = lazy(
  () => import('./TranslatorMatchingTableList')
)

const TranslatorMatching = () => {
  const dispatch = useAppDispatch()

  const { loadingUpdateJobTask, jobTask } = useAppSelector(jobListSelector)
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const id = searchParams.get('job-task-id')
  const jobDetailId = searchParams.get('job-detail-id')
  const edit = searchParams.get('edit')

  const { state } = location
  const previousUrl = state?.path
  //all reducer action and clear function implement start

  useLayoutEffect(() => {
    dispatch(getCommonTranslatorRequest(defaultRowsPerPage))
    if (jobDetailId ?? jobTask?.job_detail) {
      dispatch(getJobDetailRowByIdRequest(jobDetailId ?? jobTask?.job_detail))
    } else {
      dispatch(clearJobDetailRow())
    }
    if (jobTask?.translator?.id) {
      dispatch(
        getTranslatorFilterRequest({
          id: jobTask?.translator?.id,
          status: '052',
          rowsPerPage: defaultRowsPerPage,
          page: defaultPage,
        })
      )
    }
  }, [jobTask, jobDetailId, location, dispatch, edit])

  useLayoutEffect(() => {
    if (id) {
      dispatch(getJobTaskByIdRequest(id))
    } else {
      dispatch(clearJobTask())
      dispatch(clearHardResetJobList())
    }
    return () => {
      dispatch(clearHardResetJobList())
    }
  }, [id, location, dispatch, edit])

  //all reducer action and clear function implement End

  const { t } = useTranslation()
  const navigate = useNavigate()
  //initial state of the translator matching page
  const initialValues: translatorInitialState = {
    job_detail: Number(jobDetailId ?? jobTask?.job_detail),
    email: jobTask?.email ?? '',
    message: jobTask?.message ?? '',
    order_date: jobTask ? jobTask?.order_date : todayDate,
    order_amount: jobTask?.order_amount ?? '',
    rate: jobTask?.rate ?? '',
    delivery_date: jobTask ? jobTask?.delivery_date : todayDate,
    // readability: jobTask?.readability ?? '',
    translator: jobTask?.translator ?? null,
    unit_cost: jobTask?.unit_cost ?? 0,
    page_count: jobTask?.page_count ?? 0,
    word_count: jobTask?.word_count ?? 0,
    version: jobTask?.version ?? 0,
  }
  //validation for translator maching props
  const validationSchema = Yup.object().shape({
    job_detail: Yup.string().required(t(t(messages.required))),
    email: Yup.string(),
    message: Yup.string().required(t(messages.required)),
    translator: Yup.object().required(t(messages.required)),
  })
  const { user } = useAppSelector(authSelector)
  const handleSubmit = async (values: translatorInitialState, actions: any) => {
    const from_order_percussion = state?.from_order_percussion
    const updatedValues = {
      ...values,
      task: 1,
      own_contact_person_user: user?.id,
      translator: values?.translator?.id ?? '',
      from_order_percussion: from_order_percussion
        ? from_order_percussion
        : false,
    }
    if (edit) {
      dispatch(
        updateJobTaskRequest({
          id: jobTask?.id,
          values: updatedValues,
          // nav: navigate,
          actions,
          previousUrl,
          matchingTranslator: true,
        })
      )
    } else {
      dispatch(
        createJobTaskRequest({
          values: updatedValues,
          nav: navigate,
          // actions,
          previousUrl,
          matchingTranslator: true,
          jobDetailId: jobDetailId ?? jobTask?.job_detail,
        })
      )
    }
  }
  const [query, setQuery] = useState<query>({
    search: '',
    url: '',
  })
  useInputSearch(query)

  const focusSaveBtn = useRef(null)

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        handleChange,
        handleBlur,
        values,
        errors,
        setFieldValue,
        submitForm,
      }) => {
        console.log(values, 'what is values')
        const handleSave = () => {
          submitForm()
        }

        const buttonConfigs = [
          ...(jobTask?.job_task_status_code !== '059'
            ? [
                {
                  label: 'common.percussion',
                  handler: handleSave,
                  isShowAlert: true,
                  focusBtn: focusSaveBtn,
                  loading: loadingUpdateJobTask,
                  message: t('confirmMessage.percussion'),
                },
              ]
            : []),
        ]

        const handleButtonAction = (action: string) => {
          const buttonConfig = buttonConfigs.find(
            (config) => config.label === action
          )
          if (buttonConfig) {
            const { handler } = buttonConfig
            handler()
          }
        }
        return (
          <Form autoComplete="off" noValidate>
            {/* header component---------- */}
            <Grid item sx={{ mb: 4 }}>
              <SaveFooterComponent
                handleClick={handleButtonAction}
                buttonConfigs={buttonConfigs}
              />
            </Grid>

            <Paper sx={{ mb: 2 }}>
              <Card variant="outlined">
                <CardHeader
                  title={
                    <Typography
                      variant="h6"
                      sx={{ fontWeight: 'bold', color: 'white' }}
                    >
                      {t('translatorMatchingScreen.name')}
                    </Typography>
                  }
                  sx={{
                    backgroundColor: '#062f96',
                    height: '48px',
                  }}
                />
              </Card>
              <Grid container spacing={2} sx={{ p: 4 }}>
                {/* translator details------------------- */}
                <Grid item xs={5}></Grid>

                <Grid container spacing={2} item xs={12}>
                  <Grid item xs={6}>
                    <Grid container alignItems="center" sx={{ mt: 4 }}>
                      <Grid item xs={5}>
                        {t('translatorMatchingScreen.orderDate')}:
                      </Grid>
                      <AppDatePicker
                        autoFocus
                        xs={6}
                        name="order_date"
                        value={values?.order_date}
                        onChange={(value: any) => {
                          if (value) {
                            setFieldValue(`order_date`, dateFormatter(value))
                          } else {
                            setFieldValue(`order_date`, '')
                          }
                        }}
                        variant="outlined"
                        mt
                        sx={{
                          width: '100%',
                          size: 'small',
                          '& .MuiInputBase-root': {
                            height: '39px',
                            display: 'flex',
                            alignItems: 'center',
                          },
                          '& .MuiInputLabel-root': {
                            textAlign: 'center',
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container alignItems="center" sx={{ mt: 4 }}>
                      <Grid item xs={5}>
                        {t('translatorMatchingScreen.orderAmount')}:
                      </Grid>
                      <AppTextField
                        xs={6}
                        name="order_amount"
                        label={t('translatorMatchingScreen.orderAmount')}
                        value={values?.order_amount}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        InputProps={{
                          inputComponent: NumericFormatCustom as any,
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={6}></Grid>
                  <Grid item xs={6}></Grid>
                  <Grid item xs={6}>
                    <Grid container alignItems="center" sx={{ mt: 4 }}>
                      <Grid item xs={5}>
                        {t('translatorMatchingScreen.deliveryDate')}:
                      </Grid>
                      <AppDatePicker
                        xs={6}
                        name="delivery_date"
                        value={values?.delivery_date}
                        onChange={(value: any) => {
                          if (value !== null) {
                            setFieldValue(`delivery_date`, dateFormatter(value))
                          } else {
                            setFieldValue(`delivery_date`, null)
                          }
                        }}
                        variant="outlined"
                        mt
                        sx={{
                          width: '100%',
                          size: 'small',
                          '& .MuiInputBase-root': {
                            height: '39px',
                            display: 'flex',
                            alignItems: 'center',
                          },
                          '& .MuiInputLabel-root': {
                            textAlign: 'center',
                          },
                        }}
                      />
                    </Grid>
                  </Grid>

                  <Grid item xs={6}>
                    <Grid container alignItems="center" sx={{ mt: 4 }}>
                      <Grid item xs={5}>
                        {t('translatorMatchingScreen.wordCount')}:
                      </Grid>
                      <AppTextField
                        xs={6}
                        name="word_count"
                        label={t('translatorMatchingScreen.wordCount')}
                        value={values?.word_count}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container alignItems="center" sx={{ mt: 4 }}>
                      <Grid item xs={5}>
                        {t('translatorMatchingScreen.rate')}:
                      </Grid>
                      <AppTextField
                        xs={6}
                        name="rate"
                        label={t('translatorMatchingScreen.rate')}
                        value={values?.rate}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container spacing={2} item xs={12} mt={3}>
                  <Grid item xs={6}>
                    <AppTextField
                      id={t('translatorMatchingScreen.addMsgEmail')}
                      xs={12}
                      name="message"
                      placeholder={t('translatorMatchingScreen.addMsgEmail')}
                      multiline
                      rows={3}
                      value={values?.message}
                      onChange={(event: any) => {
                        setFieldValue(`message`, event?.target?.value)
                      }}
                      onBlur={handleBlur}
                      sx={{ width: '100%' }}
                      InputProps={{
                        style: {
                          paddingLeft: '15px',
                          paddingReft: '15px',
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Paper>

            <Suspense fallback={<></>}>
              <TranslatorMatchingTable
                setFieldValue={setFieldValue}
                handleChange={handleChange}
                handleBlur={handleBlur}
                values={values}
              />
            </Suspense>
            <FocusElementOnInvalidValidation />
          </Form>
        )
      }}
    </Formik>
  )
}
export default TranslatorMatching
