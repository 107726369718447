import { axiosInstance } from '../../../Utils/axios'
import { getApiProps } from '../../../Utils/globalTypes'
import { CompleteInspectionProps, MatchingCancelProps } from './types'

const taskStateListModURL = '/task-state-list'

export const getTaskStateList = (data: getApiProps) => {
  const { rowsPerPage, page, filterData } = data
  const params = new URLSearchParams({
    offset: `${rowsPerPage * page}`,
    limit: `${rowsPerPage}`,
  })
  if (filterData?.affiliated_department) {
    params.append('affiliated_department', filterData.affiliated_department)
  }
  if (filterData?.create_user) {
    params.append('create_user', filterData.create_user)
  }
  if (filterData?.case_no) {
    params.append('case_no', filterData.case_no)
  }
  if (filterData?.case_title) {
    params.append('case_title', filterData.case_title)
  }
  if (filterData?.job_no) {
    params.append('job_no', filterData.job_no)
  }
  if (filterData?.status) {
    params.append('status', filterData.status)
  }
  if (filterData?.translator) {
    params.append('translator', filterData.translator)
  }
  if (filterData?.desired_delivery_date_from) {
    params.append(
      'desired_delivery_date_from',
      filterData.desired_delivery_date_from
    )
  }
  if (filterData?.desired_delivery_date_to) {
    params.append(
      'desired_delivery_date_to',
      filterData.desired_delivery_date_to
    )
  }
  if (filterData?.order_date_from) {
    params.append('order_date_from', filterData.order_date_from)
  }
  if (filterData?.order_date_to) {
    params.append('order_date_to', filterData.order_date_to)
  }
  if (filterData?.task_delivery_date_from) {
    params.append('task_delivery_date_from', filterData.task_delivery_date_from)
  }
  if (filterData?.task_delivery_date_to) {
    params.append('task_delivery_date_to', filterData.task_delivery_date_to)
  }
  if (filterData?.stocking_date_from) {
    params.append('stocking_date_from', filterData.stocking_date_from)
  }
  if (filterData?.stocking_date_to) {
    params.append('stocking_date_to', filterData.stocking_date_to)
  }
  if (filterData?.inspection_date_from) {
    params.append('inspection_date_from', filterData.inspection_date_from)
  }
  if (filterData?.inspection_date_to) {
    params.append('inspection_date_to', filterData.inspection_date_to)
  }
  return axiosInstance.get(`${taskStateListModURL}?${params.toString()}`)
}

export const completeInspection = (data: CompleteInspectionProps) => {
  return axiosInstance.patch(
    `/job-task/${data.id}/complete-inspection?task-version=${data.version}`
  )
}

export const matchingCancel = (data: MatchingCancelProps) => {
  return axiosInstance.patch(
    `/job-task/${data.id}/matching-cancel?task-version=${data.version}`
  )
}
