import { axiosInstance } from '../../../../Utils/axios'

const InputTrackRecordModURL = '/cost'

// export const getInputTrackRecord = (data: getApiProps) => {
//   const { rowsPerPage, page } = data
//   return axiosInstance.get(
//     `${InputTrackRecordModURL}?offset=${rowsPerPage * page}&limit=${rowsPerPage}`
//   )
// }

export const getInputTrackRecord = (id: number) => {
  return axiosInstance.get(`${InputTrackRecordModURL}/${id}`)
}

export const createInputTrackRecord = (body: string | FormData) => {
  // return axiosInstance.patch(`${inspectionBulkListModURL}/${body}`)
  return axiosInstance.post(`${InputTrackRecordModURL}`, body)
}

export const updateInputTrackRecord = (id: number, body: string | FormData) => {
  return axiosInstance.patch(`${InputTrackRecordModURL}/${id}`, body)
}
