import React, { useState, Suspense, lazy, useLayoutEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import { Card, CardHeader, Grid, Typography } from '@mui/material'

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import {
  handleFocusSaleTaxCommon,
  handleFocusTranslatorCommon,
  onChangeInputTranslatorCommon,
} from '../../../../CommonAppRedux/commonAppFunctions'
import { commonAppSelector } from '../../../../CommonAppRedux/selector'
import { authSelector } from '../../../../Login/Redux/selector'
import AppAutoComplete from '../../../../ReusableComponent/AppAutoComplete/AppAutoComplete'
import AppDatePicker from '../../../../ReusableComponent/AppDatePicker/AppDatePicker'
import AppTextField from '../../../../ReusableComponent/AppTextField/AppTextField'
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../ReusableComponent/CustomHooks/appHooks'
import { useInputSearch } from '../../../../ReusableComponent/CustomHooks/useInputSearch'
import FocusElementOnInvalidValidation from '../../../../ReusableComponent/FocusElementIfInvalid/FocusElementOnInvalidValidation'
import History from '../../../../ReusableComponent/History'
import IssueBarComponent from '../../../../ReusableComponent/IssueBarComponent/IssueBarComponent'
import ReasonForChange from '../../../../ReusableComponent/ReasonForChange/ReasonForChange'
import SaveFooterComponent from '../../../../ReusableComponent/SaveFooterButton/SaveFooterButton'
import WorkFlowPanel from '../../../../ReusableComponent/Workflow'
import { workflowSelector } from '../../../../ReusableComponent/Workflow/Redux/selector'
import WorkFlowHistoryTable from '../../../../ReusableComponent/WorkflowHistory'
import {
  dateFormatter,
  formateNumberAgain,
} from '../../../../Utils/AppFormatFunction'
import {
  downloadPDF,
  handleCancelButtonBackend,
  todayDate,
} from '../../../../Utils/AppFunction'
import messages, {
  maxCharValidation,
} from '../../../../Utils/ValidationMessage'
import { query } from '../../../../Utils/globalTypes'
import PreviewIndex from '../../../Preview'
import { taxSubTotalProps } from '../../../ProjectList/IssueReportPages/IssueQuotation'
import {
  clearHardResetJobList,
  clearJobDetailRow,
  clearJobTask,
  clearPurchaseOrder,
  createPurchaseOrderRequest,
  getJobDetailRowByIdRequest,
  getJobTaskByIdRequest,
  getPurchaseOrderByIdRequest,
  updatePurchaseOrderRequest,
} from '../../Redux/jobListSlice'
import { jobListSelector } from '../../Redux/selector'
import { saleTax } from '../../Types'

const CommonReportTable = lazy(
  () =>
    import('../../../../ReusableComponent/CommonReportTable/CommonReportTable')
)

export interface purchase_order_details {
  uniqueId?: string
  job_no: any
  desired_delivery_date: any
  tax_excluded_price: string | number
  tax_included_price: string | number
  tax_amount: number
  sales_tax: saleTax | null
}

export interface purchaseOrder {
  workflow?: any
  id?: number
  job_task?: string
  purchase_order_no: string
  purchase_order_date: string | Date | null
  translator: any
  remarks: string
  reason: string
  comment: string
  purchase_order_details: purchase_order_details[]
  history?: any
  tax_subtotal: taxSubTotalProps[]
  issue_flag?: boolean
  withdrawal_flag?: boolean
  version: number
}

const IssuePurchaseOrder = () => {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const jobTaskId = searchParams.get('job-task-id')
  const purchaseOrderId = searchParams.get('purchase-order-id')
  const edit = searchParams.get('edit')
  const issuePurchaseOrderId = searchParams.get('issue-purchase-id')

  const { state } = location
  const previousUrl = state?.path
  const { purchaseOrder, jobTask, loadingCreatePurchaseOrder, jobDetailRow } =
    useAppSelector(jobListSelector)
  const { commonSalesTax, commonTranslator, loadingCommonTranslator } =
    useAppSelector(commonAppSelector)
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { workflow } = useAppSelector(workflowSelector)

  useLayoutEffect(() => {
    handleFocusSaleTaxCommon(dispatch)
    if (jobTaskId) {
      dispatch(getJobTaskByIdRequest(jobTaskId))
    } else {
      dispatch(clearJobTask())
      dispatch(clearHardResetJobList())
    }
    return () => {
      dispatch(clearHardResetJobList())
    }
  }, [jobTaskId, location, dispatch, edit])

  useLayoutEffect(() => {
    if (jobTask?.job_detail) {
      dispatch(getJobDetailRowByIdRequest(jobTask?.job_detail))
    } else {
      dispatch(clearJobDetailRow())
    }
    if ((purchaseOrderId || issuePurchaseOrderId) && edit) {
      dispatch(
        getPurchaseOrderByIdRequest(purchaseOrderId || issuePurchaseOrderId)
      )
    } else {
      dispatch(clearPurchaseOrder())
    }
  }, [
    jobTask,
    purchaseOrderId,
    issuePurchaseOrderId,
    workflow,
    location,
    dispatch,
    edit,
  ])

  const taskJobNoDetailNo =
    (jobDetailRow?.job_no ?? '') + (jobDetailRow?.detail_no ?? '')
  const updatedPurchaseOrderDetailsData =
    purchaseOrder?.purchase_order_details?.map((details: any) => {
      const matchingSubtotal = purchaseOrder?.tax_subtotal?.find(
        (subtotal: any) => {
          return subtotal?.sales_tax?.id === details?.sales_tax?.id
        }
      )
      return {
        ...details,
        sub_total_id: matchingSubtotal?.id ? matchingSubtotal?.id : undefined,
      }
    })

  const initialState: purchaseOrder = {
    job_task: purchaseOrder?.job_task ? purchaseOrder?.job_task : '',
    purchase_order_no: purchaseOrder?.purchase_order_no
      ? purchaseOrder?.purchase_order_no
      : '',
    purchase_order_date: purchaseOrder
      ? purchaseOrder?.purchase_order_date
      : todayDate,
    translator: purchaseOrder
      ? purchaseOrder?.translator
      : jobTask?.translator ?? null,
    remarks: purchaseOrder?.remarks ?? '',
    reason: '',
    comment: '',
    tax_subtotal: purchaseOrder?.tax_subtotal
      ? purchaseOrder?.tax_subtotal
      : [
          {
            sales_tax: null,
            subtotal_amount: 0,
            amount: 0,
          },
        ],
    purchase_order_details: updatedPurchaseOrderDetailsData
      ? updatedPurchaseOrderDetailsData
      : [
          {
            job_no: taskJobNoDetailNo,
            desired_delivery_date: jobTask?.delivery_date ?? todayDate,
            tax_excluded_price: jobTask?.order_amount ?? 0,
            tax_included_price: 0,
            tax_amount: 0,
            sales_tax: jobTask?.translator?.sales_tax,
          },
        ],
    version: purchaseOrder?.version ?? 0,
  }

  const validationSchema = Yup.object().shape({
    job_task: Yup.string(),
    title: Yup.string().max(100, maxCharValidation(100)),
    purchase_order_details: Yup.array().of(
      Yup.object().shape({
        desired_delivery_date: Yup.string().required(t(messages.required)),
        sales_tax: Yup.object().required(t(messages.required)),
      })
    ),
  })

  const columns = [
    {
      id: 'job_no',
      label: `${t('taskList.issuePurchase.tableItems.jobNo')}`,
      minWidth: 150,
      disabled: true,
    },
    {
      id: 'desired_delivery_date',
      label: `${t('taskList.issuePurchase.tableItems.desiredDeliveryDate')}`,
      minWidth: 200,
      required: true,
      dateField: true,
    },
    {
      id: 'tax_excluded_price',
      label: `${t('taskList.issuePurchase.tableItems.taxExcludedPrice')}`,
      currency: true,
      minWidth: 150,
      taxExcludedPrice: true,
    },
    {
      id: 'tax_amount',
      label: `${t('taskList.issuePurchase.tableItems.taxAmount')}`,
      minWidth: 150,
      currency: true,
      taxAmount: true,
    },
    {
      id: 'tax_included_price',
      label: `${t('taskList.issuePurchase.tableItems.taxIncludedPrice')}`,
      currency: true,
      minWidth: 150,
      taxIncludedPrice: true,
    },
    {
      id: 'sales_tax',
      label: `${t('taskList.issuePurchase.tableItems.salesTax')}`,
      minWidth: 200,
      dropDown: true,
      options: commonSalesTax,
    },
    {
      id: 'actions',
      label: `${t('common.actions')}`,
      minWidth: 100,
      actions: true,
    },
  ]

  const [query, setQuery] = useState<query>({
    search: '',
    url: '',
  })
  useInputSearch(query)

  const nav = useNavigate()
  const [detailsData, setDetailsData] = useState([])

  const onSubmit = async (values: purchaseOrder, actions: any) => {
    const { translator, tax_subtotal, purchase_order_date } = values
    const updatedTaxSubTotal = tax_subtotal?.map((row: any) => ({
      ...row,
      sales_tax: row?.sales_tax?.id,
    }))

    if (edit) {
      const updatedPurchaseDetails = detailsData?.map((details: any) => ({
        id: details?.id,
        job_no: details?.job_no,
        sales_tax: details?.sales_tax?.id,
        tax_included_price: details?.tax_included_price,
        tax_excluded_price: details?.tax_excluded_price,
        tax_amount: details.tax_amount,
        desired_delivery_date: details?.desired_delivery_date,
      }))
      const updatedValues = {
        ...values,
        job_task: jobTaskId,
        translator: translator ? translator?.id : null,
        purchase_order_date: purchase_order_date ? purchase_order_date : null,
        purchase_order_details: updatedPurchaseDetails,
        tax_subtotal: updatedTaxSubTotal,
      }
      dispatch(
        updatePurchaseOrderRequest({
          id: purchaseOrderId,
          values: updatedValues,
          nav: nav,
          actions,
          previousUrl,
          jobTaskId,
        })
      )
    } else {
      const updatedPurchaseOrderDetails = detailsData?.map((details: any) => ({
        ...details,
        sales_tax: details?.sales_tax?.id,
        amount: formateNumberAgain(details?.amount),
        unit_cost: formateNumberAgain(details?.unit_cost),
        tax_amount: formateNumberAgain(details.tax_amount),
      }))
      const updatedValues = {
        ...values,
        job_task: jobTaskId,
        translator: translator ? translator?.id : null,
        purchase_order_date: purchase_order_date ? purchase_order_date : null,
        purchase_order_details: updatedPurchaseOrderDetails,
        tax_subtotal: updatedTaxSubTotal,
      }
      dispatch(
        createPurchaseOrderRequest({
          values: updatedValues,
          nav: nav,
          actions,
          previousUrl,
          jobTaskId,
        })
      )
    }
  }

  const navigate = useNavigate()
  const handlePreview = () => {
    const url = 'purchase-order-report'
    const queryParams = `?id=${purchaseOrderId}&preview-report-url=${url}&file-name=${purchaseOrder?.purchase_order_no}`
    navigate(`/preview${queryParams}`)
  }

  const handleCancel = () => {
    const apiUrl = `translator-purchase-order/${jobTask?.translator_purchase_order?.translator_purchase_order}/cancel-translator-purchase-order?task-version=${jobTask?.version}`
    const queryParams = `?job-task-id=${jobTaskId}`
    const navLink = '/issue-purchase'
    handleCancelButtonBackend(dispatch, apiUrl, queryParams, navLink, nav)
  }

  const [readOnlyMode, setReadOnlyMode] = useState(false)
  useLayoutEffect(() => {
    if (workflow) {
      if (workflow?.status !== 1) {
        setReadOnlyMode(true)
      } else {
        setReadOnlyMode(false)
      }
    } else {
      setReadOnlyMode(false)
    }
    return () => {
      setReadOnlyMode(false)
    }
  }, [workflow, purchaseOrder])
  const { is_staff } = useAppSelector(authSelector)
  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialState}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          values,
          handleChange,
          setFieldValue,
          handleBlur,
          errors,
          submitForm,
          touched,
          validateForm,
        }) => {
          const handleSave = async () => {
            try {
              await validateForm()
              await submitForm()
            } catch (validationError) {
              console.log('the validation error is', validationError)
            }
          }
          const buttonConfigs = [
            ...(!readOnlyMode && !purchaseOrder?.withdrawal_flag
              ? [
                  {
                    label: `${edit ? 'common.update' : 'common.save'}`,
                    handler: handleSave,
                    isShowAlert: true,
                    loading: loadingCreatePurchaseOrder,
                    message: edit
                      ? t('confirmMessage.update')
                      : t('confirmMessage.save'),
                  },
                ]
              : []),

            ...(edit &&
            (jobTask?.translator_purchase_order?.translator_purchase_order ||
              purchaseOrder)
              ? [
                  {
                    label: 'common.preview',
                    handler: handlePreview,
                  },
                ]
              : []),
            ...(jobTask?.translator_purchase_order?.translator_purchase_order &&
            jobTask?.job_task_status_code < '053' &&
            !purchaseOrder?.withdrawal_flag
              ? [
                  {
                    label: 'common.cancel',
                    handler: handleCancel,
                    isShowAlert: true,
                    message: t('confirmMessage.cancel'),
                  },
                ]
              : []),
          ]

          const handleButtonAction = (action: string) => {
            const buttonConfig = buttonConfigs.find(
              (config) => config.label === action
            )
            if (buttonConfig) {
              const { handler } = buttonConfig
              handler()
            }
          }
          return (
            <>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Grid item sx={{ mb: 4 }}>
                  <SaveFooterComponent
                    handleClick={handleButtonAction}
                    buttonConfigs={buttonConfigs}
                  />
                </Grid>
                {edit && (
                  <WorkFlowPanel
                    id={purchaseOrder?.workflow}
                    purchaseOrderCancel={purchaseOrder?.withdrawal_flag}
                  />
                )}
                {readOnlyMode ? (
                  <PreviewIndex
                    hideButton={true}
                    directUrl="purchase-order-report"
                    directId={purchaseOrder?.id || issuePurchaseOrderId}
                  />
                ) : (
                  <div className={readOnlyMode ? 'disabled-form' : ''}>
                    {edit && (
                      <ReasonForChange
                        name="reason"
                        setFieldValue={setFieldValue}
                        onChange={handleChange}
                      />
                    )}
                    <IssueBarComponent isIssued={purchaseOrder?.issue_flag} />
                    <Form autoComplete="off" noValidate>
                      <Card sx={{ mb: 2, bgcolor: '#d7e2ea' }}>
                        <CardHeader
                          title={
                            <Typography
                              variant="h6"
                              sx={{ fontWeight: 'bold', color: 'white' }}
                            >
                              {t('taskList.issuePurchase.purchaseIssue')}
                            </Typography>
                          }
                          sx={{
                            backgroundColor: '#062f96',
                            height: '48px',
                          }}
                        />
                        <h1 style={{ textAlign: 'center' }}>
                          {t('taskList.issuePurchase.purchaseIssue')}
                        </h1>
                        <Grid
                          container
                          spacing={2}
                          padding={4}
                          alignItems="center"
                        >
                          <Grid item xs={6}>
                            <Grid container alignItems="center" mb={2}>
                              <Grid item xs={3.6}>
                                {t('taskList.issuePurchase.purchase_order_no')}:
                              </Grid>
                              <AppTextField
                                disabled
                                xs={5}
                                name="purchase_order_no"
                                value={values?.purchase_order_no}
                                label={t(
                                  'taskList.issuePurchase.purchase_order_no'
                                )}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </Grid>
                            <Grid container alignItems="center" mb={0}></Grid>
                          </Grid>
                          <Grid item xs={6}>
                            <Grid container alignItems="center" mb={1}>
                              <Grid item xs={4}>
                                {t('taskList.issuePurchase.purchaseOrderDate')}:
                              </Grid>
                              <AppDatePicker
                                xs={5}
                                disabled={readOnlyMode}
                                name="purchase_order_date"
                                value={values?.purchase_order_date}
                                onChange={(value: any) => {
                                  if (value !== null) {
                                    setFieldValue(
                                      `purchase_order_date`,
                                      dateFormatter(value)
                                    )
                                  } else {
                                    setFieldValue(`purchase_order_date`, null)
                                  }
                                }}
                                variant="outlined"
                                mt
                                sx={{
                                  width: '100%',
                                  size: 'small',
                                  '& .MuiInputBase-root': {
                                    height: '39px',
                                    display: 'flex',
                                    alignItems: 'center',
                                  },
                                  '& .MuiInputLabel-root': {
                                    textAlign: 'center',
                                  },
                                }}
                              />
                            </Grid>
                          </Grid>

                          <Grid item xs={12}>
                            <Grid container alignItems="center">
                              <Grid item xs={1.8}>
                                {t('taskList.issuePurchase.translator')}:
                              </Grid>
                              <AppAutoComplete
                                xs={2.5}
                                disabled={readOnlyMode}
                                name="translator"
                                value={values?.translator}
                                options={commonTranslator}
                                loading={loadingCommonTranslator}
                                getOptionLabel={(option: any) => option.name}
                                label={t('taskList.issuePurchase.translator')}
                                onChange={(event: any, value: any) => {
                                  if (value !== null) {
                                    setFieldValue('translator', value)
                                    const updatedDetails =
                                      values.purchase_order_details.map(
                                        (detail) => {
                                          const taxRate = parseFloat(
                                            value.sales_tax.tax_rate
                                          )
                                          const excludedPrice =
                                            typeof detail.tax_excluded_price ===
                                            'number'
                                              ? detail.tax_excluded_price
                                              : parseFloat(
                                                  detail.tax_excluded_price
                                                )

                                          const taxAmount =
                                            (excludedPrice * taxRate) / 100
                                          const roundedTaxAmount =
                                            taxAmount.toFixed(0)

                                          const includedPrice =
                                            excludedPrice * (1 + taxRate / 100)
                                          const roundedIncludedPrice =
                                            includedPrice.toFixed(0)

                                          return {
                                            ...detail,
                                            sales_tax: value.sales_tax,
                                            tax_amount:
                                              parseFloat(roundedTaxAmount),
                                            tax_included_price:
                                              parseFloat(roundedIncludedPrice),
                                          }
                                        }
                                      )

                                    const taxAmountSum = updatedDetails.reduce(
                                      (sum, detail) => sum + detail.tax_amount,
                                      0
                                    )
                                    const taxSubtotalAmountSum =
                                      updatedDetails.reduce(
                                        (sum, detail) =>
                                          sum +
                                          (typeof detail.tax_excluded_price ===
                                          'number'
                                            ? detail.tax_excluded_price
                                            : parseFloat(
                                                detail.tax_excluded_price
                                              )),
                                        0
                                      )

                                    const updatedTaxSubtotal = {
                                      sales_tax: value?.sales_tax,
                                      amount: taxAmountSum,
                                      subtotal_amount: taxSubtotalAmountSum,
                                    }
                                    setFieldValue(
                                      'purchase_order_details',
                                      updatedDetails
                                    )
                                    setFieldValue('tax_subtotal', [
                                      updatedTaxSubtotal,
                                    ])
                                  } else {
                                    setFieldValue('translator', null)
                                  }
                                }}
                                onFocus={() =>
                                  handleFocusTranslatorCommon(dispatch)
                                }
                                onBlur={handleBlur}
                                inputOnchange={(e: any) =>
                                  onChangeInputTranslatorCommon(
                                    e.target,
                                    setQuery
                                  )
                                }
                              />
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <Grid container alignItems="center" mt={2}>
                              <Grid item xs={1.8}>
                                {t('taskList.issuePurchase.remarks')}:
                              </Grid>
                              <AppTextField
                                xs={8.8}
                                disabled={readOnlyMode}
                                name="remarks"
                                value={values?.remarks}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                multiline // Set the multiline prop to true
                                rows={4}
                                InputProps={{
                                  style: { padding: '10px' }, // Add padding to the input element
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          padding={4}
                          rowSpacing={2}
                          alignItems="center"
                        ></Grid>
                        <Suspense fallback={<></>}>
                          <CommonReportTable
                            setDetailsData={setDetailsData}
                            columns={columns}
                            taxType={`${t('commonFinanceTerm.excludingTax')})`}
                            keyName="purchase_order_details"
                            values={values?.purchase_order_details}
                            retrieve_tax_subtotal={values?.tax_subtotal}
                            handleChange={handleChange}
                            setFieldValue={setFieldValue}
                            handleBlur={handleBlur}
                            initialValues={initialState?.purchase_order_details}
                            setQuery={setQuery}
                            touched={touched}
                            disabled={readOnlyMode}
                            bgColor="#d7e2ea"
                            edit={edit}
                            translatorsSalesTax={values?.translator?.sales_tax}
                            jobTaskDeliveryDate={
                              jobTask?.delivery_date ?? todayDate
                            }
                          />
                        </Suspense>
                      </Card>
                      <FocusElementOnInvalidValidation />
                    </Form>
                  </div>
                )}
                {edit && purchaseOrder?.workflow && (
                  <>
                    <WorkFlowHistoryTable />
                    {is_staff && (
                      <History
                        history={
                          purchaseOrder?.history?.length > 0
                            ? purchaseOrder?.history
                            : []
                        }
                      />
                    )}
                  </>
                )}
              </LocalizationProvider>
            </>
          )
        }}
      </Formik>
    </>
  )
}

export default IssuePurchaseOrder
