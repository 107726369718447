import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import {
  User,
  Workflow,
  WorkflowAction,
  WorkflowActionPayload,
  WorkflowState,
} from '../Types'

const initialState: WorkflowState = {
  loading: false,
  users: [],
  loadingUser: false,
  workflow: null,
}

export const WorkflowSlice = createSlice({
  name: 'workflowReducer',
  initialState,
  reducers: {
    getWorkflowRequest: (state, action: PayloadAction<number>) => {
      state.loading = true
      console.log(state.loading)
    },
    getWorkflowSuccess: (state, action: PayloadAction<Workflow>) => {
      state.loading = false
      state.workflow = action.payload
    },
    getWorkflowFail: (state) => {
      state.loading = false
      state.workflow = null
    },
    updateWorkflowRequest: (
      state,
      action: PayloadAction<{
        id: number
        body: WorkflowActionPayload
        action: WorkflowAction
      }>
    ) => {
      state.loading = true
    },
    updateWorkflowSuccess: (state, action: PayloadAction<Workflow>) => {
      state.loading = false
      state.workflow = action.payload
    },
    updateWorkflowFail: (state) => {
      state.loading = false
    },
    clearWorkflow: (state) => {
      state.workflow = null
    },
    getUsersRequest: (state, payload: PayloadAction<string>) => {
      state.loadingUser = true
    },
    getUsersSuccess: (state, action: PayloadAction<User[]>) => {
      state.users = action.payload
      state.loadingUser = false
    },
    getUsersFail: (state) => {
      state.loadingUser = false
    },
  },
})
export const {
  clearWorkflow,
  getWorkflowRequest,
  getWorkflowSuccess,
  getWorkflowFail,
  updateWorkflowRequest,
  updateWorkflowSuccess,
  updateWorkflowFail,
  getUsersRequest,
  getUsersSuccess,
  getUsersFail,
} = WorkflowSlice.actions
export default WorkflowSlice.reducer
