import { memo, useEffect } from 'react'

import { Autocomplete, Box, Grid, TextField } from '@mui/material'

import { ErrorMessage, FastField } from 'formik'

import { handleFocus } from '../FocusElement/focusElement'
import TextError from '../TextError/TextError'
import { appAutoCompleteProps } from './types'

const AppAutoComplete = ({
  options,
  value,
  name,
  getOptionLabel,
  onChange,
  inputRef,
  label,
  xs,
  md,
  lg,
  sm,
  required,
  focusElement,
  onBlur,
  multiple,
  onFocus,
  isNotFormik,
  autoFocus,
  inputOnchange,
  loading,
  disabled,
  filterSelectedOptions,
  renderTags,
  renderOption,
  mt,
  classes,
  handleDisabledOption,
  groupBy,
  onKeyUp,
  InputProps,
  style,
  defaultFilter,
  variant,
  helperText,
  placeholder,
  defaultValue,
  size,
  setFieldValue,
  notShrink,
  isOptionEqualToValue,
}: appAutoCompleteProps) => {
  // const handleFocusElement = (e: React.KeyboardEvent<HTMLDivElement>) =>
  //   handleFocus(focusElement, e, required && value)
  const defaultRenderOption = (props: any, option: any) => (
    <Box component="li" {...props} key={option.id ?? option?.value}>
      {getOptionLabel(option)}
    </Box>
  )
  const dullLabelStyle = {
    color: 'gray', // Replace with your desired dull color
  }
  const disableFlagOptions = (option: any) => {
    return option.disable_flag
  }
  return (
    <Grid
      item
      xs={xs}
      md={md}
      lg={lg}
      sm={sm}
      mt={mt}
      className={'textfield-wrapper'}
    >
      <Autocomplete
        style={style}
        id={label}
        options={options}
        multiple={multiple}
        classes={classes}
        value={value}
        filterOptions={(options, state) =>
          !defaultFilter
            ? options
            : options.filter((option) => {
                const optionLabel = getOptionLabel(option)
                return (
                  optionLabel &&
                  optionLabel
                    .toLowerCase()
                    .includes(state.inputValue.toLowerCase())
                )
              })
        }
        // filterOptions={(options, state) =>
        //   options.filter((option) => {
        //     const optionLabel = getOptionLabel(option);
        //     return option && option.toLowerCase().includes(state.inputValue.toLowerCase());
        //   })
        // }
        getOptionLabel={getOptionLabel}
        isOptionEqualToValue={
          isOptionEqualToValue ||
          ((option, value) => {
            return option?.id
              ? option?.id === value?.id
              : option?.value === value?.value
            // return option?.id  === value?.id;
          })
        }
        onFocus={onFocus}
        onChange={onChange}
        onBlur={onBlur}
        loading={loading}
        disabled={disabled}
        getOptionDisabled={disableFlagOptions}
        renderTags={renderTags}
        filterSelectedOptions={filterSelectedOptions}
        renderOption={renderOption ?? defaultRenderOption}
        groupBy={groupBy}
        size={size ?? 'medium'}
        renderInput={(params) => (
          // <FastField name={name}>
          //   {({ field, form, meta }: any) => (
          <>
            <TextField
              {...params}
              onChange={inputOnchange}
              name={name}
              variant={variant ?? 'outlined'}
              label={label && label}
              autoFocus={autoFocus ?? false}
              // inputRef={inputRef}
              helperText={helperText}
              placeholder={placeholder}
              required={required}
              disabled={disabled}
              InputLabelProps={{
                // style: dullLabelStyle,
                shrink: notShrink ? undefined : true,
              }}
              // onKeyDown={handleFocusElement}
              onKeyUp={onKeyUp}
            />
          </>
          //   )}
          // </FastField>
        )}
      />
      {!isNotFormik && <ErrorMessage name={name!} component={TextError} />}
    </Grid>
  )
}

export default memo(AppAutoComplete)
