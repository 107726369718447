import React, { useState, useRef, useEffect, useLayoutEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { Grid } from '@mui/material'

import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import {
  handleFocusCostSharingDepartmentCodeCommon,
  handleFocusExpenseTypeCommon,
  handleFocusSaleTaxCommon,
  handleFocusTranslatorLanguageCommon,
  onChangeInputCostSharingDepartmentCodeCommon,
  onChangeInputExpenseTypeCommon,
  onChangeInputTranslatorLanguageCommon,
} from '../../../../CommonAppRedux/commonAppFunctions'
import { commonAppSelector } from '../../../../CommonAppRedux/selector'
import { commonOptionType } from '../../../../CustomAlert/types'
import { authSelector } from '../../../../Login/Redux/selector'
import AppAutoComplete from '../../../../ReusableComponent/AppAutoComplete/AppAutoComplete'
import AppTextField from '../../../../ReusableComponent/AppTextField/AppTextField'
import AppTitleCard from '../../../../ReusableComponent/AppTitleCard/AppTitleCard'
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../ReusableComponent/CustomHooks/appHooks'
import { useInputSearch } from '../../../../ReusableComponent/CustomHooks/useInputSearch'
import FocusElementOnInvalidValidation from '../../../../ReusableComponent/FocusElementIfInvalid/FocusElementOnInvalidValidation'
import SaveFooterComponent from '../../../../ReusableComponent/SaveFooterButton/SaveFooterButton'
import { todayDate } from '../../../../Utils/AppFunction'
import messages, {
  maxCharValidation,
} from '../../../../Utils/ValidationMessage'
import { InputChangeEvent, query } from '../../../../Utils/globalTypes'
import {
  clearDataProjectDetails,
  clearHardResetProjectList,
  clearQuotation,
  completeCertificateRequest,
  getCertificateCompleteByIdRequest,
  getProjectDetailByIdRequest,
  updateProjectDetailsRequest,
} from '../../Redux/projectListSlice'
import { projectListSelector } from '../../Redux/selector'
import { expenseType } from '../../Types'

export interface CompleteCertificatePayload {
  cost_sharing_department_code1: any
  cost_sharing_department_code2: any
  production_order_no1: string
  production_order_no2: string
  expense_type: expenseType | null
  message: string
}

const CertificateComplete = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const id = searchParams.get('case-id')
  const { state } = location
  const previousUrl = state?.path
  const {
    commonExpenseTypes,
    loadingCommonExpenseType,
    commonCostSharingDepartmentCodes,
  } = useAppSelector(commonAppSelector)
  const {
    projectDetail,
    completingCertificate: loadingCreateCertificateComplete,
    certificateComplete,
  } = useAppSelector(projectListSelector)
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  //all reducer action and clear function implement start

  useLayoutEffect(() => {
    handleFocusSaleTaxCommon(dispatch)
    if (id) {
      dispatch(getProjectDetailByIdRequest(id))
    } else {
      dispatch(clearDataProjectDetails())
      dispatch(clearHardResetProjectList())
    }
    return () => {
      dispatch(clearHardResetProjectList())
    }
  }, [id, location, dispatch])

  //all reducer action and clear function implement End

  // useEffect(() => {
  //   handleFocusSaleTaxCommon(dispatch)
  //   if (id) {
  //     dispatch(getProjectDetailByIdRequest(id))
  //   }

  //   return () => {
  //     dispatch(clearQuotation())
  //     dispatch(clearDataProjectDetails())
  //   }
  // }, [id])

  const initialState: CompleteCertificatePayload = {
    cost_sharing_department_code1: projectDetail?.cost_sharing_department_code1
      ? projectDetail?.cost_sharing_department_code1
      : null,
    cost_sharing_department_code2: projectDetail?.cost_sharing_department_code2
      ? projectDetail?.cost_sharing_department_code2
      : null,
    production_order_no1: projectDetail?.production_order_no1
      ? projectDetail?.production_order_no1
      : '',
    production_order_no2: projectDetail?.production_order_no2
      ? projectDetail?.production_order_no2
      : '',
    expense_type: projectDetail?.expense_type
      ? projectDetail?.expense_type
      : null,
    message: '',
  }
  const validationSchema = Yup.object().shape({
    expense_type: Yup.object().required(t(messages.required)),
    message: Yup.string().max(500, maxCharValidation(500)),
    cost_sharing_department_code1: Yup.object()
      .shape({
        production_order_no_required_flag: Yup.boolean().optional(),
      })
      .nullable()
      .required(t(messages.required)),
    cost_sharing_department_code2: Yup.object()
      .shape({
        production_order_no_required_flag: Yup.boolean().optional(),
      })
      .nullable(),
    production_order_no1: Yup.string()
      .max(20, maxCharValidation(20))
      .when(
        ['cost_sharing_department_code1.production_order_no_required_flag'],
        (flag, schema) => {
          return flag[0]
            ? schema.required(t('validationMessage.costSharingRequired1'))
            : schema
        }
      ),
    production_order_no2: Yup.string()
      .max(20, maxCharValidation(20))
      .when(
        ['cost_sharing_department_code2.production_order_no_required_flag'],
        (flag, schema) => {
          return flag[0]
            ? schema.required(t('validationMessage.costSharingRequired2'))
            : schema
        }
      ),
  })

  const nav = useNavigate()

  const onSubmit = async (values: CompleteCertificatePayload, actions: any) => {
    if (id !== undefined) {
      // if values contain object value, convert to id
      const payload = Object.keys(values).reduce((acc, key) => {
        if (
          typeof values[key as keyof CompleteCertificatePayload] === 'object'
        ) {
          acc[key as keyof CompleteCertificatePayload] =
            values[key as keyof CompleteCertificatePayload]?.id
        } else {
          acc[key as keyof CompleteCertificatePayload] =
            values[key as keyof CompleteCertificatePayload]
        }
        return acc
      }, {} as CompleteCertificatePayload)
      dispatch(
        completeCertificateRequest({
          values: {
            ...payload,
            case: id,
            version: projectDetail?.version,
          },
          nav: nav,
          actions,
          previousUrl,
        })
      )
    }
  }
  // onfocus
  const focus_cost_sharing_department_code1 = useRef(null)
  const focus_cost_sharing_department_code2 = useRef(null)
  const focus_production_order_no1 = useRef(null)
  const focus_production_order_no2 = useRef(null)
  const focus_expense_type = useRef(null)
  const focus_message = useRef(null)
  const focus_save_btn = useRef(null)

  const [query, setQuery] = useState<query>({
    search: '',
    url: '',
  })
  useInputSearch(query)
  const { is_staff } = useAppSelector(authSelector)
  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialState}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          values,
          handleChange,
          setFieldValue,
          handleBlur,
          errors,
          submitForm,
          touched,
        }) => {
          const handleSave = () => {
            submitForm()
          }
          const buttonConfigs = [
            ...(['060'].includes(projectDetail?.status?.code)
              ? [
                  {
                    label:
                      'deliveryReportNoteApprove.certificateComplete.certificateComplete',
                    handler: handleSave,
                    isShowAlert: true,
                    focusBtn: focus_save_btn,
                    loading: loadingCreateCertificateComplete,
                    message: t('confirmMessage.completeInspection'),
                  },
                ]
              : []),
          ]

          const handleButtonAction = (action: string) => {
            const buttonConfig = buttonConfigs.find(
              (config) => config.label === action
            )
            if (buttonConfig) {
              const { handler } = buttonConfig
              handler()
            }
          }

          return (
            <>
              <Grid item sx={{ mb: 4 }}>
                <SaveFooterComponent
                  handleClick={handleButtonAction}
                  buttonConfigs={buttonConfigs}
                />
              </Grid>
              <Form autoComplete="off" noValidate>
                <AppTitleCard title="deliveryReportNoteApprove.certificateComplete.title">
                  <h1 style={{ textAlign: 'center' }}>
                    {t('deliveryReportNoteApprove.certificateComplete.title')}
                  </h1>
                  <Grid
                    container
                    padding={4}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Grid item xs={6}>
                      <Grid container alignItems="center">
                        <Grid item xs={4}>
                          {t(
                            'deliveryReportNoteApprove.certificateComplete.costSharingDepartmentCode1'
                          )}
                          :
                        </Grid>
                        <AppAutoComplete
                          xs={5}
                          options={commonCostSharingDepartmentCodes}
                          value={values?.cost_sharing_department_code1 ?? null}
                          getOptionLabel={(option: any) => option.name}
                          label={t(
                            'projectList.columns.costSharingDepartmentCode1'
                          )}
                          // required
                          name="cost_sharing_department_code1"
                          onChange={(_: object, value: any) => {
                            if (value !== null) {
                              setFieldValue(
                                `cost_sharing_department_code1`,
                                value
                              )
                            } else {
                              setFieldValue(
                                `cost_sharing_department_code1`,
                                null
                              )
                            }
                          }}
                          onFocus={() =>
                            handleFocusCostSharingDepartmentCodeCommon(dispatch)
                          }
                          onBlur={handleBlur}
                          inputOnchange={(e: any) =>
                            onChangeInputCostSharingDepartmentCodeCommon(
                              e.target,
                              setQuery
                            )
                          }
                        />
                      </Grid>
                      <Grid container alignItems="center" mt={3}>
                        <Grid item xs={4}>
                          {t(
                            'deliveryReportNoteApprove.certificateComplete.costSharingDepartmentCode2'
                          )}
                        </Grid>
                        <AppAutoComplete
                          xs={5}
                          options={commonCostSharingDepartmentCodes}
                          value={values?.cost_sharing_department_code2 ?? null}
                          getOptionLabel={(option: any) => option.name}
                          label={t(
                            'projectList.columns.costSharingDepartmentCode2'
                          )}
                          name="cost_sharing_department_code2"
                          onChange={(_: object, value: any) => {
                            if (value !== null) {
                              setFieldValue(
                                `cost_sharing_department_code2`,
                                value
                              )
                            } else {
                              setFieldValue(
                                `cost_sharing_department_code2`,
                                null
                              )
                            }
                          }}
                          onFocus={() =>
                            handleFocusCostSharingDepartmentCodeCommon(dispatch)
                          }
                          onBlur={handleBlur}
                          inputOnchange={(e: any) =>
                            onChangeInputCostSharingDepartmentCodeCommon(
                              e.target,
                              setQuery
                            )
                          }
                        />
                      </Grid>
                    </Grid>

                    <Grid item xs={6}>
                      <Grid container alignItems="center">
                        <Grid item xs={4}>
                          {t(
                            'deliveryReportNoteApprove.certificateComplete.productionOrderNo1'
                          )}
                          :
                        </Grid>
                        <AppTextField
                          xs={5}
                          name="production_order_no1"
                          label={t(
                            'deliveryReportNoteApprove.certificateComplete.productionOrderNo1'
                          )}
                          id={t(
                            'deliveryReportNoteApprove.certificateComplete.productionOrderNo1'
                          )}
                          value={values?.production_order_no1}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Grid>
                      <Grid container alignItems="center" mt={3}>
                        <Grid item xs={4}>
                          {t(
                            'deliveryReportNoteApprove.certificateComplete.productionOrderNo2'
                          )}
                          :
                        </Grid>
                        <AppTextField
                          xs={5}
                          name="production_order_no2"
                          label={t(
                            'deliveryReportNoteApprove.certificateComplete.productionOrderNo2'
                          )}
                          id={t(
                            'deliveryReportNoteApprove.certificateComplete.productionOrderNo2'
                          )}
                          value={values?.production_order_no2}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Grid>
                    </Grid>

                    <Grid item xs={12}>
                      <Grid container alignItems="center" mt={3}>
                        <Grid item xs={2}>
                          {t(
                            'deliveryReportNoteApprove.certificateComplete.expenseType'
                          )}
                          :
                        </Grid>
                        <AppAutoComplete
                          xs={2.5}
                          name="expense_type"
                          value={values?.expense_type}
                          options={commonExpenseTypes}
                          loading={loadingCommonExpenseType}
                          getOptionLabel={(option: commonOptionType) =>
                            option.name
                          }
                          label={t(
                            'deliveryReportNoteApprove.certificateComplete.expenseType'
                          )}
                          onChange={(event: any, value: InputChangeEvent) => {
                            if (value !== null) {
                              setFieldValue('expense_type', value)
                            } else {
                              setFieldValue('expense_type', null)
                            }
                          }}
                          onFocus={() => handleFocusExpenseTypeCommon(dispatch)}
                          onBlur={handleBlur}
                          inputOnchange={(e: any) =>
                            onChangeInputExpenseTypeCommon(e.target, setQuery)
                          }
                        />
                      </Grid>
                    </Grid>

                    <Grid item xs={12}>
                      <Grid container alignItems="center" mt={3}>
                        <Grid item xs={2}>
                          {t(
                            'deliveryReportNoteApprove.certificateComplete.message'
                          )}
                          :
                        </Grid>
                        <AppTextField
                          xs={6}
                          name="message"
                          label={t(
                            'deliveryReportNoteApprove.certificateComplete.message'
                          )}
                          multiline
                          rows={4}
                          value={values?.message}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </AppTitleCard>
                <FocusElementOnInvalidValidation />
              </Form>
            </>
          )
        }}
      </Formik>
    </>
  )
}

export default CertificateComplete
