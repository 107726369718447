import React, { Suspense, lazy } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Autocomplete,
  Button,
  Card,
  CardHeader,
  Grid,
  TextField,
  Typography,
} from '@mui/material'

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

//below import with lazy loading, which is typically used when you want to load modules or components asynchronously, especially when they are not immediately needed.
const TaskListTable = lazy(() => import('./TaskListTableList'))

interface FilmOptionType {
  title: string
  year: number
}

//not needed no need to configure formik leave it for now

const TaskList = () => {
  const { t } = useTranslation()

  const handleClick = () => {
    console.log('Button clicked!')
  }

  const top100Films = [
    { title: 'The Shawshank Redemption', year: 1994 },
    { title: 'The Godfather', year: 1972 },
    { title: 'The Godfather: Part II', year: 1974 },
    { title: 'The Dark Knight', year: 2008 },
    { title: '12 Angry Men', year: 1957 },
    { title: "Schindler's List", year: 1993 },
    { title: 'Pulp Fiction', year: 1994 },
  ]

  const supplierOptions = [
    { title: '発注先１', year: 1 },
    { title: '発注先２', year: 2 },
    { title: '発注先３', year: 3 },
    { title: '発注先４', year: 4 },
  ]

  // const defaultProps = {
  //   options: top100Films,
  //   getOptionLabel: (option: FilmOptionType) => option.title,
  // }

  const [value, setValue] = React.useState<FilmOptionType | null>(null)

  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Card sx={{ mb: 2 }}>
          <CardHeader
            title={
              <Typography
                variant="h6"
                sx={{ fontWeight: 'bold', color: 'white' }}
              >
                {t('taskList.searchCondition')}
              </Typography>
            }
            sx={{
              backgroundColor: '#062f96',
              height: '48px',
            }}
          />

          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={2.5} sx={{ ml: 2, mb: 2 }}>
              <TextField
                id="standard-basic"
                label={t('taskList.job')}
                variant="standard"
              />
            </Grid>

            <Grid item xs={2} sx={{ ml: 2, mb: 2 }}>
              <DatePicker
                format="YYYY年MM月DD日"
                label={t('taskList.orderDate')}
                slotProps={{
                  textField: {
                    // helperText: 'MM/DD/YYYY',
                    variant: 'standard',
                  },
                }}
              />
            </Grid>

            <Grid item xs={2.5} sx={{ ml: 2, mb: 2 }}>
              <Autocomplete
                options={supplierOptions}
                getOptionLabel={(option) => option.title}
                selectOnFocus
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    label={t('taskList.subContractor')}
                    variant="standard"
                  />
                )}
              />
            </Grid>

            <Grid item xs={2.5} sx={{ ml: 2, mb: 2 }}>
              <TextField
                id="standard-basic"
                label={t('taskList.orderAmount')}
                variant="standard"
                type="number"
              />
            </Grid>

            <Grid item xs={2.5} sx={{ ml: 2, mb: 2 }}>
              <TextField
                id="standard-basic"
                label={t('taskList.wordCount')}
                variant="standard"
                type="number"
              />
            </Grid>

            <Grid item xs={2} sx={{ ml: 2, mb: 2 }}>
              <TextField
                id="standard-basic"
                label={t('taskList.rate')}
                variant="standard"
                type="number"
              />
            </Grid>

            <Grid item xs={2.5} sx={{ ml: 2, mb: 2 }}>
              <DatePicker
                format="YYYY年MM月DD日"
                label={t('taskList.deliveryDate')}
                slotProps={{
                  textField: {
                    // helperText: 'MM/DD/YYYY',
                    variant: 'standard',
                  },
                }}
              />
            </Grid>

            <Grid item xs={2.5} sx={{ ml: 2, mb: 2 }}>
              <DatePicker
                format="YYYY年MM月DD日"
                label={t('taskList.stockingDate')}
                slotProps={{
                  textField: {
                    // helperText: 'MM/DD/YYYY',
                    variant: 'standard',
                  },
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mt: 0.5 }}>
            <Grid item xs={12} sx={{ ml: 1, mb: 1, mr: 2, textAlign: 'right' }}>
              <Button size="large" variant="contained" sx={{ ml: 3 }}>
                {t('taskList.search')}
              </Button>
              <Button size="large" variant="contained" sx={{ ml: 3 }}>
                {t('taskList.clearCondition')}
              </Button>
            </Grid>
          </Grid>
        </Card>
      </LocalizationProvider>
      <Suspense fallback={<></>}>
        <TaskListTable />
      </Suspense>
    </div>
  )
}

export default TaskList
