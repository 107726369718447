import { Action } from '@reduxjs/toolkit'
import { t } from 'i18next'
import { combineEpics } from 'redux-observable'
import { Observable } from 'rxjs'
import { filter, map, switchMap } from 'rxjs/operators'

import { getNext, getPrevious } from '../../../CommonAppRedux/api'
import { alertErrorAction } from '../../../CommonAppRedux/commonAppSlice'
import messages from '../../../Utils/ValidationMessage'
import { dispatchAction, stateAction } from '../../../Utils/globalTypes'
import { getBilledList } from './api'
import {
  loadingBilledList,
  getBilledListSuccess,
  getBilledListFail,
  getBilledListRequest,
  getBilledListNextRequest,
  getBilledListPreviousRequest,
} from './billedSlice'
import { extractErrorMessage } from '../../../Utils/AppFunction'

const getBilledListEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(getBilledListRequest.match),
    switchMap(async (action) => {
      dispatch(loadingBilledList())
      try {
        const response = await getBilledList(action.payload)
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? getBilledListSuccess(action?.payload)
        : getBilledListFail()
    )
  )
}

//get next
const getBilledListNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getBilledListNextRequest.match),
    switchMap(async (action) => {
      try {
        const response = await getNext(action.payload)
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? getBilledListSuccess(action?.payload)
        : getBilledListFail()
    )
  )
//get previous
const getBilledListPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getBilledListPreviousRequest.match),
    switchMap(async (action) => {
      try {
        const response = await getPrevious(action.payload)
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? getBilledListSuccess(action?.payload)
        : getBilledListFail()
    )
  )

export const billedListEpics = combineEpics(
  getBilledListEpic,
  getBilledListNext,
  getBilledListPrevious
)
