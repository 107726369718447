import React, { Suspense, lazy, useLayoutEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import {
  Card,
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  Switch,
  Box,
  Alert,
} from '@mui/material'
import indigo from '@mui/material/colors/indigo'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import { styled } from '@mui/system'
import { alpha } from '@mui/system'
import { Formik } from 'formik'
import * as Yup from 'yup'

import { alertInfoAction } from '../../../CommonAppRedux/commonAppSlice'
import { alertErrorAction } from '../../../CommonAppRedux/commonAppSlice'
import { authSelector } from '../../../Login/Redux/selector'
import {
  useAppDispatch,
  useAppSelector,
} from '../../../ReusableComponent/CustomHooks/appHooks'
import { initialDuplicateValues } from '../../../ReusableComponent/CustomHooks/useDuplicateData'
import SaveFooterComponent from '../../../ReusableComponent/SaveFooterButton/SaveFooterButton'
import { dateFormatter } from '../../../Utils/AppFormatFunction'
import { fetchDataUrl, validateEmail } from '../../../Utils/AppFunction'
import messages, { maxCharValidation } from '../../../Utils/ValidationMessage'
import { globalDuplicateValue } from '../../../Utils/globalTypes'
import { getInputTrackRecordRequest } from '../../JobList/InputTrackRecordScreen/Redux/inputTrackRecordSlice'
import { translationLanguage } from '../../JobList/Types'
import {
  autoCalculateRequest,
  clearAutoCalculate,
  clearDataProjectDetails,
  clearHardResetProjectList,
  clearQuotationDetailDraft,
  createProjectDetailsRequest,
  deleteProjectRequest,
  getCertificateUploadStatusRequest,
  getCompleteCertificateStatusRequest,
  getDeliveryItemUploadStatusRequest,
  getDeliveryReportStatusRequest,
  getInputTrackRecordStatusRequest,
  getOrderReceiptStatusRequest,
  getProjectDetailByIdRequest,
  getPurchaseOrderUploadStatusRequest,
  getQuotationDetailDraftByIdRequest,
  getQuotationStatusRequest,
  updateProjectDetailsRequest,
  updatePurchaseOrderPlacementRequest,
} from '../Redux/projectListSlice'
import { projectListSelector } from '../Redux/selector'
import { CreateProjectProps } from '../Types'
import AutoCalculateTable from './AutoCalculateTable'
import { autoCalculateColumns } from './table'

//help to speed up form system
const MultipleProjectDetailForm = lazy(
  () => import('./MultipleProjectDetailForm')
)
const ProjectForm = lazy(() => import('./ProjectForm'))

const DeliveryItemUpload = lazy(
  () => import('../UploadReceptLayout/DeliveryItemUpload')
)

//below import with lazy loading, which is typically used when you want to load modules or components asynchronously, especially when they are not immediately needed.
export const GreenSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked.MuiSwitch-switchBase': {
    color: indigo[600],
    '&:hover': {
      backgroundColor: alpha(indigo[600], theme.palette.action.hoverOpacity),
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked.MuiSwitch-switchBase + .MuiSwitch-track':
    {
      backgroundColor: indigo[600],
    },
  '& .MuiSwitch-switchBase.MuiSwitch-switchBase': {
    color: indigo[600],
    '&:hover': {
      backgroundColor: alpha(indigo[600], theme.palette.action.hoverOpacity),
    },
  },
  '& .MuiSwitch-switchBase.MuiSwitch-switchBase + .MuiSwitch-track': {
    backgroundColor: indigo[600],
  },
}))
async function editCaseDetailsRunByRun(
  caseDetails: any,
  setUpdatedCaseDetails: any
) {
  try {
    const updatedDetails = []

    for (const caseDetail of caseDetails) {
      const requestFiles = await Promise.all(
        (caseDetail?.request_files || []).map(async (rowChild: any) => {
          return {
            ...rowChild,
            file: (await fetchDataUrl(rowChild?.file)) ?? '',
            url: rowChild?.url ?? '',
          }
        })
      )
      const referenceFiles = await Promise.all(
        (caseDetail?.reference_files || []).map(async (rowChild: any) => {
          return {
            ...rowChild,
            file: (await fetchDataUrl(rowChild?.file)) ?? '',
            url: rowChild?.url ?? '',
          }
        })
      )
      const updatedCaseDetail = {
        ...caseDetail,
        showReferenceFile: (caseDetail?.reference_files || []).length > 0,
        showRequestFile: requestFiles.length > 0,
        reference_files: referenceFiles,
        request_files: requestFiles,
      }
      updatedDetails.push(updatedCaseDetail)
    }
    setUpdatedCaseDetails(updatedDetails)
    return updatedDetails
  } catch (error) {
    // console.error('Error editing case details:', error);
  }
}
const CreateAgencyProjectList = () => {
  const { t } = useTranslation()
  // const { id, edit } = useParams()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const id = searchParams.get('id')
  const edit = searchParams.get('edit')
  const { state } = location
  const previousUrl = state?.path
  const {
    projectDetail,
    loadingCreate,
    loadingUpdate,
    autoCalculating,
    loadingDeleteProject,
    autoCalculateResult,
    combinedAutoCalculateResult,
    loadingUploadPurchaseOrderPlacement,
    issueQuotationDetailDraft,
  } = useAppSelector(projectListSelector)
  const { is_staff, user } = useAppSelector(authSelector)
  const dispatch = useAppDispatch()

  useLayoutEffect(() => {
    if (id) {
      dispatch(getProjectDetailByIdRequest(id))
      if (edit) {
        dispatch(getQuotationDetailDraftByIdRequest(id))
      }
    } else {
      dispatch(clearDataProjectDetails())
      dispatch(clearAutoCalculate())
      dispatch(clearHardResetProjectList())
      dispatch(clearQuotationDetailDraft())
    }
    return () => {
      dispatch(clearHardResetProjectList())
    }
  }, [id, location, dispatch])

  //all reducer action and clear function implement End

  const navigate = useNavigate()

  const handleDeliveryItemUpload = () => {
    dispatch(
      getDeliveryItemUploadStatusRequest({
        id: projectDetail?.id,
        navigate,
        location,
      })
    )
  }
  const handlePurchaseOrderUpload = async () => {
    dispatch(
      getPurchaseOrderUploadStatusRequest({
        id: projectDetail?.id,
        navigate,
        location,
      })
    )
  }

  const isCaseStatus000 = projectDetail?.status.code === '000'

  const handleCertificateUpload = () => {
    dispatch(
      getCertificateUploadStatusRequest({
        id: projectDetail?.id,
        navigate,
        location,
      })
    )
  }
  const handleCompleteCertificate = () => {
    dispatch(
      getCompleteCertificateStatusRequest({
        id: projectDetail?.id,
        navigate,
        location,
      })
    )
  }

  const handleCreateQuotation = async (caseId: string | undefined) => {
    if (caseId !== undefined) {
      const queryParams = `?case-id=${caseId}`
      await navigate(`/issue-quotation${queryParams}`, {
        state: { path: location?.pathname + location?.search },
      })
    }
  }

  const handleDeliveryReport = () => {
    dispatch(
      getDeliveryReportStatusRequest({
        id: projectDetail?.id,
        navigate,
        location,
      })
    )
  }

  const handleOrderReceipt = () => {
    dispatch(
      getOrderReceiptStatusRequest({
        id: projectDetail?.id,
        navigate,
        location,
      })
    )
  }
  const handleOrderPlacement = () => {
    if (projectDetail?.case_report_relation?.quotation) {
      dispatch(
        updatePurchaseOrderPlacementRequest({
          case: projectDetail?.id,
          createAgencyProjectList: true,
          case_version: projectDetail?.version,
        })
      )
    } else {
      dispatch(alertErrorAction(t('customAlertMessage.addQuotationFirst')))
    }

    // setShowOrderPlacement(true)
  }
  //login user details start

  //login user details end

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      if (panel === 'panel1') setExpanded(newExpanded ? panel : false)
      else setExpanded2(newExpanded ? panel : false)
    }

  const [expanded, setExpanded] = React.useState<string | false>('panel1')
  const [expanded2, setExpanded2] = React.useState<string | false>('panel2')

  // This state variable stores the updated case details.
  const [updatedCaseDetails, setUpdatedCaseDetails] = useState([])
  useLayoutEffect(() => {
    if (projectDetail?.case_details && id) {
      editCaseDetailsRunByRun(
        projectDetail?.case_details,
        setUpdatedCaseDetails
      )
    } else {
      setUpdatedCaseDetails([])
    }
  }, [projectDetail, id])

  const initialState: CreateProjectProps = {
    readOnlyJobNo: false,
    autoCalculateButton: false,
    version: projectDetail?.version ?? 0,
    general_affairs_flag: projectDetail?.general_affairs_flag ?? false,
    use_cc_flag: projectDetail?.use_cc_flag ?? false,
    use_approval_workflow_flag:
      projectDetail?.use_approval_workflow_flag ?? false,
    export_restriction: projectDetail?.export_restriction ?? null,
    tempButtonRequired: false,
    registrationButtonRequired: false,
    quoteRequest: false,
    requested_date:
      edit && projectDetail
        ? dateFormatter(projectDetail?.requested_date)
        : dateFormatter(new Date()),
    job: edit ? projectDetail?.job : null,
    case_title: projectDetail?.case_title ?? '',
    own_case_title:
      edit && projectDetail?.own_case_title ? projectDetail.own_case_title : '',
    case_no: edit ? projectDetail?.case_no ?? '' : '',
    company: projectDetail
      ? projectDetail?.request_company
      : projectDetail?.request_company_name
      ? projectDetail?.request_company_name
      : null,
    // request_number: projectDetail?.request_number ?? '',

    //contact detail 1
    request_department_affiliated1:
      projectDetail?.request_department_affiliated1
        ? projectDetail?.request_department_affiliated1
        : null,
    request_department_name1: projectDetail?.request_department_name1 ?? '',
    request_department_contact_person_name1:
      projectDetail?.request_department_contact_person_name1
        ? projectDetail?.request_department_contact_person_name1
        : !edit && user?.full_name
        ? user?.full_name
        : '',
    request_department_inner_line1:
      projectDetail?.request_department_inner_line1 ?? '',
    request_department_mail1: projectDetail?.request_department_mail1
      ? projectDetail?.request_department_mail1
      : !edit && user?.email
      ? user?.email
      : '',
    //contact details 2
    request_department_name2: projectDetail?.request_department_name2 ?? '',
    request_department_contact_person_name2:
      projectDetail?.request_department_contact_person_name2 ?? '',
    request_department_inner_line2:
      projectDetail?.request_department_inner_line2 ?? '',
    request_department_mail2: projectDetail?.request_department_mail2 ?? '',
    // general affair flag items
    invoice_no: projectDetail?.invoice_no ?? '',
    sales_no: projectDetail?.sales_no ?? '',

    //contact details 3
    request_department_name3: projectDetail?.request_department_name3 ?? '',
    request_department_contact_person_name3:
      projectDetail?.request_department_contact_person_name3 ?? '',
    request_department_inner_line3:
      projectDetail?.request_department_inner_line3 ?? '',
    request_department_mail3: projectDetail?.request_department_mail3 ?? '',
    //Approve department
    approver_department_name: projectDetail?.approver_department_name ?? '',
    approver_contact_person_name:
      projectDetail?.approver_contact_person_name ?? '',
    approver_inner_line: projectDetail?.approver_inner_line ?? '',
    approver_mail: projectDetail?.approver_mail ?? '',
    //client item
    cost_sharing_department_code1:
      projectDetail?.cost_sharing_department_code1 ?? null,
    production_order_no1: projectDetail?.production_order_no1 ?? '',
    cost_sharing_department_code2:
      projectDetail?.cost_sharing_department_code2 ?? null,
    production_order_no2: projectDetail?.production_order_no2 ?? '',
    own_contact_person_user: projectDetail?.own_contact_person_user ?? null,
    industry_type: projectDetail?.industry_type ?? null,
    expense_type: projectDetail?.expense_type ?? null,
    sales_department: projectDetail?.sales_department ?? null,
    sales_type: projectDetail?.sales_type ?? null,
    desired_delivery_date: projectDetail
      ? dateFormatter(projectDetail?.desired_delivery_date)
      : '',
    delivery_date: projectDetail
      ? dateFormatter(projectDetail?.delivery_date)
      : '',
    general_affairs_certificate_date: projectDetail
      ? dateFormatter(projectDetail?.general_affairs_certificate_date)
      : '',
    order_receive_date: projectDetail?.order_receive_date
      ? dateFormatter(projectDetail?.order_receive_date)
      : '',
    message: projectDetail?.message ?? '',
    case_details:
      updatedCaseDetails?.length > 0
        ? updatedCaseDetails
        : [
            {
              seq: 0,
              product_name: '',
              document: null,
              task: null,
              translation_languages: [],
              translation_subject: null,
              layout: null,
              sheet_count: 0,
              showReferenceFile: true,
              showRequestFile: true,
              // message: '',
              reference_files: [
                {
                  isFileSelected: false,
                  file: '',
                  url: '',
                },
              ],
              request_files: [
                {
                  isFileSelected: false,
                  file: '',
                  url: '',
                },
              ],
              auto_calculate_details: [
                //   {
                // }
              ],
            },
          ],
    detail_code: projectDetail?.detail_code ?? '',
    draft_flag: projectDetail?.draft_flag ?? false,
    without_quotation_flag: projectDetail?.without_quotation_flag ?? false,
    inspection_flag: projectDetail?.inspection_flag ?? false,
    // version: projectDetail?.version ?? 0,
    // showCC: false,
  }

  const allowedTypes = ['.doc', '.docx']
  const sheetCountCheck = function (this: any) {
    const { sheet_count, layout } = this.parent
    return !(
      layout?.id === 1 &&
      (sheet_count === 0 || sheet_count === undefined)
    )
  }

  const validationSchema = Yup.object().shape({
    general_affairs_flag: Yup.bool(),
    use_approval_workflow_flag: Yup.bool(),
    tempButtonRequired: Yup.bool(),
    registrationButtonRequired: Yup.bool(),
    autoCalculateButton: Yup.bool(),
    quoteRequest: Yup.bool(),
    case_title: Yup.string()
      .max(80, maxCharValidation(80))
      .when(
        ['tempButtonRequired', 'registrationButtonRequired'],
        ([tempButtonRequired, registrationButtonRequired], schema) => {
          return tempButtonRequired || registrationButtonRequired
            ? schema.required(t(messages.required))
            : schema.nullable()
        }
      ),
    own_case_title: Yup.string().max(100, maxCharValidation(100)),
    company: Yup.object().when(
      ['tempButtonRequired', 'registrationButtonRequired'],
      ([tempButtonRequired, registrationButtonRequired], schema) => {
        return tempButtonRequired || registrationButtonRequired
          ? schema.required(t(messages.required))
          : schema.nullable()
      }
    ),
    requested_date: Yup.mixed().when(
      ['registrationButtonRequired'],
      ([registrationButtonRequired], schema) => {
        return registrationButtonRequired
          ? schema.required(t(messages.required))
          : schema.nullable()
      }
    ),
    request_department_affiliated1: Yup.object().when(
      ['tempButtonRequired'],
      (tempButtonRequired, schema) => {
        return tempButtonRequired[0]
          ? schema.required(t(messages.required))
          : schema.nullable()
      }
    ),
    // Contact detail 1 fields
    // request_department_name1: Yup.string().max(20, maxCharValidation(20)),
    request_department_name1: Yup.string()
      .max(20, maxCharValidation(20))
      .when(['tempButtonRequired'], (tempButtonRequired, schema) => {
        return tempButtonRequired[0]
          ? schema.required(t(messages.required))
          : schema.nullable()
      }),
    // request_department_contact_person_name1: Yup.string().max(
    //   20,
    //   maxCharValidation(20)
    // ),
    request_department_contact_person_name1: Yup.string()
      .max(20, maxCharValidation(20))
      .when(['tempButtonRequired'], (tempButtonRequired, schema) => {
        return tempButtonRequired[0]
          ? schema.required(t(messages.required))
          : schema.nullable()
      }),
    // request_department_inner_line1: Yup.string().max(15, maxCharValidation(15)),
    request_department_inner_line1: Yup.string()
      .max(20, maxCharValidation(15))
      .when(['tempButtonRequired'], (tempButtonRequired, schema) => {
        return tempButtonRequired[0]
          ? schema.required(t(messages.required))
          : schema.nullable()
      }),

    request_department_mail1: Yup.string()
      .max(100, maxCharValidation(100))
      .test('valid email', t(messages.email), validateEmail)
      .when(['tempButtonRequired'], (tempButtonRequired, schema) => {
        return tempButtonRequired[0]
          ? schema.required(t(messages.required))
          : schema.nullable()
      }),

    // Contact detail 2 fields
    request_department_name2: Yup.string().max(20, maxCharValidation(20)),
    request_department_contact_person_name2: Yup.string().max(
      20,
      maxCharValidation(20)
    ),
    request_department_inner_line2: Yup.string().max(15, maxCharValidation(15)),
    request_department_mail2: Yup.string()
      .max(100, maxCharValidation(100))
      .test('valid email', t(messages.email), validateEmail),

    // Contact detail 3 field
    request_department_name3: Yup.string().max(20, maxCharValidation(20)),
    request_department_contact_person_name3: Yup.string().max(
      20,
      maxCharValidation(20)
    ),
    request_department_inner_line3: Yup.string().max(15, maxCharValidation(15)),
    request_department_mail3: Yup.string()
      .max(100, maxCharValidation(100))
      .test('valid email', t(messages.email), validateEmail),

    // general affair flag true fields

    invoice_no: Yup.string()
      .max(20, maxCharValidation(20))
      .when(
        ['general_affairs_flag', 'registrationButtonRequired'],
        ([general_affairs_flag, registrationButtonRequired], schema) => {
          return general_affairs_flag || registrationButtonRequired
            ? schema.required(t(messages.required))
            : schema.nullable()
        }
      ),
    sales_no: Yup.string()
      .max(7, maxCharValidation(7))
      .when(
        ['general_affairs_flag', 'registrationButtonRequired'],
        ([general_affairs_flag, registrationButtonRequired], schema) => {
          return general_affairs_flag || registrationButtonRequired
            ? schema.required(t(messages.required))
            : schema.nullable()
        }
      ),
    sales_department: Yup.object().when(
      ['general_affairs_flag', 'registrationButtonRequired'],
      ([general_affairs_flag, registrationButtonRequired], schema) => {
        return general_affairs_flag || registrationButtonRequired
          ? schema.required(t(messages.required))
          : schema.nullable()
      }
    ),
    order_receive_date: Yup.mixed().when(
      ['registrationButtonRequired'],
      ([registrationButtonRequired], schema) => {
        return registrationButtonRequired
          ? schema.required(t(messages.required))
          : schema.nullable()
      }
    ),
    delivery_date: Yup.mixed().when(
      ['general_affairs_flag'],
      ([general_affairs_flag], schema) => {
        return general_affairs_flag
          ? schema.required(t(messages.required))
          : schema.nullable()
      }
    ),
    general_affairs_certificate_date: Yup.mixed().when(
      ['general_affairs_flag'],
      ([general_affairs_flag], schema) => {
        return general_affairs_flag
          ? schema.required(t(messages.required))
          : schema.nullable()
      }
    ),
    sales_type: Yup.object().when(
      ['registrationButtonRequired'],
      ([registrationButtonRequired], schema) => {
        return registrationButtonRequired
          ? schema.required(t(messages.required))
          : schema.nullable()
      }
    ),

    // Approve Department fields
    approver_department_name: Yup.string()
      .max(20, maxCharValidation(20))
      .when(
        ['use_approval_workflow_flag'],
        (use_approval_workflow_flag, schema) => {
          return use_approval_workflow_flag[0]
            ? schema.required(t(messages.required))
            : schema.nullable()
        }
      ),
    approver_contact_person_name: Yup.string()
      .max(20, maxCharValidation(20))
      .when(
        ['use_approval_workflow_flag'],
        (use_approval_workflow_flag, schema) => {
          return use_approval_workflow_flag[0]
            ? schema.required(t(messages.required))
            : schema.nullable()
        }
      ),
    approver_inner_line: Yup.string()
      .max(15, maxCharValidation(15))
      .when(
        ['use_approval_workflow_flag'],
        (use_approval_workflow_flag, schema) => {
          return use_approval_workflow_flag[0]
            ? schema.required(t(messages.required))
            : schema.nullable()
        }
      ),
    approver_mail: Yup.string()
      .test('valid email', t(messages.email), validateEmail)
      .max(100, maxCharValidation(100))
      .when(
        ['use_approval_workflow_flag'],
        (use_approval_workflow_flag, schema) => {
          return use_approval_workflow_flag[0]
            ? schema.required(t(messages.required))
            : schema.nullable()
        }
      ),

    cost_sharing_department_code1: Yup.object()
      // .shape({
      //   production_order_no_required_flag: Yup.boolean().optional(),
      // })
      // .nullable(),
      // .test(
      //   'cost_sharing_department_code1',
      //   t(messages.required),
      //   costSharingDepartmentCode1Check
      // )
      .when(['quoteRequest'], (quoteRequest, schema) => {
        return quoteRequest[0]
          ? schema.required(t(messages.required))
          : schema.nullable()
      }),
    production_order_no1: Yup.string()
      .max(20, maxCharValidation(20))
      .when(
        ['cost_sharing_department_code1.production_order_no_required_flag'],
        (flag, schema) => {
          return flag[0]
            ? schema.required(t('validationMessage.costSharingRequired1'))
            : schema.nullable()
        }
      ),
    cost_sharing_department_code2: Yup.object()
      .shape({
        production_order_no_required_flag: Yup.boolean().optional(),
      })
      .nullable(),
    production_order_no2: Yup.string()
      .max(20, maxCharValidation(20))
      .when(
        ['cost_sharing_department_code2.production_order_no_required_flag'],
        (flag, schema) => {
          return flag[0]
            ? schema.required(t('validationMessage.costSharingRequired2'))
            : schema.nullable()
        }
      ),
    own_contact_person_user: Yup.object().nullable(),
    // own_contact_person_user:Yup.object().nullable(),

    industry_type: Yup.object().nullable(),
    // expense_type: Yup.object().nullable()
    // .test('expense_type', t(messages.required), expenseTypeCheck)
    expense_type: Yup.object().when(
      ['quoteRequest'],
      (quoteRequest, schema) => {
        return quoteRequest[0]
          ? schema.required(t(messages.required))
          : schema.nullable()
      }
    ),
    export_restriction: Yup.object().when(
      ['quoteRequest'],
      (quoteRequest, schema) => {
        return quoteRequest[0]
          ? schema.required(t(messages.required))
          : schema.nullable()
      }
    ),
    message: Yup.string().max(500, maxCharValidation(500)),
    desired_delivery_date: Yup.mixed().when(
      ['quoteRequest'],
      (quoteRequest, schema) => {
        return quoteRequest[0]
          ? schema.required(t(messages.required))
          : schema.nullable()
      }
    ),
    case_details: Yup.array().of(
      Yup.object().shape({
        // general_affairs_flag: Yup.bool(),
        product_name: Yup.string().max(20, maxCharValidation(20)),
        document: Yup.object().when(
          ['autoCalculateButton'],
          (_, schema, context) => {
            let isRequired = context?.context?.autoCalculateButton
            return isRequired
              ? schema.required(t(messages.required))
              : schema.nullable()
          }
        ),
        task: Yup.object().when(
          ['autoCalculateButton'],
          (_, schema, context) => {
            return context?.context?.autoCalculateButton
              ? schema.required(t(messages.required))
              : schema.nullable()
          }
        ),
        layout: Yup.object().when(
          ['autoCalculateButton'],
          (_, schema, context) => {
            return context?.context?.autoCalculateButton
              ? schema.required(t(messages.required))
              : schema.nullable()
          }
        ),
        translation_languages: Yup.array()
          .when(['autoCalculateButton'], (_, schema, context) => {
            return context?.context?.autoCalculateButton
              ? schema
                  .test(
                    'required',
                    t(messages.required),
                    (array) => array && array.length > 0
                  )
                  .test(
                    'exactly-one',
                    t(messages.pleaseSelectOnlyOneLanguage),
                    (array) => array?.length === 1
                  )
              : schema.required(t(messages.required))
          })
          .when(['quoteRequest'], (_, schema, context) => {
            console.log(context?.context)
            return context?.context?.quoteRequest
              ? schema.test(
                  'required',
                  t(messages.required),
                  (array) => array && array.length > 0
                )
              : schema.required(t(messages.required))
          }),
        translation_subject: Yup.object().when(
          ['autoCalculateButton'],
          (_, schema, context) => {
            return context?.context?.autoCalculateButton
              ? schema.required(t(messages.required))
              : schema.nullable()
          }
        ),
        sheet_count: Yup.number()
          .max(999999999999, maxCharValidation(12))
          .typeError(t(messages.numericInput))
          .test('sheet_count', t(messages.minSheetCount), sheetCountCheck),
        request_files: Yup.array().of(
          Yup.object().shape({
            file: Yup.mixed().when(
              ['autoCalculateButton'],
              (_, schema, context) => {
                console.log(
                  context?.context?.autoCalculateButton,
                  'context?.context?.autoCalculateButton'
                )
                return context?.context?.autoCalculateButton
                  ? schema
                      .test(
                        'fileType',
                        t(messages.invalidFileTypeOnlyAllowedDoc),
                        (value: any) => {
                          console.log(value, 'valuevalue')
                          if (!value) {
                            return true
                          }
                          return allowedTypes.some((allowedType) =>
                            value?.type.includes(allowedType)
                          )
                        }
                      )
                      .required(t(messages.required))
                  : schema.nullable()
              }
            ),
            url: Yup.string()
              .url(t(messages.invalidUrl))
              // .matches(
              //   /^.+\.[a-z]{2,}(\/.*)?$/i,
              //   t(messages.atLeast2CharAfterDecimal)
              // )
              .max(200, maxCharValidation(200))
              .transform((value) => (value === '' ? null : value)) // Convert empty string to null
              .nullable(),
          })
        ),

        reference_files: Yup.array().of(
          Yup.object().shape({
            url: Yup.string()
              .url(t(messages.invalidUrl))
              // .matches(
              //   /^.+\.[a-z]{2,}(\/.*)?$/i,
              //   t(messages.atLeast2CharAfterDecimal)
              // )
              .max(200, maxCharValidation(200))
              .transform((value) => (value === '' ? null : value)) // Convert empty string to null
              .nullable(),
          })
        ),
      })
    ),
  })

  const [saveStatusId, setSaveStatusId] = useState<string>('')
  const onSubmit = async (values: CreateProjectProps, actions: any) => {
    //
    // validationSchema
    //   .validate(values, { abortEarly: false })
    //   .then(() => {
    const {
      case_details,
      job,
      company,
      expense_type,
      industry_type,
      own_contact_person_user,
      cost_sharing_department_code1,
      cost_sharing_department_code2,
      sales_type,
      sales_department,
      without_quotation_flag,
      export_restriction,
      request_department_affiliated1,
      desired_delivery_date,
      delivery_date,
      general_affairs_certificate_date,
      version,
    } = values

    const rowValues = case_details?.map((row: any, index: number) => ({
      ...row,
      document: row?.document?.id ?? '',
      task: row?.task?.id ?? '',
      translation_subject: row?.translation_subject?.id ?? '',
      layout: row?.layout?.id ?? '',
      reference_files: row?.reference_files.map((rowChild: any) => ({
        ...rowChild,
        // file:fetchImageAsBinary(rowChild.file)
      })),
      auto_calculate_details:
        autoCalculateResult[index]?.length > 0
          ? autoCalculateResult[index].map((row1: any) => {
              return {
                ...row1,
                // quantity: Math.round(row1?.quantity),
              }
            })
          : [],
    }))
    const updatedValues = {
      ...values,
      cost_sharing_department_code1: cost_sharing_department_code1?.id ?? '',
      cost_sharing_department_code2: cost_sharing_department_code2?.id ?? '',
      status: saveStatusId ? saveStatusId : projectDetail?.status?.code ?? '',
      own_contact_person_user: own_contact_person_user?.id ?? '' ?? '',
      expense_type: expense_type?.id ?? '',
      industry_type: industry_type?.id ?? '',
      company: company?.id ?? '',
      request_department_affiliated1: request_department_affiliated1?.id ?? '',
      case_details: rowValues,
      request_company: typeof company !== 'string' ? company?.id ?? '' : '',
      request_company_name: typeof company === 'string' ? company ?? '' : '',
      job: job?.id ?? '',
      sales_type: sales_type?.id ?? '',
      sales_department: sales_department?.id ?? '',
      export_restriction: export_restriction?.id ?? '',
      desired_delivery_date: desired_delivery_date ?? '',
      delivery_date: delivery_date ?? '',
      general_affairs_certificate_date: general_affairs_certificate_date ?? '',
    }

    if (values?.autoCalculateButton) {
      values.case_details?.forEach((detail: any, index: any) => {
        const files = detail.request_files.map((file: any) => {
          return {
            file: file?.file ?? file,
          }
        })
        const document = detail?.document?.id ?? ''
        const task = detail?.task?.id ?? ''
        const layout = detail?.layout?.id ?? ''
        const translation_languages = detail?.translation_languages?.map(
          (language: translationLanguage) => {
            return language?.id
          }
        )
        const translation_subject = detail?.translation_subject?.id ?? ''
        const sheet_count = detail?.sheet_count.toString() || ''

        const formData = new FormData()
        formData.append('document', document)
        formData.append('task', task)
        formData.append('layout', layout)
        formData.append('translation_languages', translation_languages)
        formData.append('translation_subject', translation_subject)
        formData.append('sheet_count', sheet_count)
        files.forEach((file: any) => formData.append('file', file?.file))
        dispatch(autoCalculateRequest({ data: formData, index }))
      })
    } else {
      if (id && edit) {
        dispatch(
          updateProjectDetailsRequest({
            id: id,
            values: updatedValues,
            nav: navigate,
            actions,
            previousUrl,
          })
        )
      } else {
        dispatch(
          createProjectDetailsRequest({
            values: updatedValues,
            nav: navigate,
            actions,
            previousUrl,
          })
        )
      }
    }
    // })
    // .catch((errors) => {
    //   // Handle validation errors
    //   // if (errors.inner) {
    //   //   const fieldErrors = {};
    //   //   errors.inner.forEach((error:any) => {
    //   //     console.log(error,"errorerrorerror")
    //   //     const fieldName = error.path; // Get the field name
    //   //     const errorMessage = error.message;
    //   //     //@ts-ignore
    //   //     fieldErrors[fieldName] = errorMessage;
    //   //     console.error(`Validation error for ${fieldName}: ${errorMessage}`);
    //   //   });
    //   //   // You can now use fieldErrors to display errors for specific fields.
    //   // } else {
    //   console.error('Validation errors:', errors)
    //   // }
    // })
  }

  const [duplicateValue, setDuplicateValue] = useState<globalDuplicateValue>(
    initialDuplicateValues
  )

  const [readOnlyMode, setReadOnlyMode] = useState(false)
  useLayoutEffect(() => {
    if (edit && !is_staff && projectDetail?.status.code >= '010') {
      setReadOnlyMode(true)
    } else {
      setReadOnlyMode(false)
    }
  }, [edit, is_staff, projectDetail])
  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialState}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      validateOnChange={false}
    >
      {(formik) => {
        console.log(formik?.values, 'valuesvaluesvaluesvalues')
        const handleSave = async () => {
          try {
            // if (edit && projectDetail?.status?.code === '000') {
            //   await formik.setFieldValue('tempButtonRequired', true)
            // } else {
            await formik.setFieldValue('tempButtonRequired', true)
            // }
            await formik.validateForm()
            await formik.submitForm()
          } catch (validationError) {
            console.log('the validation error is', validationError)
          }
        }
        const handleAutoCalculate = async () => {
          formik.setFieldValue('autoCalculateButton', true)
          formik.setFieldValue('tempButtonRequired', false)
          await formik.setFieldValue('quoteRequest', false)
          await formik.setFieldValue('registrationButtonRequired', false)
          try {
            await formik.validateForm()
            await formik.submitForm()
          } catch (validationError) {
            console.log('the validation error is', validationError)
          }
        }
        const handleRequestQuotation = async () => {
          try {
            await formik.setFieldValue('without_quotation_flag', false)
            await formik.setFieldValue('tempButtonRequired', true)
            await formik.setFieldValue('autoCalculateButton', false)
            await formik.setFieldValue('quoteRequest', true)
            await formik.setFieldValue('registrationButtonRequired', false)
            setSaveStatusId('010')
            await formik.validateForm()
            await formik.submitForm()
          } catch (validationError) {
            console.log('the validation error is', validationError)
          }
        }
        const handleTemporarySaving = async () => {
          try {
            await formik.setFieldValue('without_quotation_flag', false)
            await formik.setFieldValue('tempButtonRequired', true)
            await formik.setFieldValue('autoCalculateButton', false)
            await formik.setFieldValue('quoteRequest', false)
            await formik.setFieldValue('registrationButtonRequired', false)
            setSaveStatusId('000')
            await formik.validateForm()
            await formik.submitForm()
          } catch (validationError) {
            console.log('the validation error is', validationError)
          }
        }
        const handleRegistration = async () => {
          try {
            await formik.setFieldValue('without_quotation_flag', false)
            await formik.setFieldValue('tempButtonRequired', false)
            await formik.setFieldValue('autoCalculateButton', false)
            await formik.setFieldValue('quoteRequest', false)
            await formik.setFieldValue('registrationButtonRequired', true)
            const currentStatus = projectDetail?.status?.code
            if (!edit || currentStatus === '000') {
              setSaveStatusId('070')
            } else {
              setSaveStatusId(currentStatus)
            }
            await formik.validateForm()
            await formik.submitForm()
          } catch (validationError) {
            console.log('the validation error is', validationError)
          }
        }
        const handleWithoutQuotation = async () => {
          try {
            await formik.setFieldValue('without_quotation_flag', true)
            await formik.setFieldValue('tempButtonRequired', true)
            await formik.setFieldValue('autoCalculateButton', false)
            await formik.setFieldValue('quoteRequest', true)
            await formik.setFieldValue('registrationButtonRequired', false)
            setSaveStatusId('030')
            await formik.validateForm()
            await formik.submitForm()
          } catch (validationError) {
            console.log('the validation error is', validationError)
          }
        }
        const handleQuotation = () => {
          dispatch(
            getQuotationStatusRequest({
              id: projectDetail?.id,
              navigate,
              location,
            })
          )
        }
        const handleCancel = async () => {
          setSaveStatusId('040')
          await formik.submitForm()
        }
        const handleDelete = () => {
          dispatch(
            deleteProjectRequest({ id: projectDetail?.id, navigate, is_staff })
          )
        }

        const handleDuplicateProject = () => {
          const queryParams = `?id=${id}`
          navigate(`/create-agency-project${queryParams}`, {
            state: { path: location?.pathname + location?.search },
          })
        }

        //handle workflow for individual case
        const handleWorkFlow = () => {
          const queryParams = `?case-id=${projectDetail?.id}`
          navigate(`/case-workflow-history${queryParams}`, {
            state: { path: location?.pathname + location?.search },
          })
        }
        const handleInputTrackRecord = () => {
          dispatch(
            getInputTrackRecordStatusRequest({
              id: projectDetail?.id,
              navigate,
              location,
            })
          )
        }
        const generalAffairFlagStatusTrue = formik?.values.general_affairs_flag

        const buttonConfigs = [
          ...(edit &&
          !readOnlyMode &&
          !(
            projectDetail?.status?.code === '040' ||
            projectDetail?.status?.code === '000'
          ) &&
          !generalAffairFlagStatusTrue
            ? [
                {
                  label: `${'common.update'}`,
                  handler: handleSave,
                  isShowAlert: true,
                  loading: loadingUpdate,
                  onlyMessage: projectDetail?.status?.code === '090',
                  message: `${
                    projectDetail?.status?.code === '090'
                      ? t('customAlertMessage.completeCaseEdit')
                      : t('confirmMessage.save')
                  }`,
                },
              ]
            : []),
          ...(edit &&
          projectDetail?.status?.code === '010' &&
          !generalAffairFlagStatusTrue
            ? [
                {
                  label: `${'common.cancel'}`,
                  handler: handleCancel,
                  isShowAlert: true,
                  message: t('confirmMessage.cancel'),
                },
              ]
            : []),
          ...(edit &&
          projectDetail?.status?.code === '000' &&
          !generalAffairFlagStatusTrue
            ? [
                {
                  label: `${'common.delete'}`,
                  handler: handleDelete,
                  isShowAlert: true,
                  loading: loadingDeleteProject,
                  message: t('confirmMessage.delete'),
                },
              ]
            : []),
          ...(!generalAffairFlagStatusTrue &&
          (!edit || projectDetail?.status?.code === '000')
            ? [
                {
                  label: `common.temporarySaving`,
                  handler: handleTemporarySaving,
                  isShowAlert: true,
                  loading: loadingCreate,
                  message: t('confirmMessage.temporarySaving'),
                },
              ]
            : []),
          ...(!generalAffairFlagStatusTrue &&
          (!edit || projectDetail?.status?.code === '000')
            ? [
                {
                  label: `common.autoCalculate`,
                  handler: handleAutoCalculate,
                  isShowAlert: true,
                  loading: autoCalculating,
                  message: t('confirmMessage.autoCalculate'),
                },
              ]
            : []),

          ...((projectDetail?.status?.code === '000' || !edit) &&
          !generalAffairFlagStatusTrue
            ? [
                {
                  label: `common.withoutQuotation`,
                  handler: handleWithoutQuotation,
                  isShowAlert: true,
                  loading: loadingUpdate,
                  message: t('confirmMessage.orderWithoutQuotation'),
                },
              ]
            : []),
          ...((projectDetail?.status?.code === '000' || !edit) &&
          !generalAffairFlagStatusTrue
            ? [
                {
                  label: `common.requestQuotation`,
                  handler: handleRequestQuotation,
                  isShowAlert: true,
                  loading: loadingUpdate,
                  message: formik?.values?.case_details.find((row: any) =>
                    row?.request_files?.find(
                      (childRow: any) => childRow?.file !== ''
                    )
                  )
                    ? t('confirmMessage.requestQuotation')
                    : t('confirmMessage.requestQuotationRequestFiles'),
                },
              ]
            : []),
          ...((is_staff
            ? edit &&
              [
                '010',
                '020',
                '040',
                '030',
                '050',
                '060',
                '070',
                '080',
                '090',
              ].includes(projectDetail?.status?.code) &&
              !projectDetail?.without_quotation_flag
            : edit &&
              ['020', '030', '050', '060', '070', '080', '090'].includes(
                projectDetail?.status?.code
              ) &&
              !projectDetail?.without_quotation_flag) &&
          !generalAffairFlagStatusTrue
            ? [
                {
                  label: projectDetail?.case_report_relation?.quotation
                    ? 'projectDetails.updateQuotation'
                    : 'projectDetails.quotation',
                  handler: handleQuotation,
                },
              ]
            : []),
          // ...(edit &&
          // id &&
          // projectDetail?.status?.code === '010' &&
          // is_staff &&
          // !(
          //   projectDetail?.status?.code === '010' &&
          //   projectDetail?.without_quotation_flag
          // )
          //   ? [
          //       {
          //         label: 'projectDetails.createQuotation',
          //         handler: () => handleCreateQuotation(id),
          //       },
          //     ]
          //   : []),
          ...(edit &&
          !generalAffairFlagStatusTrue &&
          (is_staff
            ? ['030', '040', '050', '060', '070', '080', '090'].includes(
                projectDetail?.status?.code
              ) ||
              (projectDetail?.status?.code === '010' &&
                projectDetail?.without_quotation_flag)
            : [''].includes(projectDetail?.status?.code))
            ? [
                {
                  label: 'uploadRecept.purchaseOrderUpload.name',
                  handler: handlePurchaseOrderUpload,
                },
              ]
            : []),
          ...(edit && projectDetail?.status?.code === '030' && is_staff
            ? [
                {
                  label: 'projectDetails.orderPlacement',
                  handler: handleOrderPlacement,
                  loading: loadingUploadPurchaseOrderPlacement,
                  isShowAlert: true,
                  message:
                    t('projectDetails.orderPlacement') +
                    ':' +
                    t('projectDetails.orderPlacementModalTitle'),
                },
              ]
            : []),
          ...(edit &&
          !generalAffairFlagStatusTrue &&
          (is_staff
            ? ['030', '050', '060', '070', '080', '090'].includes(
                projectDetail?.status?.code
              ) ||
              (projectDetail?.status?.code === '010' &&
                projectDetail?.without_quotation_flag)
            : ['050', '060', '070', '080', '090'].includes(
                projectDetail?.status?.code
              ))
            ? [
                {
                  label: 'projectDetails.orderReceipt',
                  handler: handleOrderReceipt,
                },
              ]
            : []),
          ...(edit &&
          !generalAffairFlagStatusTrue &&
          (is_staff
            ? ['050', '060', '070', '080', '090'].includes(
                projectDetail?.status?.code
              )
            : ['060', '070', '080', '090'].includes(
                projectDetail?.status?.code
              ))
            ? [
                {
                  label: is_staff
                    ? 'uploadRecept.deliveryItemUpload.name'
                    : 'uploadRecept.deliveryItemUpload.clientName',
                  handler: handleDeliveryItemUpload,
                },
              ]
            : []),

          ...(edit &&
          !generalAffairFlagStatusTrue &&
          (is_staff
            ? ['050', '060', '070', '080', '090'].includes(
                projectDetail?.status?.code
              )
            : ['060', '070', '080', '090'].includes(
                projectDetail?.status?.code
              ))
            ? [
                {
                  label: 'projectDetails.deliveryReport',
                  handler: handleDeliveryReport,
                },
              ]
            : []),
          ...(edit &&
          !generalAffairFlagStatusTrue &&
          projectDetail?.status?.code === '060'
            ? [
                {
                  label: 'projectDetails.completeCertificate',
                  handler: handleCompleteCertificate,
                },
              ]
            : []),
          ...(edit &&
          !generalAffairFlagStatusTrue &&
          (is_staff
            ? ['070', '080', '090'].includes(projectDetail?.status?.code)
            : ['080', '090'].includes(projectDetail?.status?.code))
            ? [
                {
                  label: 'projectDetails.certificateUpload',
                  handler: handleCertificateUpload,
                },
              ]
            : []),
          ...(edit && !is_staff
            ? [
                {
                  label: 'projectDetails.copyCase',
                  handler: handleDuplicateProject,
                },
              ]
            : []),
          ...(edit && is_staff
            ? [
                {
                  label: `${'projectDetails.workFlowHistory'}`,
                  handler: handleWorkFlow,
                },
              ]
            : []),
          ...(edit && is_staff && !generalAffairFlagStatusTrue
            ? [
                {
                  label: `${'inputTrackRecord.name'}`,
                  handler: handleInputTrackRecord,
                },
              ]
            : []),
          ...(is_staff && generalAffairFlagStatusTrue
            ? [
                {
                  label: `common.registration`,
                  handler: handleRegistration,
                  isShowAlert: true,
                  loading: loadingCreate,
                  message: t('confirmMessage.registration'),
                },
              ]
            : []),
        ]

        const handleButtonAction = (action: string) => {
          const buttonConfig = buttonConfigs.find(
            (config) => config.label === action
          )
          if (buttonConfig) {
            const { handler } = buttonConfig
            handler()
          }
        }
        return (
          <>
            <Grid item sx={{ mb: 4 }}>
              <SaveFooterComponent
                handleClick={handleButtonAction}
                buttonConfigs={buttonConfigs}
              />
            </Grid>
            <div>
              <Grid>
                <Typography
                  variant="h6"
                  fontWeight="bold"
                  mt={6}
                  // sx={{ padding: '16px', mt: 10 }}
                >
                  {t('moduleName.project.create')}
                </Typography>
                {edit && projectDetail?.status && (
                  <Alert
                    variant="filled"
                    icon={false}
                    sx={{
                      backgroundColor: projectDetail.status.display_color
                        ? projectDetail.status.display_color
                        : '#c0c6c9',
                      color: projectDetail.status.display_color
                        ? 'white'
                        : 'black',
                    }}
                  >
                    {is_staff
                      ? projectDetail.status.own_display_name
                      : projectDetail.status.client_display_name ?? ''}
                  </Alert>
                )}
              </Grid>
              {projectDetail?.artifact && edit && (
                <Suspense fallback={<div>Loading...</div>}>
                  <Grid item sx={{ mt: 2 }}>
                    <DeliveryItemUpload caseIdFromCase={projectDetail?.id} />
                  </Grid>
                </Suspense>
              )}
              <Accordion
                expanded={expanded === 'panel1'}
                onChange={handleChange('panel1')}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon sx={{ color: 'white', fontSize: 28 }} />
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{
                    backgroundColor: '#062f96',
                    height: '38px',
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: 'bold',
                      color: 'white',
                      transition: 'all 1s ease-out',
                    }}
                  >
                    {t('projectDetails.customerInformation')}
                  </Typography>
                  <Box sx={{ marginLeft: 'auto' }}>
                    <Typography
                      sx={{
                        marginTop: 0.5,
                        marginRight: 1,
                        fontWeight: 'bold',
                        color: 'white',
                        transition: 'all 10s ease-out',
                      }}
                    >
                      {expanded ? t('common.Expanded') : t('common.Collapse')}
                    </Typography>
                  </Box>
                </AccordionSummary>
                <Suspense fallback={<div>Loading...</div>}>
                  <Card sx={{ mb: 0 }}>
                    <Grid
                      container
                      // display={displayCardContentFirst ? '' : 'none'}
                      sx={{
                        // mt: 1,
                        p: 2,
                        justifyContent: 'center',
                        alignItems: 'center',
                        transition: 'all 1s ease-out',
                      }}
                    >
                      <ProjectForm
                        readOnlyMode={readOnlyMode}
                        formik={formik}
                        setDuplicateValue={setDuplicateValue}
                        duplicateValue={duplicateValue}
                        isCaseStatus000={isCaseStatus000}
                      />
                    </Grid>
                  </Card>
                </Suspense>
              </Accordion>
              {(combinedAutoCalculateResult?.length > 0 ||
                issueQuotationDetailDraft?.length > 0) && (
                <Accordion
                  expanded={expanded2 === 'panel2'}
                  onChange={handleChange('panel2')}
                >
                  <AccordionSummary
                    expandIcon={
                      <ExpandMoreIcon sx={{ color: 'white', fontSize: 28 }} />
                    }
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                    sx={{
                      backgroundColor: '#062f96',
                      height: '38px',
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: 'bold',
                        color: 'white',
                        transition: 'all 1s ease-out',
                      }}
                    >
                      {t('projectDetails.autoCalculateTable')}
                    </Typography>
                    <Box sx={{ marginLeft: 'auto' }}>
                      <Typography
                        sx={{
                          marginTop: 0.5,
                          marginRight: 1,
                          fontWeight: 'bold',
                          color: 'white',
                          transition: 'all 10s ease-out',
                        }}
                      >
                        {expanded2
                          ? t('common.Expanded')
                          : t('common.Collapse')}
                      </Typography>
                    </Box>
                  </AccordionSummary>
                  <Suspense fallback={<div>Loading...</div>}>
                    <AutoCalculateTable
                      columns={autoCalculateColumns}
                      handleBlur={formik?.handleBlur}
                      keyName="auto_calculate"
                      dataValues={
                        combinedAutoCalculateResult?.length > 0
                          ? combinedAutoCalculateResult
                          : issueQuotationDetailDraft?.length > 0
                          ? issueQuotationDetailDraft
                          : []
                      }
                    />
                  </Suspense>
                </Accordion>
              )}
              {/* Panel 2 */}

              <Accordion
                expanded={expanded2 === 'panel2'}
                onChange={handleChange('panel2')}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon sx={{ color: 'white', fontSize: 28 }} />
                  }
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                  sx={{
                    backgroundColor: '#062f96',
                    height: '38px',
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: 'bold',
                      color: 'white',
                      transition: 'all 1s ease-out',
                    }}
                  >
                    {t('projectDetails.requestInformation')}
                  </Typography>
                  <Box sx={{ marginLeft: 'auto' }}>
                    <Typography
                      sx={{
                        marginTop: 0.5,
                        marginRight: 1,
                        fontWeight: 'bold',
                        color: 'white',
                        transition: 'all 10s ease-out',
                      }}
                    >
                      {expanded2 ? t('common.Expanded') : t('common.Collapse')}
                    </Typography>
                  </Box>
                </AccordionSummary>
                <Suspense fallback={<div>Loading...</div>}>
                  <MultipleProjectDetailForm
                    readOnlyMode={readOnlyMode}
                    formik={formik}
                    setDuplicateValue={setDuplicateValue}
                    duplicateValue={duplicateValue}
                    initialState={initialState}
                  />
                </Suspense>
              </Accordion>
            </div>
          </>
        )
      }}
    </Formik>
  )
}

export default CreateAgencyProjectList
