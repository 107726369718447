import React, {
  lazy,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useParams, useNavigate, useLocation } from 'react-router-dom'

import {
  Autocomplete,
  Card,
  CardHeader,
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
  TextField,
  Typography,
} from '@mui/material'

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import {
  handleFocusTaskCommon,
  handleFocusTradosCommon,
  handleFocusTranslatorCommon,
  handleFocusTranslatorLanguageCommon,
  onChangeInputTaskCommon,
  onChangeInputTradosCommon,
  onChangeInputTranslatorCommon,
  onChangeInputTranslatorLanguageCommon,
} from '../../../CommonAppRedux/commonAppFunctions'
import { commonAppSelector } from '../../../CommonAppRedux/selector'
import AppAutoComplete from '../../../ReusableComponent/AppAutoComplete/AppAutoComplete'
import AppButton from '../../../ReusableComponent/AppButton/AppButton'
import AppDatePicker from '../../../ReusableComponent/AppDatePicker/AppDatePicker'
import AppTextField from '../../../ReusableComponent/AppTextField/AppTextField'
import AppTitleCard from '../../../ReusableComponent/AppTitleCard/AppTitleCard'
import {
  useAppDispatch,
  useAppSelector,
} from '../../../ReusableComponent/CustomHooks/appHooks'
import { useInputSearch } from '../../../ReusableComponent/CustomHooks/useInputSearch'
import { handleFocus } from '../../../ReusableComponent/FocusElement/focusElement'
import FocusElementOnInvalidValidation from '../../../ReusableComponent/FocusElementIfInvalid/FocusElementOnInvalidValidation'
import SaveFooterComponent from '../../../ReusableComponent/SaveFooterButton/SaveFooterButton'
import { dateFormatter } from '../../../Utils/AppFormatFunction'
import { todayDate } from '../../../Utils/AppFunction'
import messages, { maxCharValidation } from '../../../Utils/ValidationMessage'
import { InputChangeEvent } from '../../../Utils/globalTypes'
import {
  clearHardResetJobList,
  clearJobTask,
  createJobTaskRequest,
  getJobTaskByIdRequest,
  updateJobTaskRequest,
} from '../Redux/jobListSlice'
import { jobListSelector } from '../Redux/selector'

//below import with lazy loading, which is typically used when you want to load modules or components asynchronously, especially when they are not immediately needed.
const TaskListTable = lazy(() => import('./TaskListTableList'))

interface jobTask {
  accounts_payable_month: string
  order_date: Date | string | null
  translator: any | null
  task: any | null
  translation_language: any | null
  order_amount: string
  order_amount_cost: string
  order_amount_10per: string
  word_count: number
  rate: string
  delivery_date: Date | string | null
  stocking_date: Date | string | null
  date_recorded: Date | string | null
  operation_manager: string
  trados: any | null
  readability: number
  remarks: string
  // cancel_flag: boolean
}

//page not being used anywhere no need to implement formik
const CreateTaskList = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  // const jobTaskId = useParams()
  // const { id, edit,jobDetailId } = useParams();
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const id = searchParams.get('id')
  const jobDetailId = searchParams.get('job-detail')
  const edit = searchParams.get('edit')
  const { state } = location
  const previousUrl = state?.path

  const nav = useNavigate()
  //all reducer action and clear function implement start

  useLayoutEffect(() => {
    if (id) {
      dispatch(getJobTaskByIdRequest(id))
    } else {
      dispatch(clearJobTask())
      dispatch(clearHardResetJobList())
    }

    return () => {
      dispatch(clearHardResetJobList())
    }
  }, [id, location, dispatch, edit])
  //all reducer action and clear function implement End

  const {
    commonFilteredTranslator,
    loadingCommonFilteredTranslator,
    commonTasks,
    loadingCommonTask,
    commonTrados,
    commonTranslator,
    loadingCommonTrados,
    commonTranslatorLanguages,
    loadingCommonTranslatorLanguage,
  } = useAppSelector(commonAppSelector)

  const { jobTask, loadingUpdateJobTask, loadingCreateJobTask } =
    useAppSelector(jobListSelector)

  const initialState: jobTask = {
    accounts_payable_month: jobTask ? jobTask?.accounts_payable_month : '',
    order_date: jobTask ? jobTask?.order_date : todayDate, //
    translator: jobTask ? jobTask?.translator : null,
    task: jobTask ? jobTask?.task : null,
    translation_language: jobTask ? jobTask?.translation_language : null,
    order_amount: jobTask?.order_amount ? jobTask?.order_amount : '',
    order_amount_cost: jobTask?.order_amount_cost
      ? jobTask?.order_amount_cost
      : '',
    order_amount_10per: jobTask?.order_amount_10per
      ? jobTask?.order_amount_10per
      : '',
    word_count: jobTask?.word_count ? jobTask?.word_count : 0,
    rate: jobTask?.rate ? jobTask?.rate : '',
    delivery_date: jobTask ? jobTask?.delivery_date : todayDate, //
    stocking_date: jobTask ? jobTask?.stocking_date : todayDate, //
    date_recorded: jobTask ? jobTask?.date_recorded : todayDate, //
    operation_manager: jobTask?.operation_manager
      ? jobTask?.operation_manager
      : '',
    trados: jobTask?.trados ? jobTask?.trados : null,
    readability: jobTask?.readability ? jobTask?.readability : 0,
    remarks: jobTask?.remarks ? jobTask?.remarks : '',
    // cancel_flag: jobTask?.cancel_flag ? true : false,
  }

  const validationSchema = Yup.object().shape({
    task: Yup.object().required(t(`${messages.required}`)),
    translator: Yup.object()
      .required(t(`${messages.required}`))
      .nullable(),

    accounts_payable_month: Yup.string().max(6, maxCharValidation(6)),
  })

  const handleSubmit = async (values: jobTask, actions: any) => {
    const { translator, task, translation_language, trados } = values
    // dispatch();
    const updatedValues = {
      ...values,
      translator: translator?.id,
      task: task?.id,
      translation_language: translation_language
        ? translation_language?.id
        : null,
      trados: trados ? trados?.id : null,
    }
    const updatedValuesCreate = {
      ...values,
      job_detail: Number(jobDetailId),
      translator: translator?.id,
      task: task?.id,
      translation_language: translation_language
        ? translation_language?.id
        : null,
      trados: trados ? trados?.id : null,
    }
    if (id && edit) {
      dispatch(
        updateJobTaskRequest({
          id: id,
          values: updatedValues,
          nav,
          actions,
          previousUrl,
        })
      )
    } else {
      dispatch(
        createJobTaskRequest({
          values: updatedValuesCreate,
          nav,
          actions,
          previousUrl,
        })
      )
    }
  }

  //for focusing fields on enter
  const focusOrderDate = useRef(null)
  const focusTranslator = useRef(null)
  const focusTask = useRef(null)
  const focusTranslatorLanguage = useRef(null)
  const focusOrderAmount = useRef(null)
  const focusOrderAmountCost = useRef(null)
  const focusOrderAmount10Per = useRef(null)
  const focusWordCount = useRef(null)
  const focusRate = useRef(null)
  const focusDeliveryDate = useRef(null)
  const focusStockingDate = useRef(null)
  const focusDateRecorded = useRef(null)
  const focusOperationManager = useRef(null)
  const focusTrados = useRef(null)
  const focusReadibility = useRef(null)
  const focusRemarks = useRef(null)
  const focusSaveBtn = useRef(null)
  const focusCancelFlag = useRef(null)

  const [query, setQuery] = useState({
    url: '',
    search: '',
  })
  useInputSearch(query)

  return (
    <Formik
      initialValues={initialState}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({
        handleChange,
        handleBlur,
        values,
        errors,
        setFieldValue,
        submitForm,
        resetForm,
      }) => {
        const handleSave = () => {
          submitForm()
        }
        const buttonConfigs = [
          {
            label: `${edit ? 'common.update' : 'common.save'}`,
            handler: handleSave,
            isShowAlert: true,
            focusBtn: focusSaveBtn,
            loading: edit ? loadingUpdateJobTask : loadingCreateJobTask,
            message: edit
              ? t('confirmMessage.update')
              : t('confirmMessage.save'),
          },
        ]

        const handleButtonAction = (action: string) => {
          const buttonConfig = buttonConfigs.find(
            (config) => config.label === action
          )
          if (buttonConfig) {
            const { handler } = buttonConfig
            handler()
          }
        }

        return (
          <Form autoComplete="off" noValidate>
            <Grid item sx={{ mb: 4 }}>
              <SaveFooterComponent
                handleClick={handleButtonAction}
                buttonConfigs={buttonConfigs}
              />
            </Grid>
            <Typography variant="h5" fontWeight="bold" sx={{ padding: '16px' }}>
              {edit ? t('moduleName.task.edit') : t('moduleName.task.create')}
            </Typography>
            <AppTitleCard title={t('taskList.taskInformation')}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Grid container spacing={2} sx={{ m: 2 }}>
                  <AppTextField
                    xs={2}
                    name="accounts_payable_month"
                    autoFocus
                    value={values?.accounts_payable_month}
                    label={t('jobDetails.jobTask.accPayableMonth')}
                    onChange={handleChange}
                    focusElement={focusOrderDate}
                  />
                  <AppDatePicker
                    xs={2}
                    name="order_date"
                    label={t('jobDetails.jobTask.orderDate')}
                    onChange={(value: any) => {
                      setFieldValue(`order_date`, dateFormatter(value))
                    }}
                    value={values?.order_date ?? ''}
                    variant="outlined"
                    sx={{
                      width: '100%',
                      size: 'small',
                      '& .MuiInputBase-root': {
                        height: '39px',
                        display: 'flex',
                        alignItems: 'center',
                        alignSelf: 'center',
                      },
                      '& .MuiInputLabel-root': {
                        textAlign: 'center',
                      },
                    }}
                  />
                  <AppAutoComplete
                    xs={2.2}
                    name="translator"
                    onFocus={() => handleFocusTranslatorCommon(dispatch)}
                    options={commonTranslator}
                    value={values?.translator}
                    loading={loadingCommonFilteredTranslator}
                    getOptionLabel={(option: any) =>
                      `${option.first_name} ${option?.last_name}`
                    }
                    label={t('jobDetails.jobTask.translator')}
                    onChange={(e: any, value: any) => {
                      if (value !== null) {
                        setFieldValue(`translator`, value)
                      }
                    }}
                    inputOnchange={(e: any) =>
                      onChangeInputTranslatorCommon(e?.target, setQuery)
                    }
                    onBlur={handleBlur}
                  />
                  <AppAutoComplete
                    xs={2.2}
                    name="task"
                    required
                    onFocus={() => handleFocusTaskCommon(dispatch)}
                    options={commonTasks}
                    value={values?.task}
                    loading={loadingCommonTask}
                    getOptionLabel={(option: any) => option.name}
                    label={t('jobDetails.jobTask.task')}
                    onChange={(e: any, value: any) => {
                      if (value !== null) {
                        setFieldValue(`task`, value)
                      }
                    }}
                    inputOnchange={(e: any) =>
                      onChangeInputTaskCommon(e?.target, setQuery)
                    }
                    onBlur={handleBlur}
                  />
                  <AppAutoComplete
                    xs={2.2}
                    name="translation_language"
                    onFocus={() =>
                      handleFocusTranslatorLanguageCommon(dispatch)
                    }
                    loading={loadingCommonTranslatorLanguage}
                    options={commonTranslatorLanguages}
                    value={values?.translation_language}
                    getOptionLabel={(option: any) => option.name}
                    label={t('jobDetails.jobTask.translateLanguage')}
                    onChange={(e: any, value: any) => {
                      if (value !== null) {
                        setFieldValue(`translation_language`, value)
                      }
                    }}
                    inputOnchange={(e: any) =>
                      onChangeInputTranslatorLanguageCommon(e?.target, setQuery)
                    }
                    onBlur={handleBlur}
                  />
                  <AppTextField
                    xs={2}
                    name="order_amount"
                    // onFocus={""}
                    value={values?.order_amount}
                    type="number"
                    label={t('jobDetails.jobTask.orderAmt')}
                    onChange={handleChange}
                  />
                  <AppTextField
                    xs={2}
                    name="order_amount_cost"
                    type="number"
                    // onFocus={""}
                    value={values?.order_amount_cost}
                    label={t('jobDetails.jobTask.orderAmtCost')}
                    onChange={handleChange}
                  />
                  <AppTextField
                    xs={1.5}
                    name="order_amount_10per"
                    type="number"
                    // onFocus={""}
                    value={values?.order_amount_10per}
                    label={t('jobDetails.jobTask.orderAmt10Oer')}
                    onChange={handleChange}
                  />
                  <AppTextField
                    xs={1.5}
                    name="word_count"
                    type="number"
                    // onFocus={""}
                    value={values?.word_count}
                    label={t('jobDetails.jobTask.wordCount')}
                    onChange={handleChange}
                  />
                  <AppTextField
                    xs={1.5}
                    name="rate"
                    type="number"
                    // onFocus={""}
                    value={values?.rate}
                    label={t('jobDetails.jobTask.rate')}
                    onChange={handleChange}
                  />
                  <AppDatePicker
                    xs={2}
                    name="delivery_date"
                    label={t('jobDetails.jobTask.deliveryDate')}
                    value={values?.delivery_date ?? ''}
                    onChange={(value: any) => {
                      setFieldValue(`delivery_date`, dateFormatter(value))
                    }}
                    variant="outlined"
                    sx={{
                      width: '100%',
                      size: 'small',
                      '& .MuiInputBase-root': {
                        height: '39px',
                        display: 'flex',
                        alignItems: 'center',
                      },
                      '& .MuiInputLabel-root': {
                        textAlign: 'center',
                      },
                    }}
                  />
                  <AppDatePicker
                    xs={2}
                    name="stocking_date"
                    label={t('jobDetails.jobTask.stockingDate')}
                    value={values?.stocking_date ?? ''}
                    onChange={(value: any) => {
                      setFieldValue(`stocking_date`, dateFormatter(value))
                    }}
                    variant="outlined"
                    sx={{
                      width: '100%',
                      size: 'small',
                      '& .MuiInputBase-root': {
                        height: '39px',
                        display: 'flex',
                        alignItems: 'center',
                      },
                      '& .MuiInputLabel-root': {
                        textAlign: 'center',
                      },
                    }}
                  />
                  <AppDatePicker
                    xs={2}
                    name="date_recorded"
                    label={t('jobDetails.jobTask.dateRecorded')}
                    value={values?.date_recorded ?? ''}
                    onChange={(value: any) => {
                      setFieldValue(`date_recorded`, dateFormatter(value))
                    }}
                    variant="outlined"
                    sx={{
                      width: '100%',
                      size: 'small',
                      '& .MuiInputBase-root': {
                        height: '39px',
                        display: 'flex',
                        alignItems: 'center',
                      },
                      '& .MuiInputLabel-root': {
                        textAlign: 'center',
                      },
                    }}
                  />
                  <AppTextField
                    xs={2}
                    name="operation_manager"
                    value={values?.operation_manager}
                    label={t('jobDetails.jobTask.operationManager')}
                    onChange={handleChange}
                  />
                  <AppAutoComplete
                    xs={2.2}
                    name="trados"
                    onFocus={() => handleFocusTradosCommon(dispatch)}
                    options={commonTrados}
                    value={values?.trados}
                    getOptionLabel={(option: any) => option.name}
                    label={t('jobDetails.jobTask.trados')}
                    onChange={(e: any, value: any) => {
                      if (value !== null) {
                        setFieldValue(`trados`, value)
                      }
                    }}
                    inputOnchange={(e: InputChangeEvent) =>
                      onChangeInputTradosCommon(e?.target, setQuery)
                    }
                    // inputRef={""}
                    // focusElement={""}
                    onBlur={handleBlur}
                  />
                  <AppTextField
                    xs={2}
                    name="readability"
                    type="number"
                    // onFocus={""}
                    value={values?.readability}
                    label={t('jobDetails.jobTask.readibility')}
                    onChange={handleChange}
                  />
                  <AppTextField
                    xs={2}
                    name="remarks"
                    // onFocus={""}
                    value={values?.remarks}
                    label={t('jobDetails.jobTask.remarks')}
                    onChange={handleChange}
                  />
                </Grid>
              </LocalizationProvider>
              {/* </Grid> */}
            </AppTitleCard>
            <FocusElementOnInvalidValidation />
          </Form>
        )
      }}
    </Formik>
  )
}

export default CreateTaskList
