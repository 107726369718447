import React, { useState, useRef, useLayoutEffect, lazy, Suspense } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { Card, CardHeader, Grid, Typography } from '@mui/material'

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import {
  handleFocusUserCommon,
  handleFocusTaskTypeCommon,
} from '../../../CommonAppRedux/commonAppFunctions'
import { commonAppSelector } from '../../../CommonAppRedux/selector'
import { authSelector } from '../../../Login/Redux/selector'
import AppTextField from '../../../ReusableComponent/AppTextField/AppTextField'
import { NumericFormatCustom } from '../../../ReusableComponent/CommonReportTable/CommonReportTable'
import {
  useAppDispatch,
  useAppSelector,
} from '../../../ReusableComponent/CustomHooks/appHooks'
import { useInputSearch } from '../../../ReusableComponent/CustomHooks/useInputSearch'
import FocusElementOnInvalidValidation from '../../../ReusableComponent/FocusElementIfInvalid/FocusElementOnInvalidValidation'
import SaveFooterComponent from '../../../ReusableComponent/SaveFooterButton/SaveFooterButton'
import messages, { maxCharValidation } from '../../../Utils/ValidationMessage'
import { query } from '../../../Utils/globalTypes'
import {
  clearDataProjectDetails,
  clearHardResetProjectList,
  getProjectDetailByIdRequest,
} from '../../ProjectList/Redux/projectListSlice'
import { projectListSelector } from '../../ProjectList/Redux/selector'
import {
  clearHardResetInputTrackRecord,
  clearInputTrackRecord,
  createInputTrackRecordRequest,
  getInputTrackRecordRequest,
  updateInputTrackRecordRequest,
} from './Redux/inputTrackRecordSlice'
import { inputTrackRecordSelector } from './Redux/selector'

// import
const InputTrackRecordTable = lazy(() => import('./InputTrackRecordTable'))

const InputTrackRecord = () => {
  // const { id } = useParams()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const { state } = location
  const previousUrl = state?.path
  const id = searchParams.get('case-id')
  // const projectDetail?.case = searchParams?.get('quotation-id')
  const edit = searchParams?.get('edit')
  const { commonUsers, commonTaskTypes } = useAppSelector(commonAppSelector)
  const { projectDetail } = useAppSelector(projectListSelector)
  const { InputTrackRecord } = useAppSelector(inputTrackRecordSelector)
  const intUsers = commonUsers.filter((user: any) => user.is_staff === true)
  const navigate = useNavigate()
  const purchaseOrderExcludedPrice =
    projectDetail?.total_tax_excluded_price ?? 0

  const orderReceiptTaxSubTotal =
    projectDetail?.delivery_note_tax_subtotal_subtotal_amount ?? 0

  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  //all reducer action and clear function implement start

  useLayoutEffect(() => {
    handleFocusUserCommon(dispatch)
    handleFocusTaskTypeCommon(dispatch)
    if (id) {
      dispatch(getProjectDetailByIdRequest(id))
    } else {
      dispatch(clearDataProjectDetails())
      dispatch(clearHardResetProjectList())
    }

    return () => {
      dispatch(clearHardResetProjectList())
      dispatch(clearInputTrackRecord())
      dispatch(clearHardResetInputTrackRecord())
    }
  }, [id, location, dispatch, edit])

  useLayoutEffect(() => {
    if (projectDetail?.costs[0]) {
      dispatch(getInputTrackRecordRequest(projectDetail?.costs[0]))
    } else {
      dispatch(clearInputTrackRecord())
    }
  }, [projectDetail, location, dispatch, edit])

  //all reducer action and clear function implement End

  const updateValues = InputTrackRecord?.cost_details?.map((row: any) => {
    return {
      ...row,
      internal_expense: +row?.time * +InputTrackRecord?.internal_unit_price,
    }
  })

  const initialState: any = {
    case: id,
    total_expense: 0,
    cost: 0,
    total_cost: 0,
    selling_price: 0,
    internal_unit_price: InputTrackRecord?.internal_unit_price ?? 5000,
    cost_rate: 0,
    total_cost_rate: 0,
    cost_details: updateValues ?? [
      {
        user: null,
        task_type: null,
        time: 1,
        internal_expense: 5000 * 1,
      },
    ],
  }

  const validationSchema = Yup.object().shape({
    internal_unit_price: Yup.number()
      .max(999999999999, maxCharValidation(12))
      .required(t(messages.required)),
    cost_details: Yup.array().of(
      Yup.object().shape({
        user: Yup.object().required(t(messages.required)),
        task_type: Yup.object().required(t(messages.required)),
        time: Yup.number().max(999999999999, maxCharValidation(12)).nullable(),
      })
    ),
  })

  // CommonReportProps
  const columns = [
    {
      id: 'user',
      label: `${t('inputTrackRecord.tableItems.contactPersonUser')}`,
      minWidth: 400,
      dropDown: true,
      options: intUsers,
    },
    {
      id: 'task_type',
      label: `${t('inputTrackRecord.tableItems.taskType')}`,
      minWidth: 300,
      dropDown: true,
      options: commonTaskTypes,
    },
    {
      id: 'time',
      type: 'number',
      label: `${t('inputTrackRecord.tableItems.time')}`,
      minWidth: 30,
      currency: true,
      time: true,
    },
    {
      id: 'internal_expense',
      label: `${t('inputTrackRecord.tableItems.internalExpense')}`,
      minWidth: 100,
      disabled: true,
      currency: true,
    },
    {
      id: 'actions',
      label: `${t('common.actions')}`,
      minWidth: 100,
      actions: true,
    },
  ]
  const [query, setQuery] = useState<query>({
    search: '',
    url: '',
  })
  useInputSearch(query)
  const nav = useNavigate()

  const onSubmit = async (values: any, actions: any) => {
    const cost_details = values.cost_details.map(
      ({ user, task_type, time, id }: any) => ({
        id: id ?? undefined,
        user: user?.id,
        task_type: task_type?.id,
        time,
      })
    )
    const version = InputTrackRecord?.version
    if (edit) {
      dispatch(
        updateInputTrackRecordRequest({
          id: InputTrackRecord?.id,
          values: {
            ...values,
            version,
            cost_details,
          },
          nav: nav,
          actions,
          previousUrl,
        })
      )
    } else {
      dispatch(
        createInputTrackRecordRequest({
          values: { ...values, cost_details },
          nav: nav,
          actions,
          previousUrl,
        })
      )
    }
  }
  // onfocus
  const focus_save_btn = useRef(null)

  const handleBackToCase = () => {
    const queryParams = `?id=${id}&edit=${true}`
    navigate(`/create-agency-project${queryParams}`, {
      state: { path: location?.pathname + location?.search },
    })
  }

  const [readOnlyMode, setReadOnlyMode] = useState(false)

  const { is_staff, user } = useAppSelector(authSelector)

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialState}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          values,
          handleChange,
          setFieldValue,
          handleBlur,
          errors,
          submitForm,
          touched,
        }) => {
          const handleSave = () => {
            submitForm()
          }
          const buttonConfigs = [
            {
              label: edit ? 'common.update' : 'common.save',
              handler: handleSave,
              isShowAlert: true,
              message: edit
                ? t('confirmMessage.update')
                : t('confirmMessage.save'),
            },
          ]

          const handleButtonAction = (action: string) => {
            const buttonConfig = buttonConfigs.find(
              (config) => config.label === action
            )
            if (buttonConfig) {
              const { handler } = buttonConfig
              handler()
            }
          }

          const subInputTrackRecodeTotal = values?.cost_details.reduce(
            (sum: number, item: any) => {
              const amountSubTotal = parseFloat(item.internal_expense) || 0
              return sum + amountSubTotal
            },
            0
          )
          const total_cost_sum =
            subInputTrackRecodeTotal + purchaseOrderExcludedPrice

          return (
            <>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Grid item sx={{ mb: 4 }}>
                  <SaveFooterComponent
                    handleClick={handleButtonAction}
                    buttonConfigs={buttonConfigs}
                  />
                </Grid>

                <div className={readOnlyMode ? 'disabled-form' : ''}>
                  <Form autoComplete="off" noValidate>
                    <Card sx={{ mb: 2 }}>
                      <CardHeader
                        title={
                          <Typography
                            variant="h6"
                            sx={{ fontWeight: 'bold', color: 'white' }}
                          >
                            {t('inputTrackRecord.name')}
                          </Typography>
                        }
                        sx={{
                          backgroundColor: '#062f96',
                          height: '48px',
                        }}
                      />

                      <h1 style={{ textAlign: 'center' }}>
                        {t('inputTrackRecord.name')}
                      </h1>
                      <Grid
                        container
                        padding={4}
                        // alignItems="center"
                        // justifyContent="center"
                        spacing={3}
                      >
                        <Grid
                          container
                          padding={2}
                          // alignItems="center"
                          // justifyContent="center"
                          spacing={3}
                        >
                          <Grid item xs={4}>
                            <AppTextField
                              name="selling_price"
                              disabled
                              label={t('inputTrackRecord.sellingPrice')}
                              value={orderReceiptTaxSubTotal}
                              onChange={(e: any) => {}}
                              InputProps={{
                                inputComponent: NumericFormatCustom as any,
                              }}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <AppTextField
                              name="internal_unit_price"
                              label={t('inputTrackRecord.internalUnitPrice')}
                              value={values?.internal_unit_price}
                              InputProps={{
                                inputComponent: NumericFormatCustom as any,
                              }}
                              onChange={(e: any) => {
                                setFieldValue(
                                  'internal_unit_price',
                                  e.target.value.trim()
                                )
                                const updateValues = values?.cost_details.map(
                                  (row: any) => {
                                    return {
                                      ...row,
                                      internal_expense:
                                        +row?.time * +e.target.value.trim() ??
                                        0,
                                    }
                                  }
                                )
                                setFieldValue('cost_details', updateValues)
                              }}
                              onBlur={handleBlur}
                            />
                          </Grid>

                          <Grid item xs={4}>
                            <AppTextField
                              name="total_expense"
                              disabled
                              value={subInputTrackRecodeTotal}
                              label={t('inputTrackRecord.totalExpense')}
                              onChange={(e: any) => {}}
                              onBlur={handleBlur}
                              InputProps={{
                                inputComponent: NumericFormatCustom as any,
                              }}
                            />
                          </Grid>
                        </Grid>

                        <Grid
                          container
                          padding={2}
                          // alignItems="center"
                          // justifyContent="center"
                          spacing={3}
                        >
                          <Grid item xs={4}>
                            <AppTextField
                              name="cost"
                              disabled
                              label={t('inputTrackRecord.cost')}
                              value={purchaseOrderExcludedPrice}
                              onChange={(e: any) => {}}
                              InputProps={{
                                inputComponent: NumericFormatCustom as any,
                              }}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <AppTextField
                              name="total_cost"
                              disabled
                              label={t('inputTrackRecord.totalCost')}
                              value={
                                subInputTrackRecodeTotal +
                                purchaseOrderExcludedPrice
                              }
                              InputProps={{
                                inputComponent: NumericFormatCustom as any,
                              }}
                            />
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          padding={2}
                          // alignItems="center"
                          // justifyContent="center"
                          spacing={3}
                        >
                          <Grid item xs={4}>
                            <AppTextField
                              name="cost_rate"
                              disabled
                              label={t('inputTrackRecord.costRate')}
                              value={(() => {
                                if (
                                  orderReceiptTaxSubTotal === null ||
                                  orderReceiptTaxSubTotal === 0
                                ) {
                                  return 0.0
                                } else {
                                  return (
                                    (purchaseOrderExcludedPrice /
                                      orderReceiptTaxSubTotal) *
                                    100
                                  ).toFixed(2)
                                }
                              })()}
                              InputProps={{
                                inputComponent: NumericFormatCustom as any,
                              }}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <AppTextField
                              name="total_cost_rate"
                              disabled
                              label={t('inputTrackRecord.totalCostRate')}
                              value={(() => {
                                if (
                                  orderReceiptTaxSubTotal === null ||
                                  orderReceiptTaxSubTotal === 0
                                ) {
                                  return 0.0
                                } else {
                                  return (
                                    (total_cost_sum / orderReceiptTaxSubTotal) *
                                    100
                                  ).toFixed(2)
                                }
                              })()}
                              InputProps={{
                                inputComponent: NumericFormatCustom as any,
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>

                      {/* //-------------------------------------------------------------------- */}
                      <Suspense fallback={<></>}>
                        <InputTrackRecordTable
                          columns={columns}
                          disabled={readOnlyMode}
                          taxType={`${t('commonFinanceTerm.excludingTax')})`}
                          keyName="cost_details"
                          values={values?.cost_details}
                          handleChange={handleChange}
                          setFieldValue={setFieldValue}
                          handleBlur={handleBlur}
                          initialValues={initialState?.cost_details}
                          setQuery={setQuery}
                          touched={touched}
                          // bgColor="#d7e2ea"
                          edit={edit}
                          internal_unit_price={values?.internal_unit_price}
                        />
                      </Suspense>
                    </Card>
                    <FocusElementOnInvalidValidation />
                  </Form>
                </div>
              </LocalizationProvider>
            </>
          )
        }}
      </Formik>
    </>
  )
}

export default InputTrackRecord
