import { useEffect, useCallback, useState } from 'react'

import { catchError, from, of } from 'rxjs'

import { axiosInstance } from '../../Utils/axios'
import { query } from '../../Utils/globalTypes'
import { useAppDispatch } from './appHooks'

export interface apiData {
  results: any
}
export const useInputSearch = (query: query) => {
  const { search, url, loadingAction, successSlice, errorSlice } = query
  const controller = new AbortController()
  const dispatch = useAppDispatch()
  const [apiData, setApiData] = useState<apiData>({ results: {} })

  // prevent
  const getFormData = useCallback(() => {
    from(
      axiosInstance.get(url, {
        signal: controller?.signal,
      })
    )
      // catching error
      .pipe(catchError((err) => of(err)))
      .subscribe((response) => {
        setApiData(response.data)
      })
  }, [url])

  //call api if url changes
  useEffect(() => {
    url && (
      <>
        {getFormData()}
        {dispatch(loadingAction())};
      </>
    )

    return () => controller.abort()
  }, [dispatch, search, getFormData])
  //dispatch actions if apiData exist
  useEffect(() => {
    apiData?.results.length !== undefined &&
      apiData?.results.length >= 0 &&
      dispatch(successSlice?.(apiData))
  }, [search, apiData, dispatch])
}
