import { useEffect, Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Link,
} from '@mui/material'

import { getCommonStorePreviousURLPath } from '../../../CommonLocalStorageRedux/localReduxSlice'
import { localStorageReduxSelector } from '../../../CommonLocalStorageRedux/selector'
import AppTitleCard from '../../../ReusableComponent/AppTitleCard/AppTitleCard'
import {
  useAppDispatch,
  useAppSelector,
} from '../../../ReusableComponent/CustomHooks/appHooks'
import ListingTableData from '../../../ReusableComponent/Loader/ListingTableData'
import NoData from '../../../ReusableComponent/NoData/NoData'
import SaveFooterComponent from '../../../ReusableComponent/SaveFooterButton/SaveFooterButton'
import { formatDateTimestamp } from '../../../Utils/AppFormatFunction'
import { getProjectWorkFlowHistoryRequest } from '../Redux/projectListSlice'
import { projectListSelector } from '../Redux/selector'

const WorkFlowHistory = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const caseId = searchParams?.get('case-id')
  const { caseWorkFlowHistories, loadingCaseWorkFlowHistory } =
    useAppSelector(projectListSelector)

  useEffect(() => {
    dispatch(getProjectWorkFlowHistoryRequest({ id: caseId }))
  }, [dispatch, caseId])

  const columns = [
    {
      id: 'case_no',
      label: `${t('workFlowHistory.columns.caseNo')}`,
      minWidth: 200,
    },
    {
      id: 'user_fullname',
      label: `${t('workFlowHistory.columns.userFullname')}`,
      minWidth: 200,
    },
    {
      id: 'job_no',
      label: `${t('workFlowHistory.columns.jobNo')}`,
      minWidth: 200,
    },
    {
      id: 'status',
      label: `${t('workFlowHistory.columns.status')}`,
      minWidth: 200,
    },
    {
      id: 'processing_date',
      label: `${t('workFlowHistory.columns.processingDate')}`,
      minWidth: 200,
    },
    {
      id: 'report_no',
      label: `${t('workFlowHistory.columns.reportNo')}`,
      minWidth: 200,
    },
    {
      id: 'report_type',
      label: `${t('workFlowHistory.columns.reportType')}`,
      minWidth: 200,
    },
  ]
  const { commonPreviousURLPath } = useAppSelector(localStorageReduxSelector)
  useEffect(() => {
    dispatch(
      getCommonStorePreviousURLPath([
        ...commonPreviousURLPath,
        { pathName: location?.pathname, search: location?.search },
      ])
    )
  }, [])
  const buildReportLink = (
    type: number,
    parentId: number,
    reportId: number
  ) => {
    const id =
      type === 4 || type === 5
        ? `=${reportId}&job-task-id=${parentId}`
        : type === 7
        ? `?invoicehq-parent-id=${reportId}`
        : `=${reportId}&case-id=${parentId}`
    const queryParams = `${id}&edit=${true}`
    return type === 1
      ? `/issue-quotation?quotation-id${queryParams}`
      : type === 2
      ? `/issue-order-receipt?order-receipt-id${queryParams}`
      : type === 3
      ? `/delivery-report-note-approve?delivery-note-id${queryParams}`
      : type === 4
      ? `/issue-purchase?purchase-order-id${queryParams}`
      : type === 5
      ? `/issue-certificate?certificate-id${queryParams}`
      : type === 6
      ? `/case-issue-invoice?invoice-id${queryParams}`
      : type === 7
      ? `/invoicehq${queryParams}`
      : '#'
  }

  const rowValues = caseWorkFlowHistories?.map((row: any) => ({
    ...row,
    status: t(`workFlowHistory.status.${row.status}`),
    report_type: t(`workFlowHistory.reportName.${row.report_type}`),
    report_no: (
      <Link
        variant="body1"
        href={buildReportLink(row.report_type, row.parent_id, row.report_id)}
      >
        {row.report_no}
      </Link>
    ),
    processing_date: row?.processing_date
      ? formatDateTimestamp(row?.processing_date)
      : '-',
  }))

  //handling pagination
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const handleChangePage = (
    event: React.MouseEvent | null,
    newPage: number
  ) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  return (
    <Fragment>
      <SaveFooterComponent />
      <AppTitleCard
        title="workFlowHistory.title"
        sx={{ backgroundColor: '#f57c00' }}
        mt={5}
      >
        <TableContainer
          component={Paper}
          sx={{
            minWidth: 700,
            minHeight: 500,
            position: 'relative',
            paddingBottom: '50px',
          }}
        >
          <Table aria-label="spanning table">
            <TableHead>
              <TableRow>
                {columns?.map((col) => {
                  return <TableCell key={col.id}>{col?.label}</TableCell>
                })}
              </TableRow>
            </TableHead>
            {loadingCaseWorkFlowHistory ? (
              <ListingTableData headerLength={columns?.length} />
            ) : (
              <TableBody>
                {(rowsPerPage > 0
                  ? rowValues.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : rowValues
                )?.map((row: any, index: number) => {
                  return (
                    <TableRow key={index}>
                      {columns?.map((col) => {
                        return (
                          <TableCell key={col?.id}>
                            {row[col?.id] ? row[col?.id] : '-'}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  )
                })}
              </TableBody>
            )}
          </Table>
          <TablePagination
            sx={{ position: 'absolute', bottom: 0 }}
            rowsPerPageOptions={[10, 20, 50]}
            component="div"
            count={rowValues.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          {!loadingCaseWorkFlowHistory &&
            caseWorkFlowHistories?.length === 0 && (
              <Grid
                container
                justifyContent="center"
                sx={{ position: 'absolute' }}
              >
                <NoData />
              </Grid>
            )}
        </TableContainer>
      </AppTitleCard>
    </Fragment>
  )
}

export default WorkFlowHistory
