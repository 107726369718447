import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, useLocation, useNavigate} from 'react-router-dom'

import { Card, Grid, Button } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'

import {
  clickFooterButtonActive,
  showAlertDialog,
} from '../../CommonAppRedux/commonAppSlice'
import { commonAppSelector } from '../../CommonAppRedux/selector'
import AlertDialog from '../AlertDialog/AlertDialog'
import AppButton from '../AppButton/AppButton'
import { useAppDispatch, useAppSelector } from '../CustomHooks/appHooks'
import Spinner from '../Loader/Spinner'
import { authSelector } from '../../Login/Redux/selector'
import { getCommonStorePreviousURLPath } from '../../CommonLocalStorageRedux/localReduxSlice'
import { localStorageReduxSelector } from '../../CommonLocalStorageRedux/selector'
// import { clearCommonStorePreviousURLPath, getCommonStorePreviousURLPath } from '../../Login/Redux/authSlice'


interface SaveFooterComponentProps {
  handleClick?: (action: string, ...args: any[]) => void
  buttonConfigs?: {
    label: string
    handler: any
    isShowAlert?: boolean | undefined
    message?: string
    focusBtn?: any
    onlyMessage?: boolean
    loading?: boolean
    name?: string
  }[]
  hideBackBtn?: boolean
}
function SaveFooterComponent({
  handleClick,
  buttonConfigs,
  hideBackBtn,
}: SaveFooterComponentProps) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const cardRef = useRef<HTMLDivElement>(null)
  const [sidebarWidth, setSidebarWidth] = useState<number | undefined>(
    undefined
  )
  const dispatch = useAppDispatch()
  const {
    showAlert,
    activeButtonLoading,
    activeHandler,
    activeMessage,
    currentInnerWidth,
    activeButtonIndex,
    footerButtonActive,
  } = useAppSelector(commonAppSelector);
  const {
    commonPreviousURLPath
  } = useAppSelector(localStorageReduxSelector);
  console.log(commonPreviousURLPath,"commonPreviousURLPathcommonPreviousURLPath")
  useEffect(() => {
    const handleResize = () => {
      if (cardRef.current) {
        const cardWidthLeftWidth = cardRef.current.offsetLeft
        const windowWidth = window.innerWidth
        const availableWidth = windowWidth - cardWidthLeftWidth
        setSidebarWidth(availableWidth)
      }
    }
    setTimeout(() => {
      handleResize()
    }, 200)
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [currentInnerWidth])
  const location=useLocation();
  const { is_staff } = useAppSelector(authSelector)
  const handleBack = () => {
    const currentPathName=location?.pathname
    if (commonPreviousURLPath.length > 0) {
      const filterArray=commonPreviousURLPath.filter((row:any)=>row?.pathName !==currentPathName);
      const lastEntry = filterArray[filterArray.length - 1];
      if(lastEntry){
        navigate(`${lastEntry.pathName}${lastEntry.search}`);
        const updateUrls=filterArray?.filter((row:any)=>row?.pathName !==lastEntry.pathName);
        dispatch(getCommonStorePreviousURLPath(updateUrls))
      }else{
        const dest = is_staff ? '/project-list' : '/project-list-client'
        return <Navigate to={dest} replace />
      }
    } else {
      console.error('Array is empty, cannot navigate back.');
    }
  }
      // navigate(-1)
  useEffect(() => {
    if (!showAlert) {
      dispatch(
        clickFooterButtonActive({
          activeButtonIndex: -1,
        })
      )
    }
  }, [showAlert])


  return (
    <div ref={cardRef} style={{ display: 'flex', justifyContent: 'center' }}>
      <Card
        sx={{
          // mr: 30,
          mt: -4,
          position: 'absolute',
          width: sidebarWidth,
          zIndex: 999,
          transition: 'transform 0.3s ease-in-out',
        }}
      >
        <Grid container spacing={1} sx={{ mt: 0, mb: 1 }}>
          <Grid
            item
            xs={12}
            sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}
          >
            <Grid
              item
              sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}
            >
              {!hideBackBtn && (
                <Button
                  size="large"
                  variant="contained"
                  onClick={handleBack}
                  sx={{ mr: 2 }}
                >
                  {t('common.goBack')}
                </Button>
              )}

              {buttonConfigs?.map(
                (
                  {
                    label,
                    handler,
                    isShowAlert,
                    message,
                    focusBtn,
                    onlyMessage,
                    loading,
                    name,
                  },
                  index
                ) => (
                  console.log(loading, 'loading'),
                  (
                    <React.Fragment key={index}>
                      <>
                        {isShowAlert && footerButtonActive && (
                          <AlertDialog
                            handleSubmit={activeHandler}
                            message={activeMessage}
                          />
                        )}
                      </>

                      <AppButton
                        key={index}
                        // ref={focusBtn}
                        name={name}
                        loading={index === activeButtonIndex}
                        variant="contained"
                        sx={{ mr: 0.3 }}
                        style={{ height: '100%' }}
                        onClick={(e: any) => {
                          dispatch(getCommonStorePreviousURLPath([ ...commonPreviousURLPath,{pathName:location?.pathname,search: location?.search }]))
                          if (isShowAlert) {
                            dispatch(
                              clickFooterButtonActive({
                                activeButtonLoading: loading,
                                activeButtonIndex: index,
                                activeHandler: handler,
                                activeMessage: message,
                                activeOnlyMessage: onlyMessage,
                              })
                            )
                            dispatch(showAlertDialog())
                          } else {
                            handler()
                          }
                        }}
                      >
                        {/* {loading ? (
                        <Spinner color="inherit" />
                      ) : ( */}
                        {/* // )} */}
                        {t(`${label}`)}
                      </AppButton>
                    </React.Fragment>
                  )
                )
              )}
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </div>
  )
}
export default SaveFooterComponent
