import { Action } from '@reduxjs/toolkit'
import { t } from 'i18next'
import { combineEpics } from 'redux-observable'
import { Observable } from 'rxjs'
import { filter, map, switchMap } from 'rxjs/operators'

import { getNext, getPrevious } from '../../../CommonAppRedux/api'
import {
  alertErrorAction,
  alertSuccessAction,
} from '../../../CommonAppRedux/commonAppSlice'
import messages from '../../../Utils/ValidationMessage'
import { defaultPage, defaultRowsPerPage } from '../../../Utils/globalConstant'
import { dispatchAction, stateAction } from '../../../Utils/globalTypes'
import {
  getOrderList,
  getOrderListDetail,
  getOrderListDetailById,
} from './api'
import {
  loadingOrderList,
  getOrderListSuccess,
  getOrderListFail,
  getOrderListRequest,

  getOrderListDetailSuccess,
  getOrderListDetailFail,
  getOrderListDetailRequest,
  getOrderListDetailByIdRequest,
  getOrderListDetailByIdSuccess,
  getOrderListDetailByIdFail,
  getOrderDetailListNextRequest,
  getOrderDetailListPreviousRequest,
} from './orderListSlice'
import { extractErrorMessage } from '../../../Utils/AppFunction'

const getOrderListEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(getOrderListRequest.match),
    switchMap(async (action) => {
      dispatch(loadingOrderList())
      try {
        const response = await getOrderList(action.payload)
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload ? getOrderListSuccess(action?.payload) : getOrderListFail()
    )
  )
}

//get next
const getOrderDetailListNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getOrderDetailListNextRequest.match),
    switchMap(async (action) => {
      try {
        const response = await getNext(action.payload)
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? getOrderListDetailSuccess(action?.payload)
        : getOrderListDetailFail()
    )
  )
//get previous
const getOrderDetailListPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getOrderDetailListPreviousRequest.match),
    switchMap(async (action) => {
      try {
        const response = await getPrevious(action.payload)
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? getOrderListDetailSuccess(action?.payload)
        : getOrderListDetailFail()
    )
  )

const getOrderListDetailEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(getOrderListDetailRequest.match),
    switchMap(async (action) => {
      dispatch(loadingOrderList())
      try {
        const response = await getOrderListDetail(action.payload)
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? getOrderListDetailSuccess(action?.payload)
        : getOrderListDetailFail()
    )
  )
}

const getOrderListDetailByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(getOrderListDetailByIdRequest.match),
    switchMap(async (action) => {
      dispatch(loadingOrderList())
      try {
        const response = await getOrderListDetailById(action.payload)
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? getOrderListDetailByIdSuccess(action?.payload)
        : getOrderListDetailByIdFail()
    )
  )
}

//

export const orderListEpics = combineEpics(
  getOrderListEpic,

  getOrderListDetailEpic,
  getOrderListDetailByIdEpic,

  getOrderDetailListNext,
  getOrderDetailListPrevious,
  //translator certificate
  //user made invoice
)
