import {
  useCallback,
  useEffect,
  Dispatch,
  SetStateAction,
  useState,
} from "react";
import { catchError, from, of } from "rxjs";
import { axiosInstance } from "../../Utils/axios";
import { globalApiData } from "../../Utils/globalTypes";
import { useDispatch } from "react-redux";
// import { useAppDispatch } from "./appHooks";
// import { useImmer } from "use-immer";
// import { axiosInstance } from "../Utils/axios";
// import { globalApiData } from "../Utils/globalTypes";
export interface apiData extends globalApiData {
  results: any[];
}
interface duplicateData {
  url: string;
  value: string;
  edit: boolean;
  editType: any;
  fieldName?: string;
  componentName: string;
}
interface dataProps {
  [key: string]: number | string;
}
interface duplicateValueProps {
  [key: string]: dataProps;
}

export const initialDuplicateValues = {
  url: "",
  value: "",
  edit: false,
  editType: "",
  componentName: "",
  fieldName: "",
};
export const useDuplicateData = (duplicateData: duplicateData) => {
  const { url, value, edit, editType, componentName, fieldName } =
    duplicateData;
  const dispatch = useDispatch();
  const controller = new AbortController();
  const [apiData, setApiData] = useState<any>([]);
  const [duplicateDataList, setDuplicateData] = useState<duplicateValueProps[]>(
    [{}]
  );
  const getFormData = useCallback(() => {
    from(
      axiosInstance.get(url, {
        signal: controller?.signal,
      })
    )
      // catching error
      .pipe(catchError((err) => of(err)))
      .subscribe((response) => {
        setApiData(response.data);
        response?.data?.results &&
          setDuplicateData((prev: any) => {
            return prev?.map((duplicateValue: any) => {
              return {
                ...duplicateValue,
                [`${fieldName}`]:
                  edit && value.toLowerCase() === editType.toLowerCase()
                    ? []
                    : response.data.results,
              };
            });
          });
      });
  }, [url]);

  //call api if url changes
  useEffect(() => {
    url && getFormData();
    return () => controller.abort();
  }, [dispatch, url, getFormData]);
  //check if data is already added or not
  useEffect(() => {

    if (edit && value.toLowerCase() !== editType.toLowerCase()) {
      if (value?.trim() && value !== "") {
        if (apiData?.results?.length! > 0) {
          alert("added")
          // dispatch(
          //   alertErrorAction(`${componentName} has been already added.`)
          // );
        }
      }
    } else {
      if (!edit && value?.trim() && value !== "") {
        if (apiData?.results?.length! > 0) {
          alert("added")
          // dispatch(
          //   alertErrorAction(`${componentName} has been already added.`)
          // );
        }
      }
    }
  }, [apiData, editType, edit]);

  return [duplicateDataList];
};
