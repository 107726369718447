const enTranslations = {
  translation: {
    common: {
      referenceFile: 'Reference File',
      requestFile: 'Request File',
      issueFlag: 'Issue Flag',
      networkError: 'Network Error',
      noInternet: 'No Internet Connection',
      uploadFile: 'Upload File',
      createDeliveryItemUploadBtn: 'Create Delivery Item Upload',
      requestForApproval: 'Request For Approval',
      delivery: 'Delivery',
      openURLLink: 'Open URL Link',
      createCertificateUpload: 'Certificate Upload',
      updateCertificateUpload: 'Update Certificate Upload',
      issued: 'Issued',
      notIssued: 'Not Issued',
      backToCase: 'Back To Case',
      deliveryNote: 'Delivery Note',
      translator: 'Translator',
      company: 'company',
      selectReportForProjectListType: 'Select Report For Project List Type',
      selectReportType: 'Select Report Type',
      client: 'Client',
      orderPlacement: 'Order Placement',
      orderPlacementBtn: 'Create Order Placement',
      stockingExport: 'Stocking Export',
      salesExport: 'Sales Export',
      dataExport: 'Data Export',
      exportInvoiceData: 'Export Invoice Data',
      withoutQuotation: 'Without Quotation',
      temporarySaving: 'Temporary Saving',
      registration: 'Registration',
      issueInvoice: 'Issue Invoice',
      download: 'Download',
      autoCalculate: 'Auto Calculate',
      edit: 'edit',
      save: 'Save',
      print: 'Print',
      percussion: 'percussion',
      email: 'Email',
      submit: 'Submit',
      confirm: 'Confirm',
      ok: 'Ok',
      cancel: 'Cancel',
      telePhoneNo: 'Telephone No',
      general: 'General',
      remarks: 'Remarks',
      search: 'Search',
      clear: 'Clear',
      invoice: 'Invoice',
      invoiceBtn: 'Create Invoice',
      add: 'Add',
      no: 'NO',
      yes: 'YES',
      number: 'no',
      upload: 'Upload',
      character: 'Character(s)',
      send: 'Send',
      url: 'URL',
      file: 'FILE',
      fileName: 'File Name',
      uploadUrlLinkHere: 'Upload URL Link Here',
      downloadAttachment: 'Download Attachment',
      addAttachment: 'Add Attachment',
      deleteAttachment: 'Delete Attachment',
      actions: 'Actions',
      delete: 'Delete',
      update: 'Update',
      reasonForChange: 'Reason For Change',
      comments: 'Comments',
      goBack: 'Go Back',
      cost: 'cost',
      expense: 'expense',
      csvExport: 'CSV Export',
      title: 'Title',
      Expanded: 'Expanded',
      Collapse: 'Collapse',
      preview: 'Preview',
      quotation: 'Quotation',
      searchCondition: 'Search Condition',
      clearConditions: 'Clear Conditions',
      purchaseOrder: 'Purchase Order',
      purchaseOrderBtn: 'Create Purchase Order',
      orderReceipt: 'Order Receipt',
      orderReceiptBtn: 'Create Order Receipt',
      deliveryItemUpload: 'delivery item upload',
      deliveryItemUploadBtn: 'delivery item upload',
      deliveryNoteApprove: 'Delivery Note Approve',
      deliveryNoteApproveBtn: 'Create Delivery Note Approve',
      acceptance: 'Acceptance',
      requestQuotation: 'Request Quotation',
      updateRequestQuotation: 'Update Request Quotation',
      addNewDetail: 'add new detail',
      deleteDetail: 'delete detail',
      addNewProjectDetail: 'add new project detail',
      deleteProjectDetail: 'delete project detail',
      noDataDisplay: 'No Data To Display',
      logout: 'logout',
      uploadAndDeliveryNoteIssue: 'upload + Delivery Note Issue',
      uploadAndIssueOrderReceipt: 'Upload + Issue Order Receipt',
      invoiceUpload: 'Invoice Upload',
      close: 'Close',
      tax: '%Tax',
      total: 'Total',
      withdrawal: 'Withdrawal',
      previewReportNotFound: 'Preview report not found',
      urlOrFile: 'URL or File',
    },
    confirmMessage: {
      deleteJobDetail: 'Delete Job Detail, Are you sure?',
      cancelMatching: 'Cancel Matching,  Are you sure?',
      requestQuotationRequestFiles:
        'The Request File Has Not Been Uploaded, Are You Sure?',
      confirmationTitle: 'confirmation',
      confirmation: 'Are you sure?',
      save: 'save. Are you sure?',
      update: 'update. Are you sure?',
      upload: 'upload. Are you sure?',
      delete: 'delete. Are you sure?',
      cancel: 'cancel. Are you sure?',
      send: 'send. Are you sure?',
      temporarySaving: 'temporary saving. Are you sure?',
      registration: 'Registration. Are you sure?',
      requestQuotation: 'request quotation. Are you sure?',
      autoCalculate: 'auto calculate. Are you sure?',
      orderWithoutQuotation: 'order without quotation. Are you sure?',
      completeInspection: 'complete inspection. Are you sure?',
      completeStocking: 'complete stocking. Are you sure?',
      sendCertificate: 'auto issue certificate. Are you sure?',
      UploadAndSendDeliveryNote:
        'delivery item upload and auto issue delivery note. Are you sure?',
      UploadAndSendOrderReceipt:
        'purchase order upload and auto issue order receipt. Are you sure?',
      percussion: 'order percussion. Are you sure?',
      withdrawal: 'withdrawal. Are you sure?',
      orderPlacement: 'order placement. Are you sure?',
      approveWorkflowFlag:
        'Cannot Uncheck the checkbox when there are values in the flag required fields',
    },
    validationMessage: {
      invalidFileTypeOnlyAllowedDoc: 'Invalid FileType Only Allowed Doc',
      minSheetCount: 'Min Sheet Count',
      field: 'Field',
      filledAllRequiredField: 'Filled All Required Field',
      required: 'Required',
      email: 'Please enter a valid email',
      selectOneTranslator: 'Please select at least one translator',
      minimumChar: 'Must have at least',
      maxChar: 'Can have at most',
      invalidUrl: 'Please Enter A Valid URL',
      atLeast2CharAfterDecimal:
        'Domain should have at least two characters after the dot',
      atMost3CharAfterDecimal: 'There can be at most 3 digits after decimal',
      passwordNotMatch: 'Password Not Match',
      costSharingRequired1:
        'production_order_no1 is required when flag is true',
      costSharingRequired2:
        'production_order_no2 is required when flag is true',
      costSharingRequired: 'production_order_no is required when flag is true',
      numericInput: 'Please enter a numerical value',
    },
    customAlertMessage: {
      processSuccess: 'Process Success',
      cancelSuccess: 'Cancel Successfully',
      usermadeNotCreated: 'Usermade Not Created',
      pleaseSelectOnlyOneLanguage: 'Please Select Only One Language',
      invalidFileType: 'Invalid File Type',
      previewDataFailed: 'Preview Data Failed',
      pleaseSelectJobDetail: 'Select Job Detail First',
      createSuccess: 'Created Successfully',
      createFail: 'Failed To Create',
      updateSuccess: 'Updated Successfully',
      updateFail: 'Failed To Update',
      deleteSuccess: 'Deleted Successfully',
      deleteFail: 'Failed To Delete',
      gettingFail: 'Getting Data Failed',
      createQuotationMsg: 'Create Quotation First',
      selectOne: 'Please Select One Of The Checkbox First',
      doesntExist: 'There is no File Present To Download',
      uploadValidation: 'アップロードするファイルを選択してください。',
      pleaseSelectOnlyOneData: 'Please Select Only One Data',
      sendMailSuccess: 'Mail Send Successfully',
      sendMailFail: 'Failed To Send Mail',
      sendAcceptanceSuccess: 'Acceptance Completed',
      sendAcceptanceFail: 'Acceptance Failed',
      createOrderReceiptFirst: 'create order receipt first',
      caseIdNotFound: 'case id not found',
      addQuotationFirst: 'add quotation first',
      addJobTaskFirst: 'add job task first',
      purchaseOrderAmountNotFound: 'purchase order amount not found',
      addTaskFirst: 'add task first',
      getJobIdFirst: 'get job id first',
      selectCheckboxFirst: 'select checkbox first',
      noInvoiceExistsForThisJobDetail: 'no invoice exists',
      firstOrderReceiptSend: 'first order receipt send',
      completeCaseEdit: 'case is complete.',
      failedToExport: 'Failed to download file.',
      downloadSuccess: 'File downloaded successfully',
      getJobTaskId: 'Get Job Task Id',
    },
    authMessage: {
      loginFail: 'Login Failed',
      resetPasswordSuccess: 'Mail Sent Successfully',
      resetPasswordFail: 'User Not Found',
      confirmPasswordSuccess: 'Password Changed Successfully',
      confirmPasswordFail: 'Password Change Failed',
      changePasswordSuccess: 'Password Changed Successfully',
      changePasswordFail: 'Password Change Failed',
      authFail: 'Authentication Failed, Please Login.',
    },
    moduleName: {
      task: {
        create: 'Add Job task',
        edit: 'Edit Job task',
        delete: 'Delete Job task',
      },
      job: {
        create: 'Add Job',
        edit: 'Edit Job',
        delete: 'Delete Job',
      },
      project: {
        create: 'Add Project',
        edit: 'Edit Project',
        delete: 'Delete Project',
      },
      projectForClient: {
        create: 'Add Project for client',
        edit: 'Edit Project for client',
        delete: 'Delete Project for client',
      },
    },
    taskList: {
      certificateIssue: 'Issue Certificate',
      searchCondition: 'Search Condition',
      clearCondition: 'Clear Condition',
      search: 'Search',
      taskInformation: 'Task Information',
      job: 'Job',
      orderDate: 'Order Date',
      subContractor: 'Sub Contractor',
      orderAmount: 'Order Amount',
      wordCount: 'Word Count',
      rate: 'Rate',
      deliveryDate: 'Delivery Date',
      stockingDate: 'Stocking Date',
      dateRecorded: 'Recorded Date ',
      operationManager: 'Operation Manager',
      trados: 'Trados',
      readability: 'Readability',
      jobTask: 'Job Task',
      ownWorkFlow: 'Own WorkFlow',
      clientWorkFlow: 'Client WorkFlow',
      issuePurchase: {
        deliveryDate: 'Delivery Date',
        translator: 'Translator',
        purchaseOrderDate: 'Purchase Order Date',
        purchaseIssue: 'Issue Purchase',
        your: 'Your',
        purchase_order_no: 'Purchase Order No',
        purchase_order_no_seq: 'Purchase Order No Seq',
        quotation_date: 'Quotation Date',
        due_date: 'Due Date',
        jobNo: 'Job Number',
        subject: 'Subject',
        estimateDate: 'Estimate Date',
        amountIncTax: 'Amount: Yen (Including tax)',
        appDeliveryDate: 'Appointed Day Of Delivery',
        paymentTerms: 'Payment Terms',
        effictiveDate: 'Effective Date',
        language: 'Translation Language',
        company: 'Company',
        title: 'Title',
        remarks: 'Remarks',
        tableItems: {
          jobNo: 'Job No',
          desiredDeliveryDate: 'Desired Delivery Date',
          taxExcludedPrice: 'Tax Excluded Price',
          taxIncludedPrice: 'Tax Included Price',
          tableTitle: 'Add Purchase Issue',
          name: 'Project',
          content: 'Content',
          qty: 'Quantity',
          unit: 'Unit',
          salesTax: 'Sales Tax',
          unitCost: 'Unit Cost',
          amount: 'Amount',
          operate: 'Operate',
          subTotal: 'Sub Total',
          yen: 'Yen (Including tax)',
          taxAmount: 'Tax Amount',
        },
      },
      issueCertificate: {
        caseId: 'Case Id',
        jobId: 'Job Id',
        issueDate: 'Issue Date',
        inspectionDate: 'Inspection Date',
        issueInspectionCertificate: 'Issue Inspection Certificate',
        certificate_no: 'Certificate No',
        certificate_no_seq: 'Certificate No Seq',
        company: 'Company',
        title: 'Title',
        jobNo: 'Job Number',
        language: 'Translation Language',
        paymentTerms: 'Payment Terms',
        remarks: 'Remarks',
        withdrawal_flag: 'Withdrawl Flag',
        invalid_flag: 'Invalid Flag',
        addIssueCertificate: {
          tableTitle: 'Add Issue Certificate',
          totalAmount: 'Total Amount',
          unitCost: 'Unit Cost',
          unit: 'Unit',
          salesTax: 'Sales Tax',
          operate: 'Operate',
          quantity: 'Quantity',
          content: 'Item Name',
          projectName: 'Section',
        },
      },
    },
    appDropZoneCommon: {
      title: ' Drag and drop a file here or click to choose a file',
      size: 'Up to 50MB',
      fileSizeShouldBeUpTo: 'File size should be up to',
      fileTypeShouldBe: 'File type should be',
      invalidFileTypeOrSize: 'Invalid file type or size.',
    },
    menuName: {
      projectList: 'project list',
      quotationList: '①quotation list',
      completeQuotationList: '②complete quotation list',
      orderList: '③order list',
      taskStateList: 'task state list',
      inspectionTaskStateList: 'inspection task state list',
      waitingBillList: 'waiting bill list',
      completeDeliveryList: '④complete delivery list',
      jobList: 'Job list',
      billableList: '⑤billable list',
      exportInvoiceDataList: 'export invoice data list',
      billedList: '⑥billed list',
      projectCreate: 'project create',
      clientProjectCreate: 'client project create',
      projectListClient: 'project list client',
      workflowTest: 'workflow test',
    },
    uploadRecept: {
      preview: 'Preview',
      orderReceipt: {
        name: 'Order Receipt Upload',
        company: 'Company',
        issueDate: 'issue date',
        amount: 'amount',
      },
      invoiceUpload: {
        name: 'Invoice Upload',
        issuedTo: 'Issued To',
        publisher: 'Publisher',
        issueDate: 'Issue Date',
        amount: 'Amount',
      },
      purchaseOrderUpload: {
        name: 'Purchase Order Upload',
        company: 'Company',
        purchaseOrderNo: 'Purchase Order No',
        dateRecorded: 'Date Recorded',
        issueDate: 'Issue Date',
        publisher: 'Publisher',
        amount: 'Amount',
      },
      usermadeInvoiceUpload: {
        name: 'Usermade Invoice Upload',
        company: 'Company',
        issueDate: 'Issue Date',
        amount: 'Amount',
        taxAmount: 'tax amount',
        taxExcludedAmount: 'tax excluded amount',
      },
      translatorInvoiceUpload: {
        certificateNo: 'Certificate No',
        name: 'Translator Invoice Upload',
        issueDate: 'Issue Date',
        historySeq: 'History Sequence',
        translator: 'Translator',
        amount: 'Amount',
        recordedDate: 'recorded date',
      },
      deliveryItemUpload: {
        name: 'Delivery Item Upload',
        message: 'message',
      },
      certificateUpload: {
        name: 'Certificate Upload',
        issueDate: 'Issue Date',
        company: 'Company',
        amount: 'Amount',
        recordedDate: 'recorded date',
        productionOrderNo1: 'Production Order No1',
        costSharingDepartmentCode1: 'costSharingDepartmentCode1',
        productionOrderNo2: 'Production Order No2',
        costSharingDepartmentCode2: 'Cost Sharing Department Code2',
        certificateNo: 'certificate No',
        discardFlag: 'discard Flag',
      },
      fields: {
        seq: 'Sequence',
      },
      invoiceHq: {
        name: 'Invoicehq',
        company: 'Company',
        issueDate: 'Issue Date',
        amount: 'Amount',
        taxAmount: 'Tax Amount',
        taxExcludedAmount: 'Tax Excluded Amount',
        invoiceNo: 'Invoice No',
      },
    },
    exportInvoiceDataList: {
      columns: {
        dlDate: 'DL Date',
        dlUser: 'DL User',
        invoiceHq: 'Invoicehq',
        delete: 'Delete',
      },
    },
    projectList: {
      salesExport: 'Sales Export',
      confirmSalesExportMsg: 'Are you sure you want to download sales export',
      generalAffairsFlag: 'General Affairs Flag',
      approveWorkflowFlag: 'Approve workflow',
      useCCFlag: 'Use CC Flag',
      saleStatus: 'Sales Status',
      stockingStatus: 'Stocking Status',
      departmentToWhichRequester: 'Department To which Requester',
      contactPerson: 'Contact Person',
      searchCondition: 'Search Condition',
      departmentResponsibleFor: 'Department Responsible For',
      nameOfShimadzuAdcom: 'Name of Shimadzu Adcom',
      includeDeletedMatters: 'Include Deleted Matters',
      desiredDeliveryDate: 'Desired Delivery Date',
      desiredDate: 'Desired Date',
      estimatedAmountFrom: 'Estimated AmountFrom',
      estimatedAmountTo: 'Estimated AmountTo',
      orderNumber: 'Order Number',
      freeDescription: 'Free Description',
      agencyProjectCreate: 'Agency Project Create',
      export: 'Export',
      approveDepartment: 'Approve Department',
      csvExportFileName: 'Case Details',
      columns: {
        companyAbbreviation: 'Abbreviation',
        generalAffairsFlag: 'General Affairs Flag',
        salesDepartment: 'Sales Department',
        orderReceiptTaxSubTotalSubTotalAmount:
          'Order Receipt Tax SubTotal SubTotal Amount',
        deliveryNoteDeliveryDate: 'Delivery Note Delivery Date',
        certificateCompletionDate: 'Certificate Completion Date',
        salesType: 'Sales Type',
        duplicateBtn: 'Copy Case',
        approverDepartmentName: 'Approver Department Name',
        approverDepartmentNamePlaceholder: '承認フローに必要です。',
        approverInnerLine: 'Approver Inner Line',
        approverInnerLinePlaceholder: '承認フローに必要です。半角',
        approverMail: 'approver Mail',
        approverMailPlaceholder: '承認フローに必要です。半角',
        approverContactPersonName: 'approver Contact Person Name',
        approverContactPersonNamePlaceholder:
          '承認フローに必要です。入力例：島津花子',
        costSharingDepartmentCode1: 'cost Sharing Department Code1',
        costSharingDepartmentCode1Placeholder: '見積に必要です。',
        productionOrderNo1: 'production Order No1',
        productionOrderNo1Placeholder: 'ある場合は入力(8桁)',
        costSharingDepartmentCode2: 'cost Sharing Department Code2',
        costSharingDepartmentCode2Placeholder: 'ある場合のみ(4桁)',
        productionOrderNo2: 'production Order No2',
        productionOrderNo2Placeholder: 'ある場合は入力(8桁)',
        detailCode: 'detail Code',
        detailCodePlaceholder: '見積に必要です。',
        ownContactPersonUser: 'own Contact PersonUser',
        invoiceNumber: 'Invoice No',
        salesNumber: 'Sales No',
        deliveryDate: 'Delivery Date',
        generalAffairsCertificateDate: 'General Affairs Certificate Date',
        invoiceNumberPlaceholder: 'Invoice No Placeholder',
        salesNumberPlaceholder: 'Sales No Placeholder',
        expenseType: 'expense Type',
        version: 'version',
        draftFlag: 'draft Flag',
        withoutQuotationFlag: 'without Quotation Flag',
        inspectionFlag: 'inspection Flag',
        statusBtnColumn: 'status',
        caseNo: 'case no',
        jobNo: 'job no',
        requestedDate: 'requested date',
        requestNo: 'request no',
        caseTitle: 'title',
        ownCaseTitle: 'title (inter)',
        ownCaseTitlePlaceholder: 'インター側で指定する件名を入力してください。',
        caseTitlePlaceholder:
          '一時保存に必要です。メールの件名となりますので特殊文字は使わないでください。',
        industryType: 'industry',
        desireDeliveryDate: 'desired delivery date',
        desireDeliveryDatePlaceholder: '見積に必要です。',
        orderReceiveDate: 'order receive date',
        requestDepartmentCode: 'Request Department code',
        requestDepartmentName1: 'Request Department name 1',
        requestDepartmentName1Placeholder:
          '一時保存に必要です。入力例：分析計測SCOE',
        requestDepartmentContactPersonName1:
          'Request Department contact person name 1',
        requestDepartmentContactPersonName1Placeholder:
          '一時保存に必要です。入力例：島津太郎',
        requestDepartmentInnerLine1: 'Request Department inner line 1',
        requestDepartmentInnerLine1Placeholder: '一時保存に必要です。',
        requestDepartmentMail1: 'Request Department mail 1',
        requestDepartmentMail1Placeholder: '一時保存に必要です。',
        requestDepartmentName2: 'Request Department name 2',
        requestDepartmentName2Placeholder: '入力例：分析計測SCOE',
        requestDepartmentContactPersonName2:
          'Request Department contact person name 2',
        requestDepartmentContactPersonName2Placeholder: '入力例：島津花子',
        requestDepartmentInnerLine2: 'Request Department inner line 2',
        requestDepartmentInnerLine2Placeholder: '半角',
        requestDepartmentMail2: 'Request Department mail 2',
        requestDepartmentMail2Placeholder: '半角',
        requestDepartmentContactPersonName3:
          'Request Department contact person name 3',
        requestDepartmentContactPersonName3Placeholder: '入力例：島津太郎',
        requestDepartmentInnerLine3: 'Request Department inner line 3',
        requestDepartmentInnerLine3Placeholder: '半角',
        requestDepartmentMail3: 'Request Department mail 3',
        requestDepartmentMail3Placeholder: '半角',
        requestDepartmentName3: 'Request Department name 3',
        requestDepartmentName3Placeholder: '入力例：分析計測SCOE',
        PurchaseOrderFile: 'Purchase order file',
        PurchaseOrderNo: 'Purchase order no',
        status: 'Ordered',
        company: 'Company',
        companyPlaceholder: '一時保存に必要です。',
        requestDepartmentAffiliated1: 'Request Department Affiliated1',
        requestDepartmentAffiliated1Placeholder: '一時保存に必要です。',
      },
    },
    projectDetails: {
      certificate: 'Certificate',
      invoice: 'Invoice',
      autoCalculateTable: 'Auto Calculate Table',
      showClientItem: 'Show Client Item',
      hiddenClientItem: 'Hidden Client Item',
      completeCertificate: 'Complete Certificate',
      hiddenRequestFile: 'Hidden Request File',
      showRequestFile: 'Show Request File',
      hiddenReferenceFile: 'Hidden Reference File',
      showReferenceFile: 'Show Request File',
      layout: 'Layout',
      layoutPlaceholder: '自動計算に必要です。',
      updateQuotation: 'Update Quotation',
      createQuotation: 'Create Quotation',
      orderConfirmation: 'Order Confirmation',
      showArtifact: 'create artifact',
      artifactUpload: 'Artifact Upload',
      certificateUpload: 'Certificate Upload',
      showDeliveryNote: 'show delivery note',
      deliveryReport: 'Delivery Report Note',
      showOrderReceipt: 'show order receipt',
      orderReceipt: 'issue Order Receipt',
      orderPlacement: 'Order Placement',
      showPurchaseOrder: 'show purchase order',
      productName: 'Product Name',
      document: 'Document',
      documentPlaceholder: '自動計算に必要です。',
      work: 'Work',
      workPlaceholder: '自動計算に必要です。',
      orderPlacementModalTitle:
        'Are you sure you want you want to automatically issues an order receipt',
      exportRestriction: 'Export Restriction',
      exportRestrictionPlaceholder: '見積に必要です。',
      translationLanguage: 'Translation Language',
      translationLanguagePlaceholder: '自動計算に必要です。',
      multipleSelectAllowed: 'Multiple Select Allowed',
      translationSubject: 'Translation Subject',
      translationSubjectPlaceholder: '自動計算に必要です。',
      customerInformation: 'Customer Information',
      quotationDate: 'Quotation date',
      requesterName: 'Requester Name',
      departmentToWhichThe: 'department To WhichThe',
      requestInformation: 'Request Information',
      requestTitle: 'Request Title',
      referenceTitle: 'Reference Title',
      remarks: 'request message',
      remarksPlaceholder: '400文字',
      formTitleName: {
        contactDetail1: 'Contact Detail 1',
        contactDetail2: 'Contact Detail 2',
        contactDetail3: 'Contact Detail 3',
        caseInfo: 'Case Info',
        clientItem: 'Client Item',
      },
      showCC: 'Show CC',
      hiddenCC: 'Hidden CC',
      addNewProjectDetails: 'Add New Project Details',
      deleteProjectDetails: 'Delete Project Details',
      copyCase: 'Copy Case',
      workFlowHistory: 'WorkFlow History',
      quotation: 'Quotation',
    },
    projectListClient: {
      issueDate: 'Issue Date',
      requester: 'Requester',
      deptReqBelongTo: 'Department Request Belong To',
      departmentResponsibleFor: 'Department Responsible For',
      contactPerson: 'Contact Person',
      includeDeletedMatters: 'Include Deleted Matters',
      estimatedAmountFrom: 'Estimated Amount From',
      estimatedAmountTo: 'Estimated Amount To',
      orderNumber: 'Order Number',
      freeDescription: 'Free Description',
      desiredDate: 'Desired Date',
    },
    quotationRequestList: {
      columns: {
        status: 'status',
        purchaseWithoutQuotation: 'order without quotation',
        quotationRequestedDateTime: 'quotation request date time',
        quotationRequestNumber: 'quotation request number',
        caseTitle: 'title',
        requestCompany: 'company',
        requestDepartmentName: 'request department',
        requestContactPersonName: 'request contact person name',
        desiredDeliveryDate: 'desired delivery date',
        quotationState: 'Quotation State',
      },
    },

    completeQuotationList: {
      columns: {
        status: 'status',
        requestDepartmentName1: 'Request Department Name1',
        requestDepartmentContactPersonName1:
          'Request Department Contact Person Name1',
        purchaseWithoutQuotation: 'purchase without quotation',
        quotationRequestedDateTime: 'quotation requested date time',
        quotationRequestNumber: 'quotation request number',
        caseTitle: 'title',
        requestCompany: 'request company',
        requestDepartmentName: 'request department name',
        requestContactPersonName: 'request contact person name',
        desiredDeliveryDate: 'desired delivery date',
        quotation: 'quotation',
        orderReceipt: 'order receipt',
        openQuotation: 'open quotation',
        issueOrderReceipt: 'issue order receipt',
      },
    },

    quotationStateList: {
      columns: {
        status: 'Status',
        statusBtn: 'quotation state',
        orderReceivedDate: 'Order Received Date',
        requestNumber: 'Request Number',
        approverMail: 'Approver Mail',
        caseTitle: 'Title',
        requestDepartmentName1: 'Request Department Name 1',
        requestDepartmentMail1: 'Request Department Mail 1',
        requestContactPersonName1: 'Request Contact Person Name 1',
        requestInnerLine: 'Request Inner Line',
        desiredDeliveryDate: 'Desired Delivery Date',
        forwardingInvoiceDateTime: 'Forwarding Invoice Date Time',
        quotation: 'Quotation',
      },
    },

    inspectionTaskStateList: {
      caseNo: 'Case No',
      jobNo: 'Job No',
      freeDescription: 'Free Description',
      inspectionDateFrom: 'Inspection Date From',
      inspectionDateTo: 'Inspection Date To',
      certificateCompletionDateFrom: 'Certificate Completion Date From',
      certificateCompletionDateTo: 'Certificate Completion Date To',
      desiredDate: 'Desired Date',
      translator: 'Translator',
      columns: {
        issueCertificate: 'Issue Certificate',
        issueCertificateBtn: 'Create Issue Certificate',
        quotationRequestNumber: 'quotation request number',
        title: 'title',
        jobNo: 'job no',
        orderAmount: 'order amount',
        deliveryDate: 'delivery date',
        deliveryContactPersonName: 'delivery contact person name',
        completeInspectionDate: 'complete inspection date',
        statusBtnColumn: 'status',
        certificateDetailDraftTotalTaxIncludedPrice:
          'Detail Draft Tax Included Price',
      },
    },

    waitingBillList: {
      columns: {
        certificateNo: 'Certificate No',
        certificateDate: 'Certificate Date',
        issueDate: 'Issue Date',
        company: 'Company',
        title: 'Title',
        invoice: 'Invoice',
        invoiceUploadConfirmationFlag: 'Invoice Upload Confirmation Flag',
      },
    },

    orderList: {
      columns: {
        purchaseWithoutQuotation: 'purchase without quotation',
        quotationRequestNumber: 'quotation request number',
        jobNo: 'JOB number',
        caseTitle: 'title',
        requestCompany: 'request company',
        requestDepartmentName: 'request department name',
        requestContactPersonName: 'request contact person name',
        desireDeliveryDate: 'desire delivery date',
        quotation: 'quotation',
        orderReceipt: 'order receipt',
        orderReceiveDate: 'order receive date',
        statusBtnColumn: 'status',
      },
    },

    deliverableList: {
      columns: {
        status: 'status',
        purchaseWithoutQuotation: 'purchase without quotation',
        requestedDateTime: 'requested date time',
        requestNumber: 'request number',
        caseTitle: 'title',
        requestDepartmentName: 'request department name',
        requestContactPersonName: 'request contact person name',
        requestInnerLine: 'request inner line',
        quotation: 'Quotation',
        purchaseOrder: 'Purchase Order',
        orderReceipt: 'Order Receipt',
        deliveryItemUpload: 'Delivery Item Upload',
        deliveryNoteApprove: 'Delivery Note Approve',
      },
    },

    billedList: {
      columns: {
        caseNo: 'Request Quotation Number',
        caseTitle: 'Title',
        requestCompanyName: 'Request Company Name',
        requestDepartmentName1: 'Request Department Name',
        requestContactPersonName: 'request contact person name',
        jobNo: 'JOB number',
        deliveryContactPersonName: 'Delivery Contact Person Name',
        deliveryDate: 'Delivery date',
        completeInspectionDate: 'complete inspection date',
        invoiceDate: 'invoice date',
        excludingAmount: 'amount',
        statusBtnColumn: 'status',
        invoice: 'Invoice',
      },
    },

    deliveryCompleteList: {
      completeCertificate: 'Complete Certificate',
      columns: {
        requestNumber: 'Request Number',
        jobNo: 'Job Number',
        caseTitle: 'Case Title',
        requestCompany: 'Request Company Name',
        requestDepartmentName1: 'Request Department Name 1',
        requestContactPersonName1: 'Request Contact Person Name 1',
        deliveryDate: 'Desired Delivery Date',
        deliveryContactPersonName: 'delivery contact person name',
        inspectionFlag: 'Inspection Flag',
        statusBtnColumn: 'status',
      },
    },

    billableList: {
      fileName: 'Billable List',
      columns: {
        certificateUpload: 'Certificate Upload',
        requestedDate: 'Requested Date',
        requestCompanyName: 'Request Company Name',
        desiredDeliveryDate: 'Desired Delivery Date',
        requestDepartmentContactPersonName1:
          'requestDepartmentContactPersonName1',
        subject: 'Subject',
        caseNo: 'Case Number',
        caseTitle: 'Case Title',
        jobNo: 'Job Number',
        deliveryDate: 'Delivery Date',
        statusBtnColumn: 'status',
      },
    },

    projectListForClient: {
      columns: {
        requestDepartmentName1: 'Request Department Name1',
        requestCompany: 'Request Company',
        requestDepartmentContactPersonName1:
          'Request Department Contact Person Name1',
        quotationRequestedDateTime: 'Quotation Requested Date Time',
        quotationRequestNo: 'Quotation Request No',
        quotationPassiveDateTime: 'Quotation Passive Date Time',
        caseTitle: 'Title',
        orderDateTime: 'Order Date Time',
        jobNo: 'Job No',
        desireDeliveryDate: 'Desired Delivery Date',
        deliveryNoteAcceptanceDate: 'Acceptance Date',
        completeInspectionDate: 'Complete Inspection Date',
        statusBtnColumn: 'status',
        ownContactPersonUser: 'Own Contact Person User',
      },
    },
    jobDetails: {
      unitPrice: 'Unit Price',
      taskTime: 'Task Time',
      taskType: 'Task Type',
      sellingPrice: 'Selling Price',
      totalCost: 'Total Cost',
      jobInformation: 'Job Information',
      jobNo: 'Job No',
      recyclingNo: 'Recycling Number',
      caseId: 'Case Id',
      invoiceId: 'Invoice Id',
      detailNo: 'Detail No',
      subject: 'Subject',
      orderAmount: 'Order Amount',
      pageCount: 'Page Count',
      wordCount: 'Word Count',
      rate: 'Rate',
      sheetCount: 'Sheet Count',
      sheetCountPlaceholder: 'レイアウト有の場合、自動計算に必要です。',
      layout: 'Layout',
      desiredDeliveryDate: 'Desired Delivery Date',
      deliveryDate: 'Delivery Date',
      invoicingFlag: 'Invoicing Flag',
      delete_flag: 'Delete Flag',
      accountsReceivableMonth: 'accounts Receivable Month',
      jobTask: {
        accPayableMonth: 'Accounts Payable Month',
        orderDate: 'Order Date',
        translator: 'Translator',
        task: 'Task',
        translateLanguage: 'Translate Language',
        orderAmt: 'Order Amount',
        orderAmtCost: 'Order Amount Cost',
        orderAmt10Oer: 'Order Amount 10per',
        wordCount: 'Word Count',
        rate: 'Rate',
        deliveryDate: 'Delivery Date',
        stockingDate: 'Stocking Date',
        dateRecorded: 'Date Recorder',
        operationManager: 'Operation Manager',
        trados: 'Trados',
        readibility: 'Readibility',
        remarks: 'Remarks',
        cancelFlag: 'Cancel Flag',
      },
    },
    jobList: {
      name: 'Job List',
      searchCondition: 'search condition',
      stockingExportMessage: 'stocking export. Are you sure?',
      requester: 'Requester',
      deptReqBelongTo: 'Department To Which The Request Belongs',
      search: 'Search',
      clearConditions: 'Clear Conditions',
      create: 'Create new Job',
      export: 'Export',
      csvExportFileName: 'Job Details',
      paymentSchedule: 'Create Payment Schedule',
      bulkInspectionCertificate: 'Bulk Inspection Certificate',
      bulkInspectionCertificateModalTitle:
        'Are you sure you want to send the Inspection Certificate to the (X) selected translators?',
      sendPaymentScheduleModalTitle:
        'Are you sure you want to send the Inspection Certificate to the (X) selected translators?',
      issueInvoiceData: {
        btnName: 'Issue Invoice Data',
        exportBtn: 'CSV export',
        cardTitle: 'Invoice Data',
        tableItems: {
          deptCode: 'Department Code',
          deptName: 'Department Name',
          invoiceAmt: 'Invoice Amount',
          selectReqNo: 'Select Request Number',
          title: 'Title',
          total: 'Total',
          totalAmount: '41,000(Excluding tax)',
        },
      },
      columns: {
        statusBtnColumn: 'Status',
        requestedDate: 'Requested date',
        requestNo: 'Request number',
        desireDeliveryDate: 'Desired delivery date',
        orderReceiveDate: 'Order Receive Date',
        jobNo: 'Job Number',
        reqDepartmentName: 'Request Department name',
        requestDepartmentContactPersonName:
          'Request Department contact person name',
        title: 'Title',
        orderReceiveAmount: 'Order Receive Amount',
        interDesiredDeliveryDate: 'Inter Desired Delivery Date',
        paymentScheduleBtnColumn: 'Payment Schedule',
        deliveryDate: 'Delivery date',
        inspectionDate: 'Inspection Date',
        orderDate: 'Order date',
        subContractor: 'Subcontractor',
        orderAmt: 'Order Amount',
        stockingDate: 'Stocking date',
        oprMngr: 'Operation Manager',
        duplicateTaskBtnLabel: 'Copy Job Detail',
        caseTitle: 'Case Title',
        invoiceId: 'Invoice Id',
        orderNo: 'Order No',
        requestDepartment: 'Request Department',
        invoiceBtnColumn: 'Invoice',
        status: 'Ordered',
        translatorMatchingBtnColumn: 'Translatory Matching',
        reqDeptName1: 'Request Department Name 1',
      },
      taskColumns: {
        duplicateBtn: 'Copy',
        duplicateTaskLabel: 'Copy Task',
        subject: 'Subject',
        orderDate: 'Order Date',
        transratorId: 'Last Name',
        transrateLanguageId: 'Transrate Language Name',
        taskId: 'Task name',
        orderAmount: 'Order Amount',
        remarks: 'Remarks',
        insCertFlag: 'Inspection Certificate Flag',
        cancelBtn: 'Cancel',
        translator: 'Translator',
        PurchaseOrdBtn: 'Purchase Order',
        createPurchaseOrdBtn: 'Create Purchase Order',
        createPurchaseAfterAmountChangeBtn:
          'Create purchase after amount change',
        insCert: 'Inspection Certificate',
        invoiceBtn: 'Invoice',
        paymentScheduleBtn: 'Payment Schedule',
      },
      collapsableColumn: {
        name: 'Task',
        check: 'Check',
        orderDate: 'Order Date',
        transratorId: 'Transrator Id',
        transratorLangId: 'Transrate Language Id',
        taskId: 'Task Id',
        orderAmt: 'Order Amount',
        remarks: 'Remarks',
        insCertFlag: 'Inspection Certificate Flag',
        cancel: 'Cancel',
        purchaseOrder: 'Purchase Order',
        insCertificate: 'Inspection Certificate',
        invoice: 'Invoice',
      },
    },
    quotation: {
      name: 'Issue Quotation',
      proposalNumber: 'Purposal Number',
      caseNo: 'Case No',
      quotationNo: 'Quotation Number',
      discardFlag: 'Discard Flag',
      quotationNoSeq: 'Quotation No Seq',
      quotationDate: 'Quotation Date',
      title: 'Title',
      dueDate: 'Due Date',
      productionOrderNo: 'Production Order No',
      remarks: 'Remarks',
      issueFlag: 'Issue Flag',
      withdrawalFlag: 'Withdrawal Flag',
      invalidFlag: 'Invalid Flag',
      job: 'Job',
      case: 'Case',
      orderReceipt: 'Order Receipt',
      company: 'Company',
      costSharingDeptCode: 'Cost Sharing Department Code',
      exportRestriction: 'Export Restriction',
      detailCode: 'Detail Code',
      amountIncTax: 'Amount: Yen (Including tax)',
      yen: 'Yen (Including tax)',
      subtotal: 'Subtotal',
      quotationValidityPeriod: 'Quotation Validity Period',
      deliveryStyle: 'Delivery Style',
      desiredDeliveryData: 'Desired delivery date',
      mediaName: 'Media Name',
      plateTypeSize: 'Plate type/size',
      manuScriptFormat: 'Manuscript format',
      adName: ' Advertisement name',
      dummyPlateType: 'Dummy plate type',
      originalColor: 'Original color',
      select: 'select',
      tableItems: {
        tableTitle: 'Add Quotation',
        content: 'Item Name',
        quantity: 'Quantity',
        unitCost: 'Unit Cost',
        salesTax: 'Sales Tax',
        taxAmount: 'Tax Amount',
        amount: 'Total Amount',
      },
    },
    history: {
      title: 'History For Change',
      updateDate: 'Update Date',
      updateUser: 'Update User',
      operation: 'Operation',
      reasonForChange: 'Reason For Change',
      PDFBeforeChange: 'PDF Before Change',
    },
    workFlow: {
      approvalWorkFlow: 'Approval WorkFlow',
      approvalHistory: 'Approval History',
      applicator: 'Applicator',
      approver: 'Approver',
      comment: 'Comment',
      applicate: 'Applicate',
      approve: 'Approve',
      reject: 'Reject',
      unApplicate: 'UnApplicate',
      cancel: 'Cancel',
      tableItems: {
        userName: 'User Name',
        status: 'Status',
        comment: 'Comment',
        processingData: 'Processing Data',
      },
      status: {
        APPLICATED: 'Applicated',
        APPROVED: 'Approved',
        REJECTED: 'Rejected',
        CANCELLED: 'Cancelled',
        APPROVINGREQUEST: 'Approving Request',
        ORDER: 'Order',
      },
      useEmail: 'Use Email',
    },
    purchaseAfterAmountChange: {
      title: 'Purchase After Amount Change',
      legendTitle: 'Add Delivery Date',
      jobNo: 'job number',
      translatorId: 'translator name',
      remarks: 'Remarks',
      deliveryDate: 'Delivery date',
      stockingDate: 'stocking date',
      purchaseOrderDate: 'purchase order date',
      addAttachments: 'Add',
      tableItems: {
        section: 'Section',
        itemName: 'Item Name',
        quantity: 'Quantity',
        unit: 'Unit',
        unitCost: 'Unit Cost',
        salesTax: 'Sales Tax',
        totalAmount: 'Total Amount',
      },
    },
    issueInvoice: {
      certificateDate: 'Certificate Date',
      invoiceNoSeq: 'Invoice No Seq',
      paymentTermsId: 'Payment Terms',
      translationExpiration: 'Translation Expiration',
      company: 'Company',
      your: 'Your',
      name: 'Invoice',
      looks: 'Looks',
      invoiceNumber: 'Invoice Number',
      invoiceDate: 'Invoice Date',
      totalAmount: 'Total Amount (Tax included)',
      subject: 'Subject',
      language: 'Language',
      deliveryDate: 'Delivery Date',
      jobNo: 'Job No',
      quotationNo: 'quotation No',
      preview: 'Preview',
      withdrawalFlag: 'Withdrawal Flag',
      issueFlag: 'Issue Flag',
      addIssueInvoice: {
        section: 'Section',
        itemName: 'Item Name',
        unit: 'unit',
        unitCost: 'Unit Cost',
        salesTax: 'Sales Tax',
        amount: 'Amount',
        tableTitle: 'Add Issue Invoice',
        totalAmount: 'Total Amount',
        operate: 'Operate',
        quantity: 'Quantity',
        content: 'Content',
        projectName: 'Project Name',
      },
      tableItems: {
        productName: 'Product',
        quantity: 'Quantity',
        unit: 'Unit',
        unitCost: 'unit cost',
        amount: 'Amount',
      },
      subtotal: 'Subtotal',
      totalOrderAmount: 'Total Order Amount',
      consumptionTax: 'Consumption tax',
      total: '10% Total',
      bankNameLabel: 'Bank name',
      bankName: 'Mitsubishi Bank',
      branchNameLabel: 'Branch name',
      branchName: 'Kyoto Central Branch',
      depositTypeLabel: 'Deposit typelabel',
      depositType: 'Checking Account',
      accountLabel: 'account',
      account: '238432',
      accountHolderNameLabel: 'Account Holder name',
      accountHolderName: 'Shimazu Int',
      paymentDueDateLabel: 'Payment due Date',
      paymentDueDate: '2023/02/01',
      noteLabel: 'Note:',
      note: 'Please bear the bank transfer fee at your company.',
    },
    orderReceiptIssuance: {
      certificateDate: 'Certificate Date',
      caseNo: 'Case No',
      jobNo: 'Job No',
      paymentDate: 'Payment Date',
      acceptanceDate: 'Acceptance Date',
      purchaseOrderNo: 'Purchase Order No',
      name: 'Order Receipt Issuance',
      orderReceiptNumber: 'Order Receipt Number',
      orderReceiptDate: 'Order Receipt Date',
      quotationDate: 'Quotation Date',
      issueDate: 'Issue Date',
      quotationNo: 'Quotation No',
      quotationNoSeq: 'Quotation No Seq',
      company: 'Company',
      title: 'Title',
      translationLanugage: 'Translation Language',
      deliveryStyle: 'Delivery Style',
      dueDate: 'Due Date',
      translationExpiration: 'translation Expiration',
      paymentTerms: 'payment Terms',
      remarks: 'remarks',
      withdrawalFlag: 'Withdrawal Flag',
      costSharingDepartment: 'Cost Sharing Department',
      productionOrderNo: 'Production Order No',
      addOrderReceipt: {
        tableTitle: 'Add Order Receipt',
        totalAmount: 'Total Amount',
        unitPrice: 'Unit Price',
        operate: 'Operate',
        quantity: 'Quantity',
        content: 'Content',
        projectName: 'Project Name',
      },
      tableItems: {
        section: 'section',
        itemName: 'Item Name',
        quantity: 'Quantity',
        unit: 'unit',
        unitCost: 'unitCost',
        saleTax: 'saleTax',
        amount: 'amount',
      },
      subtotal: '3620',
      yen: 'Yen',
    },
    translatorMatchingScreen: {
      pageCount: 'Page Count',
      unitCost: 'Unit Cost',
      translationLanguage: 'Translation Language',
      readability: 'readability',
      task: 'Task',
      trados: 'Trados',
      orderAmount10per: 'Order Amount 10per',
      deliveryDate: 'deliveryDate',
      operationManager: 'Operation Manager',
      orderAmountCost: 'Order Amount Cost',
      wordCount: 'Word Count',
      rate: 'Rate',
      orderAmount: 'Order Amount',
      translatorLanguage: 'Translator Language',
      orderDate: 'Order Date',
      name: 'Translator Matching Screen',
      jobInfo: 'Translator',
      desiredDeliveryDate: 'Desired Delivery Date',
      desiredDeliveryDateValue: '2023-09-08',
      jobNo: 'Job No',
      jobNoValue: 'Job no. 1',
      subject: 'Subject',
      subjectValue: 'Subject 1',
      translatorListLabel: 'Translator',
      bodyEmail: 'Email body',
      addMsgEmail: 'Additional Message',
      richText: 'Rich Text',
      translatorFilter: 'Translator',
      tableItems: {
        status: 'Status',
        accountsPayableMonth: 'Accounts Payable Month',
        cancelFlag: 'Cancel Flag',
        deliveryDate: 'Delivery Date',
        operationManager: 'Operation Manager',
        orderAmount: 'Order Amount',
        orderAmount10per: 'Order Amount 10per',
        orderAmountCost: 'Order Amount Cost',
        orderDate: 'order Date',
        rate: 'Rate',
        stockingDate: 'stocking Date',
        trados: 'Trados',
        translationLanguage: 'Translation Language',
        translator: 'Translator',
      },
      subtotal: '3620',
      yen: 'Yen',
    },
    auth: {
      login: 'Login',
      emailAddress: 'Email Address',
      password: 'Password',
      changePassword: 'Change Password',
      confirmPassword: 'Confirm Password',
      oldPassword: 'Old Password',
      newPassword: 'New Password',
      confirmNewPassword: 'Confirm New Password',
      rememberMe: 'Remember me',
      pid: 'PID',
      submit: 'Submit',
      createNow: 'Create Now',
      dontHaveAcc: "Don't have an account?",
      send: 'Send',
      return: 'Return',
      forgotPassword: {
        msg: 'Forgot Password?',
        steps: 'Steps to follow',
        step1: 'Enter your PID below and submit.',
        step2: 'An email will be sent to your registered email address.',
        step3: 'Follow the instructions in the email to reset your password.',
      },
      resetPassword: 'Reset Password',
      register: {
        firstName: 'First Name',
        lastName: 'Last Name',
        register: 'Register',
        email: 'Email',
        tel: 'Tel',
      },
    },
    deliveryReportNoteApprove: {
      name: 'Delivery Report Note',
      deliveryNoteNo: 'Delivery Note No',
      deliveryNoteNoSeq: 'Delivery Note No Seq',
      companyId: 'Company Id',
      title: 'Title',
      detailCode: 'Detail Code',
      preview: 'Preview',
      send: 'Send',
      costSharingDepartmentCode: 'Cost Sharing Department Code',
      exportRestriction: 'Export Restriction',
      paymentTermId: 'Payment Term',
      ownWorkFlowId: 'Own Work Flow',
      clientWorkFlowId: 'Client Work Flow',
      completeInspection: 'Complete Inspection',
      caseNo: 'Case No',
      productionOrderNo: 'Production Order No',
      deliveryNoteDetail: {
        section: 'Section',
        itemName: 'Item Name',
        quantity: 'Quantity',
        unit: 'Unit',
        unitCost: 'Unit Cost',
        amount: 'Amount',
        salesTax: 'sales tax',
      },
      certificateComplete: {
        certificateComplete: 'Certificate Complete',
        title: 'Certificate Complete Confirm',
        costSharingDepartmentCode1: 'Cost Sharing Department Code1',
        costSharingDepartmentCode2: 'Cost Sharing Department Code2',
        productionOrderNo1: 'Production Order No1',
        productionOrderNo2: 'Production Order No2',
        expenseType: 'Expense Type',
        message: 'Message',
      },
    },
    commonDate: {
      issueDate: 'Issue Date',
      deliveryDate: 'Delivery Date',
      issueDateFrom: 'Issue Date from',
      issueDateTo: 'Issue Date To',
    },
    commonFinanceTerm: {
      subTotal: 'Sub Total',
      includingTax: 'Including Tax',
      excludingTax: 'Excluding Tax',
      currency: {
        yen: 'Yen',
      },
    },
    dummyData: {
      jobNo: '00021',
      translatorName: 'nihon taro',
      purchaseOrderDate: '2023/07/20',
      deliveryDate: '2023/08/31',
    },
    taskStateList: {
      jobDetail: 'Job Detail',
      deleteJobDetail: 'delete job detail',
      deliveryItemUpload: 'Delivery Item Upload',
      create: 'Create Task State',
      deptReqBelongTo: 'Department Request Belong To',
      requester: 'Requester',
      unitPrice: 'Unit Price',
      deliveryDate: 'Delivery Date',
      jobNo: 'Job No',
      taskTime: 'Task Time',
      taskType: 'Task Type',
      sellingPrice: 'Selling Price',
      totalCost: 'Total Cost',
      wordCount: 'Word Count',
      rate: 'Rate',
      sheetCount: 'Sheet Count',
      layout: 'Layout',
      invoicingFlag: 'Invoicing Flag',
      task: 'Job Task',
      accountsReceivableMonth: 'Accounts Receivable Month',
      bulkInspectionCertificateModalTitle: 'Bulk Inspection Certificate',
      columns: {
        caseOrderAmount: 'Order Amount',
        matchingCancel: 'Matching Cancel',
        caseNo: 'Case No',
        caseTitle: 'Case Title',
        orderReceiveDate: 'Order Receive Date',
        industryTypeName: 'Industry Type Name',
        requestDepartmentName1: 'Request Department Name1',
        requestDepartmentAffiliatedName1: 'Request Department Affiliated Name1',
        requestDepartmentContactPersonName1:
          'Request Department Contact Person Name1',
        desiredDeliveryDate: 'Desired Delivery Date',
        invoiceNo: 'Invoice No',
        company: 'company',
        purchaseOrder: 'Purchase Order',
        stocking: 'Stocking',
        totalCostRate: 'Total Cost Rate',
        costRate: 'Cost Rate',
        unitCost: 'Unit Cost',
        translatorLanguageName: 'Translation Language Name',
        task: 'Job Task',
        stockingDate: 'Stocking Date',
        inspectionDate: 'inspection date',
        duplicateJobDetail: 'Duplicate Job Detail',
        purchaseAfterAmountChange: 'Purchase After Amount Change',
        cancel: 'Cancel',
        completeInspection: 'Complete Inspection',
        inspectionCertificate: 'Inspection Certificate',
        translatorMatching: 'Translator Matching',
        orderPurchase: 'Order Purchase',
        addTask: 'Add Task',
        version: 'version',
        inspectionFlag: 'Inspection Flag',
        cost: 'cost',
        expense: 'expense',
        unitPrice: 'Unit Price',
        layout: 'layout',
        invoice: 'invoice',
        updateUser: 'update User',
        updateDate: 'Update Date',
        detailNo: 'detail No',
        subject: 'subject',
        orderAmount: 'Order Amount',
        pageCount: 'Page Count',
        wordCount: 'Word Count',
        rate: 'rate',
        sheetCount: 'Sheets Count',
        deliveryDate: 'Delivery Date',
        totalCost: 'Total Cost',
        sellingPrice: 'Selling Price',
        taskType: 'Task Type',
        taskTime: 'Task Time',
        jobNo: 'Job No',
        job: 'Job',
        caseDetail: 'Case Detail',
        duplicateBtn: 'Duplicate',
        statusBtnColumn: 'Status',
        accountsReceivableMonth: 'Accounts Receivable Month',
        translatorMatchingBtnColumn: 'Translator Matching',
        invoiceBtnColumn: 'Invoice',
        duplicateTaskBtnLabel: 'Duplicate Task',
        status: 'Status',
        industryType: 'Industry Type',
        accountsPayableMonth: 'Accounts Payable Month',
        orderDate: 'Order Date',
        translator: 'translator',
        task_inspection_flag: 'inspection flag',
        dateRecorded: 'Date Recorded',
        operationManager: 'Operation Manager',
        trados: 'trados',
        readability: 'readability',
        jobDetailWordCount: 'Job Detail Word Count',
        jobDetailDeliveryDate: 'Job Detail Delivery Date',
        invoiceNoUpload: 'Invoice Upload',
      },
      subColumns: {
        duplicateBtn: 'Duplicate',
        orderDate: 'Order Date',
        translateLanguageId: 'Transrate Language',
        taskId: 'Task Id',
        orderAmount: 'Order Amount',
        remarks: 'Remarks',
        cancelBtn: 'Cancel',
        PurchaseOrdBtn: 'Purchase Order',
        title: 'Title',
        insCert: 'Inspection Certificate',
        invoiceBtn: 'Invoice',
      },
    },
    globalConstant: {
      quotationReport: 'Quotation Report',
      deliveryNoteReport: 'Delivery Note Report',
      orderReceiptReport: 'Order Receipt Report',
      invoiceReport: 'Invoice Report',
      purchaseOrderReport: 'Purchase OrderReport',
      certificateReport: 'Certificate Report',
    },
    searchConditions: {
      date_from: 'Date From',
      date_to: 'Date To',
      amount_from: 'Amount From',
      amount_to: 'Amount To',
      workInProgress: 'Work In Progress',
      status: 'Status',
      caseTitle: 'Case Title',
      requestDepartmentName1: 'Request Department Name1',
      ownContactPersonUser: 'Own Contact Person User',
      caseNo: 'Case No',
      jobNo: 'Job No',
      requestDeptName1: 'Request Department Name1',
      requestDeptContactPersonName1: 'Request Department Contact Person Name1',
      desiredDeliveryDateFrom: 'Desired Delivery Date From',
      desiredDeliveryDateTo: 'Desired Delivery Date To',
      deliveryDateFrom: 'Delivery Date From',
      deliveryDateTo: 'Delivery Date To',
      certificateCompletionDateFrom: 'Certificate Completion Date From',
      certificateCompletionDateTo: 'Certificate Completion Date To',
      orderDateFrom: 'Order Date From',
      orderDateTo: 'Order Date To',
      taskDeliveryDateFrom: 'Task Delivery Date From',
      taskDeliveryDateTo: 'Task Delivery Date To',
      stockingDateFrom: 'Stocking Date From',
      stockingDateTo: 'Stocking Date To',
      inspectionDateFrom: 'Inspection Date From',
      inspectionDateTo: 'Inspection Date To',
    },
    autoCalculateTable: {
      quantity: 'Quantity',
      unitCost: 'Unit Cost',
      itemName: 'Item Name',
      amount: 'amount',
      taxAmount: 'Tax Amount',
    },
    workFlowHistory: {
      title: 'Approval History',
      columns: {
        caseNo: 'Case No',
        userFullname: 'Full Name',
        jobNo: 'Job No',
        processingDate: 'Processing Date',
        reportNo: 'Report No',
        reportType: 'Report Type',
        status: 'Status',
      },
      status: {
        Applicated: 'Applicated',
        Approved: 'Approved',
        Rejected: 'Rejected',
        Cancelled: 'Cancelled',
        Sent: 'Sent',
        ApproveRequested: 'Approve Requested',
        Ordered: 'Ordered',
      },
      reportName: {
        1: 'Quotation',
        2: 'Order Receipt',
        3: 'Translator Purchase Order',
        4: 'Translator Certificate',
        5: 'Invoice',
        6: 'InvoiceHQ',
      },
    },
    inputTrackRecord: {
      name: 'Input Track Record',
      totalExpense: 'Total Expense',
      cost: 'Cost',
      totalCost: 'Total Cost',
      sellingPrice: 'Selling Price',
      internalUnitPrice: 'Internal Unit Price',
      costRate: 'Cost Rate',
      totalCostRate: 'Total Cost Rate',
      tableItems: {
        contactPersonUser: 'Contact Person User',
        taskType: 'Task Type',
        time: 'Time',
        internalExpense: 'Internal Expense',
      },
    },
  },
}

export const taxAmount = (tax_amount: number) => {
  return `${'消費税('}${tax_amount}${'%)'}`
}

export default enTranslations
