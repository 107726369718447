import React, {
  Suspense,
  lazy,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import {
  Button,
  Card,
  CardHeader,
  Fab,
  Grid,
  Input,
  Tooltip,
  Typography,
} from '@mui/material'

import AttachFileIcon from '@mui/icons-material/AttachFile'
import DeleteIcon from '@mui/icons-material/Delete'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import LinkIcon from '@mui/icons-material/Link'

import { ErrorMessage, FieldArray, Formik } from 'formik'
import { v4 as uuidv4 } from 'uuid'
import * as Yup from 'yup'

import { handleFocusSaleTaxCommon } from '../../../../CommonAppRedux/commonAppFunctions'
import { alertErrorAction } from '../../../../CommonAppRedux/commonAppSlice'
import { commonAppSelector } from '../../../../CommonAppRedux/selector'
import { authSelector } from '../../../../Login/Redux/selector'
import AppDatePicker from '../../../../ReusableComponent/AppDatePicker/AppDatePicker'
import AppElementContainer from '../../../../ReusableComponent/AppElementContainer/AppElementContainer'
import AppTextField from '../../../../ReusableComponent/AppTextField/AppTextField'
import Comments from '../../../../ReusableComponent/Comments/Comments'
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../ReusableComponent/CustomHooks/appHooks'
import { useInputSearch } from '../../../../ReusableComponent/CustomHooks/useInputSearch'
import FocusElementOnInvalidValidation from '../../../../ReusableComponent/FocusElementIfInvalid/FocusElementOnInvalidValidation'
import History from '../../../../ReusableComponent/History'
import IssueBarComponent from '../../../../ReusableComponent/IssueBarComponent/IssueBarComponent'
import ReasonForChange from '../../../../ReusableComponent/ReasonForChange/ReasonForChange'
import SaveFooterComponent from '../../../../ReusableComponent/SaveFooterButton/SaveFooterButton'
import { dateFormatter } from '../../../../Utils/AppFormatFunction'
import { todayDate } from '../../../../Utils/AppFunction'
import messages from '../../../../Utils/ValidationMessage'
import { query } from '../../../../Utils/globalTypes'
import {
  clearHardResetJobList,
  clearJobDetailRow,
  clearJobTask,
  clearPurchaseChangeAmountById,
  clearPurchaseOrder,
  createPurchaseAmountChangeRequest,
  getJobDetailRowByIdRequest,
  getJobTaskByIdRequest,
  getJobTaskStockingDateByIdRequest,
  getPurchaseChangeAmountByIdRequest,
  getPurchaseOrderByIdRequest,
} from '../../Redux/jobListSlice'
import { jobListSelector } from '../../Redux/selector'
import { initialPurchaseAfterAmountChangeProps } from '../../Types'

//lazy

// import CommonReportTable from '../../../../ReusableComponent/CommonReportTable/CommonReportTable'
const CommonReportTable = lazy(
  () =>
    import('../../../../ReusableComponent/CommonReportTable/CommonReportTable')
)

const PurchaseAfterAmountChange = () => {
  const { t } = useTranslation()

  const { commonSalesTax, defaultSalesTax, commonTranslator } =
    useAppSelector(commonAppSelector)
  const {
    purchaseChangeAmountDetails,
    jobTaskStockingDateDetails,
    jobTask,
    loadingCreatePurchaseAmountChange,
    jobDetailRow,
  } = useAppSelector(jobListSelector)
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  // const id = searchParams.get('translator-purchase-order')
  const jobTaskId = searchParams.get('job-task-id')
  // const jobTaskNo = searchParams.get('job-task-no')
  const { state } = location
  const previousUrl = state?.path
  const dispatch = useAppDispatch()

  //all reducer action and clear function implement start

  useLayoutEffect(() => {
    handleFocusSaleTaxCommon(dispatch)
    if (jobTaskId) {
      dispatch(getJobTaskByIdRequest(jobTaskId))
    } else {
      dispatch(clearJobTask())
      dispatch(clearHardResetJobList())
    }
    return () => {
      dispatch(clearHardResetJobList())
    }
  }, [jobTaskId, location, dispatch])

  useLayoutEffect(() => {
    if (jobTask?.job_detail) {
      dispatch(getJobDetailRowByIdRequest(jobTask?.job_detail))
    } else {
      dispatch(clearJobDetailRow())
    }

    if (jobTask?.translator_purchase_order?.translator_purchase_order) {
      dispatch(
        getPurchaseChangeAmountByIdRequest(
          jobTask?.translator_purchase_order?.translator_purchase_order
        )
      )
    } else {
      dispatch(clearPurchaseChangeAmountById())
    }
  }, [jobTask, location, dispatch])

  //all reducer action and clear function implement End
  const taskJobNoDetailNo =
    (jobDetailRow?.job_no ?? '') + (jobDetailRow?.detail_no ?? '')
  const columns = [
    {
      id: 'job_no',
      label: `${t('taskList.issuePurchase.tableItems.jobNo')}`,
      minWidth: 150,
      disabled: true,
      // type: 'number',
    },
    {
      id: 'delivery_date',
      label: `${t('taskList.issuePurchase.tableItems.desiredDeliveryDate')}`,
      minWidth: 200,
      required: true,
      dateField: true,
    },
    {
      id: 'tax_excluded_price',
      label: `${t('taskList.issuePurchase.tableItems.taxExcludedPrice')}`,
      currency: true,
      minWidth: 150,
      // amount: true,
      taxExcludedPrice: true,
    },
    {
      id: 'tax_amount',
      label: `${t('taskList.issuePurchase.tableItems.taxAmount')}`,
      minWidth: 150,
      // amount: true,
      currency: true,
      taxAmount: true,
    },
    {
      id: 'tax_included_price',
      label: `${t('taskList.issuePurchase.tableItems.taxIncludedPrice')}`,
      currency: true,
      minWidth: 150,
      // amount: true,
      taxIncludedPrice: true,
    },
    {
      id: 'sales_tax',
      label: `${t('taskList.issuePurchase.tableItems.salesTax')}`,
      minWidth: 200,
      dropDown: true,
      options: commonSalesTax,
    },
    {
      id: 'actions',
      label: `${t('common.actions')}`,
      minWidth: 100,
      actions: true,
    },
  ]

  const updatedPurchaseOrderAmountChangeDetailsData =
    purchaseChangeAmountDetails?.map((details: any) => {
      const matchingSubtotal = purchaseChangeAmountDetails?.tax_subtotal?.find(
        (subtotal: any) => {
          return subtotal.sales_tax.id === details.sales_tax.id
        }
      )
      return {
        ...details,
        job_no: taskJobNoDetailNo,
        sub_total_id: matchingSubtotal?.id ? matchingSubtotal?.id : undefined,
      }
    })
  const initialState: initialPurchaseAfterAmountChangeProps = {
    id:
      jobTask?.translator_purchase_order?.translator_purchase_order ??
      undefined,
    purchase_order_version:
      jobTask?.translator_purchase_order?.purchase_order_version,
    reason: '',
    comment: '',
    // delivery_dates:
    //   jobTaskStockingDateDetails?.length > 0
    //     ? jobTaskStockingDateDetails
    //     : [{ stocking_date: dateFormatter(new Date()), remarks: '' }],
    drafts:
      updatedPurchaseOrderAmountChangeDetailsData?.length > 0
        ? updatedPurchaseOrderAmountChangeDetailsData
        : [
            {
              job_no: taskJobNoDetailNo,
              delivery_date: todayDate,
              tax_excluded_price: 0,
              tax_included_price: 0,
              tax_amount: 0,
              sales_tax: defaultSalesTax,
            },
          ],
    tax_subtotal: purchaseChangeAmountDetails?.tax_subtotal
      ? purchaseChangeAmountDetails?.tax_subtotal
      : [
          {
            sales_tax: null,
            subtotal_amount: 0,
            amount: 0,
          },
        ],
  }
  const validationSchema = Yup.object().shape({
    drafts: Yup.array().of(
      Yup.object().shape({
        sales_tax: Yup.object().required(t(messages.required)),
        // desired_delivery_date:Yup.mixed().required(t(messages.required)),
      })
    ),
  })
  const nav = useNavigate()
  const onSubmit = async (values: initialPurchaseAfterAmountChangeProps) => {
    const { drafts, tax_subtotal } = values
    const updatedInvoiceDetails = drafts?.map((details: any) => ({
      ...details,
      sales_tax: details?.sales_tax?.id,
    }))
    const updatedTaxSubTotal = tax_subtotal?.map((row: any) => ({
      ...row,
      sales_tax: row?.sales_tax?.id,
    }))
    const updatedValues = {
      ...values,
      drafts: updatedInvoiceDetails,
      tax_subtotal: updatedTaxSubTotal,
      job_task: jobTaskId,
    }
    if (jobTaskId) {
      dispatch(
        createPurchaseAmountChangeRequest({
          values: updatedValues,
          nav: nav,
          previousUrl,
          jobTaskId,
        })
      )
    } else {
      dispatch(alertErrorAction(t(messages.getJobTaskId)))
    }
  }

  const [query, setQuery] = useState<query>({
    search: '',
    url: '',
    // loadingAction: "",
    // successSlice: "",
    // errorSlice: "",
  })
  useInputSearch(query)

  const [readOnlyMode, setReadOnlyMode] = useState(false)
  const { is_staff } = useAppSelector(authSelector)
  useEffect(() => {
    if (!is_staff) {
      setReadOnlyMode(true)
    }

    return () => {
      setReadOnlyMode(false)
    }
  }, [])

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialState}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          values,
          handleChange,
          setFieldValue,
          handleBlur,
          touched,
          errors,
          submitForm,
        }) => {
          const handleSave = () => {
            submitForm()
          }
          const buttonConfigs = [
            {
              label: 'common.update',
              handler: handleSave,
              isShowAlert: true,
              loading: loadingCreatePurchaseAmountChange,
              message: t('confirmMessage.update'),
            },
          ]
          const handleButtonAction = (action: string) => {
            const buttonConfig = buttonConfigs.find(
              (config) => config.label === action
            )
            if (buttonConfig) {
              const { handler } = buttonConfig
              handler()
            }
          }
          return (
            <>
              <Grid item sx={{ mb: 4 }}>
                <SaveFooterComponent
                  handleClick={handleButtonAction}
                  buttonConfigs={buttonConfigs}
                />
              </Grid>
              {/* {id && (
                <ReasonForChange
                  name="reason"
                  setFieldValue={setFieldValue}
                  onChange={handleChange}
                />
              )} */}
              {/* <IssueBarComponent isIssued={purchaseChangeAmountDetails?.issue_flag} /> */}
              <div className={readOnlyMode ? 'disabled-form' : ''}>
                <Card sx={{ mb: 2, mt: 8, bgcolor: '#d7e2ea' }}>
                  <CardHeader
                    title={
                      <Typography
                        variant="h6"
                        sx={{ fontWeight: 'bold', color: 'white' }}
                      >
                        {t('purchaseAfterAmountChange.title')}
                      </Typography>
                    }
                    sx={{
                      backgroundColor: '#062f96',
                      height: '48px',
                    }}
                  />
                  <Grid container spacing={2} sx={{ mt: 2 }}></Grid>
                  <h1 style={{ textAlign: 'center' }}>
                    {t('purchaseAfterAmountChange.title')}
                  </h1>
                  <Grid container padding={4} alignItems="center">
                    {/* <Grid item xs={6}>
                      <Typography>
                        <strong>{t('purchaseAfterAmountChange.jobNo')}</strong>
                      </Typography>
                      <Typography>{jobTaskNo}</Typography>
                    </Grid> */}
                    <Grid item xs={6}>
                      <Typography>
                        <strong>
                          {t('purchaseAfterAmountChange.purchaseOrderDate')}
                        </strong>
                      </Typography>
                      <Typography>{jobTask?.order_date}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>
                        <strong>
                          {t('purchaseAfterAmountChange.translatorId')}
                        </strong>
                      </Typography>
                      <Typography>{jobTask?.translator?.name}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>
                        <strong>
                          {t('purchaseAfterAmountChange.deliveryDate')}
                        </strong>
                      </Typography>
                      <Typography>{jobTask?.delivery_date}</Typography>
                    </Grid>
                  </Grid>
                  {/* -------------------------------------------------- */}

                  {/* --------------------------------------- */}

                  {/* <Grid item xs={12} sx={{ ml: 2, mr: 2 }}>
                  <AppElementContainer
                    title={t('purchaseAfterAmountChange.legendTitle')}
                    spacing={2}
                    justifyContent="center"
                    xs={10}
                  >
                    <FieldArray name={`delivery_dates`}>
                      {({ push, remove }: any) => (
                        <React.Fragment>
                          {values?.delivery_dates &&
                            values?.delivery_dates.map(
                              (detail: any, index: any) => (
                                <React.Fragment key={index}>
                                  <Grid
                                    container
                                    alignItems="center"
                                    spacing={1}
                                    key={index}
                                    sx={{ mb: 1, ml: 2 }}
                                  >
                                    <Grid item xs={2}>
                                      {t(
                                        'purchaseAfterAmountChange.stockingDate'
                                      )}
                                      :
                                    </Grid>
                                    <AppDatePicker
                                      xs={2.5}
                                      name={`delivery_dates[${index}]stocking_date`}
                                      value={detail?.stocking_date}
                                      onChange={(value: any) => {
                                        setFieldValue(
                                          `delivery_dates[${index}]stocking_date`,
                                          dateFormatter(value)
                                        )
                                      }}
                                      variant="outlined"
                                      mt
                                      sx={{
                                        width: '100%',
                                        size: 'small',
                                        '& .MuiInputBase-root': {
                                          height: '39px',
                                          display: 'flex',
                                          alignItems: 'center',
                                        },
                                        '& .MuiInputLabel-root': {
                                          textAlign: 'center',
                                        },
                                      }}
                                    />
                                    <Grid item xs={2} sx={{ ml: 3 }}>
                                      {t('purchaseAfterAmountChange.remarks')}:
                                    </Grid>
                                    <AppTextField
                                      xs={3}
                                      name={`delivery_dates[${index}]remarks`}
                                      value={detail?.remarks}
                                      required
                                      placeholder={t(
                                        'purchaseAfterAmountChange.remarks'
                                      )}
                                      onChange={handleChange}
                                      // onBlur={handleBlur}
                                      sx={{ width: '100%' }}
                                    />

                                    {values?.delivery_dates && (
                                      <Grid
                                        item
                                        style={{
                                          paddingTop: 0,
                                          zIndex: 0,
                                        }}
                                        sx={{ mt: 1, ml: 2 }}
                                      >
                                        <Tooltip
                                          title={t('common.deleteAttachment')}
                                          placement="right-start"
                                        >
                                          <Fab
                                            style={{
                                              left: '0px',
                                            }}
                                            size="small"
                                            color="error"
                                            aria-label="delete"
                                            //@ts-ignore
                                            onClick={() => {
                                              if (
                                                values?.delivery_dates?.length >
                                                1
                                              ) {
                                                remove(index)
                                              } else {
                                                setFieldValue(
                                                  `delivery_dates[${index}].stocking_date`,
                                                  ''
                                                )
                                                setFieldValue(
                                                  `delivery_dates[${index}].remarks`,
                                                  ''
                                                )
                                              }
                                            }}
                                          >
                                            <DeleteIcon
                                              style={{
                                                left: '0px',
                                              }}
                                            />
                                          </Fab>
                                        </Tooltip>
                                      </Grid>
                                    )}
                                    <Grid
                                      item
                                      alignItems="center"
                                      justifyContent="center"
                                      style={{
                                        paddingTop: 0,
                                        zIndex: 0,
                                      }}
                                      sx={{ mt: 1, ml: 1 }}
                                      xs={12}
                                    >
                                      {index ===
                                        values?.delivery_dates.length - 1 && (
                                        <Tooltip
                                          title={t('common.addAttachment')}
                                          placement="right-start"
                                        >
                                          <Grid
                                            container
                                            alignItems="center"
                                            justifyContent="center"
                                            // style={{ width: '100%' }}
                                          >
                                            <Button
                                              size="large"
                                              variant="contained"
                                              color="secondary"
                                              style={{
                                                width: '20%',
                                              }}
                                              onClick={() => {
                                                if (
                                                  detail.file !== '' ||
                                                  detail.url !== ''
                                                ) {
                                                  push({
                                                    stocking_date:
                                                      dateFormatter(new Date()),
                                                    remarks: '',
                                                  })
                                                } else {
                                                  dispatch(
                                                    alertErrorAction(
                                                      messages.required
                                                    )
                                                  )
                                                }
                                              }}
                                            >
                                              {t('common.add')}
                                            </Button>
                                          </Grid>
                                        </Tooltip>
                                      )}
                                    </Grid>
                                  </Grid>
                                </React.Fragment>
                              )
                            )}
                        </React.Fragment>
                      )}
                    </FieldArray>
                  </AppElementContainer>
                </Grid> */}
                  {/* --------------------------------------------- */}
                  <Suspense fallback={<></>}>
                    <CommonReportTable
                      columns={columns}
                      taxType={`${t('commonFinanceTerm.excludingTax')})`}
                      keyName="drafts"
                      values={values?.drafts}
                      retrieve_tax_subtotal={values?.tax_subtotal}
                      handleChange={handleChange}
                      setFieldValue={setFieldValue}
                      handleBlur={handleBlur}
                      initialValues={initialState?.drafts}
                      setQuery={setQuery}
                      touched={touched}
                      disabled={readOnlyMode}
                      bgColor="#d7e2ea"
                      // edit={false}
                    />
                  </Suspense>
                </Card>
              </div>
              {/* {id && (
                <WorkFlowHistory
                  showHistoryOnly={true}
                  history={
                    purchaseChangeAmountDetails?.history?.length > 0
                      ? purchaseChangeAmountDetails?.history
                      : []
                  }
                />
              )} */}

              {/* {id && (
                <Comments
                  name="comment"
                  setFieldValue={setFieldValue}
                  onChange={handleChange}
                />
              )} */}
              <FocusElementOnInvalidValidation />
            </>
          )
        }}
      </Formik>
    </>
  )
}

export default PurchaseAfterAmountChange
