import { TableBody, TableCell, TableRow } from '@mui/material'
import Skeleton from '@mui/material/Skeleton'

interface ListingTableDataProps {
  headerLength: number
}
const ListingTableData = ({ headerLength }: ListingTableDataProps) => {
  return (
    <>
      <TableBody>
        {[...Array(3)]?.map((_: number, i: number) => {
          return (
            <TableRow key={i}>
              {[...Array(headerLength)]?.map((_: number, i: number) => {
                return (
                  <TableCell component="th" scope="row" key={i}>
                    <Skeleton animation="wave" height={40} />
                  </TableCell>
                )
              })}
            </TableRow>
          )
        })}
      </TableBody>
    </>
  )
}

export default ListingTableData
