import { createSlice } from '@reduxjs/toolkit'

import { CompleteQuotationListInitialSlice } from '../Types'

const initialState: CompleteQuotationListInitialSlice = {
  completeQuotationLists: [],
  completeQuotationList: null,
  loadingCompleteQuotationList: false,
  loadingCreateCompleteQuotationList: false,
  count: 0,
  next: '',
  previous: '',
  loadingCreate: false,
  completeQuotationListDetails: [],
  completeQuotationListDetail: null,
  loadingOrderDetails:false,
}
export const CompleteQuotationList = createSlice({
  name: 'completeQuotationListReducer',
  initialState,
  reducers: {
    loadingCompleteQuotationList: (state) => {
      state.loadingCompleteQuotationList = true
    },
    getCompleteQuotationListRequest: (state, action) => {
      state.loadingCompleteQuotationList = true
    },
    getCompleteQuotationListSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingCompleteQuotationList = false
      state.completeQuotationLists = results
      state.count = count
      state.next = next
      state.previous = previous
    },
    getCompleteQuotationListFail: (state) => {
      state.loadingCompleteQuotationList = false
    },
    //
    getCompleteQuotationListDetailRequest: (state, action) => {
      state.loadingOrderDetails = true
    },
    getCompleteQuotationListDetailSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingOrderDetails = false
      state.completeQuotationListDetails = results
      state.count = count
      state.next = next
      state.previous = previous
    },
    getCompleteQuotationListDetailFail: (state) => {
      state.loadingOrderDetails = false
    },
    //
    getOrderDetailListNextRequest: (state, payload) => {
      state.loadingOrderDetails = true
    },
    getOrderDetailListPreviousRequest: (state, payload) => {
      state.loadingOrderDetails = true
    },
    //
    getCompleteQuotationListDetailByIdRequest: (state, action) => {
      state.loadingOrderDetails = true
    },
    getCompleteQuotationListDetailByIdSuccess: (state, { payload }) => {
      state.completeQuotationListDetail = payload
    },
    getCompleteQuotationListDetailByIdFail: (state) => {
      state.loadingOrderDetails = false
      state.completeQuotationListDetail = null
    },
    getCompleteQuotationListNextRequest: (state, action) => {
      state.loadingCompleteQuotationList = true
    },

    getCompleteQuotationListPreviousRequest: (state, action) => {
      state.loadingCompleteQuotationList = true
    },

  
  },
})
export const {
  loadingCompleteQuotationList,
  getCompleteQuotationListSuccess,
  getCompleteQuotationListFail,
  getCompleteQuotationListRequest,
  getCompleteQuotationListPreviousRequest,
  getCompleteQuotationListNextRequest,
  //
  getCompleteQuotationListDetailSuccess,
  getCompleteQuotationListDetailFail,
  getCompleteQuotationListDetailRequest,
  //
  getCompleteQuotationListDetailByIdRequest,
  getCompleteQuotationListDetailByIdSuccess,
  getCompleteQuotationListDetailByIdFail,
  getOrderDetailListNextRequest,
  getOrderDetailListPreviousRequest,
  //create translator
 
} = CompleteQuotationList.actions
export default CompleteQuotationList.reducer
