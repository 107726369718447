import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import {
  CompleteInspectionProps,
  MatchingCancelProps,
  TaskStateListInitialState,
} from './types'

const initialState: TaskStateListInitialState = {
  taskStateLists: [],
  edit: false,
  taskStateList: null,
  loadingTaskStateList: false,
  count: 0,
  next: '',
  previous: '',
  completingInspection: false,
  matchingCancel: false,
}
export const TaskStateList = createSlice({
  name: 'taskStateListReducer',
  initialState,
  reducers: {
    loadingTaskStateList: (state) => {
      state.loadingTaskStateList = true
    },
    getTaskStateListRequest: (state, action) => {
      state.loadingTaskStateList = true
    },
    getTaskStateListSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingTaskStateList = false
      state.taskStateLists = results
      state.count = count
      state.next = next
      state.previous = previous
    },
    getTaskStateListFail: (state) => {
      state.loadingTaskStateList = false
    },
    getTaskStateListNextRequest: (state, payload) => {
      state.loadingTaskStateList = true
    },
    getTaskStateListPreviousRequest: (state, payload) => {
      state.loadingTaskStateList = true
    },
    completeInspectionRequest: (
      state,
      payload: PayloadAction<CompleteInspectionProps>
    ) => {
      state.completingInspection = true
    },
    completeInspectionFinish: (state) => {
      state.completingInspection = false
    },
    matchingCancelRequest: (
      state,
      payload: PayloadAction<MatchingCancelProps>
    ) => {
      state.matchingCancel = true
    },
    matchingCancelFinish: (state) => {
      state.matchingCancel = false
    },
  },
})
export const {
  loadingTaskStateList,
  getTaskStateListSuccess,
  getTaskStateListFail,
  getTaskStateListRequest,
  getTaskStateListNextRequest,
  getTaskStateListPreviousRequest,
  completeInspectionRequest,
  completeInspectionFinish,
  matchingCancelRequest,
  matchingCancelFinish,
} = TaskStateList.actions
export default TaskStateList.reducer
