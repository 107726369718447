import { SxProps } from '@mui/system'

export const gridStyles: SxProps = {
  mt: 1,
  p: 2,
  justifyContent: 'center',
  alignItems: 'center',
  transition: 'all 1s ease-out',
}

export const datePickerStyles: SxProps = {
  width: '100%',
  size: 'small',
  '& .MuiInputBase-root': {
    height: '39px',
    display: 'flex',
    alignItems: 'center',
  },
  '& .MuiInputLabel-root': {
    textAlign: 'center',
  },
}

export const cardStyles: SxProps = {
  mb: 2,
}
