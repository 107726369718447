import { memo } from 'react'

import {
  Breakpoint,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from '@mui/material'

import { t } from 'i18next'

import AppButton from '../AppButton/AppButton'

interface DialogBodyProps {
  handleClose: () => void
  handleClickOpen: () => void
  message: string | undefined
  maxWidth?: false | Breakpoint | undefined
  openDialog: boolean
  activeOnlyMessage: boolean
}

const DialogBody = ({
  openDialog,
  handleClose,
  maxWidth,
  message,
  handleClickOpen,
  activeOnlyMessage,
}: DialogBodyProps) => {
  const messageDefault = t('confirmMessage.confirmation')
  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      sx={{ zIndex: '99999' }}
      maxWidth={maxWidth ?? 'sm'}
    >
      <DialogTitle id="alert-dialog-title">
        {t('confirmMessage.confirmationTitle')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {message ?? messageDefault}
        </DialogContentText>
      </DialogContent>
      {!activeOnlyMessage && (
        <DialogActions>
          <Grid container item justifyContent="flex-end">
            <Grid item sx={{ textAlign: 'right', mr: 1 }}>
              <AppButton color="secondary" onClick={handleClickOpen} autoFocus>
                {'common.yes'}
              </AppButton>
            </Grid>
            <Grid item sx={{ textAlign: 'right', mr: 0 }}>
              <AppButton color="secondary" onClick={handleClose}>
                {'common.no'}
              </AppButton>
            </Grid>
          </Grid>
        </DialogActions>
      )}
    </Dialog>
  )
}

export default memo(DialogBody)
