import { t } from 'i18next'

const messages = {
  cancelSuccess: 'customAlertMessage.cancelSuccess',
  invalidFileTypeOnlyAllowedDoc:
    'validationMessage.invalidFileTypeOnlyAllowedDoc',
  required: 'validationMessage.required',
  processSuccess: 'customAlertMessage.processSuccess',
  createSuccess: 'customAlertMessage.createSuccess',
  createFail: 'customAlertMessage.createFail',
  email: 'validationMessage.email',
  updateSuccess: 'customAlertMessage.updateSuccess',
  updateFail: 'customAlertMessage.updateFail',
  gettingFail: 'customAlertMessage.gettingFail',
  selectAtLeastOneTranslator: 'validationMessage.selectOneTranslator',
  selectCheckbox: 'customAlertMessage.selectOne',
  invalidUrl: 'validationMessage.invalidUrl',
  atLeast2CharAfterDecimal: 'validationMessage.atLeast2CharAfterDecimal',
  atMost3CharAfterDecimal: 'validationMessage.atMost3CharAfterDecimal',
  doesntExist: 'customAlertMessage.doesntExist',
  sendSuccess: 'customAlertMessage.sendMailSuccess',
  sendFail: 'customAlertMessage.sendMailFail',
  sendAcceptanceSuccess: 'customAlertMessage.sendAcceptanceSuccess',
  sendAcceptanceFail: 'customAlertMessage.sendAcceptanceFail',
  deleteSuccess: 'customAlertMessage.deleteSuccess',
  deleteFail: 'customAlertMessage.deleteFail',
  previewDataFailed: 'customAlertMessage.previewDataFailed',
  invalidFileType: 'customAlertMessage.invalidFileType',
  pleaseSelectOnlyOneLanguage: 'customAlertMessage.pleaseSelectOnlyOneLanguage',
  minSheetCount: 'validationMessage.minSheetCount',
  numericInput: 'validationMessage.numericInput',
  failedToExport: 'customAlertMessage.failedToExport',
  downloadSuccess: 'customAlertMessage.downloadSuccess',
  fileOrUrlRequired: 'validationMessage.fileOrUrlRequired',
  getJobTaskId: 'customAlertMessage.getJobTaskId',
}

export const minCharValidation = (numb: number) => {
  return `${numb}${t('validationMessage.minimumChar')}`
}

export const maxCharValidation = (numb: number) => {
  return `${numb}${t('validationMessage.maxChar')} `
}

export default messages
