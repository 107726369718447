import { axiosInstance } from '../../../Utils/axios'
import { getApiProps } from '../../../Utils/globalTypes'

const billedList = '/billed-list'
export const getBilledList = (data: getApiProps) => {
  const { rowsPerPage, page } = data
  return axiosInstance.get(
    `${billedList}?offset=${rowsPerPage * page}&limit=${rowsPerPage}`
  )
}
