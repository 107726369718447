import { Tooltip } from '@mui/material'

interface tooltipProps {
  title: string
  children: React.ReactNode
}

const TooltipContent = ({ title, children }: tooltipProps) => {
  return (
    <Tooltip title={title} placement="top" disableInteractive>
      <div>{children}</div>
    </Tooltip>
  )
}

export default TooltipContent
