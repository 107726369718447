import { axiosInstance } from '../../../Utils/axios'
import { getApiProps } from '../../../Utils/globalTypes'

const deliverableList = '/deliverable-list'
export const getDeliverableList = (data: getApiProps) => {
  const { rowsPerPage, page } = data
  return axiosInstance.get(
    `${deliverableList}?offset=${rowsPerPage * page}&limit=${rowsPerPage}`
  )
}
