import { Action } from '@reduxjs/toolkit'
import { t } from 'i18next'
import { combineEpics } from 'redux-observable'
import { Observable } from 'rxjs'
import { filter, map, switchMap } from 'rxjs/operators'

import { getNext, getPrevious } from '../../../CommonAppRedux/api'
import { alertErrorAction } from '../../../CommonAppRedux/commonAppSlice'
import messages from '../../../Utils/ValidationMessage'
import { dispatchAction, stateAction } from '../../../Utils/globalTypes'
import { getProjectListClient } from './api'
import {
  loadingProjectListClient,
  getProjectListClientSuccess,
  getProjectListClientFail,
  getProjectListClientRequest,
  getProjectListClientNextRequest,
  getProjectListClientPreviousRequest,
} from './projectListClientSlice'
import { extractErrorMessage } from '../../../Utils/AppFunction'

const getProjectListClientEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(getProjectListClientRequest.match),
    switchMap(async (action) => {
      dispatch(loadingProjectListClient())
      try {
        const response = await getProjectListClient(action.payload)
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? getProjectListClientSuccess(action?.payload)
        : getProjectListClientFail()
    )
  )
}

//get next
const getProjectListClientNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getProjectListClientNextRequest.match),
    switchMap(async (action) => {
      try {
        const response = await getNext(action.payload)
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? getProjectListClientSuccess(action?.payload)
        : getProjectListClientFail()
    )
  )
//get previous
const getProjectListClientPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getProjectListClientPreviousRequest.match),
    switchMap(async (action) => {
      try {
        const response = await getPrevious(action.payload)
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? getProjectListClientSuccess(action?.payload)
        : getProjectListClientFail()
    )
  )

export const projectListClientEpics = combineEpics(
  getProjectListClientEpic,
  getProjectListClientNext,
  getProjectListClientPrevious
)
