import React, { Suspense, lazy, useEffect, useMemo, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'

import { Typography } from '@mui/material'

import { t } from 'i18next'

import { commonUrlFilterData } from '../../../CommonAppRedux/commonAppSlice'
import { commonAppSelector } from '../../../CommonAppRedux/selector'
import ConfirmModal from '../../../ReusableComponent/ConfirmModal/ConfirmModal'
import {
  useAppDispatch,
  useAppSelector,
} from '../../../ReusableComponent/CustomHooks/appHooks'
import Modal from '../../../ReusableComponent/Modal'
import { getTaskStateListRequest } from '../../TaskStateList/Redux/taskStateListSlice'
import { searchSubmitValuesProps } from '../Redux/types'
import { getCommonStorePreviousURLPath } from '../../../CommonLocalStorageRedux/localReduxSlice'

//below import with lazy loading, which is typically used when you want to load modules or components asynchronously, especially when they are not immediately needed.
const TaskStateListTable = lazy(() => import('./TaskStateListTable'))
const TaskStateListSearch = lazy(() => import('./TaskStateListSearch'))
const TaskStateList = () => {
  const dispatch = useAppDispatch()

  const { rowsPerPage, page, tableCheckBoxData, collapseTableCheckBoxData } =
    useAppSelector(commonAppSelector)

  const [showBulkInspectionCertificate, setShowBulkInspectionCertificate] =
    useState(false)

  let bulkInspectionCertificateModalProps: any = useMemo(() => {
    return {
      title: t('common.confirm'),
      maxWidth: 'sm',
      showModal: showBulkInspectionCertificate,
      setShowModal: setShowBulkInspectionCertificate,
    }
  }, [showBulkInspectionCertificate])

  //filter for test purpose make component soon---
  const params = useParams<{ values?: string }>()
  const decodedValues = useMemo(() => {
    if (params.values) {
      return decodeURIComponent(params.values)
    }
    return ''
  }, [params.values])

  // Define the type for parsedValues
  let parsedValues: searchSubmitValuesProps | null = null

  try {
    if (decodedValues) {
      parsedValues = JSON.parse(decodedValues)
    }
  } catch (error) {
    console.error('Error parsing JSON:', error)
  }
  const location = useLocation()
  useEffect(() => {
    dispatch(commonUrlFilterData(parsedValues))
    dispatch(
      getTaskStateListRequest({
        page: page,
        rowsPerPage: rowsPerPage,
        filterData: parsedValues,
      })
    )
  }, [location, rowsPerPage, page])

  useEffect(() => {
    dispatch(
      getCommonStorePreviousURLPath([
        { pathName: location?.pathname, search: location?.search },
      ])
    )
  }, [])

  return (
    <React.Fragment>
      <Typography variant="h6" fontWeight="bold" sx={{ padding: '16px' }}>
        {t('menuName.taskStateList')}
      </Typography>
      <Suspense fallback={<></>}>
        <TaskStateListSearch />
      </Suspense>
      <Suspense fallback={<></>}>
        <TaskStateListTable />
      </Suspense>
      {showBulkInspectionCertificate && (
        <Suspense fallback={<></>}>
          <Modal {...bulkInspectionCertificateModalProps}>
            <ConfirmModal
              setShowConfirmModal={setShowBulkInspectionCertificate}
              showConfirmModal={showBulkInspectionCertificate}
              title={t('taskStateList.bulkInspectionCertificateModalTitle')}
            />
          </Modal>
        </Suspense>
      )}
    </React.Fragment>
  )
}

export default TaskStateList
