import { Navigate } from 'react-router-dom'

import { authSelector } from '../../Login/Redux/selector'
import { useAppSelector } from '../../ReusableComponent/CustomHooks/appHooks'

const ProjectListNavigate = () => {
  const { is_staff } = useAppSelector(authSelector)

  const dest = is_staff ? '/project-list' : '/project-list-client'
  return <Navigate to={dest} replace />
}

export default ProjectListNavigate
