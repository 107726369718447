import React, { memo } from 'react'

import { Grid, TextField } from '@mui/material'

import { ErrorMessage, FastField } from 'formik'

import { handleFocus } from '../FocusElement/focusElement'
import TextError from '../TextError/TextError'
import './AppTextField.css'

const AppTextField = ({
  xs,
  autoFocus,
  name,
  value,
  inputRef,
  label,
  focusElement,
  onChange,
  required,
  isNotFormik,
  type,
  InputLabelProps,
  InputProps,
  placeholder,
  error,
  onBlur,
  disabled,
  handleButtonClick,
  id,
  md,
  lg,
  xl,
  className,
  helperText,
  style,
  color,
  focused,
  onKeyUp,
  inputProps,
  multiline,
  rows,
  margin,
  sx,
  variant,
  fastFieldNotAllowed,
  onKeyDown,
  onCut,
  autoComplete,
}: any) => {
  // const classes = useAppStyles();
  const handleFocusElement = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (type === 'number') {
      if (e.key === 'e' || e.key === 'E' || e.key === '-' || e.key === '+') {
        e.preventDefault()
      }
    }
    // handleFocus(focusElement, e, required && value)
    // const defaultWidth=sx??{{ width: '100%' }}
    const dullLabelStyle = {
      color: 'gray', // Replace with your desired dull color
    }
  }

  return (
    <>
      <Grid item xs={xs} md={md} lg={lg} xl={xl} className="textfield-wrapper">
        <TextField
          onCut={onCut}
          sx={sx ?? { width: '100%' }}
          disabled={disabled}
          style={style}
          name={name}
          required={required}
          error={error}
          color={color}
          type={type}
          focused={focused}
          autoComplete={autoComplete ? autoComplete : 'new-password'}
          autoFocus={autoFocus ?? false}
          className={className ? className : ''}
          value={value}
          id={id ?? label}
          label={label}
          variant={variant ?? 'outlined'}
          // inputRef={inputRef}
          onBlur={onBlur}
          InputLabelProps={
            InputLabelProps ?? {
              // style: dullLabelStyle,
              shrink: true,
            }
          }
          InputProps={InputProps}
          // inputProps={inputProps}
          helperText={helperText}
          placeholder={placeholder}
          onKeyDown={onKeyDown ?? handleButtonClick ?? handleFocusElement}
          onKeyUp={onKeyUp}
          onChange={onChange}
          multiline={multiline}
          rows={rows}
          margin={margin}
        />

        {!isNotFormik && <ErrorMessage name={name} component={TextError} />}
      </Grid>
    </>
  )
}

export default memo(AppTextField)

// <FastField name={name}>
// {({ field, form, meta }: any) => (
//   <>
//     <TextField
//       sx={sx ?? { width: '100%' }}
//       disabled={disabled}
//       style={style}
//       name={name}
//       required={required}
//       error={error}
//       color={color}
//       type={type}
//       focused={focused}
//       autoComplete="new-password"
//       autoFocus={autoFocus ?? false}
//       className={className ? className : ''}
//       value={value}
//       id={id ?? name}
//       label={label}
//       variant={variant ?? 'outlined'}
//       // inputRef={inputRef}
//       onBlur={onBlur}
//       InputLabelProps={
//         InputLabelProps ?? {
//           // style: dullLabelStyle,
//           shrink: true,
//         }
//       }
//       InputProps={InputProps}
//       // inputProps={inputProps}
//       helperText={helperText}
//       placeholder={placeholder}
//       onKeyDown={
//         handleButtonClick ? handleButtonClick : handleFocusElement
//       }
//       onKeyUp={onKeyUp}
//       onChange={onChange}
//       multiline={multiline}
//       rows={rows}
//       margin={margin}
//     />
//   </>
// )}
// </FastField>
