import React from 'react'

import WorkFlowPanel from '.'

export default function WorkFlowTest() {
  const [id, setId] = React.useState(1)
  return (
    <div>
      <h1>WorkFlowTest</h1>
      <input
        type="number"
        value={id}
        onChange={(e) => setId(+e.target.value)}
      />
      <WorkFlowPanel id={id} />
    </div>
  )
}
