import { Action } from '@reduxjs/toolkit'
import { t } from 'i18next'
import { combineEpics } from 'redux-observable'
import { Observable } from 'rxjs'
import { filter, map, switchMap } from 'rxjs/operators'

import { getNext, getPrevious } from '../../../CommonAppRedux/api'
import {
  alertErrorAction,
  alertSuccessAction,
} from '../../../CommonAppRedux/commonAppSlice'
import messages from '../../../Utils/ValidationMessage'
import { defaultPage, defaultRowsPerPage } from '../../../Utils/globalConstant'
import { dispatchAction, stateAction } from '../../../Utils/globalTypes'
import {
  getCompleteQuotationList,
  getCompleteQuotationListDetail,
  getCompleteQuotationListDetailById,
} from './api'
import {
  loadingCompleteQuotationList,
  getCompleteQuotationListSuccess,
  getCompleteQuotationListFail,
  getCompleteQuotationListRequest,

  getCompleteQuotationListDetailSuccess,
  getCompleteQuotationListDetailFail,
  getCompleteQuotationListDetailRequest,
  getCompleteQuotationListDetailByIdRequest,
  getCompleteQuotationListDetailByIdSuccess,
  getCompleteQuotationListDetailByIdFail,
  getOrderDetailListNextRequest,
  getOrderDetailListPreviousRequest,
} from './completeQuotationListSlices'
import { extractErrorMessage } from '../../../Utils/AppFunction'

const getCompleteQuotationListEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(getCompleteQuotationListRequest.match),
    switchMap(async (action) => {
      dispatch(loadingCompleteQuotationList())
      try {
        const response = await getCompleteQuotationList(action.payload)
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload ? getCompleteQuotationListSuccess(action?.payload) : getCompleteQuotationListFail()
    )
  )
}

//get next
const getOrderDetailListNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getOrderDetailListNextRequest.match),
    switchMap(async (action) => {
      try {
        const response = await getNext(action.payload)
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? getCompleteQuotationListDetailSuccess(action?.payload)
        : getCompleteQuotationListDetailFail()
    )
  )
//get previous
const getOrderDetailListPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getOrderDetailListPreviousRequest.match),
    switchMap(async (action) => {
      try {
        const response = await getPrevious(action.payload)
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? getCompleteQuotationListDetailSuccess(action?.payload)
        : getCompleteQuotationListDetailFail()
    )
  )

const getCompleteQuotationListDetailEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(getCompleteQuotationListDetailRequest.match),
    switchMap(async (action) => {
      dispatch(loadingCompleteQuotationList())
      try {
        const response = await getCompleteQuotationListDetail(action.payload)
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? getCompleteQuotationListDetailSuccess(action?.payload)
        : getCompleteQuotationListDetailFail()
    )
  )
}

const getCompleteQuotationListDetailByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(getCompleteQuotationListDetailByIdRequest.match),
    switchMap(async (action) => {
      dispatch(loadingCompleteQuotationList())
      try {
        const response = await getCompleteQuotationListDetailById(action.payload)
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? getCompleteQuotationListDetailByIdSuccess(action?.payload)
        : getCompleteQuotationListDetailByIdFail()
    )
  )
}

//

export const completeQuotationListEpics = combineEpics(
  getCompleteQuotationListEpic,

  getCompleteQuotationListDetailEpic,
  getCompleteQuotationListDetailByIdEpic,

  getOrderDetailListNext,
  getOrderDetailListPrevious,
  //translator certificate
  //user made invoice
)
