import { axiosInstance } from '../../../Utils/axios'
import { getApiProps } from '../../../Utils/globalTypes'

const waitingBillList = '/waiting-bill-project-list'
export const getWaitingBillList = (data: getApiProps) => {
  const { rowsPerPage, page } = data
  return axiosInstance.get(
    `${waitingBillList}?offset=${rowsPerPage * page}&limit=${rowsPerPage}`
  )
}
export const getTranslatorCertificateTaxSubTotal = (id: number) => {
  return axiosInstance.get(
    `/translator-certificate-tax-subtotal-summation/${id}`
  )
}

export const getWaitingBillRowDataById = (id: number) => {
  return axiosInstance.get(
    `${waitingBillList}/${id}`
  )
}



