import { Action } from '@reduxjs/toolkit'
import { t } from 'i18next'
import { combineEpics } from 'redux-observable'
import { Observable } from 'rxjs'
import { filter, map, switchMap } from 'rxjs/operators'

import { getNext, getPrevious } from '../../../CommonAppRedux/api'
import { alertErrorAction } from '../../../CommonAppRedux/commonAppSlice'
import messages from '../../../Utils/ValidationMessage'
import { dispatchAction, stateAction } from '../../../Utils/globalTypes'
import {
  getTranslatorCertificateTaxSubTotal,
  getWaitingBillList,
  getWaitingBillRowDataById,
} from './api'
import {
  loadingWaitingBillList,
  getWaitingBillListSuccess,
  getWaitingBillListFail,
  getWaitingBillListRequest,
  getWaitingBillListNextRequest,
  getWaitingBillListPreviousRequest,
  getTranslatorCertificateTaxSubtotalFail,
  getTranslatorCertificateTaxSubtotalSuccess,
  getTranslatorCertificateTaxSubtotalRequest,
  getWaitingBillRowDataByIdSuccess,
  getWaitingBillRowDataByIdFail,
  getWaitingBillRowDataByIdRequest,
} from './waitingBillListSlice'
import { extractErrorMessage } from '../../../Utils/AppFunction'

const getWaitingBillListEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(getWaitingBillListRequest.match),
    switchMap(async (action) => {
      dispatch(loadingWaitingBillList())
      try {
        const response = await getWaitingBillList(action.payload)
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? getWaitingBillListSuccess(action?.payload)
        : getWaitingBillListFail()
    )
  )
}

//get next
const getWaitingBillListNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getWaitingBillListNextRequest.match),
    switchMap(async (action) => {
      try {
        const response = await getNext(action.payload)
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? getWaitingBillListSuccess(action?.payload)
        : getWaitingBillListFail()
    )
  )
//get previous
const getWaitingBillListPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getWaitingBillListPreviousRequest.match),
    switchMap(async (action) => {
      try {
        const response = await getPrevious(action.payload)
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? getWaitingBillListSuccess(action?.payload)
        : getWaitingBillListFail()
    )
  )

const getTranslatorCertificateTaxSubtotalEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(getTranslatorCertificateTaxSubtotalRequest.match),
    switchMap(async (action) => {
      dispatch(loadingWaitingBillList())
      try {
        const response = await getTranslatorCertificateTaxSubTotal(
          action.payload
        )
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? getTranslatorCertificateTaxSubtotalSuccess(action?.payload)
        : getTranslatorCertificateTaxSubtotalFail()
    )
  )
}

const getWaitingBillRowDataByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(getWaitingBillRowDataByIdRequest.match),
    switchMap(async (action) => {
      dispatch(loadingWaitingBillList())
      try {
        const response = await getWaitingBillRowDataById(action.payload)
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? getWaitingBillRowDataByIdSuccess(action?.payload)
        : getWaitingBillRowDataByIdFail()
    )
  )
}

export const waitingBillListEpics = combineEpics(
  getWaitingBillListEpic,
  getWaitingBillListNext,
  getWaitingBillListPrevious,
  getTranslatorCertificateTaxSubtotalEpic,
  getWaitingBillRowDataByIdEpic
)
