import { useEffect } from 'react'

import { useFormikContext } from 'formik'
import { t } from 'i18next'

import { alertErrorAction, closeAlertDialog, showAlertDialog } from '../../CommonAppRedux/commonAppSlice'
import { commonAppSelector } from '../../CommonAppRedux/selector'
import AlertDialog from '../AlertDialog/AlertDialog'
import { useAppDispatch, useAppSelector } from '../CustomHooks/appHooks'
import { extractErrorMessageYup } from '../../Utils/AppFunction'

const FocusElementOnInvalidValidation = () => {
  const formik = useFormikContext()
  const submitting = formik?.isSubmitting

  function findIndexOfFirstDefinedElement(arr: any) {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i] !== undefined) {
        return i
      }
    }
    return -1
  }
  const dispatch = useAppDispatch()
  function getPropertyByPath<T>(formikObject: T, path: string): any {
    const keys = path.split('.')
    let result: any = formikObject

    for (const key of keys) {
      if (result && typeof result === 'object') {
        if (key.includes('[')) {
          const [arrayKey, index] = key.split('[')
          const arrayIndex = parseInt(index.slice(0, -1), 10)
          result = result[arrayKey][arrayIndex]
        } else {
          result = result[key]
        }
      } else {
        return undefined
      }
    }

    return result
  }
  useEffect(() => {
    if (!submitting) return
    let errorField
    let sortField:any;
    console.log(formik?.errors, 'formik.errors1111')
    if (formik?.errors) {
      let updateField = formik.errors
      let checkArray = Object.keys(updateField)[0]
      //@ts-ignore
      if (Array.isArray(updateField[checkArray])) {
        //@ts-ignore
        const firstDefinedIndex = findIndexOfFirstDefinedElement(
          //@ts-ignore
          updateField[checkArray]
        )
        console.log(firstDefinedIndex, 'firstDefinedIndexfirstDefinedIndex')
        //@ts-ignore
        let foundArrayKeyName = Object.keys(
          //@ts-ignore
          updateField[checkArray][firstDefinedIndex]
        )[0]
        //@ts-ignore
        errorField = `${checkArray}[${firstDefinedIndex}].${foundArrayKeyName}`
        sortField = foundArrayKeyName
      } else {
        errorField = checkArray
        sortField = checkArray
      }
    }
    if (errorField) {
      const documentValue = getPropertyByPath(formik?.errors, errorField)
      const elements = document?.getElementsByName(errorField)
      const element = elements[0]
      console.log(element, 'elementelement')
      console.log(sortField, 'sortFieldsortField')
      // console.log(documentValue?.filter((item:any) => item !== null), 'documentValuedocumentValue')
      console.log(extractErrorMessageYup(documentValue),"extractErrorMessageYup(documentValue)")

      const someExceptionKeyName=[
        {
          keyName:"cost_sharing_department_code1",
          label:t('projectList.columns.costSharingDepartmentCode1'),
        },
        {
          keyName:"request_files",
          label:t('common.requestFile'),
        },
        {
          keyName:"reference_files",
          label:t('common.referenceFile'),
        }
      ]

      if (element) {
        //@ts-ignore
        dispatch(alertErrorAction(`${element?.type === "file" && element?.placeholder !==""?element?.placeholder:element?.id}は${documentValue}`))
      } else {
        //@ts-ignore
        dispatch(alertErrorAction(`${someExceptionKeyName?.find(item => item?.keyName === `${sortField}`)?.label ?? sortField}は${Array.isArray(documentValue)?extractErrorMessageYup(documentValue?.filter((item:any) => item !== null)):documentValue}`));
      }
        //@ts-ignore
      if (element) {
        element.focus()
      }
    }
  }, [submitting, formik.errors])

  return <></>
}

export default FocusElementOnInvalidValidation
