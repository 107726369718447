import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { billableListProps } from './types'

const initialState: billableListProps = {
  billableLists: [],
  edit: false,
  billableList: null,
  loadingBillableList: false,
  loadingCreateBillableList: false,
  count: 0,
  next: '',
  previous: '',
  loadingCSV: false,
}
export const BillableList = createSlice({
  name: 'billableListReducer',
  initialState,
  reducers: {
    loadingBillableList: (state) => {
      state.loadingBillableList = true
    },
    getBillableListRequest: (state, payload) => {
      state.loadingBillableList = true
    },
    getBillableListSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingBillableList = false
      state.billableLists = results
      state.count = count
      state.next = next
      state.previous = previous
    },
    getBillableListFail: (state) => {
      state.loadingBillableList = false
    },
    getBillableListNextRequest: (state, action) => {
      state.loadingBillableList = true
    },
    getBillableListPreviousRequest: (state, action) => {
      state.loadingBillableList = true
    },
    getBillableListCSVRequest: (state, action: PayloadAction<number[]>) => {
      state.loadingCSV = true
    },
    getBillableListCSVFinish: (state) => {
      state.loadingCSV = false
    },
  },
})
export const {
  loadingBillableList,
  getBillableListSuccess,
  getBillableListFail,
  getBillableListRequest,
  getBillableListPreviousRequest,
  getBillableListNextRequest,
  getBillableListCSVRequest,
  getBillableListCSVFinish,
} = BillableList.actions
export default BillableList.reducer
