import React, {
  useState,
  useRef,
  useLayoutEffect,
  useEffect,
  lazy,
  Suspense,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import { Card, CardHeader, Grid, Typography } from '@mui/material'

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import dayjs from 'dayjs'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import {
  handleFocusCompanyCommon,
  handleFocusSaleTaxCommon,
  onChangeInputCompanyCommon,
  handleFocusCostSharingDepartmentCodeCommon,
  onChangeInputCostSharingDepartmentCodeCommon,
  handleFocusExportRestrictionCommon,
  onChangeInputExportRestrictionCommon,
  handleFocusExpenseTypeCommon,
  onChangeInputExpenseTypeCommon,
} from '../../../CommonAppRedux/commonAppFunctions'
import { getCommonPaymentTermsRequest } from '../../../CommonAppRedux/commonAppSlice'
import { commonAppSelector } from '../../../CommonAppRedux/selector'
import { commonOptionType } from '../../../CustomAlert/types'
import { authSelector } from '../../../Login/Redux/selector'
import AppAutoComplete from '../../../ReusableComponent/AppAutoComplete/AppAutoComplete'
import AppDatePicker from '../../../ReusableComponent/AppDatePicker/AppDatePicker'
import AppTextField from '../../../ReusableComponent/AppTextField/AppTextField'
import {
  useAppDispatch,
  useAppSelector,
} from '../../../ReusableComponent/CustomHooks/appHooks'
import { useInputSearch } from '../../../ReusableComponent/CustomHooks/useInputSearch'
import FocusElementOnInvalidValidation from '../../../ReusableComponent/FocusElementIfInvalid/FocusElementOnInvalidValidation'
import History from '../../../ReusableComponent/History'
import IssueBarComponent from '../../../ReusableComponent/IssueBarComponent/IssueBarComponent'
import ReasonForChange from '../../../ReusableComponent/ReasonForChange/ReasonForChange'
import SaveFooterComponent from '../../../ReusableComponent/SaveFooterButton/SaveFooterButton'
import WorkFlowPanel from '../../../ReusableComponent/Workflow'
import { workflowSelector } from '../../../ReusableComponent/Workflow/Redux/selector'
import WorkFlowHistoryTable from '../../../ReusableComponent/WorkflowHistory'
import {
  dateFormatter,
  formateNumberAgain,
} from '../../../Utils/AppFormatFunction'
import { todayDate } from '../../../Utils/AppFunction'
import messages, { maxCharValidation } from '../../../Utils/ValidationMessage'
import {
  defaultRowsPerPage,
  selectTypePaymentTerms,
} from '../../../Utils/globalConstant'
import { InputChangeEvent, query } from '../../../Utils/globalTypes'
import { company, saleTax } from '../../JobList/Types'
import PreviewIndex from '../../Preview'
import {
  clearDataProjectDetails,
  clearHardResetProjectList,
  clearQuotation,
  clearQuotationDetailDraft,
  createQuotationRequest,
  getProjectDetailByIdRequest,
  getQuotationByIdRequest,
  getQuotationDetailDraftByIdRequest,
  updateQuotationRequest,
} from '../Redux/projectListSlice'
import { projectListSelector } from '../Redux/selector'
import { exportRestrictionProps } from '../Types'

const CommonReportTable = lazy(
  () => import('../../../ReusableComponent/CommonReportTable/CommonReportTable')
)

export interface costSharingDepartment {
  id?: number
  code: string
  name: string
  production_order_no_required_flag: boolean
}

export interface quotationDetails {
  uniqueId?: string
  item_name: string
  quantity: string | number
  unit_cost: string | number
  sales_tax: saleTax | null
  amount: string | number
  tax_amount: string | number
}

export interface taxSubTotalProps {
  id?: number
  sales_tax: object | any
  subtotal_amount: number
  amount: number
}

export interface issueQuotation {
  id?: number
  quotation_details: quotationDetails[]
  version?: number
  quotation_no: string
  quotation_date: Date | string | null
  company: company | null
  title: string
  due_date: string | Date | null
  cost_sharing_department_code: costSharingDepartment | null
  production_order_no: string
  export_restriction: exportRestrictionProps | null
  remarks: string
  case_no: number | null
  history?: any
  workflow: number
  tax_subtotal: taxSubTotalProps[]
  payment_terms: any
  translation_expiration: any
  expense_type: any
  total_amount: any
  reason: string
  comment: string
  issue_flag?: boolean
  shimadzu_group_flag?: boolean
  shimadzu_report_flag?: boolean
  production_order_no_required_flag?: boolean
  withdrawal_flag?: boolean
}

const IssueQuotation = () => {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const { state } = location
  const previousUrl = state?.path
  const id = searchParams.get('case-id')
  const quotationId = searchParams.get('quotation-id')
  const edit = searchParams?.get('edit')
  const navigate = useNavigate()
  const {
    commonSalesTax,
    defaultSalesTax,
    commonCompanies,
    loadingCommonCompany,
    commonCostSharingDepartmentCodes,
    loadingCommonCostSharingDepartmentCode,
    commonExportRestrictions,
    loadingCommonExportRestriction,
    commonExpenseTypes,
  } = useAppSelector(commonAppSelector)
  const {
    issueQuotation,
    projectDetail,
    loadingCreateIssueQuotation,
    issueQuotationDetailDraft,
  } = useAppSelector(projectListSelector)

  const { workflow } = useAppSelector(workflowSelector)

  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  useLayoutEffect(() => {
    handleFocusSaleTaxCommon(dispatch)
    if (id) {
      dispatch(getProjectDetailByIdRequest(id))
      dispatch(getQuotationDetailDraftByIdRequest(id))
    } else {
      dispatch(clearDataProjectDetails())
      dispatch(clearHardResetProjectList())
      dispatch(clearQuotationDetailDraft())
    }
    return () => {
      dispatch(clearHardResetProjectList())
    }
  }, [id, location, dispatch, edit])

  useLayoutEffect(() => {
    if (edit) {
      dispatch(getQuotationByIdRequest(quotationId))
    } else {
      dispatch(clearQuotation())
    }
  }, [quotationId, workflow, location, dispatch, edit])

  useEffect(() => {
    dispatch(getCommonPaymentTermsRequest(defaultRowsPerPage))
  }, [])

  const updateIssueQuotationDetailDraft = issueQuotationDetailDraft?.map(
    (row: any) => {
      return {
        item_name: row?.item_name,
        quantity: row?.quantity,
        unit_cost: row?.unit_cost,
        sales_tax: row?.sales_tax,
        amount: row?.amount,
        tax_amount: row?.tax_amount,
      }
    }
  )
  const updateQuotationDetails = issueQuotation?.quotation_details.map(
    (quotationDetail: any) => {
      const matchingSubtotal = issueQuotation?.tax_subtotal?.find(
        (subtotal: any) => {
          return subtotal.sales_tax.id === quotationDetail.sales_tax.id
        }
      )
      return {
        ...quotationDetail,
        sub_total_id: matchingSubtotal?.id ? matchingSubtotal?.id : undefined,
      }
    }
  )

  const initialState: issueQuotation = {
    shimadzu_group_flag:
      projectDetail?.request_company?.shimadzu_group_flag ?? null,
    version: issueQuotation?.version,
    quotation_no: issueQuotation?.quotation_no
      ? issueQuotation?.quotation_no
      : '',
    quotation_date: issueQuotation ? issueQuotation?.quotation_date : todayDate,
    title: issueQuotation
      ? issueQuotation?.title
      : projectDetail?.case_title ?? '',
    due_date: issueQuotation
      ? issueQuotation?.due_date
      : dayjs(projectDetail?.desired_delivery_date).format('YYYY年MM月DD日') ??
        '',
    company: issueQuotation?.company
      ? issueQuotation?.company
      : edit
      ? null
      : projectDetail?.request_company ?? null,
    production_order_no: issueQuotation
      ? issueQuotation?.production_order_no
      : projectDetail?.production_order_no1 ?? '',
    export_restriction: issueQuotation
      ? issueQuotation?.export_restriction
      : projectDetail?.export_restriction ?? null,
    cost_sharing_department_code: issueQuotation
      ? issueQuotation?.cost_sharing_department_code
      : projectDetail?.cost_sharing_department_code1 ?? null,
    expense_type: issueQuotation
      ? issueQuotation?.expense_type
      : projectDetail?.expense_type ?? null,
    payment_terms: issueQuotation
      ? issueQuotation?.payment_terms
      : projectDetail?.request_company?.shimadzu_report_flag
      ? selectTypePaymentTerms[0]?.name
      : selectTypePaymentTerms[1]?.name ?? '',
    translation_expiration: issueQuotation
      ? issueQuotation?.translation_expiration
      : '提出後1か月',

    remarks: issueQuotation ? issueQuotation?.remarks : '特になし',
    reason: '',
    comment: '',
    case_no: issueQuotation?.case_no
      ? issueQuotation?.case_no
      : projectDetail?.case_no
      ? projectDetail?.case_no
      : '',
    workflow: issueQuotation?.workflow ? issueQuotation.workflow : 0,
    total_amount: issueQuotation?.total_amount ?? 0,
    tax_subtotal: issueQuotation?.tax_subtotal
      ? issueQuotation?.tax_subtotal
      : [
          {
            sales_tax: null,
            subtotal_amount: 0,
            amount: 0,
          },
        ],
    quotation_details: updateQuotationDetails
      ? updateQuotationDetails
      : updateIssueQuotationDetailDraft?.length > 0
      ? updateIssueQuotationDetailDraft
      : [
          {
            item_name: '',
            quantity: 0,
            unit_cost: 0,
            sales_tax: defaultSalesTax,
            amount: 0,
            tax_amount: 0,
          },
        ],
  }

  const validationSchema = Yup.object().shape({
    shimadzu_group_flag: Yup.boolean().nullable(),
    quotation_date: Yup.mixed().required(t(messages.required)),
    title: Yup.string()
      .max(100, maxCharValidation(100))
      .required(t(messages.required)),
    due_date: Yup.string()
      .max(100, maxCharValidation(100))
      .required(t(messages.required)),
    company: Yup.object().when(
      ['shimadzu_group_flag'],
      ([shimadzu_group_flag], schema) => {
        return shimadzu_group_flag
          ? schema.required(t(messages.required))
          : schema.nullable()
      }
    ),
    cost_sharing_department_code: Yup.object().when(
      ['shimadzu_group_flag'],
      ([shimadzu_group_flag], schema) => {
        return shimadzu_group_flag
          ? schema.required(t(messages.required))
          : schema.nullable()
      }
    ),
    export_restriction: Yup.object().when(
      ['shimadzu_group_flag'],
      ([shimadzu_group_flag], schema) => {
        return shimadzu_group_flag
          ? schema.required(t(messages.required))
          : schema.nullable()
      }
    ),
    production_order_no: Yup.string().when(
      ['cost_sharing_department_code.production_order_no_required_flag'],
      ([production_order_no_required_flag], schema) => {
        return production_order_no_required_flag
          ? schema.required(t(messages.required)).max(20, maxCharValidation(20))
          : schema.optional().max(20, maxCharValidation(20))
      }
    ),
    expense_type: Yup.object().when(
      ['shimadzu_group_flag'],
      ([shimadzu_group_flag], schema) => {
        return shimadzu_group_flag
          ? schema.required(t(messages.required))
          : schema.nullable()
      }
    ),
    payment_terms: Yup.string().required(t(messages.required)),
    translation_expiration: Yup.string()
      .required(t(messages.required))
      .max(50, maxCharValidation(50)),
    remarks: Yup.string().required(t(messages.required)),
    cost_sharing_department_code1: Yup.object()
      .shape({
        production_order_no_required_flag: Yup.boolean().optional(),
      })
      .nullable(),
    cost_sharing_department_code2: Yup.object()
      .shape({
        production_order_no_required_flag: Yup.boolean().optional(),
      })
      .nullable(),
    production_order_no1: Yup.string()
      .max(20, maxCharValidation(20))
      .when(
        ['cost_sharing_department_code1.production_order_no_required_flag'],
        (flag, schema) => {
          return flag[0]
            ? schema.required(t('validationMessage.costSharingRequired1'))
            : schema
        }
      ),
    production_order_no2: Yup.string()
      .max(20, maxCharValidation(20))
      .when(
        ['cost_sharing_department_code2.production_order_no_required_flag'],
        (flag, schema) => {
          return flag[0]
            ? schema.required(t('validationMessage.costSharingRequired2'))
            : schema
        }
      ),
    quotation_details: Yup.array().of(
      Yup.object().shape({
        item_name: Yup.string()
          .max(100, maxCharValidation(100))
          .required(t(messages.required)),
        unit_cost: Yup.string().required(t(messages.required)),
        sales_tax: Yup.object().required(t(messages.required)),
      })
    ),
  })

  // CommonReportProps
  const columns = [
    {
      id: 'item_name',
      label: `${t('quotation.tableItems.content')}`,
      minWidth: 100,
    },
    {
      id: 'quantity',
      label: `${t('quotation.tableItems.quantity')}`,
      minWidth: 50,
      qty: true,
      currency: true,
    },
    {
      id: 'unit_cost',
      label: `${t('quotation.tableItems.unitCost')}`,
      currency: true,
      minWidth: 100,
      cost: true,
    },
    {
      id: 'sales_tax',
      label: `${t('quotation.tableItems.salesTax')}`,
      minWidth: 200,
      dropDown: true,
      options: commonSalesTax,
    },
    {
      id: 'amount',
      label: `${t('quotation.tableItems.amount')}`,
      minWidth: 100,
      amount: true,
      currency: true,
    },
    {
      id: 'actions',
      label: `${t('common.actions')}`,
      minWidth: 100,
      actions: true,
    },
  ]
  const [query, setQuery] = useState<query>({
    search: '',
    url: '',
  })
  useInputSearch(query)
  const nav = useNavigate()

  const onSubmit = async (values: issueQuotation, actions: any) => {
    const {
      company,
      cost_sharing_department_code,
      export_restriction,
      due_date,
      quotation_details,
      tax_subtotal,
      expense_type,
      version,
    } = values
    const updatedQuotationDetails = quotation_details?.map((details: any) => ({
      id: details?.id ?? undefined,
      sales_tax: details?.sales_tax?.id,
      amount: formateNumberAgain(details?.amount),
      unit_cost: formateNumberAgain(details?.unit_cost),
      section: details?.section,
      item_name: details?.item_name,
      quantity: details?.quantity,

      tax_amount: formateNumberAgain(details.tax_amount),
    }))
    const updatedTaxSubTotal = tax_subtotal?.map((row: any) => ({
      ...row,
      sales_tax: row?.sales_tax?.id,
    }))

    if (edit) {
      const updatedValues = {
        ...values,
        case: id,
        company: company?.id ?? null,
        due_date: due_date !== '' ? due_date : '',
        cost_sharing_department_code: cost_sharing_department_code?.id ?? '',
        export_restriction: export_restriction ? export_restriction?.id : '',
        quotation_details: updatedQuotationDetails,
        payment_terms_id: company?.payment_terms,
        expense_type: expense_type?.id ?? '',
        tax_subtotal: updatedTaxSubTotal,
        status: '010',
      }
      dispatch(
        updateQuotationRequest({
          id: projectDetail?.case_report_relation?.quotation,
          values: updatedValues,
          nav: nav,
          actions,
          previousUrl,
        })
      )
    } else {
      const updatedValues = {
        ...values,
        case: id,
        company: company?.id ?? null,
        cost_sharing_department_code: cost_sharing_department_code?.id ?? '',
        export_restriction: export_restriction ? export_restriction?.id : '',
        quotation_details: updatedQuotationDetails,
        tax_subtotal: updatedTaxSubTotal,
        payment_terms_id: company?.payment_terms,
        expense_type: expense_type?.id ?? '',
        status: '010',
      }
      dispatch(
        createQuotationRequest({
          values: updatedValues,
          nav: nav,
          actions,
          previousUrl,
        })
      )
    }
  }
  const focus_save_btn = useRef(null)

  const handlePreview = () => {
    const url = 'quotation-report'
    const queryParams = `?id=${quotationId}&preview-report-url=${url}&file-name=${issueQuotation?.quotation_no}&case-id=${id}`
    navigate(`/preview${queryParams}`)
  }
  const handleBackToCase = () => {
    const queryParams = `?id=${id}&edit=${true}`
    navigate(`/create-agency-project${queryParams}`, {
      state: { path: location?.pathname + location?.search },
    })
  }

  useLayoutEffect(() => {
    handleFocusExportRestrictionCommon(dispatch)
  }, [])

  const [readOnlyMode, setReadOnlyMode] = useState(false)
  useLayoutEffect(() => {
    if (workflow) {
      if (workflow?.status !== 1) {
        setReadOnlyMode(true)
      } else {
        setReadOnlyMode(false)
      }
    } else {
      setReadOnlyMode(false)
    }
    return () => {
      setReadOnlyMode(false)
    }
  }, [workflow, issueQuotation])
  const { is_staff, user } = useAppSelector(authSelector)
  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialState}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          values,
          handleChange,
          setFieldValue,
          handleBlur,
          errors,
          submitForm,
          touched,
        }) => {
          const handleSave = () => {
            submitForm()
          }
          const buttonConfigs = [
            ...((
              is_staff && edit && !readOnlyMode
                ? ['010', '020', '050'].includes(projectDetail?.status?.code)
                : ['010'].includes(projectDetail?.status?.code) &&
                  !issueQuotation?.withdrawal_flag
            )
              ? [
                  {
                    label: edit ? 'common.update' : 'common.save',
                    handler: handleSave,
                    isShowAlert: true,
                    focusBtn: focus_save_btn,
                    loading: loadingCreateIssueQuotation,
                    message: edit
                      ? t('confirmMessage.update')
                      : t('confirmMessage.save'),
                  },
                ]
              : []),
            ...((
              edit && is_staff
                ? [
                    '010',
                    '020',
                    '030',
                    '040',
                    '050',
                    '060',
                    '070',
                    '080',
                    '090',
                  ].includes(projectDetail?.status?.code)
                : ['020', '030', '050', '060', '070', '080', '090'].includes(
                    projectDetail?.status?.code
                  )
            )
              ? [
                  {
                    label:
                      user?.email === workflow?.workflow_details[0]?.user_email
                        ? 'common.requestForApproval'
                        : 'common.delivery',
                    handler: handlePreview,
                  },
                ]
              : []),
            {
              label: 'common.backToCase',
              handler: handleBackToCase,
            },
          ]

          const handleButtonAction = (action: string) => {
            const buttonConfig = buttonConfigs.find(
              (config) => config.label === action
            )
            if (buttonConfig) {
              const { handler } = buttonConfig
              handler()
            }
          }

          return (
            <>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Grid item sx={{ mb: 4 }}>
                  <SaveFooterComponent
                    handleClick={handleButtonAction}
                    buttonConfigs={buttonConfigs}
                  />
                </Grid>
                {edit && <WorkFlowPanel id={values?.workflow} />}
                {readOnlyMode ? (
                  <PreviewIndex
                    hideButton={true}
                    directUrl="quotation-report"
                    directId={quotationId}
                  />
                ) : (
                  <div className={readOnlyMode ? 'disabled-form' : ''}>
                    {edit && (
                      <>
                        <ReasonForChange
                          setFieldValue={setFieldValue}
                          name="reason"
                          onChange={handleChange}
                        />
                      </>
                    )}

                    <IssueBarComponent isIssued={issueQuotation?.issue_flag} />
                    <Form autoComplete="off" noValidate>
                      <Card sx={{ mb: 2 }}>
                        <CardHeader
                          title={
                            <Typography
                              variant="h6"
                              sx={{ fontWeight: 'bold', color: 'white' }}
                            >
                              {t('quotation.name')}
                            </Typography>
                          }
                          sx={{
                            backgroundColor: '#062f96',
                            height: '48px',
                          }}
                        />

                        <h1 style={{ textAlign: 'center' }}>
                          {t('quotation.name')}
                        </h1>
                        <Grid
                          container
                          padding={4}
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Grid item xs={6}>
                            <Grid container alignItems="center">
                              <Grid item xs={3}>
                                {t('quotation.quotationNo')}:
                              </Grid>
                              <AppTextField
                                id={t('quotation.quotationNo')}
                                xs={4}
                                disabled
                                name="quotation_no"
                                value={values?.quotation_no}
                                onChange={handleChange}
                                label={t('quotation.quotationNo')}
                                onBlur={handleBlur}
                              />
                            </Grid>
                            <Grid container alignItems="center" mt={2.5}>
                              <Grid item xs={3}>
                                {t('quotation.caseNo')}:
                              </Grid>
                              <AppTextField
                                id={t('quotation.caseNo')}
                                xs={4}
                                disabled
                                name="case_no"
                                value={values?.case_no}
                                onChange={handleChange}
                                label={t('quotation.caseNo')}
                                onBlur={handleBlur}
                              />
                            </Grid>
                            <Grid container alignItems="center" mt={2.5}>
                              <Grid item xs={3}>
                                {t('quotation.title')}:
                              </Grid>
                              <AppTextField
                                id={t('quotation.title')}
                                xs={4}
                                name="title"
                                disabled={readOnlyMode}
                                label={t('quotation.title')}
                                value={values?.title}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </Grid>
                          </Grid>

                          <Grid item xs={6}>
                            <Grid container alignItems="center">
                              <Grid item xs={3}>
                                {t('quotation.quotationDate')}:
                              </Grid>
                              <AppDatePicker
                                id={t('quotation.quotationDate')}
                                xs={4}
                                name="quotation_date"
                                label={t('quotation.quotationDate')}
                                disabled={readOnlyMode}
                                value={values?.quotation_date}
                                onChange={(value: any) => {
                                  if (value !== null) {
                                    setFieldValue(
                                      `quotation_date`,
                                      dateFormatter(value)
                                    )
                                  } else {
                                    setFieldValue(`quotation_date`, null)
                                  }
                                }}
                                variant="outlined"
                                mt
                                sx={{
                                  width: '100%',
                                  size: 'small',
                                  '& .MuiInputBase-root': {
                                    height: '39px',
                                    display: 'flex',
                                    alignItems: 'center',
                                  },
                                  '& .MuiInputLabel-root': {
                                    textAlign: 'center',
                                  },
                                }}
                              />
                            </Grid>
                            <Grid container alignItems="center" mt={2.5}>
                              <Grid item xs={3}>
                                {t('quotation.dueDate')}:
                              </Grid>
                              <AppTextField
                                id={t('quotation.dueDate')}
                                xs={4}
                                name="due_date"
                                disabled={readOnlyMode}
                                label={t('quotation.dueDate')}
                                value={values?.due_date}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </Grid>
                          </Grid>
                          <Grid item xs={6} sx={{ mt: 2.5 }}>
                            <Grid container alignItems="center">
                              <Grid item xs={3}>
                                {t('quotation.company')}:
                              </Grid>
                              <AppAutoComplete
                                xs={4}
                                name="company"
                                value={values?.company}
                                disabled={readOnlyMode}
                                options={commonCompanies}
                                loading={loadingCommonCompany}
                                getOptionLabel={(option: commonOptionType) =>
                                  option.name
                                }
                                label={t('quotation.company')}
                                onChange={(event: any, value: any) => {
                                  if (value !== null) {
                                    setFieldValue('company', value)
                                    setFieldValue(
                                      'shimadzu_group_flag',
                                      value?.shimadzu_group_flag
                                    )
                                    setFieldValue(
                                      'payment_terms',
                                      value?.shimadzu_report_flag
                                        ? selectTypePaymentTerms[0]?.name
                                        : selectTypePaymentTerms[1]?.name
                                    )
                                  } else {
                                    setFieldValue('company', null)
                                    setFieldValue(
                                      'shimadzu_group_flag',
                                      projectDetail?.request_company
                                        ?.shimadzu_group_flag
                                    )
                                  }
                                }}
                                onFocus={() =>
                                  handleFocusCompanyCommon(dispatch)
                                }
                                onBlur={handleBlur}
                                inputOnchange={(e: any) =>
                                  onChangeInputCompanyCommon(e.target, setQuery)
                                }
                              />
                            </Grid>
                            <Grid container alignItems="center" mt={2.5}>
                              <Grid item xs={3}>
                                {t('quotation.exportRestriction')}:
                              </Grid>
                              <AppAutoComplete
                                xs={4}
                                name="export_restriction"
                                value={values?.export_restriction}
                                options={commonExportRestrictions}
                                disabled={readOnlyMode}
                                loading={loadingCommonExportRestriction}
                                getOptionLabel={(option: commonOptionType) =>
                                  option.name
                                }
                                label={t('quotation.exportRestriction')}
                                onChange={(
                                  event: any,
                                  value: InputChangeEvent
                                ) => {
                                  if (value !== null) {
                                    setFieldValue('export_restriction', value)
                                  } else {
                                    setFieldValue('export_restriction', null)
                                  }
                                }}
                                onFocus={() =>
                                  handleFocusExportRestrictionCommon(dispatch)
                                }
                                onBlur={handleBlur}
                                inputOnchange={(e: any) =>
                                  onChangeInputExportRestrictionCommon(
                                    e.target,
                                    setQuery
                                  )
                                }
                              />
                            </Grid>
                          </Grid>
                          <Grid item xs={6}>
                            <Grid container alignItems="center" mt={2.5}>
                              <Grid item xs={3}>
                                {t('quotation.costSharingDeptCode')}:
                              </Grid>
                              <AppAutoComplete
                                xs={4}
                                name="cost_sharing_department_code"
                                disabled={readOnlyMode}
                                value={values?.cost_sharing_department_code}
                                options={commonCostSharingDepartmentCodes}
                                loading={loadingCommonCostSharingDepartmentCode}
                                getOptionLabel={(option: commonOptionType) =>
                                  option.name
                                }
                                label={t('quotation.costSharingDeptCode')}
                                onChange={(
                                  event: any,
                                  value: costSharingDepartment
                                ) => {
                                  if (value !== null) {
                                    setFieldValue(
                                      'cost_sharing_department_code',
                                      value
                                    )
                                    setFieldValue(
                                      'production_order_no_required_flag',
                                      value?.production_order_no_required_flag
                                    )
                                  } else {
                                    setFieldValue(
                                      'cost_sharing_department_code',
                                      null
                                    )
                                  }
                                }}
                                onFocus={() =>
                                  handleFocusCostSharingDepartmentCodeCommon(
                                    dispatch
                                  )
                                }
                                onBlur={handleBlur}
                                inputOnchange={(e: any) =>
                                  onChangeInputCostSharingDepartmentCodeCommon(
                                    e.target,
                                    setQuery
                                  )
                                }
                              />
                            </Grid>
                            <Grid container alignItems="center" mt={2.5}>
                              <Grid item xs={3}>
                                {t('quotation.productionOrderNo')}:
                              </Grid>
                              <AppTextField
                                id={t('quotation.productionOrderNo')}
                                xs={4}
                                name="production_order_no"
                                disabled={readOnlyMode}
                                label={t('quotation.productionOrderNo')}
                                value={values?.production_order_no}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </Grid>
                          </Grid>
                          <Grid item xs={6}>
                            <Grid
                              container
                              alignItems="center"
                              mt={2.5}
                              justifyContent="flex-start"
                            >
                              <Grid item xs={3}>
                                {t('projectList.columns.expenseType')}:
                              </Grid>
                              <AppAutoComplete
                                xs={4}
                                options={commonExpenseTypes}
                                value={values?.expense_type ?? null}
                                getOptionLabel={(option: any) => option.name}
                                label={t('projectList.columns.expenseType')}
                                name="expense_type"
                                onChange={(_: object, value: any) => {
                                  if (value !== null) {
                                    setFieldValue(`expense_type`, value)
                                  } else {
                                    setFieldValue(`expense_type`, null)
                                  }
                                }}
                                onFocus={() =>
                                  handleFocusExpenseTypeCommon(dispatch)
                                }
                                onBlur={handleBlur}
                                inputOnchange={(e: any) =>
                                  onChangeInputExpenseTypeCommon(
                                    e.target,
                                    setQuery
                                  )
                                }
                              />
                            </Grid>
                          </Grid>

                          <Grid item xs={6}>
                            <Grid container alignItems="center" mt={2.5}>
                              <Grid item xs={3}>
                                {t('orderReceiptIssuance.paymentTerms')}:
                              </Grid>
                              <AppTextField
                                id={t('orderReceiptIssuance.paymentTerms')}
                                xs={4}
                                name="payment_terms"
                                value={values?.payment_terms}
                                label={t('orderReceiptIssuance.paymentTerms')}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid item xs={6}>
                              <Grid
                                container
                                alignItems="center"
                                mt={2.5}
                                justifyContent="flex-start"
                              >
                                <Grid item xs={3}>
                                  {t(
                                    'orderReceiptIssuance.translationExpiration'
                                  )}
                                  :
                                </Grid>
                                <AppTextField
                                  id={t(
                                    'orderReceiptIssuance.translationExpiration'
                                  )}
                                  xs={4}
                                  name="translation_expiration"
                                  label={t(
                                    'orderReceiptIssuance.translationExpiration'
                                  )}
                                  value={values?.translation_expiration}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <Grid container alignItems="center" mt={2.5}>
                              <Grid item xs={1.5}>
                                {t('quotation.remarks')}:
                              </Grid>
                              <AppTextField
                                id={t('quotation.remarks')}
                                xs={8}
                                name="remarks"
                                value={values?.remarks}
                                disabled={readOnlyMode}
                                label={t('quotation.remarks')}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                multiline
                                rows={4}
                                InputProps={{
                                  style: { padding: '10px' }, // Add padding to the input element
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Suspense fallback={<></>}>
                          <CommonReportTable
                            columns={columns}
                            disabled={readOnlyMode}
                            taxType={`${t('commonFinanceTerm.excludingTax')})`}
                            keyName="quotation_details"
                            values={values?.quotation_details}
                            retrieve_tax_subtotal={values?.tax_subtotal}
                            handleChange={handleChange}
                            setFieldValue={setFieldValue}
                            handleBlur={handleBlur}
                            initialValues={initialState?.quotation_details}
                            setQuery={setQuery}
                            touched={touched}
                            edit={edit}
                          />
                        </Suspense>
                      </Card>
                      <FocusElementOnInvalidValidation />
                    </Form>
                  </div>
                )}
                {edit && (
                  <>
                    <WorkFlowHistoryTable />
                    {is_staff && (
                      <History
                        history={
                          issueQuotation?.history?.length > 0
                            ? issueQuotation?.history
                            : []
                        }
                      />
                    )}
                  </>
                )}
              </LocalizationProvider>
            </>
          )
        }}
      </Formik>
    </>
  )
}

export default IssueQuotation
