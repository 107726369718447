import { Card, CardHeader, Grid, Typography } from '@mui/material'

import { t } from 'i18next'

import AppTextField from '../AppTextField/AppTextField'

interface commentsProps {
  bgColor?: boolean
  name?: string
  setFieldValue?: any
  onChange?: any
  value?: string
}

const Comments = ({
  bgColor,
  setFieldValue,
  name,
  onChange,
  value,
}: commentsProps) => {
  return (
    <Card sx={{ mb: 2, bgcolor: `${bgColor ? '#cfe7f8' : ''}` }}>
      <CardHeader
        title={
          <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'white' }}>
            {t('common.comments')}
          </Typography>
        }
        sx={{
          backgroundColor: '#062f96',
          height: '48px',
        }}
      />
      <Grid container spacing={2}>
        <AppTextField
          xs={12}
          name={name}
          multiline
          rows={5}
          value={value}
          onChange={onChange}
          placeholder={t('common.comments')}
          InputProps={{
            style: {
              paddingTop: '2px',
              paddingLeft: '15px', // Increase the left padding of input value
              paddingRight: '5px',
            },
          }}
          isNotFormik
        />
      </Grid>
    </Card>
  )
}

export default Comments
