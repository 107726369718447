import { createSlice } from '@reduxjs/toolkit'

import { quotationStateListProps } from './types'

const initialState: quotationStateListProps = {
  quotationStateLists: [],
  edit: false,
  quotationStateList: null,
  loadingQuotationStateList: false,
  loadingCreateQuotationStateList: false,
  count: 0,
  next: '',
  previous: '',
}
export const QuotationStateList = createSlice({
  name: 'quotationStateListReducer',
  initialState,
  reducers: {
    loadingQuotationStateList: (state) => {
      state.loadingQuotationStateList = true
    },
    getQuotationStateListRequest: (state, action) => {
      state.loadingQuotationStateList = true
    },
    getQuotationStateListSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingQuotationStateList = false
      state.quotationStateLists = results
      state.count = count
      state.next = next
      state.previous = previous
    },
    getQuotationStateListFail: (state) => {
      state.loadingQuotationStateList = false
    },
    getQuotationStateListNextRequest: (state, action) => {
      state.loadingQuotationStateList = true
    },
    getQuotationStateListPreviousRequest: (state, action) => {
      state.loadingQuotationStateList = true
    },
  },
})
export const {
  loadingQuotationStateList,
  getQuotationStateListSuccess,
  getQuotationStateListFail,
  getQuotationStateListRequest,
  getQuotationStateListPreviousRequest,
  getQuotationStateListNextRequest,
} = QuotationStateList.actions
export default QuotationStateList.reducer
