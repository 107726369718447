import React, { Suspense, lazy, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Typography } from '@mui/material'

import { commonAppSelector } from '../../../CommonAppRedux/selector'
import {
  useAppDispatch,
  useAppSelector,
} from '../../../ReusableComponent/CustomHooks/appHooks'
import { defaultPage, defaultRowsPerPage } from '../../../Utils/globalConstant'
import { getQuotationStateListRequest } from '../Redux/quotationStateSlice'

const QuotationStateListTable = lazy(() => import('./QuotationStateListTable'))

const QuotationStateList = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { page, rowsPerPage } = useAppSelector(commonAppSelector)
  useEffect(() => {
    dispatch(
      getQuotationStateListRequest({
        page,
        rowsPerPage,
      })
    )
  }, [dispatch, page, rowsPerPage])

  return (
    <React.Fragment>
      <Typography variant="h6" fontWeight="bold" sx={{ padding: '16px' }}>
        {t('menuName.quotationStateList')}
      </Typography>
      <Suspense fallback={<></>}>
        <QuotationStateListTable />
      </Suspense>
    </React.Fragment>
  )
}

export default QuotationStateList
