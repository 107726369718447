import { combineEpics } from 'redux-observable'

import { commonAppEpics } from '../components/CommonAppRedux/commonAppEpic'
import { authEpics } from '../components/Login/Redux/authEpic'
import { workflowEpics } from '../components/ReusableComponent/Workflow/Redux/workflowEpic'
import { billableListEpics } from '../components/pages/BillableList/Redux/billableEpic'
import { billedListEpics } from '../components/pages/BilledList/Redux/billedEpic'
import { completeDeliveryEpics } from '../components/pages/CompleteDeliveryList/Redux/completeDeliveryListEpic'
import { completeQuotationListEpics } from '../components/pages/CompleteQuotationList/Redux/completeQuotationListEpic'
import { deliverableListEpics } from '../components/pages/DeliverableList/Redux/deliverableEpic'
import { exportInvoiceDataListEpics } from '../components/pages/ExportInvoiceDataList/Redux/exportInvoiceDataEpic'
import { jobListEpics } from '../components/pages/JobList/Redux/jobListEpic'
import { orderListEpics } from '../components/pages/OrderList/Redux/orderListEpic'
import { projectListEpics } from '../components/pages/ProjectList/Redux/projectListEpic'
import { projectListClientEpics } from '../components/pages/ProjectListClient/Redux/projectListClientEpic'
import { quotationStateListEpics } from '../components/pages/QuotationStateList/Redux/quotationStateEpic'
import { taskStateListEpics } from '../components/pages/TaskStateList/Redux/taskStateListEpic'
import { waitingBillListEpics } from '../components/pages/WaitingBillList/Redux/waitingBillListEpic'
import { inspectionTaskStateListEpics } from '../components/pages/InspectionTaskStateList/Redux/InspectionTaskStateListEpic'
import { quotationRequestListEpics } from '../components/pages/QuotationRequestList/Redux/quotationRequestListEpic'
import { InputTrackRecordEpics } from '../components/pages/JobList/InputTrackRecordScreen/Redux/inputTrackRecordEpic'

export const RootEpic = combineEpics(
  authEpics,
  jobListEpics,
  commonAppEpics,
  projectListEpics,
  billedListEpics,
  quotationStateListEpics,
  deliverableListEpics,
  billableListEpics,
  workflowEpics,
  completeDeliveryEpics,
  projectListClientEpics,
  exportInvoiceDataListEpics,
  orderListEpics,
  waitingBillListEpics,
  completeQuotationListEpics,
  inspectionTaskStateListEpics,
  taskStateListEpics,
  quotationRequestListEpics,
  InputTrackRecordEpics,
)
