import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'

import { styled } from '@mui/material/styles'

import {
  Backdrop,
  Button,
  CircularProgress,
  Link,
  ListItemIcon,
  Menu,
  MenuItem,
  Switch,
} from '@mui/material'
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import CssBaseline from '@mui/material/CssBaseline'
import Divider from '@mui/material/Divider'
import MuiDrawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import KeyIcon from '@mui/icons-material/Key'
import LogoutIcon from '@mui/icons-material/Logout'
import MenuIcon from '@mui/icons-material/Menu'
import PersonOutlineIcon from '@mui/icons-material/PersonOutline'

import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state'

import {
  closeChangePassword,
  getCurrentInnerWidth,
  openChangePassword,
} from '../CommonAppRedux/commonAppSlice'
import { commonAppSelector } from '../CommonAppRedux/selector'
import { logoutRequest } from '../Login/Redux/authSlice'
import { authSelector } from '../Login/Redux/selector'
import {
  useAppDispatch,
  useAppSelector,
} from '../ReusableComponent/CustomHooks/appHooks'
import { ChangePasswordModal } from './ChangePasswordModal'
import MenuItems from './MenuItems'

const drawerWidth: number = 240

interface AppBarProps extends MuiAppBarProps {
  open?: boolean
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  '& .MuiDrawer-paper': {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: 'border-box',
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
    }),
  },
}))

const Frame: React.FC = () => {
  const { t, i18n } = useTranslation()
  const [open, setOpen] = React.useState(true)
  // const [showModal, setShowModal] = React.useState(false)
  const { showChangePassword } = useAppSelector(commonAppSelector)
  const dispatch = useAppDispatch()
  const toggleDrawer = () => {
    setOpen(!open)
    dispatch(getCurrentInnerWidth(open))
  }
  const { user, isAuthenticated } = useAppSelector(authSelector)
  const { is_staff } = useAppSelector(authSelector)
  return (
    <Box sx={{ display: 'flex', height: '100vh' }}>
      <Backdrop open={!isAuthenticated}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <CssBaseline />
      <AppBar position="absolute" open={open}>
        <Toolbar
          sx={{
            pr: '24px', // keep right padding when drawer closed
          }}
        >
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{
              marginRight: '36px',
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            sx={{ flexGrow: 1 }}
          >
            島津インターナショナル翻訳依頼Webシステム Ver.
            {process.env.REACT_APP_VERSION}
          </Typography>
          {!is_staff && (
            <Typography>
              使い方は
              <Link
                color="inherit"
                target="_blank"
                href="http://172.31.1.3/subsidiary/sin/common_rw/files/request_honyaku_manual.pdf"
              >
                こちら
              </Link>
            </Typography>
          )}
          {process.env.NODE_ENV === 'development' && (
            <Switch
              color="default"
              onChange={(e) => {
                if (e.target.checked) {
                  i18n.changeLanguage('ja')
                } else {
                  i18n.changeLanguage('en')
                }
              }}
            />
          )}
          <PopupState variant="popover" popupId="setting-menu">
            {(popupState) => (
              <>
                <Button
                  size="large"
                  {...bindTrigger(popupState)}
                  color="inherit"
                  startIcon={<PersonOutlineIcon />}
                >
                  {user?.full_name}
                </Button>
                <Menu {...bindMenu(popupState)}>
                  <MenuItem
                    onClick={() => {
                      dispatch(openChangePassword())
                      popupState.close()
                    }}
                  >
                    <ListItemIcon>
                      <KeyIcon fontSize="small" />
                    </ListItemIcon>
                    {t('auth.changePassword')}
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      dispatch(logoutRequest())
                      popupState.close()
                    }}
                  >
                    <ListItemIcon>
                      <LogoutIcon fontSize="small" />
                    </ListItemIcon>
                    {t('common.logout')}
                  </MenuItem>
                </Menu>
              </>
            )}
          </PopupState>
        </Toolbar>
        <ChangePasswordModal
          showModal={showChangePassword}
          setShowModal={(open: boolean) =>
            dispatch(open ? openChangePassword() : closeChangePassword())
          }
        />
      </AppBar>

      <Drawer variant="permanent" open={open}>
        <Toolbar
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            px: [1],
          }}
        >
          <IconButton onClick={toggleDrawer}>
            <ChevronLeftIcon />
          </IconButton>
        </Toolbar>
        <Divider />
        <List component="nav" style={{ overflowY: 'visible' }}>
          <MenuItems />
        </List>
      </Drawer>
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
        }}
      >
        <Toolbar />
        <Container maxWidth={false} sx={{ pt: 4, pb: 10 }}>
          {isAuthenticated && <Outlet />}
        </Container>
      </Box>
    </Box>
  )
}

export default Frame
