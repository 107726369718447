import { t } from 'i18next'

import {
  alertErrorAction,
  alertSuccessAction,
} from '../CommonAppRedux/commonAppSlice'
import { commonAppSelector } from '../CommonAppRedux/selector'
import { useAppSelector } from '../ReusableComponent/CustomHooks/appHooks'
import { dateFormatter } from './AppFormatFunction'
import messages from './ValidationMessage'
import { axiosInstance } from './axios'
import { globalSearchPostPerPage, saveStatus } from './globalConstant'

const getSearchURL = (value: string) =>
  `offset=0&limit=${globalSearchPostPerPage}&search=${value.trimStart()}`
function downloadFile(file: any) {
  const fileName =
    typeof file === 'string' ? 'usermade-invoice-file' : file.name
  const blob = typeof file === 'string' ? null : new Blob([file])
  const url = blob ? URL.createObjectURL(blob) : file

  const a = document.createElement('a')
  a.href = url
  a.download = decodeURIComponent(fileName)
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}

function removeValuesAndKeepKeys(
  obj: Record<string, any>
): Record<string, any> {
  const result: Record<string, any> = {}
  for (const key in obj) {
    if (key === 'id') {
      continue //
    }
    if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
      result[key] = null
    } else if (typeof obj[key] === 'number') {
      result[key] = 0
    } else if (Array.isArray(obj[key])) {
      result[key] = []
    } else {
      result[key] = ''
    }
  }
  return result
}
// function removeValuesAndKeepKeys<T extends Record<string, any>>(
//   type: T,
//   inputObject: Partial<T>
// ): (keyof T)[] {
//   const result: (keyof T)[] = [];
//   for (const key in inputObject) {
//     if (key in type) {
//       result.push(key);
//     }
//   }
//   return result;
// }
async function fetchImageAsBinary(url: any) {
  try {
    const response = await fetch(url)
    const blobData = await response.blob()

    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onloadend = () => resolve(reader.result)
      reader.onerror = reject
      reader.readAsArrayBuffer(blobData)
    })
  } catch (error) {
    console.error('Error fetching and converting image:', error)
    throw error
  }
}

const dataURLtoFile = (dataurl: any, filename: any) => {
  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new File([u8arr], filename, { type: mime })
}
const fetchImageAsFile = async (url: any) => {
  try {
    const response = await fetch(url)
    if (!response.ok) {
      throw new Error(`HTTP Error: ${response.status}`)
    }

    const blob = await response.blob()
    const file = new File([blob], 'default-image', { type: blob.type })
    return file
  } catch (error) {
    console.error('Error fetching image:', error)
    return null
  }
}

function getContentTypeFromUrl(url: any, allowedTypes: any) {
  const fileExtension = url.split('.').pop().toLowerCase()
  const contentType = allowedTypes.find((type: any) => {
    const typeExtension = type.split('/')[1]
    return typeExtension === fileExtension
  })
  if (contentType) {
    return contentType
  } else {
    return 'image/png'
  }
}

async function getContentTypeFromUrl2(url: string) {
  try {
    const response = await fetch(url, { method: 'HEAD' })
    if (!response.ok) {
      throw new Error(`HTTP Error: ${response.status}`)
    }
    const contentType = response.headers.get('content-type')

    if (contentType) {
      return contentType
    } else {
      return 'application/octet-stream'
    }
  } catch (error) {
    console.error('Error fetching file:', error)
    return 'application/octet-stream'
  }
}

const formatJPDateTime = (
  originalDate: string | Date,
  timeZone: string = 'Asia/Tokyo'
): string => {
  const parsedDate = new Date(originalDate)

  if (isNaN(parsedDate.getTime())) {
    return '-'
  }

  if (timeZone !== 'UTC') {
    const offsetMinutes = parsedDate.getTimezoneOffset()
    const timeZoneOffsetInMinutes = timeZone === 'Asia/Tokyo' ? -540 : 0 // Japan Standard Time (JST) offset is +09:00
    parsedDate.setMinutes(
      parsedDate.getMinutes() - offsetMinutes + timeZoneOffsetInMinutes
    )
  }

  const formatter = new Intl.DateTimeFormat('ja-JP', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
    timeZone: timeZone,
  })

  return formatter.format(parsedDate)
}
//react number forming library

//
const status000 = saveStatus.find((row: any) => row?.fields?.code === '000')
const status010 = saveStatus.find((row: any) => row?.fields?.code === '010')
const status020 = saveStatus.find((row: any) => row?.fields?.code === '020')
const status030 = saveStatus.find((row: any) => row?.fields?.code === '030')
const status040 = saveStatus.find((row: any) => row?.fields?.code === '040')
const status050 = saveStatus.find((row: any) => row?.fields?.code === '050')
const status060 = saveStatus.find((row: any) => row?.fields?.code === '060')
const status070 = saveStatus.find((row: any) => row?.fields?.code === '070')

const todayDate = dateFormatter(new Date())

//single report preview section

const handleGetReportPreview = async (
  dispatch: any,
  previewReportUrl: any,
  id: any,
  setPdfUrl: any
) => {
  try {
    const response = await axiosInstance.get(`${previewReportUrl}/${id}`, {
      responseType: 'arraybuffer',
    })
    const blob = new Blob([response.data], { type: 'application/pdf' })
    const pdfBlobUrl = URL.createObjectURL(blob)
    setPdfUrl(pdfBlobUrl)
  } catch (error) {
    dispatch(alertErrorAction(messages.previewDataFailed))
  }
}

//
const handleIsDefaultCheck = (
  setFieldValue: any,
  dispatch: any,
  apiUrl: any,
  keyName: any
) => {
  axiosInstance
    .get(`${apiUrl}?is_default=true`)
    .then((response: any) => {
      let data = response?.data?.results[0]
      if (response.status === 200) {
        setFieldValue(`${keyName}`, data ?? null)
      }
    })
    .catch((error: any) => {
      dispatch(alertErrorAction(`データの取得に失敗しました。`))
    })
}
//
function extractFilenameFromURL(url: string) {
  const urlSegments = decodeURIComponent(url).split('/')
  return urlSegments[urlSegments.length - 1]
}
//
function generateQueryString(data: any): string {
  if (data?.company && data?.selectReportForProjectListType) {
    data.company = { ...data.company }
    data.company.queryName = data.selectReportForProjectListType.queryName
  } else if (data?.translator && data?.selectReportType) {
    // data.translator = { ...data.translator }
    // data.translator.queryName = data.selectReportType.queryName
  }
  const queryString = Object.entries(data)
    .map(([key, value]: [string, any]) => {
      console.log(key, 'keykeykey')
      if (
        typeof value === 'object' &&
        value !== null &&
        !Array.isArray(value)
      ) {
        if (
          key !== 'selectReportForProjectListType' &&
          key !== 'selectReportType'
        ) {
          if (value.queryName) {
            return `${value.queryName}=${encodeURIComponent(value?.id)}`
          } else {
            return `${key}=${encodeURIComponent(value?.id)}`
          }
        } else {
          if (value.keyName) {
            return `${value.keyName}=${true}`
          }
        }
      } else if (
        (typeof value === 'string' || typeof value === 'number') &&
        value !== ''
      ) {
        return `${key}=${encodeURIComponent(value)}`
      } else if (typeof value === 'boolean' && value === true) {
        return `${key}=${value}`
      } else {
        return ''
      }
    })
    .filter(Boolean)
    .join('&')

  return queryString
}

//
function validateEmail(value: string | undefined) {
  if (!value) {
    return true
  }
  // Regular expression to check if the email address has a valid format
  const emailRegex = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
  return emailRegex.test(value)
}

export const patternThreeDigisAfterComma = /^\d+(\.\d{0,3})?$/

function removeLastDot(path: string) {
  const parts = path.split('.')
  parts.pop()
  const result = parts.join('.')
  return result
}

//
//
function combineRequestFiles(data: any): any[] {
  const combinedFiles: any[] = []
  console.log(combinedFiles, 'combinedFilescombinedFilescombinedFiles')
  data.case_details.forEach((detail: any) => {
    const requestFiles = detail?.request_files
    console.log(requestFiles, 'requestFilesrequestFiles')

    requestFiles.forEach((requestFile: any) => {
      const file = requestFile?.file
      console.log(file, 'filefilefilefilefilefile')
      combinedFiles.push(file)
      // Check if the file object is not empty
      // if (Object.keys(file).length > 0) {
      //     combinedFiles.push(file);
      // }
    })
  })

  return combinedFiles
}

async function fetchDataUrl(urlfile: any) {
  if (
    urlfile &&
    typeof urlfile === 'string' &&
    (urlfile.startsWith('http://') || urlfile.startsWith('https://'))
  ) {
    try {
      const response = await fetch(urlfile)
      if (!response.ok) {
        throw new Error(`HTTP Error: ${response.status}`)
      }
      const blob = await response.blob()

      // Extract file extension from the URL
      const urlParts = urlfile.split('/')
      const fileNameWithExtension = urlParts[urlParts.length - 1]
      console.log(fileNameWithExtension, 'fileNameWithExtension')
      const fileNameWithoutExtension = fileNameWithExtension
        .split('.')
        .slice(0, -1)
        .join('.')
      const extension = fileNameWithExtension.split('.').pop()
      const fileNameWithBlobExtension = `${fileNameWithoutExtension}.${extension}`
      const contentType = await getContentTypeFromUrl2(urlfile)
      const file = new File(
        [blob],
        decodeURIComponent(fileNameWithBlobExtension),
        {
          type: contentType,
        }
      )

      return file
    } catch (error) {
      console.error('Error:', error)
      return null
    }
  } else {
    return null
  }
}

function downloadPDF(apiUrl: string, fileNameData: string) {
  axiosInstance
    .get(apiUrl, { responseType: 'blob' })
    .then((response) => {
      const blob = response.data
      const link = document.createElement('a')
      const fileName = fileNameData + '.pdf'
      const blobUrl = window.URL.createObjectURL(blob)

      link.href = blobUrl
      link.download = fileName
      link.click()
      window.URL.revokeObjectURL(blobUrl)
    })
    .catch((error) => {
      console.error('Error downloading PDF:', error)
    })
}
//
const handleSendButtonBackend = (dispatch: any, apiUrl: any) => {
  return axiosInstance
    .patch(`${apiUrl}`)
    .then((response: any) => {
      dispatch(alertSuccessAction(t(messages.sendSuccess)))
    })
    .catch((error: any) => {
      dispatch(alertErrorAction(t(messages.sendFail)))
    })
}

const handleIsJobNoReadOnlyCheck = (
  setFieldValue: any,
  dispatch: any,
  apiUrl: any,
  keyName: string
) => {
  axiosInstance
    .get(`${apiUrl}`)
    .then((response: any) => {
      let data = response?.data?.set_read_only
      if (response.status === 200) {
        console.log(data, 'datadatadatadata')
        setFieldValue(`${keyName}`, data)
      }
    })
    .catch((error: any) => {
      dispatch(alertErrorAction(`データの取得に失敗しました。`))
    })
}

//
function truncateString(string: string, maxLength?: number) {
  let maxLengthNumber = maxLength ?? 10
  console.log(string?.length, 'char')
  if (string?.length <= maxLengthNumber) {
    return string
  } else {
    return string?.substring(0, maxLengthNumber) + '...'
  }
}
//
const extractErrorMessage = (error: any) => {
  // if 401 return auth fail error
  if (error?.response?.status === 401) return t('authMessage.authFail')
  const defaultErrorMessage = '内部サーバによるエラーが発生しました。'

  let errorValue = defaultErrorMessage

  if (error?.response?.data) {
    const deepSearch = (obj: any) => {
      if (Array.isArray(error.response.data)) {
        errorValue = error.response.data[0]
      }
      for (const key in obj) {
        if (Array.isArray(obj[key])) {
          const firstArrayElement = obj[key][0]
          if (firstArrayElement) {
            if (Array.isArray(firstArrayElement)) {
              errorValue = firstArrayElement[0]
              break
            } else if (typeof firstArrayElement === 'object') {
              deepSearch(firstArrayElement)
            } else {
              errorValue = firstArrayElement
              break
            }
          }
        }
      }
    }

    deepSearch(error.response.data)
  }

  return errorValue
}

// for AutoCalcuate message
const extractAutoCalculateErrorMessage = (error: any) => {
  // if 401 return auth fail error
  if (error?.response?.status === 401) return t('authMessage.authFail')
  const defaultErrorMessage = '自動計算できません。見積依頼してください。'

  let errorValue = defaultErrorMessage

  if (error?.response?.data) {
    const deepSearch = (obj: any) => {
      for (const key in obj) {
        if (Array.isArray(obj[key])) {
          const firstArrayElement = obj[key][0]
          if (firstArrayElement) {
            if (Array.isArray(firstArrayElement)) {
              errorValue = firstArrayElement[0]
              break
            } else if (typeof firstArrayElement === 'object') {
              deepSearch(firstArrayElement)
            } else {
              errorValue = firstArrayElement
              break
            }
          }
        }
      }
    }

    deepSearch(error.response.data)
  }

  return errorValue
}

const handleCancelButtonBackend = (
  dispatch: any,
  apiUrl: any,
  queryParams: string,
  navLink: string,
  nav: any
) => {
  axiosInstance
    .post(`${apiUrl}`)
    .then((response: any) => {
      dispatch(alertSuccessAction(t(messages.updateSuccess)))
      if (response && nav) {
        nav(`${navLink}${queryParams}`)
      }
    })
    .catch((error: any) => {
      dispatch(alertErrorAction(extractErrorMessage(error)))
    })
}
const handleDeleteButtonBackend = (
  dispatch: any,
  apiUrl: any,
  queryParams: string,
  navLink: string,
  nav: any,
  message: any
) => {
  axiosInstance
    .delete(`${apiUrl}`)
    .then((response: any) => {
      dispatch(alertSuccessAction(message))
      if (response && nav) {
        nav(`${navLink}${queryParams}`)
      }
    })
    .catch((error: any) => {
      dispatch(alertErrorAction(extractErrorMessage(error)))
    })
}

const extractErrorMessageYup = (error: any) => {
  const defaultErrorMessage = 'Internal Server Error'
  let errorValue = defaultErrorMessage
  console.log(error, 'errorerrorerrorerror')

  if (error) {
    const deepSearch = (obj: any): any => {
      for (const key in obj) {
        return obj[key]
      }
      return null
    }
    const result = deepSearch(error[0])
    console.log(result, 'result')
    if (result) {
      // const [key] = Object.keys(result);
      // errorValue = `${key}: ${result[key]}`;
      errorValue = result
    }
  }

  return errorValue
}

function filterValues(values: Record<string, any>): Record<string, any> {
  const filteredValues: Record<string, any> = Object.entries(values).reduce(
    (acc, [key, value]) => {
      if (value !== '' && value !== null) {
        if (typeof value === 'object') {
          acc[key] = Object.entries(value).reduce((acc1, [key1, value1]) => {
            if (typeof value1 !== 'object') {
              acc1[key1] = value1
            }
            return acc1
          }, {} as Record<string, any>)
        } else {
          acc[key] = value
        }
      }
      return acc
    },
    {} as Record<string, any>
  )

  return filteredValues
}

export {
  filterValues,
  extractErrorMessageYup,
  handleDeleteButtonBackend,
  extractErrorMessage,
  extractAutoCalculateErrorMessage,
  truncateString,
  handleIsJobNoReadOnlyCheck,
  handleSendButtonBackend,
  downloadPDF,
  fetchDataUrl,
  handleCancelButtonBackend,
  combineRequestFiles,
  removeLastDot,
  getContentTypeFromUrl2,
  validateEmail,
  generateQueryString,
  extractFilenameFromURL,
  handleIsDefaultCheck,
  status000,
  status010,
  status020,
  status030,
  status040,
  status050,
  status060,
  status070,
  getSearchURL,
  downloadFile,
  removeValuesAndKeepKeys,
  fetchImageAsBinary,
  dataURLtoFile,
  fetchImageAsFile,
  getContentTypeFromUrl,
  formatJPDateTime,
  todayDate,
  handleGetReportPreview,
}
