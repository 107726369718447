import React, { useEffect } from 'react'

import {
  Card,
  CardHeader,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'

import { t } from 'i18next'

import { authSelector } from '../../Login/Redux/selector'
import { formatJPDateTime } from '../../Utils/AppFunction'
import { useAppDispatch, useAppSelector } from '../CustomHooks/appHooks'
import { workflowSelector } from '../Workflow/Redux/selector'
import {
  clearWorkflow,
  getWorkflowRequest,
} from '../Workflow/Redux/workflowSlice'
import { WorkflowDetail } from '../Workflow/Types'
import { WORKFLOW_DETAIL_STATUS } from '../Workflow/constants'

export interface workFlowProps {
  id?: number
}

const WorkFlowHistoryTable = () => {
  const { workflow, loading } = useAppSelector(workflowSelector)
  const { user } = useAppSelector(authSelector)

  const statusNames = React.useMemo(() => {
    return new Map(
      Object.entries(WORKFLOW_DETAIL_STATUS).map(([k, v]) => [v, k])
    )
  }, [])

  const historyDetails = React.useMemo(() => {
    if (!workflow) return []
    const finishedDetails = workflow.workflow_details.filter(
      ({ status }) => status > WORKFLOW_DETAIL_STATUS.PENDING
    )
    if (user?.is_staff) {
      return finishedDetails
    } else {
      return finishedDetails.filter(({ stage }) => stage === 2) // ToDo: Stage 2 for client Users Detail
    }
  }, [workflow, user])
  return (
    <>
      {/* //-------------------------------------------approval history----------------------- */}
      <Card sx={{ mb: 2 }}>
        <CardHeader
          title={
            <Typography
              variant="h6"
              sx={{ fontWeight: 'bold', color: 'white' }}
            >
              {t('workFlow.approvalHistory')}
            </Typography>
          }
          sx={{
            backgroundColor: '#f57c00',
            height: '28px',
          }}
        />
        <TableContainer component={Paper} sx={{}}>
          <Table sx={{ minWidth: 700 }} aria-label="spanning table">
            <TableHead>
              <TableRow>
                <TableCell>{t('common.number')}</TableCell>
                <TableCell>{t('workFlow.tableItems.userName')}</TableCell>
                <TableCell>{t('workFlow.tableItems.status')}</TableCell>
                <TableCell>{t('workFlow.tableItems.comment')}</TableCell>
                <TableCell>{t('workFlow.tableItems.processingData')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {historyDetails.map((row: WorkflowDetail, index: number) => (
                <TableRow key={index}>
                  <TableCell> {index + 1} </TableCell>
                  <TableCell>{row.user_fullname || row.user_email}</TableCell>
                  <TableCell>
                    {row.stage === 2 && workflow?.status === 2
                      ? t(`workFlow.status.APPROVINGREQUEST`)
                      : row.stage === 2 && workflow?.status === 3
                      ? workflow.workflow_details[workflow.progress].id ===
                        row.id
                        ? t(`workFlow.status.ORDER`)
                        : t(`workFlow.status.APPROVINGREQUEST`)
                      : t(`workFlow.status.${statusNames.get(row.status)}`)}
                  </TableCell>
                  <TableCell> {row.comment} </TableCell>
                  <TableCell>{formatJPDateTime(row.processing_date)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </>
  )
}
export default WorkFlowHistoryTable
