import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material'

import { Visibility, VisibilityOff } from '@mui/icons-material'

import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import { changePasswordRequest } from '../Login/Redux/authSlice'
import { authSelector } from '../Login/Redux/selector'
import { ChangePasswordPayload } from '../Login/Redux/types'
import {
  useAppDispatch,
  useAppSelector,
} from '../ReusableComponent/CustomHooks/appHooks'
import Modal from '../ReusableComponent/Modal'

type ChangePasswordProps = {
  showModal: boolean
  setShowModal: (showModal: boolean) => void
}

export const ChangePasswordModal = ({
  showModal,
  setShowModal,
}: ChangePasswordProps) => {
  const [showPassword, setShowPassword] = React.useState(false)
  const toggleShowPassword = () => setShowPassword((show) => !show)
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { loading } = useAppSelector(authSelector)
  const handleSubmit = (values: ChangePasswordPayload) => {
    dispatch(changePasswordRequest(values))
  }

  return (
    <Modal
      showModal={showModal}
      setShowModal={setShowModal}
      title={t('auth.changePassword')}
      fullScreen={false}
    >
      <Grid container>
        <Grid item xs={12}>
          <Formik
            initialValues={{
              old_password: '',
              new_password1: '',
              new_password2: '',
            }}
            validationSchema={Yup.object().shape({
              old_password: Yup.string().required(
                t('validationMessage.required')
              ),
              new_password1: Yup.string().required(
                t('validationMessage.required')
              ),
              new_password2: Yup.string()
                .required(t('validationMessage.required'))
                .oneOf(
                  [Yup.ref('new_password1'), ''],
                  t('validationMessage.passwordNotMatch')
                ),
            })}
            onSubmit={handleSubmit}
          >
            {({ values, handleChange, handleBlur, touched, errors }) => {
              return (
                <Form autoComplete="off">
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        id="old_password"
                        name="old_password"
                        type={showPassword ? 'text' : 'password'}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        fullWidth
                        label={t('auth.oldPassword')}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={toggleShowPassword}
                                // onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        error={
                          touched.new_password1 && Boolean(errors.new_password1)
                        }
                        helperText={
                          touched.new_password1 && errors.new_password1
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="new_password1"
                        name="new_password1"
                        type={showPassword ? 'text' : 'password'}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        fullWidth
                        label={t('auth.newPassword')}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={toggleShowPassword}
                                // onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        error={
                          touched.new_password1 && Boolean(errors.new_password1)
                        }
                        helperText={
                          touched.new_password1 && errors.new_password1
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="new_password2"
                        name="new_password2"
                        type={showPassword ? 'text' : 'password'}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        fullWidth
                        label={t('auth.confirmNewPassword')}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle confirm password visibility"
                                onClick={toggleShowPassword}
                                // onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        error={
                          touched.new_password2 && Boolean(errors.new_password2)
                        }
                        helperText={
                          touched.new_password2 && errors.new_password2
                        }
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{ display: 'flex', flexDirection: 'row-reverse' }}
                    >
                      <Button
                        size="large"
                        variant="contained"
                        type="submit"
                        disabled={loading}
                      >
                        {t('auth.submit')}
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              )
            }}
          </Formik>
        </Grid>
      </Grid>
    </Modal>
  )
}
