import { memo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Grid } from '@mui/material'

import { invoiceHqUploadProps } from '../../pages/ExportInvoiceDataList/InvoiceHqPages/InvoiceHq'
import { userMadeInvoiceUploadProps } from '../../pages/JobList/IssueInvoicePages/UsermadeInvoiceUpload'
import { orderReceptUpload } from '../../pages/ProjectList/UploadReceptLayout/OrderReceptUpload'
import { PurchaseOrderUploadPayload } from '../../pages/ProjectList/UploadReceptLayout/PurchaseOrderUpload'
import { initialTranslatorInvoiceUploadProps } from '../../pages/WaitingBillList/Redux/types'
import AlertDialogLocal from '../AlertDialog/AlertDialogLocal'
import AppDropZone from '../AppDropZone/AppDropZone'

interface FileUploadAndWithdrawBtnProps {
  edit: string | null
  index: number
  field?: string
  allowedTypes: string[]
  setFieldValue: any
  disableFileDropZone: boolean | undefined
  showFileDownloadIcon: boolean | undefined
  uploadScreenInPlaceOfReport?: boolean
  handleWithdrawalSubmit: () => void
  value:
    | initialTranslatorInvoiceUploadProps
    | userMadeInvoiceUploadProps
    | invoiceHqUploadProps
    | PurchaseOrderUploadPayload
    | orderReceptUpload
  showWithdrawButton: boolean
}

const FileUploadAndWithdrawBtn = ({
  edit,
  index,
  allowedTypes,
  setFieldValue,
  disableFileDropZone,
  showFileDownloadIcon,
  uploadScreenInPlaceOfReport,
  handleWithdrawalSubmit,
  value,
  showWithdrawButton,
  field,
}: FileUploadAndWithdrawBtnProps) => {
  const [showWithdrawalConfirmation, setShowWithdrawalConfirmation] =
    useState(false)
  const { t } = useTranslation()
  return (
    <Grid
      container
      spacing={2}
      sx={{
        mt: 2,
        mb: 2,
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Grid item xs={!showWithdrawButton ? 12 : 10}>
        <AppDropZone
          multiple={true}
          index={index}
          field={field}
          allowedTypes={allowedTypes}
          maxFileSize={1024 * 1024 * 1024 * 1024}
          value={value}
          onUpload={(file: File) => {
            setFieldValue(`${field}.file`, file)
          }}
          setFieldValue={setFieldValue}
          disabled={disableFileDropZone}
          showDownloadIconOnly={showFileDownloadIcon}
          uploadScreenInPlaceOfReport={uploadScreenInPlaceOfReport}
        />
      </Grid>

      <Grid
        item
        xs={2}
        sx={{
          display: showWithdrawButton ? 'block' : 'none',
        }}
      >
        <Grid item xs={4} sx={{ ml: 0, mt: 0 }}>
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              setShowWithdrawalConfirmation(true)
            }}
          >
            取り下げ
          </Button>
          <AlertDialogLocal
            openDialog={showWithdrawalConfirmation}
            setOpenDialog={setShowWithdrawalConfirmation}
            message={t('confirmMessage.withdrawal')}
            handleSubmit={handleWithdrawalSubmit}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}
export default memo(FileUploadAndWithdrawBtn)
