import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'

import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material'

import dayjs from 'dayjs'
import 'dayjs/locale/ja'
import { t } from 'i18next'

import menuConfig from '../../menuConfig'
import {
  clearCommonUrlFilterData,
  initialRowsPerPageAndPages,
} from '../CommonAppRedux/commonAppSlice'
import { authSelector } from '../Login/Redux/selector'
import {
  useAppDispatch,
  useAppSelector,
} from '../ReusableComponent/CustomHooks/appHooks'

const MenuItems = () => {
  const { pathname } = useLocation() //get the current path
  const [activeItem, setActiveItem] = React.useState<any>(null)
  const { is_staff } = useAppSelector(authSelector)
  console.log(is_staff, 'isStaffisStaff')
  const modifyMenu = menuConfig.filter((row: any) => row?.isStaff === is_staff)
  console.log(modifyMenu, 'modifyMenumodifyMenu')

  //set the current path as the active item if it matches one of the sidebar menu
  // useEffect(() => {
  //   menuConfig?.forEach((item) => {
  //     if (item?.to === pathname) {
  //       setActiveItem(item)
  //     }
  //   })
  // }, [pathname])

  useEffect(() => {
    // @ts-ignore
    const menu = is_staff ? menuConfig[0] : modifyMenu[1]
    // @ts-ignore
    const storedMenuItem = JSON.parse(localStorage.getItem('activeMenuItem'))

    if (pathname === menu?.to || !storedMenuItem) {
      setActiveItem(menu)
      localStorage.setItem('activeMenuItem', JSON.stringify(menu))
    } else {
      setActiveItem(storedMenuItem)
    }
    // alert("run");
    dispatch(clearCommonUrlFilterData())
  }, [pathname, is_staff])

  // useEffect(() => {
  //   const handleBeforeUnload = (event: BeforeUnloadEvent) => {

  //     // @ts-ignore
  //     if (event.currentTarget.performance.navigation.type === PerformanceNavigation.TYPE_RELOAD) {
  //       return;
  //     }
  //     localStorage.removeItem('activeMenuItem');
  //   };

  //   window.addEventListener('beforeload', handleBeforeUnload);

  //   // return () => {
  //   //   window.removeEventListener('beforeload', handleBeforeUnload);
  //   // };
  // }, []);

  dayjs.locale(process.env.REACT_APP_LOCALE)
  const dispatch = useAppDispatch()
  const handleMenuItemClick = (item: any) => {
    setActiveItem(item)
    localStorage.setItem('activeMenuItem', JSON.stringify(item))
  }
  
  // React.useEffect(() => {
  //   dispatch(initialRowsPerPageAndPages())
  // }, [activeItem])
  return (
    <React.Fragment>
      {modifyMenu.map((item) => (
        <Link
          key={item.key}
          to={item.to}
          style={{
            textDecoration: 'none',
            color: 'inherit',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <ListItemButton
            key={item.key}
            onClick={() => handleMenuItemClick(item)}
            className={pathname === item.to ? 'active' : ''}
            style={{
              backgroundColor:
                activeItem?.to === item.to ? '#c1d8ef' : 'transparent',
              marginLeft: item?.spacing,
            }}
          >
            <ListItemText>
              {t(`menuName.${item?.translationTitle}`)}
            </ListItemText>
          </ListItemButton>
        </Link>
      ))}
    </React.Fragment>
  )
}

export default MenuItems
