import { t } from 'i18next'

export const autoCalculateColumns = [
  {
    id: 'item_name',
    label: `${'autoCalculateTable.itemName'}`,
    minWidth: 100,
  },
  {
    id: 'quantity',
    label: `${'autoCalculateTable.quantity'}`,
    qty: true,
    minWidth: 100,
  },
  {
    id: 'unit_cost',
    label: `${'autoCalculateTable.unitCost'}`,
    minWidth: 50,
    qty: true,
    type: 'number',
  },
  {
    id: 'amount',
    label: `${'autoCalculateTable.amount'}`,
    minWidth: 100,
    amount: true,
    currency: true,
  },
  {
    id: 'tax_amount',
    label: `${'autoCalculateTable.taxAmount'}`,
    minWidth: 100,
    amount: true,
    currency: true,
  },
]
