import { Action } from '@reduxjs/toolkit'
import { t } from 'i18next'
import { combineEpics } from 'redux-observable'
import { from, switchMap, of } from 'rxjs'
import { Observable } from 'rxjs'
import {
  map,
  filter,
  catchError,
  ignoreElements,
  mergeMap,
  finalize,
} from 'rxjs/operators'

import {
  alertErrorAction,
  alertSuccessAction,
} from '../../CommonAppRedux/commonAppSlice'
import { dispatchAction, stateAction } from '../../Utils/globalTypes'
import {
  login,
  getCurrentUser,
  logoutApi,
  resetPassword,
  confirmPassword,
  register,
  changePassword,
} from './api'
import {
  loginRequest,
  loginSuccess,
  logoutRequest,
  logout,
  forgetPasswordRequest,
  forgetPasswordSuccess,
  forgetPasswordFail,
  resetPasswordRequest,
  resetPasswordSuccess,
  resetPasswordFail,
  getCurrentUserRequest,
  getCurrentUserSuccess,
  registerRequest,
  changePasswordRequest,
  changePasswordSuccess,
  changePasswordFail,
} from './authSlice'
import { extractErrorMessage } from '../../Utils/AppFunction'

const loginEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(loginRequest.match),
    switchMap((action) =>
      from(login(action.payload)).pipe(
        map((response) => loginSuccess(response.data)),
        catchError((error) => {
          return of(logout(), alertErrorAction(extractErrorMessage(error)));
        })
      )
    )
  )

const registerEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(registerRequest.match),
    switchMap((action) =>
      from(register(action.payload)).pipe(
        map((response) => loginSuccess(response.data)),
        catchError((error) => {
          return of(logout(), alertErrorAction(extractErrorMessage(error)));
        })
      )
    )
  )

const logoutEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(logoutRequest.match),
    switchMap(() =>
      from(logoutApi()).pipe(
        map(() => logout()),
        catchError(() => of(logout()))
      )
    )
  )

const forgetPasswordRequestEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(forgetPasswordRequest.match),
    mergeMap((action) =>
      from(resetPassword(action.payload)).pipe(
        switchMap(() =>
          of(
            forgetPasswordSuccess(),
            alertSuccessAction(t('authMessage.resetPasswordSuccess'))
          )
        ),
        catchError((error) => {
          return of(forgetPasswordFail(), alertErrorAction(extractErrorMessage(error)));
        })

      )
    )
  )

const resetPasswordRequestEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(resetPasswordRequest.match),
    mergeMap((action) =>
      from(confirmPassword(action.payload)).pipe(
        switchMap(() =>
          of(
            alertSuccessAction(t('authMessage.confirmPasswordSuccess')),
            resetPasswordSuccess()
          )
        ),
        catchError((error) => {
          return of(resetPasswordFail(), alertErrorAction(extractErrorMessage(error)));
        })
      )
    )
  )

const changePasswordRequestEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(changePasswordRequest.match),
    mergeMap((action) =>
      from(changePassword(action.payload)).pipe(
        switchMap(() =>
          of(
            alertSuccessAction(t('authMessage.changePasswordSuccess')),
            changePasswordSuccess()
          )
        ),
        catchError((error) => {
          return of(changePasswordFail(), alertErrorAction(extractErrorMessage(error)));
        })
      )
    )
  )

const getCurrentUserEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> =>
  action$.pipe(
    filter(getCurrentUserRequest.match),
    switchMap(() =>
      from(getCurrentUser()).pipe(
        map((response) => getCurrentUserSuccess(response.data)),
        catchError((error) => {
          return of(logout(), alertErrorAction(extractErrorMessage(error)));
        })
      )
    )
  )

export const authEpics = combineEpics(
  loginEpic,
  registerEpic,
  getCurrentUserEpic,
  logoutEpic,
  resetPasswordRequestEpic,
  forgetPasswordRequestEpic,
  changePasswordRequestEpic
)
