import React, { Suspense, lazy, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import { Typography } from '@mui/material'

import { commonAppSelector } from '../../../CommonAppRedux/selector'
import {
  useAppSelector,
  useAppDispatch,
} from '../../../ReusableComponent/CustomHooks/appHooks'
import { getWaitingBillListRequest } from '../Redux/waitingBillListSlice'
import { initialRowsPerPageAndPages } from '../../../CommonAppRedux/commonAppSlice'
import { getCommonStorePreviousURLPath } from '../../../CommonLocalStorageRedux/localReduxSlice'
// import { getCommonStorePreviousURLPath } from '../../../CommonLocalStorageRedux/localReduxSlice'

const WaitingBillListTable = lazy(() => import('./WaitingBillListTable'))

const WaitingBillList = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { rowsPerPage, page } = useAppSelector(commonAppSelector)
  useEffect(() => {
    dispatch(
      getWaitingBillListRequest({
        page,
        rowsPerPage,
      })
    )
  }, [dispatch, page, rowsPerPage])

  const location=useLocation();
  useEffect(() => {
    dispatch(getCommonStorePreviousURLPath([{pathName:location?.pathname,search: location?.search }]))
  }, [])
  return (
    <div>
      <Typography variant="h6" fontWeight="bold" sx={{ padding: '16px' }}>
        {t('menuName.waitingBillList')}
      </Typography>
      <Suspense fallback={<></>}>
        <WaitingBillListTable />
      </Suspense>
    </div>
  )
}

export default WaitingBillList
