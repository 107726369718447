import axios from 'axios'

import { store } from '../../store'
// import useTokenStore from '../../store/tokenStore'
import { alertErrorAction, alertInfoAction } from '../CommonAppRedux/commonAppSlice'
import { t } from 'i18next'

// import Cookies from 'js-cookie'
// import { store } from '../../store'
// import { loginFail } from '../Login/Redux/authSlice'

export const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_PREFIX}`,
})
// Authorization: Cookies.get('accessToken')
//   ? `Bearer ${Cookies.get('accessToken')}`
//   : '',
axiosInstance.interceptors.request.use((config: any) => {
  if (!window.navigator.onLine) {
    store?.dispatch(alertInfoAction(t('common.noInternet')));
  } else {
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    }
    config.params = config.params || {}
    if (config.data !== undefined) {
      if (config.data instanceof FormData) {
        config.headers['Content-Type'] = 'multipart/form-data'
      } else {
        if (!config.headers?.['Content-Type']) {
          config.headers['Content-Type'] = 'application/json'
        }
        if (typeof config.data === 'string') {
          try {
            const parsedData = JSON.parse(config.data)
            config.data = parsedData
          } catch (error) {}
        }
      }
    }
    return config
  }
})

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    //handle no interner error
    if (error === 'No Internet') {
      store?.dispatch(alertInfoAction(t('common.noInternet')));
    } else if (error.toJSON().message === 'Network Error') {
      store?.dispatch(alertInfoAction(t('common.networkError')));
    }
    //handle page not found
    else if (error.response?.status === 404) {
      // alert('Page Not Found')
    }
    //handle internal serve error
    else if (error?.response?.status === 500 || error?.response?.status > 500) {
      // alert('Internal Server Error')
    }
    //handle forbidden status
    else if (error?.response?.status == 403) {
      // alert('Error: Permission not granted ')
    }
    // const originalRequest = error.config
    // if (
    //   error?.response.status === 401 &&
    //   originalRequest.url === 'api call for refresh token'
    // ) {
    //   alert('Unauthorized')
    // }
    // const value = Cookies.get('refreshToken')
    if (error?.response?.status === 401) {
      // useTokenStore.getState().removeToken()
      // Cookies.remove('accessToken')
      // alert('No cookies found')
      // store.dispatch(loginFail())
    }
    return Promise.reject(error)
  }
)
