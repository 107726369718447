import { Action } from '@reduxjs/toolkit'
import { t } from 'i18next'
import { combineEpics } from 'redux-observable'
import { Observable } from 'rxjs'
import { filter, map, switchMap } from 'rxjs/operators'

import { getNext, getPrevious } from '../../../CommonAppRedux/api'
import {
  alertErrorAction,
  alertSuccessAction,
} from '../../../CommonAppRedux/commonAppSlice'
import messages from '../../../Utils/ValidationMessage'
import { defaultPage, defaultRowsPerPage } from '../../../Utils/globalConstant'
import { dispatchAction, stateAction } from '../../../Utils/globalTypes'
import {
  loadingInspectionTaskStateList, // getInspectionTaskStateListSuccess,
  // getInspectionTaskStateListFail,
  // getInspectionTaskStateListRequest,
  getInspectionTaskStateListDetailSuccess,
  getInspectionTaskStateListDetailFail,
  getInspectionTaskStateListDetailRequest, // getInspectionTaskStateListDetailByIdRequest,
  // getInspectionTaskStateListDetailByIdSuccess,
  // getInspectionTaskStateListDetailByIdFail,
  getOrderDetailListNextRequest,
  getOrderDetailListPreviousRequest,
  bulkUpdateInspectionCertificateByIdRequest,
  bulkUpdateInspectionCertificateByIdSuccess,
  bulkUpdateInspectionCertificateByIdFail,
} from './InspectionTaskStateListSlice'
import {
  bulkInspectionCertificateByIdEpic, // getInspectionTaskStateList,
  getInspectionTaskStateListDetail, // getInspectionTaskStateListDetailById,
} from './api'
import { extractErrorMessage } from '../../../Utils/AppFunction'

// const getInspectionTaskStateListEpic = (
//   action$: Observable<Action>,
//   _: stateAction,
//   { dispatch }: dispatchAction
// ): Observable<Action> => {
//   return action$.pipe(
//     filter(getInspectionTaskStateListRequest.match),
//     switchMap(async (action) => {
//       dispatch(loadingInspectionTaskStateList())
//       try {
//         const response = await getInspectionTaskStateList(action.payload)
//         return { payload: response.data }
//       } catch (e) {
//         dispatch(alertErrorAction(extractErrorMessage(e)))
//         return { error: e }
//       }
//     }),
//     map((action) =>
//       action?.payload ? getInspectionTaskStateListSuccess(action?.payload) : getInspectionTaskStateListFail()
//     )
//   )
// }

//get next
const getOrderDetailListNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getOrderDetailListNextRequest.match),
    switchMap(async (action) => {
      try {
        const response = await getNext(action.payload)
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? getInspectionTaskStateListDetailSuccess(action?.payload)
        : getInspectionTaskStateListDetailFail()
    )
  )
//get previous
const getOrderDetailListPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getOrderDetailListPreviousRequest.match),
    switchMap(async (action) => {
      try {
        const response = await getPrevious(action.payload)
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? getInspectionTaskStateListDetailSuccess(action?.payload)
        : getInspectionTaskStateListDetailFail()
    )
  )

const getInspectionTaskStateListDetailEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(getInspectionTaskStateListDetailRequest.match),
    switchMap(async (action) => {
      dispatch(loadingInspectionTaskStateList())
      try {
        const response = await getInspectionTaskStateListDetail(action.payload)
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? getInspectionTaskStateListDetailSuccess(action?.payload)
        : getInspectionTaskStateListDetailFail()
    )
  )
}

// const getInspectionTaskStateListDetailByIdEpic = (
//   action$: Observable<Action>,
//   _: stateAction,
//   { dispatch }: dispatchAction
// ): Observable<Action> => {
//   return action$.pipe(
//     filter(getInspectionTaskStateListDetailByIdRequest.match),
//     switchMap(async (action) => {
//       dispatch(loadingInspectionTaskStateList())
//       try {
//         const response = await getInspectionTaskStateListDetailById(action.payload)
//         return { payload: response.data }
//       } catch (e) {
//         dispatch(alertErrorAction(extractErrorMessage(e)))
//         return { error: e }
//       }
//     }),
//     map((action) =>
//       action?.payload
//         ? getInspectionTaskStateListDetailByIdSuccess(action?.payload)
//         : getInspectionTaskStateListDetailByIdFail()
//     )
//   )
// }

const bulkBulkInspectionCertificateByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(bulkUpdateInspectionCertificateByIdRequest.match),
    switchMap(async ({ payload: { values, page, rowsPerPage } }) => {
      dispatch(loadingInspectionTaskStateList())
      try {
        const body = JSON.stringify(values)
        const response = await bulkInspectionCertificateByIdEpic(body)
        if (response) {
          dispatch(
            getInspectionTaskStateListDetailRequest({
              page: page,
              rowsPerPage: rowsPerPage,
            })
          )
          dispatch(alertSuccessAction(messages.updateSuccess))
        }
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? bulkUpdateInspectionCertificateByIdSuccess(action?.payload)
        : bulkUpdateInspectionCertificateByIdFail()
    )
  )
}

//

export const inspectionTaskStateListEpics = combineEpics(
  // getInspectionTaskStateListEpic,

  getInspectionTaskStateListDetailEpic,
  // getInspectionTaskStateListDetailByIdEpic,

  getOrderDetailListNext,
  getOrderDetailListPrevious,
  bulkBulkInspectionCertificateByIdEpic
  //translator certificate
  //user made invoice
)
