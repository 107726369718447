import { Action } from '@reduxjs/toolkit'
import { t } from 'i18next'
import { combineEpics } from 'redux-observable'
import { Observable } from 'rxjs'
import { filter, map, switchMap } from 'rxjs/operators'

import { getNext, getPrevious } from '../../../CommonAppRedux/api'
import {
  alertErrorAction,
  alertSuccessAction,
} from '../../../CommonAppRedux/commonAppSlice'
import { extractErrorMessage } from '../../../Utils/AppFunction'
import messages from '../../../Utils/ValidationMessage'
import { dispatchAction, stateAction } from '../../../Utils/globalTypes'
import {
  createInvoiceHq,
  deleteExportInvoiceDataId,
  getExportInvoiceDataList,
  getInvoiceHqById,
  updateInvoiceHq,
} from './api'
import {
  loadingExportInvoiceDataList,
  getExportInvoiceDataListSuccess,
  getExportInvoiceDataListFail,
  getExportInvoiceDataListRequest,
  getExportInvoiceDataListNextRequest,
  getExportInvoiceDataListPreviousRequest,
  deleteExportInvoiceDataListByIdRequest,
  deleteExportInvoiceDataListByIdSuccess,
  deleteExportInvoiceDataListByIdFail,
  getInvoiceHqByIdRequest,
  getInvoiceHqByIdSuccess,
  getInvoiceHqByIdFail,
  updateInvoiceHqRequest,
  clearInvoiceHq,
  updateInvoiceHqSuccess,
  updateInvoiceHqFail,
  createInvoiceHqRequest,
  createInvoiceHqSuccess,
  createInvoiceHqFail,
  clearHardResetExportInvoiceHQ,
} from './exportInvoiceDataSlice'

const getExportInvoiceDataListEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(getExportInvoiceDataListRequest.match),
    switchMap(async (action) => {
      dispatch(loadingExportInvoiceDataList())
      try {
        const response = await getExportInvoiceDataList(action.payload)
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? getExportInvoiceDataListSuccess(action?.payload)
        : getExportInvoiceDataListFail()
    )
  )
}

//get next
const getExportInvoiceDataListNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getExportInvoiceDataListNextRequest.match),
    switchMap(async (action) => {
      try {
        const response = await getNext(action.payload)
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? getExportInvoiceDataListSuccess(action?.payload)
        : getExportInvoiceDataListFail()
    )
  )
//get previous
const getExportInvoiceDataListPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getExportInvoiceDataListPreviousRequest.match),
    switchMap(async (action) => {
      try {
        const response = await getPrevious(action.payload)
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? getExportInvoiceDataListSuccess(action?.payload)
        : getExportInvoiceDataListFail()
    )
  )

const deleteExportInvoiceDataIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(deleteExportInvoiceDataListByIdRequest.match),
    switchMap(async ({ payload: { id, page, rowsPerPage } }) => {
      try {
        const response = await deleteExportInvoiceDataId(id)
        if (response) {
          dispatch(
            getExportInvoiceDataListRequest({
              page: page,
              rowsPerPage: rowsPerPage,
            })
          )
        }
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? deleteExportInvoiceDataListByIdSuccess()
        : deleteExportInvoiceDataListByIdFail()
    )
  )
}

// get InvoiceHq
const getInvoiceHqByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(getInvoiceHqByIdRequest.match),
    switchMap(async (action) => {
      try {
        const response = await getInvoiceHqById(action.payload)
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? getInvoiceHqByIdSuccess(action?.payload)
        : getInvoiceHqByIdFail()
    )
  )
}

// create InvoiceHq
const createInvoiceHqEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(createInvoiceHqRequest.match),
    switchMap(
      async ({
        payload: { parentId, values, nav, actions, invoiceDataId },
      }) => {
        const { file, ...restValues } = values
        try {
          const body = new FormData()
          for (const [key, value] of Object.entries(restValues)) {
            //@ts-ignore
            body.append(key, value)
          }
          if (parentId) {
            body.append('parent_id', parentId)
          } else {
            body.append('parent_id', '-1')
          }

          if (file) {
            body.append('file', file)
          } else {
            body.append('file', '')
          }
          const response = await createInvoiceHq(body)
          if (response) {
            const responseId = response?.data?.parent_id
            actions.resetForm()
            dispatch(alertSuccessAction(t(messages.createSuccess)))
            const queryParams = `?invoice-data-id=${invoiceDataId}&invoicehq-parent-id=${responseId}&edit=${true}`
            nav(`/invoicehq${queryParams}`)
          }
          return { payload: response.data }
        } catch (e) {
          dispatch(alertErrorAction(extractErrorMessage(e)))
          return { error: e }
        }
      }
    ),
    map((action) =>
      action?.payload
        ? createInvoiceHqSuccess(action?.payload)
        : createInvoiceHqFail()
    )
  )
}

//update InvoiceHq
const updateInvoiceHqEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(updateInvoiceHqRequest.match),
    switchMap(
      async ({ payload: { id, values, nav, actions, invoiceDataId } }) => {
        const { file, ...restValues } = values
        try {
          const body = new FormData()
          for (const [key, value] of Object.entries(restValues)) {
            //@ts-ignore
            body.append(key, value)
          }
          if (file === null) {
            body.append('file', '')
          } else if (typeof file !== 'string') {
            body.append('file', file)
          }
          const response = await updateInvoiceHq(id, body)
          if (response) {
            const responseId = response?.data?.parent_id
            if (actions) {
              actions.resetForm()
            }
            dispatch(alertSuccessAction(t(messages.updateSuccess)))
            dispatch(clearHardResetExportInvoiceHQ())
            const queryParams = `?invoice-data-id=${invoiceDataId}&invoicehq-parent-id=${responseId}&edit=${true}`
            await nav(`/invoicehq${queryParams}`)
          }
          return { payload: response.data }
        } catch (e) {
          dispatch(alertErrorAction(extractErrorMessage(e)))
          return { error: e }
        }
      }
    ),
    map((action) =>
      action?.payload
        ? updateInvoiceHqSuccess(action?.payload)
        : updateInvoiceHqFail()
    )
  )
}

export const exportInvoiceDataListEpics = combineEpics(
  getExportInvoiceDataListEpic,
  getExportInvoiceDataListNext,
  getExportInvoiceDataListPrevious,
  deleteExportInvoiceDataIdEpic,
  //invoice hq
  getInvoiceHqByIdEpic,
  updateInvoiceHqEpic,
  createInvoiceHqEpic
)
