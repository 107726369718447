import { axiosInstance } from '../../../Utils/axios'
import { getApiProps } from '../../../Utils/globalTypes'

const orderListModURL = '/order-list'
const orderListDetailModURL = '/order-detail'

export const getOrderList = (data: getApiProps) => {
  const { rowsPerPage, page } = data
  return axiosInstance.get(
    `${orderListModURL}?offset=${rowsPerPage * page}&limit=${rowsPerPage}`
  )
}
export const getOrderListDetail = (data: getApiProps) => {
  const { rowsPerPage, page, filterData } = data
  return axiosInstance.get(
    `${orderListModURL}?offset=${rowsPerPage * page}&limit=${rowsPerPage}&${filterData?.requester ? `create_user=${filterData?.requester?.id}` : ''
    }`
  )
}
export const getOrderListDetailById = (id: number) => {
  return axiosInstance.get(`${orderListModURL}/${id}`)
}
