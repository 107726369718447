import { generateQueryString } from '../../../Utils/AppFunction'
import { axiosInstance } from '../../../Utils/axios'
import { getApiProps } from '../../../Utils/globalTypes'

const exportInvoiceDataList = '/invoice-data-export-result'
const invoiceHqUpload = '/invoicehq'

export const getExportInvoiceDataList = (data: getApiProps) => {
  const { rowsPerPage, page, filterData } = data
  return axiosInstance.get(
    `${exportInvoiceDataList}?offset=${
      rowsPerPage * page
    }&limit=${rowsPerPage}${
      filterData ? `&${generateQueryString(filterData) ?? ''}` : ''
    }`
  )
}

export const deleteExportInvoiceDataId = (id: number) => {
  return axiosInstance.delete(`${exportInvoiceDataList}/${id}`)
}

//InvoiceHq  by id
export const getInvoiceHqById = (id: number) => {
  return axiosInstance.get(`${invoiceHqUpload}/invoicehqs?parent_id=${id}`)
}

//update Invoice Hq
export const updateInvoiceHq = (id: number, body: string | FormData) => {
  return axiosInstance.patch(`${invoiceHqUpload}/${id}`, body)
}

//create invoice hq
export const createInvoiceHq = (body: string | FormData) => {
  return axiosInstance.post(`${invoiceHqUpload}`, body)
}
