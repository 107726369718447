import { combineReducers } from '@reduxjs/toolkit'

import CommonAppSlice from '../components/CommonAppRedux/commonAppSlice'
import Auth from '../components/Login/Redux/authSlice'
import workflow from '../components/ReusableComponent/Workflow/Redux/workflowSlice'
import BillableList from '../components/pages/BillableList/Redux/billableSlice'
import BilledList from '../components/pages/BilledList/Redux/billedSlice'
import CompleteDeliveryList from '../components/pages/CompleteDeliveryList/Redux/completeDeliveryListSlice'
import CompleteQuotationList from '../components/pages/CompleteQuotationList/Redux/completeQuotationListSlices'
import DeliverableList from '../components/pages/DeliverableList/Redux/deliverableSlice'
import ExportInvoiceDataList from '../components/pages/ExportInvoiceDataList/Redux/exportInvoiceDataSlice'
import JobList from '../components/pages/JobList/Redux/jobListSlice'
import OrderList from '../components/pages/OrderList/Redux/orderListSlice'
import ProjectList from '../components/pages/ProjectList/Redux/projectListSlice'
import ProjectListClient from '../components/pages/ProjectListClient/Redux/projectListClientSlice'
import QuotationStateList from '../components/pages/QuotationStateList/Redux/quotationStateSlice'
import TaskStateList from '../components/pages/TaskStateList/Redux/taskStateListSlice'
import WaitingBillList from '../components/pages/WaitingBillList/Redux/waitingBillListSlice'
import  InspectionTaskStateList  from '../components/pages/InspectionTaskStateList/Redux/InspectionTaskStateListSlice'
import  QuotationRequestList  from '../components/pages/QuotationRequestList/Redux/quotationRequestListSlice'
import InputTrackRecord  from '../components/pages/JobList/InputTrackRecordScreen/Redux/inputTrackRecordSlice'
import LocalStorageRedux  from '../components/CommonLocalStorageRedux/localReduxSlice'

export const rootReducer = combineReducers({
  //auth
  localStorageRedux:LocalStorageRedux,
  auth: Auth,
  jobList: JobList,
  commonApp: CommonAppSlice,
  projectList: ProjectList,
  billedList: BilledList,
  quotationStateList: QuotationStateList,
  deliverableList: DeliverableList,
  billableList: BillableList,
  workflow,
  completeDeliveryList: CompleteDeliveryList,
  projectListClient: ProjectListClient,
  exportInvoiceDataList: ExportInvoiceDataList,
  orderList: OrderList,
  waitingBillList: WaitingBillList,
  completeQuotationList:CompleteQuotationList,
  inspectionTaskStateList:InspectionTaskStateList,
  taskStateList: TaskStateList,
  quotationRequestList:QuotationRequestList,
  inputTrackRecord:InputTrackRecord,
})
