import { Suspense, lazy, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { Typography } from '@mui/material'

import { commonAppSelector } from '../../../CommonAppRedux/selector'
import {
  useAppDispatch,
  useAppSelector,
} from '../../../ReusableComponent/CustomHooks/appHooks'
import { getCompleteDeliveryRequest } from '../Redux/completeDeliveryListSlice'
import { useLocation } from 'react-router-dom'
import { getCommonStorePreviousURLPath } from '../../../CommonLocalStorageRedux/localReduxSlice'

const CompleteDeliveryListTable = lazy(
  () => import('./CompleteDeliveryListTable')
)

const CompleteDeliveryList = () => {
  const { page, rowsPerPage, tableCheckBoxData } =
    useAppSelector(commonAppSelector)
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(
      getCompleteDeliveryRequest({
        page,
        rowsPerPage,
      })
    )
  }, [dispatch, page, rowsPerPage])
  const location=useLocation();
  useEffect(() => {
    dispatch(getCommonStorePreviousURLPath([{pathName:location?.pathname,search: location?.search }]))
  }, [])

  return (
    <div>
      <Typography variant="h6" fontWeight="bold" sx={{ padding: '16px' }}>
        {t('menuName.completeDeliveryList')}
      </Typography>
      <Suspense fallback={<></>}>
        <CompleteDeliveryListTable />
      </Suspense>
    </div>
  )
}

export default CompleteDeliveryList
