import spinner from '../../Assets/gif/spinner.gif'

const Spinner = ({ colorSpinner }: any) => {
  return (
    <img
      src={spinner}
      alt="spinner"
      color={colorSpinner}
      height="30px"
      //   className="ml-4"
    />
  )
}

export default Spinner
