import { axiosInstance } from '../../../Utils/axios'
import { WorkflowAction, WorkflowActionPayload } from '../Types'

const workflowUrl = '/workflows'
const commonUserUrl = '/common/user'

//get worklow by id
export const getWorkflowById = (id: number) => {
  return axiosInstance.get(`${workflowUrl}/${id}`)
}

// update workflow
export const updateWorkflowById = (
  id: number,
  body: WorkflowActionPayload,
  action: WorkflowAction
) => {
  return axiosInstance.patch(`${workflowUrl}/${id}/${action}`, body)
}

// get users
export const getUsers = (query: string) => {
  if (query) {
    return axiosInstance.get(`${commonUserUrl}?search=${query}&is_staff=true`)
  } else {
    return axiosInstance.get(`${commonUserUrl}?is_staff=true`)
  }
}
