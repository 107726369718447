import React, {
  useRef,
  useState,
  useEffect,
  useLayoutEffect,
  ChangeEvent,
  useMemo,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import { Card, Grid } from '@mui/material'

import { FieldArray, Formik } from 'formik'
import * as Yup from 'yup'

import {
  alertInfoAction,
  getCommonSaleTaxRequest,
} from '../../../CommonAppRedux/commonAppSlice'
import { commonAppSelector } from '../../../CommonAppRedux/selector'
import AppDatePicker from '../../../ReusableComponent/AppDatePicker/AppDatePicker'
import AppTextField from '../../../ReusableComponent/AppTextField/AppTextField'
import { NumericFormatCustom } from '../../../ReusableComponent/CommonReportTable/CommonReportTable'
import CompanySelect from '../../../ReusableComponent/CompanySelect/CompanySelect'
import {
  useAppDispatch,
  useAppSelector,
} from '../../../ReusableComponent/CustomHooks/appHooks'
import { useInputSearch } from '../../../ReusableComponent/CustomHooks/useInputSearch'
import FocusElementOnInvalidValidation from '../../../ReusableComponent/FocusElementIfInvalid/FocusElementOnInvalidValidation'
import AddNewInvoiceFormBtn from '../../../ReusableComponent/MultipleUploadScreen/AddNewInvoiceFormBtn'
import CardTitle from '../../../ReusableComponent/MultipleUploadScreen/CardTitle'
import FileUploadAndWithdrawBtn from '../../../ReusableComponent/MultipleUploadScreen/FileUploadAndWithdrawBtn'
import {
  datePickerStyles,
  gridStyles,
  cardStyles,
} from '../../../ReusableComponent/MultipleUploadScreen/styles'
import SaveFooterComponent from '../../../ReusableComponent/SaveFooterButton/SaveFooterButton'
import { workflowSelector } from '../../../ReusableComponent/Workflow/Redux/selector'
import { getWorkflowRequest } from '../../../ReusableComponent/Workflow/Redux/workflowSlice'
import { dateFormatter } from '../../../Utils/AppFormatFunction'
import { downloadFile } from '../../../Utils/AppFunction'
import messages from '../../../Utils/ValidationMessage'
import { defaultRowsPerPage } from '../../../Utils/globalConstant'
import { query } from '../../../Utils/globalTypes'
import {
  clearHardResetJobList,
  clearJobDetailInvoice,
  clearUserMadeInvoice,
  createUserMadeInvoiceRequest,
  getJobDetailInvoiceByIdRequest,
  getUserMadeInvoiceByIdRequest,
  getMultipleUserMadeInvoiceByIdRequest,
  updateUserMadeInvoiceRequest,
} from '../Redux/jobListSlice'
import { jobListSelector } from '../Redux/selector'
import { company } from '../Types'

export interface userMadeInvoiceUploadProps {
  id?: number
  company: company | null | any
  issue_date: string | Date | null
  amount: number
  tax_amount: number
  tax_excluded_amount: number
  file: string | File | null
  url: string | null
  history?: any
  workflow?: any
  version?: number
  withdrawal_flag: boolean
  date_recorded?: string | Date | null
}

export interface inititalUserMadeInvoiceUploadProps {
  invoice_details: userMadeInvoiceUploadProps[]
}

const UsermadeInvoiceUpload = ({ showInReportScreen }: any) => {
  const { t } = useTranslation()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const invoiceId = searchParams.get('invoice-id')
  // const userMadeInvoiceId = searchParams.get('usermade-invoice-id')
  const edit = searchParams.get('edit')
  const [isWithdraw, setIsWithdraw] = useState(false)
  const { state } = location
  const previousUrl = state?.path
  const nav = useNavigate()
  const { defaultSalesTax } = useAppSelector(commonAppSelector)
  const dispatch = useAppDispatch()
  const { userMadeInvoice, jobDetailInvoice, loadingCreateUserMadeInvoice } =
    useAppSelector(jobListSelector)
  // jobDetailInvoice?.usermade_invoice
  //all reducer action and clear function implement start
  useLayoutEffect(() => {
    if (invoiceId) {
      dispatch(getJobDetailInvoiceByIdRequest(invoiceId))
    } else if (showInReportScreen) {
      dispatch(getJobDetailInvoiceByIdRequest(showInReportScreen))
    } else {
      dispatch(clearJobDetailInvoice())
    }
    dispatch(getCommonSaleTaxRequest(defaultRowsPerPage))
    return () => {
      dispatch(clearUserMadeInvoice())
      dispatch(clearHardResetJobList())
    }
  }, [invoiceId, location, dispatch, edit, showInReportScreen])
  useLayoutEffect(() => {
    if (jobDetailInvoice?.usermade_invoice_exists) {
      if (
        showInReportScreen &&
        jobDetailInvoice?.usermade_invoice_unWithdrawed
      ) {
        dispatch(
          getUserMadeInvoiceByIdRequest(
            jobDetailInvoice?.usermade_invoice_unWithdrawed
          )
        )
      } else {
        dispatch(getMultipleUserMadeInvoiceByIdRequest(jobDetailInvoice?.id))
        dispatch(getWorkflowRequest(jobDetailInvoice?.workflow))
      }
    } else {
      dispatch(clearUserMadeInvoice())
    }
    return () => {
      dispatch(clearUserMadeInvoice())
      dispatch(clearHardResetJobList())
    }
  }, [jobDetailInvoice, showInReportScreen, location, dispatch, edit])

  const [useMadeInvoiceObject, setUserMadeInvoiceObject] = useState<any>(null)

  // For the Case-Invoice Screen's
  useLayoutEffect(() => {
    if (userMadeInvoice && typeof userMadeInvoice === 'object') {
      setUserMadeInvoiceObject(userMadeInvoice)
    }
  }, [userMadeInvoice])

  //all reducer action and clear function implement End
  const initialUserMadeInvoiceData: userMadeInvoiceUploadProps = {
    id: useMadeInvoiceObject?.id ?? undefined,
    issue_date: useMadeInvoiceObject?.issue_date ?? dateFormatter(new Date()),
    company: useMadeInvoiceObject?.company ?? '',
    amount: useMadeInvoiceObject?.amount ?? 0,
    tax_amount: useMadeInvoiceObject?.tax_amount ?? 0,
    tax_excluded_amount: useMadeInvoiceObject?.tax_excluded_amount ?? 0,
    file: useMadeInvoiceObject?.file ?? '',
    url: useMadeInvoiceObject?.url ?? '',
    version: useMadeInvoiceObject?.version ?? 0,
    withdrawal_flag: false,
  }

  const initialState: inititalUserMadeInvoiceUploadProps = {
    invoice_details:
      userMadeInvoice && userMadeInvoice.length > 0
        ? userMadeInvoice
        : [initialUserMadeInvoiceData],
  }

  const sales_tax_default: number = defaultSalesTax?.tax_rate
  const sales_tax_default_percentage: number = sales_tax_default / 100

  const allowedTypes = ['']
  const validationSchema = Yup.object().shape({
    invoice_details: Yup.array().of(
      Yup.object().shape(
        {
          url: Yup.string().when(['file'], ([file], schema, context) => {
            return file === null
              ? schema
                  .required(t(messages.required))
                  .url(t(messages.invalidUrl))
              : schema.url(t(messages.invalidUrl))
          }),

          file: Yup.mixed().when(['url'], ([url], schema) => {
            return url === undefined
              ? schema
                  .required(t(messages.required))
                  .test(
                    'fileType',
                    t(messages.invalidFileType),
                    (value: any) => {
                      if (!value) {
                        return true
                      }
                      return (
                        allowedTypes.includes(value.type) ||
                        allowedTypes.includes('')
                      )
                    }
                  )
              : schema.nullable()
          }),
        },
        [['url', 'file']]
      )
    ),
  })

  const onSubmit = async (
    values: inititalUserMadeInvoiceUploadProps,
    actions: any
  ) => {
    const firstInvoiceDetail = values.invoice_details[0]
    const updatedValues = {
      ...firstInvoiceDetail,
      invoice: invoiceId,
      company: firstInvoiceDetail?.company?.id ?? '',
      file: firstInvoiceDetail?.file === '' ? null : firstInvoiceDetail.file,
      issue_date: firstInvoiceDetail?.issue_date
        ? firstInvoiceDetail?.issue_date
        : '',
      date_recorded: firstInvoiceDetail?.date_recorded ?? '',
      version: firstInvoiceDetail?.version ?? 1,
      withdrawal_flag: firstInvoiceDetail?.withdrawal_flag
        ? firstInvoiceDetail?.withdrawal_flag
        : isWithdraw
        ? isWithdraw
        : false,
    }

    if (!edit || (edit && !firstInvoiceDetail?.id && userMadeInvoice?.length)) {
      dispatch(
        createUserMadeInvoiceRequest({
          values: updatedValues,
          nav,
          actions,
          invoiceId,
          previousUrl,
        })
      )
    } else {
      dispatch(
        updateUserMadeInvoiceRequest({
          values: updatedValues,
          id: firstInvoiceDetail?.id,
          nav,
          actions,
          invoiceId,
          previousUrl,
        })
      )
    }
  }
  const focus_SaveBtn = useRef(null)

  const [query, setQuery] = useState<query>({
    search: '',
    url: '',
  })
  useInputSearch(query)

  const { workflow } = useAppSelector(workflowSelector)
  const [readOnlyMode, setReadOnlyMode] = useState(false)
  const [workFlowCompleted, setWorkFlowCompleted] = useState(false)
  useEffect(() => {
    if (workflow) {
      if (workflow?.status !== 1) {
        setReadOnlyMode(true)
      } else {
        setReadOnlyMode(false)
      }
      if (workflow?.status === 3) {
        setWorkFlowCompleted(true)
      }
    } else {
      setReadOnlyMode(false)
    }
    return () => {
      setReadOnlyMode(false)
    }
  }, [workflow, userMadeInvoice])
  // preCalculated the values
  const DETAIL_NAME = 'invoice_details'
  const isAllInvoiceWithdrawed: boolean | null = useMemo(() => {
    return (
      userMadeInvoice &&
      userMadeInvoice?.length > 0 &&
      userMadeInvoice?.every(
        (invc: userMadeInvoiceUploadProps) => invc.withdrawal_flag
      )
    )
  }, [userMadeInvoice])

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={initialState}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          values,
          handleChange,
          setFieldValue,
          handleBlur,
          touched,
          errors,
          submitForm,
          setValues,
        }) => {
          const handleSave = () => {
            setIsWithdraw(false)
            submitForm()
          }

          const handleDownload = () => {
            if (values.invoice_details[0]?.file === null) {
              dispatch(alertInfoAction(t(messages.doesntExist)))
            } else {
              downloadFile(values.invoice_details[0]?.file)
            }
          }

          const buttonConfigs = [
            ...[
              {
                label: 'common.upload',
                handler: handleSave,
                focusBtn: focus_SaveBtn,
                isShowAlert: true,
                loading: loadingCreateUserMadeInvoice,
                message: t('confirmMessage.upload'),
              },
            ],
            ...(userMadeInvoice &&
            userMadeInvoice.length > 0 &&
            values.invoice_details[0]?.file !== ''
              ? [
                  {
                    label: 'common.download',
                    handler: handleDownload,
                    isShowAlert: false,
                  },
                ]
              : []),
          ]
          const handleButtonAction = (action: string) => {
            const buttonConfig = buttonConfigs.find(
              (config) => config.label === action
            )
            if (buttonConfig) {
              const { handler } = buttonConfig
              handler()
            }
          }

          return (
            <>
              {!showInReportScreen && (
                <Grid item sx={{ mb: 4 }}>
                  <SaveFooterComponent
                    handleClick={handleButtonAction}
                    buttonConfigs={buttonConfigs}
                  />
                </Grid>
              )}
              <CardTitle name={t('uploadRecept.usermadeInvoiceUpload.name')} />
              <AddNewInvoiceFormBtn
                displayAddButton={isAllInvoiceWithdrawed && !showInReportScreen}
                onAddButtonClick={() => {
                  if (
                    userMadeInvoice &&
                    values.invoice_details.length <= userMadeInvoice?.length //to only add the form once
                  ) {
                    // Update Formik's values with the new object at the beginning
                    setValues({
                      ...values,
                      invoice_details: [
                        initialUserMadeInvoiceData,
                        ...values.invoice_details,
                      ],
                    })
                  }
                }}
              />
              <FieldArray
                name={DETAIL_NAME}
                render={({ push }: any) => (
                  <React.Fragment>
                    {values.invoice_details?.map(
                      (value: userMadeInvoiceUploadProps, index: number) => {
                        const field = `${DETAIL_NAME}[${index}]`
                        const isDisabled =
                          showInReportScreen ||
                          (readOnlyMode && value?.id) ||
                          value?.withdrawal_flag
                            ? true
                            : false
                        let isShowWithDrawButton = false
                        if (
                          value?.id &&
                          !showInReportScreen &&
                          !value?.withdrawal_flag
                        ) {
                          isShowWithDrawButton =
                            (value?.file || value?.url) &&
                            (workFlowCompleted || !readOnlyMode)
                              ? true
                              : false
                        } else {
                          isShowWithDrawButton = false
                        }
                        return (
                          <Card
                            key={value?.id || index}
                            sx={{
                              ...cardStyles,
                              backgroundColor: value?.withdrawal_flag
                                ? '#D3D3D3'
                                : '',
                            }}
                          >
                            <Grid container spacing={2} sx={gridStyles}>
                              <CompanySelect
                                label={t(
                                  'uploadRecept.usermadeInvoiceUpload.company'
                                )}
                                value={value?.company}
                                field={`${field}.company`}
                                setFieldValue={setFieldValue}
                                handleBlur={handleBlur}
                                isDisabled={isDisabled}
                                setQuery={setQuery}
                              />
                              <AppDatePicker
                                xs={2.5}
                                name={`${field}.issue_date`}
                                disabled={isDisabled}
                                label={t(
                                  'uploadRecept.usermadeInvoiceUpload.issueDate'
                                )}
                                value={value?.issue_date}
                                onChange={(value: any) => {
                                  setFieldValue(
                                    `${field}.issue_date`,
                                    dateFormatter(value)
                                  )
                                }}
                                variant="outlined"
                                sx={datePickerStyles}
                              />
                              <AppTextField
                                xs={2.3}
                                name={`${field}.tax_excluded_amount`}
                                disabled={isDisabled}
                                readOnlyMode
                                onBlur={handleBlur}
                                value={value?.tax_excluded_amount}
                                label={t(
                                  'uploadRecept.invoiceHq.taxExcludedAmount'
                                )}
                                InputProps={{
                                  inputComponent: NumericFormatCustom as any,
                                }}
                              />
                              <AppTextField
                                xs={2.3}
                                name={`${field}.tax_amount`}
                                disabled={isDisabled}
                                readOnlyJobNo
                                onBlur={handleBlur}
                                value={value?.tax_amount}
                                label={t('uploadRecept.invoiceHq.taxAmount')}
                                onChange={(
                                  e: ChangeEvent<HTMLInputElement>
                                ) => {
                                  const tax_amount: number = parseFloat(
                                    e.target.value
                                  )
                                  setFieldValue(
                                    `${field}.tax_amount`,
                                    tax_amount
                                  )
                                  setFieldValue(
                                    `${field}.tax_excluded_amount`,
                                    +value?.amount - tax_amount
                                  )
                                }}
                                InputProps={{
                                  inputComponent: NumericFormatCustom as any,
                                }}
                              />
                              <AppTextField
                                xs={2.5}
                                name={`${field}.amount`}
                                disabled={isDisabled}
                                onBlur={handleBlur}
                                value={value?.amount}
                                label={t(
                                  'uploadRecept.usermadeInvoiceUpload.amount'
                                )}
                                onChange={(
                                  e: ChangeEvent<HTMLInputElement>
                                ) => {
                                  const amount: number = parseFloat(
                                    e.target.value
                                  )
                                  {
                                    const tax_excluded_amount_calculated =
                                      Math.round(
                                        amount /
                                          (1 + sales_tax_default_percentage)
                                      )
                                    setFieldValue(`${field}.amount`, amount)
                                    setFieldValue(
                                      `${field}.tax_amount`,
                                      amount - tax_excluded_amount_calculated
                                    )
                                    setFieldValue(
                                      `${field}.tax_excluded_amount`,
                                      tax_excluded_amount_calculated
                                    )
                                  }
                                }}
                                InputProps={{
                                  inputComponent: NumericFormatCustom as any,
                                }}
                              />
                            </Grid>

                            <FileUploadAndWithdrawBtn
                              edit={edit}
                              value={value}
                              index={index}
                              field={field}
                              allowedTypes={allowedTypes}
                              setFieldValue={setFieldValue}
                              disableFileDropZone={isDisabled}
                              uploadScreenInPlaceOfReport={
                                showInReportScreen ? true : false
                              }
                              showFileDownloadIcon={
                                isDisabled || value?.id ? true : false
                              }
                              showWithdrawButton={isShowWithDrawButton}
                              handleWithdrawalSubmit={async () => {
                                setIsWithdraw(true)
                                await submitForm()
                              }}
                            />
                          </Card>
                        )
                      }
                    )}
                  </React.Fragment>
                )}
              />
              <FocusElementOnInvalidValidation />
            </>
          )
        }}
      </Formik>
    </div>
  )
}

export default UsermadeInvoiceUpload
