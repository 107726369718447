import { createSearchParams } from 'react-router-dom'

import { saveAs } from 'file-saver'

import { generateQueryString } from '../../../Utils/AppFunction'
import { axiosInstance } from '../../../Utils/axios'
import { getApiProps } from '../../../Utils/globalTypes'

const jobListModURL = '/job'
const jobListDetailModURL = '/job-detail'
const jobTaskStockingDateModURL = '/job-task-stocking-date'
const purchaseOrderDetailsURL = '/purchase-order-detail'
const userMadeInvoiceUpload = '/usermade-invoice-upload'
const translatorPurchaseOrderDetailsURL = '/translator-purchase-order-detail'
const translatorInvoiceUpload = '/translator-invoice'

// export const getJobListSalesExport = async (data: getApiProps) => {
//   const { rowsPerPage, page, filterData } = data
//   console.log(data, 'what is data')
//   try {
//     const { data, headers } = await axiosInstance.get(
//       `${jobListDetailModURL}?offset=${
//         rowsPerPage * page
//       }&limit=${rowsPerPage}${
//         filterData?.selectReportType
//           ? `&${generateQueryString(filterData)}`
//           : ''
//       }${
//         filterData?.work_in_progress
//           ? `&work_in_progress=${filterData?.work_in_progress}`
//           : ''
//       }`,
//       {
//         paramsSerializer: {
//           indexes: null,
//         },
//         headers: {
//           Accept: 'text/csv',
//         },
//         responseType: 'blob',
//       }
//     )
//     const mineType = headers['content-type']
//     const dispostion = headers['content-disposition']
//     const fileName = decodeURI(
//       dispostion.split(';')[1].split('=')[1].replace('"', '').replace('"', '')
//     )
//     const blob = new Blob([data], { type: mineType })
//     saveAs(blob, fileName)
//     return data;
//   } catch (error) {
//     console.log(error)
//   }
// }
export const getJobListSalesExport = async (data: getApiProps) => {
  const { rowsPerPage, page, filterData } = data
  console.log('generateQueryString(filterData)', filterData)
  try {
    const { data, headers } = await axiosInstance.get(
      `${jobListDetailModURL}?offset=${
        rowsPerPage * page
      }&limit=${rowsPerPage}${`${
        filterData ? `&${generateQueryString(filterData)}` : ''
      }`}`,
      {
        paramsSerializer: {
          indexes: null,
        },
        headers: {
          Accept: 'text/csv',
        },
        responseType: 'blob',
      }
    )
    const mineType = headers['content-type']
    const dispostion = headers['content-disposition']
    const fileName = decodeURI(
      dispostion.split(';')[1].split('=')[1].replace('"', '').replace('"', '')
    )
    const blob = new Blob([data], { type: mineType })
    saveAs(blob, fileName)
  } catch (error) {
    console.log(error)
  }
}

export const getJobList = (data: getApiProps) => {
  const { rowsPerPage, page } = data
  return axiosInstance.get(
    `${jobListModURL}?offset=${rowsPerPage * page}&limit=${rowsPerPage}`
  )
}
//get joblist by id
export const getJobListById = (id: number) => {
  return axiosInstance.get(`${jobListModURL}/${id}`)
}

export const createJobDetails = (body: string | FormData) => {
  return axiosInstance.post(`${jobListModURL}`, body)
}

export const updateJobDetails = (id: number, body: string | FormData) => {
  return axiosInstance.patch(`${jobListModURL}/${id}`, body)
}
export const getJobListDetail = (data: getApiProps) => {
  const { rowsPerPage, page, filterData } = data
  console.log(
    filterData,
    'generateQueryStringgenerateQueryStringgenerateQueryString'
  )
  return axiosInstance.get(
    `${jobListDetailModURL}?offset=${
      rowsPerPage * page
    }&limit=${rowsPerPage}${`${
      filterData ? `&${generateQueryString(filterData)}` : ''
    }`}${
      filterData?.work_in_progress
        ? `&work_in_progress=${filterData?.work_in_progress}`
        : ''
    }`
  )
}

//get job detail by id
export const getJobDetailById = (id: number) => {
  return axiosInstance.get(`${jobListDetailModURL}/${id}`)
}

export const getJobListDetailById = (id: number) => {
  return axiosInstance.get(`${jobListModURL}/${id}`)
}

//get common translator api
export const getCommonTranslator = () => {
  return axiosInstance.get(`/common/translator`)
}
//create translator
export const createTranslator = (body: string) => {
  return axiosInstance.post(`/translator-matching`, body)
}

//get common translator  list for tableapi
export const getCommonTranslatorTableList = (data: getApiProps) => {
  const { rowsPerPage, page } = data
  return axiosInstance.get(
    `/job-task?offset=${rowsPerPage * page}&limit=${rowsPerPage}`
  )
}

//get job task by id
export const getJobTaskById = (id: number) => {
  return axiosInstance.get(`/job-task/${id}`)
}

//update job task
export const updateJobTask = (id: number, body: string | FormData) => {
  return axiosInstance.patch(`/job-task/${id}`, body)
}
//create job task
export const createJobTask = (body: string | FormData) => {
  return axiosInstance.post('/job-task', body)
}

//create job detail invoice
export const createIssueInvoice = (body: string | FormData) => {
  return axiosInstance.post(`/invoice`, body)
}
//update job detail Invoice
export const updateIssueInvoice = (id: number, body: string | FormData) => {
  return axiosInstance.patch(`/invoice/${id}`, body)
}
//get invoice by job detail id
export const getInvoiceById = (id: number) => {
  return axiosInstance.get(`/invoice/${id}`)
}
//get filterd translator matching table list
export const getTranslatorFilter = (data: any) => {
  const { id, status, rowsPerPage, page } = data
  return axiosInstance.get(
    `/job-task?translator=${id}&status_code=${status}&offset=${
      rowsPerPage * page
    }&limit=${rowsPerPage}`
  )
}

//purchase order details by id
export const getPurchaseOrderById = (id: number) => {
  return axiosInstance.get(`/translator-purchase-order/${id}`)
}

//create job task purchase order
export const createPurchaseOrder = (body: string | FormData) => {
  return axiosInstance.post(`/translator-purchase-order`, body)
}

//update job task purchase order
export const updatePurchaseOrder = (id: number, body: string | FormData) => {
  return axiosInstance.patch(`/translator-purchase-order/${id}`, body)
}

//TranslatorCertificate by id
export const getTranslatorCertificateById = (id: number) => {
  return axiosInstance.get(`/translator-certificate/${id}`)
}

//create job task purchase order
export const createTranslatorCertificate = (body: string | FormData) => {
  return axiosInstance.post(`/translator-certificate`, body)
}

//update job task purchase order
export const updateTranslatorCertificate = (
  id: number,
  body: string | FormData
) => {
  return axiosInstance.patch(`/translator-certificate/${id}`, body)
}

export const createPurchaseAmountChange = (body: string | FormData) => {
  return axiosInstance.patch(`/translator-detail-certificate-draft`, body)
}

export const getPurchaseChangeAmountById = (id: number) => {
  return axiosInstance.get(
    `/translator-detail-certificate-draft?translator_purchase_order=${id}`
  )
}

// export const createJobTaskCopy = (body: string | FormData) => {
//   return axiosInstance.patch(`/job-task-copy`, body)
// }

export const getJobTaskStockingDateById = (id: number) => {
  return axiosInstance.get(`${jobTaskStockingDateModURL}?job_task=${id}`)
}

//userMadeInvoice  by id
export const getUserMadeInvoiceById = (id: number) => {
  return axiosInstance.get(`${userMadeInvoiceUpload}/${id}`)
}

//Multiple userMadeInvoice
export const getMultipleUserMadeInvoiceById = (invoice_id: number) => {
  return axiosInstance.get(
    `${userMadeInvoiceUpload}/usermade-invoices?invoice_id=${invoice_id}`
  )
}

//create userMadeInvoice
export const createUserMadeInvoice = (body: string | FormData) => {
  return axiosInstance.post(`${userMadeInvoiceUpload}`, body)
}

//update userMadeInvoice
export const updateUserMadeInvoice = (id: number, body: string | FormData) => {
  return axiosInstance.patch(`${userMadeInvoiceUpload}/${id}`, body)
}

export const getTranslatorPurchaseOrderDetailsById = (id: number) => {
  return axiosInstance.get(
    `${translatorPurchaseOrderDetailsURL}?translator_purchase_order=${id}`
  )
}

//translatorInvoice  by id
export const getTranslatorInvoiceUploadByCertificateId = (id: number) => {
  return axiosInstance.get(
    `${translatorInvoiceUpload}/translator-invoices?certificate_id=${id}`
  )
}

//create translatorInvoice
export const createTranslatorInvoiceUpload = (body: string | FormData) => {
  return axiosInstance.post(`${translatorInvoiceUpload}`, body)
}

//update translatorInvoice
export const updateTranslatorInvoiceUpload = (
  id: number,
  body: string | FormData
) => {
  return axiosInstance.patch(`${translatorInvoiceUpload}/${id}`, body)
}

//update inspection withdrawal
export const updateInspectionWithdrawal = (id: number, version: number) => {
  return axiosInstance.post(
    `/translator-certificate/${id}/withdraw-translator-certificate?translator-certificate-version=${version}`
  )
}

///
export const updateJobDetailById = (id: number, body: string | FormData) => {
  return axiosInstance.patch(`${jobListDetailModURL}/${id}`, body)
}

export const createJobDetailById = (body: string | FormData) => {
  return axiosInstance.post(`${jobListDetailModURL}`, body)
}
