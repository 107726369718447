import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { Grid, Typography } from '@mui/material'

import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'

import { Formik } from 'formik'
import { t } from 'i18next'

import { authSelector } from '../../Login/Redux/selector'
import AppDropZone from '../../ReusableComponent/AppDropZone/AppDropZone'
import CollapsableSearch from '../../ReusableComponent/CollapsableSearch/CollapsableSearch'
import {
  useAppDispatch,
  useAppSelector,
} from '../../ReusableComponent/CustomHooks/appHooks'
import SaveFooterComponent from '../../ReusableComponent/SaveFooterButton/SaveFooterButton'
import {
  downloadPDF,
  handleGetReportPreview,
  handleSendButtonBackend,
} from '../../Utils/AppFunction'
import {
  getOrderReceiptByIdRequest,
  getProjectDetailInvoiceByIdRequest,
} from '../ProjectList/Redux/projectListSlice'
import { projectListSelector } from '../ProjectList/Redux/selector'

const PreviewIndex = ({ hideButton, directId, directUrl }: any) => {
  const { projectDetailInvoice, orderReceipt } =
    useAppSelector(projectListSelector)
  const [uploadScreenValues, setUploadScreenValues] = useState<any>(null)
  const [sending, setSending] = useState(false)
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const previewReportUrl = searchParams.get('preview-report-url')
  const fileNameData = searchParams.get('file-name')
  const activeSend = searchParams.get('active-send')
  const caseId = searchParams.get('case-id')

  const id = searchParams?.get('id')
  const dispatch = useAppDispatch()
  const [pdfUrl, setPdfUrl] = useState<string | null>(null)
  useEffect(() => {
    if (caseId && previewReportUrl === 'order-receipt-report') {
      dispatch(getOrderReceiptByIdRequest(id))
    } else if (caseId && previewReportUrl === 'invoice-report') {
      dispatch(getProjectDetailInvoiceByIdRequest(id))
    }
  }, [id, caseId, previewReportUrl, dispatch])
  useEffect(() => {
    if (hideButton) {
      handleGetReportPreview(dispatch, directUrl, directId, setPdfUrl)
    } else if (id) {
      handleGetReportPreview(dispatch, previewReportUrl, id, setPdfUrl)
    }
  }, [previewReportUrl, id, hideButton, directId, directUrl, dispatch])

  useEffect(() => {
    if (
      projectDetailInvoice &&
      Object.keys(projectDetailInvoice).length !== 0
    ) {
      setUploadScreenValues(projectDetailInvoice.usermade_invoice)
    } else if (orderReceipt && Object.keys(orderReceipt).length !== 0) {
      setUploadScreenValues(orderReceipt.usr_md_receipt)
    }
  }, [projectDetailInvoice, orderReceipt])

  const handlePrint = async () => {
    const fileNameDataUpdate = fileNameData ?? 'No Name'
    await downloadPDF(`${previewReportUrl}/${id}`, fileNameDataUpdate)
  }

  const handleSend = async () => {
    setSending(true)
    try {
      if (caseId && previewReportUrl === 'order-receipt-report') {
        // /api/v1/order-receipt/{id}/send
        const apiUrl = `order-receipt/${id}/send`
        await handleSendButtonBackend(dispatch, apiUrl)
      } else if (caseId && previewReportUrl === 'delivery-note-report') {
        const apiUrl = `delivery-note/${id}/send`
        await handleSendButtonBackend(dispatch, apiUrl)
      } else if (previewReportUrl === 'translator-certificate-report') {
        const apiUrl = `translator-certificate/${id}/send`
        await handleSendButtonBackend(dispatch, apiUrl)
      }
    } finally {
      setSending(false)
    }
  }
  const navigate = useNavigate()
  const handleBackToCase = () => {
    const queryParams = `?id=${caseId}&edit=${true}`
    navigate(`/create-agency-project${queryParams}`, {
      state: { path: location?.pathname + location?.search },
    })
  }
  const { is_staff } = useAppSelector(authSelector)

  const buttonConfigs = [
    // {
    //   label: 'common.print',
    //   handler: handlePrint,
    // },
    ...(pdfUrl
      ? [
          {
            label: 'common.print',
            handler: handlePrint,
            isShowAlert: false,
          },
        ]
      : []),
    ...(is_staff && activeSend && pdfUrl
      ? [
          {
            label: 'common.send',
            handler: handleSend,
            isShowAlert: true,
            loading: sending,
            message: t('confirmMessage.send'),
          },
        ]
      : []),
    ...(caseId
      ? [
          {
            label: 'common.backToCase',
            handler: handleBackToCase,
          },
        ]
      : []),
  ]
  const handleButtonAction = (action: string) => {
    const buttonConfig = buttonConfigs.find((config) => config.label === action)
    if (buttonConfig) {
      const { handler } = buttonConfig
      handler()
    }
  }
  const allowedTypes = ['']
  return (
    <>
      {!hideButton && (
        <Grid item sx={{ mb: 4 }}>
          <SaveFooterComponent
            handleClick={handleButtonAction}
            buttonConfigs={buttonConfigs}
          />
        </Grid>
      )}
      <CollapsableSearch searchLabel="common.preview" byDefault={true}>
        {uploadScreenValues && Object.keys(uploadScreenValues).length !== 0 ? (
          <Formik initialValues={{}} onSubmit={() => {}}>
            {({ setFieldValue }) => {
              return (
                <AppDropZone
                  multiple={false}
                  allowedTypes={allowedTypes}
                  maxFileSize={1024 * 1024 * 1024 * 1024}
                  value={uploadScreenValues}
                  setFieldValue={setFieldValue}
                  onUpload={() => {}}
                  showDownloadIconOnly={true}
                  disabled={true}
                  previewBtn={true}
                />
              )
            }}
          </Formik>
        ) : pdfUrl ? (
          <iframe
            key={pdfUrl}
            src={pdfUrl}
            title="preview"
            style={{ height: '70vh', width: '100%', border: 'none' }}
            allowFullScreen
          />
        ) : (
          <Grid
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              minHeight: '70vh',
            }}
          >
            <PictureAsPdfIcon style={{ fontSize: 48, marginBottom: 16 }} />
            <Typography variant="subtitle1" align="center">
              {t('common.previewReportNotFound')}
            </Typography>
          </Grid>
        )}
      </CollapsableSearch>
    </>
  )
}

export default PreviewIndex

// src="https://basponccollege.org/LMS/EMaterial/Science/Comp/HVP/JS%20Notes.pdf"
