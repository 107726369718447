import React, {
  useState,
  useMemo,
  useRef,
  useEffect,
  lazy,
  Suspense,
  useLayoutEffect,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import {
  Fab,
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material'

import DeleteIcon from '@mui/icons-material/Delete'

import { Form, Formik } from 'formik'
import { v4 as uuidv4 } from 'uuid'
import * as Yup from 'yup'

import {
  handleFocusCompanyCommon,
  handleFocusDeliveryStyleCommon,
  handleFocusPaymentTermsCommon,
  handleFocusSaleTaxCommon,
  handleFocusTranslatorLanguageCommon,
  onChangeInputCompanyCommon,
  onChangeInputDeliveryStyleCommon,
  onChangeInputPaymentTermsCommon,
  onChangeInputSaleTaxCommon,
  onChangeInputTranslatorLanguageCommon,
} from '../../../CommonAppRedux/commonAppFunctions'
import { alertInfoAction } from '../../../CommonAppRedux/commonAppSlice'
import { commonAppSelector } from '../../../CommonAppRedux/selector'
import { commonOptionType } from '../../../CustomAlert/types'
import { authSelector } from '../../../Login/Redux/selector'
import AppAutoComplete from '../../../ReusableComponent/AppAutoComplete/AppAutoComplete'
import AppDatePicker from '../../../ReusableComponent/AppDatePicker/AppDatePicker'
import AppTextField from '../../../ReusableComponent/AppTextField/AppTextField'
import AppTitleCard from '../../../ReusableComponent/AppTitleCard/AppTitleCard'
import Comments from '../../../ReusableComponent/Comments/Comments'
import { CommonReportTableProps } from '../../../ReusableComponent/CommonReportTable/Types'
import {
  useAppDispatch,
  useAppSelector,
} from '../../../ReusableComponent/CustomHooks/appHooks'
import { useInputSearch } from '../../../ReusableComponent/CustomHooks/useInputSearch'
import { handleFocus } from '../../../ReusableComponent/FocusElement/focusElement'
import FocusElementOnInvalidValidation from '../../../ReusableComponent/FocusElementIfInvalid/FocusElementOnInvalidValidation'
import FreeSoloInput from '../../../ReusableComponent/FreeSoloInput/FreeSoloInput'
import History from '../../../ReusableComponent/History'
import IssueBarComponent from '../../../ReusableComponent/IssueBarComponent/IssueBarComponent'
import ReasonForChange from '../../../ReusableComponent/ReasonForChange/ReasonForChange'
import SaveFooterComponent from '../../../ReusableComponent/SaveFooterButton/SaveFooterButton'
import WorkFlowPanel from '../../../ReusableComponent/Workflow'
import {
  dateFormatter,
  formateNumberAgain,
} from '../../../Utils/AppFormatFunction'
import { downloadPDF } from '../../../Utils/AppFunction'
import messages, {
  maxCharValidation,
  minCharValidation,
} from '../../../Utils/ValidationMessage'
import { InputChangeEvent, query } from '../../../Utils/globalTypes'
import {
  clearHardResetJobList,
  clearJobDetailInvoice,
  createIssueInvoiceRequest,
  getJobDetailInvoiceByIdRequest,
  updateIssueInvoiceRequest,
  updateJobTaskRequest,
} from '../Redux/jobListSlice'
import { jobListSelector } from '../Redux/selector'
import { initialIssueInvoiceProps } from './Type'

//lazy
const CommonReportTable = lazy(
  () => import('../../../ReusableComponent/CommonReportTable/CommonReportTable')
)

const IssueInvoiceForm = () => {
  const dispatch = useAppDispatch()
  const { jobDetailInvoice, loadingCreateIssueInvoice, loadingUpdateJobTask } =
    useAppSelector(jobListSelector)
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const id = searchParams.get('id')
  const edit = searchParams.get('edit')
  const jobTaskId = searchParams.get('job-task-id')

  const handlePrint = async () => {
    const queryParams = `invoice-report/${id}`
    const fileNameData = jobDetailInvoice?.invoice_no ?? 'No Name'
    await downloadPDF(queryParams, fileNameData)
  }

  const { state } = location
  const previousUrl = state?.path
  let fromBilledList = false
  if (state?.fromBilledList) {
    fromBilledList = true
  }
  //all reducer action and clear function implement start
  useLayoutEffect(() => {
    handleFocusSaleTaxCommon(dispatch)
    if (id) {
      dispatch(getJobDetailInvoiceByIdRequest(id))
    } else {
      dispatch(clearJobDetailInvoice())
      dispatch(clearHardResetJobList())
    }
    return () => {
      dispatch(clearHardResetJobList())
    }
  }, [id, location, dispatch, edit])

  //all reducer action and clear function implement End
  const navigate = useNavigate()
  const {
    tableCheckBoxData,
    defaultSalesTax,
    commonTranslatorLanguages,
    commonCompanies,
    commonPaymentTerms,
    commonDeliveryStyles,
    commonSalesTax,
  } = useAppSelector(commonAppSelector)
  const handleUsermadeInvoice = () => {
    const usermade_invoice = jobDetailInvoice?.usermade_invoice?.id
    if (usermade_invoice) {
      const queryParams = `?invoice-id=${id}&edit=${true}`
      navigate(`/usermade-invoice-upload${queryParams}`, {
        state: { path: location?.pathname + location?.search },
      })
    } else {
      const queryParams = `?invoice-id=${id}`
      navigate(`/usermade-invoice-upload${queryParams}`, {
        state: { path: location?.pathname + location?.search },
      })
    }
  }

  const { t } = useTranslation()

  const columns = [
    // {
    //   id: 'section',
    //   label: `${t('issueInvoice.addIssueInvoice.section')}`,
    //   minWidth: 100,
    //   type: 'number',
    // },
    {
      id: 'item_name',
      label: `${t('issueInvoice.addIssueInvoice.itemName')}`,
      minWidth: 100,
    },
    {
      id: 'quantity',
      label: `${t('issueInvoice.addIssueInvoice.quantity')}`,
      minWidth: 50,
      qty: true,
      currency: true,
      // type: 'number',
    },
    // {
    //   id: 'unit',
    //   label: `${t('issueInvoice.addIssueInvoice.unit')}`,
    //   minWidth: 50,
    // },
    {
      id: 'unit_cost',
      label: `${t('issueInvoice.addIssueInvoice.unitCost')}`,
      minWidth: 50,
      cost: true,
      currency: true,
      // type: 'number',
    },
    {
      id: 'sales_tax',
      label: `${t('issueInvoice.addIssueInvoice.salesTax')}`,
      currency: true,
      minWidth: 250,
      tax: true,
      dropDown: true,
      options: commonSalesTax,
      // onfocus:handleFocusSaleTaxCommon,
      // inputOnchange:onChangeInputSaleTaxCommon,
    },
    {
      id: 'amount',
      label: `${t('issueInvoice.addIssueInvoice.totalAmount')}`,
      minWidth: 100,
      currency: true,
      amount: true,
    },
    {
      id: 'actions',
      label: `${t('common.actions')}`,
      minWidth: 100,
      actions: true,
    },
  ]

  const updateInvoiceDetails = jobDetailInvoice?.invoice_details.map(
    (invoiceDetail: any) => {
      const matchingSubtotal = jobDetailInvoice?.tax_subtotal?.find(
        (subtotal: any) => {
          return subtotal.sales_tax.id === invoiceDetail.sales_tax.id
        }
      )
      return {
        ...invoiceDetail,
        sub_total_id: matchingSubtotal?.id ? matchingSubtotal?.id : undefined,
      }
    }
  )
  const initialState: initialIssueInvoiceProps = {
    invoice_no: jobDetailInvoice?.invoice_no
      ? jobDetailInvoice?.invoice_no
      : '',
    invoice_date: jobDetailInvoice
      ? jobDetailInvoice?.invoice_date
      : dateFormatter(new Date()),
    // job_no: jobDetailInvoice?.job_no ? jobDetailInvoice?.job_no : '',
    company: jobDetailInvoice?.company ? jobDetailInvoice?.company : null,
    title: jobDetailInvoice?.title ? jobDetailInvoice?.title : '',
    payment_terms: jobDetailInvoice?.payment_terms
      ? jobDetailInvoice?.payment_terms
      : null,
    job_details: tableCheckBoxData,
    reason: '',
    comment: '',
    workflow: jobDetailInvoice?.workflow ? jobDetailInvoice.workflow : 0,
    tax_subtotal: jobDetailInvoice?.tax_subtotal
      ? jobDetailInvoice?.tax_subtotal
      : [
          {
            sales_tax: null,
            subtotal_amount: 0,
            amount: 0,
          },
        ],
    invoice_details: jobDetailInvoice
      ? updateInvoiceDetails
      : [
          {
            // section: 0,
            item_name: '',
            quantity: 0,
            // unit: '',
            unit_cost: 0,
            sales_tax: defaultSalesTax,
            amount: 0,
          },
        ],
  }

  const validationSchema = Yup.object().shape({
    title: Yup.string().max(100, maxCharValidation(100)),
    translation_expiration: Yup.string().max(50, maxCharValidation(50)),
    payment_terms: Yup.mixed().required(t(messages.required)),
    // remarks: Yup.string().max(100, maxCharValidation(100)),
    invoice_details: Yup.array().of(
      Yup.object().shape({
        // unit_cost: Yup.number().required(messages.required),
        sales_tax: Yup.object().required(t(messages.required)),
      })
    ),
  })
  const nav = useNavigate()
  const onSubmit = async (values: initialIssueInvoiceProps) => {
    const {
      company,
      payment_terms,
      // delivery_style,
      // translation_language,
      invoice_details,
      tax_subtotal,
    } = values
    const updatedInvoiceDetails = invoice_details?.map((details: any) => ({
      ...details,
      sales_tax: details?.sales_tax?.id,
      amount: details?.amount,
      unit_cost: details?.unit_cost,
    }))

    const updatedTaxSubTotal = tax_subtotal?.map((row: any) => ({
      ...row,
      sales_tax: row?.sales_tax?.id,
    }))
    if (edit) {
      const updatedValues = {
        ...values,
        // delivery_style: delivery_style?.id,
        company: company ? company?.id : null,
        payment_terms: payment_terms?.name
          ? payment_terms?.name
          : payment_terms,
        // translation_language: translation_language?.id,
        job_details: tableCheckBoxData,
        invoice_details: updatedInvoiceDetails,
        tax_subtotal: updatedTaxSubTotal,
      }
      dispatch(
        updateIssueInvoiceRequest({
          id,
          values: updatedValues,
          nav: nav,
          fromBilledList,
          previousUrl,
        })
      )
    } else {
      const updatedValues = {
        ...values,
        // delivery_style: delivery_style?.id,
        company: company ? company?.id : null,
        payment_terms: payment_terms?.name
          ? payment_terms?.name
          : payment_terms,
        // translation_language: translation_language?.id,
        job_details: tableCheckBoxData,
        invoice_details: updatedInvoiceDetails,
        tax_subtotal: updatedTaxSubTotal,
      }
      dispatch(
        createIssueInvoiceRequest({
          values: updatedValues,
          nav: nav,
          fromBilledList,
          previousUrl,
        })
      )
    }
    if (jobTaskId) {
      dispatch(
        updateJobTaskRequest({
          id: jobTaskId,
          values: { inspection_flag: true, status: '055' },
        })
      )
    }
  }

  // onfocus
  const focus_invoice_no = useRef(null)
  const focus_invoice_date = useRef(null)
  const focus_company = useRef(null)
  const focus_title = useRef(null)
  const focus_translation_language = useRef(null)
  const focus_delivery_style_id = useRef(null)
  const focus_delivery_date = useRef(null)
  const focus_translation_expiration = useRef(null)
  const focus_payment_terms_id = useRef(null)
  const focus_withdrawal_flag = useRef(null)
  // const focus_issue_flag = useRef(null)
  const focus_save_btn = useRef(null)

  //
  const [query, setQuery] = useState<query>({
    search: '',
    url: '',
    // loadingAction: "",
    // successSlice: "",
    // errorSlice: "",
  })

  useInputSearch(query)

  const handlePreview = () => {
    navigate('/preview')
  }
  const [readOnlyMode, setReadOnlyMode] = useState(false)
  const { is_staff } = useAppSelector(authSelector)
  useEffect(() => {
    if (!is_staff) {
      setReadOnlyMode(true)
    }
    return () => {
      setReadOnlyMode(false)
    }
  }, [])
  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={initialState}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          values,
          handleChange,
          setFieldValue,
          handleBlur,
          touched,
          errors,
          submitForm,
        }) => {
          const handleSave = () => {
            submitForm()
          }
          const buttonConfigs = [
            {
              label: edit ? 'common.update' : 'common.save',
              handler: handleSave,
              isShowAlert: true,
              loading: loadingCreateIssueInvoice,
            },
            ...(edit
              ? [
                  {
                    label: 'common.print',
                    handler: handlePrint,
                  },
                ]
              : []),
            ...(edit
              ? [
                  {
                    label: 'uploadRecept.usermadeInvoiceUpload.name',
                    handler: handleUsermadeInvoice,
                  },
                ]
              : []),
            ...(edit
              ? [
                  {
                    label: 'issueInvoice.preview',
                    handler: handlePreview,
                  },
                ]
              : []),
          ]
          const handleButtonAction = (action: string) => {
            const buttonConfig = buttonConfigs.find(
              (config) => config.label === action
            )
            if (buttonConfig) {
              const { handler } = buttonConfig
              handler()
            }
          }
          return (
            <>
              <Grid item sx={{ mb: 4 }}>
                <SaveFooterComponent
                  handleClick={handleButtonAction}
                  buttonConfigs={buttonConfigs}
                />
              </Grid>
              {edit && (
                <ReasonForChange
                  setFieldValue={setFieldValue}
                  name="reason"
                  onChange={handleChange}
                />
              )}
              <IssueBarComponent isIssued={jobDetailInvoice?.issue_flag} />
              <div className={readOnlyMode ? 'disabled-form' : ''}>
                <Form autoComplete="off" noValidate>
                  <AppTitleCard title="issueInvoice.name">
                    <h1 style={{ textAlign: 'center' }}>
                      {t('issueInvoice.name')}
                    </h1>
                    <Grid
                      container
                      padding={4}
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Grid item xs={6}>
                        <Grid container alignItems="center">
                          <Grid item xs={3.5}>
                            {t('issueInvoice.invoiceNumber')}:
                          </Grid>
                          <AppTextField
                            disabled
                            xs={5}
                            name="invoice_no"
                            value={values?.invoice_no}
                            label={t('issueInvoice.invoiceNumber')}
                            // inputRef={focus_invoice_no}
                            // focusElement={focus_invoice_date}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={6}></Grid>
                    </Grid>

                    <Grid
                      container
                      padding={4}
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Grid item xs={6}>
                        <Grid container alignItems="center">
                          <Grid
                            item
                            xs={3.5}
                            sx={{ fontWeight: 'medium', fontSize: 20 }}
                          >
                            {t('issueInvoice.invoiceDate')}:
                          </Grid>
                          <AppDatePicker
                            xs={5}
                            value={values?.invoice_date ?? ''}
                            name="invoice_date"
                            label="issueInvoice.invoiceDate"
                            disabled={readOnlyMode}
                            onChange={(value: any) => {
                              if (value !== null) {
                                setFieldValue(
                                  'invoice_date',
                                  dateFormatter(value)
                                )
                              } else {
                                setFieldValue('invoice_date', '')
                              }
                            }}
                            // inputRef={focus_invoice_date}
                            // focusElement={focus_company}
                            variant="outlined"
                            sx={{
                              width: '100%',
                              size: 'medium',
                              '& .MuiInputBase-root': {
                                height: '39px',
                                display: 'flex',
                                alignItems: 'center',
                              },
                              '& .MuiInputLabel-root': {
                                textAlign: 'center',
                              },
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={6}>
                        <Grid container alignItems="center">
                          <Grid item xs={4}>
                            {t('issueInvoice.company')}:
                          </Grid>
                          <AppAutoComplete
                            xs={5}
                            name="company"
                            value={values?.company}
                            options={commonCompanies}
                            getOptionLabel={(option: commonOptionType) =>
                              option.name
                            }
                            label={t('issueInvoice.company')}
                            onChange={(event: any, value: InputChangeEvent) => {
                              if (value !== null) {
                                setFieldValue('company', value)
                              } else {
                                setFieldValue('company', null)
                              }
                            }}
                            onFocus={() => handleFocusCompanyCommon(dispatch)}
                            onBlur={handleBlur}
                            inputOnchange={(e: any) =>
                              onChangeInputCompanyCommon(e.target, setQuery)
                            }
                            disabled={readOnlyMode}
                            // inputRef={focus_company}
                            // focusElement={focus_title}
                            //groupBy={(option: any) => String(option.title)}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid container spacing={1} padding={4} alignItems="center">
                      <Grid container>
                        <Grid item xs={6} sx={{ pl: 1 }}>
                          <Grid container alignItems="center" mt={1}>
                            <Grid item xs={3.6}>
                              {t('common.title')}:
                            </Grid>
                            <AppTextField
                              xs={5}
                              name="title"
                              value={values?.title}
                              label={t('common.title')}
                              disabled={readOnlyMode}
                              // inputRef={focus_title}
                              // focusElement={focus_translation_language}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Grid>
                        </Grid>
                        <Grid item xs={6} sx={{ pl: 1 }}>
                          <Grid container alignItems="center">
                            <Grid item xs={4}>
                              {t('orderReceiptIssuance.paymentTerms')}:
                            </Grid>
                            <FreeSoloInput
                              xs={5}
                              name="payment_terms"
                              // required
                              options={commonPaymentTerms}
                              value={values?.payment_terms}
                              label={t('orderReceiptIssuance.paymentTerms')}
                              disabled={readOnlyMode}
                              onFocus={() =>
                                handleFocusPaymentTermsCommon(dispatch)
                              }
                              onBlur={handleBlur}
                              inputOnchange={(e: any) =>
                                onChangeInputPaymentTermsCommon(
                                  e.target,
                                  setQuery
                                )
                              }
                              getOptionLabel={(option: any) => {
                                // Value selected with enter, right from the input
                                if (typeof option === 'string') {
                                  return option
                                }
                                // Add "xxx" option created dynamically
                                if (option.inputValue) {
                                  return option.inputValue
                                }
                                // Regular option
                                return option.name
                              }}
                              renderOption={(props: any, option: any) => (
                                <li {...props}>{option.name}</li>
                              )}
                              onChange={(event: any, newValue: any) => {
                                if (typeof newValue === 'string') {
                                  setFieldValue('payment_terms', newValue)
                                } else if (newValue && newValue.inputValue) {
                                  setFieldValue(
                                    'payment_terms',
                                    newValue?.inputValue
                                  )
                                } else {
                                  setFieldValue('payment_terms', newValue)
                                }
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>

                      {/*  */}
                    </Grid>
                    <Suspense fallback={<></>}>
                      <CommonReportTable
                        columns={columns}
                        taxType={`${t('commonFinanceTerm.excludingTax')})`}
                        keyName="invoice_details"
                        values={values?.invoice_details}
                        retrieve_tax_subtotal={values?.tax_subtotal}
                        handleChange={handleChange}
                        setFieldValue={setFieldValue}
                        handleBlur={handleBlur}
                        initialValues={initialState?.invoice_details}
                        setQuery={setQuery}
                        touched={touched}
                        disabled={readOnlyMode}
                        edit={edit}
                      />
                    </Suspense>
                  </AppTitleCard>
                  <Grid
                    container
                    justifyContent="start"
                    alignItems="center"
                    sx={{ m: 3 }}
                  >
                    <Grid item xs={12}>
                      <Typography>
                        {t('issueInvoice.bankNameLabel')}:{' '}
                        {t('issueInvoice.bankName')}
                      </Typography>
                      <Typography>
                        {t('issueInvoice.branchNameLabel')}:{' '}
                        {t('issueInvoice.branchName')}
                      </Typography>
                      <Typography>
                        {t('issueInvoice.depositTypeLabel')}:{' '}
                        {t('issueInvoice.depositType')}
                      </Typography>
                      <Typography>
                        {t('issueInvoice.accountLabel')}:{' '}
                        {t('issueInvoice.account')}
                      </Typography>
                      <Typography>
                        {t('issueInvoice.accountHolderNameLabel')}:{' '}
                        {t('issueInvoice.accountHolderName')}
                      </Typography>
                      <Typography>
                        {t('issueInvoice.paymentDueDateLabel')}:{' '}
                        {t('issueInvoice.paymentDueDate')}
                      </Typography>
                      <Typography>
                        {t('issueInvoice.noteLabel')}: {t('issueInvoice.note')}
                      </Typography>
                    </Grid>
                  </Grid>
                  {/* work flow----------------------------- */}
                  {edit && is_staff && (
                    <>
                      <History
                        // showApprovalHistory={true}
                        history={
                          jobDetailInvoice?.history?.length > 0
                            ? jobDetailInvoice?.history
                            : []
                        }
                      />
                    </>
                  )}

                  {/* {edit && (
                    <Comments
                      setFieldValue={setFieldValue}
                      name="comment"
                      onChange={handleChange}
                    />
                  )} */}
                  <FocusElementOnInvalidValidation />
                </Form>
              </div>
            </>
          )
        }}
      </Formik>
    </div>
  )
}

export default IssueInvoiceForm
