import { createSlice } from '@reduxjs/toolkit'

import { exportInvoiceDataListProps } from './types'

const initialState: exportInvoiceDataListProps = {
  exportInvoiceDataLists: [],
  edit: false,
  exportInvoiceDataList: null,
  loadingExportInvoiceDataList: false,
  loadingCreateExportInvoiceDataList: false,
  count: 0,
  next: '',
  previous: '',
  loadingExportInvoiceDataListDelete: false,
  invoiceHq: null,
  loadingCreateInvoiceHq: false,
  loadingInvoiceHq: false,
}

export const ExportInvoiceDataList = createSlice({
  name: 'exportInvoiceDataListReducer',
  initialState,
  reducers: {
    loadingExportInvoiceDataList: (state) => {
      state.loadingExportInvoiceDataList = true
    },
    getExportInvoiceDataListRequest: (state, action) => {
      state.loadingExportInvoiceDataList = true
    },
    getExportInvoiceDataListSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingExportInvoiceDataList = false
      state.exportInvoiceDataLists = results
      state.count = count
      state.next = next
      state.previous = previous
    },
    getExportInvoiceDataListFail: (state) => {
      state.loadingExportInvoiceDataList = false
    },
    getExportInvoiceDataListNextRequest: (state, action) => {
      state.loadingExportInvoiceDataList = true
    },
    getExportInvoiceDataListPreviousRequest: (state, action) => {
      state.loadingExportInvoiceDataList = true
    },
    //
    deleteExportInvoiceDataListByIdRequest: (state, action) => {
      state.loadingExportInvoiceDataListDelete = true
    },
    deleteExportInvoiceDataListByIdSuccess: (state) => {
      state.loadingExportInvoiceDataListDelete = false
    },
    deleteExportInvoiceDataListByIdFail: (state) => {
      state.loadingExportInvoiceDataListDelete = false
    },
    //get InvoiceHq by id
    getInvoiceHqByIdRequest: (state, action) => {
      state.loadingInvoiceHq = true
    },
    getInvoiceHqByIdSuccess: (state, { payload }) => {
      state.invoiceHq = payload
      state.loadingInvoiceHq = false
    },
    getInvoiceHqByIdFail: (state) => {
      state.loadingInvoiceHq = false
    },
    clearInvoiceHq: (state) => {
      state.invoiceHq = null
    },
    //update InvoiceHq
    updateInvoiceHqRequest: (state, payload) => {
      state.loadingCreateInvoiceHq = true
    },
    updateInvoiceHqSuccess: (state) => {
      state.loadingCreateInvoiceHq = false
    },
    updateInvoiceHqFail: (state) => {
      state.loadingCreateInvoiceHq = false
    },
    //
    createInvoiceHqRequest: (state, payload) => {
      state.loadingCreateInvoiceHq = true
    },
    createInvoiceHqSuccess: (state) => {
      state.loadingCreateInvoiceHq = false
    },
    createInvoiceHqFail: (state) => {
      state.loadingCreateInvoiceHq = false
    },
    clearHardResetExportInvoiceHQ: (state) => {
      return initialState;
     },
  },
})
export const {
  clearHardResetExportInvoiceHQ,
  loadingExportInvoiceDataList,
  getExportInvoiceDataListSuccess,
  getExportInvoiceDataListFail,
  getExportInvoiceDataListRequest,
  getExportInvoiceDataListPreviousRequest,
  getExportInvoiceDataListNextRequest,
  //
  deleteExportInvoiceDataListByIdRequest,
  deleteExportInvoiceDataListByIdSuccess,
  deleteExportInvoiceDataListByIdFail,
  //invoice hq
  getInvoiceHqByIdRequest,
  getInvoiceHqByIdSuccess,
  getInvoiceHqByIdFail,
  clearInvoiceHq,
  updateInvoiceHqRequest,
  updateInvoiceHqSuccess,
  updateInvoiceHqFail,
  createInvoiceHqRequest,
  createInvoiceHqSuccess,
  createInvoiceHqFail,
} = ExportInvoiceDataList.actions
export default ExportInvoiceDataList.reducer
