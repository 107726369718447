import { createSlice } from '@reduxjs/toolkit'

import { OrderListInitialSlice } from '../Types'

const initialState: OrderListInitialSlice = {
  orderLists: [],
  orderList: null,
  loadingOrderList: false,
  loadingCreateOrderList: false,
  count: 0,
  next: '',
  previous: '',
  loadingCreate: false,
  orderListDetails: [],
  orderListDetail: null,
  loadingOrderDetails:false,
}
export const OrderList = createSlice({
  name: 'orderListReducer',
  initialState,
  reducers: {
    loadingOrderList: (state) => {
      state.loadingOrderList = true
    },
    getOrderListRequest: (state, action) => {
      state.loadingOrderList = true
    },
    getOrderListSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingOrderList = false
      state.orderLists = results
      state.count = count
      state.next = next
      state.previous = previous
    },
    getOrderListFail: (state) => {
      state.loadingOrderList = false
    },
    //
    getOrderListDetailRequest: (state, action) => {
      state.loadingOrderDetails = true
    },
    getOrderListDetailSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingOrderDetails = false
      state.orderListDetails = results
      state.count = count
      state.next = next
      state.previous = previous
    },
    getOrderListDetailFail: (state) => {
      state.loadingOrderDetails = false
    },
    //
    getOrderDetailListNextRequest: (state, payload) => {
      state.loadingOrderDetails = true
    },
    getOrderDetailListPreviousRequest: (state, payload) => {
      state.loadingOrderDetails = true
    },
    //
    getOrderListDetailByIdRequest: (state, action) => {
      state.loadingOrderDetails = true
    },
    getOrderListDetailByIdSuccess: (state, { payload }) => {
      state.orderListDetail = payload
    },
    getOrderListDetailByIdFail: (state) => {
      state.loadingOrderDetails = false
      state.orderListDetail = null
    },
    getOrderListNextRequest: (state, action) => {
      state.loadingOrderList = true
    },

    getOrderListPreviousRequest: (state, action) => {
      state.loadingOrderList = true
    },

  
  },
})
export const {
  loadingOrderList,
  getOrderListSuccess,
  getOrderListFail,
  getOrderListRequest,
  getOrderListPreviousRequest,
  getOrderListNextRequest,
  //
  getOrderListDetailSuccess,
  getOrderListDetailFail,
  getOrderListDetailRequest,
  //
  getOrderListDetailByIdRequest,
  getOrderListDetailByIdSuccess,
  getOrderListDetailByIdFail,
  getOrderDetailListNextRequest,
  getOrderDetailListPreviousRequest,
  //create translator
 
} = OrderList.actions
export default OrderList.reducer
