import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { Card, CardHeader, Grid, TextField, Typography } from '@mui/material'

import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import { handleFocusSaleTaxCommon } from '../../../CommonAppRedux/commonAppFunctions'
import { alertInfoAction } from '../../../CommonAppRedux/commonAppSlice'
import { authSelector } from '../../../Login/Redux/selector'
import AppDropZone from '../../../ReusableComponent/AppDropZone/AppDropZone'
import AppTextField from '../../../ReusableComponent/AppTextField/AppTextField'
import {
  useAppDispatch,
  useAppSelector,
} from '../../../ReusableComponent/CustomHooks/appHooks'
import FocusElementOnInvalidValidation from '../../../ReusableComponent/FocusElementIfInvalid/FocusElementOnInvalidValidation'
import SaveFooterComponent from '../../../ReusableComponent/SaveFooterButton/SaveFooterButton'
import {
  downloadFile,
  extractFilenameFromURL,
  getContentTypeFromUrl,
  getContentTypeFromUrl2,
  handleSendButtonBackend,
  todayDate,
} from '../../../Utils/AppFunction'
import messages, { maxCharValidation } from '../../../Utils/ValidationMessage'
import { AppDropZoneProps } from '../../../Utils/globalTypes'
import {
  clearArtifact,
  clearDataProjectDetails,
  clearHardResetProjectList,
  createArtifactRequest,
  createProjectDeliveryNoteApproveRequest,
  getArtifactByIdRequest,
  getOrderReceiptByIdRequest,
  getProjectDetailByIdRequest,
  updateArtifactRequest,
  updateProjectDetailsRequest,
} from '../Redux/projectListSlice'
import { projectListSelector } from '../Redux/selector'

export interface artifact {
  id?: number
  version?: number
  case?: any
  message: string
  file: string | File | null
  url: string
}

const DeliveryItemUpload = ({ caseIdFromCase }: any) => {
  const { t } = useTranslation()
  const { artifact, loadingCreateArtifact } =
    useAppSelector(projectListSelector)
  const dispatch = useAppDispatch()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const caseId = searchParams.get('id')
  // const artifactId = searchParams.get('artifact')
  const edit = searchParams.get('edit')
  const { state } = location
  const previousUrl = state?.path
  const { projectDetail, orderReceipt } = useAppSelector(projectListSelector)
  const sender = useRef('')
  //all reducer action and clear function implement start

  useLayoutEffect(() => {
    handleFocusSaleTaxCommon(dispatch)
    if (caseId && !caseIdFromCase) {
      dispatch(getProjectDetailByIdRequest(caseId))
    } else if (!caseIdFromCase) {
      dispatch(clearDataProjectDetails())
      dispatch(clearHardResetProjectList())
    }
    return () => {
      if (!caseIdFromCase) {
        dispatch(clearHardResetProjectList())
      }
    }
  }, [caseId, location, dispatch, edit])

  useLayoutEffect(() => {
    if (projectDetail?.artifact) {
      dispatch(getArtifactByIdRequest(projectDetail?.artifact))
    } else {
      dispatch(clearArtifact())
    }
    if (projectDetail?.case_report_relation?.order_receipt) {
      dispatch(
        getOrderReceiptByIdRequest(
          projectDetail?.case_report_relation?.order_receipt
        )
      )
    }
  }, [projectDetail, location, dispatch, edit])
  console.log('artifacttttttt', artifact?.version)
  //all reducer action and clear function implement End

  const allowedTypes = ['']
  const validationSchema = Yup.object().shape({
    // file: Yup.mixed().test(
    //   'fileType',
    //   t(messages.invalidFileType),
    //   (value: any) => {
    //     if (!value) {
    //       return true
    //     }
    //     return allowedTypes.includes(value.type) || allowedTypes.includes('')
    //   }
    // ),
    url: Yup.string()
      .url(t(messages.invalidUrl))
      // .matches(/^.+\.[a-z]{2,}(\/.*)?$/i, t(messages.atLeast2CharAfterDecimal))
      .max(200, maxCharValidation(200)),
  })
  // .test('oneRequired', t(messages.required), function (value) {
  //   const { url, file } = value
  //   if (!url && !file) {
  //     return this.createError({
  //       message: t(messages.required),
  //       path: 'url',
  //     })
  //   }
  //   return true
  // })

  const [selectedValue, setSelectedFile] = useState<File | null>(null)

  // useEffect(() => {
  //   const fetchData = async () => {
  //     if (
  //       artifact?.file &&
  //       typeof artifact?.file === 'string' &&
  //       (artifact?.file.startsWith('http://') ||
  //         artifact?.file.startsWith('https://'))
  //     ) {
  //       try {
  //         const response = await fetch(artifact?.file)
  //         if (!response.ok) {
  //           throw new Error(`HTTP Error: ${response.status}`)
  //         }
  //         const blob = await response.blob()

  //         // Extract file extension from the URL
  //         // const urlParts = artifact.file.split('/')
  //         const fileNameWithExtension = extractFilenameFromURL(artifact?.file)
  //         const fileNameWithoutExtension = fileNameWithExtension
  //           .split('.')
  //           .slice(0, -1)
  //           .join('.')
  //         const extension = fileNameWithExtension.split('.').pop()
  //         const fileNameWithBlobExtension = `${fileNameWithoutExtension}.${extension}`

  //         // Create a File object with the blob and custom file name
  //         // const file = new File([blob], fileNameWithBlobExtension, {
  //         //   type: getContentTypeFromUrl(artifact?.file),
  //         // })
  //         const contentType = await getContentTypeFromUrl2(artifact?.file)
  //         const file = new File([blob], fileNameWithBlobExtension, {
  //           type: contentType,
  //         })

  //         setSelectedFile(file)
  //       } catch (error) {
  //         console.error('Error fetching image:', error)
  //         setSelectedFile(null)
  //       }
  //     } else {
  //       // setSelectedFile(artifact?.file);
  //     }
  //   }
  //   fetchData()
  // }, [artifact?.file])

  const initialState: artifact = {
    message: artifact?.message ? artifact?.message : '',
    file: artifact?.file ? artifact?.file ?? '' : null,
    url: artifact?.url ?? '',
    case: caseId,
    version: artifact?.version,
  }
  const nav = useNavigate()

  const onSubmit = (values: artifact, actions: any) => {
    if (edit && artifact !== null) {
      dispatch(
        updateArtifactRequest({
          id: projectDetail?.artifact,
          values: {
            ...values,
            case: caseId,
          },
          nav,
          actions,
          previousUrl,
        })
      )
    } else {
      const auto_issue = sender.current === 'auto-issue'
      const send_only = sender.current === 'send-only'
      dispatch(
        createArtifactRequest({
          values: {
            ...values,
            case: caseId,
            auto_issue,
            send_only,
          },
          nav,
          actions,
          previousUrl,
        })
      )
    }
  }
  const { is_staff } = useAppSelector(authSelector)
  const [readOnlyMode, setReadOnlyMode] = useState(false)
  useLayoutEffect(() => {
    if (edit && !is_staff && projectDetail?.status.code >= '010') {
      setReadOnlyMode(true)
    } else {
      setReadOnlyMode(false)
    }
  }, [edit, is_staff, projectDetail])
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initialState}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          values,
          handleChange,
          setFieldValue,
          handleBlur,
          errors,
          submitForm,
          touched,
        }) => {
          const handleSave = () => {
            sender.current = 'upload'
            submitForm()
          }
          const handleSend = async () => {
            const apiUrl = `artifact/${projectDetail?.artifact}/send`
            await handleSendButtonBackend(dispatch, apiUrl)
          }
          const handleDeliveryNoteButton = async () => {
            const { case_report_relation, id } = projectDetail
            if (case_report_relation?.delivery_note) {
              const deliveryNoteId = case_report_relation.delivery_note
              const queryParams = `?delivery-note-id=${deliveryNoteId}&case-id=${id}&edit=${true}`
              nav(`/delivery-report-note-approve${queryParams}`, {
                state: { path: location?.pathname + location?.search },
              })
            } else {
              const queryParams = `?case-id=${id}`
              nav(`/delivery-report-note-approve${queryParams}`, {
                state: { path: location?.pathname + location?.search },
              })
            }
          }
          const handleUpload = (file: File) => {
            setFieldValue(`file`, file)
          }
          const handleDownload = () => {
            if (values?.file === null) {
              dispatch(alertInfoAction(t(messages.doesntExist)))
            } else {
              // downloadFile(values?.file)
              console.log('askdjkasjdkajskdjksad', values)
            }
          }
          const handleUploadAndDeliveryNoteIssue = () => {
            sender.current = projectDetail?.case_report_relation?.delivery_note
              ? 'send-only'
              : 'auto-issue'
            submitForm()
          }
          const handleBackToCase = () => {
            const queryParams = `?id=${caseId}&edit=${true}`
            nav(`/create-agency-project${queryParams}`, {
              state: { path: location?.pathname + location?.search },
            })
          }

          const buttonConfigs = [
            ...(is_staff
              ? //&& ['050'].includes(projectDetail?.status?.code)
                [
                  {
                    label: 'common.upload',
                    handler: handleSave,
                    isShowAlert: true,
                    loading: loadingCreateArtifact,
                    message: t('confirmMessage.upload'),
                  },
                ]
              : []),

            ...(edit &&
            (values?.file !== '' || !values?.file) &&
            is_staff &&
            ['050', '060', '070', '080', '090'].includes(
              projectDetail?.status?.code
            )
              ? [
                  {
                    label: 'common.download',
                    handler: handleDownload,
                    isShowAlert: false,
                  },
                ]
              : !is_staff
              ? [
                  {
                    label: 'common.download',
                    handler: handleDownload,
                    isShowAlert: false,
                  },
                ]
              : []),
            ...(projectDetail?.case_report_relation?.order_receipt &&
            projectDetail?.job?.id &&
            is_staff &&
            !edit &&
            !['070', '080', '090'].includes(projectDetail?.status?.code)
              ? [
                  {
                    label: 'common.uploadAndDeliveryNoteIssue',
                    handler: handleUploadAndDeliveryNoteIssue,
                    isShowAlert: true,
                    message: t('confirmMessage.UploadAndSendDeliveryNote'),
                  },
                ]
              : []),
            ...(is_staff && edit && projectDetail?.artifact
              ? [
                  {
                    label: 'common.send',
                    handler: handleSend,
                    isShowAlert: true,
                    message: t('confirmMessage.send'),
                  },
                ]
              : []),
            ...(is_staff && edit
              ? [
                  {
                    label: `${
                      projectDetail?.case_report_relation?.delivery_note
                        ? 'common.deliveryNoteApprove'
                        : 'common.deliveryNote'
                    }`,
                    handler: handleDeliveryNoteButton,
                    isShowAlert: false,
                  },
                ]
              : []),
            {
              label: 'common.backToCase',
              handler: handleBackToCase,
            },
          ]

          const handleButtonAction = (action: string) => {
            const buttonConfig = buttonConfigs.find(
              (config) => config.label === action
            )
            if (buttonConfig) {
              const { handler } = buttonConfig
              handler()
            }
          }
          return (
            <>
              {!caseIdFromCase && (
                <Grid item sx={{ mb: 4 }}>
                  <SaveFooterComponent
                    handleClick={handleButtonAction}
                    buttonConfigs={buttonConfigs}
                  />
                </Grid>
              )}
              <Form autoComplete="off" noValidate>
                <Card sx={{ mb: 2, pointerEvents: 'auto' }}>
                  <CardHeader
                    title={
                      <Typography
                        variant="h6"
                        sx={{ fontWeight: 'bold', color: 'white' }}
                      >
                        {is_staff
                          ? t('uploadRecept.deliveryItemUpload.name')
                          : t('uploadRecept.deliveryItemUpload.clientName')}
                      </Typography>
                    }
                    sx={{
                      backgroundColor: '#062f96',
                      height: '48px',
                    }}
                  />
                  <Grid container spacing={1} sx={{ mt: 1, mb: 0 }}>
                    <Grid item xs={12} sx={{ ml: 2, mb: 1, mr: 2 }}>
                      <TextField
                        label={
                          is_staff
                            ? t('uploadRecept.deliveryItemUpload.message')
                            : t(
                                'uploadRecept.deliveryItemUpload.deliveryComment'
                              )
                        }
                        multiline
                        minRows={2}
                        maxRows={4}
                        disabled={caseIdFromCase ? true : false}
                        name={`message`}
                        value={values?.message ?? null}
                        onChange={handleChange}
                        sx={{ minWidth: '100%' }}
                        InputLabelProps={{
                          // style: dullLabelStyle,
                          shrink: true,
                        }}
                      />
                      <Typography
                        variant="body2"
                        sx={{ mt: 1, textAlign: 'right' }}
                      >
                        {values?.message.length} {t('common.character')}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ mt: 2, mb: 2 }}>
                    <Grid item xs={12}>
                      <AppDropZone
                        disabled={readOnlyMode}
                        allowedTypes={allowedTypes}
                        maxFileSize={1024 * 1024 * 1024 * 1024}
                        value={values}
                        onUpload={handleUpload}
                        setFieldValue={setFieldValue}
                        dispatchDeliveryItem={true}
                        artifactId={projectDetail?.artifact}
                        caseIdFromCase={caseIdFromCase ? true : false}
                        nav={nav}
                        isStaff={is_staff}
                        artifact_version={artifact?.version}
                      />
                    </Grid>
                  </Grid>
                </Card>
                <FocusElementOnInvalidValidation />
              </Form>
            </>
          )
        }}
      </Formik>
    </>
  )
}

export default DeliveryItemUpload
