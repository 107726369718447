import { configureStore } from '@reduxjs/toolkit'
import { createEpicMiddleware } from 'redux-observable'

import { rootReducer } from './reducers'
import { RootEpic } from './rootEpic'
import { persistReducer, persistStore } from 'redux-persist'
import storage from "redux-persist/lib/storage";
const persistConfig = {
  key: "root",
  storage: storage,
  // which reducer you want to store
  whitelist: ["localStorageRedux"],
};
const epicMiddleware = createEpicMiddleware()
const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = configureStore({
  reducer: persistedReducer,
  middleware: [epicMiddleware],
  devTools: process.env.NODE_ENV !== 'production',
})
const persistor = persistStore(store);
export { store, persistor };
// epicMiddleware.run(RootEpic);s
epicMiddleware.run((action$, state$) => {
  return RootEpic(action$, state$, { dispatch: store.dispatch })
})
// Infer the `RootState` and `AppDispatch` types from the store itself

export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
