import { createSlice } from '@reduxjs/toolkit'

import { JobListInitialSlice } from '../Types'

const initialState: JobListInitialSlice = {
  jobLists: [],
  edit: false,
  jobList: null,
  loadingJobList: false,
  loadingCreateJobList: false,
  count: 0,
  next: '',
  previous: '',
  loadingCreate: false,
  jobListDetails: [],
  jobListDetail: null,
  loadingCreateTranslator: false,
  translatorTableList: [],
  loadingTranslatorTableList: false,
  loadingUpdate: false,
  jobTask: null,
  loadingJobTask: false,
  loadingUpdateJobTask: false,
  loadingJobDetails: false,
  loadingCreateIssueInvoice: false,
  jobDetailRow: null,
  jobDetailInvoice: null,
  loadingJobDetailInvoice: false,
  purchaseOrder: null,
  loadingPurchaseOrder: false,
  loadingCreatePurchaseOrder: false,
  translatorCertificate: null,
  loadingCreateTranslatorCertificate: false,
  loadingTranslatorCertificate: false,

  userMadeInvoice: null,
  loadingUserMadeInvoice: false,
  loadingCreateUserMadeInvoice: false,
  loadingCreatePurchaseAmountChange: false,
  loadingGetPurchaseChangeAmount: false,
  purchaseChangeAmountDetails: null,
  loadingCreateJobTask: false,
  loadingGetJobTaskStockingDate: false,
  jobTaskStockingDateDetails: null,
  loadingGetTranslatorPurchaseOrderDetail: false,
  translatorPurchaseOrderDetailDetails: [],
  translatorInvoiceUpload: null,
  loadingTranslatorInvoiceUpload: false,
  loadingCreateTranslatorInvoiceUpload: false,
  loadingUpdateInspectionWithdrawal: false,
  loadingDownloadJobListSalesExport: false,
}
export const JobList = createSlice({
  name: 'jobListReducer',
  initialState,
  reducers: {
    loadingJobList: (state) => {
      state.loadingJobList = true
    },
    getJobListRequest: (state, action) => {
      state.loadingJobList = true
    },
    getJobListSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingJobList = false
      state.jobLists = results
      state.count = count
      state.next = next
      state.previous = previous
    },
    getJobListFail: (state) => {
      state.loadingJobList = false
    },
    //
    getJobListDetailRequest: (state, action) => {
      state.loadingJobDetails = true
    },
    getJobListDetailSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingJobDetails = false
      state.jobListDetails = results
      state.count = count
      state.next = next
      state.previous = previous
    },
    getJobListDetailFail: (state) => {
      state.loadingJobDetails = false
    },
    //
    getJobDetailListNextRequest: (state, payload) => {
      state.loadingJobDetails = true
    },
    getJobDetailListPreviousRequest: (state, payload) => {
      state.loadingJobDetails = true
    },
    //
    getJobListDetailByIdRequest: (state, action) => {
      state.edit = true
    },
    getJobListDetailByIdSuccess: (state, { payload }) => {
      state.jobListDetail = payload
    },
    getJobListDetailByIdFail: (state) => {
      state.edit = false
      state.jobListDetail = null
    },
    getJobListNextRequest: (state, action) => {
      state.loadingJobList = true
    },

    getJobListPreviousRequest: (state, action) => {
      state.loadingJobList = true
    },
    createJobDetailsRequest: (state, payload) => {
      state.loadingCreate = true
    },
    createJobDetailsSuccess: (state) => {
      state.loadingCreate = false
    },
    createJobDetailsFail: (state) => {
      state.loadingCreate = false
    },
    //
    updateJobDetailsRequest: (state, payload) => {
      state.loadingUpdate = true
    },
    updateJobDetailsSuccess: (state) => {
      state.loadingUpdate = false
    },
    updateJobDetailsFail: (state) => {
      state.loadingUpdate = false
    },
    clearDataJobListDetails: (state) => {
      state.jobListDetail = null
      state.edit = false
    },

    //create translator
    createTranslatorRequest: (state, payload) => {
      state.loadingCreateTranslator = true
    },
    createTranslatorSuccess: (state) => {
      state.loadingCreateTranslator = false
    },
    createTranslatorFail: (state) => {
      state.loadingCreateTranslator = false
    },

    //get translator list for table
    getCommonTranslatorTableListRequest: (state, action) => {
      state.loadingTranslatorTableList = true
    },
    //handle translator filter in translator matching table
    getTranslatorFilterRequest: (state, { payload }) => {
      state.loadingTranslatorTableList = true
    },
    getCommonTranslatorTableListSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingTranslatorTableList = false
      state.translatorTableList = results
      state.count = count
      state.next = next
      state.previous = previous
    },
    getCommonTranslatorTableListFail: (state) => {
      state.loadingTranslatorTableList = false
      state.translatorTableList = []
    },
    getCommonTranslatorNextRequest: (state, payload) => {
      state.loadingTranslatorTableList = true
    },
    getCommonTranslatorPreviousRequest: (state, payload) => {
      state.loadingTranslatorTableList = true
    },
    //get common translator list
    // loadingTranslatorFilterOption: (state) => {
    //   state.loadingTranslatorFilterOptions = true
    // },
    // getTranslatorFilterOptionRequest: (state, payload) => {
    //   state.loadingTranslatorFilterOptions = true
    // },
    // getTranslatorFilterOptionSuccess: (state, { payload: { results } }) => {
    //   state.translatorFilterOptions = results
    //   state.loadingTranslatorFilterOptions = false
    // },
    // getTranslatorFilterOptionFail: (state) => {
    //   state.loadingTranslatorFilterOptions = false
    // },

    //get job task
    getJobTaskByIdRequest: (state, action) => {
      state.loadingJobTask = true
    },
    getJobTaskByIdSuccess: (state, { payload }) => {
      state.jobTask = payload
      state.loadingJobTask = false
    },
    getJobTaskByIdFail: (state) => {
      state.loadingJobTask = false
    },
    clearJobTask: (state) => {
      state.jobTask = null
    },
    //update job task
    updateJobTaskRequest: (state, payload) => {
      state.loadingUpdateJobTask = true
    },
    updateJobTaskSuccess: (state) => {
      state.loadingUpdateJobTask = false
    },
    updateJobTaskFail: (state) => {
      state.loadingUpdateJobTask = false
    },
    //
    createJobTaskRequest: (state, payload) => {
      state.loadingCreateJobTask = true
    },
    createJobTaskSuccess: (state) => {
      state.loadingCreateJobTask = false
    },
    createJobTaskFail: (state) => {
      state.loadingCreateJobTask = false
    },
    // create job detail invoice

    //
    createPurchaseAmountChangeRequest: (state, payload) => {
      state.loadingCreatePurchaseAmountChange = true
    },
    createPurchaseAmountChangeSuccess: (state) => {
      state.loadingCreatePurchaseAmountChange = false
    },
    createPurchaseAmountChangeFail: (state) => {
      state.loadingCreatePurchaseAmountChange = false
    },
    //update job detail invoice
    createIssueInvoiceRequest: (state, payload) => {
      state.loadingCreateIssueInvoice = true
    },
    createIssueInvoiceSuccess: (state) => {
      state.loadingCreateIssueInvoice = false
    },
    createIssueInvoiceFail: (state) => {
      state.loadingCreateIssueInvoice = false
    },
    //
    updateIssueInvoiceRequest: (state, payload) => {
      state.edit = true
      state.loadingCreateIssueInvoice = true
    },
    updateIssueInvoiceSuccess: (state) => {
      state.loadingCreateIssueInvoice = false
    },
    updateIssueInvoiceFail: (state) => {
      state.loadingCreateIssueInvoice = false
    },
    clearJobDetailInvoice: (state) => {
      state.jobDetailInvoice = null
      state.edit = false
    },
    // get issue invoice by job detail id
    getJobDetailInvoiceByIdRequest: (state, action) => {
      state.edit = true
      state.loadingJobDetailInvoice = true
    },
    getJobDetailInvoiceByIdSuccess: (state, { payload }) => {
      state.jobDetailInvoice = payload
      state.loadingJobDetailInvoice = false
    },
    getJobDetailInvoiceByIdFail: (state) => {
      state.edit = false
      state.loadingJobDetailInvoice = false
    },
    //save data of job detail row to pass to edit job task
    getJobDetailRowByIdRequest: (state, payload) => {
      state.loadingJobDetails = true
    },
    getJobDetailRowByIdFail: (state) => {
      state.loadingJobDetails = false
    },
    getJobDetailRowByIdSuccess: (state, { payload }) => {
      state.jobDetailRow = payload
      state.loadingJobDetails = false
    },
    //
    updateJobDetailRowByIdRequest: (state, payload) => {
      state.loadingJobDetails = true
    },
    updateJobDetailRowByIdFail: (state) => {
      state.loadingJobDetails = false
    },
    updateJobDetailRowByIdSuccess: (state) => {
      state.loadingJobDetails = false
    },
    //
    createJobDetailRowByIdRequest: (state, payload) => {
      state.loadingJobDetails = true
    },
    createJobDetailRowByIdFail: (state) => {
      state.loadingJobDetails = false
    },
    createJobDetailRowByIdSuccess: (state) => {
      state.loadingJobDetails = false
    },
    clearJobDetailRow: (state) => {
      state.jobDetailRow = null
    },
    // get purchase order by id
    getPurchaseOrderByIdRequest: (state, action) => {
      state.loadingPurchaseOrder = true
    },
    getPurchaseOrderByIdSuccess: (state, { payload }) => {
      state.loadingPurchaseOrder = false
      state.purchaseOrder = payload
    },
    getPurchaseOrderByIdFail: (state) => {
      state.loadingPurchaseOrder = false
    },
    //update purchase order
    updatePurchaseOrderRequest: (state, payload) => {
      state.edit = true
      state.loadingCreatePurchaseOrder = true
    },
    updatePurchaseOrderSuccess: (state) => {
      state.loadingCreatePurchaseOrder = false
    },
    updatePurchaseOrderFail: (state) => {
      state.loadingCreatePurchaseOrder = false
    },
    clearPurchaseOrder: (state) => {
      state.purchaseOrder = null
      state.loadingCreatePurchaseOrder = false
    },
    // create job detail invoice
    createPurchaseOrderRequest: (state, payload) => {
      state.loadingCreatePurchaseOrder = true
    },
    createPurchaseOrderSuccess: (state) => {
      state.loadingCreatePurchaseOrder = false
    },
    createPurchaseOrderFail: (state) => {
      state.loadingCreatePurchaseOrder = false
    },
    //-------------------------------------------------------------------------------
    // get purchase order by id
    getTranslatorCertificateByIdRequest: (state, action) => {
      state.loadingTranslatorCertificate = true
    },
    getTranslatorCertificateByIdSuccess: (state, { payload }) => {
      state.loadingTranslatorCertificate = false
      state.translatorCertificate = payload
    },
    getTranslatorCertificateByIdFail: (state) => {
      state.loadingTranslatorCertificate = false
    },
    //update purchase order
    updateTranslatorCertificateRequest: (state, payload) => {
      state.edit = true
      state.loadingCreateTranslatorCertificate = true
    },
    updateTranslatorCertificateSuccess: (state) => {
      state.loadingCreateTranslatorCertificate = false
    },
    updateTranslatorCertificateFail: (state) => {
      state.loadingCreateTranslatorCertificate = false
    },
    clearTranslatorCertificate: (state) => {
      state.translatorCertificate = null
      state.loadingCreateTranslatorCertificate = false
    },
    // create job detail invoice
    createTranslatorCertificateRequest: (state, payload) => {
      state.loadingCreateTranslatorCertificate = true
    },
    createTranslatorCertificateSuccess: (state) => {
      state.loadingCreateTranslatorCertificate = false
    },
    createTranslatorCertificateFail: (state) => {
      state.loadingCreateTranslatorCertificate = false
    },
    //

    getPurchaseChangeAmountByIdRequest: (state, action) => {
      state.loadingGetPurchaseChangeAmount = false
    },
    getPurchaseChangeAmountByIdSuccess: (state, { payload }) => {
      state.loadingGetPurchaseChangeAmount = false
      state.purchaseChangeAmountDetails = payload?.results
    },
    getPurchaseChangeAmountByIdFail: (state) => {
      state.loadingGetPurchaseChangeAmount = false
      state.purchaseChangeAmountDetails = null
    },
    clearPurchaseChangeAmountById: (state) => {
      state.loadingGetPurchaseChangeAmount = false
      state.purchaseChangeAmountDetails = null
    },
    //
    getJobTaskStockingDateByIdRequest: (state, action) => {
      state.loadingGetJobTaskStockingDate = false
    },
    getJobTaskStockingDateByIdSuccess: (state, { payload }) => {
      state.loadingGetJobTaskStockingDate = false
      state.jobTaskStockingDateDetails = payload?.results
    },
    getJobTaskStockingDateByIdFail: (state) => {
      state.loadingGetJobTaskStockingDate = false
      state.jobTaskStockingDateDetails = null
    },
    //get UserMadeInvoice by id
    getUserMadeInvoiceByIdRequest: (state, action) => {
      state.loadingUserMadeInvoice = true
    },
    //get UserMadeInvoice by id
    getMultipleUserMadeInvoiceByIdRequest: (state, action) => {
      state.loadingUserMadeInvoice = true
    },
    getUserMadeInvoiceByIdSuccess: (state, { payload }) => {
      state.userMadeInvoice = payload
      state.loadingUserMadeInvoice = false
    },
    getUserMadeInvoiceByIdFail: (state) => {
      state.loadingUserMadeInvoice = false
    },
    clearUserMadeInvoice: (state) => {
      state.userMadeInvoice = null
    },
    //update UserMadeInvoice
    updateUserMadeInvoiceRequest: (state, payload) => {
      state.loadingCreateUserMadeInvoice = true
    },
    updateUserMadeInvoiceSuccess: (state) => {
      state.loadingCreateUserMadeInvoice = false
    },
    updateUserMadeInvoiceFail: (state) => {
      state.loadingCreateUserMadeInvoice = false
    },
    //
    createUserMadeInvoiceRequest: (state, payload) => {
      state.loadingCreateUserMadeInvoice = true
    },
    createUserMadeInvoiceSuccess: (state) => {
      state.loadingCreateUserMadeInvoice = false
    },
    createUserMadeInvoiceFail: (state) => {
      state.loadingCreateUserMadeInvoice = false
    },
    //
    getTranslatorPurchaseOrderDetailByIdRequest: (state, action) => {
      state.loadingGetTranslatorPurchaseOrderDetail = false
    },
    getTranslatorPurchaseOrderDetailByIdSuccess: (state, { payload }) => {
      state.loadingGetTranslatorPurchaseOrderDetail = false
      state.translatorPurchaseOrderDetailDetails = payload?.results
    },
    getTranslatorPurchaseOrderDetailByIdFail: (state) => {
      state.loadingGetTranslatorPurchaseOrderDetail = false
      state.translatorPurchaseOrderDetailDetails = null
    },
    //get TranslatorInvoiceUpload by id
    getTranslatorInvoiceUploadByCertIdRequest: (state, action) => {
      state.loadingTranslatorInvoiceUpload = true
    },
    getTranslatorInvoiceUploadByIdSuccess: (state, { payload }) => {
      state.translatorInvoiceUpload = payload
      state.loadingTranslatorInvoiceUpload = false
    },
    getTranslatorInvoiceUploadByIdFail: (state) => {
      state.loadingTranslatorInvoiceUpload = false
    },
    clearTranslatorInvoiceUpload: (state) => {
      state.translatorInvoiceUpload = null
    },
    //update TranslatorInvoiceUpload
    updateTranslatorInvoiceUploadRequest: (state, payload) => {
      state.loadingCreateTranslatorInvoiceUpload = true
    },
    updateTranslatorInvoiceUploadSuccess: (state) => {
      state.loadingCreateTranslatorInvoiceUpload = false
    },
    updateTranslatorInvoiceUploadFail: (state) => {
      state.loadingCreateTranslatorInvoiceUpload = false
    },
    //
    createTranslatorInvoiceUploadRequest: (state, payload) => {
      state.loadingCreateTranslatorInvoiceUpload = true
    },
    createTranslatorInvoiceUploadSuccess: (state) => {
      state.loadingCreateTranslatorInvoiceUpload = false
    },
    createTranslatorInvoiceUploadFail: (state) => {
      state.loadingCreateTranslatorInvoiceUpload = false
    },
    //job task inspection withdrawal
    updateInspectionWithdrawalRequest: (state, payload) => {
      state.loadingUpdateInspectionWithdrawal = true
    },
    updateInspectionWithdrawalSuccess: (state) => {
      state.loadingUpdateInspectionWithdrawal = false
    },
    updateInspectionWithdrawalFail: (state) => {
      state.loadingUpdateInspectionWithdrawal = false
    },
    //
    downloadJobListSalesExportRequest: (state, action) => {
      state.loadingDownloadJobListSalesExport = true
    },
    downloadJobListSalesExportSuccess: (state) => {
      state.loadingDownloadJobListSalesExport = false
    },
    downloadJobListSalesExportFail: (state) => {
      state.loadingDownloadJobListSalesExport = false
    },
    //
    clearHardResetJobList: (state) => {
      return initialState
    },
  },
})
export const {
  loadingJobList,
  getJobListSuccess,
  getJobListFail,
  getJobListRequest,
  getJobListPreviousRequest,
  getJobListNextRequest,
  clearDataJobListDetails,
  //create
  createJobDetailsRequest,
  createJobDetailsSuccess,
  createJobDetailsFail,
  //
  getJobListDetailSuccess,
  getJobListDetailFail,
  getJobListDetailRequest,
  //
  getJobListDetailByIdRequest,
  getJobListDetailByIdSuccess,
  getJobListDetailByIdFail,
  //create translator
  createTranslatorRequest,
  createTranslatorSuccess,
  createTranslatorFail,
  //get translator list for table
  getCommonTranslatorTableListRequest,
  getCommonTranslatorTableListSuccess,
  getCommonTranslatorTableListFail,
  //get transltor table list filter
  getTranslatorFilterRequest,
  getCommonTranslatorNextRequest,
  getCommonTranslatorPreviousRequest,
  //translator filter option
  // loadingTranslatorFilterOption,
  // getTranslatorFilterOptionRequest,
  // getTranslatorFilterOptionSuccess,
  // getTranslatorFilterOptionFail,
  //
  updateJobDetailsRequest,
  updateJobDetailsSuccess,
  updateJobDetailsFail,
  //job task
  getJobTaskByIdRequest,
  getJobTaskByIdSuccess,
  getJobTaskByIdFail,
  clearJobTask,
  //update job task
  updateJobTaskRequest,
  updateJobTaskSuccess,
  updateJobTaskFail,
  getJobDetailListNextRequest,
  getJobDetailListPreviousRequest,
  //
  createIssueInvoiceRequest,
  createIssueInvoiceSuccess,
  createIssueInvoiceFail,
  //updat job detail invoice
  updateIssueInvoiceRequest,
  updateIssueInvoiceSuccess,
  updateIssueInvoiceFail,
  clearJobDetailInvoice,
  //get job detail individual rows
  getJobDetailRowByIdRequest,
  getJobDetailRowByIdSuccess,
  getJobDetailRowByIdFail,
  clearJobDetailRow,
  //get inovice by job detail id
  getJobDetailInvoiceByIdRequest,
  getJobDetailInvoiceByIdSuccess,
  getJobDetailInvoiceByIdFail,
  //purchase order
  getPurchaseOrderByIdRequest,
  getPurchaseOrderByIdSuccess,
  getPurchaseOrderByIdFail,
  createPurchaseOrderRequest,
  createPurchaseOrderSuccess,
  createPurchaseOrderFail,
  updatePurchaseOrderRequest,
  updatePurchaseOrderSuccess,
  updatePurchaseOrderFail,
  clearPurchaseOrder,
  //translator certificate
  getTranslatorCertificateByIdRequest,
  getTranslatorCertificateByIdSuccess,
  getTranslatorCertificateByIdFail,
  updateTranslatorCertificateRequest,
  updateTranslatorCertificateSuccess,
  updateTranslatorCertificateFail,
  createTranslatorCertificateRequest,
  createTranslatorCertificateSuccess,
  createTranslatorCertificateFail,
  clearTranslatorCertificate,
  //
  createPurchaseAmountChangeRequest,
  createPurchaseAmountChangeSuccess,
  createPurchaseAmountChangeFail,
  //
  getPurchaseChangeAmountByIdRequest,
  getPurchaseChangeAmountByIdSuccess,
  getPurchaseChangeAmountByIdFail,
  //
  createJobTaskRequest,
  createJobTaskSuccess,
  createJobTaskFail,
  //
  getJobTaskStockingDateByIdRequest,
  getJobTaskStockingDateByIdSuccess,
  getJobTaskStockingDateByIdFail,
  // user made invoice
  getUserMadeInvoiceByIdRequest,
  getMultipleUserMadeInvoiceByIdRequest,
  getUserMadeInvoiceByIdSuccess,
  getUserMadeInvoiceByIdFail,
  createUserMadeInvoiceRequest,
  createUserMadeInvoiceSuccess,
  createUserMadeInvoiceFail,
  updateUserMadeInvoiceRequest,
  updateUserMadeInvoiceSuccess,
  updateUserMadeInvoiceFail,
  clearUserMadeInvoice,
  //
  getTranslatorPurchaseOrderDetailByIdRequest,
  getTranslatorPurchaseOrderDetailByIdSuccess,
  getTranslatorPurchaseOrderDetailByIdFail,
  //translator invoice upload
  getTranslatorInvoiceUploadByCertIdRequest,
  getTranslatorInvoiceUploadByIdFail,
  getTranslatorInvoiceUploadByIdSuccess,
  createTranslatorInvoiceUploadRequest,
  createTranslatorInvoiceUploadSuccess,
  createTranslatorInvoiceUploadFail,
  clearTranslatorInvoiceUpload,
  updateTranslatorInvoiceUploadRequest,
  updateTranslatorInvoiceUploadSuccess,
  updateTranslatorInvoiceUploadFail,
  //job task inspection withdrawal
  updateInspectionWithdrawalRequest,
  updateInspectionWithdrawalSuccess,
  updateInspectionWithdrawalFail,
  //
  updateJobDetailRowByIdRequest,
  updateJobDetailRowByIdSuccess,
  updateJobDetailRowByIdFail,
  //
  createJobDetailRowByIdRequest,
  createJobDetailRowByIdSuccess,
  createJobDetailRowByIdFail,
  //
  downloadJobListSalesExportRequest,
  downloadJobListSalesExportSuccess,
  downloadJobListSalesExportFail,
  //
  clearHardResetJobList,
  clearPurchaseChangeAmountById,
} = JobList.actions
export default JobList.reducer
