import { axiosInstance } from '../Utils/axios'

//get next
export const getNext = (next: string) => axiosInstance.get(next)
//get previous
export const getPrevious = (next: string) => axiosInstance.get(next)

export const commonUserModUrl = '/common/user'
export const commonLayoutModUrl = '/common/layout'
export const commonTranslatorURL = '/common/translator'
export const commonTradosURL = '/common/trados'
export const commonTaskURL = '/common/task'
export const commonSaleURL = '/common/sales-tax'
export const commonDocumentUrl = 'common/document'
export const commonTranslationSubjectUrl = 'common/translation-subject'
export const commonExpenseTypeUrl = 'common/expense-type'
export const commonIndustryTypeUrl = 'common/industry-type'
export const commonExportRestrictionUrl = 'common/export-restriction'
export const commonJobUrl = '/job-case'
export const commonCostSharingDepartmentCodeModUrl =
  '/common/cost-sharing-department'
export const commonSalesDepartmentModUrl = '/common/sales-department'
export const commonSalesTypeModUrl = '/common/sales-type'
export const commonDepartmentModUrl = '/common/department'
export const commonStatusModUrl = '/common/status'
export const commonAffiliatedDepartmentModUrl = '/common/affiliated-department'
export const commonTaskTypeModUrl = '/common/task-type'

export const getCommonRequester = (rowsPerPage: number) => {
  return axiosInstance.get(`${commonUserModUrl}?offset=0&limit=${rowsPerPage}`)
}
export const getCommonLayout = (rowsPerPage: number) => {
  return axiosInstance.get(
    `${commonLayoutModUrl}?offset=0&limit=${rowsPerPage}`
  )
}
export const getCommonTranslatorLanguage = (rowsPerPage: number) => {
  return axiosInstance.get(
    `/common/translation-language?offset=0&limit=${rowsPerPage}`
  )
}

export const getCommonCompany = (rowsPerPage: number) => {
  return axiosInstance.get(`/common/company?offset=0&limit=${rowsPerPage}`)
}

export const getCommonPaymentTerms = (rowsPerPage: number) => {
  return axiosInstance.get(
    `/common/payment-terms?offset=0&limit=${rowsPerPage}`
  )
}

export const getCommonDeliveryStyle = (rowsPerPage: number) => {
  return axiosInstance.get(
    `/common/delivery-style?offset=0&limit=${rowsPerPage}`
  )
}

//common translator
export const getCommonTranslator = (rowsPerPage: number) => {
  return axiosInstance.get(
    `${commonTranslatorURL}?offset=0&limit=${rowsPerPage}`
  )
}

//get filterd translator matching table list
export const getTranslatorFilter = (id: number) => {
  return axiosInstance.get(`/job-task?translator=${id}`)
}

//common trados
export const getCommonTrados = (rowsPerPage: number) => {
  return axiosInstance.get(`${commonTradosURL}?offset=0&limit=${rowsPerPage}`)
}

//common task
export const getCommonTask = (rowsPerPage: number) => {
  return axiosInstance.get(`${commonTaskURL}?offset=0&limit=${rowsPerPage}`)
}

//common trados
export const getCommonSaleTax = (rowsPerPage: number) => {
  return axiosInstance.get(`${commonSaleURL}?offset=0&limit=${rowsPerPage}`)
}

//
export const getCommonDocument = (rowsPerPage: number) => {
  return axiosInstance.get(`${commonDocumentUrl}?offset=0&limit=${rowsPerPage}`)
}

//
export const getCommonTranslationSubject = (rowsPerPage: number) => {
  return axiosInstance.get(
    `${commonTranslationSubjectUrl}?offset=0&limit=${rowsPerPage}`
  )
}

export const getCommonExpenseType = (rowsPerPage: number) => {
  return axiosInstance.get(
    `${commonExpenseTypeUrl}?offset=0&limit=${rowsPerPage}`
  )
}

//
export const getCommonIndustryType = (rowsPerPage: number) => {
  return axiosInstance.get(
    `${commonIndustryTypeUrl}?offset=0&limit=${rowsPerPage}`
  )
}

export const getCommonExportRestriction = (rowsPerPage: number) => {
  return axiosInstance.get(
    `${commonExportRestrictionUrl}?offset=0&limit=${rowsPerPage}`
  )
}

//
export const getCommonJob = (rowsPerPage: number) => {
  return axiosInstance.get(`${commonJobUrl}?offset=0&limit=${rowsPerPage}`)
}

//
export const getCommonUser = (rowsPerPage: number) => {
  return axiosInstance.get(`${commonUserModUrl}?offset=0&limit=${rowsPerPage}`)
}

//

export const getCommonCostSharingDepartmentCode = (rowsPerPage: number) => {
  return axiosInstance.get(
    `${commonCostSharingDepartmentCodeModUrl}?offset=0&limit=${rowsPerPage}`
  )
}

export const getCommonSalesDepartment = (rowsPerPage: number) => {
  return axiosInstance.get(
    `${commonSalesDepartmentModUrl}?offset=0&limit=${rowsPerPage}`
  )
}
export const getCommonSalesType = (rowsPerPage: number) => {
  return axiosInstance.get(
    `${commonSalesTypeModUrl}?offset=0&limit=${rowsPerPage}`
  )
}

export const getCommonDepartment = (rowsPerPage: number) => {
  return axiosInstance.get(
    `${commonDepartmentModUrl}?offset=0&limit=${rowsPerPage}`
  )
}

export const getCommonAffiliatedDepartment = (rowsPerPage: number) => {
  return axiosInstance.get(
    `${commonAffiliatedDepartmentModUrl}?offset=0&limit=${rowsPerPage}`
  )
}

export const getCommonTaskType = (rowsPerPage: number) => {
  return axiosInstance.get(
    `${commonTaskTypeModUrl}?offset=0&limit=${rowsPerPage}`
  )
}

export const getCommonStatus = (rowsPerPage: number) => {
  return axiosInstance.get(
    `${commonStatusModUrl}?offset=0&limit=${rowsPerPage}`
  )
}
