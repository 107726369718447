import React from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, useNavigate } from 'react-router-dom'

import {
  Button,
  FormControl,
  IconButton,
  Checkbox,
  InputLabel,
  Link,
  OutlinedInput,
  FormControlLabel,
  FormGroup,
  TextField,
  Typography,
  Toolbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import InputAdornment from '@mui/material/InputAdornment'
import Paper from '@mui/material/Paper'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'

import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import AlternateEmailRoundedIcon from '@mui/icons-material/AlternateEmailRounded'
import LockRoundedIcon from '@mui/icons-material/LockRounded'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'

import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import {
  useAppDispatch,
  useAppSelector,
} from '../../ReusableComponent/CustomHooks/appHooks'
import { registerRequest } from '../Redux/authSlice'
import { authSelector } from '../Redux/selector'
import { RegisterPayload } from '../Redux/types'

//initial form values
const initialValues: RegisterPayload = {
  email: '',
  first_name: '',
  last_name: '',
  password1: '',
  password2: '',
}

const Register: React.FC = () => {
  const [showPassword, setShowPassword] = React.useState(false)
  const { loading, isAuthenticated, token } = useAppSelector(authSelector)
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const nav = useNavigate()
  const [open, setOpen] = React.useState(false)

  const openSuccessDialog = () => {
    setOpen(true)
  }
  const closeSuccessDialog = () => {
    setOpen(false)
  }
  const loginNav = () => {
    nav('/login')
  }

  const handleClickShowPassword = () => setShowPassword((show) => !show)

  //validation for form fields
  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required(t('validationMessage.required')),
    last_name: Yup.string().required(t('validationMessage.required')),
    email: Yup.string()
      .email(t('validationMessage.email'))
      .required(t('validationMessage.required')),
    password1: Yup.string().required(t('validationMessage.required')),
    // .min(6, 'パスワードには、少なくとも6文字含める必要があります'),
    password2: Yup.string()
      .required(t('validationMessage.required'))
      .oneOf([Yup.ref('password1'), ''], t('validationMessage.passwordMatch')),
  })

  //handle form submittion
  const handleSubmit = (values: RegisterPayload) => {
    dispatch(registerRequest(values))
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="100vh"
    >
      <Paper elevation={20}>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            values,
            handleChange,
            setFieldValue,
            handleBlur,
            touched,
            errors,
          }) => {
            return (
              <Form autoComplete="off">
                <AppBar position="static">
                  <Toolbar>
                    <ManageAccountsIcon fontSize="large" sx={{ mr: 2 }} />
                    <Typography variant="h5">
                      {t('auth.register.register')}
                    </Typography>
                  </Toolbar>
                </AppBar>
                {/* 登録後もisAuthenticatedの値が変わらなかったので、ログインのロジックを参考にしてtokenを条件に使いました。 */}
                {token ? (
                  <Dialog
                    open={open}
                    onClose={closeSuccessDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      {t('common.complete')}
                    </DialogTitle>
                    <DialogContent id="alert-dialog-description">
                      <DialogContentText>
                        {t('common.message')}
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={loginNav} variant="contained">
                        {t('auth.login')}
                      </Button>
                      <Button onClick={closeSuccessDialog} autoFocus>
                        {t('common.Expanded')}
                      </Button>
                    </DialogActions>
                  </Dialog>
                ) : (
                  ''
                )}
                <Grid container sx={{ p: 2 }} spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      id="outlined-adornment-pid-email"
                      name="email"
                      type="text"
                      label={t('auth.register.email')}
                      autoFocus
                      fullWidth
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.email && Boolean(errors.email)}
                      helperText={touched.email && errors.email}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id="outlined-adornment-pid-lastName"
                      name="last_name"
                      type="text"
                      label={t('auth.register.lastName')}
                      fullWidth
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.last_name && Boolean(errors.last_name)}
                      helperText={touched.last_name && errors.last_name}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id="outlined-adornment-pid-firstName"
                      name="first_name"
                      type="text"
                      label={t('auth.register.firstName')}
                      fullWidth
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.first_name && Boolean(errors.first_name)}
                      helperText={touched.first_name && errors.first_name}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="password1"
                      name="password1"
                      type={showPassword ? 'text' : 'password'}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      fullWidth
                      label={t('auth.password')}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              // onMouseDown={handleMouseDownPassword}
                              edge="end"
                              tabIndex={-1}
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      error={touched.password1 && Boolean(errors.password1)}
                      helperText={touched.password2 && errors.password2}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="password2"
                      name="password2"
                      type={showPassword ? 'text' : 'password'}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      fullWidth
                      label={t('auth.confirmPassword')}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle confirm password visibility"
                              onClick={handleClickShowPassword}
                              // onMouseDown={handleMouseDownPassword}
                              edge="end"
                              tabIndex={-1}
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      error={touched.password2 && Boolean(errors.password2)}
                      helperText={touched.password2 && errors.password2}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      size="large"
                      variant="contained"
                      onClick={() => nav('/login')}
                    >
                      {t('common.goBack')}
                    </Button>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sx={{ display: 'flex', flexDirection: 'row-reverse' }}
                  >
                    <Button
                      size="large"
                      variant="contained"
                      type="submit"
                      disabled={loading}
                      onClick={openSuccessDialog}
                    >
                      {t('auth.submit')}
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )
          }}
        </Formik>
      </Paper>
    </Box>
  )
}

export default Register
