import axios from 'axios'

import { axiosInstance } from '../../Utils/axios'
import {
  LoginPayload,
  ForgetPasswordPayload,
  ResetPasswordPayload,
  RegisterPayload,
  ChangePasswordPayload,
} from './types'

const authPrefix = 'auth'

const loginAxios = axios.create({
  baseURL: `${process.env.REACT_APP_API_PREFIX}`,
  headers: { 'Content-Type': 'application/json' },
})

export const login = (body: LoginPayload) =>
  loginAxios.post(`${authPrefix}/login`, body)
//for logout
export const logoutApi = () => axiosInstance.post(`${authPrefix}/logout`)

export const resetPassword = (body: ForgetPasswordPayload) =>
  loginAxios.post(`${authPrefix}/password/reset`, body)

export const confirmPassword = (body: ResetPasswordPayload) =>
  loginAxios.post(`${authPrefix}/password/confirm`, body)

export const getCurrentUser = () => axiosInstance.get(`${authPrefix}/user`)

export const register = (body: RegisterPayload) =>
  loginAxios.post(`${authPrefix}/register`, body)

export const changePassword = (body: ChangePasswordPayload) =>
  axiosInstance.post(`${authPrefix}/password/change`, body)
