import { useTranslation } from 'react-i18next'

import {
  handleFocusCompanyCommon,
  onChangeInputCompanyCommon,
} from '../../CommonAppRedux/commonAppFunctions'
import { commonAppSelector } from '../../CommonAppRedux/selector'
import { query } from '../../Utils/globalTypes'
import AppAutoComplete from '../AppAutoComplete/AppAutoComplete'
import { useAppDispatch, useAppSelector } from '../CustomHooks/appHooks'

interface CompanySelectProps {
  label: any
  value: any
  setFieldValue: any
  field: any
  handleBlur: {
    (e: React.FocusEvent<any, Element>): void
    <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void
  }
  isDisabled: boolean
  setQuery: React.Dispatch<React.SetStateAction<query>>
}

const CompanySelect = ({
  label,
  value,
  field,
  setFieldValue,
  handleBlur,
  isDisabled,
  setQuery,
}: CompanySelectProps) => {
  const dispatch = useAppDispatch()
  const { commonCompanies } = useAppSelector(commonAppSelector)
  const { t } = useTranslation()
  const getCompanyOptionLabel = (option: any) => {
    if (typeof option === 'string') {
      return option
    }
    if (option.name) {
      return option.name
    }
    return option.inputValue
  }

  return (
    <>
      <AppAutoComplete
        xs={2.5}
        options={commonCompanies}
        value={value}
        getOptionLabel={getCompanyOptionLabel}
        label={label}
        name={field}
        onChange={(_: object, value: any) => {
          if (value !== null) {
            setFieldValue(field, value)
          } else {
            setFieldValue(field, null)
          }
        }}
        onFocus={() => handleFocusCompanyCommon(dispatch)}
        onBlur={handleBlur}
        inputOnchange={(e: any) =>
          onChangeInputCompanyCommon(e.target, setQuery)
        }
        disabled={isDisabled}
      />
    </>
  )
}
export default CompanySelect
