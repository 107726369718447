import { createSlice } from '@reduxjs/toolkit'

import { InspectionTaskStateListInitialSlice } from '../Types'

const initialState: InspectionTaskStateListInitialSlice = {
  inspectionTaskStateLists: [],
  inspectionTaskStateList: null,
  loadingInspectionTaskStateList: false,
  loadingCreateInspectionTaskStateList: false,
  count: 0,
  next: '',
  previous: '',
  loadingCreate: false,
  inspectionTaskStateListDetails: [],
  inspectionTaskStateListDetail: null,
  loadingOrderDetails: false,
  loadingBulkUpdateInspectionCertificate: false,
}
export const InspectionTaskStateList = createSlice({
  name: 'inspectionTaskStateListReducer',
  initialState,
  reducers: {
    loadingInspectionTaskStateList: (state) => {
      state.loadingInspectionTaskStateList = true
    },
    // getInspectionTaskStateListRequest: (state, action) => {
    //   state.loadingInspectionTaskStateList = true
    // },
    // getInspectionTaskStateListSuccess: (
    //   state,
    //   { payload: { results, count, next, previous } }
    // ) => {
    //   state.loadingInspectionTaskStateList = false
    //   state.inspectionTaskStateLists = results
    //   state.count = count
    //   state.next = next
    //   state.previous = previous
    // },
    // getInspectionTaskStateListFail: (state) => {
    //   state.loadingInspectionTaskStateList = false
    // },
    //
    getInspectionTaskStateListDetailRequest: (state, action) => {
      state.loadingOrderDetails = true
    },
    getInspectionTaskStateListDetailSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingOrderDetails = false
      state.inspectionTaskStateListDetails = results
      state.count = count
      state.next = next
      state.previous = previous
    },
    getInspectionTaskStateListDetailFail: (state) => {
      state.loadingOrderDetails = false
    },
    //
    getOrderDetailListNextRequest: (state, payload) => {
      state.loadingOrderDetails = true
    },
    getOrderDetailListPreviousRequest: (state, payload) => {
      state.loadingOrderDetails = true
    },
    //
    // getInspectionTaskStateListDetailByIdRequest: (state, action) => {
    //   state.loadingOrderDetails = true
    // },
    // getInspectionTaskStateListDetailByIdSuccess: (state, { payload }) => {
    //   state.inspectionTaskStateListDetail = payload
    // },
    // getInspectionTaskStateListDetailByIdFail: (state) => {
    //   state.loadingOrderDetails = false
    //   state.inspectionTaskStateListDetail = null
    // },
    // getInspectionTaskStateListNextRequest: (state, action) => {
    //   state.loadingInspectionTaskStateList = true
    // },

    // getInspectionTaskStateListPreviousRequest: (state, action) => {
    //   state.loadingInspectionTaskStateList = true
    // },
    //
    bulkUpdateInspectionCertificateByIdRequest: (state, action) => {
      state.loadingBulkUpdateInspectionCertificate = true
    },
    bulkUpdateInspectionCertificateByIdSuccess: (state, { payload }) => {
      state.loadingBulkUpdateInspectionCertificate = false
    },
    bulkUpdateInspectionCertificateByIdFail: (state) => {
      state.loadingBulkUpdateInspectionCertificate = false
    },
  },
})
export const {
  loadingInspectionTaskStateList,
  // getInspectionTaskStateListSuccess,
  // getInspectionTaskStateListFail,
  // getInspectionTaskStateListRequest,
  // getInspectionTaskStateListPreviousRequest,
  // getInspectionTaskStateListNextRequest,
  //
  getInspectionTaskStateListDetailSuccess,
  getInspectionTaskStateListDetailFail,
  getInspectionTaskStateListDetailRequest,
  //
  // getInspectionTaskStateListDetailByIdRequest,
  // getInspectionTaskStateListDetailByIdSuccess,
  // getInspectionTaskStateListDetailByIdFail,
  getOrderDetailListNextRequest,
  getOrderDetailListPreviousRequest,
  //
  bulkUpdateInspectionCertificateByIdRequest,
  bulkUpdateInspectionCertificateByIdSuccess,
  bulkUpdateInspectionCertificateByIdFail,
  //create translator
} = InspectionTaskStateList.actions
export default InspectionTaskStateList.reducer
