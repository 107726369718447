import { createSlice } from '@reduxjs/toolkit'

import { InputTrackRecordInitialSlice } from '../Types'

const initialState: InputTrackRecordInitialSlice = {
  InputTrackRecord: null,
  loadingInputTrackRecord: false,
  loadingCreateInputTrackRecord: false,
  count: 0,
  next: '',
  previous: '',
}
export const InputTrackRecord = createSlice({
  name: 'InputTrackRecordReducer',
  initialState,
  reducers: {
    loadingInputTrackRecord: (state) => {
      state.loadingInputTrackRecord = true
    },
  
    createInputTrackRecordRequest: (state, action) => {
      state.loadingCreateInputTrackRecord = true
    },
    createInputTrackRecordSuccess: (state, { payload }) => {
      state.loadingCreateInputTrackRecord = false
      state.InputTrackRecord = payload
    },
    createInputTrackRecordFail: (state) => {
      state.loadingCreateInputTrackRecord = false
    },
    updateInputTrackRecordRequest: (state, action) => {
      state.loadingCreateInputTrackRecord = true
    },
    updateInputTrackRecordSuccess: (state, { payload }) => {
      state.loadingCreateInputTrackRecord = false
      state.InputTrackRecord = payload
    },
    updateInputTrackRecordFail: (state) => {
      state.loadingCreateInputTrackRecord = false
    },
   getInputTrackRecordRequest: (state, action) => {
      state.loadingInputTrackRecord = true
    },
   getInputTrackRecordSuccess: (state, { payload }) => {
      state.loadingInputTrackRecord = false
      state.InputTrackRecord = payload
    },
   getInputTrackRecordFail: (state) => {
      state.loadingInputTrackRecord = false
    },
    clearInputTrackRecord:(state)=>{
      state.InputTrackRecord = null
    },
    clearHardResetInputTrackRecord: (state) => {
      return initialState;
     },
  },
})
export const {
  loadingInputTrackRecord,
  clearHardResetInputTrackRecord,
  clearInputTrackRecord,
  
 createInputTrackRecordRequest,
 createInputTrackRecordSuccess,
 createInputTrackRecordFail,
 //
 updateInputTrackRecordRequest,
 updateInputTrackRecordSuccess,
 updateInputTrackRecordFail,
 //
 getInputTrackRecordRequest,
 getInputTrackRecordSuccess,
 getInputTrackRecordFail,
  //create translator
} = InputTrackRecord.actions
export default InputTrackRecord.reducer
