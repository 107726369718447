import { createSlice } from '@reduxjs/toolkit'

import { QuotationRequestListInitialSlice } from '../Types'

const initialState: QuotationRequestListInitialSlice = {
  quotationRequestLists: [],
  quotationRequestList: null,
  loadingQuotationRequestList: false,
  loadingCreateQuotationRequestList: false,
  count: 0,
  next: '',
  previous: '',
  loadingCreate: false,
  quotationRequestListDetails: [],
  quotationRequestListDetail: null,
  loadingOrderDetails:false,
}
export const QuotationRequestList = createSlice({
  name: 'quotationRequestListReducer',
  initialState,
  reducers: {
    loadingQuotationRequestList: (state) => {
      state.loadingQuotationRequestList = true
    },
    getQuotationRequestListRequest: (state, action) => {
      state.loadingQuotationRequestList = true
    },
    getQuotationRequestListSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingQuotationRequestList = false
      state.quotationRequestLists = results
      state.count = count
      state.next = next
      state.previous = previous
    },
    getQuotationRequestListFail: (state) => {
      state.loadingQuotationRequestList = false
    },
    //
    getQuotationRequestListDetailRequest: (state, action) => {
      state.loadingOrderDetails = true
    },
    getQuotationRequestListDetailSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingOrderDetails = false
      state.quotationRequestListDetails = results
      state.count = count
      state.next = next
      state.previous = previous
    },
    getQuotationRequestListDetailFail: (state) => {
      state.loadingOrderDetails = false
    },
    //
    getOrderDetailListNextRequest: (state, payload) => {
      state.loadingOrderDetails = true
    },
    getOrderDetailListPreviousRequest: (state, payload) => {
      state.loadingOrderDetails = true
    },
    //
    getQuotationRequestListDetailByIdRequest: (state, action) => {
      state.loadingOrderDetails = true
    },
    getQuotationRequestListDetailByIdSuccess: (state, { payload }) => {
      state.quotationRequestListDetail = payload
    },
    getQuotationRequestListDetailByIdFail: (state) => {
      state.loadingOrderDetails = false
      state.quotationRequestListDetail = null
    },
    getQuotationRequestListNextRequest: (state, action) => {
      state.loadingQuotationRequestList = true
    },

    getQuotationRequestListPreviousRequest: (state, action) => {
      state.loadingQuotationRequestList = true
    },

  
  },
})
export const {
  loadingQuotationRequestList,
  getQuotationRequestListSuccess,
  getQuotationRequestListFail,
  getQuotationRequestListRequest,
  getQuotationRequestListPreviousRequest,
  getQuotationRequestListNextRequest,
  //
  getQuotationRequestListDetailSuccess,
  getQuotationRequestListDetailFail,
  getQuotationRequestListDetailRequest,
  //
  getQuotationRequestListDetailByIdRequest,
  getQuotationRequestListDetailByIdSuccess,
  getQuotationRequestListDetailByIdFail,
  getOrderDetailListNextRequest,
  getOrderDetailListPreviousRequest,
  //create translator
 
} = QuotationRequestList.actions
export default QuotationRequestList.reducer
