import React, { useState } from 'react'

import { Card, CardHeader, Grid, Typography } from '@mui/material'

import { t } from 'i18next'

import { AppTitleCardProps } from './types'

const AppTitleCard = ({ title, sx, children, mt }: AppTitleCardProps) => {
  const sxDefault = {
    backgroundColor: '#062f96',
    height: '48px',
  }

  return (
    <Card sx={{ mb: 2, mt: mt ? mt : 0 }}>
      <CardHeader
        title={
          <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'white' }}>
            {t(title)}
          </Typography>
        }
        sx={sx ?? sxDefault}
      />
      {children}
    </Card>
  )
}

export default AppTitleCard
