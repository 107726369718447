import React, { memo, useEffect, useRef } from 'react'

import { Button, Grid } from '@mui/material'

import Spinner from '../Loader/Spinner'
import TooltipContent from '../Tooltip/Tooltip'
import { t } from 'i18next'
import { appButtonProps } from './type'

const AppButton = ({
  className,
  tooltipTitle,
  disabled,
  title,
  loading,
  color,
  onClick,
  reference,
  id,
  children,
  submit,
  name,
  style,
  autoFocus,
  sx,
  variant,
  size,
  colorSpinner,
  fullWidth,
}: appButtonProps) => {
  const sxDefault = { mx: 0 }
  return (
    <TooltipContent  key={title} title={tooltipTitle ? tooltipTitle : title ? title : ''}>
      <Button
        id={id}
        color={color??"primary"}
        style={style}
        name={name ?? tooltipTitle}
        type={submit ? 'submit' : 'button'}
        disabled={loading || disabled}
        onClick={onClick}
        autoFocus={autoFocus}
        ref={reference}
        variant={variant??"contained"}
        sx={sx ?? sxDefault}
        size={size??"large"}
       
      >
        <span>{loading ? <Spinner colorSpinner={colorSpinner} /> : title}</span>
        {children && t(children)}
      </Button>
    </TooltipContent>

  )
}
export default memo(AppButton)
