import { Action } from '@reduxjs/toolkit'
import { t } from 'i18next'
import { combineEpics } from 'redux-observable'
import { Observable, from, of } from 'rxjs'
import { catchError, filter, map, mergeMap, switchMap } from 'rxjs/operators'

import { getNext, getPrevious } from '../../../CommonAppRedux/api'
import {
  alertErrorAction,
  alertSuccessAction,
} from '../../../CommonAppRedux/commonAppSlice'
import { extractErrorMessage } from '../../../Utils/AppFunction'
import messages from '../../../Utils/ValidationMessage'
import { defaultPage, defaultRowsPerPage } from '../../../Utils/globalConstant'
import { dispatchAction, stateAction } from '../../../Utils/globalTypes'
import { completeInspection, getTaskStateList, matchingCancel } from './api'
import {
  loadingTaskStateList,
  getTaskStateListSuccess,
  getTaskStateListFail,
  getTaskStateListRequest,
  getTaskStateListNextRequest,
  getTaskStateListPreviousRequest,
  completeInspectionRequest,
  completeInspectionFinish,
  matchingCancelRequest,
  matchingCancelFinish, // getTranslatorFilterOptionFail,
} from './taskStateListSlice'

const getTaskStateListEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(getTaskStateListRequest.match),
    switchMap(async (action) => {
      dispatch(loadingTaskStateList())
      try {
        const response = await getTaskStateList(action.payload)
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? getTaskStateListSuccess(action?.payload)
        : getTaskStateListFail()
    )
  )
}

//get next
const getTaskStateListNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getTaskStateListNextRequest.match),
    switchMap(async (action) => {
      try {
        const response = await getNext(action.payload)
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? getTaskStateListSuccess(action?.payload)
        : getTaskStateListFail()
    )
  )
//get previous
const getTaskStateListPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getTaskStateListPreviousRequest.match),
    switchMap(async (action) => {
      try {
        const response = await getPrevious(action.payload)
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? getTaskStateListSuccess(action?.payload)
        : getTaskStateListFail()
    )
  )

const completeInspectionEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(completeInspectionRequest.match),
    mergeMap((action) =>
      from(completeInspection(action.payload)).pipe(
        mergeMap(() => {
          return of(
            dispatch(alertSuccessAction(messages?.updateSuccess)),
            dispatch(
              getTaskStateListRequest({
                page: defaultPage,
                rowsPerPage: defaultRowsPerPage,
              })
            )
          )
        }),
        catchError((e) =>
          of(
            dispatch(alertErrorAction(extractErrorMessage(e))),
            completeInspectionFinish()
          )
        )
      )
    )
  )

const matchingCancelEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(matchingCancelRequest.match),
    mergeMap((action) =>
      from(matchingCancel(action.payload)).pipe(
        mergeMap(() => {
          return of(
            dispatch(alertSuccessAction(messages?.updateSuccess)),
            dispatch(
              getTaskStateListRequest({
                page: defaultPage,
                rowsPerPage: defaultRowsPerPage,
              })
            )
          )
        }),
        catchError((e) =>
          of(
            dispatch(alertErrorAction(extractErrorMessage(e))),
            matchingCancelFinish()
          )
        )
      )
    )
  )

export const taskStateListEpics = combineEpics(
  getTaskStateListEpic,
  getTaskStateListNext,
  getTaskStateListPrevious,
  completeInspectionEpic,
  matchingCancelEpic
)
