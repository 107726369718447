import React, { useRef, useState, useLayoutEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import { Card, Grid } from '@mui/material'

import { FieldArray, Form, Formik } from 'formik'
import * as Yup from 'yup'

import { alertInfoAction } from '../../../CommonAppRedux/commonAppSlice'
import { authSelector } from '../../../Login/Redux/selector'
import AppDatePicker from '../../../ReusableComponent/AppDatePicker/AppDatePicker'
import AppTextField from '../../../ReusableComponent/AppTextField/AppTextField'
import { NumericFormatCustom } from '../../../ReusableComponent/CommonReportTable/CommonReportTable'
import CompanySelect from '../../../ReusableComponent/CompanySelect/CompanySelect'
import {
  useAppDispatch,
  useAppSelector,
} from '../../../ReusableComponent/CustomHooks/appHooks'
import { useInputSearch } from '../../../ReusableComponent/CustomHooks/useInputSearch'
import FocusElementOnInvalidValidation from '../../../ReusableComponent/FocusElementIfInvalid/FocusElementOnInvalidValidation'
import AddNewInvoiceFormBtn from '../../../ReusableComponent/MultipleUploadScreen/AddNewInvoiceFormBtn'
import CardTitle from '../../../ReusableComponent/MultipleUploadScreen/CardTitle'
import FileUploadAndWithdrawBtn from '../../../ReusableComponent/MultipleUploadScreen/FileUploadAndWithdrawBtn'
import {
  gridStyles,
  cardStyles,
  datePickerStyles,
} from '../../../ReusableComponent/MultipleUploadScreen/styles'
import SaveFooterComponent from '../../../ReusableComponent/SaveFooterButton/SaveFooterButton'
import { dateFormatter } from '../../../Utils/AppFormatFunction'
import { downloadFile, todayDate } from '../../../Utils/AppFunction'
import messages from '../../../Utils/ValidationMessage'
import { query } from '../../../Utils/globalTypes'
import { company } from '../../JobList/Types'
import {
  clearDataProjectDetails,
  clearHardResetProjectList,
  clearPurchaseOrderDetails,
  createPurchaseOrderUploadRequest,
  getProjectDetailByIdRequest,
  getPurchaseOrderByIdRequest,
  updatePurchaseOrderRequest,
} from '../Redux/projectListSlice'
import { projectListSelector } from '../Redux/selector'

export interface PurchaseOrderUploadPayload {
  id?: number
  amount: number
  case?: number
  date_recorded: string | Date | null
  file: string | File | null | any
  company: company | null
  issue_date: string | Date | null
  job?: number
  url: string
  version?: number
  withdrawal_flag?: boolean
}

interface PurchaseOrderProps {
  purchase_order: PurchaseOrderUploadPayload[]
}

const PurchaseOrderUpload = () => {
  const location = useLocation()
  const sender = useRef('')
  const searchParams = new URLSearchParams(location.search)
  const caseId = searchParams.get('case-id')
  const edit = searchParams.get('edit')
  const [isWithdraw, setIsWithdraw] = useState(false)
  const { state } = location
  const previousUrl = state?.path

  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const {
    purchaseOrder,
    projectDetail,
    issueQuotation,
    loadingUploadPurchaseOrder,
  } = useAppSelector(projectListSelector)
  //all reducer action and clear function implement start
  useLayoutEffect(() => {
    if (caseId) {
      dispatch(getProjectDetailByIdRequest(caseId))
      dispatch(getPurchaseOrderByIdRequest(Number(caseId)))
    } else {
      dispatch(clearDataProjectDetails())
      dispatch(clearHardResetProjectList())
      dispatch(clearPurchaseOrderDetails())
    }
    return () => {
      dispatch(clearHardResetProjectList())
    }
  }, [caseId, location, dispatch, edit])

  //all reducer action and clear function implement End
  // const allowedTypes = ['image/jpeg', 'image/png', 'application/pdf']
  const allowedTypes = ['']
  const initialPurchaseOrderData: PurchaseOrderUploadPayload = {
    amount: 0,
    company: issueQuotation?.company ?? projectDetail?.request_company ?? null,
    date_recorded: todayDate,
    issue_date: todayDate,
    file: null,
    url: '',
    version: 1,
  }
  const initialState: PurchaseOrderProps = {
    purchase_order:
      purchaseOrder && purchaseOrder?.length > 0
        ? purchaseOrder
        : [initialPurchaseOrderData],
  }
  const validationSchema = Yup.object().shape({
    purchase_order: Yup.array().of(
      Yup.object().shape(
        {
          amount: Yup.mixed(),
          date_recorded: Yup.mixed(),
          issue_date: Yup.mixed().required(t(messages.required)),
          url: Yup.string().when(['file'], ([file], schema, context) => {
            return file === null
              ? schema
                  .required(t(messages.required))
                  .url(t(messages.invalidUrl))
              : schema.url(t(messages.invalidUrl))
          }),
          file: Yup.mixed().when(['url'], ([url], schema, context) => {
            return url === undefined
              ? schema
                  .required(t(messages.required))
                  .test(
                    'fileType',
                    t(messages.invalidFileType),
                    (value: any) => {
                      if (!value) {
                        return true
                      }
                      return (
                        allowedTypes.includes(value.type) ||
                        allowedTypes.includes('')
                      )
                    }
                  )
              : schema.nullable()
          }),
        },
        [['url', 'file']]
      )
    ),
  })

  const nav = useNavigate()
  const onSubmit = (values: PurchaseOrderProps, actions: any) => {
    const firstPurchaseOrder = values?.purchase_order[0]
    const { amount } = firstPurchaseOrder
    const updatedAmount = amount
    if (!edit || (edit && !firstPurchaseOrder.id)) {
      const auto_issue = sender.current === 'auto-issue'
      const send_only = sender.current === 'send-only'

      dispatch(
        createPurchaseOrderUploadRequest({
          id: projectDetail?.purchase_order_case?.id,
          values: {
            ...firstPurchaseOrder,
            job: projectDetail?.job?.id ?? '',
            case: projectDetail?.id,
            amount: updatedAmount,
            company: firstPurchaseOrder?.company?.id ?? '',
            auto_issue: auto_issue,
            send_only: send_only,
            version: 1,
            file:
              firstPurchaseOrder?.file === '' ? null : firstPurchaseOrder.file,
            issue_date: firstPurchaseOrder?.issue_date
              ? firstPurchaseOrder?.issue_date
              : '',
          },
          previousUrl,
          nav,
          actions,
        })
      )
    } else {
      const { id, ...restValues } = purchaseOrder[0]
      dispatch(
        updatePurchaseOrderRequest({
          id: id,
          values: {
            ...restValues,
            ...firstPurchaseOrder,
            amount: updatedAmount,
            company: firstPurchaseOrder?.company?.id ?? '',
            file:
              firstPurchaseOrder?.file === '' ? null : firstPurchaseOrder.file,
            issue_date: firstPurchaseOrder?.issue_date
              ? firstPurchaseOrder?.issue_date
              : '',
            version: firstPurchaseOrder?.version,
            withdrawal_flag: firstPurchaseOrder?.withdrawal_flag
              ? firstPurchaseOrder?.withdrawal_flag
              : isWithdraw
              ? isWithdraw
              : false,
          },
          previousUrl,
          nav,
          actions,
        })
      )
    }
  }

  //focus elements
  const { is_staff } = useAppSelector(authSelector)

  const [query, setQuery] = useState<query>({
    search: '',
    url: '',
  })
  useInputSearch(query)
  const DETAIL_NAME = 'purchase_order'
  const isAllPurchaseOrderWithdrawed: boolean = useMemo(() => {
    return (
      purchaseOrder &&
      purchaseOrder?.length > 0 &&
      purchaseOrder?.every(
        (po: PurchaseOrderUploadPayload) => po.withdrawal_flag
      )
    )
  }, [purchaseOrder])

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initialState}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          values,
          handleChange,
          setFieldValue,
          handleBlur,
          errors,
          submitForm,
          touched,
          setValues,
        }) => {
          const handleSave = (
            e: React.MouseEvent<HTMLButtonElement, MouseEvent>
          ) => {
            sender.current = 'upload'
            setIsWithdraw(false)
            submitForm()
          }

          const handleDownload = () => {
            if (values?.purchase_order[0]?.file === null) {
              dispatch(alertInfoAction(t(messages.doesntExist)))
            } else {
              downloadFile(values?.purchase_order[0]?.file)
            }
          }
          const handleUploadAndIssue = () => {
            sender.current = projectDetail?.case_report_relation?.order_receipt
              ? 'send-only'
              : 'auto-issue'
            submitForm()
          }
          const handleBackToCase = () => {
            const queryParams = `?id=${projectDetail?.id}&edit=${true}`
            nav(`/create-agency-project${queryParams}`, {
              state: { path: location?.pathname + location?.search },
            })
          }
          const buttonConfigs = [
            ...(is_staff &&
            (['030', '040', '050', '060', '070', '080', '090'].includes(
              projectDetail?.status?.code
            ) ||
              (projectDetail?.status?.code === '010' &&
                projectDetail?.without_quotation_flag))
              ? [
                  {
                    label: 'common.upload',
                    handler: handleSave,
                    name: 'upload',
                    isShowAlert: true,
                    loading: loadingUploadPurchaseOrder,
                    message: t('confirmMessage.upload'),
                  },
                ]
              : []),
            ...((
              edit && values?.purchase_order[0]?.file !== '' && is_staff
                ? ['030', '040', '050', '060', '070', '080', '090'].includes(
                    projectDetail?.status?.code
                  )
                : [''].includes(projectDetail?.status?.code)
            )
              ? [
                  {
                    label: 'common.download',
                    handler: handleDownload,
                    isShowAlert: false,
                  },
                ]
              : []),
            ...(projectDetail?.case_report_relation?.quotation &&
            is_staff &&
            ['030', '040'].includes(projectDetail?.status?.code)
              ? [
                  {
                    label: 'common.uploadAndIssueOrderReceipt',
                    handler: handleUploadAndIssue,
                    name: projectDetail?.case_report_relation?.order_receipt
                      ? 'send-only'
                      : 'auto-issue',
                    isShowAlert: true,
                    loading: loadingUploadPurchaseOrder,
                    message: t('confirmMessage.UploadAndSendOrderReceipt'),
                  },
                ]
              : []),
            {
              label: 'common.backToCase',
              handler: handleBackToCase,
            },
          ]

          return (
            <>
              <Grid item sx={{ mb: 4 }}>
                <SaveFooterComponent
                  handleClick={() => {}}
                  buttonConfigs={buttonConfigs}
                />
              </Grid>
              <CardTitle name={t('uploadRecept.purchaseOrderUpload.name')} />
              <AddNewInvoiceFormBtn
                displayAddButton={isAllPurchaseOrderWithdrawed}
                onAddButtonClick={() => {
                  if (values?.purchase_order?.length <= purchaseOrder?.length) {
                    setValues({
                      ...values,
                      purchase_order: [
                        initialPurchaseOrderData,
                        ...values.purchase_order,
                      ],
                    })
                  }
                }}
              />
              <Form autoComplete="off" noValidate>
                <FieldArray
                  name={DETAIL_NAME}
                  render={() => (
                    <React.Fragment>
                      {values?.purchase_order?.map(
                        (value: PurchaseOrderUploadPayload, index: number) => {
                          const field = `${DETAIL_NAME}[${index}]`
                          const isDisabled = value?.withdrawal_flag
                            ? true
                            : false
                          const isShowWithDrawButton =
                            (value?.file || value?.url) &&
                            !value?.withdrawal_flag &&
                            value?.id
                              ? true
                              : false
                          return (
                            <Card
                              key={value?.id || index}
                              sx={{
                                ...cardStyles,
                                bgcolor: value?.withdrawal_flag
                                  ? '#D3D3D3'
                                  : '',
                              }}
                            >
                              <Grid container spacing={2} sx={gridStyles}>
                                <AppTextField
                                  xs={2.5}
                                  name={`${field}.amount`}
                                  type="text"
                                  value={value?.amount}
                                  placeholder={t(
                                    'uploadRecept.purchaseOrderUpload.amount'
                                  )}
                                  label={t(
                                    'uploadRecept.purchaseOrderUpload.amount'
                                  )}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  InputProps={{
                                    inputComponent: NumericFormatCustom as any,
                                  }}
                                  autoFocus
                                  disabled={isDisabled}
                                />
                                <CompanySelect
                                  label={t(
                                    'uploadRecept.purchaseOrderUpload.company'
                                  )}
                                  value={value?.company}
                                  field={`${field}.company`}
                                  setFieldValue={setFieldValue}
                                  handleBlur={handleBlur}
                                  isDisabled={isDisabled}
                                  setQuery={setQuery}
                                />
                                <AppDatePicker
                                  xs={3}
                                  value={value?.issue_date ?? ''}
                                  name={`${field}.issue_date`}
                                  label="uploadRecept.purchaseOrderUpload.issueDate"
                                  onChange={(value: any) => {
                                    if (value !== null) {
                                      setFieldValue(
                                        `${field}.issue_date`,
                                        dateFormatter(value)
                                      )
                                    } else {
                                      setFieldValue(`${field}.issue_date`, '')
                                    }
                                  }}
                                  variant="outlined"
                                  sx={datePickerStyles}
                                  disabled={isDisabled}
                                />
                              </Grid>
                              <FileUploadAndWithdrawBtn
                                edit={edit}
                                value={value}
                                index={index}
                                field={field}
                                allowedTypes={allowedTypes}
                                setFieldValue={setFieldValue}
                                disableFileDropZone={isDisabled}
                                showFileDownloadIcon={
                                  isDisabled || value?.id ? true : false
                                }
                                showWithdrawButton={isShowWithDrawButton}
                                handleWithdrawalSubmit={async () => {
                                  setIsWithdraw(true)
                                  await submitForm()
                                }}
                              />
                            </Card>
                          )
                        }
                      )}
                    </React.Fragment>
                  )}
                />
                <FocusElementOnInvalidValidation />
              </Form>
            </>
          )
        }}
      </Formik>
    </>
  )
}

export default PurchaseOrderUpload
