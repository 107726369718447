import React, { Suspense, lazy, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { Typography } from '@mui/material'

import { commonAppSelector } from '../../../CommonAppRedux/selector'
import {
  useAppDispatch,
  useAppSelector,
} from '../../../ReusableComponent/CustomHooks/appHooks'
import { getProjectListClientRequest } from '../Redux/projectListClientSlice'
import { initialRowsPerPageAndPages } from '../../../CommonAppRedux/commonAppSlice'

const ProjectListClientTable = lazy(() => import('./ProjectListClientTable'))
const ProjectListClientSearch = lazy(() => import('./ProjectListClientSearch'))

const ProjectListClient = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { page, rowsPerPage } = useAppSelector(commonAppSelector)
  useEffect(() => {
    dispatch(
      getProjectListClientRequest({
        page,
        rowsPerPage,
      })
    )
  }, [dispatch, page, rowsPerPage])

  return (
    <React.Fragment>
      <Typography variant="h6" fontWeight="bold" sx={{ padding: '16px' }}>
        {t('menuName.projectListClient')}
      </Typography>
      <Suspense fallback={<></>}>
        <ProjectListClientSearch />
      </Suspense>
      <Suspense fallback={<></>}>
        <ProjectListClientTable />
      </Suspense>
    </React.Fragment>
  )
}

export default ProjectListClient
