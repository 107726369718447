import { axiosInstance } from '../../../Utils/axios'
import { getApiProps } from '../../../Utils/globalTypes'

const completeQuotationListModURL = '/quotation-complete-list'

export const getCompleteQuotationList = (data: getApiProps) => {
  const { rowsPerPage, page } = data
  return axiosInstance.get(
    `${completeQuotationListModURL}?offset=${rowsPerPage * page}&limit=${rowsPerPage}`
  )
}
export const getCompleteQuotationListDetail = (data: getApiProps) => {
  const { rowsPerPage, page, filterData } = data
  return axiosInstance.get(
    `${completeQuotationListModURL}?offset=${rowsPerPage * page}&limit=${rowsPerPage}&${filterData?.requester ? `create_user=${filterData?.requester?.id}` : ''
    }`
  )
}
export const getCompleteQuotationListDetailById = (id: number) => {
  return axiosInstance.get(`${completeQuotationListModURL}/${id}`)
}
