import { Action } from '@reduxjs/toolkit'
import { t } from 'i18next'
import { combineEpics } from 'redux-observable'
import { Observable } from 'rxjs'
import { filter, map, switchMap } from 'rxjs/operators'

import { getNext, getPrevious } from '../../../CommonAppRedux/api'
import {
  alertErrorAction,
  alertSuccessAction,
} from '../../../CommonAppRedux/commonAppSlice'
import messages from '../../../Utils/ValidationMessage'
import { defaultPage, defaultRowsPerPage } from '../../../Utils/globalConstant'
import { dispatchAction, stateAction } from '../../../Utils/globalTypes'
import {
  getQuotationRequestList,
  getQuotationRequestListDetail,
  getQuotationRequestListDetailById,
} from './api'
import {
  loadingQuotationRequestList,
  getQuotationRequestListSuccess,
  getQuotationRequestListFail,
  getQuotationRequestListRequest,

  getQuotationRequestListDetailSuccess,
  getQuotationRequestListDetailFail,
  getQuotationRequestListDetailRequest,
  getQuotationRequestListDetailByIdRequest,
  getQuotationRequestListDetailByIdSuccess,
  getQuotationRequestListDetailByIdFail,
  getOrderDetailListNextRequest,
  getOrderDetailListPreviousRequest,
} from './quotationRequestListSlice'
import { extractErrorMessage } from '../../../Utils/AppFunction'

const getQuotationRequestListEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(getQuotationRequestListRequest.match),
    switchMap(async (action) => {
      dispatch(loadingQuotationRequestList())
      try {
        const response = await getQuotationRequestList(action.payload)
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload ? getQuotationRequestListSuccess(action?.payload) : getQuotationRequestListFail()
    )
  )
}

//get next
const getOrderDetailListNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getOrderDetailListNextRequest.match),
    switchMap(async (action) => {
      try {
        const response = await getNext(action.payload)
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? getQuotationRequestListDetailSuccess(action?.payload)
        : getQuotationRequestListDetailFail()
    )
  )
//get previous
const getOrderDetailListPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getOrderDetailListPreviousRequest.match),
    switchMap(async (action) => {
      try {
        const response = await getPrevious(action.payload)
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? getQuotationRequestListDetailSuccess(action?.payload)
        : getQuotationRequestListDetailFail()
    )
  )

  const getQuotationRequestListDetailEpic = (
    action$: Observable<Action>,
    _: stateAction,
    { dispatch }: dispatchAction
  ): Observable<Action> => {
    return action$.pipe(
      filter(getQuotationRequestListDetailRequest.match),
      switchMap(async (action) => {
        dispatch(loadingQuotationRequestList());
        try {
          const response = await getQuotationRequestListDetail(action.payload);
          return { payload: response.data };
        } catch (e) {
          dispatch(alertErrorAction(extractErrorMessage(e)));
          return { error: e };
        }
      }),
      map((action) =>
        action?.payload
          ? getQuotationRequestListDetailSuccess(action?.payload)
          : getQuotationRequestListDetailFail()
      )
    );
  };
  

const getQuotationRequestListDetailByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(getQuotationRequestListDetailByIdRequest.match),
    switchMap(async (action) => {
      dispatch(loadingQuotationRequestList())
      try {
        const response = await getQuotationRequestListDetailById(action.payload)
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? getQuotationRequestListDetailByIdSuccess(action?.payload)
        : getQuotationRequestListDetailByIdFail()
    )
  )
}

//

export const quotationRequestListEpics = combineEpics(
  getQuotationRequestListEpic,

  getQuotationRequestListDetailEpic,
  getQuotationRequestListDetailByIdEpic,

  getOrderDetailListNext,
  getOrderDetailListPrevious,
  //translator certificate
  //user made invoice
)
