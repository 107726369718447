import React, { Suspense, lazy, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { Grid, Typography } from '@mui/material'

import { t } from 'i18next'

import {
  alertInfoAction,
  clearCommonData,
  initialRowsPerPageAndPages,
} from '../../../CommonAppRedux/commonAppSlice'
import { commonUrlFilterData } from '../../../CommonAppRedux/commonAppSlice'
import { commonAppSelector } from '../../../CommonAppRedux/selector'
import { getCommonStorePreviousURLPath } from '../../../CommonLocalStorageRedux/localReduxSlice'
import {
  useAppDispatch,
  useAppSelector,
} from '../../../ReusableComponent/CustomHooks/appHooks'
import SaveFooterComponent from '../../../ReusableComponent/SaveFooterButton/SaveFooterButton'
import { updateJobTaskRequest } from '../../JobList/Redux/jobListSlice'
import {
  bulkUpdateInspectionCertificateByIdRequest,
  getInspectionTaskStateListDetailRequest,
} from '../Redux/InspectionTaskStateListSlice'
import { inspectionTaskStateListSelector } from '../Redux/selector'
// import {
//   getInspectionTaskStateListDetailRequest,
//   getInspectionTaskStateListRequest,
// } from '../Redux/projectListSlice'
import { searchOrderSubmitValuesProps } from '../Types'

//below import with lazy loading, which is typically used when you want to load modules or components asynchronously, especially when they are not immediately needed.
const InspectionTaskStateListTableList = lazy(
  () => import('./InspectionTaskStateListTableList')
)
const InspectionTaskStateListSearch = lazy(
  () => import('./InspectionTaskStateListSearch')
)
const InspectionTaskStateList = () => {
  const dispatch = useAppDispatch()

  const { rowsPerPage, page, tableCheckBoxData, collapseTableCheckBoxData } =
    useAppSelector(commonAppSelector)
  const { loadingBulkUpdateInspectionCertificate } = useAppSelector(
    inspectionTaskStateListSelector
  )

  //filter for test purpose make component soon---
  const params = useParams<{ values?: string }>()
  const decodedValues = useMemo(() => params.values || '', [params.values])
  let parsedValues: searchOrderSubmitValuesProps | null = null

  try {
    if (decodedValues) {
      parsedValues = JSON.parse(decodedValues)
    }
  } catch (error) {
    console.error('Error parsing JSON:', error)
  }

  console.log(parsedValues, 'parsedValuesparsedValuesparsedValues')
  useEffect(() => {
    dispatch(commonUrlFilterData(parsedValues))
    dispatch(
      getInspectionTaskStateListDetailRequest({
        page: page,
        rowsPerPage: rowsPerPage,
        filterData: parsedValues,
      })
    )

    return () => {
      dispatch(clearCommonData())
    }
  }, [rowsPerPage, page])
  const location = useLocation()
  useEffect(() => {
    dispatch(
      getCommonStorePreviousURLPath([
        { pathName: location?.pathname, search: location?.search },
      ])
    )
  }, [])

  // console.log(tableCheckBoxData, 'what is table data check')

  const handleBulkInspectionCertificate = () => {
    if (tableCheckBoxData?.length) {
      const updatedData = tableCheckBoxData?.map((n: any) => {
        return { id: n?.id, version: n?.version }
      })

      // console.log(updatedData,"updatedData")
      dispatch(
        bulkUpdateInspectionCertificateByIdRequest({
          values: { job_tasks: updatedData },
          page,
          rowsPerPage,
        })
      )
    } else {
      dispatch(alertInfoAction(t('customAlertMessage.pleaseSelectJobDetail')))
    }
  }

  const handleButtonAction = (action: string) => {
    const buttonConfig = buttonConfigs.find((config) => config.label === action)
    if (buttonConfig) {
      const { handler } = buttonConfig
      handler()
    }
  }

  const buttonConfigs = [
    {
      label: 'jobList.bulkInspectionCertificate',
      handler: handleBulkInspectionCertificate,
      isShowAlert: true,
      loading: loadingBulkUpdateInspectionCertificate,
      message: t('confirmMessage.sendCertificate'),
    },
  ]

  return (
    <React.Fragment>
      <Grid item sx={{ mb: 4 }}>
        <SaveFooterComponent
          handleClick={handleButtonAction}
          buttonConfigs={buttonConfigs}
          hideBackBtn={true}
        />
      </Grid>
      <Typography variant="h6" fontWeight="bold" sx={{ padding: '16px' }}>
        {t('menuName.inspectionTaskStateList')}
      </Typography>
      <Suspense fallback={<></>}>
        <InspectionTaskStateListSearch />
      </Suspense>
      <Suspense fallback={<></>}>
        <InspectionTaskStateListTableList />
      </Suspense>
    </React.Fragment>
  )
}

export default InspectionTaskStateList
