import { createSlice } from '@reduxjs/toolkit'

import { deliverableListProps } from './types'

const initialState: deliverableListProps = {
  deliverableLists: [],
  edit: false,
  deliverableList: null,
  loadingDeliverableList: false,
  loadingCreateDeliverableList: false,
  count: 0,
  next: '',
  previous: '',
}
export const DeliverableList = createSlice({
  name: 'deliverableListReducer',
  initialState,
  reducers: {
    loadingDeliverableList: (state) => {
      state.loadingDeliverableList = true
    },
    getDeliverableListRequest: (state, action) => {
      state.loadingDeliverableList = true
    },
    getDeliverableListSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingDeliverableList = false
      state.deliverableLists = results
      state.count = count
      state.next = next
      state.previous = previous
    },
    getDeliverableListFail: (state) => {
      state.loadingDeliverableList = false
    },
    getDeliverableListNextRequest: (state, action) => {
      state.loadingDeliverableList = true
    },
    getDeliverableListPreviousRequest: (state, action) => {
      state.loadingDeliverableList = true
    },
  },
})
export const {
  loadingDeliverableList,
  getDeliverableListSuccess,
  getDeliverableListFail,
  getDeliverableListRequest,
  getDeliverableListPreviousRequest,
  getDeliverableListNextRequest,
} = DeliverableList.actions
export default DeliverableList.reducer
