import { createSlice } from '@reduxjs/toolkit'

import { waitingBillListProps } from './types'

const initialState: waitingBillListProps = {
  waitingBillLists: [],
  edit: false,
  waitingBillList: null,
  loadingWaitingBillList: false,
  loadingCreateWaitingBillList: false,
  count: 0,
  next: '',
  previous: '',
  translatorCertificateTaxSubtotal: null,
  loadingTranslatorCertificateTaxSubtotal: false,
  loadingWaitingBillRowDataById:false,
  waitingBillRowData:null
}
export const WaitingBillList = createSlice({
  name: 'waitingBillListReducer',
  initialState,
  reducers: {
    loadingWaitingBillList: (state) => {
      state.loadingWaitingBillList = true
    },
    getWaitingBillListRequest: (state, action) => {
      state.loadingWaitingBillList = true
    },
    getWaitingBillListSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingWaitingBillList = false
      state.waitingBillLists = results
      state.count = count
      state.next = next
      state.previous = previous
    },
    getWaitingBillListFail: (state) => {
      state.loadingWaitingBillList = false
    },
    getWaitingBillListNextRequest: (state, action) => {
      state.loadingWaitingBillList = true
    },
    getWaitingBillListPreviousRequest: (state, action) => {
      state.loadingWaitingBillList = true
    },
    getTranslatorCertificateTaxSubtotalRequest: (state, action) => {
      state.loadingTranslatorCertificateTaxSubtotal = true
    },
    getTranslatorCertificateTaxSubtotalSuccess: (state, action) => {
      state.translatorCertificateTaxSubtotal = action?.payload?.subtotal_amount+action?.payload?.amount
      state.loadingTranslatorCertificateTaxSubtotal = false
    },
    getTranslatorCertificateTaxSubtotalFail: (state) => {
      state.translatorCertificateTaxSubtotal = null
      state.loadingTranslatorCertificateTaxSubtotal = false
    },
    getWaitingBillRowDataByIdRequest: (state, action) => {
      state.loadingWaitingBillRowDataById = true
    },
    getWaitingBillRowDataByIdSuccess: (state, action) => {
      state.waitingBillRowData = action?.payload
      state.loadingWaitingBillRowDataById = false
    },
    getWaitingBillRowDataByIdFail: (state) => {
      state.waitingBillRowData = null
      state.loadingWaitingBillRowDataById = false
    },

  },
})
export const {
  loadingWaitingBillList,
  getWaitingBillListSuccess,
  getWaitingBillListFail,
  getWaitingBillListRequest,
  getWaitingBillListPreviousRequest,
  getWaitingBillListNextRequest,
  getTranslatorCertificateTaxSubtotalRequest,
  getTranslatorCertificateTaxSubtotalSuccess,
  getTranslatorCertificateTaxSubtotalFail,

  getWaitingBillRowDataByIdRequest,
  getWaitingBillRowDataByIdSuccess,
  getWaitingBillRowDataByIdFail,
} = WaitingBillList.actions
export default WaitingBillList.reducer
