import React, { Suspense, lazy, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Grid, Typography } from '@mui/material'

import { commonAppSelector } from '../../../CommonAppRedux/selector'
import {
  useAppDispatch,
  useAppSelector,
} from '../../../ReusableComponent/CustomHooks/appHooks'
import { defaultPage, defaultRowsPerPage } from '../../../Utils/globalConstant'
import { getExportInvoiceDataListRequest } from '../Redux/exportInvoiceDataSlice'
import { commonUrlFilterData, initialRowsPerPageAndPages } from '../../../CommonAppRedux/commonAppSlice'
import { useLocation, useParams } from 'react-router-dom'
import { getCommonStorePreviousURLPath } from '../../../CommonLocalStorageRedux/localReduxSlice'
import { exportInvoiceDataListSearchProps } from './types'

const ExportInvoiceDataListTable = lazy(() => import('./ExportInvoiceDataListTable'))

const ExportInvoiceDataListSearch = lazy(() => import('./ExportInvoiceDataListSearch'))

const ExportInvoiceDataList = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { page, rowsPerPage } = useAppSelector(commonAppSelector)
  const location=useLocation();

    const params = useParams<{ values?: string }>()
    const decodedValues = useMemo(() => params.values || '', [params.values])
    let parsedValues: exportInvoiceDataListSearchProps | null = null
  
    try {
      if (decodedValues) {
        parsedValues = JSON.parse(decodedValues)
      }
    } catch (error) {
      console.error('Error parsing JSON:', error)
    }
  
    console.log(parsedValues, 'parsedValuesparsedValuesparsedValues')
  useEffect(() => {
    if(parsedValues){
      dispatch(commonUrlFilterData(parsedValues))
      dispatch(
        getExportInvoiceDataListRequest({
          page: page,
          rowsPerPage: rowsPerPage,
          filterData: parsedValues,
        })
      )
    }else{  
      dispatch(
        getExportInvoiceDataListRequest({
          page: page,
          rowsPerPage: rowsPerPage,
        })
      )
    }

  }, [dispatch, page, rowsPerPage,location])

  useEffect(() => {
    dispatch(getCommonStorePreviousURLPath([{pathName:location?.pathname,search: location?.search }]))
  }, [])

  return (
    <React.Fragment>
      <Typography variant="h6" fontWeight="bold" sx={{ padding: '16px' }}>
        {t('menuName.exportInvoiceDataList')}
      </Typography>
      <Suspense fallback={<></>}>
        <ExportInvoiceDataListSearch />
      </Suspense>
      <Suspense fallback={<></>}>
        <ExportInvoiceDataListTable />
      </Suspense>
    </React.Fragment>
  )
}

export default ExportInvoiceDataList
