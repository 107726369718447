import { axiosInstance } from '../../../Utils/axios'
import { getApiProps } from '../../../Utils/globalTypes'

const quotationStateList = '/quotation-state-list'
export const getQuotationStateList = (data: getApiProps) => {
  const { rowsPerPage, page } = data
  return axiosInstance.get(
    `${quotationStateList}?offset=${rowsPerPage * page}&limit=${rowsPerPage}`
  )
}
