import * as React from 'react'

import { Box, Grid } from '@mui/material'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'

import { ErrorMessage } from 'formik'
import { t } from 'i18next'

import { handleFocus } from '../FocusElement/focusElement'
import TextError from '../TextError/TextError'
//types
import { filterProps, freeSoloProps } from './types'

const filter = createFilterOptions<filterProps>()

const FreeSoloInput = ({
  options,
  value,
  name,
  getOptionLabel,
  onChange,
  inputRef,
  label,
  xs,
  md,
  lg,
  sm,
  required,
  focusElement,
  onBlur,
  multiple,
  onFocus,
  isNotFormik,
  autoFocus,
  inputOnchange,
  loading,
  disabled,
  filterSelectedOptions,
  renderTags,
  renderOption,
  mt,
  classes,
  handleDisabledOption,
  groupBy,
  onKeyUp,
  limitTags,
  InputProps,
  style,
  defaultFilter,
  variant,
  helperText,
}: freeSoloProps) => {
  //   const [value, setValue] = React.useState<filterProps | null>(null)

  // const handleFocusElement = (e: React.KeyboardEvent<HTMLDivElement>) => {
  //   handleFocus(focusElement, e, required && value)
  // }

  const defaultRenderOption = (props: any, option: any) => {
    return (
      <Box component="li" {...props} key={option.id ?? option?.name}>
        {getOptionLabel(option)}
      </Box>
    )
  }

  return (
    <Grid
      item
      xs={xs}
      md={md}
      lg={lg}
      sm={sm}
      mt={mt}
      className={'textfield-wrapper'}
    >
      <Autocomplete
        style={style}
        value={value}
        onChange={onChange}
        classes={classes}
        filterOptions={(options, params) => {
          const filtered = filter(options, params)
          const { inputValue } = params
          // Suggest the creation of a new value
          const isExisting = options.some(
            (option) => inputValue === option.name
          )
          if (inputValue !== '' && !isExisting) {
            filtered.push({
              name: `"${inputValue}"${t('quotation.select')}`,
              inputValue,
            })
          }
          return filtered
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        id={label}
        options={options}
        onFocus={onFocus}
        onBlur={onBlur}
        loading={loading}
        disabled={disabled}
        getOptionLabel={getOptionLabel}
        renderOption={renderOption ?? defaultRenderOption}
        // sx={{ width: 300 }}
        freeSolo
        renderInput={(params) => (
          <TextField
            {...params}
            onChange={inputOnchange}
            fullWidth
            name={name}
            variant={variant ?? 'outlined'}
            label={label && label}
            autoFocus={autoFocus ?? false}
            // inputRef={inputRef}
            helperText={helperText}
            required={required}
            disabled={disabled}
            // onKeyDown={handleFocusElement}
            onKeyUp={onKeyUp}
            InputLabelProps={{
              // style: dullLabelStyle,
              shrink: true,
            }}
          />
        )}
      />
      {!isNotFormik && <ErrorMessage name={name!} component={TextError} />}
    </Grid>
  )
}

export default FreeSoloInput
