import { memo } from 'react'

import { Grid, TextField } from '@mui/material'

import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import { ErrorMessage } from 'formik'
import { t } from 'i18next'

import { handleFocus } from '../FocusElement/focusElement'
import TextError from '../TextError/TextError'
import './datePicker.css'
import { appDatePickerProps } from './types'

const AppDatePicker = ({
  name,
  value,
  inputRef,
  onChange,
  focusElement,
  required,
  xs,
  sm,
  lg,
  md,
  mt,
  isNotFormik,
  className,
  label,
  inputFormat,
  variant,
  sx,
  disabled,
  autoFocus,
  id,
}: appDatePickerProps) => {
  //handle focus birth date
  const handleFocusElement = (e: React.KeyboardEvent<HTMLDivElement>) =>
    handleFocus(focusElement, e, required && value)
  const sxDefault = {
    width: '100%',
    bottom: variant === undefined || variant === 'standard' ? 9 : 0,
  }
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Grid
          item
          xs={xs}
          sm={sm}
          lg={lg}
          md={md}
          className={className}
          id="user-date-picker-wrapper"
          mt={mt ? 1 : ''}
        >
          <DatePicker
            format={inputFormat ?? 'YYYY年MM月DD日'}
            label={label && t(label)}
            disabled={disabled}
            value={value === '' ? null : dayjs(value)}
            onChange={onChange}
            // inputRef={inputRef}
            slotProps={{
              textField: {
                id: id ? id : name,
                autoFocus,
                required: required,
                name: name,
                value:value === '' ? null : dayjs(value),
                variant: variant ? variant : 'standard',
                onKeyDown: handleFocusElement,
                sx: sx ?? sxDefault,
              },
            }}
          />

          {!isNotFormik && <ErrorMessage name={name} component={TextError} />}
        </Grid>
      </LocalizationProvider>
    </>
  )
}

export default memo(AppDatePicker)
// sx= {{
//   width: '100%',
//   size: 'small',
//   '& .MuiInputBase-root': {
//     height: '39px',
//     display: 'flex',
//     alignItems: 'center',
//   },
//   '& .MuiInputLabel-root': {
//     textAlign: 'center',
//   },
//   '& input': {
//     textAlign: 'center',
//   },
//   // '& .MuiFormLabel-root.MuiInputLabel-root':{
//   //   textAlign: 'center',
//   // }
// }}
