import React from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, useLocation } from 'react-router-dom'

import {
  IconButton,
  Link,
  TextField,
  Typography,
  Toolbar,
  Grid,
} from '@mui/material'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import InputAdornment from '@mui/material/InputAdornment'
import Paper from '@mui/material/Paper'

import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import AlternateEmailRoundedIcon from '@mui/icons-material/AlternateEmailRounded'
import LockRoundedIcon from '@mui/icons-material/LockRounded'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'

import { width } from '@mui/system'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import AppButton from '../../ReusableComponent/AppButton/AppButton'
import {
  useAppDispatch,
  useAppSelector,
} from '../../ReusableComponent/CustomHooks/appHooks'
import { loginRequest } from '../Redux/authSlice'
import { authSelector } from '../Redux/selector'

//css

export interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

interface LoginFormValues {
  email: string
  password: string
}

//initial form values
const initialValues: LoginFormValues = {
  email: '',
  password: '',
}

const Login: React.FC = () => {
  const { loading, token } = useAppSelector(authSelector)
  const [showPassword, setShowPassword] = React.useState(false)
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const location = useLocation()

  if (token) {
    if (location.state?.from) {
      return <Navigate to={location.state.from} />
    }
    return <Navigate to="/" />
  }

  const handleClickShowPassword = () => setShowPassword((show) => !show)

  //validation for form fields
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(t('validationMessage.email'))
      .required(t('validationMessage.required')),
    password: Yup.string().required(t('validationMessage.required')),
  })

  //handle form submittion
  const onSubmit = (values: LoginFormValues) => {
    dispatch(loginRequest(values))
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="100vh"
      flexDirection="column"
    >
      <Paper elevation={20}>
        <Formik
          // enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ handleChange, handleBlur, touched, errors }) => {
            return (
              <Form>
                <AppBar position="static">
                  <Toolbar>
                    <AccountCircleIcon fontSize="large" sx={{ mr: 2 }} />
                    <Typography variant="h5">{t('auth.login')}</Typography>
                  </Toolbar>
                </AppBar>
                <Grid container p={2} spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      id="email"
                      name="email"
                      type="email"
                      autoFocus
                      label={t('auth.emailAddress')}
                      fullWidth
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.email && Boolean(errors.email)}
                      helperText={touched.email && errors.email}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AlternateEmailRoundedIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="password"
                      name="password"
                      type={showPassword ? 'text' : 'password'}
                      autoComplete="off"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      fullWidth
                      label={t('auth.password')}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LockRoundedIcon />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      error={touched.password && Boolean(errors.password)}
                      helperText={touched.password && errors.password}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <AppButton
                      size="large"
                      variant="contained"
                      fullWidth
                      submit
                      disabled={loading}
                    >
                      {t('auth.login')}
                    </AppButton>
                  </Grid>
                  <Grid item xs={12}>
                    <Link
                      href="/forgot-password"
                      underline="none"
                      variant="caption"
                    >
                      <Typography variant="caption">
                        {t('auth.forgotPassword.msg')}
                      </Typography>
                    </Link>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="caption">
                      {t('auth.dontHaveAcc')}
                      <Link
                        href="/register"
                        underline="none"
                        variant="caption"
                        color="red"
                        sx={{ ml: 1 }}
                      >
                        {t('auth.createNow')}
                      </Link>
                    </Typography>
                  </Grid>
                </Grid>
              </Form>
            )
          }}
        </Formik>
      </Paper>
      <Grid mt={10} xs={12}>
        <Typography>{t('common.info')}</Typography>
        <Typography>{t('common.address')}</Typography>
        <Typography>
          <Link href={`mailto:${t('common.mailAddress')}`}>
            {t('common.mailAddress')}
          </Link>
        </Typography>
      </Grid>
    </Box>
  )
}

export default Login
