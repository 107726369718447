import React, {
  ChangeEvent,
  memo,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react'
import { NumericFormat, NumericFormatProps } from 'react-number-format'

import {
  Button,
  Card,
  CardContent,
  Fab,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material'

import DeleteIcon from '@mui/icons-material/Delete'

import { FieldArray } from 'formik'
import { t } from 'i18next'

import {
  disabledClickFooterButtonActive,
  showAlertDialog,
} from '../../CommonAppRedux/commonAppSlice'
import { commonAppSelector } from '../../CommonAppRedux/selector'
import { authSelector } from '../../Login/Redux/selector'
import { taxAmount } from '../../Plugin/Translations/ja'
import {
  dateFormatter,
  formatCurrencyNumber,
  formatCurrencyWithSymbol,
  formateNumberAgain,
} from '../../Utils/AppFormatFunction'
import { todayDate } from '../../Utils/AppFunction'
import AlertDialog from '../AlertDialog/AlertDialog'
// import AlertDialog from '../AlertDialog/AlertDialog'
import AppAutoComplete from '../AppAutoComplete/AppAutoComplete'
import AppDatePicker from '../AppDatePicker/AppDatePicker'
import AppTextField from '../AppTextField/AppTextField'
import { useAppDispatch, useAppSelector } from '../CustomHooks/appHooks'

export interface CustomNumberFormateProps {
  onChange: (event: { target: { name: string; value: string } }) => void
  name: string
}

export const NumericFormatCustom = React.forwardRef<
  NumericFormatProps,
  CustomNumberFormateProps
>(function NumericFormatCustom(props, ref) {
  const { onChange, ...other } = props

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        // const sanitizedValue = values?.value.replace(/[^0-9]/g, '');
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      thousandSeparator
      valueIsNumericString
      prefix=""
    />
  )
})

const CommonReportTable = ({
  columns,
  handleInputChange,
  bgColor,
  taxType,
  values,
  handleChange,
  setFieldValue,
  handleBlur,
  keyName,
  initialValues,
  setQuery,
  touched,
  types,
  disabled,
  setDetailsData,
  id,
  retrieve_tax_subtotal,
  edit,
  translatorsSalesTax,
  translator_certificate,
  jobTaskDeliveryDate,
}: any) => {
  const dataValues = useMemo(() => {
    return values?.map((row: any) => {
      if (edit) {
        return {
          ...row,
          tax_amount:
            row?.tax_amount !== undefined
              ? row?.tax_amount
              : Math.round(
                  (+row?.sales_tax?.tax_rate *
                    +formateNumberAgain(+row?.quantity) *
                    +formateNumberAgain(+row?.unit_cost)) /
                    100
                ),
        }
      } else {
        let taxBase = 0
        if (row?.tax_excluded_price && row?.tax_excluded_price !== 0 ) {
          taxBase = row.tax_excluded_price 
        } else if (row?.amount && +row?.amount !== 0) {
          taxBase = +row.amount 
        }
        const taxAmount = Math.round((row?.sales_tax?.tax_rate * taxBase) / 100)
        const taxIncludedPrice = Math.round(taxBase + taxAmount)
        console.log(row?.tax_amount, 'row?.tax_amountrow?.tax_amount')
        console.log(taxAmount, 'taxAmounttaxAmounttaxAmounttaxAmount')

        return {
          ...row,
          amount: +row?.amount,
          tax_amount: row?.tax_amount !== 0 ? row?.tax_amount : taxAmount,
          tax_included_price:
            row?.tax_included_price !== 0
              ? row?.tax_included_price
              : taxIncludedPrice,
        }
      }
    })
  }, [values, edit])

  console.log(dataValues, 'dataValuesdataValuesdataValuesdataValues')

  const { footerButtonActive, showAlert, defaultSalesTax } =
    useAppSelector(commonAppSelector)
  function removeValuesAndKeepKeys(
    obj: Record<string, any>
  ): Record<string, any> {
    const result: Record<string, any> = {}
    for (const key in obj) {
      if (key === 'id') {
        continue // Skip assigning the 'id' property
      }
      if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
        result[key] = translatorsSalesTax // ToDo: set the translators Sales Tax to new Detail Row in PurchaseOrder and Certificate
          ? translatorsSalesTax
          : defaultSalesTax ?? null
      } else if (typeof obj[key] === 'number') {
        result[key] = 0
      } else if (Array.isArray(obj[key])) {
        result[key] = []
      } else if (
        // key === 'desired_delivery_date' ||
        key === 'delivery_date' ||
        key === 'inspection_date'
      ) {
        result[key] = todayDate
      } else if (key === 'desired_delivery_date') {
        result[key] = jobTaskDeliveryDate ?? todayDate
      } else if (key === 'job_no') {
        result[key] = obj?.job_no
      } else {
        result[key] = ''
      }
    }
    return result
  }
  console.log(dataValues, 'dataValuesdataValuesdataValues')
  const dynamicValuesObject = removeValuesAndKeepKeys(initialValues[0])
  const dispatch = useAppDispatch()
  const subTotal = retrieve_tax_subtotal.reduce((sum: number, item: any) => {
    // const amount = parseFloat(item.amount) || 0
    const amountSubTotal = parseFloat(item.subtotal_amount) || 0
    return sum + amountSubTotal
  }, 0)
  console.log(
    retrieve_tax_subtotal,
    'retrieve_tax_subtotalretrieve_tax_subtotalretrieve_tax_subtotal'
  )

  const subGrossTotal = Math.round(
    dataValues.reduce((sum: number, item: any) => {
      const tax_rate = item?.sales_tax?.tax_rate
      const amountTotal = parseFloat(item.amount) || 0
      const amountSubTotal = translator_certificate
        ? parseFloat(item.tax_amount) || 0
        : parseFloat(((item.amount * tax_rate) / 100).toString()) || 0
      const amount =
        item?.amount > 0
          ? amountTotal + amountSubTotal
          : parseFloat(item?.tax_included_price) || 0
      return sum + amount
    }, 0)
  )

  const subTaxExcludingTotal = dataValues?.reduce((sum: number, item: any) => {
    const amount = parseFloat(item.tax_excluded_price) || 0
    return sum + amount
  }, 0)
  const totalTaxAmount = retrieve_tax_subtotal?.reduce(
    (sum: number, item: any) => {
      const tax_amount = parseFloat(item.amount) || 0
      return sum + tax_amount
    },
    0
  )

  // console.log(groupTaxDetails,"groupTaxDetailsgroupTaxDetails")
  console.log(retrieve_tax_subtotal, 'retrieve_tax_subtotal')
  const [boolean, setBoolean] = useState(false)
  const groupedData: { [key: number]: any[] } = {}
  retrieve_tax_subtotal.forEach((item: any) => {
    const taxRate = item?.sales_tax?.tax_rate ?? 0
    if (!groupedData[taxRate]) {
      groupedData[taxRate] = []
    }

    groupedData[taxRate].push(item)
  })

  const taxSubtotalArray: any[] = Object.values(groupedData).map(
    (groupItems) => {
      const firstItem = groupItems[0]
      const taxRate = firstItem?.sales_tax?.tax_rate
      const subtotalAmount = groupItems.reduce((sum, item) => {
        const valueToAdd = !isNaN(item?.tax_excluded_price)
          ? +item?.tax_excluded_price
          : Math.round(+item?.subtotal_amount)
        if (!isNaN(valueToAdd)) {
          sum += valueToAdd
        }
        return sum
      }, 0)
      let taxAmount = 0
      if (translator_certificate) {
        taxAmount = groupItems.reduce((sum, item) => {
          console.log(item, 'itemitemitemitemitemitem')
          const valueToAdd =
            parseFloat(item?.tax_amount) ||
            Math.round(parseFloat(item?.amount)) ||
            0
          if (!isNaN(valueToAdd)) {
            sum += valueToAdd
          }

          return sum
        }, 0)
      } else {
        taxAmount = Math.round((subtotalAmount * taxRate) / 100)
      }
      return {
        id:
          firstItem.sub_total_id > 0
            ? firstItem.sub_total_id
            : firstItem?.id > 0
            ? firstItem?.id
            : undefined,
        sales_tax: {
          id: firstItem?.sales_tax?.id,
          name: firstItem?.sales_tax?.name,
          tax_rate: taxRate,
          is_default: firstItem?.sales_tax?.is_default,
        },
        subtotal_amount: +subtotalAmount,
        amount: +taxAmount,
      }
    }
  )

  useEffect(() => {
    setFieldValue('total_amount', Number(subGrossTotal))
  }, [subTaxExcludingTotal, subTotal, totalTaxAmount, subGrossTotal])
  const handleKeyDown = (event: any) => {
    // if ((event.keyCode >= 48 && event.keyCode <= 57)||event.keyCode===8||event.keyCode===46||(event.keyCode >= 96 && event.keyCode <= 105)) {
    //   setBoolean(true)
    // }
  }
  const handleCut = () => {
    setBoolean(true)
  }
  useLayoutEffect(() => {
    const groupedData: { [key: number]: any[] } = {}

    dataValues.forEach((item: any) => {
      const taxRate = item?.sales_tax?.tax_rate ?? 0
      groupedData[taxRate] = [...(groupedData[taxRate] || []), item]
    })
    const taxSubtotalArrayInside: any[] = Object.values(groupedData).map(
      (groupItems: any[]) => {
        const firstItem = groupItems[0]
        const taxRate = firstItem?.sales_tax?.tax_rate
        const subtotalAmount = groupItems.reduce((sum, item) => {
          console.log(+item?.amount, 'itemitemitemitemitemitemitem')
          console.log('itemmmmmmmmmmitessmmmmmm', item)
          const valueToAdd = !isNaN(item?.tax_excluded_price)
            ? +item?.tax_excluded_price
            : Math.round(Number(item?.amount))
          // (+item?.quantity * +item?.unit_cost)
          if (!isNaN(valueToAdd)) {
            sum += valueToAdd
          }
          return sum
        }, 0)
        let taxAmount = 0
        if (translator_certificate) {
          taxAmount = groupItems.reduce((sum, item) => {
            console.log(item, 'itemitemitemitemitemitem')
            const valueToAdd = parseFloat(item?.tax_amount) || 0
            if (!isNaN(valueToAdd)) {
              sum += valueToAdd
            }
            return sum
          }, 0)
        } else {
          taxAmount = Math.round((subtotalAmount * taxRate) / 100)
        }

        return {
          sales_tax: {
            id: firstItem?.sales_tax?.id,
            name: firstItem?.sales_tax?.name,
            tax_rate: taxRate,
            is_default: firstItem?.sales_tax?.is_default,
          },
          subtotal_amount: +subtotalAmount,
          amount: +taxAmount,
        }
      }
    )
    if (boolean || !edit) {
      setFieldValue('tax_subtotal', taxSubtotalArrayInside)
      console.log(
        taxSubtotalArrayInside,
        'taxSubtotalArrayInsidetaxSubtotalArrayInside'
      )
    }
    if (setDetailsData) {
      setDetailsData(dataValues)
    }
    return () => {
      setBoolean(false)
    }
  }, [dataValues, boolean, edit])

  const handleMainIndexPush = ({ push }: any) => {
    push(dynamicValuesObject)
    // push(initialValues[0])
  }
  const [deleteAlert, setDeleteAlert] = useState('')
  const handleMainIndexRemove = ({ remove, index }: any) => {
    setBoolean(true)
    if (!showAlert) {
      dispatch(disabledClickFooterButtonActive())
      setDeleteAlert(index)
      dispatch(showAlertDialog())
    } else {
      remove(deleteAlert)
    }
  }

  const handleCalculationChange = async (
    e: any,
    index: any,
    column: any,
    row: any
  ) => {
    // if (boolean) {

    if (
      column?.qty ||
      column?.cost ||
      column?.taxExcludedPrice ||
      column?.taxIncludedPrice ||
      column?.taxAmount ||
      column?.amount
    ) {
      const newValue = +e.target.value
      const currentValue = row[column?.id]
      console.log(
        newValue !== row[column?.id],
        'row[column?.id]row[column?.id]'
      )
      if (newValue !== currentValue) {
        setBoolean(true)
        setFieldValue(
          `${keyName}[${index}].${[column?.id]}`,
          +formateNumberAgain(e.target.value.trimEnd())
        )
        if (column?.qty) {
          setFieldValue(
            `${keyName}[${index}].amount`,
            Math.round(
              +formateNumberAgain(e.target.value.trimEnd()) *
                +formateNumberAgain(row?.unit_cost)
            )
          )
          setFieldValue(
            `${keyName}[${index}].tax_amount`,

            Math.round(
              (+row?.sales_tax?.tax_rate *
                +formateNumberAgain(e.target.value.trimEnd()) *
                +formateNumberAgain(+row?.unit_cost)) /
                100
            )
          )
        } else if (column.cost) {
          setFieldValue(
            `${keyName}[${index}].amount`,
            Math.round(
              +formateNumberAgain(e.target.value.trimEnd()) *
                +formateNumberAgain(row?.quantity)
            )
          )
          setFieldValue(
            `${keyName}[${index}].tax_amount`,

            Math.round(
              (+row?.sales_tax?.tax_rate *
                +formateNumberAgain(+row?.quantity) *
                +formateNumberAgain(e.target.value.trimEnd())) /
                100
            )
          )
        } else if (column?.taxExcludedPrice) {
          setFieldValue(
            `${keyName}[${index}].tax_amount`,
            Math.round(
              (+row?.sales_tax?.tax_rate *
                +formateNumberAgain(e.target.value.trimEnd())) /
                100
            )
          )
          setFieldValue(
            `${keyName}[${index}].tax_included_price`,
            Math.round(
              +formateNumberAgain(e.target.value.trimEnd()) +
                (+row?.sales_tax?.tax_rate *
                  +formateNumberAgain(e.target.value.trimEnd())) /
                  100
            )
          )
        } else if (column?.taxAmount) {
          setFieldValue(
            `${keyName}[${index}].tax_included_price`,
            Math.round(
              +formateNumberAgain(+row?.tax_excluded_price) +
                +formateNumberAgain(e.target.value.trimEnd())
            )
          )
        } else if (column?.taxIncludedPrice) {
          setFieldValue(
            `${keyName}[${index}].tax_included_price`,
            Math.round(+formateNumberAgain(e.target.value.trimEnd()))
          )
        }
      }
    } else {
      setFieldValue(
        `${keyName}[${index}].${[column?.id]}`,
        e.target.value.trimStart()
      )
    }
    // }
  }

  const { is_staff } = useAppSelector(authSelector)
  return (
    <Card sx={{ width: '100%', bgcolor: `${bgColor ? bgColor : ''}` }}>
      <CardContent>
        <Grid container spacing={2} sx={{ width: '100%', mr: 2, mb: 2, ml: 0 }}>
          <TableContainer
            component={Paper}
            sx={{ width: '100%', bgcolor: `${bgColor ? bgColor : ''}` }}
          >
            <Table aria-label="spanning table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ minWidth: 20 }}>
                    {t('common.number')}
                  </TableCell>
                  {columns.map((column: any, index: number) => (
                    <TableCell
                      key={index + 1}
                      sx={{
                        minWidth: column?.minWidth ? column?.minWidth : '100',
                      }}
                    >
                      {column?.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <FieldArray name={keyName}>
                {({ push, remove }) => (
                  <>
                    <TableBody>
                      {dataValues?.map((row: any, index: any) => (
                        <TableRow key={index + 1}>
                          <TableCell align="left">{index + 1}</TableCell>
                          {columns.map((column: any, colIndex: any) => (
                            <TableCell key={colIndex + 1}>
                              {!column?.actions &&
                              !column?.dropDown &&
                              !column?.dateField ? (
                                <AppTextField
                                  onCut={
                                    (column?.qty ||
                                      column?.cost ||
                                      column?.taxExcludedPrice ||
                                      column?.taxIncludedPrice ||
                                      column?.taxAmount) &&
                                    handleCut
                                  }
                                  onKeyDown={
                                    (column?.qty ||
                                      column?.cost ||
                                      column?.taxExcludedPrice ||
                                      column?.taxIncludedPrice ||
                                      column?.taxAmount) &&
                                    handleKeyDown
                                  }
                                  fastFieldNotAllowed={true}
                                  type={column?.type ? column?.type : 'text'}
                                  id={column?.label ? column?.label : 'Some'}
                                  disabled={column?.disabled || disabled}
                                  value={row[column?.id]}
                                  name={`${keyName}[${index}].${[column?.id]}`}
                                  onChange={(e: any) => {
                                    // if(e.target.value){
                                    handleCalculationChange(
                                      e,
                                      index,
                                      column,
                                      row
                                    )
                                    // }else{
                                    //   setFieldValue(
                                    //     `${keyName}[${index}].${[column?.id]}`,
                                    //    0
                                    //   );
                                    // }
                                  }}
                                  InputProps={{
                                    inputComponent: column?.currency
                                      ? (NumericFormatCustom as any)
                                      : '',
                                  }}
                                  onBlur={handleBlur}
                                  variant="outlined"
                                />
                              ) : !column?.actions &&
                                column?.dropDown &&
                                !column?.dateField ? (
                                <AppAutoComplete
                                  xs={12}
                                  options={column?.options}
                                  value={
                                    row[column?.id] ?? defaultSalesTax ?? null
                                  }
                                  label={column?.label ? column?.label : 'Some'}
                                  getOptionLabel={(option: any) => option.name}
                                  disabled={disabled}
                                  setFieldValue={setFieldValue}
                                  name={`${keyName}[${index}].${[column?.id]}`}
                                  onChange={(_: object, value: any) => {
                                    setBoolean(true)
                                    if (value !== null) {
                                      setFieldValue(
                                        `${keyName}[${index}].${[column?.id]}`,
                                        value
                                      )
                                      setFieldValue(
                                        `${keyName}[${index}].tax_amount`,
                                        Math.round(
                                          (+value?.tax_rate *
                                            +formateNumberAgain(
                                              row?.tax_excluded_price ??
                                                row?.amount
                                            )) /
                                            100
                                        )
                                      )
                                      setFieldValue(
                                        `${keyName}[${index}].tax_included_price`,
                                        Math.round(
                                          +formateNumberAgain(
                                            row?.tax_excluded_price
                                          ) +
                                            (+value?.tax_rate *
                                              +formateNumberAgain(
                                                row?.tax_excluded_price
                                              )) /
                                              100
                                        )
                                      )
                                    } else {
                                      setFieldValue(
                                        `${keyName}[${index}].${[column?.id]}`,
                                        defaultSalesTax ?? null
                                      )
                                    }
                                  }}
                                  onBlur={handleBlur}
                                />
                              ) : !column?.actions && column?.dateField ? (
                                <AppDatePicker
                                  xs={12}
                                  id={`${column?.label} `}
                                  name={`${keyName}[${index}].${[column?.id]}`}
                                  value={row[column?.id] ?? todayDate}
                                  onChange={(value: any) => {
                                    if (value !== null) {
                                      setFieldValue(
                                        `${keyName}[${index}].${[column?.id]}`,
                                        dateFormatter(value)
                                      )
                                    } else {
                                      setFieldValue(
                                        `${keyName}[${index}].${[column?.id]}`,
                                        ''
                                      )
                                    }
                                  }}
                                  variant="outlined"
                                  // mt
                                  sx={{
                                    width: '100%',
                                    size: 'small',
                                    '& .MuiInputBase-root': {
                                      height: '39px',
                                      display: 'flex',
                                      alignItems: 'center',
                                    },
                                    '& .MuiInputLabel-root': {
                                      textAlign: 'center',
                                    },
                                  }}
                                />
                              ) : (
                                !is_staff ||
                                (!disabled && (
                                  <Grid item xs={1}>
                                    <>
                                      {showAlert && !footerButtonActive && (
                                        <AlertDialog
                                          handleSubmit={handleMainIndexRemove}
                                          values={{ remove, index }}
                                          successMessageAction="customAlertMessage.deleteSuccess"
                                          message={t('confirmMessage.delete')}
                                        />
                                      )}
                                      <Tooltip
                                        title={t('common.deleteDetail')}
                                        placement="right-start"
                                      >
                                        <Fab
                                          style={{
                                            zIndex: 0,
                                          }}
                                          size="small"
                                          color="error"
                                          aria-label="delete"
                                          //@ts-ignore
                                          onClick={() => {
                                            handleMainIndexRemove({
                                              remove,
                                              index,
                                            })
                                          }}
                                        >
                                          <DeleteIcon style={{ left: '0px' }} />
                                        </Fab>
                                      </Tooltip>
                                    </>
                                  </Grid>
                                ))
                              )}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}

                      <TableRow>
                        <TableCell colSpan={columns.length - 3} />
                        <TableCell colSpan={1} align="right">
                          {t('commonFinanceTerm.subTotal')}
                        </TableCell>
                        <TableCell colSpan={2} align="right">
                          {/* {+subTaxExcludingTotal > 0 &&
                            formatCurrencyWithSymbol(
                              Number(subTaxExcludingTotal)
                            )} */}
                          {+subTotal !== 0 &&
                            formatCurrencyWithSymbol(Number(subTotal))}
                          {+subTotal === 0 && 0}

                          {/* {+subTaxExcludingTotal === 0 && +subTotal === 0 && 0} */}
                        </TableCell>
                      </TableRow>
                      {taxSubtotalArray.map((group, index) => {
                        return (
                          <TableRow key={index + 1}>
                            <TableCell colSpan={columns.length - 3} />
                            <TableCell colSpan={1} align="right">
                              {taxAmount(group?.sales_tax?.tax_rate)}
                            </TableCell>
                            <TableCell colSpan={2} align="right">
                              {formatCurrencyNumber(Number(group?.amount))}
                            </TableCell>
                          </TableRow>
                        )
                      })}
                      <TableRow>
                        <TableCell colSpan={columns.length - 3} />
                        <TableCell colSpan={1} align="right">
                          {t('common.total')}
                        </TableCell>
                        <TableCell colSpan={2} align="right">
                          {/* {+subTaxExcludingTotal > 0 &&
                            formatCurrencyWithSymbol(
                              Number(
                                subTaxExcludingTotal + Number(totalTaxAmount)
                              )
                            )} */}
                          {+subGrossTotal !== 0 &&
                            formatCurrencyWithSymbol(Number(+subGrossTotal))}
                          {+subGrossTotal === 0 && 0}

                          {/* {+subTaxExcludingTotal === 0 && +subGrossTotal === 0 && 0} */}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        {values?.length < 15 && (
                          <TableCell colSpan={columns.length + 1}>
                            <Grid item xs={12}>
                              <Tooltip
                                title={t('common.addNewDetail')}
                                placement="right-start"
                              >
                                <Button
                                  size="large"
                                  variant="contained"
                                  fullWidth
                                  onClick={() => handleMainIndexPush({ push })}
                                >
                                  +
                                </Button>
                              </Tooltip>
                            </Grid>
                          </TableCell>
                        )}
                      </TableRow>
                    </TableBody>
                  </>
                )}
              </FieldArray>
            </Table>
          </TableContainer>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default memo(CommonReportTable)
