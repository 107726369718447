import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit'

import { localStorageReduxState } from './types'


const initialState: localStorageReduxState = {
  commonPreviousURLPath: [],
}

const LocalStorageRedux = createSlice({
  name: 'localStorageReduxSlice',
  initialState,
  reducers: {
    //
    getCommonStorePreviousURLPath: (state, action: PayloadAction<any>) => {
      state.commonPreviousURLPath = action?.payload
    },
    clearCommonStorePreviousURLPath: (state) => {
      state.commonPreviousURLPath = []
    },
  },
})
export const {
  //
  getCommonStorePreviousURLPath,
  clearCommonStorePreviousURLPath,
} = LocalStorageRedux.actions
export default LocalStorageRedux.reducer
