import React from 'react'

import { Button, Grid, Stack, Typography } from '@mui/material'

import { t } from 'i18next'
import { useAppDispatch } from '../CustomHooks/appHooks'

interface confirmModal {
  setShowConfirmModal: React.Dispatch<React.SetStateAction<boolean>>
  showConfirmModal: boolean
  title?: string
}

const ConfirmModal = ({
  showConfirmModal,
  setShowConfirmModal,
  title,
}: confirmModal) => {
  const dispatch=useAppDispatch()
  const handleCancel = () => {
    setShowConfirmModal(false)
  }

  const handleOk = () => {
    // updatePurchaseOrderPlacementRequest
  }
  return (
    <>
      <Grid container justifyContent="center">
        <Grid item>
          {title && <Typography>{title}</Typography>}
          <Stack
            direction="row"
            spacing={2}
            sx={{ mt: 3 }}
            justifyContent="space-between"
          >
            <Button variant="contained" onClick={handleOk}>
              {t('common.ok')}
            </Button>
            <Button variant="contained" onClick={handleCancel}>
              {t('common.cancel')}
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </>
  )
}

export default ConfirmModal
