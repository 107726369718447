import React, {
  useState,
  Suspense,
  useEffect,
  lazy,
  useLayoutEffect,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import { Card, CardHeader, Grid, Typography } from '@mui/material'

import { Formik } from 'formik'
import * as Yup from 'yup'

import {
  handleFocusCompanyCommon,
  handleFocusCostSharingDepartmentCodeCommon,
  handleFocusExportRestrictionCommon,
  handleFocusSaleTaxCommon,
  handleFocusExpenseTypeCommon,
  onChangeInputExpenseTypeCommon,
  onChangeInputCompanyCommon,
  onChangeInputCostSharingDepartmentCodeCommon,
  onChangeInputExportRestrictionCommon,
} from '../../../../CommonAppRedux/commonAppFunctions'
import { getCommonPaymentTermsRequest } from '../../../../CommonAppRedux/commonAppSlice'
import { commonAppSelector } from '../../../../CommonAppRedux/selector'
import { commonOptionType } from '../../../../CustomAlert/types'
import { authSelector } from '../../../../Login/Redux/selector'
import AppAutoComplete from '../../../../ReusableComponent/AppAutoComplete/AppAutoComplete'
import AppDatePicker from '../../../../ReusableComponent/AppDatePicker/AppDatePicker'
import AppTextField from '../../../../ReusableComponent/AppTextField/AppTextField'
import Comments from '../../../../ReusableComponent/Comments/Comments'
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../ReusableComponent/CustomHooks/appHooks'
import { useInputSearch } from '../../../../ReusableComponent/CustomHooks/useInputSearch'
import FocusElementOnInvalidValidation from '../../../../ReusableComponent/FocusElementIfInvalid/FocusElementOnInvalidValidation'
import History from '../../../../ReusableComponent/History'
import IssueBarComponent from '../../../../ReusableComponent/IssueBarComponent/IssueBarComponent'
import ReasonForChange from '../../../../ReusableComponent/ReasonForChange/ReasonForChange'
import SaveFooterComponent from '../../../../ReusableComponent/SaveFooterButton/SaveFooterButton'
import {
  dateFormatter,
  formateNumberAgain,
} from '../../../../Utils/AppFormatFunction'
import {
  handleCancelButtonBackend,
  todayDate,
} from '../../../../Utils/AppFunction'
import messages, {
  maxCharValidation,
} from '../../../../Utils/ValidationMessage'
import {
  defaultRowsPerPage,
  selectTypePaymentTerms,
} from '../../../../Utils/globalConstant'
import { InputChangeEvent, query } from '../../../../Utils/globalTypes'
import {
  clearDataProjectDetails,
  clearHardResetProjectList,
  clearOrderReceipt,
  clearProjectDeliveryNoteApprove,
  createProjectDeliveryNoteApproveRequest,
  getOrderReceiptByIdRequest,
  getProjectDeliveryNoteApproveByIdRequest,
  getProjectDetailByIdRequest,
  updateProjectDeliveryNoteApproveRequest,
} from '../../Redux/projectListSlice'
import { projectListSelector } from '../../Redux/selector'
import { initialDeliveryNoteApproveProps } from '../../Types'

const CommonReportTable = lazy(
  () =>
    import('../../../../ReusableComponent/CommonReportTable/CommonReportTable')
)
const DeliveryReportNoteApprove = () => {
  const { t } = useTranslation()

  const [displayCardContentFirst, setDisplayCardContentFirst] =
    useState<Boolean>(true)

  function toggleFirstContent() {
    setDisplayCardContentFirst(!displayCardContentFirst)
  }

  const {
    defaultSalesTax,
    commonCompanies,
    commonSalesTax,
    commonCostSharingDepartmentCodes,
    commonExportRestrictions,
    commonExpenseTypes,
  } = useAppSelector(commonAppSelector)

  useEffect(() => {
    dispatch(getCommonPaymentTermsRequest(defaultRowsPerPage))
  }, [])
  const columns = [
    {
      id: 'item_name',
      label: `${t('deliveryReportNoteApprove.deliveryNoteDetail.itemName')}`,
      minWidth: 100,
    },
    {
      id: 'quantity',
      label: `${t('deliveryReportNoteApprove.deliveryNoteDetail.quantity')}`,
      minWidth: 50,
      qty: true,
      currency: true,
    },

    {
      id: 'unit_cost',
      label: `${t('deliveryReportNoteApprove.deliveryNoteDetail.unitCost')}`,
      minWidth: 50,
      cost: true,
      currency: true,
    },
    {
      id: 'sales_tax',
      label: `${t('deliveryReportNoteApprove.deliveryNoteDetail.salesTax')}`,
      currency: true,
      minWidth: 350,
      tax: true,
      dropDown: true,
      options: commonSalesTax,
    },
    {
      id: 'amount',
      label: `${t('deliveryReportNoteApprove.deliveryNoteDetail.amount')}`,
      minWidth: 100,
      currency: true,
      amount: true,
    },
    {
      id: 'actions',
      label: `${t('common.actions')}`,
      minWidth: 100,
      actions: true,
    },
  ]

  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const caseId = searchParams.get('case-id')
  const deliveryNoteId = searchParams.get('delivery-note-id')
  const edit = searchParams.get('edit')
  const orderList = searchParams.get('order-list')
  const {
    ProjectDeliveryNoteApprove,
    projectDetail,
    loadingCreateProjectDeliveryNoteApprove,
    loadingUpdateDeliveryWithdrawal,
    orderReceipt,
  } = useAppSelector(projectListSelector)
  const { state } = location
  const previousUrl = state?.path
  const dispatch = useAppDispatch()

  useLayoutEffect(() => {
    handleFocusSaleTaxCommon(dispatch)
    if (caseId) {
      dispatch(getProjectDetailByIdRequest(caseId))
    } else {
      dispatch(clearDataProjectDetails())
      dispatch(clearHardResetProjectList())
    }
    return () => {
      dispatch(clearHardResetProjectList())
    }
  }, [caseId, location, dispatch, edit])

  useLayoutEffect(() => {
    if (edit) {
      dispatch(getProjectDeliveryNoteApproveByIdRequest(deliveryNoteId))
    } else if (projectDetail?.case_report_relation?.order_receipt) {
      dispatch(clearProjectDeliveryNoteApprove())
      dispatch(
        getOrderReceiptByIdRequest(
          projectDetail?.case_report_relation?.order_receipt
        )
      )
    }
  }, [
    deliveryNoteId,
    projectDetail?.case_report_relation?.order_receipt,
    location,
    dispatch,
    edit,
  ])

  const updatedDeliveryNoteDetailsData =
    ProjectDeliveryNoteApprove?.delivery_note_details?.map((details: any) => {
      const matchingSubtotal =
        ProjectDeliveryNoteApprove?.delivery_note_tax_subtotal?.find(
          (subtotal: any) => {
            return subtotal?.sales_tax?.id === details?.sales_tax?.id
          }
        )
      return {
        ...details,
        sub_total_id: matchingSubtotal?.id ? matchingSubtotal?.id : undefined,
      }
    })
  const updatedOrderReceiptTaxSubTotalDetails = orderReceipt?.tax_subtotal?.map(
    (details: any) => {
      const { id, ...rest } = details
      return {
        ...rest,
      }
    }
  )

  const updatedOrderReceiptDetails = orderReceipt?.order_receipt_details?.map(
    (details: any) => {
      const { id, ...rest } = details
      return {
        ...rest,
      }
    }
  )

  const initialState: initialDeliveryNoteApproveProps = {
    version: ProjectDeliveryNoteApprove?.version,
    company: ProjectDeliveryNoteApprove
      ? ProjectDeliveryNoteApprove?.company
      : orderReceipt?.company ?? null,
    delivery_note_no: ProjectDeliveryNoteApprove?.delivery_note_no
      ? ProjectDeliveryNoteApprove?.delivery_note_no
      : '',
    detail_code: ProjectDeliveryNoteApprove?.detail_code
      ? ProjectDeliveryNoteApprove?.detail_code
      : '',
    expense_type: ProjectDeliveryNoteApprove
      ? ProjectDeliveryNoteApprove?.expense_type
      : orderReceipt?.expense_type ?? null,
    issue_date: ProjectDeliveryNoteApprove
      ? ProjectDeliveryNoteApprove?.issue_date
      : todayDate,
    cost_sharing_department_code: ProjectDeliveryNoteApprove
      ? ProjectDeliveryNoteApprove?.cost_sharing_department_code
      : orderReceipt?.cost_sharing_department_code ?? null,
    export_restriction: ProjectDeliveryNoteApprove
      ? ProjectDeliveryNoteApprove?.export_restriction
      : orderReceipt?.export_restriction ?? null,
    delivery_date: ProjectDeliveryNoteApprove
      ? ProjectDeliveryNoteApprove?.delivery_date
      : dateFormatter(new Date()),
    payment_terms: ProjectDeliveryNoteApprove
      ? ProjectDeliveryNoteApprove?.payment_terms
      : orderReceipt?.payment_terms ?? null,
    case_no: ProjectDeliveryNoteApprove
      ? ProjectDeliveryNoteApprove?.case_no
      : orderReceipt?.case_no ?? null,
    title: ProjectDeliveryNoteApprove
      ? ProjectDeliveryNoteApprove?.title
      : orderReceipt?.title ?? '',
    production_order_no: ProjectDeliveryNoteApprove
      ? ProjectDeliveryNoteApprove?.production_order_no
      : orderReceipt?.production_order_no ?? '',
    remarks: ProjectDeliveryNoteApprove
      ? ProjectDeliveryNoteApprove?.remarks
      : orderReceipt?.remarks ?? null,
    reason: '',
    message: ProjectDeliveryNoteApprove
      ? ProjectDeliveryNoteApprove?.message
      : '',
    total_amount: ProjectDeliveryNoteApprove
      ? ProjectDeliveryNoteApprove?.total_amount
      : orderReceipt?.total_amount ?? 0,
    tax_subtotal: ProjectDeliveryNoteApprove?.delivery_note_tax_subtotal ??
      updatedOrderReceiptTaxSubTotalDetails ?? [
        {
          sales_tax: null,
          subtotal_amount: 0,
          amount: 0,
        },
      ],
    delivery_note_details: ProjectDeliveryNoteApprove?.delivery_note_details
      ? updatedDeliveryNoteDetailsData
      : updatedOrderReceiptDetails ?? [
          {
            item_name: '',
            quantity: 0,
            unit_cost: 0,
            sales_tax: defaultSalesTax,
            amount: 0,
            tax_amount: 0,
          },
        ],
  }

  const validationSchema = Yup.object().shape({
    company: Yup.object().required(t(messages.required)),
    issue_date: Yup.mixed().required(t(messages.required)),
    expense_type: Yup.object().required(t(messages.required)),
    cost_sharing_department_code: Yup.object()
      .shape({
        production_order_no_required_flag: Yup.boolean().optional(),
      })
      .nullable(),
    payment_terms: Yup.mixed().required(t(messages.required)),
    title: Yup.string().max(100, maxCharValidation(100)),
    production_order_no: Yup.string()
      .max(20, maxCharValidation(20))
      .when(
        ['cost_sharing_department_code.production_order_no_required_flag'],
        (flag, schema) => {
          return flag[0]
            ? schema.required(t('validationMessage.costSharingRequired'))
            : schema
        }
      )
      .nullable(),
    delivery_note_details: Yup.array().of(
      Yup.object().shape({
        item_name: Yup.string()
          .required(t(messages.required))
          .max(100, maxCharValidation(100)),
        quantity: Yup.number().required(t(messages.required)),
        unit_cost: Yup.string().required(t(messages.required)),
        sales_tax: Yup.object().required(t(messages.required)),
        amount: Yup.number().required(t(messages.required)),
      })
    ),
  })

  const [query, setQuery] = useState<query>({
    search: '',
    url: '',
  })
  useInputSearch(query)

  const nav = useNavigate()
  const navigate = useNavigate()
  const [detailsData, setDetailsData] = useState([])
  const onSubmit = async (values: initialDeliveryNoteApproveProps) => {
    const {
      company,
      export_restriction,
      cost_sharing_department_code,
      tax_subtotal,
      expense_type,
    } = values
    const updatedTaxSubTotal = tax_subtotal?.map((row: any) => ({
      ...row,
      sales_tax: row?.sales_tax?.id,
    }))
    const updatedInvoiceDetails = detailsData?.map((details: any) => {
      const { delivery_note_no, ...rest } = details
      return {
        ...rest,
        sales_tax: details?.sales_tax?.id,
        amount: formateNumberAgain(details?.amount),
        unit_cost: formateNumberAgain(details?.unit_cost),
        tax_amount: formateNumberAgain(details.tax_amount),
      }
    })

    if (projectDetail?.case_report_relation?.delivery_note && edit) {
      const updatedValues = {
        ...values,
        export_restriction: export_restriction ? export_restriction?.id : null,
        case: Number(caseId),
        company: company ? company?.id : null,
        payment_terms_id: company?.payment_terms,
        cost_sharing_department_code: cost_sharing_department_code
          ? cost_sharing_department_code?.id
          : null,
        delivery_note_details: updatedInvoiceDetails,
        tax_subtotal: updatedTaxSubTotal,
        expense_type: expense_type?.id ?? '',
      }
      dispatch(
        updateProjectDeliveryNoteApproveRequest({
          id: projectDetail?.case_report_relation?.delivery_note,
          values: updatedValues,
          nav: nav,
          previousUrl,
          orderList: orderList,
        })
      )
    } else {
      const updatedValues = {
        ...values,
        case: Number(caseId),
        export_restriction: export_restriction ? export_restriction?.id : null,
        company: company ? company?.id : null,
        payment_terms_id: company?.payment_terms,
        cost_sharing_department_code: cost_sharing_department_code
          ? cost_sharing_department_code?.id
          : null,
        delivery_note_details: updatedInvoiceDetails,
        tax_subtotal: updatedTaxSubTotal,
        expense_type: expense_type?.id ?? '',
      }
      dispatch(
        createProjectDeliveryNoteApproveRequest({
          values: updatedValues,
          nav: nav,
          previousUrl,
          orderList,
        })
      )
    }
  }

  const handlePreview = () => {
    const url = 'delivery-note-report'
    const queryParams = `?id=${deliveryNoteId}&preview-report-url=${url}&case-id=${
      projectDetail?.id
    }&file-name=${ProjectDeliveryNoteApprove?.delivery_note_no}${
      !ProjectDeliveryNoteApprove?.withdrawal_flag ? '&active-send=true' : ''
    }`
    navigate(`/preview${queryParams}`)
  }
  const handleDeliveryWithdrawal = () => {
    const apiUrl = `delivery-note/${projectDetail?.case_report_relation?.delivery_note}/withdraw-delivery-note`
    const queryParams = `?case-id=${caseId}&delivery-note-id=${projectDetail?.case_report_relation?.delivery_note}`
    const navLink = '/delivery-report-note-approve'
    handleCancelButtonBackend(dispatch, apiUrl, queryParams, navLink, nav)
  }

  const [readOnlyMode, setReadOnlyMode] = useState(false)
  const { is_staff } = useAppSelector(authSelector)
  useEffect(() => {
    if (!is_staff || !['050'].includes(projectDetail?.status?.code)) {
      setReadOnlyMode(true)
    } else {
      setReadOnlyMode(false)
    }
    return () => {
      setReadOnlyMode(false)
    }
  }, [projectDetail])
  return (
    <div>
      <Formik
        enableReinitialize={true}
        initialValues={initialState}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          values,
          handleChange,
          setFieldValue,
          handleBlur,
          touched,
          errors,
          submitForm,
        }) => {
          console.log(values, 'valuesvaluesvaluesvalues')
          const handleSave = () => {
            submitForm()
          }

          const handleBackToCase = () => {
            const queryParams = `?id=${projectDetail?.id}&edit=${true}`
            navigate(`/create-agency-project${queryParams}`, {
              state: { path: location?.pathname + location?.search },
            })
          }
          const buttonConfigs = [
            ...(is_staff &&
            !ProjectDeliveryNoteApprove?.withdrawal_flag &&
            ['050'].includes(projectDetail?.status?.code)
              ? [
                  {
                    label: edit ? 'common.update' : 'common.save',
                    handler: handleSave,
                    isShowAlert: true,
                    loading: loadingCreateProjectDeliveryNoteApprove,
                    message: edit
                      ? t('confirmMessage.update')
                      : t('confirmMessage.save'),
                  },
                ]
              : []),
            ...((
              edit
                ? ['050', '060', '070', '080', '090'].includes(
                    projectDetail?.status?.code
                  )
                : [''].includes(projectDetail?.status?.code)
            )
              ? [
                  {
                    label: 'deliveryReportNoteApprove.preview',
                    handler: handlePreview,
                  },
                ]
              : []),
            ...(is_staff &&
            edit &&
            !['070', '080', '090'].includes(projectDetail?.status?.code) &&
            !ProjectDeliveryNoteApprove?.withdrawal_flag
              ? [
                  {
                    label: 'common.withdrawal',
                    isShowAlert: true,
                    handler: handleDeliveryWithdrawal,
                    loading: loadingUpdateDeliveryWithdrawal,
                    message: t('confirmMessage.withdrawal'),
                  },
                ]
              : []),
            {
              label: 'common.backToCase',
              handler: handleBackToCase,
            },
          ]
          const handleButtonAction = (action: string) => {
            const buttonConfig = buttonConfigs.find(
              (config) => config.label === action
            )
            if (buttonConfig) {
              const { handler } = buttonConfig
              handler()
            }
          }

          return (
            <>
              <Grid item sx={{ mb: 4 }}>
                <SaveFooterComponent
                  handleClick={handleButtonAction}
                  buttonConfigs={buttonConfigs}
                />
              </Grid>
              <div className={readOnlyMode ? 'disabled-form' : ''}>
                {edit && is_staff && (
                  <ReasonForChange
                    name="reason"
                    setFieldValue={setFieldValue}
                    onChange={handleChange}
                  />
                )}
                <IssueBarComponent
                  isIssued={ProjectDeliveryNoteApprove?.issue_flag}
                />
                <Card sx={{ mb: 2, width: '100%' }}>
                  <CardHeader
                    title={
                      <Typography
                        variant="h6"
                        sx={{ fontWeight: 'bold', color: 'white' }}
                      >
                        {t('deliveryReportNoteApprove.name')}
                      </Typography>
                    }
                    sx={{
                      backgroundColor: '#062f96',
                      height: '48px',
                    }}
                    onClick={() => {
                      console.log(toggleFirstContent())
                    }}
                  />
                  <Grid
                    container
                    display={displayCardContentFirst ? '' : 'none'}
                    sx={{
                      justifyContent: 'center',
                      alignItems: 'center',
                      transition: 'all 1s ease-out',
                    }}
                  >
                    <Typography
                      variant="h4"
                      gutterBottom
                      sx={{
                        mb: 2,
                        mt: 1,
                        textAlign: 'center',
                        fontWeight: '500',
                        padding: '1rem 1rem',
                      }}
                    >
                      {t('deliveryReportNoteApprove.name')}
                    </Typography>

                    <Grid container spacing={2} sx={{ mb: 2, ml: 1 }}>
                      <Grid item xs={6}>
                        <Grid container alignItems="center">
                          <Grid item xs={4}>
                            {t('deliveryReportNoteApprove.companyId')}:
                          </Grid>
                          <AppAutoComplete
                            xs={5}
                            disabled={readOnlyMode}
                            name="company"
                            value={values?.company}
                            options={commonCompanies}
                            getOptionLabel={(option: commonOptionType) =>
                              option.name
                            }
                            label={t('deliveryReportNoteApprove.companyId')}
                            onChange={(event: any, value: any) => {
                              if (value !== null) {
                                setFieldValue('company', value)
                                setFieldValue(
                                  'payment_terms',
                                  value?.shimadzu_report_flag
                                    ? selectTypePaymentTerms[0]?.name
                                    : selectTypePaymentTerms[1]?.name
                                )
                              } else {
                                setFieldValue('company', null)
                              }
                            }}
                            onFocus={() => handleFocusCompanyCommon(dispatch)}
                            onBlur={handleBlur}
                            inputOnchange={(e: any) =>
                              onChangeInputCompanyCommon(e.target, setQuery)
                            }
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={6}>
                        <Grid container alignItems="center">
                          <Grid item xs={4.5}>
                            {t('deliveryReportNoteApprove.deliveryNoteNo')}:
                          </Grid>
                          <AppTextField
                            disabled
                            xs={6.5}
                            name="delivery_note_no"
                            value={values?.delivery_note_no}
                            label={t(
                              'deliveryReportNoteApprove.deliveryNoteNo'
                            )}
                            placeholder={t(
                              'deliveryReportNoteApprove.deliveryNoteNo'
                            )}
                            onChange={handleChange}
                            sx={{ width: '100%' }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ mb: 2, ml: 1 }}>
                      <Grid item xs={6}>
                        <Grid container alignItems="center">
                          <Grid item xs={4}>
                            {t('projectList.columns.expenseType')}:
                          </Grid>
                          <AppAutoComplete
                            xs={5}
                            disabled={readOnlyMode}
                            options={commonExpenseTypes}
                            value={values?.expense_type ?? null}
                            getOptionLabel={(option: any) => option.name}
                            label={t('projectList.columns.expenseType')}
                            name="expense_type"
                            onChange={(_: object, value: any) => {
                              if (value !== null) {
                                setFieldValue(`expense_type`, value)
                              } else {
                                setFieldValue(`expense_type`, null)
                              }
                            }}
                            onFocus={() =>
                              handleFocusExpenseTypeCommon(dispatch)
                            }
                            onBlur={handleBlur}
                            inputOnchange={(e: any) =>
                              onChangeInputExpenseTypeCommon(e.target, setQuery)
                            }
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={6}>
                        <Grid container alignItems="center">
                          <Grid item xs={4.5}>
                            {t('commonDate.issueDate')}:
                          </Grid>
                          <AppDatePicker
                            xs={6.5}
                            disabled={readOnlyMode}
                            value={values?.issue_date ?? ''}
                            name="issue_date"
                            label="commonDate.issueDate"
                            onChange={(value: any) => {
                              if (value !== null) {
                                setFieldValue(
                                  'issue_date',
                                  dateFormatter(value)
                                )
                              } else {
                                setFieldValue('issue_date', '')
                              }
                            }}
                            variant="outlined"
                            sx={{
                              width: '100%',
                              size: 'medium',
                              '& .MuiInputBase-root': {
                                height: '39px',
                                display: 'flex',
                                alignItems: 'center',
                              },
                              '& .MuiInputLabel-root': {
                                textAlign: 'center',
                              },
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid container spacing={2} sx={{ mb: 2, ml: 1 }}>
                      <Grid item xs={6}>
                        <Grid container alignItems="center">
                          <Grid item xs={4}>
                            {t(
                              'deliveryReportNoteApprove.costSharingDepartmentCode'
                            )}
                            :
                          </Grid>
                          <AppAutoComplete
                            xs={5}
                            disabled={readOnlyMode}
                            name="cost_sharing_department_code"
                            value={values?.cost_sharing_department_code}
                            options={commonCostSharingDepartmentCodes}
                            getOptionLabel={(option: commonOptionType) =>
                              option.name
                            }
                            label={t(
                              'deliveryReportNoteApprove.costSharingDepartmentCode'
                            )}
                            onChange={(event: any, value: InputChangeEvent) => {
                              if (value !== null) {
                                setFieldValue(
                                  'cost_sharing_department_code',
                                  value
                                )
                              } else {
                                setFieldValue(
                                  'cost_sharing_department_code',
                                  null
                                )
                              }
                            }}
                            onFocus={() =>
                              handleFocusCostSharingDepartmentCodeCommon(
                                dispatch
                              )
                            }
                            onBlur={handleBlur}
                            inputOnchange={(e: any) =>
                              onChangeInputCostSharingDepartmentCodeCommon(
                                e.target,
                                setQuery
                              )
                            }
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={6}>
                        <Grid container alignItems="center">
                          <Grid item xs={4.5}>
                            {t('deliveryReportNoteApprove.exportRestriction')}:
                          </Grid>
                          <AppAutoComplete
                            disabled={readOnlyMode}
                            xs={6.5}
                            name="export_restriction"
                            value={values?.export_restriction}
                            options={commonExportRestrictions}
                            getOptionLabel={(option: commonOptionType) =>
                              option.name
                            }
                            // required
                            label={t(
                              'deliveryReportNoteApprove.exportRestriction'
                            )}
                            onChange={(event: any, value: InputChangeEvent) => {
                              if (value !== null) {
                                setFieldValue('export_restriction', value)
                              } else {
                                setFieldValue('export_restriction', null)
                              }
                            }}
                            onFocus={() =>
                              handleFocusExportRestrictionCommon(dispatch)
                            }
                            onBlur={handleBlur}
                            inputOnchange={(e: any) =>
                              onChangeInputExportRestrictionCommon(
                                e.target,
                                setQuery
                              )
                            }
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ mb: 2, ml: 1 }}>
                      <Grid item xs={6}>
                        <Grid container alignItems="center">
                          <Grid item xs={4}>
                            {t('commonDate.deliveryDate')}:
                          </Grid>
                          <AppDatePicker
                            disabled={readOnlyMode}
                            xs={5}
                            value={values?.delivery_date ?? ''}
                            name="delivery_date"
                            label="commonDate.deliveryDate"
                            onChange={(value: any) => {
                              if (value !== null) {
                                setFieldValue(
                                  'delivery_date',
                                  dateFormatter(value)
                                )
                              } else {
                                setFieldValue('delivery_date', '')
                              }
                            }}
                            variant="outlined"
                            sx={{
                              width: '100%',
                              size: 'medium',
                              '& .MuiInputBase-root': {
                                height: '39px',
                                display: 'flex',
                                alignItems: 'center',
                              },
                              '& .MuiInputLabel-root': {
                                textAlign: 'center',
                              },
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={6}>
                        <Grid container alignItems="center">
                          <Grid item xs={4.5}>
                            {t('orderReceiptIssuance.paymentTerms')}:
                          </Grid>
                          <AppTextField
                            id={t('orderReceiptIssuance.paymentTerms')}
                            xs={6.5}
                            name="payment_terms"
                            value={values?.payment_terms}
                            label={t('orderReceiptIssuance.paymentTerms')}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ mb: 2, ml: 1 }}>
                      <Grid item xs={6}>
                        <Grid container alignItems="center">
                          <Grid item xs={4}>
                            {t('deliveryReportNoteApprove.caseNo')}:
                          </Grid>
                          <AppTextField
                            xs={5}
                            disabled
                            name="case_no"
                            value={values?.case_no ?? ''}
                            label={t('deliveryReportNoteApprove.caseNo')}
                            placeholder={t('deliveryReportNoteApprove.caseNo')}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            sx={{ width: '100%' }}
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={6}>
                        <Grid container alignItems="center">
                          <Grid item xs={4.5}>
                            {t('deliveryReportNoteApprove.title')}:
                          </Grid>
                          <AppTextField
                            disabled={readOnlyMode}
                            xs={6.5}
                            name="title"
                            value={values?.title}
                            label={t('deliveryReportNoteApprove.title')}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            sx={{ width: '100%' }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ mb: 2, ml: 1 }}>
                      <Grid item xs={6}>
                        <Grid container alignItems="center">
                          <Grid item xs={4}>
                            {t('deliveryReportNoteApprove.productionOrderNo')}:
                          </Grid>
                          <AppTextField
                            disabled={readOnlyMode}
                            xs={5}
                            name="production_order_no"
                            value={values?.production_order_no ?? ''}
                            label={t(
                              'deliveryReportNoteApprove.productionOrderNo'
                            )}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            sx={{ width: '100%' }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      spacing={2}
                      sx={{ width: '100%', mb: 2, ml: 1, mr: 3 }}
                    >
                      <Grid item xs={12}>
                        <Grid container alignItems="center">
                          <Grid item xs={2}>
                            {t('common.remarks')}:
                          </Grid>
                          <AppTextField
                            disabled={readOnlyMode}
                            xs={9.7}
                            name="remarks"
                            value={values?.remarks ?? ''}
                            label={t('common.remarks')}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            rows={4}
                            multiline
                            InputProps={{
                              style: {
                                paddingLeft: '15px',
                                paddingReft: '15px',
                              },
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Suspense fallback={<></>}>
                      <CommonReportTable
                        setDetailsData={setDetailsData}
                        columns={columns}
                        taxType={`${t('commonFinanceTerm.excludingTax')})`}
                        keyName="delivery_note_details"
                        values={values?.delivery_note_details}
                        retrieve_tax_subtotal={values?.tax_subtotal}
                        handleChange={handleChange}
                        setFieldValue={setFieldValue}
                        handleBlur={handleBlur}
                        initialValues={initialState?.delivery_note_details}
                        setQuery={setQuery}
                        touched={touched}
                        disabled={readOnlyMode}
                        edit={edit}
                      />
                    </Suspense>
                  </Grid>
                </Card>
              </div>
              {edit && is_staff && (
                <History
                  history={
                    ProjectDeliveryNoteApprove?.history?.length > 0
                      ? ProjectDeliveryNoteApprove?.history
                      : []
                  }
                />
              )}
              {edit && is_staff && (
                <Comments
                  name="message"
                  setFieldValue={setFieldValue}
                  onChange={handleChange}
                  value={values?.message}
                />
              )}
              <FocusElementOnInvalidValidation />
            </>
          )
        }}
      </Formik>
    </div>
  )
}

export default DeliveryReportNoteApprove
