import React, { useEffect } from 'react'
import { Navigate, useLocation } from 'react-router-dom'

import { getCurrentUserRequest } from '../Login/Redux/authSlice'
import { authSelector } from '../Login/Redux/selector'
import {
  useAppDispatch,
  useAppSelector,
} from '../ReusableComponent/CustomHooks/appHooks'

const AuthGuard: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { pathname, search } = useLocation()
  const { token } = useAppSelector(authSelector)
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(getCurrentUserRequest())
  }, [dispatch])

  if (!token && process.env.REACT_APP_DISABLE_AUTH === 'false') {
    // redirect to login page with current path
    return (
      <Navigate to={'/login'} state={{ from: pathname + search }} replace />
    )
  }
  return <>{children}</>
}

export default AuthGuard
