import { useTranslation } from 'react-i18next'

import {
  Button,
  Card,
  CardHeader,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'

//
import { formatJPDateTime } from '../../Utils/AppFunction'

export interface HistoryProps {
  bgColor?: boolean
  history?: any[]
}

const History = ({ bgColor, history }: HistoryProps) => {
  const { t } = useTranslation()

  return (
    <Card sx={{ mb: 2, bgcolor: `${bgColor ? '#cfe7f8' : ''}` }}>
      <CardHeader
        title={
          <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'white' }}>
            {t('history.title')}
          </Typography>
        }
        sx={{
          backgroundColor: '#2e7d31',
          height: '28px',
        }}
      />
      <TableContainer
        component={Paper}
        sx={{ bgcolor: `${bgColor ? '#cfe7f8' : ''}` }}
      >
        <Table sx={{ minWidth: 700 }} aria-label="spanning table">
          <TableHead>
            <TableRow>
              <TableCell>{t('common.number')}</TableCell>
              <TableCell>{t('history.updateDate')}</TableCell>
              <TableCell>{t('history.updateUser')}</TableCell>
              <TableCell>{t('history.operation')}</TableCell>
              <TableCell>{t('history.reasonForChange')}</TableCell>
              <TableCell>{t('history.PDFBeforeChange')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {history?.map((row: any, index: number) => {
              const userName =
                (row?.processed_user_last_name
                  ? row?.processed_user_last_name
                  : '-') +
                ' ' +
                (row?.processed_user_first_name
                  ? row?.processed_user_first_name
                  : '-')
              const formattedDate = formatJPDateTime(row?.update_date)

              const handleDownload = (fileUrl: string) => {
                console.log('fileUrl', fileUrl)
                // Use anchor element with 'download' attribute to initiate download
                const link = document.createElement('a')
                link.href = fileUrl
                // get filename from fileUrl and decode it then set it as download attribute
                const filename = decodeURIComponent(
                  fileUrl.split('/').pop() || ''
                )
                link.download = filename
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
              }
              return (
                <TableRow key={row.id}>
                  <TableCell> {index + 1} </TableCell>
                  <TableCell> {formattedDate}</TableCell>
                  <TableCell>{userName}</TableCell>
                  <TableCell>
                    {row?.report_operation_name
                      ? row?.report_operation_name
                      : '-'}
                  </TableCell>
                  <TableCell>{row?.reason ? row?.reason : '-'} </TableCell>
                  <TableCell>
                    {row.file ? (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleDownload(row.file)}
                      >
                        {t('common.download')}
                      </Button>
                    ) : (
                      '-'
                    )}
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  )
}

export default History
