import { Navigate, redirect, type RouteObject } from 'react-router-dom'

import Layout from '../components/Layout'
import ForgotPassword from '../components/Login/ForgetPasswordPage'
//login routes
import Login from '../components/Login/LoginPage'
import Register from '../components/Login/RegisterPage'
import ResetPassword from '../components/Login/ResetPasswordPage'
import WorkFlow from '../components/ReusableComponent/Workflow/TestScreen'
import BillableList from '../components/pages/BillableList/BillableListPages'
import BilledList from '../components/pages/BilledList/BilledListPages'
import CompleteDeliveryList from '../components/pages/CompleteDeliveryList/CompleteDeliveryListPages'
import CompleteQuotationList from '../components/pages/CompleteQuotationList/CompleteQuotationListPages'
import DeliverableList from '../components/pages/DeliverableList/DeliverableListPages'
import ExportInvoiceDataList from '../components/pages/ExportInvoiceDataList/ExportInvoiceDataListPages'
import InvoiceHq from '../components/pages/ExportInvoiceDataList/InvoiceHqPages/InvoiceHq'
import InspectionTaskStateList from '../components/pages/InspectionTaskStateList/InspectionTaskStateListPages'
import CompleteInspectionList from '../components/pages/JobList/CompleteInspectionList'
import InputTrackRecord from '../components/pages/JobList/InputTrackRecordScreen/InputTrackRecord'
//screen not needed
import IssueInvoiceForm from '../components/pages/JobList/IssueInvoicePages/IssueInvoice'
import UsermadeInvoiceUpload from '../components/pages/JobList/IssueInvoicePages/UsermadeInvoiceUpload'
import CreateJob from '../components/pages/JobList/JobCreatePages/CreateJob'
import TranslatorMatching from '../components/pages/JobList/JobListDetails/TranslatorMatching'
import JobList from '../components/pages/JobList/JobListPages'
import JobProgressList from '../components/pages/JobList/JobProgressListPages'
import TaskList from '../components/pages/JobList/JobTaskDetails'
import CreateTaskList from '../components/pages/JobList/JobTaskDetails/CreateTask'
import IssueCertificate from '../components/pages/JobList/JobTaskDetails/InspectionCertificate/IssueInspectionCertificate'
import IssuePurchaseOrder from '../components/pages/JobList/JobTaskDetails/IssuePurchaseOrder/IssuePurchaseOrder'
import PurchaseAfterAmountChange from '../components/pages/JobList/JobTaskDetails/PurchaseAfterAmountChange/PurchaseAfterAmountChange'
import OrderList from '../components/pages/OrderList/OrderListPages'
// screen not needed
import PreviewIndex from '../components/pages/Preview'
import CreateAgencyProjectList from '../components/pages/ProjectList/CreateProjectListPages/CreateAgencyProject'
import CertificateComplete from '../components/pages/ProjectList/IssueReportPages/DeliveryReport/CertificateComplete'
import DeliveryReportNoteApprove from '../components/pages/ProjectList/IssueReportPages/DeliveryReport/DeliveryReportNoteApprove'
import IssueOrderReceipt from '../components/pages/ProjectList/IssueReportPages/IssueOrderReceipt'
import IssueQuotation from '../components/pages/ProjectList/IssueReportPages/IssueQuotation'
import ProjectIssueInvoice from '../components/pages/ProjectList/ProjectIssueInvoicePages/ProjectIssueInvoice'
import ProjectList from '../components/pages/ProjectList/ProjectListPages'
import DeliveryItemUpload from '../components/pages/ProjectList/UploadReceptLayout/DeliveryItemUpload'
import InspectionCertificateUpload from '../components/pages/ProjectList/UploadReceptLayout/InspectionCertificateUpload'
import OrderRecept from '../components/pages/ProjectList/UploadReceptLayout/OrderReceptUpload'
import PurchaseOrderUpload from '../components/pages/ProjectList/UploadReceptLayout/PurchaseOrderUpload'
import WorkFlowHistory from '../components/pages/ProjectList/WorkFlowHistory/WorkFlowHistory'
import ProjectListClient from '../components/pages/ProjectListClient/ProjectListPages'
import ProjectListNavigate from '../components/pages/ProjectListNavigate'
import QuotationRequestList from '../components/pages/QuotationRequestList/QuotationRequestListPages'
import QuotationStateList from '../components/pages/QuotationStateList/QuotationStateListPages'
import TaskStateList from '../components/pages/TaskStateList/TaskStateListPages'
// import InvoiceUpload from '../components/pages/UploadReceptLayout/InovoiceUpload'
import TranslatorInvoiceUpload from '../components/pages/WaitingBillList/TranslatorInvoiceUpload'
import WaitingBillList from '../components/pages/WaitingBillList/WaitingBillListPages'

const routeConfig: RouteObject[] = [
  {
    path: '/',
    element: <Layout />,
    children: [
      { index: true, element: <ProjectListNavigate /> },
      { path: '/project-list/:values?', element: <ProjectList /> },
      {
        path: '/project-list-client/:values?',
        element: <ProjectListClient />,
      }, // New path for job-list client
      {
        path: '/quotation-request-list',
        element: <QuotationRequestList />,
      },
      {
        path: '/complete-quotation-list',
        element: <CompleteQuotationList />,
      },
      {
        path: '/order-list',
        element: <OrderList />,
      },
      {
        path: '/export-invoice-data-list/:values?',
        element: <ExportInvoiceDataList />,
      },
      {
        path: '/complete-delivery-list',
        element: <CompleteDeliveryList />,
      },
      {
        path: '/complete-inspection-list',
        element: <CompleteInspectionList />,
      },
      { path: '/job-list/:values?', element: <JobList /> },
      { path: '/task-state-list/:values?', element: <TaskStateList /> },
      { path: '/job-progress-list', element: <JobProgressList /> },
      { path: '/billed-list', element: <BilledList /> },
      {
        path: '/inspection-task-state-list/:values?',
        element: <InspectionTaskStateList />,
      },
      { path: '/task-list', element: <TaskList /> }, // New path for task-list
      {
        path: '/task',
        element: <CreateTaskList />,
      }, // New path for task-list
      { path: '/project-list', element: <ProjectList /> },
      {
        path: '/waiting-bill-list',
        element: <WaitingBillList />,
      },
      {
        path: '/quotation-state-list',
        element: <QuotationStateList />,
      },
      {
        path: '/delivery-able-list',
        element: <DeliverableList />,
      },
      {
        path: '/bill-able-list',
        element: <BillableList />,
      },
      {
        path: '/create-agency-project',
        element: <CreateAgencyProjectList />,
      }, // New path for project-list create

      { path: '/job-list/:values?', element: <JobList /> },
      { path: '/job', element: <CreateJob /> },
      {
        path: '/delivery-report-note-approve',
        element: <DeliveryReportNoteApprove />,
      },
      //--------------------------------------report screen
      // { path: '/issue-expense-notice', element: <IssueExpenseNotice /> },
      { path: '/issue-quotation', element: <IssueQuotation /> },
      {
        path: '/issue-invoice',
        element: <IssueInvoiceForm />,
      },
      //
      {
        path: '/case-issue-invoice',
        element: <ProjectIssueInvoice />,
      },
      //
      {
        path: '/issue-order-receipt',
        element: <IssueOrderReceipt />,
      },
      {
        path: '/issue-purchase',
        element: <IssuePurchaseOrder />,
      },
      {
        path: '/issue-certificate',
        element: <IssueCertificate />,
      },
      // { path: '/issue-payment-schedule', element: <IssuePaymentSchedule /> },
      // {
      //   path: '/issue-invoice-data',
      //   element: <IssueInvoiceData />,
      // },
      {
        // path: '/purchase-after-amount-change/:id?/:jobTaskId',
        path: '/purchase-after-amount-change',
        element: <PurchaseAfterAmountChange />,
      },
      {
        path: '/certificate-complete-confirm',
        element: <CertificateComplete />,
      },
      //------------------------------------------ upload screen
      {
        // path: '/order-receipt-upload/:id?/:edit?/:caseId?',
        path: '/order-receipt-upload',
        element: <OrderRecept />,
      },
      {
        path: '/delivery-item-upload',
        element: <DeliveryItemUpload />,
      },
      {
        path: '/purchase-order-upload',
        element: <PurchaseOrderUpload />,
      },
      // { path: '/invoice-upload', element: <InvoiceUpload /> },
      {
        path: '/certificate-upload',
        element: <InspectionCertificateUpload />,
      },
      {
        path: '/usermade-invoice-upload',
        element: <UsermadeInvoiceUpload />,
      },
      {
        path: '/invoicehq',
        element: <InvoiceHq />,
      },
      {
        path: '/translator-invoice-upload',
        element: <TranslatorInvoiceUpload />,
      },
      //PreviewIndex
      {
        path: '/preview',
        element: <PreviewIndex />,
      },

      // translator matching screen UI
      { path: '/translator-matching', element: <TranslatorMatching /> },
      { path: '/input-track-record', element: <InputTrackRecord /> },
      { path: '/workflow-test', element: <WorkFlow /> },
      // case workflow history screen
      { path: '/case-workflow-history', element: <WorkFlowHistory /> },
    ],
  },
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/forgot-password',
    element: <ForgotPassword />,
  },
  {
    path: '/reset-password/:uid/:token',
    element: <ResetPassword />,
  },
  {
    path: '/register',
    element: <Register />,
  },
]

export default routeConfig
