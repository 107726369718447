import { useTranslation } from 'react-i18next'

import { Grid, Typography } from '@mui/material'

import InfoIcon from '@mui/icons-material/Info'

import './noData.css'

export interface NoDataProps {
  hidePreferences?: boolean
}

const NoData = ({ hidePreferences }: NoDataProps) => {
  const { t } = useTranslation()
  return (
    <>
      <Typography
        variant="body1"
        color="initial"
        className="no-data-container"
        sx={{ height: '240px' }}
      >
        <InfoIcon />
        <br />
        {t('common.noDataDisplay')}
      </Typography>
    </>
  )
}
export default NoData
