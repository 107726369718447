import { t } from 'i18next'

export const defaultRowsPerPage = 100
export const defaultPage = 0
export const globalSearchPostPerPage = 35
export const selectReportForJobListTypes = [
  {
    id: 1,
    name: 'Invoice Report',
    translateName: 'globalConstant.invoiceReport',
    invoice: true,
    // queryName: 'invoice_translator_id',
    keyName: 'invoice_exists',
  },
  {
    id: 2,
    name: 'Purchase Order Report',
    translateName: 'globalConstant.purchaseOrderReport',
    purchaseOrder: true,
    queryName: 'purchase_order_translator_id',
    keyName: 'purchase_order_exists',
  },
  {
    id: 3,
    name: 'Certificate Report',
    translateName: 'globalConstant.certificateReport',
    certificate: true,
    queryName: 'certificate_translator_id',
    keyName: 'certificate_exists',
  },
]
export const selectTypePaymentTerms = [
  {
    id: 1,
    name: '理財経由で費用振替',
  },
  {
    id: 2,
    name: '銀行振り込み(振込手数料はご負担ください）',
  },
]
export const selectReportForProjectListTypes = [
  {
    id: 1,
    name: 'Quotation Report',
    translateName: 'globalConstant.quotationReport',
    quotation: true,
    queryName: 'quotation_company_id',
    keyName: 'quotation_exists',
  },
  {
    id: 2,
    name: 'Purchase Order Report',
    translateName: 'globalConstant.purchaseOrderReport',
    purchaseOrder: true,
    queryName: 'purchase_order_company_id',
    keyName: 'purchase_exists',
  },
  {
    id: 3,
    name: 'Order Receipt Report',
    translateName: 'globalConstant.orderReceiptReport',
    orderReceipt: true,
    queryName: 'order_receipt_company_id',
    keyName: 'order_receipt_exists',
  },
  {
    id: 4,
    name: 'Delivery Note Report',
    translateName: 'globalConstant.deliveryNoteReport',
    deliveryNote: true,
    queryName: 'delivery_note_company_id',
    keyName: 'delivery_note_exists',
  },
  {
    id: 5,
    name: 'Certificate Report',
    translateName: 'globalConstant.certificateReport',
    certificate: true,
    queryName: 'certificate_company_id',
    keyName: 'certificate_exists',
  },
  {
    id: 6,
    name: 'Invoice Report',
    translateName: 'globalConstant.invoiceReport',
    invoice: true,
    queryName: 'invoice_company_id',
    keyName: 'invoice_exists',
  },
]
export const saveStatus = [
  {
    model: 'int_auth_common.status',
    pk: 1,
    fields: {
      code: '000',
      name: '-',
      own_display_name: '-',
      client_display_name: 'Before requesting a quote',
      create_date: '2023-08-06',
      update_date: '2023-08-06',
    },
  },
  {
    model: 'int_auth_common.status',
    pk: 2,
    fields: {
      code: '010',
      name: 'Quotation in progress',
      own_display_name: 'Quotation in progress',
      client_display_name: 'Requesting a quote',
      create_date: '2023-08-06',
      update_date: '2023-08-06',
    },
  },
  {
    model: 'int_auth_common.status',
    pk: 3,
    fields: {
      code: '020',
      name: 'Quotation already sent',
      own_display_name: 'Quotation already sent',
      client_display_name: 'Confirming quotation',
      create_date: '2023-08-06',
      update_date: '2023-08-06',
    },
  },
  {
    model: 'int_auth_common.status',
    pk: 4,
    fields: {
      code: '030',
      name: 'Quotation received by client',
      own_display_name: 'Quotation received by client',
      client_display_name: 'Quotation received',
      create_date: '2023-08-06',
      update_date: '2023-08-06',
    },
  },
  {
    model: 'int_auth_common.status',
    pk: 5,
    fields: {
      code: '040',
      name: 'Cancel request',
      own_display_name: 'Cancel request',
      client_display_name: 'Cancel request',
      create_date: '2023-08-06',
      update_date: '2023-08-06',
    },
  },
  {
    model: 'int_auth_common.status',
    pk: 6,
    fields: {
      code: '050',
      name: 'Orders received',
      own_display_name: 'Orders received',
      client_display_name: 'Orders placed',
      create_date: '2023-08-06',
      update_date: '2023-08-06',
    },
  },
  {
    model: 'int_auth_common.status',
    pk: 7,
    fields: {
      code: '051',
      name: 'pending purchase order',
      own_display_name: 'pending purchase order',
      client_display_name: 'Orders placed',
      create_date: '2023-08-06',
      update_date: '2023-08-06',
    },
  },
  {
    model: 'int_auth_common.status',
    pk: 8,
    fields: {
      code: '052',
      name: 'Purchase Ordered',
      own_display_name: 'Purchase Ordered',
      client_display_name: 'Orders placed',
      create_date: '2023-08-06',
      update_date: '2023-08-06',
    },
  },
  {
    model: 'int_auth_common.status',
    pk: 9,
    fields: {
      code: '053',
      name: 'Purchased and delivered',
      own_display_name: 'Purchased and delivered',
      client_display_name: 'Orders placed',
      create_date: '2023-08-06',
      update_date: '2023-08-06',
    },
  },
  {
    model: 'int_auth_common.status',
    pk: 10,
    fields: {
      code: '054',
      name: 'Purchased and inspected',
      own_display_name: 'Purchased and inspected',
      client_display_name: 'Orders placed',
      create_date: '2023-08-06',
      update_date: '2023-08-06',
    },
  },
  {
    model: 'int_auth_common.status',
    pk: 11,
    fields: {
      code: '055',
      name: 'Purchases invoiced',
      own_display_name: 'Purchases invoiced',
      client_display_name: 'Orders placed',
      create_date: '2023-08-06',
      update_date: '2023-08-06',
    },
  },
  {
    model: 'int_auth_common.status',
    pk: 12,
    fields: {
      code: '060',
      name: 'already delivered',
      own_display_name: 'already delivered',
      client_display_name: 'already delivered',
      create_date: '2023-08-06',
      update_date: '2023-08-06',
    },
  },
  {
    model: 'int_auth_common.status',
    pk: 13,
    fields: {
      code: '070',
      name: 'inspected',
      own_display_name: 'inspected',
      client_display_name: 'inspected',
      create_date: '2023-08-06',
      update_date: '2023-08-06',
    },
  },
  {
    model: 'int_auth_common.status',
    pk: 14,
    fields: {
      code: '080',
      name: 'billed',
      own_display_name: 'billed',
      client_display_name: 'billed',
      create_date: '2023-08-06',
      update_date: '2023-08-06',
    },
  },
  {
    model: 'int_auth_common.status',
    pk: 15,
    fields: {
      code: '090',
      name: 'completion',
      own_display_name: 'completion',
      client_display_name: 'completion',
      create_date: '2023-08-06',
      update_date: '2023-08-06',
    },
  },
]
