import { Action } from '@reduxjs/toolkit'
import { t } from 'i18next'
import { combineEpics } from 'redux-observable'
import { Observable } from 'rxjs'
import { filter, map, switchMap } from 'rxjs/operators'

import { getNext, getPrevious } from '../../../CommonAppRedux/api'
import {
  alertErrorAction,
  alertSuccessAction,
} from '../../../CommonAppRedux/commonAppSlice'
import messages from '../../../Utils/ValidationMessage'
import { defaultPage, defaultRowsPerPage } from '../../../Utils/globalConstant'
import { dispatchAction, stateAction } from '../../../Utils/globalTypes'
import {
  getCompleteDeliveryList,
  updateCompleteDeliveryInspectionFlag,
} from './api'
import {
  getCompleteDeliverySuccess,
  getCompleteDeliveryFail,
  getCompleteDeliveryRequest,
  getCompleteDeliveryNextRequest,
  getCompleteDeliveryPreviousRequest,
} from './completeDeliveryListSlice'
import { extractErrorMessage } from '../../../Utils/AppFunction'

const getCompleteDeliveryEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(getCompleteDeliveryRequest.match),
    switchMap(async (action) => {
      try {
        const response = await getCompleteDeliveryList(action.payload)
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? getCompleteDeliverySuccess(action?.payload)
        : getCompleteDeliveryFail()
    )
  )
}

//get next
const getCompleteDeliveryListNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getCompleteDeliveryNextRequest.match),
    switchMap(async (action) => {
      try {
        const response = await getNext(action.payload)
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? getCompleteDeliverySuccess(action?.payload)
        : getCompleteDeliveryFail()
    )
  )
//get previous
const getCompleteDeliveryListPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getCompleteDeliveryPreviousRequest.match),
    switchMap(async (action) => {
      try {
        const response = await getPrevious(action.payload)
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? getCompleteDeliverySuccess(action?.payload)
        : getCompleteDeliveryFail()
    )
  )

export const completeDeliveryEpics = combineEpics(
  getCompleteDeliveryEpic,
  getCompleteDeliveryListNext,
  getCompleteDeliveryListPrevious
)
