import React from 'react'

import AuthGuard from './AuthGuard'
import Frame from './Frame'

const Layout: React.FC = () => {
  return (
    <AuthGuard>
      <Frame />
    </AuthGuard>
  )
}

export default Layout
