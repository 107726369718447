import { generateQueryString } from '../../../Utils/AppFunction'
import { axiosInstance } from '../../../Utils/axios'
import { getApiProps } from '../../../Utils/globalTypes'

const inspectionTaskStateListModURL = '/inspection-task-state-list'
const inspectionBulkListModURL = '/bulk-inspection-certificate'

// export const getInspectionTaskStateList = (data: getApiProps) => {
//   const { rowsPerPage, page } = data
//   return axiosInstance.get(
//     `${inspectionTaskStateListModURL}?offset=${rowsPerPage * page}&limit=${rowsPerPage}`
//   )
// }

export const getInspectionTaskStateListDetail = (data: getApiProps) => {
  const { rowsPerPage, page, filterData } = data
  // return axiosInstance.get(
  //   `${inspectionTaskStateListModURL}?offset=${
  //     rowsPerPage * page
  //   }&limit=${rowsPerPage}&${
  //     filterData?.requester ? `create_user=${filterData?.requester?.id}` : ''
  //   }`
  // )
  return axiosInstance.get(
    `${inspectionTaskStateListModURL}?offset=${rowsPerPage * page}&limit=${rowsPerPage}${filterData?`&${generateQueryString(filterData)??""}`:""}`
  )
}

// export const getInspectionTaskStateListDetailById = (id: number) => {
//   return axiosInstance.get(`${inspectionTaskStateListModURL}/${id}`)
// }

export const bulkInspectionCertificateByIdEpic = (body: string | FormData) => {
  // return axiosInstance.patch(`${inspectionBulkListModURL}/${body}`)
  return axiosInstance.patch(`${inspectionBulkListModURL}`, body)
}
