import React, { Suspense, lazy, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { Grid, Typography } from '@mui/material'

import { t } from 'i18next'

import {
  alertErrorAction,
  alertInfoAction,
  clearCommonData,
  commonUrlFilterData,
  initialRowsPerPageAndPages,
} from '../../../CommonAppRedux/commonAppSlice'
import { commonAppSelector } from '../../../CommonAppRedux/selector'
import { getCommonStorePreviousURLPath } from '../../../CommonLocalStorageRedux/localReduxSlice'
import ConfirmModal from '../../../ReusableComponent/ConfirmModal/ConfirmModal'
import {
  useAppDispatch,
  useAppSelector,
} from '../../../ReusableComponent/CustomHooks/appHooks'
import Modal from '../../../ReusableComponent/Modal'
import SaveFooterComponent from '../../../ReusableComponent/SaveFooterButton/SaveFooterButton'
import { defaultPage, defaultRowsPerPage } from '../../../Utils/globalConstant'
import {
  downloadJobListSalesExportRequest,
  getJobListDetailRequest,
  getJobListRequest,
} from '../Redux/jobListSlice'
import { searchSubmitValuesProps } from '../Types'

//below import with lazy loading, which is typically used when you want to load modules or components asynchronously, especially when they are not immediately needed.
const JobListTable = lazy(() => import('./JobListTableList'))
const JobListSearch = lazy(() => import('./JobListSearch'))
const JobList = () => {
  const dispatch = useAppDispatch()

  const { rowsPerPage, page, tableCheckBoxData } =
    useAppSelector(commonAppSelector)
  const uniqueCheck = tableCheckBoxData.find(
    (row: any) => row?.invoice?.invoice !== null
  )
  const uniqueCheckFilter = tableCheckBoxData.filter(
    (row: any) => row?.invoice?.invoice !== null
  )
  const location = useLocation()
  const idString = uniqueCheckFilter.map((row) => row.detail_no).join(',')
  // const handleInvoice = () => {
  //   if (uniqueCheck) {
  //     dispatch(
  //       alertErrorAction(
  //         `Job Detail No.${idString} Already Have Invoice So Make Edit`
  //       )
  //     )
  //   } else {
  //     if (tableCheckBoxData?.length > 0) {
  //       const queryParams = ``
  //       navigate(`/issue-invoice${queryParams}`, {
  //         state: { path: location?.pathname + location?.search },
  //       })
  //     } else {
  //       dispatch(alertInfoAction(t('customAlertMessage.pleaseSelectJobDetail')))
  //     }
  //   }
  // }

  const [showBulkInspectionCertificate, setShowBulkInspectionCertificate] =
    useState(false)
  const params = useParams<{ values?: string }>()
  const decodedValues = useMemo(() => params.values || '', [params.values])
  let parsedValues: searchSubmitValuesProps | null = null

  try {
    if (decodedValues) {
      parsedValues = JSON.parse(decodedValues)
    }
  } catch (error) {
    console.error('Error parsing JSON:', error)
  }

  console.log(parsedValues, 'parsedValuesparsedValuesparsedValues')
  const handleStockingExport = () => {
    // dispatch(commonUrlFilterData(parsedValues))
    dispatch(
      downloadJobListSalesExportRequest({
        page: page,
        rowsPerPage: rowsPerPage,
        filterData: parsedValues,
      })
    )
  }
  const buttonConfigs = [
    // {
    //   label: 'common.issueInvoice',
    //   handler: handleInvoice,
    // },
    {
      label: 'common.stockingExport',
      handler: handleStockingExport,
      isShowAlert: true,
      message: t('jobList.stockingExportMessage'),
    },
  ]

  const handleButtonAction = (action: string) => {
    const buttonConfig = buttonConfigs.find((config) => config.label === action)
    if (buttonConfig) {
      const { handler } = buttonConfig
      handler()
    }
  }
  const navigate = useNavigate()

  let bulkInspectionCertificateModalProps: any = useMemo(() => {
    return {
      title: t('common.confirm'),
      maxWidth: 'sm',
      showModal: showBulkInspectionCertificate,
      setShowModal: setShowBulkInspectionCertificate,
    }
  }, [showBulkInspectionCertificate])

  useEffect(() => {
    dispatch(commonUrlFilterData(parsedValues))
    dispatch(
      getJobListDetailRequest({
        page: page,
        rowsPerPage: rowsPerPage,
        filterData: parsedValues,
      })
    )
  }, [rowsPerPage, page])
  useEffect(() => {
    dispatch(
      getCommonStorePreviousURLPath([
        { pathName: location?.pathname, search: location?.search },
      ])
    )
  }, [])
  return (
    <React.Fragment>
      <Grid item sx={{ mb: 4 }}>
        <SaveFooterComponent
          handleClick={handleButtonAction}
          buttonConfigs={buttonConfigs}
          hideBackBtn={true}
        />
      </Grid>
      <Typography variant="h6" fontWeight="bold" sx={{ padding: '16px' }}>
        {t('menuName.jobList')}
      </Typography>
      <Suspense fallback={<></>}>
        <JobListSearch />
      </Suspense>
      <Suspense fallback={<></>}>
        <JobListTable />
      </Suspense>
      {showBulkInspectionCertificate && (
        <Suspense fallback={<></>}>
          <Modal {...bulkInspectionCertificateModalProps}>
            <ConfirmModal
              setShowConfirmModal={setShowBulkInspectionCertificate}
              showConfirmModal={showBulkInspectionCertificate}
              title={t('jobList.bulkInspectionCertificateModalTitle')}
            />
          </Modal>
        </Suspense>
      )}
    </React.Fragment>
  )
}

export default JobList
