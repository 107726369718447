import { Action } from '@reduxjs/toolkit'
import { t } from 'i18next'
import { combineEpics } from 'redux-observable'
import { Observable, from, of } from 'rxjs'
import { catchError, filter, map, switchMap } from 'rxjs/operators'

import { alertErrorAction } from '../../../CommonAppRedux/commonAppSlice'
import { dispatchAction, stateAction } from '../../../Utils/globalTypes'
import { getUsers, getWorkflowById, updateWorkflowById } from './api'
import {
  getWorkflowRequest,
  getWorkflowSuccess,
  getWorkflowFail,
  updateWorkflowRequest,
  updateWorkflowSuccess,
  updateWorkflowFail,
  getUsersRequest,
  getUsersSuccess,
  getUsersFail,
} from './workflowSlice'
import { extractErrorMessage } from '../../../Utils/AppFunction'

const getWorkflowEpic = (
  action$: Observable<Action>,
  _: stateAction
): Observable<Action> =>
  action$.pipe(
    filter(getWorkflowRequest.match),
    switchMap((action) =>
      from(getWorkflowById(action.payload)).pipe(
        map((response) => getWorkflowSuccess(response.data)),
        catchError((e) =>
          of(
            getWorkflowFail(),
            alertErrorAction(extractErrorMessage(e))
          )
        )
      )
    )
  )

const updateWorkflowEpic = (
  action$: Observable<Action>,
  _: stateAction
): Observable<Action> =>
  action$.pipe(
    filter(updateWorkflowRequest.match),
    switchMap(({ payload: { id, body, action } }) =>
      from(updateWorkflowById(id, body, action)).pipe(
        map((response) => updateWorkflowSuccess(response.data)),
        catchError((e) =>
          of(
            updateWorkflowFail(),
            alertErrorAction(extractErrorMessage(e))
          )
        )
      )
    )
  )

const getUsersEpic = (
  action$: Observable<Action>,
  _: stateAction
): Observable<Action> =>
  action$.pipe(
    filter(getUsersRequest.match),
    switchMap(({ payload }) =>
      from(getUsers(payload)).pipe(
        map(({ data: { results } }) => getUsersSuccess(results)),
        catchError(() => of(getUsersFail()))
      )
    )
  )

export const workflowEpics = combineEpics(
  getWorkflowEpic,
  updateWorkflowEpic,
  getUsersEpic
)
