import React, { useLayoutEffect } from 'react'

import {
  Card,
  CardContent,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'

import { FieldArray } from 'formik'
import { t } from 'i18next'

import { getCommonSaleTaxRequest } from '../../../CommonAppRedux/commonAppSlice'
import { commonAppSelector } from '../../../CommonAppRedux/selector'
import AppTextField from '../../../ReusableComponent/AppTextField/AppTextField'
import { NumericFormatCustom } from '../../../ReusableComponent/CommonReportTable/CommonReportTable'
import {
  useAppDispatch,
  useAppSelector,
} from '../../../ReusableComponent/CustomHooks/appHooks'
import { formatCurrencyWithSymbol } from '../../../Utils/AppFormatFunction'
import { defaultRowsPerPage } from '../../../Utils/globalConstant'

const AutoCalculateTable = ({
  columns,
  handleBlur,
  keyName,
  dataValues,
}: any) => {
  const { amount, tax_amount } = (dataValues || []).reduce(
    (acc: any, obj: any) => ({
      amount: acc.amount + obj.amount,
      tax_amount: acc.tax_amount + obj.tax_amount,
    }),
    { amount: 0, tax_amount: 0 }
  )
  const totalAmountSum = amount
  const totalTaxAmountSum = tax_amount
  const totalSum = totalAmountSum + totalTaxAmountSum
  const dispatch = useAppDispatch()
  const { defaultSalesTax } = useAppSelector(commonAppSelector)
  useLayoutEffect(() => {
    dispatch(getCommonSaleTaxRequest(defaultRowsPerPage))
  }, [dispatch])
  return (
    <div>
      <Card sx={{ width: '100%' }}>
        <CardContent>
          <Grid
            container
            spacing={2}
            sx={{ width: '100%', mr: 2, mb: 2, ml: 0 }}
          >
            <TableContainer component={Paper} sx={{ width: '100%' }}>
              <Table aria-label="spanning table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ minWidth: 20 }}>
                      {t('common.number')}
                    </TableCell>
                    {columns.map((column: any, index: number) => (
                      <TableCell
                        key={index + 1}
                        sx={{
                          minWidth: column?.minWidth ? column?.minWidth : '100',
                        }}
                      >
                        {t(column?.label)}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <FieldArray name={keyName}>
                  {({ push, remove }) => (
                    <>
                      <TableBody>
                        {dataValues.map((row: any, index: any) => (
                          <TableRow key={index + 1}>
                            <TableCell align="left">{index + 1}</TableCell>
                            {columns.map((column: any, colIndex: any) => (
                              <TableCell key={colIndex + 1}>
                                <AppTextField
                                  type={'text'}
                                  id="standard-basic"
                                  disabled
                                  value={row[column?.id]}
                                  name={`${keyName}[${index}].${[column?.id]}`}
                                  InputProps={{
                                    inputComponent: column?.currency
                                      ? (NumericFormatCustom as any)
                                      : '',
                                  }}
                                  onBlur={handleBlur}
                                  variant="outlined"
                                />
                              </TableCell>
                            ))}
                          </TableRow>
                        ))}
                        <TableRow>
                          <TableCell colSpan={columns.length - 2} />
                          <TableCell colSpan={1} align="right">
                            {t('commonFinanceTerm.subTotal')}
                          </TableCell>
                          <TableCell colSpan={2} align="right">
                            {formatCurrencyWithSymbol(Number(totalAmountSum))}
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell colSpan={columns.length - 2} />
                          <TableCell colSpan={1} align="right">
                            {defaultSalesTax?.name}
                          </TableCell>
                          <TableCell colSpan={2} align="right">
                            {formatCurrencyWithSymbol(
                              Number(totalTaxAmountSum)
                            )}
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell colSpan={columns.length - 2} />
                          <TableCell colSpan={1} align="right">
                            {t('common.total')}
                          </TableCell>
                          <TableCell colSpan={2} align="right">
                            {formatCurrencyWithSymbol(Number(totalSum))}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </>
                  )}
                </FieldArray>
              </Table>
            </TableContainer>
          </Grid>
        </CardContent>
      </Card>
    </div>
  )
}

export default AutoCalculateTable
