import { initReactI18next } from 'react-i18next'

import i18n from 'i18next'

import translations from './translations'

i18n.use(initReactI18next).init({
  resources: translations,
  lng: process.env.REACT_APP_LOCALE, // Set the initial language here
  fallbackLng: 'en', // Fallback language if translation is not available for the selected language
  interpolation: {
    escapeValue: false, // React already escapes the values
  },
})

export default i18n
