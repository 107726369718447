import React, {
  useState,
  ChangeEvent,
  DragEvent,
  useLayoutEffect,
  memo,
} from 'react'
import { useTranslation } from 'react-i18next'

import { Fab, Grid, Input, Tooltip } from '@mui/material'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'

import ClearIcon from '@mui/icons-material/Clear'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import LaunchIcon from '@mui/icons-material/Launch'
import LinkIcon from '@mui/icons-material/Link'

import { ErrorMessage, useFormikContext } from 'formik'
import { t } from 'i18next'

import {
  disabledClickFooterButtonActive,
  showAlertDialog,
} from '../../CommonAppRedux/commonAppSlice'
import { commonAppSelector } from '../../CommonAppRedux/selector'
import {
  downloadFile,
  extractFilenameFromURL,
  getContentTypeFromUrl2,
} from '../../Utils/AppFunction'
import messages from '../../Utils/ValidationMessage'
import { AppDropZoneProps } from '../../Utils/globalTypes'
import { updateArtifactRequest } from '../../pages/ProjectList/Redux/projectListSlice'
import AlertDialog from '../AlertDialog/AlertDialog'
import AlertDialogLocal from '../AlertDialog/AlertDialogLocal'
import { useAppDispatch, useAppSelector } from '../CustomHooks/appHooks'
import TextError from '../TextError/TextError'

export const FileInput: React.FC<
  AppDropZoneProps & { onUpload: (file: File) => void }
> = ({
  allowedTypes,
  maxFileSize,
  onUpload,
  value,
  setFieldValue,
  isNotFormik,
  dispatchDeliveryItem,
  artifactId,
  artifact_version,
  caseIdFromCase,
  nav,
  isStaff = true,
  disabled,
  uploadScreenInPlaceOfReport,
  showDownloadIconOnly,
  multiple,
  index,
  field,
  previewBtn,
}) => {
  const { t } = useTranslation()
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [openDialog, setOpenDialog] = React.useState(false)
  const [error, setError] = useState<string | null>(null)
  const { showAlert, footerButtonActive } = useAppSelector(commonAppSelector)
  useLayoutEffect(() => {
    const fetchData = async () => {
      if (
        value?.file &&
        typeof value?.file === 'string' &&
        (value?.file.startsWith('http://') ||
          value?.file.startsWith('https://'))
      ) {
        try {
          const response = await fetch(value?.file)
          if (!response.ok) {
            throw new Error(`HTTP Error: ${response.status}`)
          }
          const blob = await response.blob()
          const fileNameWithExtension = extractFilenameFromURL(value?.file)
          const fileNameWithoutExtension = fileNameWithExtension
            .split('.')
            .slice(0, -1)
            .join('.')
          const extension = fileNameWithExtension.split('.').pop()
          const fileNameWithBlobExtension = `${fileNameWithoutExtension}.${extension}`
          const contentType = await getContentTypeFromUrl2(value?.file)
          const file = new File([blob], fileNameWithBlobExtension, {
            type: contentType,
          })
          setFieldValue(!multiple ? 'file' : `${field}.file`, file)
          setSelectedFile(file)
        } catch (error) {
          // console.error('Error fetching image:', error)
          setSelectedFile(null)
        }
      } else {
        // setSelectedFile(artifact?.file);
      }
    }
    fetchData()
  }, [value?.file, value, multiple, field, setFieldValue])

  //
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file) {
      if (validateFileType(file) && validateFileSize(file)) {
        setSelectedFile(file)
        setError(null)
        onUpload(file)
      } else {
        setSelectedFile(null)
        setError(getErrorMessage(file))
      }
    } else {
      setSelectedFile(null)
      setError(null)
    }
  }

  const handleDragOver = (event: DragEvent<HTMLLabelElement>) => {
    event.preventDefault()
  }

  const handleDrop = (event: DragEvent<HTMLLabelElement>) => {
    event.preventDefault()
    const file = event.dataTransfer.files[0]
    if (file) {
      if (validateFileType(file) && validateFileSize(file)) {
        setSelectedFile(file)
        setError(null)
        onUpload(file)
      } else {
        setSelectedFile(null)
        setError(getErrorMessage(file))
      }
    } else {
      setSelectedFile(null)
      setError(null)
    }
  }

  const handleClearFile = () => {
    setFieldValue('file', '')
    // setFieldValue(!multiple ? 'file' : `${fieldName}[${index}].file`, null)
    setSelectedFile(null)
    setError(null)
    if (dispatchDeliveryItem && artifactId) {
      dispatch(
        updateArtifactRequest({
          id: artifactId,
          values: {
            file: '',
            case: value?.case,
            version: artifact_version,
          },
          nav: nav,
        })
      )
    }
  }

  const handleFileDelete = () => {
    setOpenDialog(false)
    setFieldValue(`${field}.file`, '')
    setSelectedFile(null)
    setError(null)
  }
  const openDialogFileDeleteAlert = () => {
    setOpenDialog(true)
  }

  // const validateFileType = (file: File) => {
  //   const fileType = file.type
  //   return allowedTypes.includes(fileType)
  // }
  const validateFileType = (file: File) => {
    const fileType = file.type
    if (!allowedTypes.includes('')) {
      return allowedTypes.includes(fileType)
    } else {
      return true
    }
  }

  const validateFileSize = (file: File) => {
    return file.size <= maxFileSize
  }

  const getErrorMessage = (file: File) => {
    const allowedSize = maxFileSize / (1024 * 1024)
    let errorMessage = `${t('appDropZoneCommon.invalidFileTypeOrSize')}`
    if (!validateFileType(file)) {
      const allowedTypesString = allowedTypes.join(', ')
      errorMessage += `${t(
        'appDropZoneCommon.fileTypeShouldBe'
      )} ${allowedTypesString}.`
    }

    if (!validateFileSize(file)) {
      errorMessage += ` ${t(
        'appDropZoneCommon.fileSizeShouldBeUpTo'
      )}  ${allowedSize}MB.`
    }

    return errorMessage
  }
  console.log(selectedFile, 'selectedFileselectedFileselectedFile')
  const renderPreview = () => {
    if (!selectedFile) return null

    const fileType = selectedFile.type
    if (fileType.startsWith('image/')) {
      return (
        <img
          src={URL.createObjectURL(selectedFile)}
          alt="File preview"
          style={{ maxWidth: '100%', maxHeight: '100%' }}
        />
      )
    } else if (fileType === 'application/pdf') {
      return (
        <embed
          src={URL.createObjectURL(selectedFile)}
          type="application/pdf"
          width="100%"
          height="100%"
        />
      )
    } else {
      return <span>{selectedFile.name}</span>
    }
  }

  const obj = allowedTypes.reduce((types: any, item: any) => {
    types[item] = item
    return types
  }, {})

  const dispatch = useAppDispatch()

  //
  // future used
  // const [progress, setProgress] = React.useState(0);

  // React.useEffect(() => {
  //   const timer = setInterval(() => {
  //     setProgress((oldProgress) => {
  //       if (oldProgress === 100) {
  //         clearInterval(timer); // Stop the timer when progress reaches 100
  //         return 100;
  //       }
  //       const diff = Math.random() * 10;
  //       return Math.min(oldProgress + diff, 100);
  //     });
  //   }, 200);
  // if(!selectedFile){
  //   setProgress(0);
  // }
  //   return () => {
  //     clearInterval(timer);
  //   };
  // }, [selectedFile]);
  return (
    <Box
      sx={{
        width: '80%',
        margin: '0 auto',
        textAlign: 'center',
        borderRadius: '8px',
        padding: '16px',
        height: previewBtn ? '70vh' : '200px',
        position: 'relative',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.3)', // Shadow effect
      }}
    >
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {renderPreview()}
        {!selectedFile && !error && (
          <>
            <input
              type="file"
              name={!multiple ? `file` : `${field}.file`}
              placeholder={t('common.urlOrFile')}
              id={!multiple ? `file-input` : `file-input[${index}]`}
              disabled={
                caseIdFromCase ||
                uploadScreenInPlaceOfReport ||
                showDownloadIconOnly
              }
              style={{
                display: 'none',
              }}
              accept={Object.keys(obj).join(',') ?? ''}
              onChange={handleFileChange}
            />
            <label
              htmlFor={!multiple ? `file-input` : `file-input[${index}]`}
              onDragOver={handleDragOver}
              onDrop={disabled ? () => {} : handleDrop}
              style={{
                cursor: 'pointer',
                width: '100%',
                height: '100%',
                border: '2px dashed #aaa',
                borderRadius: '8px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <CloudUploadIcon
                sx={{
                  fontSize: '48px',
                  marginBottom: '16px',
                }}
              />
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                {t('appDropZoneCommon.title')}
              </Typography>
            </label>
          </>
        )}
        {error && (
          <Box
            sx={{
              mt: 2,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography variant="body1" color="error" sx={{ mb: 1 }}>
              {error}
            </Typography>

            <IconButton
              color="secondary"
              onClick={() => showAlert && dispatch(showAlertDialog())}
            >
              {showAlert && !footerButtonActive && (
                <AlertDialog
                  handleSubmit={handleClearFile}
                  successMessageAction="customAlertMessage.deleteSuccess"
                  message={t('confirmMessage.delete')}
                />
              )}
              <ClearIcon />
            </IconButton>
          </Box>
        )}
        {/* {selectedFile&&(
        <Box sx={{ width: '100%', height: '10px' }}>
          <LinearProgress variant="determinate" value={progress} />
        </Box>
        )} */}
        {selectedFile && !error && (
          <>
            {showAlert && !footerButtonActive && (
              <AlertDialog
                handleSubmit={handleClearFile}
                successMessageAction="customAlertMessage.deleteSuccess"
                message={t('confirmMessage.delete')}
              />
            )}

            <AlertDialogLocal
              openDialog={openDialog}
              handleSubmit={handleFileDelete}
              setOpenDialog={setOpenDialog}
              successMessageAction="customAlertMessage.deleteSuccess"
              message={t('confirmMessage.delete')}
            />

            <Grid
              sx={{
                position: 'absolute',
                top: '5px',
                right: '35px',
              }}
            >
              {caseIdFromCase ||
              uploadScreenInPlaceOfReport ||
              showDownloadIconOnly ? (
                <>
                  <Tooltip
                    title={t('common.downloadAttachment')}
                    placement="right-end"
                    PopperProps={{
                      style: { zIndex: 0 },
                    }}
                    sx={{
                      top: '5px',
                      right: '5px',
                    }}
                  >
                    <div style={{ position: 'relative' }}>
                      <Fab
                        style={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          zIndex: 0,
                        }}
                        size="small"
                        color="success"
                        aria-label="download"
                        onClick={() => downloadFile(selectedFile)}
                        //@ts-ignore
                      >
                        <FileDownloadIcon />
                      </Fab>
                    </div>
                  </Tooltip>
                </>
              ) : (
                isStaff && (
                  <Tooltip
                    title={t('common.deleteAttachment')}
                    placement="right-end"
                    PopperProps={{
                      style: { zIndex: 0 },
                    }}
                    sx={{
                      top: '5px',
                      right: '5px',
                    }}
                  >
                    <div style={{ position: 'relative' }}>
                      <Fab
                        style={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          zIndex: 0,
                        }}
                        size="small"
                        color="error"
                        aria-label="delete"
                        onClick={() => {
                          if (multiple) {
                            openDialogFileDeleteAlert()
                          } else {
                            !showAlert &&
                              dispatch(showAlertDialog()) &&
                              dispatch(disabledClickFooterButtonActive())
                          }
                        }}
                        disabled={disabled}
                        //@ts-ignore
                      >
                        <ClearIcon />
                      </Fab>
                    </div>
                  </Tooltip>
                )
              )}
            </Grid>
          </>
        )}
      </Box>
      {
        <ErrorMessage
          name={!multiple ? `file` : `${field}.file`}
          component={TextError}
        />
      }
    </Box>
  )
}

const AppDropZone: React.FC<
  AppDropZoneProps & { onUpload: (file: File) => void }
> = ({
  allowedTypes,
  maxFileSize,
  onUpload,
  value,
  setFieldValue,
  isNotFormik,
  dispatchDeliveryItem,
  artifactId,
  artifact_version,
  caseIdFromCase,
  nav,
  isStaff,
  uploadScreenInPlaceOfReport,
  disabled = false,
  multiple,
  index,
  showDownloadIconOnly,
  field,
  previewBtn,
}) => {
  const formik = useFormikContext<any>()
  function redirectUrl(url: any) {
    if (url) {
      // Open the URL in a new tab
      window.open(url, '_blank')
    }
  }
  return (
    <>
      <Box
        component="div"
        sx={{
          '& > :not(style)': { m: 1 },
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: '30px',
        }}
        // noValidate
        // autoComplete="off"
      >
        <FileInput
          allowedTypes={allowedTypes}
          maxFileSize={maxFileSize}
          onUpload={onUpload}
          value={value}
          setFieldValue={setFieldValue}
          isNotFormik={isNotFormik}
          dispatchDeliveryItem={dispatchDeliveryItem}
          artifactId={artifactId}
          artifact_version={artifact_version}
          caseIdFromCase={caseIdFromCase}
          nav={nav}
          isStaff={isStaff}
          disabled={disabled}
          uploadScreenInPlaceOfReport={uploadScreenInPlaceOfReport}
          showDownloadIconOnly={showDownloadIconOnly}
          multiple={multiple}
          index={index}
          field={field}
          previewBtn={previewBtn}
        />
      </Box>
      <Grid
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          mb: 4,
        }}
      >
        <Typography>{t('appDropZoneCommon.size')}</Typography>
      </Grid>
      <Grid
        container
        item
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Grid xs={0.5} item sx={{ mt: 3 }}>
          <LinkIcon />
        </Grid>
        {/* <Tooltip
        title={(value?.url)}
        placement="bottom-start"
        > */}
        <Grid item xs={6} sx={{ mb: 0 }}>
          <Input
            id={
              formik?.errors?.url === t(messages.invalidUrl)
                ? t('common.url')
                : t('common.urlOrFile')
            }
            placeholder={t('common.uploadUrlLinkHere')}
            value={value?.url}
            name={!multiple ? `url` : `${field}.url`}
            disabled={caseIdFromCase || disabled}
            onChange={(e: any) => {
              if (e.target.value !== '') {
                setFieldValue(
                  !multiple ? `url` : `${field}.url`,
                  e.target.value.trimStart()
                )

                // setFieldValue(`file`,"");
              } else {
                setFieldValue(!multiple ? `url` : `${field}.url`, '')

                // setFieldValue(`file`,null);
              }
            }}
            // disableUnderline
            sx={{
              width: '100%',
              fontSize: '14px',
              color: 'black',
              marginTop: '15px',
            }}
          />
          {!isNotFormik && (
            <ErrorMessage
              name={!multiple ? `url` : `${field}.url`}
              component={TextError}
            />
          )}
        </Grid>
        {/* </Tooltip> */}
        <Grid
          item
          style={{
            paddingTop: 0,
            zIndex: 0,
          }}
          sx={{ mt: 2, ml: 1 }}
          xs={0.5}
        >
          {value?.url !== '' && !formik?.errors?.url && (
            <Grid>
              <Tooltip title={t('common.openURLLink')} placement="right-start">
                <Fab
                  style={{
                    left: '0px',
                    bottom: '5px',
                  }}
                  size="small"
                  color="warning"
                  aria-label="redirect "
                  //@ts-ignore
                  onClick={() => redirectUrl(value?.url)}
                >
                  <LaunchIcon
                    style={{
                      left: '0px',
                    }}
                  />
                </Fab>
              </Tooltip>
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  )
}

export default memo(AppDropZone)
