import React, { Suspense, lazy, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Grid, Typography } from '@mui/material'

import { commonAppSelector } from '../../../CommonAppRedux/selector'
import {
  useAppDispatch,
  useAppSelector,
} from '../../../ReusableComponent/CustomHooks/appHooks'
import { defaultPage, defaultRowsPerPage } from '../../../Utils/globalConstant'
import { getBilledListRequest } from '../Redux/billedSlice'
import { useLocation } from 'react-router-dom'
import { getCommonStorePreviousURLPath } from '../../../CommonLocalStorageRedux/localReduxSlice'

const BilledListTable = lazy(() => import('./BilledListTable'))

const BilledList = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { page, rowsPerPage } = useAppSelector(commonAppSelector)
  useEffect(() => {
    dispatch(
      getBilledListRequest({
        page,
        rowsPerPage,
      })
    )
  }, [dispatch, page, rowsPerPage])
  const location=useLocation();
  useEffect(() => {
    dispatch(getCommonStorePreviousURLPath([{pathName:location?.pathname,search: location?.search }]))
  }, [])

  return (
    <React.Fragment>
      <Typography variant="h6" fontWeight="bold" sx={{ padding: '16px' }}>
        {t('menuName.billedList')}
      </Typography>
      <Suspense fallback={<></>}>
        <BilledListTable />
      </Suspense>
    </React.Fragment>
  )
}

export default BilledList
