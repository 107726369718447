import { memo } from 'react'

import { t } from 'i18next'

import { alertSuccessAction } from '../../CommonAppRedux/commonAppSlice'
import { useAppDispatch } from '../CustomHooks/appHooks'
import DialogBody from './DialogBody'
import { AlertDialogLocalProps } from './types'

const AlertDialogLocal = ({
  handleSubmit,
  message,
  successMessageAction,
  maxWidth,
  openDialog,
  setOpenDialog,
}: AlertDialogLocalProps) => {
  const dispatch = useAppDispatch()
  const handleClickOpen = () => {
    handleSubmit()
    setOpenDialog(false)
    successMessageAction &&
      dispatch(alertSuccessAction(t(successMessageAction)))
  }
  const handleClose = () => {
    setOpenDialog(false)
  }
  return (
    <>
      <DialogBody
        openDialog={openDialog}
        handleClose={handleClose}
        message={message}
        handleClickOpen={handleClickOpen}
        activeOnlyMessage={false}
      />
    </>
  )
}
export default memo(AlertDialogLocal)
