import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  AppBar,
  Box,
  Button,
  Grid,
  Paper,
  TextField,
  Typography,
  Toolbar,
  InputAdornment,
} from '@mui/material'

import AlternateEmailRoundedIcon from '@mui/icons-material/AlternateEmailRounded'
import LockResetIcon from '@mui/icons-material/LockReset'

import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import {
  useAppDispatch,
  useAppSelector,
} from '../../ReusableComponent/CustomHooks/appHooks'
import { forgetPasswordRequest } from '../Redux/authSlice'
import { authSelector } from '../Redux/selector'
import { ForgetPasswordPayload } from '../Redux/types'

const ForgetPassword: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { loading } = useAppSelector(authSelector)

  //initial form value
  const initialValues: ForgetPasswordPayload = {
    email: '',
  }

  //validation for form fields
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(t('validationMessage.email'))
      .required(t('validationMessage.required')),
  })

  const handleSubmit = (values: ForgetPasswordPayload) => {
    dispatch(forgetPasswordRequest(values))
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="100vh"
    >
      <Paper elevation={20}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ handleChange, handleBlur, touched, errors }) => {
            return (
              <Form autoComplete="off">
                <AppBar position="static">
                  <Toolbar>
                    <LockResetIcon fontSize="large" sx={{ mr: 1 }} />
                    <Typography variant="h5">
                      {t('auth.forgotPassword.msg')}
                    </Typography>
                  </Toolbar>
                </AppBar>
                <Grid container p={2} spacing={2}>
                  <Grid item xs={12}>
                    <Typography>{t('auth.forgotPassword.steps')}</Typography>
                    <ol>
                      <li>{t('auth.forgotPassword.step1')}</li>
                      <li>{t('auth.forgotPassword.step2')}</li>
                      <li>{t('auth.forgotPassword.step3')}</li>
                    </ol>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="outlined-adornment-email"
                      name="email"
                      type="email"
                      autoFocus
                      fullWidth
                      label={t('auth.emailAddress')}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.email && Boolean(errors.email)}
                      helperText={touched.email && errors.email}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AlternateEmailRoundedIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      size="large"
                      variant="contained"
                      href="/login"
                      // startIcon={<ChevronLeftIcon />}
                    >
                      {t('auth.return')}
                    </Button>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sx={{ display: 'flex', flexDirection: 'row-reverse' }}
                  >
                    <Button
                      size="large"
                      variant="contained"
                      type="submit"
                      // endIcon={<SendIcon />}
                      disabled={loading}
                    >
                      {t('auth.send')}
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )
          }}
        </Formik>
      </Paper>
    </Box>
  )
}

export default ForgetPassword
