import { memo } from 'react'

import { t } from 'i18next'

import {
  alertSuccessAction,
  closeAlertDialog,
} from '../../CommonAppRedux/commonAppSlice'
import { commonAppSelector } from '../../CommonAppRedux/selector'
import { useAppDispatch, useAppSelector } from '../CustomHooks/appHooks'
import DialogBody from './DialogBody'
import { alertDialog } from './types'

const AlertDialog = ({
  handleSubmit,
  values,
  types,
  setSubmitLoading,
  setShowModal,
  message,
  successMessageAction,
  maxWidth,
}: alertDialog) => {
  const { showAlert, activeOnlyMessage } = useAppSelector(commonAppSelector)
  const dispatch = useAppDispatch()
  const handleClickOpen = () => {
    if (handleSubmit && values) {
      handleSubmit(values)
    } else if (handleSubmit) {
      handleSubmit()
    }

    dispatch(closeAlertDialog())
    successMessageAction &&
      dispatch(alertSuccessAction(t(successMessageAction)))
  }
  const handleClose = () => {
    dispatch(closeAlertDialog())
  }
  return (
    <>
      <DialogBody
        openDialog={showAlert}
        handleClose={handleClose}
        maxWidth={maxWidth}
        message={message}
        handleClickOpen={handleClickOpen}
        activeOnlyMessage={activeOnlyMessage}
      />
    </>
  )
}
export default memo(AlertDialog)
