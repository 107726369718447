import { generateQueryString } from '../../../Utils/AppFunction'
import { axiosInstance } from '../../../Utils/axios'
import { getApiProps } from '../../../Utils/globalTypes'

const projectListClient = '/project-list-for-client'

export const getProjectListClient = (data: getApiProps) => {
  const { rowsPerPage, page, filterData } = data
  return axiosInstance.get(
    `${projectListClient}?offset=${rowsPerPage * page}&limit=${rowsPerPage}${
      filterData ? `&${generateQueryString(filterData)}` : ''
    }`
  )
}
