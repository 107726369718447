import { Action } from '@reduxjs/toolkit'
import { t } from 'i18next'
import { combineEpics } from 'redux-observable'
import { Observable } from 'rxjs'
import { filter, map, switchMap } from 'rxjs/operators'

import { getNext, getPrevious } from '../../../CommonAppRedux/api'
import { alertErrorAction } from '../../../CommonAppRedux/commonAppSlice'
import messages from '../../../Utils/ValidationMessage'
import { dispatchAction, stateAction } from '../../../Utils/globalTypes'
import { getDeliverableList } from './api'
import {
  loadingDeliverableList,
  getDeliverableListSuccess,
  getDeliverableListFail,
  getDeliverableListRequest,
  getDeliverableListNextRequest,
  getDeliverableListPreviousRequest,
} from './deliverableSlice'
import { extractErrorMessage } from '../../../Utils/AppFunction'

const getDeliverableListEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(getDeliverableListRequest.match),
    switchMap(async (action) => {
      dispatch(loadingDeliverableList())
      try {
        const response = await getDeliverableList(action.payload)
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? getDeliverableListSuccess(action?.payload)
        : getDeliverableListFail()
    )
  )
}

//get next
const getDeliverableListNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getDeliverableListNextRequest.match),
    switchMap(async (action) => {
      try {
        const response = await getNext(action.payload)
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? getDeliverableListSuccess(action?.payload)
        : getDeliverableListFail()
    )
  )
//get previous
const getDeliverableListPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getDeliverableListPreviousRequest.match),
    switchMap(async (action) => {
      try {
        const response = await getPrevious(action.payload)
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)))
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? getDeliverableListSuccess(action?.payload)
        : getDeliverableListFail()
    )
  )

export const deliverableListEpics = combineEpics(
  getDeliverableListEpic,
  getDeliverableListNext,
  getDeliverableListPrevious
)
