import { axiosInstance } from '../../../Utils/axios'
import { getApiProps } from '../../../Utils/globalTypes'

const completeDelivery = '/complete-delivery-list'
export const getCompleteDeliveryList = (data: getApiProps) => {
  const { rowsPerPage, page } = data
  return axiosInstance.get(
    `${completeDelivery}?offset=${rowsPerPage * page}&limit=${rowsPerPage}`
  )
}

export const updateCompleteDeliveryInspectionFlag = (
  body: string | FormData
) => {
  return axiosInstance.patch(`${completeDelivery}`, body)
}
