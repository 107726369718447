import { axiosInstance } from '../../../Utils/axios'
import { getApiProps } from '../../../Utils/globalTypes'

const quotationRequestListModURL = '/quotation-list'
const quotationRequestListDetailModURL = '/order-detail'

export const getQuotationRequestList = (data: getApiProps) => {
  const { rowsPerPage, page } = data
  return axiosInstance.get(
    `${quotationRequestListModURL}?offset=${rowsPerPage * page}&limit=${rowsPerPage}`
  )
}
export const getQuotationRequestListDetail = (data: getApiProps) => {
  const { rowsPerPage, page, filterData } = data
  return axiosInstance.get(
    `${quotationRequestListModURL}?offset=${rowsPerPage * page}&limit=${rowsPerPage}&${filterData?.requester ? `create_user=${filterData?.requester?.id}` : ''
    }`
  )
}
export const getQuotationRequestListDetailById = (id: number) => {
  return axiosInstance.get(`${quotationRequestListModURL}/${id}`)
}
