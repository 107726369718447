import { Action } from '@reduxjs/toolkit'
import { combineEpics } from 'redux-observable'
import { switchMap } from 'rxjs'
import { Observable } from 'rxjs'
import { map, filter } from 'rxjs/operators'

import { changePasswordSuccess } from '../Login/Redux/authSlice'
import { dispatchAction, stateAction } from '../Utils/globalTypes'
import {
  getCommonCompany,
  getCommonCostSharingDepartmentCode,
  getCommonDeliveryStyle,
  getCommonDepartment,
  getCommonAffiliatedDepartment,
  getCommonTaskType,
  getCommonDocument,
  getCommonExpenseType,
  getCommonExportRestriction,
  getCommonIndustryType,
  getCommonJob,
  getCommonLayout,
  getCommonPaymentTerms,
  getCommonRequester,
  getCommonSaleTax,
  getCommonSalesDepartment,
  getCommonSalesType,
  getCommonStatus,
  getCommonTask,
  getCommonTrados,
  getCommonTranslationSubject,
  getCommonTranslator,
  getCommonTranslatorLanguage,
  getCommonUser,
  getTranslatorFilter,
} from './api'
import {
  closeChangePassword,
  getCommonCompanyFail,
  getCommonCompanyRequest,
  getCommonCompanySuccess,
  getCommonCostSharingDepartmentCodeFail,
  getCommonCostSharingDepartmentCodeRequest,
  getCommonCostSharingDepartmentCodeSuccess,
  getCommonDeliveryStyleFail,
  getCommonDeliveryStyleRequest,
  getCommonDeliveryStyleSuccess,
  getCommonDepartmentFail,
  getCommonDepartmentRequest,
  getCommonDepartmentSuccess,
  getCommonAffiliatedDepartmentFail,
  getCommonAffiliatedDepartmentRequest,
  getCommonAffiliatedDepartmentSuccess,
  getCommonTaskTypeFail,
  getCommonTaskTypeRequest,
  getCommonTaskTypeSuccess,
  getCommonDocumentFail,
  getCommonDocumentRequest,
  getCommonDocumentSuccess,
  getCommonExpenseTypeFail,
  getCommonExpenseTypeRequest,
  getCommonExpenseTypeSuccess,
  getCommonExportRestrictionFail,
  getCommonExportRestrictionRequest,
  getCommonExportRestrictionSuccess,
  getCommonFilteredTranslatorOptionRequest,
  getCommonFilteredTranslatorOptionSuccess,
  getCommonIndustryTypeFail,
  getCommonIndustryTypeRequest,
  getCommonIndustryTypeSuccess,
  getCommonJobFail,
  getCommonJobRequest,
  getCommonJobSuccess,
  getCommonLayoutFail,
  getCommonLayoutRequest,
  getCommonLayoutSuccess,
  getCommonPaymentTermsFail,
  getCommonPaymentTermsRequest,
  getCommonPaymentTermsSuccess,
  getCommonRequesterFail,
  getCommonRequesterRequest,
  getCommonRequesterSuccess,
  getCommonSaleTaxFail,
  getCommonSaleTaxRequest,
  getCommonSaleTaxSuccess,
  getCommonSalesDepartmentFail,
  getCommonSalesDepartmentRequest,
  getCommonSalesDepartmentSuccess,
  getCommonSalesTypeFail,
  getCommonSalesTypeRequest,
  getCommonSalesTypeSuccess,
  getCommonStatusFail,
  getCommonStatusRequest,
  getCommonStatusSuccess,
  getCommonTaskFail,
  getCommonTaskRequest,
  getCommonTaskSuccess,
  getCommonTradosFail,
  getCommonTradosRequest,
  getCommonTradosSuccess,
  getCommonTranslationSubjectFail,
  getCommonTranslationSubjectRequest,
  getCommonTranslationSubjectSuccess,
  getCommonTranslatorFail,
  getCommonTranslatorFilterOptionFail,
  getCommonTranslatorLanguageFail,
  getCommonTranslatorLanguageRequest,
  getCommonTranslatorLanguageSuccess,
  getCommonTranslatorRequest,
  getCommonTranslatorSuccess,
  getCommonUserFail,
  getCommonUserRequest,
  getCommonUserSuccess,
  loadingCommon,
  alertErrorAction,
} from './commonAppSlice'
import { extractErrorMessage } from '../Utils/AppFunction'

export const controller = new AbortController()

const getCommonRequesterRequestEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(getCommonRequesterRequest.match),
    switchMap(async (action) => {
      dispatch(loadingCommon())
      try {
        const response = await getCommonRequester(action.payload)
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)));
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? getCommonRequesterSuccess(action?.payload)
        : getCommonRequesterFail()
    )
  )
}
const getCommonTranslatorLanguageRequestEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(getCommonTranslatorLanguageRequest.match),
    switchMap(async (action) => {
      dispatch(loadingCommon())
      try {
        const response = await getCommonTranslatorLanguage(action.payload)
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)));
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? getCommonTranslatorLanguageSuccess(action?.payload)
        : getCommonTranslatorLanguageFail()
    )
  )
}

const getCommonCompanyRequestEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(getCommonCompanyRequest.match),
    switchMap(async (action) => {
      dispatch(loadingCommon())
      try {
        const response = await getCommonCompany(action.payload)
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)));
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? getCommonCompanySuccess(action?.payload)
        : getCommonCompanyFail()
    )
  )
}
const getCommonPaymentTermsRequestEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(getCommonPaymentTermsRequest.match),
    switchMap(async (action) => {
      dispatch(loadingCommon())
      try {
        const response = await getCommonPaymentTerms(action.payload)
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)));
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? getCommonPaymentTermsSuccess(action?.payload)
        : getCommonPaymentTermsFail()
    )
  )
}

const getCommonDeliveryStyleRequestEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(getCommonDeliveryStyleRequest.match),
    switchMap(async (action) => {
      dispatch(loadingCommon())
      try {
        const response = await getCommonDeliveryStyle(action.payload)
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)));
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? getCommonDeliveryStyleSuccess(action?.payload)
        : getCommonDeliveryStyleFail()
    )
  )
}

const getCommonLayoutRequestEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getCommonLayoutRequest.match),
    switchMap(async (action) => {
      dispatch(loadingCommon())
      try {
        const response = await getCommonLayout(action.payload)
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)));
        return { error: e }
      }
    }),
    map((action) => {
      return action?.payload
        ? getCommonLayoutSuccess(action.payload)
        : getCommonLayoutFail()
    })
  )

//common translator
const getCommonTranslatorRequestEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(getCommonTranslatorRequest.match),
    switchMap(async (action) => {
      dispatch(loadingCommon())
      try {
        const response = await getCommonTranslator(action.payload)
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)));
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? getCommonTranslatorSuccess(action?.payload)
        : getCommonTranslatorFail()
    )
  )
}

//common trados
const getCommonTradosRequestEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(getCommonTradosRequest.match),
    switchMap(async (action) => {
      dispatch(loadingCommon())
      try {
        const response = await getCommonTrados(action.payload)
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)));
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? getCommonTradosSuccess(action?.payload)
        : getCommonTradosFail()
    )
  )
}

const getCommonTranslatorFilterOptionRequestEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(getCommonFilteredTranslatorOptionRequest.match),
    switchMap(async (action) => {
      try {
        const response = await getCommonTranslator(action.payload)
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)));
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? getCommonFilteredTranslatorOptionSuccess(action?.payload)
        : getCommonTranslatorFilterOptionFail()
    )
  )
}

//common trados
const getCommonTaskRequestEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(getCommonTaskRequest.match),
    switchMap(async (action) => {
      dispatch(loadingCommon())
      try {
        const response = await getCommonTask(action.payload)
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)));
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? getCommonTaskSuccess(action?.payload)
        : getCommonTaskFail()
    )
  )
}

//common sale tax
const getCommonSaleTaxRequestEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(getCommonSaleTaxRequest.match),
    switchMap(async (action) => {
      dispatch(loadingCommon())
      try {
        const response = await getCommonSaleTax(action.payload)
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)));
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? getCommonSaleTaxSuccess(action?.payload)
        : getCommonSaleTaxFail()
    )
  )
}
//
const getCommonIndustryTypeRequestEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(getCommonIndustryTypeRequest.match),
    switchMap(async (action) => {
      dispatch(loadingCommon())
      try {
        const response = await getCommonIndustryType(action.payload)
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)));
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? getCommonIndustryTypeSuccess(action?.payload)
        : getCommonIndustryTypeFail()
    )
  )
}
//
const getCommonDocumentRequestEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(getCommonDocumentRequest.match),
    switchMap(async (action) => {
      dispatch(loadingCommon())
      try {
        const response = await getCommonDocument(action.payload)
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)));
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? getCommonDocumentSuccess(action?.payload)
        : getCommonDocumentFail()
    )
  )
}
//
const getCommonExportRestrictionRequestEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(getCommonExportRestrictionRequest.match),
    switchMap(async (action) => {
      dispatch(loadingCommon())
      try {
        const response = await getCommonExportRestriction(action.payload)
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)));
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? getCommonExportRestrictionSuccess(action?.payload)
        : getCommonExportRestrictionFail()
    )
  )
}
//
const getCommonTranslationSubjectRequestEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(getCommonTranslationSubjectRequest.match),
    switchMap(async (action) => {
      dispatch(loadingCommon())
      try {
        const response = await getCommonTranslationSubject(action.payload)
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)));
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? getCommonTranslationSubjectSuccess(action?.payload)
        : getCommonTranslationSubjectFail()
    )
  )
}
//
const getCommonExpenseTypeRequestEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(getCommonExpenseTypeRequest.match),
    switchMap(async (action) => {
      dispatch(loadingCommon())
      try {
        const response = await getCommonExpenseType(action.payload)
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)));
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? getCommonExpenseTypeSuccess(action?.payload)
        : getCommonExpenseTypeFail()
    )
  )
}
//
const getCommonJobRequestEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(getCommonJobRequest.match),
    switchMap(async (action) => {
      dispatch(loadingCommon())
      try {
        const response = await getCommonJob(action.payload)
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)));
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? getCommonJobSuccess(action?.payload)
        : getCommonJobFail()
    )
  )
}

const getCommonUserRequestEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(getCommonUserRequest.match),
    switchMap(async (action) => {
      dispatch(loadingCommon())
      try {
        const response = await getCommonUser(action.payload)
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)));
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? getCommonUserSuccess(action?.payload)
        : getCommonUserFail()
    )
  )
}
//
const getCommonSalesDepartmentRequestEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(getCommonSalesDepartmentRequest.match),
    switchMap(async (action) => {
      dispatch(loadingCommon())
      try {
        const response = await getCommonSalesDepartment(action.payload)
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)));
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? getCommonSalesDepartmentSuccess(action?.payload)
        : getCommonSalesDepartmentFail()
    )
  )
}

const getCommonSalesTypeRequestEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(getCommonSalesTypeRequest.match),
    switchMap(async (action) => {
      dispatch(loadingCommon())
      try {
        const response = await getCommonSalesType(action.payload)
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)));
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? getCommonSalesTypeSuccess(action?.payload)
        : getCommonSalesTypeFail()
    )
  )
}

const getCommonCostSharingDepartmentCodeRequestEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(getCommonCostSharingDepartmentCodeRequest.match),
    switchMap(async (action) => {
      dispatch(loadingCommon())
      try {
        const response = await getCommonCostSharingDepartmentCode(
          action.payload
        )
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)));
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? getCommonCostSharingDepartmentCodeSuccess(action?.payload)
        : getCommonCostSharingDepartmentCodeFail()
    )
  )
}

const changePasswordEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(changePasswordSuccess.match),
    map(() => closeChangePassword())
  )

// common department
const getCommonDepartmentRequestEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(getCommonDepartmentRequest.match),
    switchMap(async (action) => {
      dispatch(loadingCommon())
      try {
        const response = await getCommonDepartment(action.payload)
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)));
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? getCommonDepartmentSuccess(action?.payload)
        : getCommonDepartmentFail()
    )
  )
}

// common affiliated department
const getCommonAffiliatedDepartmentRequestEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(getCommonAffiliatedDepartmentRequest.match),
    switchMap(async (action) => {
      dispatch(loadingCommon())
      try {
        const response = await getCommonAffiliatedDepartment(action.payload)
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)));
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? getCommonAffiliatedDepartmentSuccess(action?.payload)
        : getCommonAffiliatedDepartmentFail()
    )
  )
}

// common task type
const getCommonTaskTypeRequestEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(getCommonTaskTypeRequest.match),
    switchMap(async (action) => {
      dispatch(loadingCommon())
      try {
        const response = await getCommonTaskType(action.payload)
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)));
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? getCommonTaskTypeSuccess(action?.payload)
        : getCommonTaskTypeFail()
    )
  )
}

// common status
const getCommonStatusRequestEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
): Observable<Action> => {
  return action$.pipe(
    filter(getCommonStatusRequest.match),
    switchMap(async (action) => {
      dispatch(loadingCommon())
      try {
        const response = await getCommonStatus(action.payload)
        return { payload: response.data }
      } catch (e) {
        dispatch(alertErrorAction(extractErrorMessage(e)));
        return { error: e }
      }
    }),
    map((action) =>
      action?.payload
        ? getCommonStatusSuccess(action?.payload)
        : getCommonStatusFail()
    )
  )
}

export const commonAppEpics = combineEpics(
  getCommonRequesterRequestEpic,
  getCommonLayoutRequestEpic,
  getCommonTranslatorLanguageRequestEpic,
  getCommonCompanyRequestEpic,
  getCommonPaymentTermsRequestEpic,
  getCommonDeliveryStyleRequestEpic,
  getCommonTranslatorRequestEpic,
  getCommonTradosRequestEpic,
  getCommonTaskRequestEpic,
  getCommonTranslatorFilterOptionRequestEpic,
  getCommonSaleTaxRequestEpic,
  getCommonIndustryTypeRequestEpic,
  getCommonDocumentRequestEpic,
  getCommonExportRestrictionRequestEpic,
  getCommonTranslationSubjectRequestEpic,
  getCommonExpenseTypeRequestEpic,
  getCommonJobRequestEpic,
  getCommonUserRequestEpic,
  getCommonCostSharingDepartmentCodeRequestEpic,
  changePasswordEpic,
  getCommonSalesTypeRequestEpic,
  getCommonSalesDepartmentRequestEpic,
  getCommonDepartmentRequestEpic,
  getCommonAffiliatedDepartmentRequestEpic,
  getCommonTaskTypeRequestEpic,
  getCommonStatusRequestEpic
)
