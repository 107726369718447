import { memo } from 'react'

import { Button, Grid } from '@mui/material'

interface AddNewInvoiceFormBtnProps {
  displayAddButton: boolean | null
  onAddButtonClick: () => void
}

const AddNewInvoiceFormBtn = ({
  displayAddButton,
  onAddButtonClick,
}: AddNewInvoiceFormBtnProps) => {
  return (
    <Grid
      item
      xs={10}
      sx={{
        py: 1,
        display: displayAddButton ? 'block' : 'none',
      }}
    >
      <Button
        size="large"
        fullWidth
        variant="contained"
        onClick={onAddButtonClick}
      >
        +
      </Button>
    </Grid>
  )
}
export default memo(AddNewInvoiceFormBtn)
