import React, { Suspense, lazy, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { Grid, Typography } from '@mui/material'

import { t } from 'i18next'

import { alertInfoAction, initialRowsPerPageAndPages } from '../../../CommonAppRedux/commonAppSlice'
import { commonUrlFilterData } from '../../../CommonAppRedux/commonAppSlice'
import { commonAppSelector } from '../../../CommonAppRedux/selector'
import {
  useAppDispatch,
  useAppSelector,
} from '../../../ReusableComponent/CustomHooks/appHooks'
import SaveFooterComponent from '../../../ReusableComponent/SaveFooterButton/SaveFooterButton'
import { getQuotationRequestListDetailRequest } from '../Redux/quotationRequestListSlice'
// import {
//   getQuotationRequestListDetailRequest,
//   getQuotationRequestListRequest,
// } from '../Redux/projectListSlice'
import { searchOrderSubmitValuesProps } from '../Types'
import { getCommonStorePreviousURLPath } from '../../../CommonLocalStorageRedux/localReduxSlice'

//below import with lazy loading, which is typically used when you want to load modules or components asynchronously, especially when they are not immediately needed.
const QuotationRequestListTableList = lazy(
  () => import('./QuotationRequestListTableList')
)
const QuotationRequestList = () => {
  const dispatch = useAppDispatch()

  const { rowsPerPage, page, tableCheckBoxData, collapseTableCheckBoxData } =
    useAppSelector(commonAppSelector)

  //filter for test purpose make component soon---
  const params = useParams<{ values?: string }>()
  const decodedValues = useMemo(() => {
    if (params.values) {
      return decodeURIComponent(params.values)
    }
    return ''
  }, [params.values])

  // Define the type for parsedValues
  let parsedValues: searchOrderSubmitValuesProps | null = null

  try {
    if (decodedValues) {
      parsedValues = JSON.parse(decodedValues)
    }
  } catch (error) {
    console.error('Error parsing JSON:', error)
  }

  useEffect(() => {
    dispatch(commonUrlFilterData(parsedValues))
    if(parsedValues){
      dispatch(
        getQuotationRequestListDetailRequest({
          page: page,
          rowsPerPage: rowsPerPage,
          filterData: parsedValues,
        })
      )
    }
      dispatch(
        getQuotationRequestListDetailRequest({
          page: page,
          rowsPerPage: rowsPerPage,
        })
      )
    
  }, [rowsPerPage, page]);
  const handleBulkInspectionCertificate = () => {
    if (tableCheckBoxData?.length) {
      alert('bulk inspection certificate')
    } else {
      dispatch(alertInfoAction(t('customAlertMessage.pleaseSelectJobDetail')))
    }
  }
  const location=useLocation();
  useEffect(() => {
    dispatch(getCommonStorePreviousURLPath([{pathName:location?.pathname,search: location?.search }]))
  }, [])
  const handleButtonAction = (action: string) => {
    const buttonConfig = buttonConfigs.find((config) => config.label === action)
    if (buttonConfig) {
      const { handler } = buttonConfig
      handler()
    }
  }

  const buttonConfigs = [
    {
      label: 'jobList.bulkInspectionCertificate',
      handler: handleBulkInspectionCertificate,
    },
  ]

  return (
    <React.Fragment>
      {/* <Grid item sx={{ mb: 4 }}>
        <SaveFooterComponent
          handleClick={handleButtonAction}
          buttonConfigs={buttonConfigs}
        />
      </Grid> */}
      <Typography variant="h6" fontWeight="bold" sx={{ padding: '16px' }}>
        {t('menuName.quotationList')}
      </Typography>

      <Suspense fallback={<></>}>
        <QuotationRequestListTableList />
      </Suspense>
    </React.Fragment>
  )
}

export default QuotationRequestList
